import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Field, reduxForm, change } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import {
  formatCount,
  formatDate,
  getAsset,
  getBasePrice,
  getTitle,
  numFormat,
  searchProcess, shortAddress, shortAddr,
} from '../../../modules/index';
import LoadingIcon from "mdi-react/LoadingIcon";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button, Table, Popover, PopoverBody,
} from 'reactstrap';
import TableTxs from "./components/TableTxs";
import TableOps from "./components/TableOps";
import ReactApexChart from "react-apexcharts";
import {getLedger} from "../../../endpoints/API";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import {Server} from "../../../modules/stellar";
import Trades from "../Account/components/Effects/Trades";
import Payments from "../Account/components/Effects/Payments";
import {format} from "timeago.js";



class Search extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      activeTab: '1',
      search_value: false,
      loading: false,
      ledger_loading: false,
    };
  }

  componentDidMount() {

    const title = 'Stellar Ledger Explorer';
    const description = 'The powerful and detailed Block Explorer for Stellar allows you to receive and analyze all possible combinations of data and transactions from the blockchain.';
    getTitle(title, description);

    this.getPayments()
    this.getTrades()
    this.getXLMPrice()

    this.streamLedgers()
  }


  streamLedgers = () => {

    Server.ledgers()
      .cursor('now')
      .stream({
        onmessage: async (ledger) => {
          this.setState({ ledger })

          const payments = await ledger.payments()
          if (payments.records.length) {
            this.setState({
              ledger_payments: payments.records.filter((item, index) => (index < 7))
            })
          }
        }
      });
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
          stellar,
          base_price: stellar.market_data.current_price.usd
        },
        () => {
          // this.getLedger()
        })
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };



  getPayments = () => {
    Server.payments()
      .limit(10)
      .order('desc')
      .call()
      .then(result => {
        this.setPaymentsState(result.records)
        this.streamPayments()
      })
  }


  getTrades = () => {
    Server.trades()
      .limit(10)
      .order('desc')
      .call()
      .then(result => {
        this.setTradesState(result.records)
        this.streamTrades()
      })
  }

  streamTrades = () => {

    let trades = [];

    Server.trades()
      .cursor('now')
      .stream({
        onmessage: (data) => {
          trades.unshift(data);
          if (trades.length === 10) {
            this.setTradesState(trades)
            trades = [];
          }
        }
    });
  }


  streamPayments = () => {

    let payments = [];

    Server.payments()
      .cursor('now')
      .stream({
        onmessage: (data) => {
          payments.unshift(data);
          if (payments.length === 10) {
            this.setPaymentsState(payments)
            payments = [];
          }
        }
    });
  }


  setPaymentsState = (payments) => {
    this.setState({ payments })
  }

  setTradesState = (trades) => {
    this.setState({ trades })
  }


  getLedger = () => {

    getLedger().then(result => {

      const ledger = {
        accounts: [],
        avg_ledger_time: [],
        failed_transactions: [],
        fee_pool: [],
        funded_trustlines: [],
        ledgers: [],
        new_assets: [],
        operations: [],
        payments: [],
        reserve: [],
        total_xlm: [],
        trades: [],
        transactions: [],
        trustlines: [],
        ts: [],
        volume: [],
        volume_usd: [],
        labels: [],
      }

      let limit = 1000, cnt = 30, month = 30;

      if (result.data) {

        const fool_time_ledger = result.data.stats ? JSON.parse(result.data.stats) : []
        const stats_dex = result.data.stats_dex ? JSON.parse(result.data.stats_dex) : []
        const stats_24 = result.data.stats_24 ? JSON.parse(result.data.stats_24) : []


        let total_volume = 0
        fool_time_ledger.map(item => {
          total_volume += Number(item.volume  / 10000000)
        })

        const ledger_stats = fool_time_ledger.filter((item, index) => (index > limit ? true : false))

        const getTime = (item) => {
          let time = new Date(item.ts * 1000)
          time.setDate(time.getDate() + 1)
          return new Date(time.getTime()).toDateString()
        }

        let transactions = 0, failed_transactions = 0, operations = 0, accounts = 0, new_assets = 0, trustlines = 0, payments = 0, trades = 0, volume = 0, fee_pool = 0;

        ledger_stats.map((item, index) => {

          transactions += item.transactions
          failed_transactions += item.failed_transactions
          operations += item.operations
          // accounts += item.accounts
          new_assets += item.new_assets
          // trustlines += item.trustlines
          payments += item.payments
          trades += item.trades
          // volume += item.volume
          fee_pool += item.fee_pool

          if (index === cnt) {

            volume = item.volume / 10000000

            ledger.avg_ledger_time.push(item.avg_ledger_time)
            ledger.accounts.push(item.accounts)
            ledger.funded_trustlines.push(item.funded_trustlines)
            ledger.new_assets.push(new_assets)
            ledger.trustlines.push(item.trustlines)
            ledger.transactions.push(transactions)
            ledger.failed_transactions.push(failed_transactions / 50)
            ledger.operations.push(operations * 1.5)
            ledger.payments.push(payments)
            ledger.trades.push(trades)
            ledger.reserve.push(item.reserve)
            ledger.volume.push(volume)
            ledger.volume_usd.push(volume * this.state.base_price)
            ledger.fee_pool.push(fee_pool)
            // ledger.ledgers.push(item.ledgers)
            ledger.labels.push(getTime(item))

            cnt = (cnt + 30)

            transactions = 0; operations = 0; accounts = 0; new_assets = 0; trustlines = 0; payments = 0; trades = 0;  volume = 0; fee_pool = 0;
          }
        })

        const series_txs_ops = [
          { name: 'Transactions', data: ledger.transactions },
          { name: 'Operations', data: ledger.operations, },
        ]

        const series_failed_txs = [
          { name: 'Failed', data: ledger.failed_transactions, },
          { name: 'Successful', data: ledger.transactions },
        ]

        const series_accounts = [
          { name: 'Accounts', data: ledger.accounts },
          // { name: 'Trustlines', data: ledger.trustlines, },
        ]

        const series_trustlines = [
          { name: 'Total', data: ledger.trustlines },
          { name: 'Funded', data: ledger.funded_trustlines, },
        ]

        const series_trades_payments = [
          { name: 'Trades', data: ledger.trades },
          { name: 'Payments', data: ledger.payments },
        ]

        const series_new_assets = [
          { name: 'New Assets', data: ledger.new_assets },
        ]

        const series_close_time = [
          { name: 'Closing time', data: ledger.avg_ledger_time },
        ]

        const series_volume = [
          { name: 'XLM', data: ledger.volume },
          { name: 'USD', data: ledger.volume_usd },
        ]


        this.setState({
          fool_time_ledger,
          total_volume,

          stats_dex,
          stats_24,
          ledger_stats,

          series_txs_ops,
          options_txs_ops: this.setStackedOptions(ledger.labels),
          series_failed_txs,
          options_failed_txs: this.setStackedOptions(ledger.labels),
          series_accounts,
          options_accounts: this.setStackedOptions(ledger.labels),
          series_trades_payments,
          options_trades_payments: this.setStackedOptions(ledger.labels),
          series_new_assets,
          options_new_assets: this.setStackedOptions(ledger.labels),
          series_close_time,
          options_close_time: this.setStackedOptions(ledger.labels, 'time'),
          series_trustlines,
          options_trustlines: this.setStackedOptions(ledger.labels),
          series_volume,
          options_volume: this.setStackedOptions(ledger.labels),
          volume_usd: (ledger.volume[ledger.volume.length-1] * this.state.base_price),

        }, () => {
          // this.testSetOptions(ledger.labels)
        })

      }
    })
  }


  setStackedOptions = (labels, type) => {

    const theme_loc = localStorage.getItem('theme')
    const theme = theme_loc === 'theme-dark' ? 'dark' : 'light'
    const decimels = type === 'time' ? 2 : 0

    return  {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        }
      },

      stroke: {
        width: [2, 2, 2],
        // curve: 'smooth'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],

      theme: {
        mode: theme,
          palette: 'palette1',
          monochrome: {
          enabled: false,
            color: '#255aee',
            // shadeTo: 'light',
            shadeIntensity: 0.65
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
            borderRadius: 1,
        },
      },

      grid: {
        show: true,
          borderColor: '#64677726', //#2a2a30
          row: {
          colors: undefined,
            opacity: 0.5
        },
        column: {
          colors: undefined,
            opacity: 0.5
        },
      },

      dataLabels: {
        enabled: false,
          enabledOnSeries: undefined,
      },

      labels: labels,
        xaxis: {
        type: 'datetime',
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        shared: true,
          intersect: false,
          fillSeriesColor: false,
          theme: true,
          y: {
          formatter: function (y, x) {
            if (typeof y !== "undefined") {
              return `${numFormat(y, decimels)} ${type === 'time' ? 's' : ''}`
            }
            return y;
          }
        }
      },

      yaxis: {
        show: true,
        labels: {
          show: true,
          formatter: (value) => {
            return `${formatCount(value, true, 2)} ${type === 'time' ? 's' : ''}`
          },
        },
      }
    }
  }



  parseUrl() {
    let part = this.props.match.params.id.split('-');
    let asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }
    return asset;
  }


  handleSearchClick = () => {
    this.startSearch(this.state.search_value)
  }


  searchChange = (value) => {
    this.setState({
      search_value: value.target.value
    })
  }


  submitSearchForm = (values) => {
    this.startSearch(values.search)
  }


  startSearch = (value) => {

    if (!value) {
      ToastStore.warning('Paste value');
    }
    else {
      this.setState({loading: true})
      searchProcess(value)
    }
  }


  togglePopover = () => {
      this.setState(prevState => ({
          popoverOpen: !prevState.popoverOpen
        })
      );
  }


  togglePopoverDex = () => {
    this.setState(prevState => ({
      popoverOpenDex: !prevState.popoverOpenDex
    }))
  }

  togglePopoverVol = () => {
    this.setState(prevState => ({
      popoverOpenVol: !prevState.popoverOpenVol
    }))
  }


  togglePopoverAccounts = () => {
    this.setState(prevState => ({
      popoverOpenAccounts: !prevState.popoverOpenAccounts
    }))
  }

  togglePopoverTxs = () => {
    this.setState(prevState => ({
      popoverOpenTxs: !prevState.popoverOpenTxs
    }))
  }

  togglePopoverAssets = () => {
    this.setState(prevState => ({
      popoverOpenAssets: !prevState.popoverOpenAssets
    }))
  }

  togglePopoverTrustlines = () => {
    this.setState(prevState => ({
      popoverOpenTrustlines: !prevState.popoverOpenTrustlines
    }))
  }

  togglePopoverTrades = () => {
    this.setState(prevState => ({
      popoverOpenTrades: !prevState.popoverOpenTrades
    }))
  }

  togglePopoverTime = () => {
    this.setState(prevState => ({
      popoverOpenTime: !prevState.popoverOpenTime
    }))
  }

  togglePopoverTsxRate = () => {
    this.setState(prevState => ({
      popoverOpenTsxRat: !prevState.popoverOpenTsxRat
    }))
  }



  render() {

    // console.log('state: ', this.state)

    const { handleSubmit, t } = this.props;
    const { series_txs_ops, options_txs_ops, series_accounts, options_accounts, options_trades_payments, series_trades_payments,
      options_new_assets, series_new_assets, series_close_time, options_close_time, series_trustlines, options_trustlines, series_volume, options_volume,
      stats_dex, stats_24, series_failed_txs, options_failed_txs, ledger, ledger_stats, ledger_payments, total_volume }  = this.state

    const last_ledger_stats = ledger_stats ? ledger_stats[ledger_stats.length-1] : false

    const xlm_asset = (<Link to={`/native`} className={'text-warning'}><b>XLM</b></Link>)

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={{size: 12}}>
            <h3 className="page-title">
              <img src="/img/logo/stellar-logo-gray.png" alt="Stellar" style={{width: 20, marginTop: -4}}/> {' '}
              Stellar Ledger Explorer</h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Search and detailed data analytics from <a href="https://stellar.org" target='_blank' rel='noopener noreferrer'>Stellar Network</a>
            </h4>
          </Col>
        </Row>


        <Row>
          <Col sm={{size: 12}} md={{size: 12}} lg={{size:6}}>
            {/*, offset:3*/}
            <Card>
              <CardBody style={{backgroundImage: ''}}>

                <div className={'text-center text-secondary'}>
                  <i className="fa fa-search search-logo-wr text-lightblue">
                    <img src="/img/logo/stellar-logo-gray.png" alt="Stellar" className={'search-stellar-logo'}/>
                  </i>
                </div>
                <br/><br/>

                {
                  this.state.loading ?
                    <div className="panel__refresh"><LoadingIcon /></div> : null
                }

                <form className="form" onSubmit={handleSubmit(this.submitSearchForm)}>
                  <div className="form__form-group">
                    <span className="form__form-group-label"><b>Enter your search query</b></span>
                    <div className="form__form-group-field">
                      <Field
                        className={'search-input'}
                        name="search"
                        component="input"
                        type="text"
                        placeholder="Address, Asset, Pool, Transaction, Operation, Offer, Balance, Domain, Federation"
                        onChange={this.searchChange}
                      />
                      <button
                        type="button"
                        className={`form__form-group-button btn btn-secondary search-btn`}
                        onClick={this.handleSearchClick}
                      ><i className="fa fa-search"></i></button>
                    </div>
                  </div>
                </form>
                <br/>
                <p className={'text-secondary'}>Scopuly Ledger Explorer allows you to find any combination of data from Stellar Blockchain in a simple, understandable and readable form. <small>Find all data by: <b>Address</b>, <b>Asset</b>, <b>Pool</b>, <b>Transaction</b>, <b>Operation</b>, <b>Offer</b>, <b>Balance</b>, <b>Domain</b>, <b>Federation name</b>.</small></p>
              </CardBody>
            </Card>
          </Col>



          {/*<Col sm={{size: 12}} md={{size: 6}} lg={{size: 3}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-database text-lightblue'}></i> Stellar Ledger 24h</h5>*/}
          {/*        <h5 className="subhead">Ledger stats for the last <b className={'text-inf'}>24</b> hours</h5>*/}
          {/*      </div>*/}

          {/*      <div className={'panel__btns'}>*/}
          {/*        <button id="popover"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopover}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpen}*/}
          {/*          target="popover"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopover}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}

          {/*      {*/}
          {/*        stats_24 ?*/}
          {/*          <Table className="table--bordered asset-info" responsive>*/}
          {/*            <tbody className={'fs-14'}>*/}
          {/*              <tr>*/}
          {/*                <td>Сlosing Time</td>*/}
          {/*                <td><b className={'text-info'}>{stats_24.avg_ledger_time}s</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>Accounts</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.accounts, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>New Assets</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.new_assets, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>Operations</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.operations, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>Payments</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.payments, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>Trades</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.trades, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*              <tr>*/}
          {/*                <td>Volume XLM</td>*/}
          {/*                <td><b className={'text-info'}>{formatCount(stats_24.volume / 10000000, true, 2)}</b></td>*/}
          {/*              </tr>*/}
          {/*            </tbody>*/}
          {/*          </Table>*/}
          {/*        :*/}
          {/*          <div className="panel__refresh"><LoadingIcon /></div>*/}
          {/*      }*/}

          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}



          <Col sm={{size: 12}} md={{size: 6}} lg={{size: 3}}>
            <Card>
              <CardBody >

                <div className="card__title">
                  <h5 className="bold-text"><i className="fa fa-wifi text-lightblue"></i> Ledger Live</h5>
                  <h5 className="subhead">Key real-time Ledger data</h5>
                </div>

                {
                  ledger ?
                    <Table className="table--bordered asset-info" responsive>
                      <tbody className={'fs-14'}>
                      <tr>
                        <td>Protocol</td>
                        <td><b className={'text-info'}>{ledger.protocol_version}</b></td>
                      </tr>
                        <tr>
                          <td>Transactions</td>
                          <td><b className={'text-success'}>{numFormat(ledger.successful_transaction_count)}</b> / <b className={'text-danger'}>{numFormat(ledger.failed_transaction_count)}</b></td>
                        </tr>
                        <tr>
                          <td>Operations</td>
                          <td><b className={'text-success'}>{formatCount(ledger.operation_count, true, 2)}</b> / <b className={'text-danger'}>{formatCount(ledger.tx_set_operation_count, true, 2)}
                          </b></td>
                        </tr>
                        <tr>
                          <td>Sequence</td>
                          <td><b className={'text-info'}>{ledger.sequence}</b></td>
                        </tr>
                        <tr>
                          <td>Base fee</td>
                          <td><b className={'text-info'}>{numFormat(ledger.base_fee_in_stroops / 10000000, 7)}</b> {xlm_asset}</td>
                        </tr>
                        <tr>
                          <td>Base reserve</td>
                          <td><b className={'text-info'}>{numFormat(ledger.base_reserve_in_stroops / 10000000, 7)}</b> {xlm_asset}</td>
                        </tr>
                        <tr>
                          <td>Fee pool</td>
                          <td><b className={'text-info'}>{ledger.fee_pool ? formatCount(ledger.fee_pool, true, 2) : 0}</b> {xlm_asset}</td>
                        </tr>
                      </tbody>
                    </Table>
                  :
                    <div className="panel__refresh"><LoadingIcon /></div>
                }

              </CardBody>
            </Card>
          </Col>


          {/*<Col sm={{size: 12}} md={{size: 6}} lg={{size: 3}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-cube text-lightblue'}></i> DEX Statistics</h5>*/}
          {/*        <h5 className="subhead">Main indicators of <b>Stellar Network</b></h5>*/}
          {/*      </div>*/}

          {/*      <div className={'panel__btns'}>*/}
          {/*        <button id="popover_dex"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverDex}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenDex}*/}
          {/*          target="popover_dex"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverDex}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}


          {/*      {*/}
          {/*        stats_dex ?*/}
          {/*          <Table className="table--bordered asset-info" responsive>*/}
          {/*            <tbody className={'fs-14'}>*/}
          {/*            <tr>*/}
          {/*              <td>Assets</td>*/}
          {/*              <td><b className={'text-info'}>{formatCount(stats_dex.total_assets, true, 2)}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Trustlines</td>*/}
          {/*              <td><b className={'text-info'}>{stats_24 ? formatCount(stats_24.trustlines, true, 2) : '...'}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Payments</td>*/}
          {/*              <td><b className={'text-info'}>{formatCount(stats_dex.payments, true, 2)}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Trades</td>*/}
          {/*              <td><b className={'text-info'}>{formatCount(stats_dex.trades, true, 2)}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Volume XLM</td>*/}
          {/*              <td><b className={'text-info'}>{formatCount(total_volume, true, 2)}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Fee pool XLM</td>*/}
          {/*              <td><b className={'text-info'}>{last_ledger_stats ? formatCount((last_ledger_stats.fee_pool / 10000000), true, 2) : 0}</b></td>*/}
          {/*            </tr>*/}
          {/*            <tr>*/}
          {/*              <td>Total XLM</td>*/}
          {/*              <td><b className={'text-info'}>{ledger ? formatCount(ledger.total_coins, true, 2) : '...'}</b></td>*/}
          {/*            </tr>*/}
          {/*            </tbody>*/}
          {/*          </Table>*/}
          {/*        :*/}
          {/*          <div className="panel__refresh"><LoadingIcon /></div>*/}
          {/*      }*/}

          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}


          <Col sm={{size: 12}} md={{size: 6}} lg={{size: 3}}>
            <Card>
              <CardBody >

                <div className="card__title">
                  <h5 className="bold-text"><i className={'fa fa-location-arrow text-lightblue'}></i> Ledger payments</h5>
                  <h5 className="subhead">Live stream</h5>
                  <h5 className="subhead"><i className="fa fa-clock-o"></i> Last: <b className={'text-secondary'}>{ledger_payments ? formatDate(ledger_payments[0].created_at) : ''}</b></h5>
                </div>

                <Table className="table--bordered asset-info" responsive>
                  <tbody>
                  {
                    ledger_payments ?
                      ledger_payments.map((item, index) => {

                        let amount = 0, sender, recipient, action

                        if (item.funder) {
                          amount = item.starting_balance
                          sender = item.funder
                          action = 'created'
                        }
                        if (item.from) {
                          amount = item.amount
                          sender = item.from
                          action = 'sent'
                        }
                        if (item.into && item.type === 'account_merge') {
                          sender = item.account
                          recipient = item.into
                          action = 'merged'
                        }
                        if (item.type === 'create_account') {
                          recipient = item.account
                        }

                        return (
                          <tr>
                            <td>{shortAddress(sender, false, 2)} {action} { amount && action !== 'merged' && action !== 'created' ? <b className={'text-info'}>{numFormat(amount, 7)}</b> : null } { recipient ? <span> {action !== 'created' ? 'to' : ''} {shortAddress(recipient, false, 2)}</span> : null } { item.asset_type && action !== 'merged' ? getAsset(item) : null }</td>
                            <td><Link to={`/transaction/${item.transaction_hash}`}><b>tx</b></Link></td>
                          </tr>
                        )
                      })
                    :
                      <div className="panel__refresh"><LoadingIcon /></div>
                  }

                  </tbody>
                </Table>

              </CardBody>
            </Card>
          </Col>


          {/*<Col md={{size: 12}} lg={{size: 6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-pie-chart text-lightblue'}></i> DEX Volume</h5>*/}
          {/*        <h5 className="subhead">Volume for the last month: <b>${formatCount(this.state.volume_usd, true, 2)}</b></h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_volume && <small><b>{formatCount(series_volume[0].data[series_volume[0].data.length-1], true, 2)}</b> <Link to={'/native'} className={'text-warning'}><b>XLM</b></Link></small> }*/}

          {/*        <button id="popover_vol"*/}
          {/*          className="panel__btn"*/}
          {/*          type="button" onClick={this.togglePopoverVol}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*      <Popover*/}
          {/*        placement="top"*/}
          {/*        isOpen={this.state.popoverOpenVol}*/}
          {/*        target="popover_vol"*/}
          {/*        trigger="legacy"*/}
          {/*        toggle={this.togglePopoverVol}*/}
          {/*      >*/}
          {/*        <PopoverBody>*/}
          {/*          Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*        </PopoverBody>*/}
          {/*      </Popover>*/}

          {/*      </div>*/}
          {/*      {*/}
          {/*        options_volume ?*/}
          {/*          <ReactApexChart options={options_volume} series={series_volume} type="bar" height={290} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}



          {/*<Col md={{size: 12}} lg={{size:6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-users text-lightblue'}></i> Accounts</h5>*/}
          {/*        <h5 className="subhead">Total number of accounts</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_accounts && <small><b>{formatCount(series_accounts[0].data[series_accounts[0].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_accounts"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverAccounts}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenAccounts}*/}
          {/*          target="popover_accounts"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverAccounts}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}
          {/*      {*/}
          {/*        options_accounts ?*/}
          {/*          <ReactApexChart type={'area'} series={series_accounts} options={options_accounts} height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}


          {/*<Col md={{size: 12}} lg={{size: 6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-list text-lightblue'}></i> Transactions</h5>*/}
          {/*        <h5 className="subhead">Volume of transactions and operations</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_txs_ops && <small><b>{formatCount(series_txs_ops[0].data[series_txs_ops[0].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_tsx"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverTxs}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenTxs}*/}
          {/*          target="popover_tsx"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverTxs}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}

          {/*      {*/}
          {/*        options_txs_ops ?*/}
          {/*          <ReactApexChart options={options_txs_ops} series={series_txs_ops} type="bar" height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}



          {/*<Col md={{size: 12}} lg={{size:6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-cubes text-lightblue'}></i> Assets</h5>*/}
          {/*        <h5 className="subhead">New Assets per month for the entire period</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_new_assets && <small><b>{formatCount(series_new_assets[0].data[series_new_assets[0].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_assets"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverAssets}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenAssets}*/}
          {/*          target="popover_assets"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverAssets}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}
          {/*      {*/}
          {/*        options_new_assets ?*/}
          {/*          <ReactApexChart type={'bar'} series={series_new_assets} options={options_new_assets} height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}


          {/*<Col md={{size: 12}} lg={{size: 6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-check text-lightblue'}></i> Trustlines</h5>*/}
          {/*        <h5 className="subhead">Established and funded trustlines</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_trustlines && <small><b>{formatCount(series_trustlines[0].data[series_trustlines[0].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_trustlines"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverTrustlines}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenTrustlines}*/}
          {/*          target="popover_trustlines"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverTrustlines}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}
          {/*      {*/}
          {/*        options_trustlines ?*/}
          {/*          <ReactApexChart options={options_trustlines} series={series_trustlines} type="area" height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}



          {/*<Col md={{size: 12}} lg={{size:6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-bar-chart text-lightblue'}></i> Trades</h5>*/}
          {/*        <h5 className="subhead">Total number of Trades and Payments</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_trades_payments && <small><b>{formatCount(series_trades_payments[0].data[series_trades_payments[0].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_trades"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverTrades}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenTrades}*/}
          {/*          target="popover_trades"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverTrades}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}
          {/*      {*/}
          {/*        options_trades_payments ?*/}
          {/*          <ReactApexChart type="area" series={series_trades_payments} options={options_trades_payments} height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}


          {/*<Col md={{size: 12}} lg={{size:6}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-clock-o text-lightblue'}></i> Ledger closing time</h5>*/}
          {/*        <h5 className="subhead">Average closing time of the Ledger for the period</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_txs_ops && <small><b>{numFormat(series_close_time[0].data[series_close_time[0].data.length-1], 2)}s</b></small> }*/}

          {/*        <button id="popover_time"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverTime}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenTime}*/}
          {/*          target="popover_time"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverTime}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}
          {/*      </div>*/}
          {/*      {*/}
          {/*        options_txs_ops ?*/}
          {/*          <ReactApexChart options={options_close_time} series={series_close_time} type="area" height={300} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}


          {/*<Col md={{size: 12}} lg={{size: 12}}>*/}
          {/*  <Card>*/}
          {/*    <CardBody >*/}

          {/*      <div className="card__title">*/}
          {/*        <h5 className="bold-text"><i className={'fa fa-balance-scale text-lightblue'}></i> Transaction Success Rate</h5>*/}
          {/*        <h5 className="subhead">Ratio of successful to failed transactions</h5>*/}
          {/*      </div>*/}
          {/*      <div className="panel__btns text-secondary">*/}
          {/*        { options_failed_txs && <small><b>{formatCount(series_failed_txs[1].data[series_failed_txs[1].data.length-1], true, 2)}</b></small> }*/}

          {/*        <button id="popover_tsx_rate"*/}
          {/*                className="panel__btn"*/}
          {/*                type="button" onClick={this.togglePopoverTsxRate}>*/}
          {/*          <i className="fa fa-info text-secondary"></i>*/}
          {/*        </button>*/}

          {/*        <Popover*/}
          {/*          placement="top"*/}
          {/*          isOpen={this.state.popoverOpenTsxRate}*/}
          {/*          target="popover_tsx_rate"*/}
          {/*          trigger="legacy"*/}
          {/*          toggle={this.togglePopoverTsxRate}*/}
          {/*        >*/}
          {/*          <PopoverBody>*/}
          {/*            Data provided by <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a> service*/}
          {/*          </PopoverBody>*/}
          {/*        </Popover>*/}

          {/*      </div>*/}
          {/*      {*/}
          {/*        options_failed_txs ?*/}
          {/*          <ReactApexChart options={options_failed_txs} series={series_failed_txs} type="bar" height={350} />*/}
          {/*          :*/}
          {/*          <div className="panel__refresh" style={{height: '82%'}}><LoadingIcon /></div>*/}
          {/*      }*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}

        </Row>



        <Row>
          <TableTxs />
          <TableOps />
        </Row>


        <Row>

          <Col md={{size: 12}}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Payments</h5>
                  <h5 className="subhead"><i className="fa fa-wifi"></i> Live stream</h5>
                </div>
                {
                  this.state.payments ?
                    <Payments items={this.state.payments}/>
                    :
                    <div className="panel__refresh"><LoadingIcon /></div>
                }
              </CardBody>
            </Card>
          </Col>


          <Col md={{size: 12}}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Trades</h5>
                  <h5 className="subhead"><i className="fa fa-wifi"></i> Live stream</h5>
                </div>
                {
                  this.state.trades ?
                    <Trades items={this.state.trades} />
                    :
                    <div className="panel__refresh"><LoadingIcon /></div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    )
  }
}

Search.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'vertical_form_layout', // a unique identifier for this form
})(translate('common')(Search));
