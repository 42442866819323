import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import renderToggleButtonField from '../../../../../../shared/components/form/ToggleButton';
import renderSelectField from '../../../../../../shared/components/form/Select';
import { shortAddr, get_loc_wallets } from '../../../../../../modules/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import slugify from '@sindresorhus/slugify';
import { GLOBE } from '../../../../../../modules/globeVars/index';
import axios from 'axios';
import swal from 'sweetalert';
import Collapse from "../../../../../../shared/components/Collapse";



class WizardFormFive extends PureComponent {

  constructor() {
    super();
    this.state = {
      federation_name: false,
      route_to_wallet: false,
      status_name: true,
      showPublic: false,
      showSecret: false,
    };
  }

  componentDidMount() {
    //
  }


  changeFederationName(value) {

    const formData = new FormData();
    formData.append('name', value.target.value);

    axios.post(GLOBE.API_URL+`/check_federation`, formData)
      .then(result => {

        if (result.data === 'free') {
          this.setState({ status_name: true })
        } else {
          this.setState({ status_name: false })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
    });

    if (value.target.value) {
      this.setState({ federation_name: slugify(value.target.value) })
    } else {
      this.setState({ federation_name: false })
    }
  }


  setFederationName = (values) => {

    const routeToWallet = () => {
      this.setState({ route_to_wallet: true })
    }

    const showExists = () => {
      ToastStore.warning('This name already exists');
    }

    if (values.federation_name && !this.state.status_name) {
      showExists()
      return;
    }

    if (values.federation_name && this.state.status_name) {

      const federation_name = slugify(values.federation_name);

      const formData = new FormData();
      formData.append('name', federation_name);
      formData.append('pk', values.public_key);

      axios.post(GLOBE.API_URL+`/set_federation`, formData)
        .then(result => {

          if (result.data === 'success') {

            const message = `Your Federation Address <b class="text-info">${federation_name}*scopuly.com</b> has been successfully set.`
            const element = document.createElement("span");
            element.innerHTML = message;

            swal({
              title: `Federation name set!`,
              content: element,
              icon: "success",
              closeOnClickOutside: false,
              buttons: {
                confirm: true,
              },
            })
            .then((confirm) => {
              if (confirm)
                routeToWallet()
            })
          }
          else {
            showExists()
          }
        })
        .catch((error) => {
          console.log('error: ', error)
      });
    }
    else {
      //... route to wallet
      routeToWallet()
    }
  }

  togglePublic = (e) => {
    e.preventDefault()
    this.setState({ showPublic: !this.state.showPublic })
  }

  toggleSecret = (e) => {
    e.preventDefault()
    this.setState({ showSecret: !this.state.showSecret })
  }


  render() {

    const { handleSubmit } = this.props;

    if (this.state.route_to_wallet) {
      return <Redirect to={'/wallet'} />
    }

    return (
      <div>
        <div className={'text-center'}>
          <br/><br/>
          <h4 className="wizard__title">
            {/*<i className={'fa fa-check-circle-o text-success fs-460per'}></i>*/}
            <img src="/img/icons/auth/success.svg" alt="" style={{width: '20%'}}/>
            <br/><br/>
            <b>Account created!</b>
          </h4>
        </div>

        <div>
          <div>Name: <b className="text-info">{slugify(this.props.name_wallet)}</b></div>
          <br/>

          <div>
            Public Key: {' '}
            <span style={{cursor: 'pointer'}}>
              <CopyToClipboard text={this.props.public_key}
                                 onCopy={() => {}}>
                <a onClick={(e) => {ToastStore.success('Public Key copied!')}}>
                  <b className="text-info">{shortAddr(this.props.public_key, 8)}</b> <i className="fa fa-clone"></i>
                </a>
              </CopyToClipboard> {' '}
            </span> &nbsp;
            <span><a href="#" onClick={this.togglePublic}>{!this.state.showPublic ?
              <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</a></span>
            {
              this.state.showPublic && <div className={'create-word-wrap'}><small><b>{this.props.public_key}</b></small><br/><br/></div>
            }
          </div>

          <div>
            Secret Key: {' '}
            <span style={{cursor: 'pointer'}}>
              <CopyToClipboard text={window.atob(this.props.private_key)}
                                 onCopy={() => {}}>
                <a onClick={(e) => {ToastStore.success('Private Key copied!')}}>
                  <b className="text-info">{shortAddr(window.atob(this.props.private_key), 8)}</b> <i className="fa fa-clone"></i>
                </a>
              </CopyToClipboard> {' '}
            </span> &nbsp;
            <span><a href="#" onClick={this.toggleSecret}>{!this.state.showSecret ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</a></span>
            {
              this.state.showSecret && <div className={'create-word-wrap'}><small><b>{window.atob(this.props.private_key)}</b></small><br/><br/></div>
            }
            <br/><br/>
            <p className="text-warning"><i className="fa fa-exclamation-triangle"></i> This <b>Secret key</b> cannot be shown to anyone!</p>
          </div>

          <p><small><i>* Before using this wallet for serious purposes, make sure that you safely save all the received recovery data.</i></small></p>
        </div>

        <br/>

        <div>
          <Collapse title="Federation name (optional)" className="with-shadow ml-0">

            <p> { this.state.federation_name ? <div>Name: <span className="text-info"><b className={`text-${this.state.status_name ? 'success' : 'danger'}`}>{this.state.federation_name}</b><b>*scopuly.com</b></span></div> : null }</p>
            <p><small>Set a name for the Federation Address. This is a short and easy-to-read analogue of your newly created Stellar address.</small></p>
            <br/>

            <form className="form" onSubmit={handleSubmit(this.setFederationName)}>
              <div className="form__form-group">
                <div className="form__form-group-field">
                  <Field
                    name="federation_name"
                    component="input"
                    type="text"
                    placeholder="name (optional)"
                    onChange={(value) => this.changeFederationName(value)}
                  />
                  <div className="form__form-group-icon" style={{borderRadius: 10}}>*scopuly.com</div>
                </div>
                {
                  !this.state.status_name ?
                    <div><small className="text-danger">This name already exists</small></div>
                    : null
                }
              </div>
              <br/><br/>

              {
                this.state.federation_name ?
                  <div>
                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button color="success" outline>Save name</Button>
                    </ButtonToolbar>
                  </div>
                : null
              }
            </form>

          </Collapse>
        </div>

        <br/><br/>

        <div className={'text-center'}>
          <Link to={'/wallet'}
                className={'btn btn-success'}
                style={{width: '100%'}}><b>Go to Wallet</b></Link>
        </div>

      </div>
    )
  }
}



WizardFormFive.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormFive);
