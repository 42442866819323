import React from 'react';
import { Col, Container, Row, Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';

// const react = `${process.env.PUBLIC_URL}/img/landing/technologies/react.png`;
// const bootstrap = `${process.env.PUBLIC_URL}/img/landing/technologies/bootstrap-stack.png`;
// const router = `${process.env.PUBLIC_URL}/img/landing/technologies/router.png`;
// const reduxForm = `${process.env.PUBLIC_URL}/img/landing/technologies/redux_form.png`;
// const sass = `${process.env.PUBLIC_URL}/img/landing/technologies/sass.png`;
// const matUi = `${process.env.PUBLIC_URL}/img/landing/technologies/mat_ui.png`;
// const redux = `${process.env.PUBLIC_URL}/img/landing/technologies/redux.png`;

const codeExample = `${process.env.PUBLIC_URL}/img/landing/code_example.png`;
const responsive = `${process.env.PUBLIC_URL}/img/landing/admin_book.png`;
const sdex_img = `${process.env.PUBLIC_URL}/img/landing/sdex.png`;
const tokeniz_img = `${process.env.PUBLIC_URL}/img/landing/tokenization.png`;
const explorer_img = `${process.env.PUBLIC_URL}/img/landing/explorer.png`;
const launchpad_img = `${process.env.PUBLIC_URL}/img/landing/launchpad.png`;
const fiat_img = `${process.env.PUBLIC_URL}/img/landing/fiat.png`;
const background = `${process.env.PUBLIC_URL}/img/landing/right_bg.png`;
const osx = `${process.env.PUBLIC_URL}/img/landing/osx.png`;

const Technologies = () => (
  <section className="landing__section">
    <img className="landing__section-background landing__section-background--technologies" src={background} alt="" />
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">
            In the Scopuly wallet, you can safely store digital assets, make payments, trade, raise and issue cryptocurrency assets
          </h3>
        </Col>
      </Row>

      <Row>
        <Col md={{ size: 2, offset: 1 }} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small">
            <div className="landing_icon">
              <i className="fa fa-credit-card" aria-hidden="true" ></i>
            </div>
            <p className="landing__technology-title">Secure Wallet</p>
          </div>
        </Col>
        <Col md={2} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small">
            <div className="landing_icon">
              <i className="fa fa-exchange" aria-hidden="true" ></i>
            </div>
            <p className="landing__technology-title">Decentralized Asset Trading</p>
          </div>
        </Col>
        <Col md={{ size: 2, offset: 0 }} sm={{ size: 4, offset: 2 }} xs={6}>
          <div className="landing__technology landing__technology--small">
            <div className="landing_icon">
              <i className="fa fa-money" aria-hidden="true" ></i>
            </div>
            <p className="landing__technology-title">Crypto / Fiat Exchange</p>
          </div>
        </Col>
        <Col md={2} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small">
            <div className="landing_icon">
              <i className="fa fa-cubes" aria-hidden="true" ></i>
            </div>
            <p className="landing__technology-title">IEO Launchpad</p>
          </div>
        </Col>
        <Col md={2} sm={{ size: 4, offset: 0 }} xs={{ size: 6, offset: 3 }}>
          <div className="landing__technology landing__technology--small">
            <div className="landing_icon">
              <i className="fa fa-search" aria-hidden="true" ></i>
            </div>
            <p className="landing__technology-title">Block Explorer</p>
          </div>
        </Col>
      </Row>

      <Row >
        <Col md={{ size: 10, offset: 1 }} style={{marginTop: '130px'}}>
          <Card className="embed-responsive embed-responsive-21by9">
            <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/UqjWydNMOFI" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
          </Card>
          <br/>
        </Col>
      </Row>

      <Row className="landing__code">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Secure Stellar Wallet</h3>
              <p>Make payments or payments with exchange along the path of offers (PathPayment) in crypto or in any of your tokens, around the world, without restrictions, without fees.</p>
              <p>Issue tokens, exchange tokens for Fiat and back. Trade in the most progressive markets and tokens directly from your wallet.</p>
              <p>
                <Link to={`/auth/create`} className="rounded btn btn-outline-success" style={{float: 'right'}}>Create Wallet</Link>
              </p>
              <p><b>Works on major platforms:</b></p>

              <p className="landing__footer text-left">

                <a target='_blank'
                   rel='noopener noreferrer'
                   href="https://itunes.apple.com/us/app/id1383402218"
                   className="platforms-icon"><i className="fa fa-apple"></i></a>

                <a target='_blank'
                   rel='noopener noreferrer'
                   href="https://play.google.com/store/apps/details?id=com.sdex.app"
                   className="platforms-icon"><i className="fa fa-android"></i></a>

                {/*<a target='_blank'
                   rel='noopener noreferrer'
                   href="https://github.com/Scopuly/scopuly/releases"
                   className="platforms-icon" style={{padding: '6px 14px'}}><img src={osx} /></a>*/}

                <a target='_blank'
                   rel='noopener noreferrer'
                   href="https://github.com/Scopuly/scopuly/releases"
                   className="platforms-icon"><i className="fa fa-windows"></i></a>

                <Link to="/" className="platforms-icon"><i className="fa fa-chrome"></i></Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over" src={responsive} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="landing__code">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Decentralized Crypto Asset Trading (SDEX)</h3>
              <p>
                Trade in crypto assets in the global decentralized SDEX market without intermediaries and commissions.
              </p>
              <p>
                No fees for trading tokens. No fees for canceling / editing exchange orders. No need to wait for miners to confirm your transactions.
              </p>
              <p>
                 <Link to={`/assets/all`} className="rounded btn btn-outline-success">Start Asset Trading</Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over-right" src={sdex_img} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="landing__code landing__code--first">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Buy / Sell Crypto for Fiat</h3>
              <p>
                Buy and sell crypto for fiat money. 200 countries of the world are supported and such currencies as: USD, EUR, RUB +10 digital coins BTC, LTC, ETH, DASH, BCH.
              </p>
              <p>
                <Link to={`/exchange`} className="rounded btn btn-outline-success">Buy / Sell Crypto</Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over" src={fiat_img} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="landing__code">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Issuing Tokens (Asset Tokenization)</h3>
              <p>Issue your own crypto tokens on the Stellar blockchain. The issuing process is simple and affordable for everyone, even without special technical knowledge.</p>
              <p>
                <Link to={`/issuing`} className="rounded btn btn-outline-success">Issue your tokens</Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over-right" src={tokeniz_img} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="landing__code landing__code--first">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">IEO Launchpad</h3>
              <p>Invest in IEO projects on Scopuly Launchpad. Run your IEO with no listing fees. IEO tokens will immediately appear on the market and again without listing fees.</p>
              <p>
                <Link to={`/launchpad`} className="rounded btn btn-outline-success">Run IEO project</Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over" src={launchpad_img} alt="" />
            </div>
          </div>
        </Col>
      </Row>

      <Row className="landing__code ">
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-text">
            <div className="landing__code-wrap">
              <h3 className="landing__section-title">Block Explorer</h3>
              <p>The powerful and detailed Block Explorer for Stellar allows you to receive and analyze all possible combinations of data and transactions from the blockchain.</p>
              <p>
                <Link to={`/explorer`} className="rounded btn btn-outline-success">Explore history</Link>
              </p>
            </div>
          </div>
        </Col>
        <Col md={6} sm={12} xs={12}>
          <div className="landing__code-img landing__code-img--no-shadow">
            <div className="landing__code-wrap">
              <img className="landing__img landing__img--over-right" src={explorer_img} alt="" />
            </div>
          </div>
        </Col>
      </Row>

    </Container>
  </section>
);

export default Technologies;
