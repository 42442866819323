import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	shortAddr,
	numFormat,
	getNameAddr,
	getAsset,
	shortAddress,
	shortPool,
	itemType, formatDate
} from '../../../../../modules/index';
import moment from 'moment';
import iconIdent from '../iconIdent';



class Operations extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}



	formatDate(time) {
	  return moment(time).format("DD MMMM YYYY HH:mm:ss");
	}

	shortAddress(address) {
		const name = getNameAddr(address);
	  return (
	    <a href={`/account/${address}`}>{shortAddress(address, 4)}</a>
	  )
	}


	itemType(type) {
	  return type.replace(/_/g," ")[0].toUpperCase() + type.replace(/_/g," ").slice(1);
	}


	renderHomeDomain(domain) {
	  return (
	    <p>Home domain set to: <a href={'https://'+domain}> <b>{ domain }</b> </a></p>
	    )
	}

	renderSigner(signer_key, signer_weight) {
	  return (
	    <p>Add new signer <b>{ this.shortAddress(signer_key) }</b> with weight: <b className="text-info"> { signer_weight } </b></p>
	    )
	}

	renderLow(low_threshold) {
	  return (
	    <p>Low threshold set to: <b className="text-info"> { low_threshold } </b></p>
	    )
	}

	renderMed(med_threshold) {
	  return (
	    <p>Med threshold set to: <b className="text-info"> { med_threshold } </b></p>
	    )
	}

	renderHigh(high_threshold) {
	  return (
	    <p>High threshold set to: <b className="text-info"> { high_threshold } </b></p>
	    )
	}

	renderInfl(inflation_dest) {
	  return (
	    <p>Inflation dest set to: { this.shortAddress(inflation_dest) }</p>
	    )
	}

	renderMaster(master_key_weight) {
	  return (
	    <p>Master key weight set to: <b className="text-info"> { master_key_weight } </b></p>
	    )
	}

	renderSetFlags(set_flags_s) {
	  var rows = [];
	  for(var i = 0; i < set_flags_s.length; i++) {
	    rows.push(<p key={i}> Set flag <b className="text-info"> { set_flags_s[i] }</b></p>);
	  }
	  return rows;
	}

	renderClearFlags(clear_flags_s) {

	  var rows = [];
	  for(var i = 0; i < clear_flags_s.length; i++) {
	    rows.push(<p key={i}> Clear flag <b className="text-info"> { clear_flags_s[i] }</b></p>);
	  }
	  return rows;
	}


	renderPathPayment = (item, index) => {

		const renderFirstAsset = () => {
			return <Link to={item.source_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
		}

		return (
			<tr key={index}>
				<th><small>{index+1}</small></th>
				<td>
					<div>{this.shortAddress(item.from)} sent <b className="text-info">{ numFormat(item.source_amount, 7) }</b>{ renderFirstAsset() }</div>
					<div>{this.shortAddress(item.to)} received <b className="text-info">{ numFormat(item.amount, 7) }</b> { getAsset(item) }</div>
					<div>
						{/*{item.path.length ? 'Path:' : ''}*/}
						Path: [ {' '}
						{ renderFirstAsset() } <i className="fa fa-angle-right"></i> {' '}
							{
								item.path.map((pathItem, index) => {
									return (
										<b key={ index }> {getAsset(pathItem)} { item.path.length > (index+1) ? <span> <i className="fa fa-angle-right"></i> </span> : '' }</b>
									)
								})
							}
						{item.path.length ? <i className="fa fa-angle-right"></i> : ''}  { getAsset(item) } {' '}
						]
					</div>
				</td>
				<td><b className="text-info">{ this.itemType(item.type) }</b></td>
				<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
				<td>{this.formatDate(item.created_at)}</td>
			</tr>
		)
	}


	renderOperaion(item, index) {
		// console.log('item: ', item)

		switch(item.type_i) {

		  case 0:
		    return (
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>{ this.shortAddress(item.funder) } sent <b className="text-info">{numFormat(item.starting_balance, 7)}&nbsp;
		      	  <a href={'/asset/XLM-native'} className="text-warning">XLM</a></b> to { this.shortAddress(item.account) }
		      	</td>
		      	<td><b className="text-info">{ this.itemType(item.type) }</b></td>
		      	<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		      	<td>{ this.formatDate(item.created_at) }</td>
		      </tr>
		    )
		    break;

		  case 1:
		    return (
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>{ this.shortAddress(item.from) } sent <b className="text-info">{numFormat(item.amount, 7)} { getAsset(item) } </b> to { this.shortAddress(item.to) }</td>
		        <td><b className="text-info">{ this.itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		        <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 2:
		    return this.renderPathPayment(item, index)
		    break;

			case 13:
				return this.renderPathPayment(item, index)
		    break;

		  case 3:
		    return (
	        <tr key={index}>
	        	<th><small>{index+1}</small></th>
	        	<td>
	        		<div>Offer id: { (Number(item.offer_id) > 0) ? <b className="text-info">{item.offer_id}</b> : <b className={'text-success'}>filled</b> }</div>
							<div>Buying: <b className="text-info">{numFormat(item.amount * item.price, 7)}</b> <a href={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></a></div>
	        		<div>Selling: <b className="text-info">{numFormat(item.amount, 7)}</b> <a href={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></a> { parseFloat(item.amount) ? '' : '(cancelled)' }</div>
							<div>Price: <b className="text-info">{numFormat(item.price, 7)}</b> <a href={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></a></div>
	        	</td>
	            <td><b className="text-info">{ this.itemType(item.type) }</b></td>
	            <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
	            <td>{this.formatDate(item.created_at)}</td>
	        </tr>
		    )
		    break;

			case 12:

				return (
					<tr key={index}>
						<th><small>{index+1}</small></th>
						<td>
							<div>Offer id: { (Number(item.offer_id) > 0) ? <b className="text-info">{item.offer_id}</b> : <b className={'text-success'}>filled</b> }</div>
							<div>Buying: <b className="text-info">{numFormat(item.amount, 7)}</b> <a href={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></a></div>
							<div>Selling: <b className="text-info">{numFormat(item.amount * item.price, 7)}</b> <a href={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></a> { parseFloat(item.amount) ? '' : '(cancelled)' }</div>
							<div>Price: <b className="text-info">{numFormat(item.price, 7)}</b> <a href={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></a></div>
						</td>
							<td><b className="text-info">{ this.itemType(item.type) }</b></td>
							<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
							<td>{this.formatDate(item.created_at)}</td>
					</tr>
				)
				break;

		  case 4:
		    return (
	        <tr key={index}>
	        	<th><small>{index+1}</small></th>
	        	<td>
							<div>Offer id: { (Number(item.offer_id) > 0) ? <b className="text-info">{item.offer_id}</b> : <b className={'text-success'}>filled</b> }</div>
							<div>Buying: <b className="text-info">{numFormat(item.amount * item.price, 7)}</b> <a href={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></a></div>
	        		<div>Selling: <b className="text-info">{numFormat(item.amount, 7)}</b> <a href={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></a> { parseFloat(item.amount) ? '' : '(cancelled)' }</div>
							<div>Price: <b className="text-info">{numFormat(item.price_r.d / item.price_r.n, 7)}</b> <a href={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></a></div>
	        	</td>
	            <td><b className="text-info">{ this.itemType(item.type) }</b></td>
	            <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
	            <td>{this.formatDate(item.created_at)}</td>
	        </tr>
		    )
		    break;

		  case 5:
		    return (
	        <tr key={index}>
	        	<th><small>{index+1}</small></th>
	        	<td>
	        		{ item.home_domain ? this.renderHomeDomain(item.home_domain) : '' }
	        		{ item.signer_key ? this.renderSigner(item.signer_key, item.signer_weight) : '' }

	        		{ item.low_threshold >= 0 ? this.renderLow(item.low_threshold) : '' }
	        		{ item.med_threshold >= 0 ? this.renderMed(item.med_threshold) : '' }
	        		{ item.high_threshold >= 0 ? this.renderHigh(item.high_threshold,) : '' }
	        		{ item.master_key_weight >= 0 ? this.renderMaster(item.master_key_weight) : '' }

	        		{ item.inflation_dest ? this.renderInfl(item.inflation_dest) : '' }
	        		{ item.set_flags_s ? this.renderSetFlags(item.set_flags_s) : '' }
	        		{ item.clear_flags_s ? this.renderClearFlags(item.clear_flags_s) : ''}
	        	</td>
	            <td><b className="text-info">{ this.itemType(item.type) }</b></td>
	            <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
	            <td>{this.formatDate(item.created_at)}</td>
	        </tr>
		    )
		    break;


		  case 6: // change_trust
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td><p>{shortAddress(item.source_account)} {parseFloat(item.limit) ? <span>created <b>trustline</b> for </span> : <span>removed <b>trustline</b>  for </span> } { item.liquidity_pool_id ? shortPool(item.liquidity_pool_id) : getAsset(item) }</p></td>
		      	<td><b className="text-info">{ this.itemType(item.type) }</b></td>
		      	<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		      	<td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 7:
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>For { getAsset(item) } set <b className="text-info"> ALLOW TRUST </b> to <b className="text-info"> { item.authorize ? ' true ' : ' false ' } </b> for { this.shortAddress(item.trustor) }</td>
		        <td><b className="text-info">{ this.itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		        <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 8:
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>{ this.shortAddress(item.source_account) }  deleted and merge with { this.shortAddress(item.into) }</td>
			    <td><b className="text-info">{ this.itemType(item.type) }</b></td>
			    <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
			    <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 9:
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>Inflation</td>
			    <td><b className="text-info">{ this.itemType(item.type) }</b></td>
			    <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
			    <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 10:
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>
		      		<div>{ item.value ? 'Create or modify data' : 'Remove data' }</div>
		      		<div>Name: <b className="text-info"> { item.name }</b></div>
		      		<div>{ item.value ? 'Value: ' : '' } <b className="text-info"> { item.value ? item.value : '' }</b></div>
		      	</td>
		        <td><b className="text-info">{ this.itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		        <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

		  case 11:
		    return(
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>BumpTo {item.bump_to}</td>
		        <td><b className="text-info">{ this.itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		        <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;

			case 14:
				return(
					<tr key={index}>
						<th><small>{index+1}</small></th>
						<td>
							{shortAddress(item.source_account)} sponsors <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item.asset)} {' '}

							to: [ {' '}
							{
								item.claimants.map((claimant, index) => {
									return (
										<span key={index}>{shortAddress(claimant.destination)}{item.claimants.length > index+1 ? ', ' : null}</span>
									)
								})
							}
							]
						</td>
						<td><b className="text-info">{ this.itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
						<td>{this.formatDate(item.created_at)}</td>
					</tr>
				)
				break;

			case 15:
				return(
					<tr key={index}>
						<th><small>{index+1}</small></th>
						<td>Claimant {shortAddress(item.claimant)}</td>
						<td><b className="text-info">{ this.itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
						<td>{this.formatDate(item.created_at)}</td>
					</tr>
				)
				break;

			case 16: // begin_sponsoring_future_reserves
              return(
                <tr key={index}>
                    <th><small>{index+1}</small></th>
                    <td>{ this.shortAddress(item.source_account) } started sponsoring for { this.shortAddress(item.sponsored_id) }</td>
                    <td><b className="text-info">{ this.itemType(item.type) }</b></td>
                    <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
                    <td>{this.formatDate(item.created_at)}</td>
                </tr>
              )
              break;

          case 17: // end_sponsoring_future_reserves
            return(
              <tr key={index}>
                <th><small>{index+1}</small></th>
                <td>{ this.shortAddress(item.source_account) } stopped sponsoring for { this.shortAddress(item.begin_sponsor) }</td>
                <td><b className="text-info">{ this.itemType(item.type) }</b></td>
                <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
                <td>{this.formatDate(item.created_at)}</td>
              </tr>
            )
            break;

          case 19: // clawback
            return(
              <tr key={index}>
                <th><small>{index+1}</small></th>
                <td>{ this.shortAddress(item.source_account) } clawback <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item, '', 'orange')} from { this.shortAddress(item.from) }</td>
                <td><b className="text-info">{ this.itemType(item.type) }</b></td>
                <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
                <td>{this.formatDate(item.created_at)}</td>
              </tr>
            )
            break;

          case 20: // clawback_claimable_balance
            return(
              <tr key={index}>
                <th><small>{index+1}</small></th>
                <td>{ this.shortAddress(item.source_account) } clawback claimable balance <Link to={`/claimable-balance/${item.balance_id}`}><b>{shortAddr(item.balance_id)}</b></Link></td>
                <td><b className="text-info">{ this.itemType(item.type) }</b></td>
                <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
                <td>{this.formatDate(item.created_at)}</td>
              </tr>
            )
            break;


			case 22: // liquidity_pool_deposit

				return(
					<tr key={index}>
						<th><small>{index+1}</small></th>
						<td>
							<div className={'min-details'}>{shortAddress(item.source_account)} <b className={'text-success'}>deposited</b> <b className="text-info">{numFormat(item.reserves_deposited[0].amount, 7)}</b> {getAsset(item.reserves_deposited[0].asset)} / <b className="text-info">{numFormat(item.reserves_deposited[1].amount, 7)}</b> {getAsset(item.reserves_deposited[1].asset)} (<b className="text-info">{numFormat(item.shares_received, 7)}</b> <b>SHARES</b>)</div>
							<div>to {shortPool(item.liquidity_pool_id)}</div>
						</td>
						<td><b className="text-info">{ this.itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
						<td>{this.formatDate(item.created_at)}</td>
					</tr>
				)
				break;

			case 23: // liquidity_pool_withdrew

				// const income_a = (item.reserves_received[0].amount - item.reserves_min[0].amount).toFixed(7)
				// const income_b = (item.reserves_received[1].amount - item.reserves_min[1].amount).toFixed(7)
				// const income_percent_a = (item.reserves_received[0].amount * income_a / 100).toFixed(2)
				// const income_percent_b = (item.reserves_received[1].amount * income_b / 100).toFixed(2)

				return(
					<tr key={index}>
						<th><small>{index+1}</small></th>
						<td>
							<div className={'min-details'}>{shortAddress(item.source_account)} <b className={'text-danger'}>withdrew</b> <b className="text-info">{numFormat(item.reserves_received[0].amount, 7)}</b> {getAsset(item.reserves_received[0].asset)} / <b className="text-info">{numFormat(item.reserves_received[1].amount, 7)}</b> {getAsset(item.reserves_received[1].asset)} (<b className="text-info">{numFormat(item.shares, 7)}</b> <b>pool shares</b>)</div>
							<div>from liquidity pool {shortPool(item.liquidity_pool_id)}</div>
							{/*<div>income <span className={'text-success'}><b>+{numFormat(income_a, 7)}</b></span> {getAsset(item.reserves_received[0].asset)} <small>(<span className={'text-success'}>+{income_percent_a}%</span>)</small> / <span className={'text-success'}><b>+{numFormat(income_b, 7)}</b></span>  {getAsset(item.reserves_received[1].asset)} <small>(<span className={'text-success'}>+{income_percent_b}%</span>)</small></div>*/}
						</td>
						<td><b className="text-info">{ this.itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
						<td>{this.formatDate(item.created_at)}</td>
					</tr>
				)
				break;



		  default:
		    console.log('default operation', item);

		    return (
		      <tr key={index}>
		      	<th><small>{index+1}</small></th>
		      	<td>Coming soon</td>
		        <td><b className="text-info">{ this.itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
		        <td>{this.formatDate(item.created_at)}</td>
		      </tr>
		    )
		    break;
		}
	}


	render() {

		const operations = this.props.items;
		// console.log('operations: ', operations)

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
			    	<th>#</th>
			      <th>Details</th>
			      <th>Type</th>
			      <th>Operation</th>
			      <th>Date</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ operations.map(this.renderOperaion.bind(this)) }

			  </tbody>
			</Table>
		)
	}
}

Operations.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Operations);
