/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { shortAddr, numFormat, formatDate, get_loc_wallets, getNameAddr } from '../../../modules/index';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
// import iconIdent from '../../Pages/Account/components/iconIdent';


const checkMyAccount = (address) => {
  const wallets = get_loc_wallets();

  let check = false;

  if (wallets) {
    wallets.forEach((item, index) => {
      if (item.pk === address) {
        check = true;
      }
    })
  }

  return check;
}

const shortAddress = (address) => {

  const name = getNameAddr(address);
  return (
    <a href={`/account/${address}`}><b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
  )
}


const getAsset = (item) => {

  return (
    <a href={(item.asset_type === 'native' ? '/asset/XLM-native' : /asset/+item.asset_code+'-'+item.asset_issuer)}>
      <b className="text-warning">{ item.asset_type === 'native' ? 'XLM' : item.asset_code }</b>
    </a>
  )
}


const getCounterAsset = (item) => {
  return (
    <a href={'/asset/'+(item.counter_asset_type === 'native' ? 'XLM-native' : item.counter_asset_code + '-' + item.counter_asset_issuer)}>
    <b className="text-warning">{ item.counter_asset_type === 'native' ? 'XLM' : item.counter_asset_code }</b>
    </a>
    )
}


const getBaseAsset = (item) => {
  return (
    <a href={'/asset/'+(item.base_asset_type === 'native' ? 'XLM-native' : item.base_asset_code + '-' + item.base_asset_issuer)}>
    <b className="text-warning">{ item.base_asset_type === 'native' ? 'XLM' : item.base_asset_code }</b>
    </a>
  )
}





class TopbarNotification extends PureComponent {
    state = {
      collapse: false,
    };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };


  clearNotifs = () => {

    localStorage.setItem('notifications', []);

    this.toggle();
  }


  getAsset(item) {

    return (
      <Link to={(item.asset_type === 'native' ? '/asset/XLM-native' : /asset/+item.asset_code+'-'+item.asset_issuer)}>
        <b className="text-warning">{ item.asset_type === 'native' ? 'XLM' : item.asset_code }</b>
      </Link>
    )
  }



  render() {

    let icon, nl = false;
    let notifications = localStorage.getItem('notifications');

    if (notifications) {
      notifications = JSON.parse(notifications);
      nl = notifications.length
    }


    return (
      <div className="topbar__collapse">
        <button className="topbar__btn" onClick={this.toggle} style={{position: 'relative'}}>
          <NotificationsIcon />
          {notifications && <span style={{padding: `${nl > 9 ? '2px' : '2px 4px'}`}} className={'icont-count-notif'}>{nl < 99 ? nl : 99}</span>}

        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse
          isOpen={this.state.collapse}
          className="topbar__collapse-content"
        >
          <div className="topbar__collapse-title-wrap">
            <p className="topbar__collapse-title">Notifications <small>(<b>{notifications.length}</b>)</small></p>
            <button className="topbar__collapse-button"
                    onClick={this.clearNotifs}><i className="fa fa-check"></i> Mark all as read</button>
          </div>


          <PerfectScrollbar style={{maxHeight: '400px'}}>
            {
              notifications ? notifications.map((item, index) => {

                if (!item) return

                if (item.type === 'payment') icon = 'send-o';
                if (item.type === 'trade') icon = 'exchange';

                return (
                  <div className="topbar__collapse-item" key={index} onClick={this.toggle}>
                    <div className="topbar__collapse-img-wrap">
                      <i className={`fa fa-${icon} text-secondary`}></i>
                    </div>

                    {
                      item.type === 'payment' ?
                        <div>
                          <p className="topbar__collapse-message">
                            My Account <a href={`/account/${item.to}`}><b>{shortAddr(item.to, 4)}</b></a>&nbsp;
                            <font className="text-success">received</font> <b className="text-info">{numFormat(item.amount, 7)} {this.getAsset(item)}</b> from the &nbsp;
                            <a href={`/account/${item.from}`}><b>{shortAddr(item.from, 4)}</b></a>.
                          </p>
                          <small className="text-secondary">Hash: <a href={`/transaction/${item.transaction_hash}`}><b>{shortAddr(item.transaction_hash, 4)}</b></a> / {moment(item.created_at).format("DD MMMM YYYY HH:mm:ss")}</small>
                        </div>
                      : null
                    }

                    {
                      item.type === 'trade' ?
                        <div>
                          <p className="topbar__collapse-message">
                            {
                              item.base_is_seller ?
                                <span>{checkMyAccount(item.counter_account) ? 'My Account' : ''} {shortAddress(item.counter_account)} <font className="text-danger">sold</font> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { getCounterAsset(item) } / <font className="text-success">bought</font> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { getBaseAsset(item) } from {checkMyAccount(item.counter_account) ? 'My Account' : ''} { shortAddress(item.base_account) } </span>
                              :
                                <span>{checkMyAccount(item.base_account) ? 'My Account' : ''} {shortAddress(item.base_account)} <font className="text-success">bought</font> <b className="text-info">{numFormat(item.counter_amount, 7)}</b> { getCounterAsset(item) } / <font className="text-danger">sold</font> <b className="text-info">{numFormat(item.base_amount, 7)}</b> { getBaseAsset(item) } to {checkMyAccount(item.counter_account) ? 'My Account' : ''} {shortAddress(item.counter_account)}</span>
                            }
                          </p>
                          <small className="text-secondary">Offer: <a href={`/offer/${item.offer_id}`}><b>{item.offer_id}</b></a> / Price: <b className={item.base_is_seller ? 'text-success' : 'text-danger'}>{numFormat((item.price.d / item.price.n), 7)}</b> / {formatDate(item.ledger_close_time)}</small>
                        </div>
                      : null
                    }

                  </div>
                )

              }) : null
            }
          </PerfectScrollbar>

          <a href="#" className="topbar__collapse-link" onClick={this.clearNotifs}>
            <i className="fa fa-check"></i> Mark all as read
          </a>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.customizer.notifications
  }
}


export default connect(mapStateToProps)(TopbarNotification)