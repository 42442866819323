import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import StellarSdk from 'stellar-sdk';
import { ToastContainer, ToastStore } from 'react-toasts';
import TextField from '@material-ui/core/TextField';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
// import LoadingIcon from 'mdi-react/LoadingIcon';
import CryptoJS from 'crypto-js';
import {get_loc_wallets, getTitle, setLocalWallets} from '../../../../modules/index';
import swal from 'sweetalert';
import freighterApi from "@stellar/freighter-api";
import albedo from '@albedo-link/intent'
// import scopuly from '@scopuly/intent'
import LoadingIcon from "mdi-react/LoadingIcon";
import {toggleAuthProvider} from "../../../../redux/actions/customizerActions";
import {connect} from "react-redux";
import slugify from "@sindresorhus/slugify";
const redshift = require('@stellar-fox/redshift');


const renderTextField = ({
  input, label, type, multiline, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      select={select}
      type={type}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  type: false,
  multiline: false,
  children: [],
  redirect_to_wallet: false,
};




class RestoreAccount extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      setLocalStorage: false,
      wallets: get_loc_wallets(),
      phrase_length: false,
      menu: true,
      loading: false,
    };
  }

  componentDidMount() {

    const title = 'Connect Stellar Wallet to the web interface';
    const description = 'Connecting a Stellar account to the Scopuly web app. There are various methods available to authorize your wallet in Scopuly. Connection to Scopuly, Albedo, Freighter, Rabet.';
    getTitle(title, description);

    this.switchHash()
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab);
  }


  switchHash = () => {

    switch(window.location.hash) {
      case '#phrase':
        this.toggle('1');
        break
      case '#secret':
        this.toggle('2');
        break
      case '#token':
        this.toggle('3');
        break
      default:
        break
    }

    const hash = window.location.hash
    if (hash === '#token' || hash === '#phrase' || hash === '#secret') {
      this.setState({ menu: false })
    }
  }

  toggleHash = (tab) => {
    switch(tab) {
      case '1':
        window.location.hash = 'phrase';
        break
      case '2':
        window.location.hash = 'secret';
        break
      case '3':
        window.location.hash = 'token';
        break
      default:
        break
    }
  }


  formRecoveryToken(e) {

    var token = e.recovery_token;
    var pass = e.password;
    var plaintext;

    if (!token) {
      ToastStore.warning('Paste your recovery token');
    }
    else if (!pass) {
      ToastStore.warning('Enter your recovery password');
    }
    else {
      // restore
      var token_substring;
      var firstchar = token.substring(0,1);
      if (firstchar === '<') {
        token_substring = token.substring(9);
        token_substring = token_substring.substring(0, token_substring.length - 1);
      }
      else {
        token_substring = token.substring(8);
      }

      // Decrypt
      var bytes  = CryptoJS.AES.decrypt(token_substring.toString(), pass)

      try {
          plaintext = bytes.toString(CryptoJS.enc.Utf8);

          if (plaintext) {
              var parse_account = JSON.parse(window.atob(plaintext));

              this.authAccount(parse_account);
          }
          else {
            // alert('lang.account.could_not_restore_wallet')
            ToastStore.error('Invalid Restore Token or Password');
          }
      }
      catch (err) {
          console.log('err: ', err);
          plaintext = '';
          // app.dialog.alert(lang.account.password_does_not_match_mess, lang.account.password_does_not_match);
          ToastStore.error('Invalid Restore Token or Password');
      }
      // console.log('plaintext1: ', plaintext);
    }
  }


  formRestorePhrase = (values) => {

    const phrase = values.recovery_phrase;
    const pass = values.phrase_password;
    const public_key = values.public_key

    if (!public_key) {
      ToastStore.warning('Enter your Public Key');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(public_key)) {
      ToastStore.error('The entered Address is not valid');
    }
    else if (!values.recovery_phrase) {
      ToastStore.warning('Enter your Recovery Phrase');
    }
    else if (!values.phrase_password) {
      ToastStore.warning('Enter your Recovery Password');
    }
    else {
      const phrase_split = phrase.split(' ');

      if (phrase_split.length === 24) {

        const restore = redshift.restoreAddress(phrase, pass)
        const publicKey = restore.keypair.publicKey()
        const secretKey = restore.keypair.secret()
        const ciphertext = CryptoJS.AES.encrypt(secretKey, publicKey);

        if (public_key === publicKey) {
          const account = {
            pk: publicKey,
            sk: btoa(ciphertext),
            title: 'My Wallet',
            type: 'encrypt',
          }

          this.authAccount(account);
        }
        else {
          ToastStore.warning('Invalid authorization data. Make sure you enter everything correctly.');
        }
      }
      else {
        ToastStore.warning('Invalid recovery phrase format. Must be 24 words separated by spaces.');
      }
    }
  }


  formSecretKey(e) {

    var sk = e.secret_key;
    var valid_sk = StellarSdk.StrKey.isValidEd25519SecretSeed(sk);

    if (!sk) {
      ToastStore.warning('Paste your Secret Key');
    }
    else if (!valid_sk) {
      ToastStore.error('Invalid Secret Key');
    }
    else {

      swal({
        title: 'Local wallet name',
        text: `Enter a local name for this account. Optional.`,
        icon: 'info',
        content: "input",
      })
        .then(value => {

          var sourceKeypair = StellarSdk.Keypair.fromSecret(sk);
          var sourcePublicKey = sourceKeypair.publicKey();

          var ciphertext = CryptoJS.AES.encrypt(sk, sourcePublicKey);

          var account = {
            pk: sourcePublicKey,
            sk: btoa(ciphertext),
            title: value ? slugify(value) : 'My Wallet',
            type: 'encrypt',
            provider: '',
          }

          this.authAccount(account);
        })
    }
  }


  authAccount(account) {

    let wallets = this.state.wallets;

    const setLocalStorage = (wallets) => {
      localStorage.setItem('wallets', window.btoa(JSON.stringify(wallets)));

      swal({
        title: "Connected!",
        text: 'Account successfully connected to Scopuly',
        icon: "success",
        confirm: true,
      })
      .then(confirm => {
        if (confirm) {
          // window.location.href =  `${window.location.origin}/wallet`;
          this.setState({ redirect_to_wallet: true })
        }
      });
    }

    if (!wallets) {
      wallets = [];
      wallets.push(account);
      setLocalStorage(wallets);

      localStorage.setItem('2fa', 'false');
    }
    else {

      var isset_wallet = wallets.some((item) => {
        return account.pk === item.pk;
      });

      if (!isset_wallet) {

        wallets.push(account);

        setLocalStorage(wallets);
      }
      else {
        // alert('lang.account.wallet_is_authorized')
        ToastStore.success('This Address is already authorized');
      }
    }
  }


  changePhrase(value) {
    // console.log('value: ', value)

    let string = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        string = string + value[key];
      }
    }
    // console.log('string: ', string)

    this.setState({
      phrase_length: string.split(' ').length,
    })
  }


  selectAuth = (value) => {

    if (value === 'token' || value === 'phrase' || value === 'secret') {
      this.setState({ menu: false })
      window.location.hash = value
    }

    if (value === 'phrase') {
      this.setState({ activeTab: '1' })
    }
    else if (value === 'secret') {
      this.setState({ activeTab: '2' })
    }
    else if (value === 'token') {
      this.setState({ activeTab: '3' })
    }
    else {
      this.checkProvider(value)
    }
  }


  checkProvider = (value) => {

    const provider = value[0].toUpperCase() + value.slice(1)
    let isProvider = false;

    // console.log('provider: ', provider)

    if (this.state.wallets) {
      isProvider = this.state.wallets.some(item => item.provider && item.provider === value)
    }

    if (isProvider) {
      swal({
        title: "Already connected!",
        text: `${provider} extension is already connected`,
        icon: "success",
        buttons: {
          confirm: true,
        },
      })
      return
    }

    swal({
      title: "Connection",
      text: `Connect the ${provider} extension right now?`,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {

          // this.setState({ loading: true })

          // if (value === 'scopuly') {
          //   this.connectScopuly(provider)
          // }
          if (value === 'freighter') {
            this.connectFreighter(provider)
          }
          if (value === 'albedo') {
            this.connectAlbedo(provider)
          }
          if (value === 'rabet') {
            this.connectRabet(provider)
          }
        }
      })
  }


  // connectScopuly = (provider) => {
  //
  //   scopuly.publicKey()
  //     .then(result => {
  //       // console.log('scopuly res: ', result)
  //
  //       this.setLocalStorage(result.pubkey, 'scopuly')
  //     })
  //     .catch(error => {
  //       // console.log('scopuly error: ', error)
  //       ToastStore.error(error.message);
  //     })
  // }


  connectRabet = (provider) => {

    const rabet = window.rabet
    if (rabet) {
      console.log('Rabet is installed!');

      rabet.connect()
        .then(result => {
          this.setLocalStorage(result.publicKey, 'rabet')
        } )
        .catch(error => {
          console.error(`Error: `, error)

          if (error.error === 'no-account') {
            swal({
              title: "Account not authorized",
              text: `To connect to ${provider}, authorize the account in the extension`,
              icon: "warning",
              buttons: {
                confirm: true,
              },
            })
          }
          else {
            ToastStore.error(error.error);
          }
        });
    }
    else {
      swal({
        title: "Not installed",
        text: `To connect, add the ${provider} extension to your browser`,
        icon: "warning",
      })
    }
  }


  connectAlbedo = (provider) => {

    albedo.publicKey()
      .then(result => {
        // console.log('albedo res: ', result)

        this.setLocalStorage(result.pubkey, 'albedo')
      })
      .catch(error => {
        console.log('albedo error: ', error)
        // this.setState({ loading: false })
        ToastStore.error(error.message);
      })
  }


  connectFreighter = (provider) => {

    if (freighterApi.isConnected()) {

      const retrievePublicKey = async () => {
        let publicKey = "";
        let error = "";

        try {
          publicKey = await freighterApi.getPublicKey();
        } catch (e) {
          error = e;
        }

        if (error) {
          return error;
        }

        return publicKey;
      };

      retrievePublicKey()
        .then(result => {

          if (result === 'User declined access') {
            ToastStore.warning('User declined access');
            return
          }

          this.setLocalStorage(result, 'freighter')
      })
        .catch(error => {
          console.log('retrievePublicKey error: ', error)
          ToastStore.error(error.toString());
      })
    }
    else {
      this.showErrorConnect(provider)
    }
  }


  showErrorConnect = (provider) => {
    swal({
      title: 'Extension not found',
      text: `Check if the ${provider} extension is installed in your browser`,
      icon: 'warning',
    });
  }


  setLocalStorage = (result, provider) => {

    let { wallets } = this.state
    // console.log('wallets: ', wallets)

    const connectWallet = {
      provider,
      account_id: result
    }

    const account = {
      pk: result,
      sk: 'secret',
      title: `${provider[0].toUpperCase() + provider.slice(1)} Wallet`,
      type: 'encrypt',
      provider: provider,
    }

    wallets = wallets ? wallets.filter(item => !item.provider) : []
    // console.log('wallets filter: ', wallets)

    wallets.push(account);

    setLocalWallets(wallets)
    localStorage.setItem('2fa', 'false');

    localStorage.setItem('connect_provider', JSON.stringify(connectWallet))

    setTimeout(() => {
      swal({
        title: "Connected!",
        text: `You are connected to the wallet extension.`,
        icon: "success",
        buttons: {
          confirm: true,
        },
      }).then(() => {
        this.props.dispatch(toggleAuthProvider(connectWallet))

        // setTimeout(() => this.setState({ redirect_to_wallet: true }), 1000)
        window.location = window.location.origin + '/wallet'
      })
    }, 500)
  }



  render() {
    // console.log('restore props: ', this.props)
    // console.log('restore state: ', this.state)

    const {reset, handleSubmit} = this.props;

    if (this.state.redirect_to_wallet) {
      return <Redirect to='/wallet' />
    }

    return (

      <Col md={{ size: 6, offset: 3 }}>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Card>
          <CardBody style={{paddingBottom: 0}}>

            {this.state.loading ? <div className="panel__refresh"><LoadingIcon /></div> : ''}

            {/*<div className="card__title">
              <h5 className="bold-text">Restore Account</h5>
            </div>*/}

            {
              this.state.menu ?
                <div className={'text-center login-btns'}>
                  <br/>
                  <h4>Connect Wallet</h4>
                  <br/><br/>
                  <Row>
                    <Col md={{size: 4, offset: 2}}>
                      <h5 className={'title-auth-block'}>Browser extensions</h5>
                      {/*<div><Button className={'btn btn-outline-secondary'}*/}
                      {/*             disabled={false}*/}
                      {/*             onClick={() => this.selectAuth('scopuly')}>*/}
                      {/*  <img src="/img/logo/icon-transtparent-512.png" style={{width: '13%', marginRight: 2}} alt="Scopuly"/> Scopuly</Button>*/}
                      {/*</div>*/}
                      <div><Button className={'btn btn-outline-secondary'}
                                   disabled={false}
                                   onClick={() => this.selectAuth('freighter')}>
                        <img src="/img/icons/auth/freighter.svg" alt="Freighter"/> Freighter</Button>
                      </div>
                      <div><Button className={'btn btn-outline-secondary'}
                                   disabled={false}
                                   onClick={() => this.selectAuth('albedo')}>
                        <img src="/img/icons/auth/albedo.svg" alt="Albedo" /> Albedo</Button>
                      </div>
                      <div><Button className={'btn btn-outline-secondary'}
                                   disabled={false}
                                   onClick={() => this.selectAuth('rabet')}>
                        <img src="/img/icons/auth/rabet.svg" style={{width: '8%'}} alt="" disabled/> Rabet</Button>
                      </div>
                    </Col>

                    <Col md={4}>
                      <h5 className={'title-auth-block'}>Connect directly</h5>
                      <div><Button className={'btn btn-outline-secondary'}
                                   onClick={() => this.selectAuth('phrase')}>
                        <img src="/img/icons/auth/phrase.png" style={{width: '14%'}} alt="Pass Phrase"/> Pass Phrase
                      </Button></div>
                      <div><Button className={'btn btn-outline-secondary'}
                              onClick={() => this.selectAuth('secret')}>
                        <img src="/img/icons/auth/keyword.svg" style={{width: '11%'}} alt="Secret Key"/> Secret Key
                      </Button></div>
                      <div><Button className={'btn btn-outline-secondary'}
                                   onClick={() => this.selectAuth('token')}>
                        <img src="/img/icons/auth/token.png" style={{width: '13%'}} alt="Recovery Token"/> Recovery Token
                      </Button></div>
                    </Col>
                  </Row>
                  <br/><br/><br/>
                </div>
                :
                <div className="tabs tabs--justify tabs--bordered-bottom">
                  <a href="#"
                     onClick={() => this.setState({ menu: true })}
                     style={{padding: '8px 11px 8px 5px'}}>
                      <i className="fa fa-chevron-left" aria-hidden="true"></i>
                  </a>
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>Pass Phrase</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          <b>Secret Key</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>Recovery Token</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                      <TabPane tabId="1">
                        <Card>
                          <CardBody>
                            <div className="card__title">
                              <h5 className="subhead">Recovery from Pass Phrase</h5>
                            </div>

                            <form className="material-form" onSubmit={handleSubmit(this.formRestorePhrase)}>
                              <div>
                                <Field
                                  name="public_key"
                                  component={renderTextField}
                                  placeholder="Public Key (Address)"
                                  label="Public Key"
                                  multiline
                                />
                              </div>

                              <div>
                                <Field
                                  name="recovery_phrase"
                                  component={renderTextField}
                                  placeholder="Recovery phrase"
                                  multiline
                                  label={`Phrase ${this.state.phrase_length ? `(woords: ${this.state.phrase_length} / 24)` : ''}`}
                                  onChange={(value) => this.changePhrase(value)}
                                />
                              </div>

                              <div>
                                <Field
                                  name="phrase_password"
                                  component={renderTextField}
                                  placeholder="Recovery password"
                                  label="Password"
                                  type="password"
                                />
                              </div>
                              <br/>
                              <ButtonToolbar className="form__button-toolbar">
                                <Button onClick={reset}>Reset</Button>
                                <Button color="primary" type="submit">Connect Wallet</Button>
                              </ButtonToolbar>
                            </form>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="2">
                        <Card>
                          <CardBody>

                            <div className="card__title">
                              <h5 className="subhead">Secret Key</h5>
                            </div>

                            <form className="material-form" onSubmit={handleSubmit(this.formSecretKey.bind(this))}>

                              <div>
                                <Field
                                  name="secret_key"
                                  component={renderTextField}
                                  placeholder="Your secret key"
                                  label="Secret Key"
                                  type="password"
                                />
                              </div>
                              <br/>
                              <ButtonToolbar className="form__button-toolbar">
                                <Button type="button" onClick={reset}>Reset</Button>
                                <Button color="primary" type="submit">Connect Wallet</Button>
                              </ButtonToolbar>
                            </form>
                          </CardBody>
                        </Card>
                      </TabPane>

                      <TabPane tabId="3">
                        <Card>
                          <CardBody>
                            <div className="card__title">
                              <h5 className="subhead">Recovery Token</h5>
                            </div>

                            <form className="material-form" onSubmit={handleSubmit(this.formRecoveryToken.bind(this))}>
                              <div>
                                <Field
                                  name="recovery_token"
                                  component={renderTextField}
                                  placeholder="Recovery token"
                                  multiline
                                  label="Recovery Token"
                                />
                              </div>

                              <div>
                                <Field
                                  name="password"
                                  component={renderTextField}
                                  placeholder="Recovery password"
                                  label="Recovery Password"
                                  type="password"
                                />
                              </div>
                              <br/>
                              <ButtonToolbar className="form__button-toolbar">
                                <Button onClick={reset}>Reset</Button>
                                <Button color="primary" type="submit">Connect Wallet</Button>
                              </ButtonToolbar>
                            </form>
                          </CardBody>
                        </Card>
                      </TabPane>

                    </TabContent>
                  </div>
                </div>
            }

          </CardBody>
        </Card>
      </Col>
    );
  }
}


RestoreAccount.propTypes = {
  t: PropTypes.func.isRequired,
};


// export default reduxForm({
//   form: 'floating_labels_form',
// })(translate('common')(RestoreAccount));

const mapStateToProps = (state) => {
  return {
    isAuthProvider: state.customizer.isAuthProvider
  }
}

export default connect(mapStateToProps)(translate('common')(reduxForm({ form: 'restore_form' })(RestoreAccount)));
