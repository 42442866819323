import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonToolbar, Modal } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Server } from '../../../modules/stellar/index';
import StellarSdk from 'stellar-sdk';
import { shortAddr, numFormat, get_loc_wallets, baseReserve, getAuth, needDeauthAll, getTitle, getNameAddr } from '../../../modules/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import moment from 'moment';
import Trades from '../Account/components/Effects/Trades';
import Offers from '../Account/components/Effects/Offers';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import RequiredSigners from '../Auth/components/RequiredSigners';
import iconIdent from "../Account/components/iconIdent";
import {CopyToClipboard} from "react-copy-to-clipboard";



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);




class Offer extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      modal: false,
      trades: [],
      load_trades: false,
      account: false,
      load_account: false,
      offer: [],
      load_offer: false,
      not_exist_offer: false,
      not_exist_trades: false,
      account_id: false,
      my_address: false,
      trade_type: '',


      wallets: get_loc_wallets(),
    };

  }



  componentDidMount() {

    const offer_id = this.props.match.params.id

    getTitle(`Stellar Offer: ${offer_id}`);

    this.getTradesForOffer(offer_id);
    this.getOffer(offer_id)
  }


  getOffer = (offer_id) => {
    Server
      .offers()
      .offer(offer_id)
      .call()
      .then((offer) => {
        console.log('testOffers: ', offer);

        let trade_type, base_asset_code;
        if (offer) {

          if (offer.price_r.n >= offer.price_r.d) {
            trade_type = 'Buy';
            if (offer.selling.asset_type === 'native') {
              base_asset_code = 'XLM';
            } else {
              base_asset_code = offer.selling.asset_code;
            }
          }
          else {
            trade_type = 'Sell';
            if (offer.buying.asset_type === 'native') {
              base_asset_code = 'XLM';
            } else {
              base_asset_code = offer.buying.asset_code;
            }
          }

          const account_id = offer.seller

          this.setState({
            offer: [offer],
            load_offer: true,
            account_id,
            trade_type,
            base_asset_code,
          });

          this.getAccount(account_id, base_asset_code)
        }
      })
      .catch( (err) => {
        console.error('err: ', err);
        this.setState({ not_exist_offer: true })
      });
  }



  getAccount(account_id, asset_code) {

    Server.accounts()
      .accountId(account_id)
      .call()
      .then(accountResult => {

        let native_balance, counter_balance, reserve_balance;
        accountResult.balances.forEach((item) => {

          if (item.asset_type === 'native') {
            native_balance = item.balance;
            reserve_balance = (accountResult.subentry_count * baseReserve())
          }
          else if (item.asset_code === asset_code) {
            counter_balance = item.balance;
          }
        })

        const wallets = this.state.wallets;

        if (wallets) {
          wallets.forEach((item, index) => {
            if (item.pk === account_id) {
              this.setState({
                my_address: true,
              })
            }
          })
        }
      })
      .catch(err => {
        console.error(err);
    })
  }


  getTradesForOffer(offer_id) {

    Server.trades()
      .forOffer(offer_id)
      .order('desc')
      .call()
      .then((result) => {

        if (result.records.length) {
          this.setState({
            trades: result.records,
            load_trades: true,
          });
        }
        else {
          this.setState({
            not_exist_trades: true ,
          })
        }
      })
      .catch((err) => {
        console.log(err)
        this.setState({ loader: false, });
    })
  }



  render() {

    // console.log('state: ', this.state)

    const offer_id = this.props.match.params.id;
    const { account_id, not_exist_offer, not_exist_trades, load_offer, load_trades, trades, offer, my_address } = this.state;

    var name = false;
    if (account_id) {
      name = getNameAddr(account_id);
    }


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.state.account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={this.state.type_tx} />
          : null
        }


        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{textTransform: 'none'}}>Offer ID: <b className="text-info">{ offer_id }</b> &emsp;
              <CopyToClipboard text={offer_id} onCopy={() => {}}>
                <i className="fa fa-clone"
                   color="secondary"
                   style={{cursor: 'pointer', fontSize: 14}}
                   onClick={() => ToastStore.success('Copied!')}></i>
              </CopyToClipboard>
            </h3>
            {
              account_id ?
                <h4 className="subhead" style={{marginBottom: '18px'}}>
                  <b>Account: { iconIdent(account_id, 'icon-indent-sm')} <a href={`/account/${account_id}`}>{shortAddr(account_id, 8)} <small className="text-info">{name ? `[${name}]` : ''}</small></a> &emsp;
                    <CopyToClipboard text={account_id} onCopy={() => {}}>
                      <i className="fa fa-clone"
                         color="secondary"
                         style={{cursor: 'pointer', fontSize: 12}}
                         onClick={() => ToastStore.success('Copied!')}></i>
                    </CopyToClipboard>
                  </b>
                </h4>
              : null
            }
          </Col>
        </Row>


        <Row>
          <Col md={12}>
            <Card>
              <CardBody className={ my_address ? 'my_elem' : null }>

                <div className="card__title">
                  <h5 className="bold-text">{load_offer ? 'Active' : null} Offer</h5>
                </div>

                {
                  load_offer ?
                    <div>
                      {/*{ this.state.loader ? <div className="panel__refresh" style={{height: '73%'}}><LoadingIcon /></div> : null }*/}
                        <Offers items={offer} account_id={account_id} />
                    </div>
                    :
                    not_exist_offer ?
                      <div>
                        {
                          trades.length ?
                            <div>
                              <p className={'text-success'}><b>Offer Completed</b></p>
                              <p>The offer was successfully filled.</p>
                            </div>
                            :
                            <div>
                              <p className={'text-secondary'}><b>Offer not found</b></p>
                              <p>The offer does not exist or has been deleted.</p>
                            </div>
                        }
                      </div>
                      :
                      <div className="panel__refresh" style={{height: '73%'}}><LoadingIcon /></div>
                }

              </CardBody>
            </Card>
          </Col>
        </Row>

        {
           load_trades ?
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Trades for Offer { trades.length ? `(${trades.length})` : null }</h5>
                    </div>

                    <Trades items={trades} />

                    {/*{*/}
                    {/*  load_trades ?*/}
                    {/*    <Trades items={trades} />*/}
                    {/*    :*/}
                    {/*    not_exist_offer || not_exist_trades ?*/}
                    {/*      <p><b>No Trades for this offer</b></p>*/}
                    {/*      :*/}
                    {/*      <div className="panel__refresh"><LoadingIcon /></div>*/}
                    {/*}*/}

                  </CardBody>
                </Card>
              </Col>
            </Row>
            : null
        }

        <Footer />

      </Container>
    );
  }
}


Offer.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Offer));
