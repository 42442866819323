import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../../Account/components/iconIdent';
import {numFormat, formatDate, getAsset, shortAddr, shortAddress} from '../../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class DepositedWithdrawals extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: '1',
    };
  }


  renderItem = (item, index) => {
    // console.log('item: ', item)

    const { asset_a, asset_b, pool_db, base_price } = this.props

    let assetA, assetB, price = 0, amountA, amountB, type, color, arrow

    if (item.assets[0] !== 'XLM' && item.assets[1] !== 'XLM') {
      assetA = item.assets[0].slice(0, -2).replace("-", ":")
      assetB = item.assets[1].slice(0, -2).replace("-", ":")

      if (item.max_amount) {
        amountA = item.max_amount[0]
        amountB = item.max_amount[1]
        // price = (item.price[1].n / item.price[1].d)
        price = (item.max_amount[1] / item.max_amount[0])
        type = 'deposited'
      }
      if (item.min_amount) {
        amountA = item.min_amount[0]
        amountB = item.min_amount[1]
        price = (amountA / amountB).toFixed(7)
        type = 'withdrawn'
      }
    }

    else if (item.assets[0] === 'XLM') {
      assetA = 'native'
      assetB = item.assets[1].slice(0, -2).replace("-", ":")

      if (item.max_amount) {
        amountA = item.max_amount[0]
        amountB = item.max_amount[1]
        price = (amountA / amountB)
        type = 'deposited'
      }
      if (item.min_amount) {
        amountA = item.min_amount[0]
        amountB = item.min_amount[1]
        price = (amountA / amountB).toFixed(7)
        type = 'withdrawn'
      }
    }

    color = type === 'deposited' ? 'success' : 'danger'
    arrow = type === 'deposited' ? 'up' : 'down'

    // const pool = this.props.pool
    // const reserves_a = pool.reserves[0].amount
    // const reserves_b = pool.reserves[1].amount
    // const total = pool_db.total_shares
    const sharesA = Number((pool_db.shares * amountA / pool_db.amount_a).toFixed(7))
    // console.log('sharesA: ', sharesA)

    const amount_usd = (pool_db.issuer_a === 'native' ? (amountA * pool_db.price_a) : ((amountA * pool_db.price_a) * base_price)) * 2

    return (
      <tr key={index}>
        <td><small>{index+1}</small></td>
        <td>
          <b>{shortAddress(item.accounts[0])}</b> {' '}
          <b className={`text-${color}`}><i className={`fa fa-arrow-${arrow} text-${color}`}></i> {type}</b> <b className={'text-info'}>{numFormat(amountA, 7)}</b> {getAsset(assetA, asset_a.image, false)} / <b className={'text-info'}>{numFormat(amountB, 7)}</b> {getAsset(assetB, asset_b.image, false)} {' '}
          {/*{ item.amount ? <small className={'text-secondary'}>(<b>{numFormat(item.amount, 7)}</b> shares)</small> : null }*/}
          {/*<small className={'text-secondary'}>( <b>${numFormat(amount_usd, 2)}</b> )</small>*/}
        </td>
        <td>
          <i className={`fa fa-arrow-${arrow} text-${color}`}></i> {' '}
          <b className={`text-${color}`}>{numFormat(sharesA, 7)}</b> {' '}
          <small className={'text-secondary'}>/ <b>${numFormat(amount_usd, 2)}</b></small>
          {/*<span className={'wr-balance-poll-item'}>*/}
          {/*  <span className={'balance-pool-item'}>*/}
          {/*    <span className={'pool-share'}><img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/></span>*/}
          {/*    <span className={'pool-share'}><img src={asset_b.image} alt="" style={{width: 23}}/></span>*/}
          {/*  </span>*/}
          {/*</span>*/}
        </td>
        <td> <b className="text-info">{numFormat(price, 7)}</b> <small>{getAsset(assetA, '', 'secondary')}</small></td>
        {/*<td>*/}
        {/*  <b className={`text-${color}`}><i className={`fa fa-arrow-${arrow} text-${color}`}></i> {type}</b>*/}
        {/*</td>*/}
        <td>{ formatDate(item.ts * 1000) }</td>
        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
      </tr>
    )
  }


  render() {

    const items = this.props.deposits_withdrawals;

    return (
      <Table striped responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Details</th>
          <th>Pool Shares</th>
          <th>Price</th>
          {/*<th>Type</th>*/}
          <th>Date</th>
          <th>Operation</th>
        </tr>
        </thead>
        <tbody>

        { items.map(this.renderItem) }

        </tbody>
      </Table>
    )
  }
}


export default translate('common')(DepositedWithdrawals);
