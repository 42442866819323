import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip
} from 'reactstrap';
import { Field, reduxForm, change} from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import {
  shortAddr,
  numFormat,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle,
  showErrorMessage,
  checkConnectProvider,
  signConnectProvider,
  shortAddress,
  getIconConnectProvider,
  getStellarFee,
  getBasePrice,
  balancesFormat,
  itemType,
  getAsset, formatDate, getFloatValue
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import RequiredSigners from '../Auth/components/RequiredSigners';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import iconIdent from "../Account/components/iconIdent";
import {getAccount, getTradesForAsset} from "../../../endpoints/StellarAPI";
import {getRelativeAssets, savePaymentToDB} from "../../../endpoints/API";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Collapse from "../../../shared/components/Collapse";




const wallets_arr = [];


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);


renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
  error_account: false,
};


class Send extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      select_addrs: [],
      load_select_addrs: false,
      senderAddress: '',
      select_assets: [],
      visible: true,
      error: false,
      error_mess: '',
      create_account: false,
      loader: false,
      wallets: get_loc_wallets(),
      sender_asset_code_path: '',
      recipient_asset_code_path: '',
      sender_amount: '',
      sender_amount_path: '',
      select_asset_code: 'Asset',
      recipient_assets_path: false,
      recipient_asset_path: false,
      sender_assets_path: false,
      sender_asset_path: false,
      recipient_addres_path: false,
      is_auth: false,
      load_sender_account: false,

      need_add_signers: false,
      transaction: false,
      account: false,
      tx_hash: false,
      federation: false,
      stellar: false,
      base_price: 0,
      fee_xlm: 0,
      fee_usd: 0,
      amount_usd: 0,
      balances_formated: false,
    };

  }

  fee = getStellarFee()

  componentDidMount() {
    // getTitle(`Send Assets`);

    const title = 'Send Crypto | Stellar Payments (XLM and any Stellar Assets)';
    const description = 'Make payments or payments with exchange along the path of offers (PathPayment) in crypto or in any of your tokens, around the world, without restrictions, without fees.';
    getTitle(title, description);

    // this.filterAddress();
    this.switchHash();
    this.getXLMPrice()

    var pathArray = window.location.pathname.split('/');

    if (pathArray.length > 2) {
      this.props.change('recipient_addr', pathArray[2])
    }
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)

    if (stellar) {
      const base_price = stellar.market_data.current_price.usd
      const fee_xlm = (this.fee / 10000000)
      const fee_usd = (fee_xlm * base_price)

      this.setState({
        stellar,
        base_price,
        fee_xlm,
        fee_usd,
      })
    }
  }


  switchHash() {

    switch(window.location.hash) {
      case '#payment':
          // this.toggle('1');
        break
      case '#path_payment':
          this.toggle('2');
        break
      default:
        break
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab);
  };

  toggleHash(tab) {
    switch(tab) {
      case '1':
        window.location.hash = 'payment';
        break
      case '2':
        window.location.hash = 'path_payment';
        break
      default:
        break
    }
  }



  addSigners = (value) => {

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    this.setSendTx();
  }


  filterAddress() {

    const wallets = this.state.wallets;

    this.setState({ loader: true, });

    if (wallets) {
      wallets.forEach((item, index) => {
        this.getAccount(item, index);
      })
    }

    if (this.state.wallets) {
        setTimeout(() => {
          ToastStore.info(`Loading ${this.state.wallets.length} Accounts...`);
        }, 3000)
    }
    else {
        this.setState({
          loader: false
        })
    }
  }



  getAccount(item, index) {

    var item = item;
        item.id = index;

    Server.loadAccount(item.pk)
      .then((account) => {

        item.account = account;
        item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
        item.reserve = (account.subentry_count * baseReserve());
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        wallets_arr.push(item);

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
      })
      .catch((err) => {
        console.error(err);

        wallets_arr.push(item)

        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
    })

    const newState = () => {
      this.setState({
        select_addrs: wallets_arr,
        load_select_addrs: true,
        loader: false
      })
    }
  }



  changeSender = (value) => {

    let addr = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        addr = (addr+value[key])
      }
      else {

        this.setState({
          select_addr: addr,
          tx_hash: false,
          transaction: false,
          loader: true,
        })

        getAccount(addr).then(account => {
          // console.log('account: ', account)

          const balances = account.balances.reverse();

          balances.forEach((item, index) => {

            if (item.asset_type === 'native') {

              item.asset_code = 'XLM';
              item.asset_issuer = 'native';
              item.reserve = (account.subentry_count * baseReserve() + minBalance());
              item.avaliable = (item.balance - item.reserve).toFixed(7);

              this.setState({
                balance_native: item.balance,
                avaliable_native: item.avaliable, // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                reserve_balance: item.reserve, // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                loader: false,
              })
            }
            else {
              item.avaliable = item.balance;
            }
          })

          const query = balancesFormat(balances)

          getRelativeAssets(query.pools, query.codes, query.issuers).then(result => {
            // console.log('getRelativeAssets result: ', result)

            if (result) {
              const assets = result.data.assets

              balances.map((item, index) => {

                if (item.asset_type !== 'liquidity_pool_shares') {

                  if (item.asset_type === 'native') {
                    item.image = '/img/logo/stellar-logo.png'
                  }
                  else {
                    const asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

                    if (asset) {
                      item.image = asset.image
                      item.asset = asset
                    } else {
                      item.image = '/img/digitalcoin.png'
                    }
                  }

                  return true
                }
              })

              this.setState({
                select_assets: balances,
              })
            }
            else {

              this.setState({
                select_assets: balances,
              })
            }
          })
        })
        .catch(error => {
          console.log('error: ', error)
        })
      }
    }
  }


  changeSelectAsset = (value) => {
    const { select_assets, sender_amount } = this.state

    let part = getFloatValue(value).split('-');
    const asset = select_assets.filter(item => item.asset_code === part[0] && item.asset_issuer === part[1])[0]
    this.setState({
      select_asset_code: part[0],
      select_asset: part,
      amount_usd: 0,
      asset,
    }, () => {
      sender_amount && this.calcAmountUSD(sender_amount)
    });

  }


  changeSelectAmount = (value) => {

    var amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }

    this.setState({
      sender_amount: Number(amount),
    })

    this.calcAmountUSD(amount)
  }

  changeMemo = (value) => {

    let memo = getFloatValue(value);

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }




  calcAmountUSD = async (amount) => {

    const { select_assets, select_asset, stellar, base_price, trade_price } = this.state
    let amount_usd

    if (select_asset) {


      const asset = select_assets.filter((val) => {
        return select_asset[0] === val.asset_code && select_asset[1] === val.asset_issuer;
      });

      if (select_asset[1] === 'native') {
        amount_usd = (amount * base_price)
      }
      else if (asset.length && asset[0].asset.price) {
        amount_usd = ((amount * asset[0].asset.price) * base_price)
      }
      else {

        if (!trade_price) {
          const base = new StellarSdk.Asset.native()
          const counter = new StellarSdk.Asset(select_asset[0], select_asset[1])
          const trade = await getTradesForAsset(base, counter, 1)
          const trade_price = (trade.records[0].price.d / trade.records[0].price.n)
          amount_usd = ((amount * trade_price) * base_price)
          this.setState({ trade_price, amount_usd })
        }

        amount_usd = ((amount * trade_price) * base_price)
      }

      if (amount_usd) {
        this.setState({ amount_usd })
      }
    }
  }


  payment = (values) => {

    let select_asset;
    if (values.select_asset) {
      const asset = values.select_asset.split('-');

      select_asset = this.state.select_assets.filter((val) => {
        return asset[0] === val.asset_code && asset[1] === val.asset_issuer;
      });
    }

    const allowTransaction = () => {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      function acceptSendTX() {

        const message = `Send <b class="text-info">${numFormat(values.amount, 7)}</b>  <b class="text-warning">${select_asset[0].asset_code}</b> right now?`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: "Confirm Payment",
          content: element,
          icon: "info",
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
          .then((confirm) => {
            if (confirm) {
              checkAccount();
            }
          })
      }
    }


    const checkValues = (values) => {

      // if (values_size === 0) {
      //   ToastStore.warning('Fill in the required fields');
      // }
      if (!values.select_addr) {
        ToastStore.warning('Select your Sender Address');
      }
      else if (!values.select_asset) {
        ToastStore.warning('Select Asset to send');
      }
      else if (!values.amount) {
        ToastStore.warning('Enter Amount');
      }
      else if (!values.recipient_addr) {
        ToastStore.warning('Enter the Address of the recipient');
      }
      else if (!StellarSdk.StrKey.isValidEd25519PublicKey(values.recipient_addr)) {
        ToastStore.error("Recipient's Address is not valid");
      }
      else if (Number(values.amount) > Number(select_asset[0].avaliable)) {
        ToastStore.error('Not enough balance for this operation');
      }
      else {

        allowTransaction()
      }
    }


    const checkAccount = () => {

      this.setState({ loader: true, });
      ToastStore.info("Checking destination account...");

      // Check isset account & Trust asset
      Server.loadAccount(values.recipient_addr)
        .then( (account) => {
          this.setState({ loader: false, });

          if (select_asset[0].asset_code !== 'XLM') { // Token

            let recipient_trust = account.balances.some(function(balance) {
              return balance.asset_code === select_asset[0].asset_code &&
                balance.asset_issuer === select_asset[0].asset_issuer;
            });

            if (!recipient_trust && select_asset[0].asset_issuer !== values.recipient_addr) { // issuer recipient -> burning
              swal({
                title: 'No Trustline',
                text: "Destination Account has no Trustline to "+select_asset[0].asset_code,
                icon: 'info',
              })
            }
            else {
              // ... ASSET
              this.setSendTx(values)
            }
          }
          else {  // ... XLM
            this.setSendTx(values)
          }
        })
        .catch( (error) => {
          console.log('error: ', error);

          this.setState({ loader: false })

          if (select_asset[0].asset_code !== 'XLM') {
            swal({
              title: 'Account not funded',
              text: 'This account has not yet been activated. Send at least 2 XLM to this account.',
              icon: 'info',
            })
            return
          }

          this.setState({
            create_account: true
          },
            () => this.setSendTx(values));
        });
    }


    const checkFederation = () => {

      let name = values.recipient_addr;

      if (name && name.indexOf('*') + 1) {

        StellarSdk.FederationServer.resolve(name)
          .then(federationRecord => {
            if (federationRecord) {
              this.setState({ federation: federationRecord.account_id })

              values.recipient_addr = federationRecord.account_id;
              checkValues(values)
            }
          })
          .catch(err => {
            console.log('err: ', err);
            this.setState({ federation: false })
            checkValues(values)
          });
      }
      else {
        this.setState({ federation: false })
        checkValues(values)
      }
    }

    checkFederation()
  }


  setSendTx = (values) => {

    const { select_addr, wallets } = this.state

    this.setState({ tx_hash: false, })

    const signer = wallets.filter((item) => {
      return item.pk === select_addr;
    });
    const amount = Number(values.amount).toFixed(7)


    let asset;
    if (this.state.select_asset[0] === 'XLM') {
      asset = StellarSdk.Asset.native();
    } else {
      asset = new StellarSdk.Asset(this.state.select_asset[0], this.state.select_asset[1]);
    }

    this.setState({ loader: true, });

    // payment =============
    Server.loadAccount(select_addr)
      .then((sourceAccount) => {

        const submitTx = (num) => {
          ToastStore.info('Sending transaction...');
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          if (this.state.create_account) {

              this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.createAccount({
                  destination: values.recipient_addr,
                  startingBalance: String(amount)
              }))
              .addMemo(StellarSdk.Memo.text(values.memo ? values.memo : ''))
              .setTimeout(180)
              .build();
          }
          else {

              this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.payment({
                destination: values.recipient_addr,
                asset: asset,
                amount: String(amount)
              }))
              .addMemo(StellarSdk.Memo.text(values.memo ? values.memo : ''))
              .setTimeout(180)
              .build();
          }

          if (checkConnectProvider(select_addr)) {
            return signConnectProvider(this.state.transaction, select_addr).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {

            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;

            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
              this.setState({
                account: sourceAccount,
                need_add_signers: true,
                btn_name: 'Send Asset',
                btn_icon: 'send',
                type_tx: 'med',
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {
        // console.log('result: ', result)

        this.setState({ loader: false, });

        if (result && result.hash) {
          this.swallSuccessSend(values, result.hash);

          this.setState({
            transaction: false,
            tx_hash: result.hash
          })

          // save tx
          const formData = new FormData();
          formData.append('asset_code', this.state.select_asset[0])
          formData.append('asset_issuer', this.state.select_asset[1])
          formData.append('asset_amount', amount)
          formData.append('sender_address', select_addr)
          formData.append('recipient', values.recipient_addr)
          formData.append('type', 'payment')
          formData.append('app', 'web')

          savePaymentToDB(formData)
        }
        else {
          ToastStore.warning('Error...');
        }
      })
      .catch((error) => {
        console.error('error: ', error);

        this.setState({ loader: false, transaction: false });
        showErrorMessage(error, 'payment')

        // if (error.response) {
        //   showErrorMessage(error, 'payment')
        // } else {
        //   ToastStore.error(error.toString());
        // }
    });
    //--------------/
  }



  pathPayment(values) {

    // let select_asset = this.state.select_assets.filter((val) => {
    //   return values.select_asset === val.asset_code;
    // });
    // console.log('select_asset: ', select_asset)


    if (!values.recipient_addr) {
      ToastStore.warning('Enter the Address of the Recipient');
    }
    else if (!values.recipient_asset) {
      ToastStore.warning('Enter the Asset that the Recipient should receive');
    }
    else if (!values.recipient_amount) {
      ToastStore.warning('Enter Recipient Amount');
    }
    else if (!values.sender_addr) {
      ToastStore.warning('Select your Sender Address');
    }
    else if (!values.sender_asset) {
      ToastStore.warning('Select Asset to send');
    }
    else if (!values.sender_amount) {
      ToastStore.warning('Enter Sender Amount');
    }
    // else if (Number(values.amount) > Number(select_asset[0].avaliable)) {
    //   ToastStore.error('Not enough balance for this operation');
    // }
    else {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {
        swal({
          title: "Confirmation",
          text: `Send payment now?`,
          icon: "info",
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        .then((confirm) => {
          if (confirm) {
            this.sendPathPayment(values.sender_addr);
          }
        })
      }
    }
  }


  sendPathPayment(sender_addr) {

    var signer = this.state.wallets.filter((item) => {
      return item.pk === sender_addr;
    });

    const sendMaxN = () => {

      var tallage = 5;
      var percent = (this.state.sender_amount_path / 100 * tallage);
      let sendMax = (this.state.sender_amount_path + percent).toFixed(7)
      return sendMax;
    }

    let sendAsset, destAsset, sendMax, destAmount;

    sendMax = sendMaxN();
    destAmount = this.state.recipient_amount_path;

    if (this.state.sender_asset_path.asset_issuer === 'native') {
      sendAsset = new StellarSdk.Asset.native();
      destAsset = new StellarSdk.Asset(this.state.recipient_asset_path.asset_code, this.state.recipient_asset_path.asset_issuer)
    }
    else if (this.state.recipient_asset_path.asset_issuer === 'native') {
      sendAsset = new StellarSdk.Asset(this.state.sender_asset_path.asset_code, this.state.sender_asset_path.asset_issuer);
      destAsset = new StellarSdk.Asset.native();
    }
    else {
      sendAsset = new StellarSdk.Asset(this.state.sender_asset_path.asset_code, this.state.sender_asset_path.asset_issuer)
      destAsset = new StellarSdk.Asset(this.state.recipient_asset_path.asset_code, this.state.recipient_asset_path.asset_issuer)
    }



    // Path payment ==================\
    this.setState({ loader: true, });

    Server.loadAccount(sender_addr)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.pathPayment({
                sendAsset: sendAsset,
                sendMax: String(sendMax),
                destination: this.state.recipient_addres_path,
                destAsset: destAsset,
                destAmount: String(destAmount),
                path: [
                  // new StellarSdk.Asset('USD', 'GBBM6BKZPEHWYO3E3YKREDPQXMS4VK35YLNU7NFBRI26RAN7GI5POFBB'),
                  // new StellarSdk.Asset('EUR', 'GDTNXRLOJD2YEBPKK7KCMR7J33AAG5VZXHAJTHIG736D6LVEFLLLKPDL')
                  new StellarSdk.Asset.native()
                ]
              }))
              .setTimeout(100)
              .build();

        transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        this.setState({ loader: false, });

        // this.swallSuccessSend(result.hash);
      })
      .catch((error) => {
        console.error('Something went wrong: ', error);
        this.setState({ loader: false, });

        ToastStore.error('Transaction error...');
    });
    //--------------/
  }


  swallSuccessSend = (values, hash) => {

    const message = `<b class="text-info">${numFormat(values.amount, 7)}</b> <b class="text-warning">${this.state.select_asset[0]}</b> sent to <b class="text-lightblue">${shortAddr(values.recipient_addr, 4)}</b><br><br><small>hash: <a href="/transaction/${hash}" target="_blank"><b>${shortAddr(hash, 4)}</b></a></small>`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Payment Sent!",
      content: element,
      icon: "success",
    })
  }


  saveTransaction = () => {
    //...
  }



  changeRecipientAddressPath(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address+value[key])
      }
      else {

        if (!StellarSdk.StrKey.isValidEd25519PublicKey(address)) {
          ToastStore.error("Recipient's Address is not valid");
        }
        else {
          this.getRecipientAccount(address);
        }
      }
    }
  }


  changeRecipientAssetPath(value) {

    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let part = asset.split('-');

    let select_asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }

    this.setState({
      recipient_asset_path: select_asset,
      recipient_asset_code_path: part[0],
    });


    if (part[1] === 'native') {
      // ...
      setTimeout(() => {
        if (this.state.sender_asset_path) {
          this.calcSenderAmount();
        }
      }, 300)
    }
    else {

      this.getRecipientAssetPrice(select_asset);
    }
  }


  changeRecipientAmountPath(value) {

    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = (amount + value[key]);
      }
    }

    this.setState({
      recipient_amount_path: Number(amount)
    })

    if (this.state.sender_asset_path) {
      setTimeout(() => {
        this.calcSenderAmount();
      }, 300)
    }
  }


  changeSenderAddressPath(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address+value[key])
      }
      else {

        let select_account = this.state.wallets.filter((item) => {
          return address === item.pk;
        });

        let balances = select_account[0].account.balances;

        balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.reserve = (select_account[0].account.subentry_count * baseReserve());
            item.avaliable = (item.balance - item.reserve).toFixed(7);
          }
          else {
            item.avaliable = item.balance;
          }
        })

        this.setState({
          sender_assets_path: balances.reverse(),
          load_sender_assets_path: true,
          sender_addres_path: address,
        })
      }
    }
  }


  changeSenderAssetPath(value) {

    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    let part = asset.split('-');

    let select_asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }

    this.setState({
      sender_asset_path: select_asset,
      sender_asset_code_path: part[0],
    })


    if (select_asset.asset_issuer === 'native') {
      setTimeout(() => {
        this.calcSenderAmount();
      }, 300)
    }
    else {

      this.getSenderAssetPrice(select_asset);
    }
  }



  changeSenderAmountPath(value) {

    var amonut = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amonut = amonut + value[key];
      }
    }

    this.setState({
      sender_amount_path: Number(amonut),
    })

    if (this.state.recipient_asset_path && this.state.sender_asset_path) {
      setTimeout(() => {
        this.calcRecipientAmount();
      }, 300)
    }
  }


  getRecipientAccount(address) {

    this.setState({ loader: true, });

    Server.loadAccount(address)
      .then((account) => {

        this.setState({ loader: false, });

        account.balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';
            item.avaliable = item.balance
          }
          // else {
          //   item.avaliable = item.balance;
          // }
        })

        this.setState({
          recipient_assets_path: account.balances.reverse(),
          load_recipient_assets_path: true,
          recipient_addres_path: address,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loader: false, });
    })
  }


  getRecipientAssetPrice(asset) {

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(30)
      .call()
      .then((result) => {

        this.setState({
          recipient_asset_orderbook: result,
        })

        setTimeout(() => {
          if (this.state.sender_asset_path) {
            this.calcSenderAmount();
          }
        }, 300)
      })
      .catch((err) => {
        console.log(err);
    })
  }


  getSenderAssetPrice(asset) {

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(30)
      .call()
      .then((result) => {

        this.setState({
          sender_asset_orderbook: result,
        });

        if (this.state.recipient_asset_path) {
          setTimeout(() => {
            this.calcSenderAmount();
          }, 300)
        }
      })
      .catch((err) => {
        console.log(err);
    })
  }


  calcSenderAmount() {

    if (this.state.recipient_asset_path.asset_issuer === 'native') {
      var price_sender_asset = (this.state.sender_asset_orderbook.bids[0].price_r.d / this.state.sender_asset_orderbook.bids[0].price_r.n);
      var amount_sender_asset = (this.state.recipient_amount_path / price_sender_asset).toFixed(7);
    }

    else if (this.state.sender_asset_path.asset_issuer === 'native') {
      var price_recepient_asset = (this.state.recipient_asset_orderbook.bids[0].price_r.d / this.state.recipient_asset_orderbook.bids[0].price_r.n);
      var amount_sender_asset = (this.state.recipient_amount_path * price_recepient_asset).toFixed(7);
    }
    else {
      var price_recepient_asset = (this.state.recipient_asset_orderbook.bids[0].price_r.d / this.state.recipient_asset_orderbook.bids[0].price_r.n);
      var price_sender_asset = (this.state.sender_asset_orderbook.asks[0].price_r.d / this.state.sender_asset_orderbook.asks[0].price_r.n);
      var amount_recepient_base = (this.state.recipient_amount_path * price_recepient_asset);
      var amount_sender_asset = (amount_recepient_base / price_sender_asset).toFixed(7);
    }

    this.setState({
      sender_amount_path: Number(amount_sender_asset),
    });

    this.props.change('sender_amount', amount_sender_asset)


    // let item_amount_all_base = 0,
    //     item_amount_all_asset = 0;

    // this.state.sender_asset_orderbook.forEach((item, index) => {

    //   if (this.state.recipient_amount_path > item_amount_all_asset) {
    //     item_amount_all_base = (item_amount_all_base + Number(item.amount))

    //     let item_amount_asset = (item.amount * item.price);
    //     item_amount_all_asset = Number(item_amount_all_asset + item_amount_asset);
    //     // console.log('item_amount: ', item_amount_all)
    //   }
    // })

    // console.log('item_amount_all_base: ', item_amount_all_base)
    // console.log('item_amount_all_asset: ', item_amount_all_asset)
  }


  calcRecipientAmount(amount) {

    if (this.state.recipient_asset_path.asset_issuer === 'native') {

      // - amount_xlm * price_sender = amount_sender
      var price_sender_asset = (this.state.sender_asset_orderbook.bids[0].price_r.d / this.state.sender_asset_orderbook.bids[0].price_r.n);
      var amount_recipient_asset = (this.state.sender_amount_path * price_sender_asset).toFixed(7);
    }

    else if (this.state.sender_asset_path.asset_issuer === 'native') {

      var price_recipient_asset = (this.state.recipient_asset_orderbook.bids[0].price_r.d / this.state.recipient_asset_orderbook.bids[0].price_r.n);
      var amount_recipient_asset = (this.state.sender_amount_path / price_recipient_asset).toFixed(7);
    }

    // else {

    //   let price_recipient_asset = (this.state.recipient_asset_orderbook.bids[0].price_r.d / this.state.recipient_asset_orderbook.bids[0].price_r.n);
    //   let price_sender_asset = (this.state.sender_asset_orderbook.asks[0].price_r.d / this.state.sender_asset_orderbook.asks[0].price_r.n);
    //   let amount_sender_base = (this.state.sender_amount_path * price_sender_asset).toFixed(7);
    //   let amount_recipient_asset = (amount_sender_base / price_recipient_asset).toFixed(7);

    //   console.log('price_recipient_asset: ', price_recipient_asset)
    //   console.log('price_sender_asset: ', price_sender_asset)
    //   console.log('amount_sender_base: ', amount_sender_base)
    //   console.log('amount_recipient_asset: ', amount_recipient_asset)
    // }

    let recipient_amount = Number(amount_recipient_asset);

    this.setState({
      recipient_amount_path: recipient_amount,
    })

    this.props.change('recipient_amount', recipient_amount);
  }



  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }



  changeRecipientAddr(value) {
    console.log('value: ', value);

    let name = '';
    for (const key of Object.keys(value)) {
      if (key !== 'preventDefault') {
        name = (name+value[key])
      }
    }

    console.log('federation name : ', name);

    this.setState({ recipient: name })

    if (name.indexOf('*') + 1) {

      StellarSdk.FederationServer.resolve(name)
       .then(federationRecord => {
         console.log('federationRecord: ', federationRecord);

         if (federationRecord)
           this.setState({ federation: federationRecord.account_id })
       })
       .catch(err => {
         console.log('err: ', err);
         this.setState({ federation: 'Not found' })
       });
    }
    else {
      this.setState({ federation: false, })
    }
  }


  closeLastTransaction = () => {
    this.setState({ tx_hash: false })
  }


  renderPaymentForm = () => {

    const {reset, handleSubmit } = this.props;
    const { sender_amount, select_addrs, load_select_addrs, balance_native, avaliable_native, reserve_balance, select_assets,
      federation, wallets, select_asset_code, fee_usd, amount_usd, asset, select_asset } = this.state

    return (
      <div>
        {/*<div className="card__title">*/}
        {/*  <h5 className="subhead">Payments directly from Address to Address</h5>*/}
        {/*</div>*/}

        <form className="material-form"
              onSubmit={handleSubmit(this.payment)}>
          <div onClick={this.checkAuthAddr}>
            <Field
              name="select_addr"
              component={renderTextField}
              select
              label="Select Sender Account"
              onChange={(e) => {
                this.changeSender(e)
              }}
            >
              {
                wallets ? wallets.map((item, index) => {
                  // console.log('item: ', item)

                  return (
                    <MenuItem
                      key={index}
                      className={`material-form__option fs-12 ${item && item.provider ?  'connect-provider' : ''}`}
                      value={item.pk}>
                      <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                    </MenuItem>
                    // (<b>{item.balance}</b>/<b>{item.reserve}</b>/<b className="text-success">{item.avaliable} XLM</b>)
                  )
                }) : null // &nbsp;
              }
            </Field>
          </div>

          {
            select_assets.length ?
              <div style={{margin: '-9px 0 7px'}}>
                <h5 className="subhead"> <b className="text-info">{numFormat(balance_native, 7)} <font className="text-warning">XLM</font></b> &nbsp; / &nbsp;
                  Avaliable: <b className="text-info">{numFormat(avaliable_native, 7)} <font className="text-warning">XLM</font></b>
                  <span className="float-right">Reserve: <b className="text-info">{numFormat(reserve_balance, 7)} <font className="text-warning">XLM</font></b></span>
                </h5>
                <br/>
              </div>
            : null
          }

          <div onClick={this.checkAuthAddr}>
            <Field
              name="select_asset"
              component={renderTextField}
              select
              label="Select Asset"
              onChange={(e) => {
                this.changeSelectAsset(e)
              }}
            >
              {
                select_assets.length ? select_assets.map((item, index) => {

                  if (item.asset_type !== 'liquidity_pool_shares') {
                    return (
                      <MenuItem
                        key={index}
                        className="material-form__option"
                        value={`${item.asset_code}-${item.asset_issuer}`}
                      >
                        { item.image ? <img src={item.image} alt="" className={'asset-icon-sm'} /> : null} &nbsp;
                        <b className="text-secondary">{item.asset_code}</b> &nbsp; <span className={'text-secondary'}>({item.asset_code === 'XLM' ? numFormat(item.avaliable, 7) : numFormat(item.balance, 7)})</span>
                      </MenuItem>
                    )
                  }
                }) : null
              }

            </Field>
          </div>

          <div style={{position: 'relative'}}>
            <Field
              name="amount"
              component={renderTextField}
              placeholder="Name"
              label="Amount"
              onChange={(e) => {
                this.changeSelectAmount(e)
              }}
            />
            <div className={'field-right text-secondary'}>
              {
                sender_amount && select_asset ?
                  <div>
                    {
                      amount_usd ?
                        <small>${numFormat(amount_usd, 7)}</small>
                        :
                        <i className="fa fa-spinner fa-pulse fa-1x fa-fw text-secondary"></i>
                    }
                  </div>
                : null
              }
            </div>
          </div>

          <div>
            <Field
              name="recipient_addr"
              component={renderTextField}
              label={`Recipient Address ${federation ? `( Verified! )` : '(or federation name)'}`}
              onChange={(e) => {
                this.changeRecipientAddr(e)
              }}
            />
            {
              federation ?
                <div style={{marginTop: -20}}>
                  <small className="text-success">{shortAddr(federation, 12)}</small>
                </div>
                : null
            }
          </div>

          <div>
            <Field
              name="memo"
              component={renderTextField}
              label={`Memo ${this.state.memo ? `(${this.state.memo.length}/28)` : '(optional)'}`}
              onChange={this.changeMemo}
            />
          </div>

          {/*<ButtonToolbar className="form__button-toolbar float-right">*/}
          {/*  <Button type="button" onClick={reset}>Reset</Button>*/}
          {/*  <Button color="primary" type="submit">Send <b>{numFormat(this.state.sender_amount, 7)} {this.state.select_asset_code}</b></Button>*/}
          {/*</ButtonToolbar>*/}

          <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
            {
              wallets ?
                <Button
                  type="submit"
                  color="primary"
                  size={'lg'}
                  style={{width: '100%'}}>
                    <b>Send { sender_amount ? <b>{numFormat(sender_amount, 7)} {select_asset_code}</b> : null }</b>
                  </Button>
                :
                <Link to={'/auth'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}>Connect Wallet</Link>
            }
          </ButtonToolbar>

          <div className={'stats-block'}>
            <p>Fee <a href={null}  id="fee"><i className="fa fa-question-circle-o text-secondary"></i></a>
              <UncontrolledTooltip placement="top" target="fee">
                                    <span>To prevent ledger spam and maintain the efficiency of the network, Stellar requires small transaction
                                      fees and minimum balances on accounts. Transaction fees are also used to prioritize transactions when
                                      the network enters surge pricing mode.</span>
              </UncontrolledTooltip>
              <span className={'swap-data'}>
                                    <small>${numFormat(fee_usd, 7)}</small>
                                  </span>
            </p>
          </div>
        </form>
      </div>
    )
  }




  render() {

    // console.log('state: ', this.state)

    const { memo, is_auth, tx_hash, recipient, sender_amount, amount_usd, select_addr, select_asset, asset } = this.state


    if (is_auth) {
      return <Redirect to='/auth' />
    }

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={this.state.account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={this.state.type_tx} />
          : null
        }

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text"><span className="lnr lnr-location"></span>&nbsp; Payments</h5>
                  <h5 className="subhead">Send crypto assets anywhere in the world, instantly without intermediaries.</h5>
                    {/*<h5 className="subhead">Supports sending assets to <b>federation</b> addresses (*/}
                    {/*  <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0002.md"*/}
                    {/*     target='_blank'*/}
                    {/*     rel='noopener noreferrer'>SEP-0002</a>).*/}
                    {/*</h5>*/}
                </div>

                {this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : ''}

                { this.renderPaymentForm() }

                {/*<div className="tabs tabs--justify tabs--bordered-bottom">*/}
                {/*  <div className="">*/}
                {/*    <Nav tabs>*/}
                {/*      <NavItem>*/}
                {/*        <NavLink*/}
                {/*          className={classnames({ active: this.state.activeTab === '1' })}*/}
                {/*          onClick={() => {*/}
                {/*            this.toggle('1');*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          <b>Payment</b>*/}
                {/*        </NavLink>*/}
                {/*      </NavItem>*/}
                {/*      <NavItem>*/}
                {/*        <NavLink*/}
                {/*          className={classnames({ active: this.state.activeTab === '2' })}*/}
                {/*          onClick={() => {*/}
                {/*            this.toggle('2');*/}
                {/*          }}*/}
                {/*        >*/}
                {/*          <b>Path Payment</b>*/}
                {/*        </NavLink>*/}
                {/*      </NavItem>*/}
                {/*    </Nav>*/}

                {/*    <TabContent activeTab={this.state.activeTab}>*/}
                {/*      <TabPane tabId="1">*/}
                {/*      /!*  ... *!/*/}
                {/*      </TabPane>*/}



                {/*      <TabPane tabId="2">*/}
                {/*        <div className="card__title">*/}
                {/*          <h5 className="subhead">Payments along the path of active exchange offers at the best price.</h5>*/}
                {/*        </div>*/}

                {/*        <form className="material-form"*/}
                {/*              onSubmit={handleSubmit(this.pathPayment.bind(this))}*/}
                {/*              onClick={this.checkAuthAddr.bind(this)}>*/}

                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="recipient_addr"*/}
                {/*              component={renderTextField}*/}
                {/*              placeholder="Name"*/}
                {/*              label="Paste Recipient Address (or federation name)"*/}
                {/*              onChange={(e) => {*/}
                {/*                this.changeRecipientAddressPath(e)*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          </div>*/}

                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="recipient_asset"*/}
                {/*              component={renderTextField}*/}
                {/*              select*/}
                {/*              label="Select Recipient Asset"*/}
                {/*              onChange={(value) => {*/}
                {/*                this.changeRecipientAssetPath(value)*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              {*/}
                {/*                this.state.recipient_assets_path ?*/}
                {/*                  this.state.recipient_assets_path.map((item, index) => {*/}

                {/*                    if (item.asset_issuer !== this.state.sender_asset_path.asset_issuer) {*/}
                {/*                      return (*/}
                {/*                        <MenuItem*/}
                {/*                          key={index}*/}
                {/*                          className="material-form__option"*/}
                {/*                          value={`${item.asset_code}-${item.asset_issuer}`}><b className="text-secondary">{item.asset_code}</b></MenuItem>*/}
                {/*                      )*/}
                {/*                    }*/}
                {/*                  })*/}
                {/*                : null*/}
                {/*              }*/}
                {/*            </Field>*/}
                {/*          </div>*/}

                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="recipient_amount"*/}
                {/*              component={renderTextField}*/}
                {/*              label={`Recipient Amount ${this.state.recipient_asset_code_path}`}*/}
                {/*              type="number"*/}
                {/*              onChange={(value) => {*/}
                {/*                this.changeRecipientAmountPath(value)*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          </div>*/}


                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="sender_addr"*/}
                {/*              component={renderTextField}*/}
                {/*              select*/}
                {/*              label="Select Sender Address"*/}
                {/*              onChange={(value) => {*/}
                {/*                this.changeSenderAddressPath(value)*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              {*/}
                {/*                this.state.load_select_addrs && this.state.recipient_assets_path ?*/}
                {/*                  this.state.select_addrs.map((item, index) => {*/}
                {/*                    if (item.balance) {*/}
                {/*                      return (*/}
                {/*                        <MenuItem*/}
                {/*                          key={index}*/}
                {/*                          style={{fontSize: '12px'}}*/}
                {/*                          className="material-form__option"*/}
                {/*                          value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title}</small>*/}
                {/*                        </MenuItem>*/}
                {/*                          // (<b>{item.balance}</b>/<b>{item.reserve}</b>/<b className="text-success">{item.avaliable} XLM</b>)*/}
                {/*                      )*/}
                {/*                    }*/}
                {/*                  })*/}
                {/*                :*/}
                {/*                  null*/}
                {/*              }*/}

                {/*            </Field>*/}
                {/*          </div>*/}

                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="sender_asset"*/}
                {/*              component={renderTextField}*/}
                {/*              select*/}
                {/*              label="Select Sender Asset"*/}
                {/*              onChange={(value) => {*/}
                {/*                this.changeSenderAssetPath(value)*/}
                {/*              }}*/}
                {/*            >*/}
                {/*              {*/}
                {/*                this.state.load_sender_assets_path ?*/}
                {/*                  this.state.sender_assets_path.map((item, index) => {*/}

                {/*                    if (item.asset_issuer !== this.state.recipient_asset_path.asset_issuer) {*/}
                {/*                      return (*/}
                {/*                        <MenuItem*/}
                {/*                          key={index}*/}
                {/*                          className="material-form__option"*/}
                {/*                          value={`${item.asset_code}-${item.asset_issuer}`}><b className="text-secondary">{item.asset_code}</b> &nbsp;({item.asset_code === 'XLM' ? item.avaliable : numFormat(item.balance, 7)})</MenuItem>*/}
                {/*                      )*/}
                {/*                    }*/}
                {/*                  })*/}
                {/*                : null*/}
                {/*              }*/}
                {/*            </Field>*/}
                {/*          </div>*/}

                {/*          <div>*/}
                {/*            <Field*/}
                {/*              name="sender_amount"*/}
                {/*              component={renderTextField}*/}
                {/*              label={`Sender Amount ${this.state.sender_asset_code_path}`}*/}
                {/*              type="number"*/}
                {/*              onChange={(value) => {*/}
                {/*                this.changeSenderAmountPath(value)*/}
                {/*              }}*/}
                {/*            />*/}
                {/*          </div>*/}

                {/*          <ButtonToolbar className="form__button-toolbar float-right">*/}
                {/*            <Button type="button" onClick={reset}>Reset</Button>*/}
                {/*            <Button color="primary" type="submit">Send Asset</Button>*/}
                {/*          </ButtonToolbar>*/}

                {/*        </form>*/}
                {/*      </TabPane>*/}
                {/*    </TabContent>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*{*/}
                {/*  this.state.tx_hash ?*/}
                {/*    <div><br/><small>Transaction ID: <a href={`/transaction/${this.state.tx_hash}`} target='_blank' rel='noopener noreferrer'><b>{this.state.tx_hash}</b></a></small></div>*/}
                {/*  : null*/}
                {/*}*/}

              </CardBody>
            </Card>

            {
              tx_hash ?
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Transaction</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastTransaction}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      <span>
                        <p>
                          <span>Transaction:</span>
                          <span className={'swap-data'}>
                            <b><a href={`/transaction/${tx_hash}`}>{ shortAddr(tx_hash, 4) }</a></b> {' '}
                            <CopyToClipboard text={tx_hash} onCopy={() => {}}>
                              <i className="fa fa-clone"
                                 color="secondary"
                                 style={{cursor: 'pointer', fontSize: 14}}
                                 onClick={() => ToastStore.success('Copied!')}></i>
                            </CopyToClipboard>
                          </span>
                        </p>
                        <p>
                          <span>Type:</span>
                          <span className={'swap-data'}>
                            <b className="text-info">{'Payment'}</b>
                          </span>
                        </p>
                        <p>
                          <span>Amount:</span>
                          <span className={'swap-data'}>
                            <b className="text-info">{ numFormat(sender_amount, 7) }</b> { getAsset(`${select_asset[0]}:${select_asset[1]}`, asset && asset.image, '', 15) }
                          </span>
                        </p>
                        <p>
                          <span>Sender:</span>
                          <span className={'swap-data'}>{shortAddress(select_addr)}</span>
                        </p>
                        <p>
                          <span>Recipient:</span>
                          <span className={'swap-data'}>{shortAddress(recipient)}</span>
                        </p>

                        {
                          memo ?
                            <p>
                              <span>Memo:</span>
                              <span className={'swap-data'}><b className={'text-info'}>{memo}</b></span>
                            </p>
                            : null
                        }
                        <p>
                          <span>Date:</span>
                          <span className={'swap-data'}>
                            <small>{formatDate(new Date())}</small>
                          </span>
                        </p>

                      </span>

                    </div>
                  </CardBody>
                </Card>
              : null
            }


            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>

                <Collapse title="What is Stellar Payments" className="with-shadow ml-0">
                  <p><a href="https://stellar.org" target={'_blank'}>Stellar</a> is an open source decentralized payment protocol. It allows you to quickly make cross-border transfers, payments and exchanges between any pairs of currencies (tokens).<br/><br/>
                    The speed of Stellar transactions is within <b>2-5 sec</b> and the transaction price is only about <b>$0.0001</b>, which can be said instantly and for free.<br/><br/>
                    Stellar has a unique Stellar Consensus Protocol (SCP) consensus system that uses a set of distributed servers to validate and validate transactions on the network. This system ensures the reliability and security of transactions, as well as protecting the network from attacks.</p>
                </Collapse>
                <Collapse title="What is memo" className="with-shadow ml-0">
                  <p>Some CEX exchanges and financial institutions use memos as a way to identify payments.
                    Therefore, be careful if you send your payment to the address of the exchange - they may require a memo in a strict order.
                    Usually all Stellar wallets and exchanges like Scopuly don't require a memo.</p>
                </Collapse>
                <Collapse title="What assets can be sent" className="with-shadow ml-0">
                  <p>You can send any assets that are on your Stellar Account without any restrictions. Stellar makes payments and asset
                    exchanges fast and cheap.</p>
                </Collapse>
                <Collapse title="Why Stellar has such low fees" className="with-shadow ml-0">
                  <p>
                    One of the main advantages of Stellar is its high speed and low transaction fees. The system can process up
                    to <b>100,000</b> transactions per second and charge a fee of less than <b>$0.0001</b> per transaction.
                    This makes Stellar one of the fastest and most affordable blockchains for real-world payments and money transfers around the world.
                    Read more: <a href="https://developers.stellar.org/docs/encyclopedia/fees-surge-pricing-fee-strategies" target={'_blank'}>
                    <b>Fees</b></a></p>
                </Collapse>
              </CardBody>
            </Card>


            <Card>
              <CardBody>
                <Link to={'/receive'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-qrcode'}></i> Payment Request</Link>
                <Link to={'/create-claimable-balance'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-clock-o'}></i> Pending Payments</Link>
                <Link to={'/mass-payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%', marginBottom: 0,}}><i className={'fa fa-users'}></i> Mass Payments</Link>
              </CardBody>
            </Card>

          </Col>



          {/*<Col lg={{ size: 4, offset: 1 }}>*/}
          {/*  <Card>*/}
          {/*    <CardBody>*/}
          {/*      <h1>Stellar Payments</h1>*/}
          {/*    </CardBody>*/}
          {/*  </Card>*/}
          {/*</Col>*/}

        </Row>

        <Footer />

      </Container>
    );
  }
}


Send.propTypes = {
  t: PropTypes.func.isRequired,
};


// export default translate('common')(Send);
export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(translate('common')(Send));
