import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';

// getTitle('About SKY')

const AboutSKY = () => (
  <Container>

    <Row>
      <Col md={12}>
        <h3 className="page-title">SKY - Loyalty Token</h3>
      </Col>
    </Row>

    <Row>
      <Col md={{ size: 12 }}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Change SKY Token Functionality</h5>
              <h5 className="subhead">New SCOP token or what will happen to SKY token</h5>
            </div>
            <div>
              <p>The Skycoin project (https://www.skycoin.net/) filed a petition to CoinMarketCap with a request to remove the SKY ticker from the Scopuly project from the coin lists. They complain they used to launch their SKY token before Scopuly.</p>
              <p>On many large external exchanges, the SKY ticker was also busy, for example / BTC_SKY, ETH_SKY, etc. The conflict of ticker names creates serious problems when promoting a token in the foreign market and causes confusion. This turned out to be a significant obstacle to promoting SKY in the foreign market.</p>
              <p>Since the SKY name conflicts with some projects that started long before Scopuly in the external ecosystem, but there are no problems with the name conflict in the star ecosystem, we decided not to destroy the SKY marker and give the asset the right to life to make the SKY token a symbol of loyalty in the Stellar internal ecosystem .</p>
              <p>Scopuly will reward its users with a SKY token for certain marketing activities on the platform.</p>
              <p>In this regard, we decided to issue a new SCOP token, which will serve as the platform’s virtual currency, as well as a means of paying commissions during fiat exchanges, and also one of the main functions is investing in crowdfunding projects (IEO, STO .. .) on the Scopuly platform.</p>
              <p>After completing IEO SCOP, it will be possible to exchange your SKY for SCOP not 1: 1, if you wish, but the SKY purchase price will be calculated in accordance with the hash of the purchase transaction and offered for exchange for SCOP at the current rate. We plan to conduct an exchange at the equivalent price: for what I bought - for that I sold it. the number of coins does not matter. The price at which you bought will matter.</p>
              <p>All direct exchange offers (SKY / SCOP) will be added after the distribution of IEO tokens.</p>
            </div>
            <div>
              <p>More information on the SCOP token can be obtained <a href="scop.scopuly.com">on the SCOP token site</a></p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Footer />

  </Container>
)


export default translate('common')(AboutSKY);
