import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button, ButtonGroup, ButtonToolbar, UncontrolledTooltip, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import {
  get_loc_wallets,
  getTitle,
  getStellarFee, animateElems
} from '../../../modules/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import Collapse from "../../../shared/components/Collapse";
import classnames from "classnames";
import Orderbook from "../Asset/components/Orderbook";
import Trades from "../Account/components/Effects/Trades";
import Offers from "../Account/components/Effects/Offers";



class BuyCrypto extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      fee: 0,
      cost_deposit: 1.5,
      cost_withdraw: 2.5,
      cost: 0,
      total: 0,
      total_with_fee: 0,
      activeTab: '1',
      loader: false,
      sell_asset: '',
      buy_asset: '',
      direct_ex: false,
      wallets: get_loc_wallets(),
      is_auth: false,
      type: 'buy',
      url: 'https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=Mstrs0pOQJtnPDPr&payment_id=&v=3&type=no-rev-share&color=5f41ff&headerId=1&logo=hide&buyButtonTextId=2',
    };
  }



  min_limit_native = 2
  count = 0;
  fee = getStellarFee()

  componentDidMount() {

    const title = 'Buy / Sell Crypto for Fiat, Crypto / Fiat Exchange';
    const description = 'Buy and sell crypto for fiat money. 200 countries of the world are supported and such currencies as: USD, EUR, RUB +10 digital coins BTC, LTC, ETH, DASH, BCH.';
    getTitle(title, description);

    animateElems()
  }


  // setType = (type) => {
  //   let url
  //
  //   if (type === 'exchange') {
  //     url = 'https://widget.changelly.com?from=*&to=*&amount=0.002&address=&fromDefault=btc&toDefault=xlm&merchant_id=N9v-4W7YXo7LRjVq&payment_id=&v=3'
  //   }
  //   if (type === 'buy') {
  //     url = 'https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=N9v-4W7YXo7LRjVq&payment_id=&v=3&type=no-rev-share&color=5f41ff&headerId=1&logo=hide&buyButtonTextId=2'
  //   }
  //
  //   this.setState({ type, url })
  // }


  toggle = (tab) => {

    this.setState({activeTab: tab,});

    let url

    if (tab === '1') {
      url = 'https://widget.changelly.com?from=*&to=*&amount=0.002&address=&fromDefault=btc&toDefault=xlm&merchant_id=N9v-4W7YXo7LRjVq&payment_id=&v=3'
    }
    if (tab === '2') {
      url = 'https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=N9v-4W7YXo7LRjVq&payment_id=&v=3&type=no-rev-share&color=5f41ff&headerId=1&logo=hide&buyButtonTextId=2'
    }

    this.setState({ url })
  };





  render() {
    // console.log('this.state: ', this.state)

    const { reset, handleSubmit } = this.props;
    const { type, url } = this.state

    if (this.state.is_auth) {
      return <Redirect to='/wallet' />
    }

    let background = '', darkMode = false
    if (localStorage.getItem('theme') === 'theme-dark') {
      background = '#9292b3'
      darkMode = true
    }

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Card className={'element-animation'}>
          <CardBody
            style={{
              background: 'linear-gradient(80deg, rgb(125 84 255 / 85%), rgb(108, 169, 255))',
              padding: 14,
            }}
          >
            <br/>
            <Row>
              <Col md={{ size: 10, offset: 1}} lg={{ size: 5, offset: 1 }}>
                <br/><br/><br/>
                <h1 className={'text-white'}><b>Exchange any Crypto Instantly, Easily, Securely.</b></h1>
                <br/>
                <h4 className={'text-white'}>
                  Buy and exchange <b>XLM</b>, <b>BTC</b>, <b>USDC</b> and <b>500+</b> altcoins in a fast, simple, and secure way.
                </h4>
                <br/>
                <div className={'text-white'}>
                  <ul>
                    <li><b>Instant cryptocurrency purchases and exchanges</b></li>
                    <li><b>Low fees and large selection of currencies</b></li>
                    <li><b>High transaction speed</b></li>
                  </ul>
                </div>
                <br/><br/>
                <Button color={'success'}
                        onClick={() => this.startCreateRefCode('start')}
                        size={'lg'}><i className="fa fa-plus"></i> <b>Create Wallet</b></Button>
              </Col>

              <Col md={{ size: 4, offset: 1}} className={'d-md-none d-lg-block'}>
                <div>

                  {/*<ButtonToolbar className={'toolbar-filter'}>*/}
                  {/*  <ButtonGroup className="btn-group--icons">*/}
                  {/*    <Button onClick={() => this.setType('buy')} style={{padding: '14px 34px'}}*/}
                  {/*            color={type === 'buy' ? 'primary' : 'secondary'}>*/}
                  {/*      <i className="fa fa-credit-card"></i> <b>FIAT</b></Button>*/}
                  {/*    <Button onClick={() => this.setType('exchange')} style={{padding: '14px 34px'}}*/}
                  {/*            color={type === 'exchange' ? 'primary' : 'secondary'}>*/}
                  {/*      <i className="fa fa-bitcoin"></i> <b>CRYPTO</b></Button>*/}
                  {/*  </ButtonGroup>*/}
                  {/*</ButtonToolbar>*/}

                  <Card style={{marginBottom: -46}}>
                    <CardBody>
                      <div className="tabs tabs--bordered-bottom">
                        <div className="tabs__wrap">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => {
                                  this.toggle('1');
                                }}
                              >
                                <b className={'uppercase'}>Buy </b>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => {
                                  this.toggle('2');
                                }}
                              >
                                <b className={'uppercase'}>Exchange </b>
                              </NavLink>
                            </NavItem>
                          </Nav>

                          {/*<TabContent activeTab={this.state.activeTab}>*/}
                          {/*  <TabPane tabId="1">*/}

                          {/*  </TabPane>*/}

                          {/*  <TabPane tabId="2">*/}
                          {/*    <p><span className="lnr lnr-arrow-up"/> 2</p>*/}
                          {/*  </TabPane>*/}
                          {/*</TabContent>*/}
                        </div>
                      </div>
                    </CardBody>
                  </Card>


                  {
                    this.state.activeTab === '1' ?
                      <iframe width="100%" height="390" frameBorder='none' allow="camera"
                              id={'my_iframe'}
                              style={{
                                boxShadow: '0 2px 10px 0 rgba(0,0,0,.20)',
                                borderRadius: '10px',
                                margin: 'auto',
                                paddingTop: 7,
                                // maxWidth: '420px',
                                // marginTop: '-13px',
                                background,
                              }}
                              src={'https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=Mstrs0pOQJtnPDPr&payment_id=&v=3&type=no-rev-share&color=5f41ff&headerId=1&buyButtonTextId=2'}>Can't load widget</iframe>
                    :
                      <iframe width="100%" height="390" frameBorder='none' allow="camera"
                              id={'my_iframe'}
                              style={{
                                boxShadow: '0 2px 10px 0 rgba(0,0,0,.20)',
                                borderRadius: '10px',
                                margin: 'auto',
                                paddingTop: 7,
                                // maxWidth: '420px',
                                // marginTop: '-13px',
                                background,
                              }}
                              src={'https://widget.changelly.com?from=*&to=*&amount=0.1&address=&fromDefault=btc&toDefault=xlm&merchant_id=tLFDWeuUGbc2O77K&payment_id=&v=3'}>Can't load widget</iframe>
                  }


                  <p className={'text-white'}><b>* No memo required <a href={null} id={'memo'}><i
                    className="fa fa-question-circle-o text-white"></i></a></b>
                    <UncontrolledTooltip placement="top" target="memo">
                      If you deposit XLM to your Scopuly wallet address, you don't need to provide a memo.
                    </UncontrolledTooltip>
                  </p>
                </div>


                {/*Buy*/}
                {/*<iframe width="100%" height="100%" frameborder='none' allow="camera" src="https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=Mstrs0pOQJtnPDPr&payment_id=&v=3&type=no-rev-share&color=5f41ff&headerId=1&logo=hide&buyButtonTextId=2">Can't load widget</iframe>*/}

                {/*Exchange*/}
                {/*<iframe width="100%" height="100%" frameborder='none' allow="camera" src="https://widget.changelly.com?from=*&to=*&amount=0.1&address=&fromDefault=btc&toDefault=eth&merchant_id=tLFDWeuUGbc2O77K&payment_id=&v=3">Can't load widget</iframe>*/}



                {/*<iframe width="100%" height="390" frameBorder='none' allow="camera"*/}
                {/*        id={'my_iframe'}*/}
                {/*        style={{*/}
                {/*          boxShadow: '0 2px 10px 0 rgba(0,0,0,.20)',*/}
                {/*          borderRadius: '10px',*/}
                {/*          margin: 'auto',*/}
                {/*          maxWidth: '420px',*/}
                {/*          background,*/}
                {/*        }}*/}
                {/*        src="https://widget.changelly.com?from=*&to=*&amount=150&address=&fromDefault=usd&toDefault=xlm&merchant_id=N9v-4W7YXo7LRjVq&payment_id=&v=3">Can't*/}
                {/*  load widget*/}
                {/*</iframe>*/}
                {/*className={'d-md-none d-lg-block'}*/}
                {/*<br/><br/>*/}
                {/*<img src="/img/home/referral@4x.png" alt=""/>*/}
                {/*<br/><br/>*/}
              </Col>
            </Row>
            <br/><br/>
          </CardBody>
        </Card>



        <Row>
          <Col md={4} >
            <Card>
              <CardBody>
                <div>
                  <img src="/img/exchange/1.png" alt=""/>
                </div>
                <div className="card__title">
                  <h4 className="bold-text">Best market rates</h4>
                </div>
                <p>
                  <span>
                    Through partnerships with over 20 crypto trading platforms, we strive to find the most competitive rates for your transactions.
                  </span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div>
                  <img src="/img/exchange/3.png" alt=""/>
                </div>
                <div className="card__title">
                  <h4 className="bold-text">Speedy transactions</h4>
                </div>
                <p>
                  <span>
                    With an average transaction speed of 5–40 minutes, we ensure you can quickly take advantage of market opportunities.
                  </span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div>
                  <img src="/img/exchange/2.png" alt=""/>
                </div>
                <div className="card__title">
                  <h4 className="bold-text">Secure funds handling</h4>
                </div>
                <p>
                  <span>
                    We do not store cryptocurrencies: instead, they are sent directly to your wallet after the exchange for the utmost security.
                  </span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <div className={'text-center'}>
          <br/><br/>
          <h1><b>How to Exchange Crypto</b></h1>
          <br/><br/>
        </div>

        <Row className={'element-animation'}>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                <img src="/img/exchange/6.png" alt=""/>
                <br/>
                <p className={'uppercase'}><b><b className={'fs-26'}>1.</b> Set the exchange pair</b></p>
                <p>Select the crypto pair you’d like to exchange</p>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                <img src="/img/exchange/5.png" alt=""/>
                <br/>
                <p className={'uppercase'}><b><b className={'fs-26'}>2.</b> Enter your wallet address</b></p>
                <p>Enter your crypto wallet address to which your cryptocurrency will be sent</p>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                <img src="/img/exchange/7.png" alt=""/>
                <br/>
                <p className={'uppercase'}><b><b className={'fs-26'}>3.</b> Make a payment</b></p>
                <p>Send the crypto you’d like to exchange to the wallet address you see on the screen</p>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                <img src="/img/exchange/8.png" alt=""/>
                <br/>
                <p className={'uppercase'}><b><b className={'fs-26'}>4.</b> Wait for your coins to arrive</b></p>
                <p>Track the progress of your exchange and be ready to get your crypto soon</p>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Card>
          <CardBody style={{
            background: 'linear-gradient(80deg, rgb(166 90 255), rgb(108 151 255))'
          }}>
            <Row className={'element-animation'}>
              <Col md={{ size: 10, offset: 1}} lg={{ size: 5, offset: 1 }}>
                <div className={''}>
                  <br/><br/>
                  <h1 className={'text-white'}><b>Easy Crypto Exchange</b></h1>
                  <br/>
                  <h3 className={'text-white'}><b>With Various Payment Options</b></h3>
                  <br/>
                  <h4 className={'text-white'}>A user-friendly, intuitive interface that allows you to easily exchange or buy over <b>200</b> cryptocurrencies
                    using a debit/credit card (<b>Visa</b>, <b>Mastercard</b>), bank transfer or even <b>Apple Pay</b>.</h4>
                  <br/><br/>
                  <Link to={'/auth'} className={'btn btn-primary'}><i className="fa fa-sign-in"></i> Connect Wallet</Link>
                </div>
              </Col>
              <Col md={5}>
                <div className={'d-md-none d-lg-block'}>
                  <br/>
                  <div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src="/img/exchange/4.png" style={{width: '85%'}} alt=""/></div>
                  <br/><br/><br/>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>


        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  <h4 className="subhead">General Frequently Asked Questions</h4>
                </div>

                <Collapse title="What is Scopuly Exchange?" className="with-shadow">
                  <p>Scopuly Exchange is an instant exchange service that finds the best rates on the market. Cryptocurrency exchange can
                    be done with fixed or floating rates. The first option allows you to exchange cryptocurrency at a fixed rate,
                    ensuring the final amount remains the same as stated, regardless of market conditions. The second option allows
                    for instant cryptocurrency exchange; however, note that the final amount may change depending on market conditions.</p>
                </Collapse>
                <Collapse title="What currencies are supported?" className="with-shadow">
                  <p>If you pay in a currency not listed below, you can choose US dollars, pounds sterling, or euros. Your card/bank transfer
                    currency will automatically be converted to USD, GBP, or EUR at your bank's rate if your payment method supports
                    international transactions. Payments are accepted in 89 fiat currencies.</p>
                </Collapse>
                <Collapse title="What are the fees?" className="with-shadow">
                  <p>The minimum fee for fiat-cryptocurrency transactions is 4%. However, the final fee depends on the currency pair you choose, your country of residence, market rates, volatility, and other factors.

                    You can see the final fee for a specific purchase on the processing screen before you pay. To see it, select a currency pair
                    and fiat currency amount. You will then see our suppliers who can process your purchase. You will also see the estimated
                    amount of cryptocurrency you will receive next to the provider's name. Enter the recipient's wallet address and click the
                    "Buy" button to go to the provider's page. On their page, you will see more details about your purchase, including any fees.</p>
                </Collapse>
                <Collapse title="Is Scopuly Exchange safe?" className="with-shadow">
                  <p>Absolutely! Scopuly does not hold your deposit. We work with the most reliable and liquid exchange services in the crypto market,
                    so you can rest assured that your funds will always reach their intended destination. The exchange algorithm is integrated
                    into wallets like Exodus, Ledger, MyEtherWallet, Jaxx, Trezor, Freewallet, Enjin Wallet, Edge, Ardor, and Infinito Wallet.</p>
                </Collapse>
                <Collapse title="What is fiat currency?" className="with-shadow">
                  <p>Fiat currency is a currency whose value is determined by the issuing government. Paper currencies include all modern
                    government-issued currencies such as the US dollar, euro, yen, and more.</p>
                </Collapse>
                <Collapse title="How fast is my fiat exchange?" className="with-shadow">
                  <p>Typically, cryptocurrency is delivered within 5-15 minutes. However, in some cases, due to market demand,
                    it may take up to 3 hours. If you have not received your cryptocurrency within 3 hours, contact your provider's support team.</p>
                </Collapse>

              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  <h4 className="subhead">Technical Frequently Asked Questions</h4>
                </div>

                <Collapse title="How to buy cryptocurrency with fiat?" className="with-shadow">
                  <p>Scopuly Exchange serves as an aggregator that offers our partners the best rates for purchasing over 500 cryptocurrencies
                    with nearly 90 fiat currencies. This process is designed to be user-friendly: the best provider is automatically found,
                    operating in your country and offering a variety of payment options (bank cards, transfers, etc.).</p>
                </Collapse>
                <Collapse title="How to sell cryptocurrency for fiat money?" className="with-shadow">
                  <p>Sell Crypto is a service provided by partner
                    platform <a href={'https://changelly.com/sell?from=xlm&to=usd&amount=1500&ref_id=gOKVmAIkIrxpZeHp'} target={'_blank'}>Mercuryo</a>.
                    With Sell Crypto, you can easily make crypto-to-fiat transactions and receive funds directly to your bank card.</p>
                </Collapse>
                <Collapse title="How to use Scopuly Instant Exchange?" className="with-shadow">
                  <p>
                    - Select the cryptocurrency pair you want to exchange and the exchange option (fixed or floating rate).<br/>
                    - Enter the address of your crypto wallet where you will receive the cryptocurrency.<br/>
                    - Confirm the transaction and log in/register using your email or via social media (Twitter, Facebook, or Google account) to securely
                    exchange cryptocurrency and save your transaction history.<br/>
                    - Send the exact amount of funds to the specified address and receive cryptocurrency in your wallet within a few minutes.
                    Scopuly works with various trading platforms to find you the best deal on the market.
                  </p>
                </Collapse>
                <Collapse title="What is the minimum amount?" className="with-shadow">
                  <p>We provide fiat transactions through our partners, each with their own minimum limits.
                    The limits vary based on the selected currency:
                    <br/><br/>
                    - MoonPay, Banxa – $30<br/>
                    - Simplex – $50<br/>
                    - Switchere – $10<br/>
                    - Wert – $5<br/>
                    - Topper – $10
                    <br/><br/>
                    Please note that some providers may be unavailable in your country of residence.</p>
                </Collapse>
                <Collapse title="Why can't I add a payment card?" className="with-shadow">
                  <p>If you are unable to add a payment card, make sure your card supports the 3D Secure protocol and can be used for
                    international transactions. Additionally, you may experience transaction issues if you have added cards issued by
                    more than five different banks. If so, remove unused cards from your account. If the problem persists, contact your
                    provider's support team.</p>
                </Collapse>
                <Collapse title="How to use Scopuly Exchange without a 3D Secure card?" className="with-shadow">
                  <p>Please note that Scopuly does not process fiat transactions. All fiat transactions are processed by the provider's end.</p>
                </Collapse>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Footer />

      </Container>
    );
  }
}


BuyCrypto.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(BuyCrypto));
