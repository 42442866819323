import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, icon, newLink, route, onClick, fa, img,
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {
        fa ?
          <i className={`fa fa-${fa}`} style={{color: '#718b92'}}></i>
        :
        img ?
          <img className={'sidebar-img-icon'} src={img} alt=""/>
        :
        icon ?
            <span className={`sidebar__link-icon lnr lnr-${icon}`} />
        :
          null
      }
      <p className="sidebar__link-title">
        {title}
        {newLink ? <img className={'card-icon'} src="/img/icons/card.png" /> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};

// SidebarLink.defaultProps = {
//   icon: '',
//   newLink: false,
//   route: '/',
//   onClick: () => {},
// };

export default SidebarLink;
