

export default {

    // Transaction Result Codes
    tx_success: 'The transaction succeeded.',
    tx_failed: 'One of the operations failed (none were applied).',
    tx_too_early: 'The ledger closeTime was before the minTime.',
    tx_too_late: 'The ledger closeTime was after the maxTime.',
    tx_missing_operation: 'No operation was specified',
    tx_bad_seq: 'Sequence number does not match source account. Try again a little later.',
    tx_bad_auth: 'Too few valid signatures / wrong network.',
    tx_insufficient_balance: 'Fee would bring account below reserve.',
    tx_no_source_account: 'Source account not found.',
    tx_insufficient_fee: 'Fee is too small.',
    tx_bad_auth_extra: 'Unused signatures attached to transaction.',
    tx_internal_error: 'An unknown error occured.',

    // Manage Sell / Buy Offer
    manage_offer: {
        op_success: 'The offer was successfully placed.',
        op_malformed: 'The input is incorrect and would result in an invalid offer.',
        op_sell_no_trust: 'The account creating the offer does not have a trustline for the asset it is selling.',
        op_buy_no_trust: 'The account creating the offer does not have a trustline for the asset it is buying.',
        sell_not_authorized: 'The account creating the offer is not authorized to sell this asset.',
        buy_not_authorized: 'The account creating the offer is not authorized to buy this asset.',
        op_line_full: 'The account creating the offer does not have sufficient limits to receive buying and still satisfy its buying liabilities.',
        op_underfunded: 'The account creating the offer does not have sufficient limits to send selling and still satisfy its selling liabilities. Note that if selling XLM then the account must additionally maintain its minimum XLM reserve, which is calculated assuming this offer will not completely execute immediately.',
        op_cross_self: 'The account has opposite offer of equal or lesser price active, so the account creating this offer would immediately cross itself.',
        op_sell_no_issuer: 'The issuer of selling asset does not exist.',
        buy_no_issuer: 'The issuer of buying asset does not exist.',
        op_offer_not_found: 'An offer with that offerID cannot be found.',
        op_low_reserve: 'The account creating this offer does not have enough XLM to satisfy the minimum XLM reserve increase caused by adding a subentry and still satisfy its XLM selling liabilities. For every offer an account creates, the minimum amount of XLM that account must hold will increase.',
    },

    // Path Payment Strict Send / Recive
    path_payment: {
        op_success: 'The payment was successfully completed.',
        op_malformed: 'The input for this path payment is invalid.',
        op_underfunded: 'The source account (sender) does not have enough lumens to send the payment amount while maintaining its own minimum reserve.',
        op_src_no_trust: 'The source account is missing the appropriate trustline.',
        op_src_not_authorized: 'The source account is not authorized to send this asset.',
        op_no_destination: 'The destination account does not exist.',
        op_no_trust: 'The destination account does not have a trustline for the asset being sent.',
        op_not_authorized: 'The destination account is not authorized to hold this asset.',
        op_line_full: 'The destination account (receiver) does not have sufficient limits to receive amount and still satisfy its buying liabilities.',
        op_no_issuer: 'The issuer of one of the assets is missing.',
        op_too_few_offers: 'There is no path of offers connecting the send asset and destination asset. Stellar only considers paths of length 5 or shorter.',
        op_cross_self: 'This path payment would cross one of its own offers.',
        op_over_source_max: 'The paths that could send destination amount of destination asset would exceed send max.',
        op_under_dest_min: 'The paths that could send destination amount of destination asset would fall short of destination min.',
    },

    payment: {
        op_success: 'The payment was successfully completed.',
        op_malformed: 'The input for this payment is invalid.',
        op_underfunded: 'The source account (sender) does not have enough lumens to send the payment amount while maintaining its own minimum reserve.',
        op_src_no_trust: 'The source account is missing the appropriate trustline.',
        op_src_not_authorized: 'The source account is not authorized to send this asset.',
        op_no_destination: 'The destination account does not exist.',
        op_no_trust: 'The destination account does not have a trustline for the asset being sent.',
        op_not_authorized: 'The destination account is not authorized to hold this asset.',
        op_line_full: 'The destination account (receiver) does not have sufficient limits to receive amount and still satisfy its buying liabilities.',
        op_no_issuer: 'The issuer of one of the assets is missing.',
    },

    change_trust: {
        op_success: 'Trust was successfully changed',
        op_malformed: 'The input to this operation is invalid.',
        op_no_issuer: 'The issuer of the asset cannot be found.',
        op_invalid_limit: 'The limit is not sufficient to hold the current balance of the trustline and still satisfy its buying liabilities.',
        op_low_reserve: 'The source account is not authorized to send this asset.',
        op_self_not_allowed: 'The source account attempted to create a trustline for itself, which is not allowed.',
    },

    claim: {
        OpDoesNotExist: 'There is no existing ClaimableBalanceEntry that matches the input BalanceID.',
        op_cannot_claim: 'There is no claimant that matches the source account, or the claimants predicate is not satisfied.',
        OpLineFull: 'The account claiming the ClaimableBalanceEntry does not have sufficient limits to receive amount of the asset and still satisfy its buying liabilities.',
        OpNoTrust: 'The source account does not trust the issuer of the asset it is trying to claim in the ClaimableBalanceEntry.',
        OpNotAuthorized: 'The source account is not authorized to claim the asset in the ClaimableBalanceEntry.',
    },

    liquidity: {
        op_success: 'Trust was successfully changed',
        op_malformed: 'Bad input.',
        op_no_trust: 'No trust line for one of the assets.',
        op_not_authorized: 'Not authorized for one of the assets.',
        op_underfunded: 'Insufficient balance for one of the assets.',
        op_line_full: 'The liquidity pool share trust line doesn’t have a sufficient limit.',
        op_bad_price: 'The deposit price is outside the bounds.',
        op_pool_full: 'The liquidity pool reserves are full.',
        op_under_minimum: 'amount under minimum',
    },


    // Next block
}

