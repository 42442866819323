import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
// import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import moment from 'moment';
import { get_loc_wallets, baseReserve, shortAddr } from '../../../modules/index';






const trade_assets = [
  {
    asset: {
      asset_code: 'MOBI',
      asset_issuer: 'GA6HCMBLTZS5VYYBCATRBRZ3BZJMAFUDKYYF6AH6MVCMGWMRDNSWJPIH',
    },
    trader_address: 'GA4AQNPC2GWLSXWSV7UHDAATVLRVF27T7OOMBLMN4A3A4EKVYZBDCK62',
    start_balance: 500,
    cost: 0.5,
    state: 'start',
  },
  // {
  //   asset: {
  //     asset_code: 'XCN',
  //     asset_issuer: 'GCNY5OXYSY4FKHOPT2SPOQZAOEIGXB5LBYW3HVU3OWSTQITS65M5RCNY',
  //   },
  //   trader_address: 'GDNF7P74RQEOKQCXA5PRR6HDB22ICJRQ3L6XQIIIH2IX2YCMOHE2WJA2',
  //   start_balance: 1000,
  //   cost: 0,
  //   state: 'stop',
  // },
  {
    asset: {
      asset_code: 'SKY',
      asset_issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
    },
    trader_address: 'GAUDAOYLVHIYERCRQLVRSL5RJMQWLIMYNXY2L5WR4PDXLMLTPIEHHJ3T',
    start_balance: 1000,
    cost: 0,
    state: 'stop',
  },
  {
    asset: {
      asset_code: 'TERN',
      asset_issuer: 'GDGQDVO6XPFSY4NMX75A7AOVYCF5JYGW2SHCJJNWCQWIDGOZB53DGP6C',
    },
    trader_address: 'GBPXHIOCLZ6C7JR6A3XF6KK4EWZUKGR32RZZAPAEPIQBB3C775NYZDRJ',
    start_balance: 1000,
    cost: 0.5,
    state: 'stop',
  },
  // {
  //   asset: {
  //     asset_code: 'WSD',
  //     asset_issuer: 'GDSVWEA7XV6M5XNLODVTPCGMAJTNBLZBXOFNQD3BNPNYALEYBNT6CE2V',
  //   },
  //   trader_address: 'GBUMHB2SEI2DBNEVLTPEVCSVMERTAAIOKJYMFIELV55RU2AZVRRETZC6',
  //   start_balance: 1000,
  //   cost: 0,
  //   state: 'stop',
  // },
  {
    asset: {
      asset_code: 'RMT',
      asset_issuer: 'GDEGOXPCHXWFYY234D2YZSPEJ24BX42ESJNVHY5H7TWWQSYRN5ZKZE3N',
    },
    trader_address: 'GDYYV6O7Z63DYNFI6G2YTZAHNXIYLC2TGEASMWEBZD7K6WHJRIA55GZS',
    start_balance: 1000,
    cost: 0,
    state: 'stop',
  },
  {
    asset: {
      asset_code: 'XLB',
      asset_issuer: 'GAMA33LC7FK5RF4XE5HOVX456GX4VHDZUCJ7APY4TZ4IHG65ZWVXD333',
    },
    trader_address: 'GDNF7P74RQEOKQCXA5PRR6HDB22ICJRQ3L6XQIIIH2IX2YCMOHE2WJA2',
    start_balance: 1000,
    cost: 0,
    state: 'stop',
  },
  {
    asset: {
      asset_code: 'NEOX',
      asset_issuer: 'GD3YZQFLOUNUBXMQJ3B5ZJHW5O2SQLVWHRTK3XI3GJG7G26KFGHCZCTE',
    },
    trader_address: 'GBUMHB2SEI2DBNEVLTPEVCSVMERTAAIOKJYMFIELV55RU2AZVRRETZC6',
    start_balance: 1000,
    cost: 0,
    state: 'stop',
  },
];


let stream_cnt = 0;


class Trading extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
    };
  }


  componentDidMount() {  

    return

    this.streamTrades();

    // trade_assets.forEach((item, index) => {
    //   this.startTrades(item, index)
    // });

    setTimeout(() => {
      window.location.reload()
    }, 60000 * 15)
  }


  startTrades(item) {
    // this.getOrderbook(item)
    this.getOffers(item)
  }



  getOffers(item) {


    Server.offers('accounts', item.trader_address)
      .order('desc')
      .call()
      .then((offerResult) => {
        // console.log(`offerResult ${item.asset.asset_code}: `, offerResult)

        if (!offerResult.records.length) {
          this.getAccount(item); // create buy offer
        }
        else {
          
          // console.log(`${item.asset.asset_code} offer isset - waiting...`)
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }



  checkOffers(item, type) {
    // console.log(`checkOffers ${item.asset.asset_code} -> `)

    Server.offers('accounts', item.trader_address)
      .order('desc')
      .call()
      .then((offerResult) => {
        // console.log('offerResult: ', offerResult)

        if (!offerResult.records.length) {
          if (type === 'buy') {
            this.sellOffer(item);
          }
          if (type === 'sell') {
            this.getAccount(item);
          }
        }
        else {
          console.log('no offers - waiting ...')
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }



  getAccount(item) {

    Server.loadAccount(item.trader_address)
      .then((account) => {

        if (account) {

          let balance_xlm = account.balances.filter((item) => {
            return item.asset_type === 'native';
          })
          balance_xlm = balance_xlm[0].balance;

          let balance_tokens = account.balances.filter((item2) => {
            return item2.asset_code === item.asset.asset_code && item2.asset_issuer === item.asset.asset_issuer;
          })
          balance_tokens = balance_tokens[0].balance;

          item.balance_tokens = balance_tokens;
          item.balance_xlm = balance_xlm;

          // return

          this.getOrderbook(item, account);

          // if (balance_tokens > 0.0001) {
          //   this.sellOffer(item);
          // }
          // else if (balance_xlm > (account.subentry_count * baseReserve()) + 0.5) {
          //   this.buyOffer(balance_xlm, account, item);
          // }
        }
      })
      .catch((err) => {
        console.log('error: ', err);
    })
  }



  getOrderbook(item, account) {

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(item.asset.asset_code, item.asset.asset_issuer))
      .limit(10)
      .call()
      .then((result) => { 
        if (result) {
          // console.log(`orderbook result ${item.asset.asset_code}: `, result)

          item.orderbook = result;

          // return


          if (item.balance_tokens > 0.0001) {
            this.sellOffer(item);
          }
          else if (item.balance_xlm > (account.subentry_count * baseReserve()) + 0.5) {
            this.buyOffer(item, account);
          }
        }
      })
      .catch((err) => { 
        console.log(err); 
    })
  }



  buyOffer = (item, account) => {

    let asks_price, bids_price;

    // if (item.asset.asset_code === 'MOBI') {
    //   asks_price = Number((this.state.orderbook_MOBI.asks[0].price_r.d / this.state.orderbook_MOBI.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((this.state.orderbook_MOBI.bids[0].price_r.d / this.state.orderbook_MOBI.bids[0].price_r.n).toFixed(7));
    // }
    // if (item.asset.asset_code === 'XCN') {
    //   asks_price = Number((this.state.orderbook_XCN.asks[0].price_r.d / this.state.orderbook_XCN.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((this.state.orderbook_XCN.bids[0].price_r.d / this.state.orderbook_XCN.bids[0].price_r.n).toFixed(7));
    // }
    // if (item.asset.asset_code === 'SKY') {
    //   asks_price = Number((this.state.orderbook_SKY.asks[0].price_r.d / this.state.orderbook_SKY.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((this.state.orderbook_SKY.bids[0].price_r.d / this.state.orderbook_SKY.bids[0].price_r.n).toFixed(7));
    // }
    // if (item.asset.asset_code === 'TERN') {
    //   asks_price = Number((this.state.orderbook_TERN.asks[0].price_r.d / this.state.orderbook_TERN.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((this.state.orderbook_TERN.bids[0].price_r.d / this.state.orderbook_TERN.bids[0].price_r.n).toFixed(7));
    // }
    // if (item.asset.asset_code === 'WSD') {
    //   asks_price = Number((this.state.orderbook_WSD.asks[0].price_r.d / this.state.orderbook_WSD.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((this.state.orderbook_WSD.bids[0].price_r.d / this.state.orderbook_WSD.bids[0].price_r.n).toFixed(7));
    // }
    // if (item.asset.asset_code === 'RMT') {
    //   asks_price = Number((item.orderbook.asks[0].price_r.d / item.orderbook.asks[0].price_r.n).toFixed(7));
    //   bids_price = Number((item.orderbook.bids[0].price_r.d / item.orderbook.bids[0].price_r.n).toFixed(7));
    // }

    asks_price = Number((item.orderbook.asks[0].price_r.d / item.orderbook.asks[0].price_r.n).toFixed(7));
    bids_price = Number((item.orderbook.bids[0].price_r.d / item.orderbook.bids[0].price_r.n).toFixed(7));

    let price;
    let reserve = (account.subentry_count * baseReserve());
    let avaliable = Number(((item.balance_xlm - reserve) - 2).toFixed(7)); // - 2xlm

    if (item.cost > 0) {
      var coefficient = (asks_price / 100);
      var percent = (coefficient * item.cost);
      var set_buy_price = Number((asks_price - percent).toFixed(7));


      price = set_buy_price;
    }
    else {

      // price = Number((asks_price + 0.0000001).toFixed(7));
      price = Number((asks_price).toFixed(7));
    }

    // DEMO !!!!
    // if (price <= asks_price) {
    //   price = Number((asks_price + 0.0000001).toFixed(7));
    // }

    // return

    let asset = {
      selling: new StellarSdk.Asset.native(),
      buying: new StellarSdk.Asset(item.asset.asset_code, item.asset.asset_issuer),
      amount: avaliable, // trader.amount, // avaliable,
      price: (1 / price).toFixed(7),
    }

    this.setOffer(asset, item, 'buy');
  }



  sellOffer(item) {

    Server.loadAccount(item.trader_address)
      .then((account) => {

        if (account) {
          // let balance_tokens = account.balances.filter((item2) => {
          //   return item2.asset_code === item.asset.asset_code && item2.asset_issuer === item.asset.asset_issuer;
          // })
          // balance_tokens = balance_tokens[0].balance;
          // console.log('balance_tokens: ', balance_tokens)

          let bids_price = Number((item.orderbook.bids[0].price_r.d / item.orderbook.bids[0].price_r.n).toFixed(7));

          let asset = {
            selling: new StellarSdk.Asset(item.asset.asset_code, item.asset.asset_issuer),
            buying: new StellarSdk.Asset.native(),
            amount: item.balance_tokens,
            price: bids_price,
          }

          this.setOffer(asset, item, 'sell');
        }
      })
      .catch((err) => {
        console.log('error: ', err);
    })
  }



  setOffer(asset, item, type) {

    let signer = this.state.wallets.filter((item2) => {
      return item2.pk === item.trader_address;
    });

    this.setState({ loader: true });

    Server.loadAccount(item.trader_address)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount)
        .addOperation(StellarSdk.Operation.manageOffer({
            selling: asset.selling,
            buying: asset.buying,
            amount: String(asset.amount),
            price: String(asset.price)
        }))
        .build(); 

        transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
        return Server.submitTransaction(transaction);
      })
      .then((result) => {
        ToastStore.success(`Set offer [${type} ${item.asset.asset_code}]`);

        this.setState({ loader: false });

        // if (type === 'buy') {
        //   this.setLocAmount(item, asset.amount)
        // }
        // if (type === 'sell') {
        //   // this.getOrderbook(item);
        //   // this.getOffers(item);

        //   let amount = (asset.amount * asset.price).toFixed(7);

        //   this.setLocAmount(item, amount)
        // }
      })
      .catch((error) => {
        console.error('error: ', error);
        this.setState({ loader: false });
    });
  }


  // setLocAmount(item, amount) {
  //   // if (item.asset.asset_code === 'MOBI') {
  //   //   localStorage.setItem('amount_MOBI', amount);
  //   // }
  //   // if (item.asset.asset_code === 'XCN') {
  //   //   localStorage.setItem('amount_XCN', amount);
  //   // }
  //   // if (item.asset.asset_code === 'SKY') {
  //   //   localStorage.setItem('amount_SKY', amount);
  //   // }
  //   if (item.asset.asset_code === 'TERN') {
  //     localStorage.setItem('amount_TERN', amount);
  //   }
  //   // if (item.asset.asset_code === 'WSD') {
  //   //   localStorage.setItem('amount_WSD', amount);
  //   // }
  //   if (item.asset.asset_code === 'RMT') {
  //     localStorage.setItem('amount_RMT', amount);
  //   }
  // }



  updateOffer(item, asset, type) {

    const upOffer = (asset) => {

      let signer = this.state.wallets.filter((item2) => {
        return item2.pk === item.seller;
      });

      this.setState({ loader: true });

      Server.loadAccount(item.seller)
        .then((account) => {

          var transaction = new StellarSdk.TransactionBuilder(account)
          .addOperation(StellarSdk.Operation.manageOffer({
              selling: asset.selling,
              buying: asset.buying,
              amount: String(asset.amount),
              price: String(asset.price),
              offerId: item.id,
          }))
          .build();

          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
          return Server.submitTransaction(transaction);
        })
        .then((result) => {
          // console.log('result update: ', result)
          this.setState({ loader: false, })

          ToastStore.success(type+' Offer Update');
        })
        .catch((error) => {
          console.error('Something went wrong!', error);

          this.setState({ loader: false })
          ToastStore.error('Transaction error');
      });
    }


    const buildAsset = (orderbook) => {

      let asset = {};

      if (type === 'buy') {

        let asks_price = Number((orderbook.asks[0].price_r.d / orderbook.asks[0].price_r.n).toFixed(7));

        asset.amount = Number(item.amount);
        asset.price = Number((1 / asks_price).toFixed(7));

        if (item.buying.asset_type !== "native" && item.selling.asset_type !== "native") {
          asset.buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer);
          asset.selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer);
        }
        else {
          if (item.buying.asset_type === "native") {
            asset.buying = new StellarSdk.Asset.native();
            asset.selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer);
          } 
          else {
            asset.buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer);
            asset.selling = new StellarSdk.Asset.native();
          }
        }
      }

      if (type === 'sell') {

        let bids_price = Number((orderbook.bids[0].price_r.d / orderbook.bids[0].price_r.n).toFixed(7));

        // asset.amount = Number((item.amount * item.price).toFixed(7));
        asset.amount = Number(item.amount);
        asset.price = Number(bids_price);

        if (item.buying.asset_type !== "native" && item.selling.asset_type !== "native") {

          asset.buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer);
          asset.selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer);
        }
        else {

          if (item.buying.asset_type === "native") {
            asset.buying = StellarSdk.Asset.native();
            asset.selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer);
          } 
          else {
            asset.buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer);
            asset.selling = StellarSdk.Asset.native();
          }
        }
      }

      upOffer(asset);
    }


    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(10)
      .call()
      .then((result) => { 
        if (result) {
          console.log(`orderbook result ${asset.asset_code}: `, result)

          buildAsset(result);
        }
      })
      .catch((err) => { 
        console.log(err); 
    })
  }



  preUpOffer(message, offer, asset, type) {

    let price_stream = Number((message.price.d / message.price.n).toFixed(7));
    let price_offer = Number((offer.price_r.d / offer.price_r.n).toFixed(7))

    if (type === 'sell') {
      price_offer = Number(offer.price);
    }

    if (price_offer !== price_stream) {
      this.updateOffer(offer, asset, type);
    }
    else {
      console.log('stop... [===]')
    }
  }



  checkOneOffer(message, asset_code) {

    let trader = trade_assets.filter((item) => {
      return item.asset.asset_code === asset_code;
    })


    Server.offers('accounts', trader[0].trader_address)
      .order('desc')
      .limit(10)
      .call()
      .then((offerResult) => {
        // console.log(`offerResult ${asset_code}:`, offerResult)

        if (!offerResult.records.length) {
          this.getAccount(trader); // set buy offer
        }
        else {

          let offer = offerResult.records[0];

          let offer_type, stream_type;

          if (offer.selling.asset_type === 'native' || (offer.price_r.d / offer.price_r.n) === Number(offer.amount)) {
            offer_type = 'buy';
          }
          else {
            offer_type = 'sell';
          }

          if (message.base_asset_type === 'native' && message.base_is_seller) { 
            stream_type = 'buy';

            var asset = {
              asset_code: message.counter_asset_code,
              asset_issuer: message.counter_asset_issuer,
            }
          }
          else {
            stream_type = 'sell';

            var asset = {
              asset_code: message.counter_asset_code,
              asset_issuer: message.counter_asset_issuer,
            }
          }

          if (offer_type === 'buy' && stream_type === 'buy') {
            this.preUpOffer(message, offer, asset, offer_type);
          }
          if (offer_type === 'sell' && stream_type === 'sell') {
            this.preUpOffer(message, offer, asset, offer_type);
          }

          // if (message.base_asset_code === 'MOBI' || message.counter_asset_code === 'MOBI') {
          //   checkType();
          // }
          // if (message.base_asset_code === 'XCN' || message.counter_asset_code === 'XCN') {
          //   checkType();
          // }
          // if (message.base_asset_code === 'SKY' || message.counter_asset_code === 'SKY') {
          //   checkType();
          // }
          // if (message.base_asset_code === 'TERN' || message.counter_asset_code === 'TERN') {
          //   checkType();
          // }
          // if (message.base_asset_code === 'WSD' || message.counter_asset_code === 'WSD') {
          //   checkType();
          // }
          // if (message.base_asset_code === 'RMT' || message.counter_asset_code === 'RMT') {
          //   checkType();
          // }
        }
      })
      .catch((err) => {
        console.error(err); 
    })
  }



  streamTrades() {

    setInterval(() => {
      if (stream_cnt === 0) {
        window.location.reload();
        // this.streamTrades();
      }
    }, (60000 * 2))

    Server.trades()
      .cursor('now')
      .stream({
        onmessage: (message) => {
          // console.log('message: ', message)

          if (stream_cnt === 0) {
            trade_assets.forEach((item, index) => {
              this.startTrades(item, index)
            });
          }

          stream_cnt++;

          if (message.counter_asset_code === 'MOBI' || message.base_asset_code === 'MOBI') {
            this.checkOneOffer(message, 'MOBI');
          }
          // if (message.counter_asset_code === 'XCN' || message.base_asset_code === 'XCN') {
          //   this.checkOneOffer(message, 'XCN');
          // }
          if (message.counter_asset_code === 'SKY' || message.base_asset_code === 'SKY') {
            this.checkOneOffer(message, 'SKY');
          }
          if (message.counter_asset_code === 'TERN' || message.base_asset_code === 'TERN') {
            this.checkOneOffer(message, 'TERN');
          }
          // if (message.counter_asset_code === 'WSD' || message.base_asset_code === 'WSD') {
          //   this.checkOneOffer(message, 'WSD');
          // }
          if (message.counter_asset_code === 'RMT' || message.base_asset_code === 'RMT') {
            this.checkOneOffer(message, 'RMT');
          }
          if (message.counter_asset_code === 'XLB' || message.base_asset_code === 'XLB') {
            this.checkOneOffer(message, 'XLB');
          }
          if (message.counter_asset_code === 'NEOX' || message.base_asset_code === 'NEOX') {
            this.checkOneOffer(message, 'NEOX');
          }





          // return


          // trade_assets.forEach((item, index) => {

          //   if (message.base_account === item.trader_address || message.counter_account === item.trader_address) {

          //     // if (item.asset.asset_code === 'MOBI') {
          //     //   var trader_amount = localStorage.getItem('amount_MOBI');
          //     // }
          //     // if (item.asset.asset_code === 'XCN') {
          //     //   var trader_amount = localStorage.getItem('amount_XCN');
          //     // }
          //     // if (item.asset.asset_code === 'SKY') {
          //     //   var trader_amount = localStorage.getItem('amount_SKY');
          //     // }
          //     // if (item.asset.asset_code === 'TERN') {
          //     //   var trader_amount = localStorage.getItem('amount_TERN');
          //     // } 
          //     // if (item.asset.asset_code === 'WSD') {
          //     //   var trader_amount = localStorage.getItem('amount_WSD');
          //     // }
          //     if (item.asset.asset_code === 'RMT') {
          //       var trader_amount = localStorage.getItem('amount_RMT');
          //     } 
          //     console.log('trader_amount: ', trader_amount)


          //     let trader_amount_loc = Number((Number(trader_amount) - Number(message.base_amount)).toFixed(7));
          //     console.log('trader_amount_loc: ', trader_amount_loc)


          //     // if (item.asset.asset_code === 'MOBI') {
          //     //   localStorage.setItem('amount_MOBI', trader_amount_loc);
          //     // }
          //     // if (item.asset.asset_code === 'XCN') {
          //     //   localStorage.setItem('amount_XCN', trader_amount_loc);
          //     // }
          //     // if (item.asset.asset_code === 'SKY') {
          //     //   localStorage.setItem('amount_SKY', trader_amount_loc);
          //     // }
          //     // if (item.asset.asset_code === 'TERN') {
          //     //   localStorage.setItem('amount_TERN', trader_amount_loc);
          //     // }
          //     // if (item.asset.asset_code === 'WSD') {
          //     //   localStorage.setItem('amount_WSD', trader_amount_loc);
          //     // }
          //     if (item.asset.asset_code === 'RMT') {
          //       localStorage.setItem('amount_RMT', trader_amount_loc);
          //     }
              

          //     if (message.base_account === item.trader_address) { 
          //       if (trader_amount_loc <= message.base_amount) { 
          //         this.checkOffers(item, 'buy');
          //       }
          //     }

          //     else if (message.counter_account === item.trader_address) { 
          //       if (trader_amount_loc <= message.counter_amount) { 
          //         // getAccount(trader.address);
          //         this.checkOffers(item, 'sell');
          //       }
          //     }
          //   }
          // })
        },
        onerror: (error) => {
          console.log('!!! stream error')
        }
    });
  }





  renderTraders(item, index) {

    // console.log('item: ', item)

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><b className="text-warning">{item.asset.asset_code}</b></td>
        <td><a href={`/account/${item.trader_address}`}><b>{shortAddr(item.trader_address, 4)}</b></a></td>
        <td>{item.start_balance}</td>
        <td>{0}</td>
        <td>{0}</td>
        <td>{item.state}</td>
        <td>{item.cost}</td>
        <td>{item.cost > 0 ? item.cost : 'auto'} {}</td>
        {/*<td>{moment(item.date).format("DD MMMM YYYY")}</td>*/}
        <td>
          <Button className="table-btn" 
                  color="success" 
                  size="sm" 
                  outline 
                  onClick={() => this.tradeStart()}
                >Start</Button>
          
          <Button className="table-btn" 
                  color="secondary" 
                  size="sm" 
                  outline 
                  onClick={() => this.tradeStop()}
                >Stop</Button>
        </td>
      </tr>
    )
  }




  render() {

    // console.log('state: ', this.state)

    
    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

        <Row>
          <Col md={12}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>

              { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : '' }

              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Auto Traders (<b>{trade_assets.length}</b>)</h5>
                  {/*<h5 className="subhead">Addresses I added to my contact list</h5>*/}
                </div>

                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Token</th>
                      <th>Trader</th>
                      <th>StartB</th>
                      <th>CurrentB</th>
                      <th>ProfitB</th>
                      <th>State</th>
                      <th>Cost</th>
                      <th>Spread</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>

                    { trade_assets.map(this.renderTraders) }

                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    );
  }
}


Trading.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Trading);

