import axios from "axios";
import {GLOBE} from "../modules/globeVars";


const formData = new FormData();

export const getAssetInfo = (code, issuer) => {
    formData.append('asset_code', code)
    formData.append('asset_issuer', issuer)
    return axios.post(`${GLOBE.API_URL}/asset_info`, formData);
}

export function getMarketAssets(page_count, limit) {
    formData.append('page', page_count)
    formData.append('limit', limit)
    return axios.post(`${GLOBE.API_URL}/get_market_assets`, formData);
}


export function getRelativeAssets(pools, codes, issuers) {

    formData.append('pools', pools)
    formData.append('codes', codes)
    formData.append('issuers', issuers)
    return axios.post(`${GLOBE.API_URL}/get_relative_assets`, formData);
}


export function getStateAssets() {
    return axios.post(`${GLOBE.API_URL}/get_stat_assets`, formData);
}


export function getPaymentRequests(address, limit) {
    const formData = new FormData();
    formData.append('limit', limit)
    formData.append('address', address)
    return axios.post(`${GLOBE.API_URL}/payment_requests`, formData);
}

export function isScamer(scamer, asset, type) {

    const formData = new FormData();
    formData.append('scamer', scamer)
    formData.append('asset', asset)
    formData.append('type', type)
    return axios.post(`${GLOBE.API_URL}/scamer`, formData);
}

export function setClaimant(claimants, asset) {

    const formData = new FormData();
    formData.append('claimants', claimants)
    formData.append('asset', asset)
    return axios.post(`${GLOBE.API_URL}/set_claimant`, formData);
}


export function getAssetHistory(asset) {

    const formData = new FormData();
    formData.append('asset_code', asset.asset_code)
    formData.append('asset_issuer', asset.asset_issuer)
    return axios.get(`${GLOBE.API_URL}/get_asset_info`, formData)
}

export function getTrustlines(asset, limit, cursor) {

    const formData = new FormData();
    formData.append('asset_code', asset.asset_code)
    formData.append('asset_issuer', asset.asset_issuer)
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    return axios.post(`${GLOBE.API_URL}/get_trustlines`, formData)
}

export function getAssetOffers(asset, limit, cursor) {

    const formData = new FormData();
    formData.append('asset_code', asset.asset_code)
    formData.append('asset_issuer', asset.asset_issuer)
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    return axios.post(`${GLOBE.API_URL}/get_asset_offers`, formData)
}

export function getMarkets(asset, limit=30, cursor='', filter='rating', type='all') {

    const formData = new FormData();
    formData.append('asset_code', asset ? asset.asset_code : '')
    formData.append('asset_issuer', asset ? asset.asset_issuer : '')
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    formData.append('filter', filter)
    formData.append('type', type)
    return axios.post(`${GLOBE.API_URL}/get_markets`, formData)
}


export function getDepositWithdrawals(pool_id, limit, cursor) {

    const formData = new FormData();
    formData.append('pool_id', pool_id)
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    return axios.post(`${GLOBE.API_URL}/get_deposit_withdrawals`, formData)
}

export const getLiquidityPools = (limit, page, filter, code) => {

    const formData = new FormData();
    formData.append('limit', limit)
    formData.append('page', page)
    formData.append('filter', filter)
    formData.append('code', code)

    return axios.post(`${GLOBE.API_URL}/get_liquidity_pools`, formData)
}

// export const getLiquidityPool = (pool_id, limit) => {
//
//     const formData = new FormData();
//     formData.append('pool_id', pool_id)
//     formData.append('limit', limit)
//
//     return axios.post(`${GLOBE.API_URL}/get_liquidity_pool`, formData)
// }

export function getPoolHolders(pool_id, limit, cursor) {

    const formData = new FormData();
    formData.append('pool_id', pool_id)
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    return axios.post(`${GLOBE.API_URL}/get_pool_holders`, formData)
}

export const getTopPartners = (page, limit) => {

    const formData = new FormData();
    formData.append('page', page)
    formData.append('limit', limit)

    return axios.post(`${GLOBE.API_URL}/get_top_partners`, formData)
}

export const checkPartner = (address) => {

    const formData = new FormData();
    formData.append('address', address)

    return axios.post(`${GLOBE.API_URL}/check_referral_address`, formData)
}


export const checkHashPool = (pool_id) => {
    const formData = new FormData();
    formData.append('pool_id', pool_id)
    return axios.post(`${GLOBE.API_URL}/check_hash_pool`, formData)
}

export const getAquaRewards = () => {
    return axios.post(`${GLOBE.API_URL}/get_aqua_rewards`)
}

export const getPriceGeckoXlmUsd = () => {
    return axios.post(`${GLOBE.API_URL}/get_price_gecko_xlm_usd`)
}

export const getLedger = () => {
    return axios.post(`${GLOBE.API_URL}/get_ledger`)
}


export const savePaymentToDB = (formData) => {
    return axios.post(`${GLOBE.API_URL}/set_transaction`, formData)
}

export const saveSwapToDB = (formData) => {
    return axios.post(`${GLOBE.API_URL}/save_swap`, formData)
}

export const saveQRPayment = (formData) => {
    return axios.post(`${GLOBE.API_URL}/save_qr_payment`, formData)
}

export const getQrPayments = (request_id, limit = 50, cursor = 0) => {
    const formData = new FormData();
    formData.append('request_id', request_id)
    formData.append('limit', limit)
    formData.append('cursor', cursor)
    return axios.post(`${GLOBE.API_URL}/get_qr_payments`, formData)
}

export const saveTradeToDB = (formData) => {
    return axios.post(`${GLOBE.API_URL}/save_trade`, formData)
}

export const setPool = (formData) => {
    return axios.post(`${GLOBE.API_URL}/set_pool`, formData)
}

export const getChAsset = (asset) => {
    const formData = new FormData();
    formData.append('asset', asset)
    return axios.post(`${GLOBE.API_URL}/get_ch_asset`, formData)
}

export const getTomlInfo = () => {
    return axios.post(`${GLOBE.API_URL}/get_toml_info`)
}







