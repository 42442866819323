import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import CheckIcon from 'mdi-react/CheckIcon';

const feat01 = `${process.env.PUBLIC_URL}/img/landing/features/01_new.png`;
const feat02 = `${process.env.PUBLIC_URL}/img/landing/features/02_new.png`;
const feat03 = `${process.env.PUBLIC_URL}/img/landing/features/03_new.png`;
const feat04 = `${process.env.PUBLIC_URL}/img/landing/features/04_new.png`;
const feat05 = `${process.env.PUBLIC_URL}/img/landing/features/05_new.png`;
const feat06 = `${process.env.PUBLIC_URL}/img/landing/features/06_new.png`;
const feat07 = `${process.env.PUBLIC_URL}/img/landing/features/07_new.png`;
const feat08 = `${process.env.PUBLIC_URL}/img/landing/features/08_new.png`;

const Features = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Key features</h3>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="landing__features-wrap">
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-rocket"></i>
              </div>
              <p className="landing__feature-title">Fast transactions</p>
              <p className="landing__feature-caption">Confirmation of transactions occurs in 3-5 seconds</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-gear"></i>
              </div>
              <p className="landing__feature-title">Full control over the secret key</p>
              <p className="landing__feature-caption">The secret key is kept only by the user</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-key"></i>
              </div>
              <p className="landing__feature-title">Multi-signatures</p>
              <p className="landing__feature-caption">Add multi-sign to wallet</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-gears"></i>
              </div>
              <p className="landing__feature-title">Full account management</p>
              <p className="landing__feature-caption">All Stellar Account Management Options</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-exchange"></i>
              </div>
              <p className="landing__feature-title">Decentralized P2P Trading</p>
              <p className="landing__feature-caption">Distributed sharing directly between users</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-cubes"></i>
              </div>
              <p className="landing__feature-title">Issuing tokens</p>
              <p className="landing__feature-caption">Tokenization of any type of asset into digital tokens</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-send"></i>
              </div>
              <p className="landing__feature-title">Multicurrency payments</p>
              <p className="landing__feature-caption">Payments on the way of offers (Payment with exchange)</p>
            </div>
            <div className="landing__feature">
              <div className="landing__feature-img-wrap">
                <i className="fa fa-plane"></i>
              </div>
              <p className="landing__feature-title">Cross-platform</p>
              <p className="landing__feature-caption">You can use the account from any device</p>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <h4 className="landing__additional-title">… and more</h4>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="landing__features-wrap landing__features-wrap--more">
            <p className="landing__feature-more"><CheckIcon /> Clean interface</p>
            <p className="landing__feature-more"><CheckIcon /> Fully Responsive</p>
            <p className="landing__feature-more"><CheckIcon /> Support</p>
            <p className="landing__feature-more"><CheckIcon /> Rating of tokens</p>
            <p className="landing__feature-more"><CheckIcon /> Auto add new tokens</p>
            <p className="landing__feature-more"><CheckIcon /> Merge accounts</p>
            <p className="landing__feature-more"><CheckIcon /> Block Explorer</p>
            <p className="landing__feature-more"><CheckIcon /> Without registration</p>
            <p className="landing__feature-more"><CheckIcon /> Dark & Light Themes</p>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Features;
