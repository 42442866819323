import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonToolbar,
  Button,
  Table,
  UncontrolledCarousel
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import {
  shortAddr,
  numFormat,
  get_loc_wallets,
  getAuth,
  needDeauthAll,
  getTitle,
  showErrorMessage, getStellarFee
} from '../../../../modules/index';
import { GLOBE } from '../../../../modules/globeVars/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import classnames from 'classnames';
import Collapse from '../../../../shared/components/Collapse';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from "../../../Layout/footer/Footer";



const items_slider = [{
    src: `${process.env.PUBLIC_URL}/img/slider/5.png`,
    // header: 'Tokenization Assets (Issuing Tokens)',
    altText: 'Tokenization Assets (Issuing Tokens)',
    // caption: 'Issue of own tokens and output to SDEX'
  }];


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type, disabled
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  select: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
};

renderTextField.defaultProps = {
  meta: null,
  select: false,
  children: [],
};




class IssuingAssets extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: '1',
      loader: false,
      wallets: get_loc_wallets(),
      issuer_address: false,
      distributor_address: false,
      distributor_assets: [],
      asset_code: false,
      issuing_asset: false,
      issuing_amount: 0,
      toml: false,
      home_domain: false,
      toml_verified: false,
      distributor_trust: false,
    };
  }

  fee = getStellarFee()

  componentDidMount() {
    const title = 'Token issue on Stellar (Tokenization Assets)'
    const description = 'Issue your own crypto tokens on the Stellar blockchain. The issuing process is simple and affordable for everyone, even without special technical knowledge.'
    getTitle(title, description)
  }


  // toggle = (tab) => {
  //   if (this.state.activeTab !== tab) {
  //     this.setState({
  //       activeTab: tab,
  //     });
  //   }
  // };


  checkMeta(address) {

    this.setState({ loader: true });

    Server.loadAccount(address)
      .then((account) => {

        this.setState({
          loader: false,
          home_domain: account.home_domain,
        });

        getToml(account.home_domain);
      })
      .catch((err) => {
        console.log('error: ', err);
        this.setState({ loader: false });
        ToastStore.error('Home Domain Verification Error...');
    })


    const getToml = (home_domain) => {

      this.setState({ loader: true });

      StellarSdk.StellarTomlResolver.resolve(home_domain)
        .then(assetToml => {
          this.setState({ loader: false });

          this.setState({
            toml: assetToml,
          })

          assetToml.CURRENCIES.forEach((item, index) => {

            if (item.code === this.state.asset_code && item.issuer === this.state.issuer_address) {
              this.setState({
                toml_verified: true,
              })
            }
          })
        })
        .catch(error => {
          console.log('toml err', error);
          this.setState({ loader: false });
          ToastStore.error('Error verifying Toml file...');
      });
    }
  }



  changeAssetCode(value) {

    let name = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        name = name + value[key];
      }
    }

    this.setState({
      asset_code: name,
    })
  }


  changeIssuerAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    if (!this.state.asset_code) {

      ToastStore.warning('Enter Asset Code of your issued Asset');

      setTimeout(() => {
        this.props.change('issuer_address', null)
      }, 100)
    }
    else {

      this.setState({
        issuer_address: address,
        toml: false,
        home_domain: false,
        toml_verified: false,
      })

      // check home_domain ...
      this.checkMeta(address);
    }
  }


  changeDistributorAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({ distributor_address: address })

    this.getDistributorAssets(address);
  }


  changeDistributorAsset(value) {

    let asset = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        asset = asset + value[key];
      }
    }

    this.setState({
      issuing_asset: asset,
    })
  }


  amountChange(value) {

    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }

    this.setState({
      issuing_amount: amount,
    })
  }


  getDistributorAssets(address) {

    this.setState({ loader: true });

    Server.loadAccount(address)
      .then((account) => {
        this.setState({ loader: false });
        account.balances.reverse();

        account.balances.forEach((item, index) => {
          if (item.asset_code === this.state.asset_code && item.asset_issuer === this.state.issuer_address) {
            this.setState({
              distributor_trust: true,
              distributor_assets: account.balances,
            })

            if (item.asset_type === 'native') {
              item.asset_code = 'XLM';
            }
          }
        });

        if (!this.state.distributor_trust) {
          swal({
            title: 'No trustline',
            text: `There is no Trustline for the ${this.state.asset_code} token on the Distributor Account. Want to install Trustline to ${this.state.asset_code} right now?`,
            icon: 'warning',
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          .then((value) => {
            if (value) {
              this.setTrustline(address);
            }
          })
        }
      })
      .catch((err) => {
        console.log('error: ', err);
        this.setState({ loader: false });
        ToastStore.error('Error: '+err.toString());
    })
  }


  setTrustline(address) {

    const signer = this.getSigner(address);

    this.setState({ loader: true })

    Server.loadAccount(address)
      .then((sourceAccount) => {

        var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: this.fee,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.changeTrust({
              asset: new StellarSdk.Asset(this.state.asset_code, this.state.issuer_address),
          }))
          .setTimeout(100)
          .build();

        transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {
          this.setState({
            loader: false,
            distributor_trust: true,
          })

          swal({
            title: `Added new balance ${this.state.asset_code}!`,
            text: `Trustline to ${this.state.asset_code} token has been successfully installed. Now ${this.state.asset_code} token is available on your balance.`,
            icon: "success",
            // confirm: true,
          })
          // .then(confirm => {
          //   if (confirm) {
          //     alert('Added!')
          //   }
          // });
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        showErrorMessage(error, 'change_trust')

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
    });
  }


  getSigner(address) {
    const wallets = this.state.wallets;

    if (wallets) {
      let signer = wallets.filter((item) => {
        return item.pk === address;
      });

      return signer;

      // this.setState({
      //   signer: signer,
      // })
    }
  }



  submitForm = (values) => {

    if (!values.asset_code) {
      ToastStore.warning('Enter Asset Code');
    }
    else if (!values.issuer_address) {
      ToastStore.warning('Select Issuer Address');
    }
    else if (!values.distributor_address) {
      ToastStore.warning('Select Distributor Address');
    }
    else if (!this.state.distributor_trust) {
      ToastStore.warning(`There is no trustline to the ${values.asset_code} on the Distributor Account`);
    }
    else if (!values.asset_amount) {
      ToastStore.warning('Enter the Issue Amount');
    }
    else {

      if (!this.state.toml) {
        swal({
          title: 'Toml not found',
          text: `Are you going to issue ${numFormat(values.asset_amount, 7)} ${this.state.asset_code} despite the fact that the Toml file for your Asset is not found?`,
          icon: 'warning',
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        .then((value) => {
          if (value) {
            this.sendCrypto(values);
          }
        })
      }
      else {
        this.sendCrypto(values);
      }
    }
  }



  sendCrypto(values) {

    const message = `Do you really want to issue <b class="text-info">${numFormat(values.asset_amount, 7)}</b> <b class="text-warning">${this.state.asset_code}</b> right now?`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `Confirm`,
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then(confirm => {
        if (confirm) {
          sendTransaction();
        }
    });


    const sendTransaction = () => {

      const signer = this.getSigner(values.issuer_address);
      const asset = new StellarSdk.Asset(values.asset_code, values.issuer_address);

      this.setState({ loader: true });

      Server.loadAccount(values.issuer_address)
        .then((sourceAccount) => {

          var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.payment({
              destination: values.distributor_address,
              asset: asset,
              amount: String(values.asset_amount),
            }))
            .setTimeout(100)
            .build();

          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
          return Server.submitTransaction(transaction);
        })
        .then((result) => {
          this.setState({ loader: false });

          if (result) {
            const message = `${numFormat(values.asset_amount, 7)} ${this.state.asset_code} successfully issued to <a href="/account/${values.distributor_address}"><b>${shortAddr(values.distributor_address, 4)}</b></a> 
                            <br><br><small>hash: <a href="/transaction/${result.hash}"><b>${shortAddr(result.hash, 4)}</b></a></small>`
            const element = document.createElement("span");
            element.innerHTML = message;

            swal({
              title: "Asset issued!",
              content: element,
              icon: "success",
            })
          }
        })
        .catch((error) => {
          this.setState({ loader: false });
          ToastStore.error('Transaction error...');
          console.error('Transaction error!', error);
      });
    }
  }



  renderIssuerAddress() {
    return (
        <div>
          <div>
            <Field
              name="issuer_address"
              component={renderTextField}
              select
              label="Issuer Address"
              onChange={(value) => {
                this.changeIssuerAddress(value)
              }}
            >
              {
                this.state.wallets ?
                  this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>
        </div>
    )
  }


  renderDistributorAddress() {

    return (
        <div>
          <div>
            <Field
              name="distributor_address"
              component={renderTextField}
              select
              label="Distributor Address"
              onChange={(value) => {
                this.changeDistributorAddress(value)
              }}
            >
              {
                this.state.wallets && this.state.issuer_address ?
                  this.state.wallets.map((item, index) => {
                    return (
                      <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                    );
                  })
                : null
              }
            </Field>
          </div>
        </div>
    )
  }


  handleClick = (type) => {
    console.log('type: ', type)

    const { distributor_address, issuer_address } = this.state;
    console.log('distributor_address: ', distributor_address)
    console.log('issuer_address: ', issuer_address)

    const showAlert = (text) => {
      swal({
        title: 'Data filling required',
        text: text,
        icon: 'info',
      })
    }

    if (!issuer_address) {
      if (type === 'lock' || type === 'domain' || type === 'trade') {
        showAlert('Select Account Issuer')
      }
    }
    if (!distributor_address) {
      if (type === 'balance') {
        showAlert('Select an Account Distributor')
      }
    }
  }





  render() {

    const { reset, handleSubmit } = this.props;
    const { distributor_address, issuer_address, asset_code } = this.state;

    // console.log('state: ', this.state)

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <h3 className="page-title">Create Stellar Token</h3>
            <h3 className="page-subhead subhead">Issue tokens on the Stellar blockchain, move and trade on the SDEX exchange</h3>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*  <Col md={12}>*/}
        {/*    <Card>*/}
        {/*      <CardBody style={{padding: '10px'}}>*/}
        {/*        <UncontrolledCarousel items={items_slider} />*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row>

          <Col md={5} lg={4}>

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Select asset issue options</h5>
                  <h5 className="subhead">Simple and effective Asset Tokenization on Stellar</h5>
                </div>

                { this.state.loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }


                <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                  <div>
                    <Field
                      name="asset_code"
                      component={renderTextField}
                      label="Asset Code"
                      onChange={(value) => {
                        this.changeAssetCode(value);
                      }}
                    />
                  </div>

                  { this.renderIssuerAddress() }

                  {
                    this.state.issuer_address ?
                      <div className="text-secondary">
                        <div>
                          <small>
                            <span>{this.state.home_domain && <i className="fa fa-check text-success"></i> } Home Domain: <span className={this.state.home_domain ? 'text-success' : 'text-danger'}>{this.state.home_domain ? <b>{this.state.home_domain}</b> : 'Not found'}</span></span><br/>
                            <span>{this.state.toml && <i className="fa fa-check text-success"></i> } Toml: <span className={this.state.toml ? 'text-success' : 'text-danger'}>{this.state.toml ? `https://${this.state.home_domain}/.well-known/stellar.toml` : `Toml file not found`}</span></span><br/>
                            <span>{this.state.toml_verified && <i className="fa fa-check text-success"></i> } <b>{this.state.asset_code}</b> verified: <span className={this.state.toml_verified ? 'text-success' : 'text-danger'}>{this.state.toml_verified ? 'Verified' : 'Not verified'}</span></span>
                          </small>
                        </div>
                        <br/>
                      </div>
                    : null
                  }

                  { this.renderDistributorAddress() }

                  <div>
                    <Field
                      name="asset_amount"
                      component={renderTextField}
                      label={`Amount`}
                      onChange={this.amountChange.bind(this)}
                    />
                  </div>
                  <br/>
                  <ButtonToolbar className="form__button-toolbar float-right">
                    <Button color="primary" type="submit">Issuing <b>{numFormat(this.state.issuing_amount, 7)} {this.state.asset_code ? this.state.asset_code : 'Asset'}</b></Button>
                  </ButtonToolbar>
                </form>

              </CardBody>
            </Card>

          </Col>



          <Col md={7} lg={8}>
            <Card style={{height: 'auto'}}>
              <CardBody style={{position: 'relative'}}>

                <div className="card__title">
                  <h5 className="bold-text">Rules for issuing new assets</h5>
                  <h5 className="subhead">Issue and trade assets of any type, US dollars, Nigerian naira, bitcoins, special coupons, ICO / STO / IEO tokens or whatever</h5>
                </div>

                <h5><b>Token Issuing Guide:</b></h5>
                <p>&nbsp; &nbsp; 1. To issue a new token, you must have 2 accounts: an issuer account and a distributor account. The minimum balance of each of these two accounts must be at least 4 XLM to secure transactions and trustline.</p>
                <p>&nbsp; &nbsp; 2. In the distributor account, set a trust line for the new token. To do this, select the distributor's address in the form on this page, or go to the distributor's account and go to balances, and add the token code and issuer address to add a trustline.</p>
                <p>&nbsp; &nbsp; 3. From the issuer's account, issue a certain number of tokens to the distributor's account. After this transaction, your token will be issued on the Stellar network.</p>
                <p>&nbsp; &nbsp; 4. To add a new token to the SDEX decentralised trading market, set an offer to sell your new token from the distributor's account, indicating the price and amount of the offer. After that, your new token is available for trading on SDEX.</p>
                <br/>

                <Row>
                  <Col md={4}><Link to={'/auth/create'}>Create Address</Link></Col>
                  <Col md={4}><Link to={issuer_address ? `/account/${issuer_address}#settings` : '#'}
                                    onClick={() => this.handleClick('domain')}>Set Home Domain</Link></Col>
                  <Col md={4}><Link to={asset_code && issuer_address ? `/trade/${asset_code}-XLM/${issuer_address}/native` : '#'}
                                    onClick={() => this.handleClick('trade')}>Trade Token</Link></Col>
                  <Col md={4}><Link to={issuer_address ? `/account/${issuer_address}#settings` : '#'}
                                    onClick={() => this.handleClick('lock')}>Block Issuer Account</Link></Col>
                  {/*<Col md={4}><Link to={distributor_address ? `/balances/${this.state.distributor_address}` : '#'}>Add Trustline</Link></Col>*/}
                  <Col md={4}><Link to={distributor_address ? `/account/${distributor_address}` : '#'}
                                    onClick={() => this.handleClick('balance')}>Check Balance</Link></Col>
                  <Col md={4}><Link to={'/exchange'}>Buy XLM</Link></Col>
                </Row>

                <p><small>* In order for all links to become active, fill out the token issuance form.</small></p>
                <br/>
                <hr/>
                <br/>

                <div>
                  <h5><b>To verify the token:</b></h5>
                  <p>&nbsp; &nbsp; 1. Set home_domain to the issuer account - in the account settings.</p>
                  <p>&nbsp; &nbsp; 2. Configure a toml file on a token web site (example <a href="https://scopuly.com/.well-known/stellar.toml" target='_blank' rel='noopener noreferrer'>stellar.toml</a> file).</p>
                </div>
                <br/>
                <p>You can check the stellar.toml file for validity here: <a href="https://stellar.sui.li/toml-check" target={'_blank'}>stellar.sui.li/toml-check</a></p>
                <p>
                  Check out the <a href="https://resources.stellar.org/hubfs/Asset_Issuance_on_Stellar.pdf" target={'_blank'}>token issuance guide</a>
                </p>

                <p>
                  <small>
                    To release a new type of asset, all you need to do is select a code. This can be any combination of up to 12 letters or numbers,
                    but you must use the appropriate ISO 4217 code (for example, USD for US dollars) or ISIN for national currencies or securities.
                    By selecting a code, you can start paying people using this asset code. You do not need to do anything to declare your asset online.
                  </small>
                </p>
                {/*<br/>*/}
                {/*<p>*/}
                {/*  <i>* You can also use a service like <a href="https://stellarmint.io/" target={'_blank'} rel='noopener noreferrer'>Stellarmint.io</a> and create a Stellar network token the really easy way.*/}
                {/*    Stellarmint makes creating tokens on the Stellar network even easier. You define the attributes of your token*/}
                {/*    and they'll issue that token directly to your Stellar wallet. No need to worry about the technicalities of a Stellar.toml file.</i>*/}
                {/*</p>*/}


              </CardBody>
            </Card>

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  <h4 className="subhead">Answers to frequently asked questions</h4>
                </div>

                {/** I released a token - how can I give out more assets than already released?<br/>
                * How to burn tokens?<br/>
                * How to permanently lock the issuer account to block emissions and transactions?<br/>
                    - You can lock the issuer account in the account settings<br/>
                * How to configure toml file on the issuer domain?<br/>
                * How to add a token to SDEX for trading?<br/>
                * How to send released tokens to another address?<br/>*/}

                <Collapse title="How to set the home domain for the issuer account?"
                          className="with-shadow">
                  <p>To set the home domain, you need to enter the issuer account, then in the settings in the Home Domain section enter the
                      domain address and click "Set Home Domain".</p>
                </Collapse>

                <Collapse title="I issued a token, how can I issue more assets than already issued?"
                          className="with-shadow">
                  <p>To do this, you need to repeat the transaction to issue tokens from the issuer account to the distributor account.</p>
                </Collapse>

                <Collapse title="How to burn tokens?"
                          className="with-shadow">
                  <p>To burn tokens from circulation you need to send them to the address of the issuer.</p>
                </Collapse>

                <Collapse title="How to permanently lock the issuer account to block emissions and transactions?"
                          className="with-shadow">
                  <p>It is possible to lock an issuer account in account settings.</p>
                </Collapse>

                <Collapse title="How to configure toml file on the issuer domain?"
                          className="with-shadow">
                  <p>You can find out how to set up your toml file on the asset domain
                    <a href="https://www.stellar.org/developers/guides/concepts/stellar-toml.html" target='_blank' rel='noopener noreferrer'>here</a>,
                    see how to fill it out (<a href="https://www.stellar.org/developers/guides/walkthroughs/how-to-complete-stellar-toml.html" target='_blank' rel='noopener noreferrer'>here</a>)</p>
                </Collapse>

                <Collapse title="How to add a token to SDEX for trading?"
                          className="with-shadow">
                  <p>In order for the exchanges to accept your token to their lists for trading, you must have a toml file that is complete and
                    accessible to the network. After that you need to make an offer to sell tokens for example <Link to={`/exchange`}>here</Link></p>
                </Collapse>

                <Collapse title="How to send issued tokens to another address?"
                          className="with-shadow">
                  <p>Immediately after issuing, you can send tokens to any Stellar account that has a trust line to your token. This is done in
                    the same way as you send XLM by ordinary payment, for example (<Link to={`/send`}>here</Link>)</p>
                </Collapse>

                    

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


IssuingAssets.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(IssuingAssets));
