import React, { PureComponent } from 'react';
import SearchIcon from 'mdi-react/SearchIcon';
import classNames from 'classnames';
import StellarSdk from 'stellar-sdk';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from "mdi-react/LoadingIcon";
import { searchProcess } from "../../../modules";

export default class TopbarSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputOpen: true,
      search_value: '',
      loading: false,
    };
  }


  handleChange = (e) => {
    e.preventDefault();

    this.setState({
      search_value: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.state.search_value) {
      ToastStore.warning('Paste value');
    }
    else {
      this.setState({loading: true})
      searchProcess(this.state.search_value)
    }
  }




  render() {
    const searchClass = classNames({
      'topbar__search-field': true,
      'topbar__search-field--open': this.state.inputOpen,
    });

    return (
        <form className="topbar__search" onChange={this.handleChange} onSubmit={this.handleSubmit} >
          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />
          {
            this.state.loading ?
                <div className="panel__refresh" style={{height: '98%'}}><LoadingIcon /></div> : null
          }
          <input className={searchClass} placeholder="Address, Asset, Transaction, Operation..." />
          <button className="topbar__btn topbar__search-btn">
            <SearchIcon />
          </button>
        </form>
    );
  }
}
