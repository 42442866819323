import React, { PureComponent } from 'react';
import { Link, Redirect, } from 'react-router-dom';
import { translate } from 'react-i18next';
import {Button, Card, CardBody, Col, Container, Row, Table, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {
  checkConnectProvider,
  formatDate,
  get_loc_wallets,
  getAsset, getAuth, needDeauthAll,
  numFormat, shortAddr,
  shortAddress, showErrorMessage,
  signConnectProvider, baseReserve, getClaimantStatus, getStellarFee, getSigner, getBasePrice,
} from "../../../modules";
import LoadingIcon from "mdi-react/LoadingIcon";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastContainer, ToastStore} from "react-toasts";
import {Server} from "../../../modules/stellar";
import StellarSdk from "stellar-sdk";
import swal from "sweetalert";
import RequiredSigners from "../Auth/components/RequiredSigners";
import {
  getPredicateInformation,
  isPredicateClaimableAt,
  predicateFromHorizonResponse
} from "stellar-resolve-claimant-predicates";
import {getClaimableBalancesForSponsor, getAccount} from "../../../endpoints/StellarAPI";
import {Field, reduxForm} from "redux-form";
import renderCheckBoxField from "../../../shared/components/form/CheckBox";
import ClipLoader from "react-spinners/ClipLoader";
import {css} from "@emotion/core";


const  accountLoader = css`
  display: inline-block;
`




class ClaimableBalances extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      account: false,
      activeTab: '1',
      wallets: get_loc_wallets(),
      is_my_address: false,
      loading: false,
      transaction: false,
      need_add_signers: false,
      btn_name: '',
      btn_icon: '',
      type_tx: '',
      type_set_tx: '',
      select_index: 0,
      claimable_balances: this.props.claimable_balances,
      sponsor_claimants: false,
      sponsor_loading: false,
      defaultChecked: false,
      mass_claim_balances: [],
      fee_xlm: 0,
      fee_usd: 0,
    };
  }


  fee = getStellarFee()


  componentDidMount = () => {

    this.setState({
      is_my_address: this.state.wallets ? this.state.wallets.some(item => item.pk === this.props.account) : false
    })

    this.getAccount(this.props.account)
    this.getXLMPrice()
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      const base_price = stellar.market_data.current_price.usd
      const fee_xlm = (this.fee / 10000000)
      const fee_usd = (fee_xlm * base_price)

      this.setState({ base_price, fee_xlm, fee_usd, })
    }
  }


  toggle = (tab) => {
    const { activeTab, sponsor_claimants } = this.state
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
    if (tab === '2' && !sponsor_claimants) { // sponsor
      this.getSponsorBalances(this.props.account)
    }
  };


  getAccount = (account) => {

    getAccount(account).then(account => {
      if (account) this.setState({ account })
    })
  }

  getSponsorBalances = (sponsor) => {

    this.setState({ sponsor_loading: true })

    getClaimableBalancesForSponsor(sponsor).then(result => {

      if (result) {
        this.setState({
          sponsor_claimants: result,
          sponsor_loading: false
        })
      }
    })
  }


  addSigners = (value) => {

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    this.setSendTx();
  }


  getSigner(account) {
    if (this.state.wallets) {
      return this.state.wallets.filter((item) => {
        return item.pk === account;
      })[0];
    }
  }


  getPayment = (item, account) => {

    const signer = this.getSigner(account)

    this.setState({ loading: true })
    ToastStore.info('Loading an account...');


    // Claim Claimable Balance
    Server.loadAccount(account)
      .then((sourceAccount) => {

        this.setState({ account: sourceAccount })

        const trust = item.asset !== 'native' ? sourceAccount.balances.some(balance => balance.asset_code === item.asset.split(':')[0]) : true

        if (!trust) {
          this.noTrustlineMessage(item, account)
        }
        else {

          const submitTx = (num) => {
            ToastStore.info('Sending a transaction...');
            return Server.submitTransaction(this.state.transaction);
          }

          if (!this.state.transaction) {

            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.claimClaimableBalance({
                balanceId: item.id
              }))
              .setTimeout(0)
              .build()

            if (checkConnectProvider(account)) {

              return signConnectProvider(this.state.transaction, account).then(result => {
                this.state.transaction = result
                return submitTx();
              })
            }
            else {

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer.sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

                this.setState({
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Claim',
                  btn_icon: 'clock-o',
                  type_tx: 'med',
                  type_set_tx: 'claim',
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
          }
          else {
            submitTx()
          }
        }
      })
      .then((result) => {
        this.setState({
          loading: false,
          transaction: false,
        })

        if (result) {

          const asset_code = item.asset === 'native' ? 'XLM' : item.asset.split(':')[0]

          const message = `Received <b class="text-info">${numFormat(item.amount, 7)}</b> <b class="text-warning">${asset_code}</b><br><br><small>hash: <a href="/transaction/${result.hash}"><b>${shortAddr(result.hash, 4)}</b></a></small>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Asset Claimed!`,
            content: element,
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
            .then(confirm => {
              if (confirm) {

                const claimable_balances = this.state.claimable_balances.filter(payment => payment.id !== item.id)
                this.setState({ claimable_balances })
              }
            });
        }
      })
      .catch(error => {
        console.log('error: ', error)
        this.setState({ loading: false })
        showErrorMessage(error, 'claim')
      })

  }


  noTrustlineMessage = (item, account) => {

    const message = `Add <b class="text-warning">${item.asset.split(':')[0]}</b> to your balance right now?`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `No Trustline`,
      content: element,
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then(confirm => {
        if (confirm) {
          this.setTrustline(item, account)
        }
      });
  }


  setTrustline = (item, account) => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          setTrustTx(item, account)
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });


    const setTrustTx = (item, account) => {

      const signer = this.getSigner(account)
      const split_asset = item.asset.split(':')

      this.setState({ loading: true })
      ToastStore.info('Loading an account...');

      Server.loadAccount(account)
        .then((sourceAccount) => {

          const submitTx = (num) => {
            ToastStore.info('Sending a transaction...');
            return Server.submitTransaction(this.state.transaction);
          }

          if (!this.state.transaction) {

            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(split_asset[0], split_asset[1]),
              }))
              .setTimeout(100)
              .build();


            if (checkConnectProvider(account)) {

              return signConnectProvider(this.state.transaction, account).then(result => {
                this.state.transaction = result
                return submitTx();
              })
            }
            else {

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer.sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

                this.setState({
                  // transaction: this.state.transaction,
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Accept',
                  btn_icon: 'check',
                  type_tx: 'med',
                  type_set_tx: 'change_trust',
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
          }
          else {
            return submitTx();
          }
        })
        .then((result) => {
          if (result) {
            ToastStore.success('Trustline added!');
            this.setState({ transaction: false })
            this.getPayment(item, account)
          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          ToastStore.error('Transaction error...');
          showErrorMessage(error, 'change_trust')
        });
    }
  }


  handleClick = (item, account, index) => {

    this.setState({ select_index: index })

    const asset_code = item.asset === 'native' ? 'XLM' : item.asset.split(':')[0]

    const message = `Accept and claim <b class="text-warning">${asset_code}</b> right now? ${item.asset !== 'native' ? '<small class="text-secondary"><br><br>You must have at least 0.5 XLM on your balance.</small>' : ''}`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Confirm",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.getPayment(item, account)
        }
      })
  }


  checkedAllBalances = (e) => {

    const { claimable_balances } = this.state
    const check = e.target ? (e.target.value === 'true' ? false : true) : false

    if (check) {
      this.setState({
        defaultChecked: true,
        mass_claim_balances: claimable_balances,
        claimable_balances: []
      }, () => {
        this.setState({ claimable_balances })
      })
    }
    else {
      this.setState({
        defaultChecked: false,
        claimable_balances: [],
        mass_claim_balances: [],
      }, () => {
        this.setState({ claimable_balances })
      })
    }
  }


  checkedBalance = (balance, e) => {

    const { mass_claim_balances } = this.state

    if (e.target) {
      const check = e.target.value === 'true' ? false : true

      if (check) {
        this.setState({ mass_claim_balances: mass_claim_balances.concat(balance) })
      }
      else {
        this.setState({ mass_claim_balances: mass_claim_balances.filter(item => item.id !== balance.id) })
      }
    }
  }


  massClaim = (e) => {
    e.preventDefault()

    const { mass_claim_balances, account, fee_usd, fee_xlm } = this.state
    const limit = 100

    if (mass_claim_balances > limit) {
      swal({
        title: `No more than ${limit}`,
        text: `No more than ${limit} at a time. Excess by ${mass_claim_balances.length - limit}.`,
        icon: "info",
      })

      return; // stop
    }

    if (!account) {
      ToastStore.info('Account is loading...');
      return
    }

    const claim_operations = [], trust_operations = []

    mass_claim_balances.map(item => {

      if (item.asset !== 'native') {

        const claim_asset = item.asset.split(':')
        const trustLine = account.balances.some(item => (item.asset_code === claim_asset[0] && item.asset_issuer === claim_asset[1]))

        if (!trustLine) {
          trust_operations.push(StellarSdk.Operation.changeTrust({
            asset: new StellarSdk.Asset(claim_asset[0], claim_asset[1]),
          }))
        }
      }

      claim_operations.push(StellarSdk.Operation.claimClaimableBalance({
        balanceId: item.id
      }))
    })

    const all_operations = claim_operations.concat(trust_operations)

    const fees = (all_operations.length * fee_usd)
    const reserve = (trust_operations.length * baseReserve())

    const message = `Claim all balances <span>(<b class="text-info">${mass_claim_balances.length}</b>)</span> right now? 
          <small class="text-secondary"><br><br>Reserve balance <b class="text-info">${reserve}</b> XLM required for <b class="text-info">${trust_operations.length}</b> trustlines <a href="https://developers.stellar.org/docs/fundamentals-and-concepts/lumens#base-reserves" target="_blank"><i class="fa fa-question-circle-o text-secondary"></i></a></small>
          <small class="text-secondary"><br>Fees: $${numFormat(fees, 5)} / ${numFormat(all_operations.length * fee_xlm, 7)} XLM <a href="https://developers.stellar.org/docs/encyclopedia/fees-surge-pricing-fee-strategies" target="_blank"><i class="fa fa-question-circle-o text-secondary"></i></a></small>
        `;

    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Confirm",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.buildTx(claim_operations, trust_operations, account, 0)
        }
      })
  }





  buildTx = (claim_operations, trust_operations, account, index) => {

    const { claimable_balances, mass_claim_balances } = this.state
    const address = account.id
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(address).sk))
    let ops
    if (index === 0) {
      ops = trust_operations
      ToastStore.info('Adding trustlines...')
    }
    if (index === 1) {
      ops = claim_operations
      ToastStore.info('Claiming balances...')
    }

    this.setState({ loading: true })

    Server.loadAccount(address)
      .then((sourceAccount) => {

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: this.fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        ops.forEach(op => tx.addOperation(op));
        tx = tx.setTimeout(0).build();

        if (checkConnectProvider(address)) {
          return signConnectProvider(tx, address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          tx.sign(signer);
          return Server.submitTransaction(tx);
        }
      })
      .then((result) => {

        if (result.hash) {

          if (index === 0) {

            this.buildTx(claim_operations, trust_operations, account, 1)
          }
          else {

            swal({
              title: "Done!",
              icon: "success",
              buttons: {
                confirm: true,
              },
            })
              .then(() => {

                const filter_balances = claimable_balances.filter(item => !mass_claim_balances.includes(item))

                this.setState({
                  loading: false,
                  hash: result.hash,
                  claimable_balances: [],
                  mass_claim_balances: [],
                  defaultChecked: false,
                }, () => {
                  this.setState({ claimable_balances: filter_balances })
                })
              })
          }
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        showErrorMessage(error, 'payment')

        this.setState({ loading: false })
      });
  }




  renderPayment = (item, index, account, type) => {

    const { defaultChecked, is_my_address } = this.state
    const claimant = item.claimants.filter(item => item.destination === account)[0]

    if (claimant) {

      let isSponsor = (item.sponsor === claimant.destination)
      const info = getPredicateInformation(claimant.predicate, new Date());
      const isPredicate = isPredicateClaimableAt(claimant.predicate, new Date())

      return (
        <tr key={index}>
          <td>{index+1}</td>
          {
            this.state.wallets && this.state.is_my_address ?  // this.state.is_my_address
              <td>
                <Button
                  color="success"
                  size="sm"
                  className="table-btn"
                  disabled={!isPredicate}
                  onClick={() => this.handleClick(item, account, index)}>Claim</Button>
              </td> : null
          }
          <td><b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(item.asset)}</td>
          {/*<td>{getAsset(item.asset)}</td>*/}
          {
            type === 'claimant' ?
              <td>{shortAddress(item.sponsor)} {isSponsor ? <small>{item.claimants.length-1} / {((item.claimants.length-1) * baseReserve())} XLM</small> : null}</td>
            :
              <td>{shortAddress(claimant.destination)}</td>
          }

          {/*<td>{shortAddress(claimant.destination)}</td>*/}
          <td>
            <small className={
              `${info.status === 'claimable' ? 'pending' : info.status === 'expired' ? 'expired' : 'upcoming'}-status`}><b>{info.status}</b>
            </small>
          </td>
          <td>
            <small>
              <b className={'text-info'}>
                {
                  info.validFrom ? formatDate(info.validFrom * 1000) : 'Not set'
                }
              </b>
            </small>
          </td>
          <td><small><b className={'text-danger'}>
            {
              info.validTo ? formatDate(info.validTo * 1000) : 'Not set'
            }
          </b></small>
          </td>
          <td><small><Link to={`/claimable-balance/${item.id}`}><b>{shortAddr(item.id, 4)}</b></Link></small></td>
          <td><small>{formatDate(item.last_modified_time)}</small></td>
          {
            is_my_address ?
              <td>
                {
                  this.props.params_id ?
                    <div className="form__form-group form__form-group-field">
                      <Field
                        name={`${this.props.account.id}-${index}`}
                        component={renderCheckBoxField}
                        className="colored-click"
                        defaultChecked={defaultChecked}
                        onChange={(e) => this.checkedBalance(item, e)}
                      />
                    </div>
                  :
                    <a href={`/claimable-balances/${this.props.account}`}><i className={'fa fa-list-ul text-secondary'}></i></a>
                }
              </td>
            : null
          }
        </tr>
      )
    }
  }



  render() {

    const { loading, wallets, is_my_address, activeTab, sponsor_loading, claimable_balances, sponsor_claimants,
      defaultChecked, mass_claim_balances, account,
    } = this.state


    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
            <RequiredSigners account={account}
                             add_signers={this.addSigners}
                             transaction={this.state.transaction}
                             btn_name={this.state.btn_name}
                             icon={this.state.btn_icon}
                             type_tx={'low'} />
            : null
        }

        { loading && <div className="panel__refresh" style={{height: '89%'}}><LoadingIcon /></div> }


        <div className="tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              {
                !this.props.path || this.props.path !== 'balance' ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '1' })}
                      onClick={() => this.toggle('1')}
                    >
                      <b>Claimant</b>
                    </NavLink>
                  </NavItem>
                : null
              }

              {
                !this.props.path ?
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => this.toggle('2')}
                    >
                      <b>Sponsor</b>
                    </NavLink>
                  </NavItem>
                : null
              }
            </Nav>


            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">

                <Table striped responsive>
                  <thead>
                  <tr>
                    <th>#</th>
                    { wallets && is_my_address ? <th>Claim</th> : null }
                    <th>Amount</th>
                    {/*<th>Asset</th>*/}
                    <th>Sponsor</th>
                    {/*<th>Claimant</th>*/}
                    <th>Status</th>
                    <th>Start Claim</th>
                    <th>End Claim</th>
                    {/*<th>Hash</th>*/}
                    <th>Balance</th>
                    <th>Created</th>
                    {
                      is_my_address ?
                        <th>
                          {
                            this.props.params_id ?
                              <div className="form__form-group form__form-group-field"
                                   style={{marginBottom: mass_claim_balances.length ? 0 : '10px' }}>

                                <Field
                                  name={`${this.props.account.id}-all_checked`}
                                  component={renderCheckBoxField}
                                  className="colored-click"
                                  defaultChecked={defaultChecked}
                                  onChange={this.checkedAllBalances}
                                />
                                {
                                  mass_claim_balances.length ?
                                    <span>
                                      {
                                        account ?
                                          <a href="#"
                                             style={{marginTop: '-3px', display: 'inline-block'}}
                                             onClick={this.massClaim}><i className="fa fa-check-circle fs-24 text-success"></i>
                                          </a>
                                          :
                                          <ClipLoader
                                            scc={accountLoader}
                                            color="#36d7b7"
                                            size={18}
                                            speedMultiplier={0.5}
                                            loading={true} />
                                      }
                                    </span>
                                    : null
                                }
                              </div>
                            :
                              <span>Claim all</span>
                          }
                        </th>
                      : null
                    }
                  </tr>
                  </thead>
                  <tbody>

                  { claimable_balances.map((payment, index) => this.renderPayment(payment, index, this.props.account, 'claimant')) }

                  </tbody>
                </Table>
              </TabPane>

              <TabPane tabId="2">

                { sponsor_loading && <Card><CardBody><div className="panel__refresh" style={{height: '93%'}}><LoadingIcon /></div></CardBody></Card> }
                { (!sponsor_loading && !sponsor_claimants.length) && <p>No sponsored balance...</p> }
                {
                  sponsor_claimants && sponsor_claimants.length  ?
                    <Table striped responsive>
                      <thead>
                      <tr>
                        <th>#</th>
                        { wallets && is_my_address ? <th>Claim</th> : null }
                        <th>Amount</th>
                        {/*<th>Asset</th>*/}
                        {/*<th>Sponsor</th>*/}
                        <th>Claimant</th>
                        <th>Status</th>
                        <th>Start Claim</th>
                        <th>End Claim</th>
                        {/*<th>Hash</th>*/}
                        <th>Balance</th>
                        <th>Created</th>
                      </tr>
                      </thead>
                      <tbody>

                      { sponsor_claimants.map((payment, index) => this.renderPayment(payment, index, this.props.account, 'sponsor')) }

                      </tbody>
                    </Table>
                  :
                    null
                }
              </TabPane>

            </TabContent>
          </div>
        </div>

        {
          !this.state.is_my_address ?
            <div>
              <br/>
              <small
                style={{float: 'right'}}
                className={'text-secondary'}><i className={'fa fa-info-o'}></i> Is this <Link to={`/account/${this.props.account}`}>{shortAddr(this.props.account, 4)}</Link> address yours? You can <Link to={'/auth/'}><b>connect</b></Link> this wallet and claim your claimable balances.</small>
            </div>
          : null
        }
      </div>
    );
  }
}


export default reduxForm({
  form: 'ClaimableBalances', // a unique identifier for this form
})(translate('common')(ClaimableBalances));
