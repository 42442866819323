import React, { PureComponent, useState } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, Badge, Alert, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import { shortAddr, numFormat } from '../../../modules/index';
import { get_loc_wallets, getTitle, setLocalWallets } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import Balances from './components/Balances';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import iconIdent from '../Account/components/iconIdent';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import getPrice from "../../App/GetPrice";
import { toggleAuthProvider } from '../../../redux/actions/customizerActions';
import Collapse from '../../../shared/components/Collapse';
import freighterApi from "@stellar/freighter-api";
import {connect} from "react-redux";



let wallets_arr = [], native_balances = 0, counter_balances = 0;


const DropdownSettings = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const deauth = (e, item) => {
    e.preventDefault()
    props.deauth(item.pk)
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="wallet-dropdown">
      <DropdownToggle caret size="sm"></DropdownToggle>
      <DropdownMenu className="text-secondary" style={{fontSize: 14}}>
        <DropdownItem><Link to={`/account/${props.item.pk}`}><i className="fa fa-eye"></i> Explore</Link></DropdownItem>
        <DropdownItem><Link to={`/trustlines/${props.item.pk}`}><i className="fa fa-list"></i> Trustlines</Link></DropdownItem>
        <DropdownItem><Link to={`/offers/${props.item.pk}`}><i className="fa fa-area-chart"></i> Offers</Link></DropdownItem>
        <DropdownItem><Link to={`/account/${props.item.pk}#settings`}><i className="fa fa-cog"></i> Settings</Link></DropdownItem>
        <DropdownItem><a onClick={(e) => deauth(e, props.item)} href="#"><i className="fa fa-sign-out"></i> Log out</a></DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}


class Wallet extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      account: {},
      accounts: [],
      wallets: get_loc_wallets(),
      wallets_format: false,
      wallets_load: false,
      loader: true,
      offers_count: 0,
      claimable_balance_count: 0,
      all_balances_native: 0,
      all_balances_assets_base: 0,
      all_balances_local: 0,
      all_balances_assets_local: 0,
      native_price: 0,
      native_balances: 0,
      asset_balances: 0,
      price: 0,
      changeColor: 'secondary',
      change: false,
      all_invoices: [],
      connect_provider: false,
    };
  }

  count = 0;

  componentDidMount() {

    const title = 'Stellar Wallet | DeFi | Non-Custodial';
    const description = 'Make payments or payments with exchange along the path of offers (PathPayment) in crypto or in any of your tokens, around the world, without restrictions, without fees.';

    getTitle(title, description);

    this.getBasePrice();

    // console.log('this.state.wallets: ', this.state.wallets)

    if (this.state.wallets) {
      this.getFederation()
    }
    else {
      this.setState({ loader: false, })
    }

    // this.getConnectProvider()
  }


  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({
        price: price.crypto_price.xlm_usd,
        changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success',
        change: price.crypto_price.usd.percent_change_24h,
      });
    }
    else if (price && price.local_price) {
      this.setState({
        price: price.local_price.USD,
      })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  // getConnectProvider = () => {
  //
  //   let connect_provider = localStorage.getItem('connect_provider')
  //   console.log('connect_provider: ', connect_provider)
  //
  //   if (connect_provider) {
  //     connect_provider = JSON.parse(connect_provider)
  //
  //     console.log('connect_provider: ', connect_provider)
  //
  //     if (connect_provider.provider === 'freighter') {
  //       this.checkFreigther(connect_provider)
  //     }
  //   }
  // }


  // checkFreigther = (connect_provider) => {
  //   if (freighterApi.isConnected()) {
  //     this.setState({ connect_provider })
  //     //  push to wallets
  //   }
  // }



  deauthAddress = (address) => {

    // console.log('address: ', address)

    swal({
      title: "Attention!",
      text: "Are you sure you want to log out an account from local memory right now?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then(confirm => {
      if (confirm) {

        const wallets = this.state.wallets.filter(item => item.pk !== address)
        // console.log('deauthAddress wallets: ', wallets)

        if (!wallets.length) {
          localStorage.removeItem('connect_provider')
          localStorage.removeItem('wallets')
          this.setState({
            wallets: false,
            wallets_load: false
          })
        }
        else {

          setLocalWallets(wallets)
          this.setState({ wallets })

          this.state.wallets.forEach(item => {
            if (item.provider) {
              localStorage.removeItem('connect_provider')
              this.setState({ wallets })
            }
          })
        }

        swal({
          title: "Log out!",
          text: "Wallet successfully deauthorized.",
          icon: "success",
          confirm: true,
        })
          .then(confirm => {
            if (confirm) {
              this.props.dispatch(toggleAuthProvider(false));
            }
          });
      }
    });
  }



  getFederation = () => {

    let addresses = this.state.wallets.map(item => {
      return item.pk;
    })

    const formData = new FormData();
    formData.append('addresses', addresses);

    axios.post(GLOBE.API_URL+`/get_federations`, formData)
      .then(result => {
        // console.log('get_federations result: ', result)

        if (result.data !== 'not') {
          this.state.wallets.forEach((item) => {

            result.data.forEach(item2 => {
              if (item.pk === item2.account_id) {
                item.federation = item2.stellar_address;
              }
            })
          })

          // console.log('federation item: ', item)

          // this.setState({ wallets_format: this.state.wallets })
          // setTimeout(() => {
          //   this.getWallets();
          // }, 100)

          // this.getWallets();
        }

        this.getWallets();

      })
      .catch((error) => {
        console.log('error get_federations: ', error)
        this.getWallets();
    });
  }


  getWallets = () => {

    const wallets = this.state.wallets;

    if (wallets) {

      wallets.forEach((item) => {
        this.getAccount(item)
      });
    }
    else {
      this.setState({ loader: false });
    }
  }


  getAccount(item) {
    // console.log('getAccount item: ', item)

    Server.loadAccount(item.pk)
      .then(account => {
        // console.log('sourceAccount: ', account)

        if (account) {

          item.account = account;

          // let native_balances = 0;
          account.balances.forEach((item) => {
            if (item.asset_type === 'native') {
              const native_balances = (this.state.native_balances + Number(item.balance))
              this.setState({ native_balances })
            }
            else {
              // this.state.asset_balances = (this.state.asset_balances + Number(item.balance))
              if (item.asset_type !== 'liquidity_pool_shares') {
                this.getTrades(item);
              }
            }
          })

          wallets_arr.push(item);

          if (this.state.wallets.length === wallets_arr.length) {

            this.setState({
              wallets: wallets_arr,
              accounts: this.state.accounts.concat(item),
              wallets_load: true,
              loader: false,
              // all_balances_native: native_balances,
              // native_balances,
              // asset_balances: this.state.asset_balances,
            })

            // this.getAllAssetsAmount(wallets_arr)
          }

          this.getOffers(item.pk);
          this.getClaimableBalance(item.pk);
        }
      })
      .catch(error => {
        console.log('error load: ', error);

        item.account = {
          balances: false
        };
        wallets_arr.push(item);

        if (this.state.wallets.length === wallets_arr.length) {
          this.setState({
            wallets: wallets_arr,
            wallets_load: true,
            loader: false,
          })
        }
    })
  }


  getClaimableBalance = async (address) => {

    const { records } = await Server.claimableBalances()
      .claimant(address)
      .call()

    // console.log('records: ', records)

    let length = records.length;
    if (length > 0) {
      this.setState({
        claimable_balance_count: (this.state.claimable_balance_count + length)
      })
    }
  }


  getOffers = (address) => {

    Server.offers()
      .forAccount(address)
      .call()
      .then((offerResult) => {
        // console.log('offerResult: ', offerResult)
        let length = offerResult.records.length;
        if (length > 0) {
          this.setState({
            offers_count: (this.state.offers_count + length)
          })
        }
      })
      .catch(function (err) {
        console.error(err);
      })
  }


  // getAllAssetsAmount(wallets) {
  //   wallets.forEach((item, index) => {
  //     item.account.balances.forEach((item, index) => {
  //       if (item.asset_type !== 'native') {
  //         this.getTrades(item);
  //       }
  //     })
  //   })
  // }


  getTrades(asset) {

    const base = new StellarSdk.Asset.native();
    const counter = new StellarSdk.Asset(asset.asset_code, asset.asset_issuer);
    const startTime = 0;
    const endTime = 0;
    const resolution = 86400000;
    const offset = 0;

    Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
    .order('desc')
    .limit(1)
    .call()
    .then((tradesRes) => {
      if (tradesRes.records) {
        let base_price = (tradesRes.records[0].open_r.d / tradesRes.records[0].open_r.n);
        let token_to_base = (Number(asset.balance) * base_price);
        counter_balances = (counter_balances + token_to_base);

        this.setState({
          all_balances_assets_base: counter_balances,
          asset_balances: (this.state.asset_balances + token_to_base)
        });
      }
    })
    .catch((err) => {
      console.log('err trades count', err);
    })
  }


  deauthorize = () => {

    swal({
      title: "Confirm",
      text: "Do you really want to log out all wallets right now?",
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
    .then((value) => {
      if (value) {
        localStorage.removeItem('wallets');
        this.setState({
          wallets: false,
          wallets_load: false,
        })
        ToastStore.success('All addresses are deauthorized');
      }
    });
  }


  allInvoices = (value) => {
    this.setState({
      all_invoices: this.state.all_invoices.concat(value)
    })
  }


  renderWallet = (item, index) => {
    // console.log('renderWallet item: ', item)

    let dropdownOpen = false

    const toggle = () => {
      dropdownOpen = !dropdownOpen;
    }

    return (
      <div key={index}>

        <Card key={index}>
          <span className="text-secondary item-count">{index+1}</span>
          <CardBody>
            <div className="card__title">
              <h4 className="bold-text" style={{fontSize: '16px'}}>
                <Link to={`/account/${item.pk}`}
                      className={!item.account.balances ? 'text-secondary' : ''}>
                  {iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)} <small className="addr-name text-secondary">[{item.title}]</small></Link> &nbsp;
                <CopyToClipboard text={item.pk} onCopy={() => {}}>
                  <i className="fa fa-clone"
                     color="secondary"
                     style={{cursor: 'pointer', fontSize: 14}}
                     onClick={() => ToastStore.success('Copied!')}></i>
                </CopyToClipboard>
                {item.provider && <span>&nbsp;&nbsp; <img src={`/img/icons/auth/${item.provider}.svg`} alt="" style={{width: 13}} /></span> }

                <span style={{float: 'right'}}>
                  <DropdownSettings item={item} deauth={this.deauthAddress} />
                </span>

              </h4>
              {
                item.federation ?
                  <h5 className="subhead">Federation Address: <b className="text-info">{item.federation}</b> {' '}
                    <CopyToClipboard text={item.federation} onCopy={() => {}}>
                      <i className="fa fa-clone"
                         style={{cursor: 'pointer'}}
                         onClick={() => ToastStore.success('Federation address copied!')}></i>
                    </CopyToClipboard>
                  </h5>
                : null
              }

            </div>
            {/*<font>Total Balances: <Link to={'/balances/'+item.account.id}><b>{item.account.balances.length}</b></Link></font>*/}

            {
              this.state.wallets && item.account.balances ?
                <Balances account={item.account}
                          limit={10}
                          allInvoices={this.allInvoices}
                          wallets={this.state.wallets} />
              :
                <div>
                  <p><b>The Address is not activated.</b> <br/>Activate this Address by sending <b>2 XLM</b> to it.</p>
                  <br/>
                  <div><a href="https://developers.stellar.org/docs/glossary/accounts/"
                        target={'_blank'} rel='noopener noreferrer'>Learn more about account creation</a>
                  </div><br/>
                  <div><Link to="/exchange" className={'btn btn-outline-secondary btn-sm mb-0'}>Buy XLM</Link></div>
                </div>
            }
          </CardBody>
        </Card>
      </div>
    )
  }


  render() {
    // console.log('state: ', this.state)

    const native_balances_local = (this.state.native_balances * this.state.price);
    const asset_balances_local = (this.state.asset_balances * this.state.price)

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>*/}
        {/*    <Col md={12}>*/}
        {/*      <h3 className="page-title">Wallets ({this.state.wallets ? this.state.wallets.length : 0})</h3>*/}
        {/*      <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>You can create an <b>unlimited</b> number of wallets and have <b>full control</b> and <b>management</b> over them</h4>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}

        {
          this.state.wallets ?
            <Row className={'text-center'}>
              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">All Balances XLM</h5>
                    </div>
                    <h5 className="bold-text"><span><span className="text-info amount-result">{numFormat(this.state.native_balances)}</span> <Link to={'/native'} className="text-warning">XLM</Link></span></h5>
                    {/*<small>The total amount of XLM on all addresses.</small>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">All Balances XLM/USD</h5>
                    </div>
                    <h5 className="bold-text"><span  className="text-info amount-result">{numFormat(native_balances_local)}</span> <font className="text-warning">USD</font></h5>
                    {/*<small>The total amount of XLM / USD at all addresses.</small>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">All Assets USD</h5>
                    </div>
                    <h5 className="bold-text"><span className="text-info amount-result">{numFormat(asset_balances_local + native_balances_local)}</span> <font className="text-warning">USD</font></h5>
                    {/*<small>The total potential amount for all tokens in USD.</small>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col md={3} lg={3}>
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Price XLM</h5>
                    </div>
                    <h5 className="bold-text"><span className="text-info">{numFormat(this.state.price, 4)}</span> <span className="text-warning">USD</span> {this.state.change && <Badge color={this.state.changeColor}>{this.state.change > 0 ? '+' : ''}{numFormat(this.state.change, 2)}%</Badge>}</h5>
                    {/*<small>Price comes from the API <a href={'https://coinmarketcap.com/'} target='_blank' rel='noopener noreferrer'>CoinMarketCap</a></small>*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          : null
        }

        <Row className="addr-list-wr">

          <Col md={12} lg={8}>

            { this.state.loader ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : '' }

            {
              this.state.wallets_load ?
                this.state.wallets.map(this.renderWallet)
              : null
            }

            {
              !this.state.wallets ?
                <Card style={{height: 'auto'}}>
                   <CardBody>
                      <p className="" style={{paddingTop: '20px'}}>
                        <h4>No authorized accounts.</h4>
                        <Link to="/auth/create">Create Account</Link> or <Link to="/auth/connect">Connect Wallet</Link>.
                      </p>
                      {/*<br/>
                      <p><small>
                        <b>Address authorization options:</b>
                        <br/>
                        1. Create Stellar Address<br/>
                        2. Recover wallet from recovery token<br/>
                        3. Authorize the wallet from the secret key
                      </small></p>
                      <br/>*/}
                   </CardBody>
                  </Card>
                : null
            }

            <Card style={{height: 'auto'}}>
             <CardBody>
                <Alert color={'info'} className={'alert--bordered'}>
                <div className="alert__icon"><CommentAlertOutlineIcon /></div>
                <div className="alert__content" style={{padding: '20px'}}>
                  <p><span className="bold-text">IMPORTANT TO KNOW!</span><br/></p>
                  <p><i>The minimum amount of XLM on a wallet is currently 0.5 XLM.</i></p>
                  <p>Each Stellar account must have this amount in order to be active on the network.
                  Any transaction that would reduce the balance of an account to less than the minimum
                  will not work. Further information can be found under <a href="https://www.stellar.org/developers/guides/concepts/fees.html" target="_blank">this link</a>.</p>
                </div>
              </Alert>
             </CardBody>
            </Card>

          </Col>

          <Col md={12} lg={4}>
            <Card style={{height: '100%'}}>
              <CardBody>
                  <ButtonGroup className="btn-group--justified">
                    <Link to="/auth/create" className="btn btn-success"><i className="fa fa-plus"></i> Create Wallet</Link>
                    <Link to="/auth/connect" className="btn btn-primary"><i className="fa fa-sign-in"></i> Connect Wallet</Link>
                  </ButtonGroup>
                  <ButtonGroup className="btn-group--justified">
                    <Link className="btn btn-outline-secondary" to="/send"><i className="fa fa-location-arrow"></i> Send</Link>
                    <Link className="btn btn-outline-secondary" to="/receive"><i className="fa fa-qrcode"></i> Receive</Link>
                  </ButtonGroup>
                  <ButtonGroup className="btn-group--justified">
                    <Link className="btn btn-outline-secondary" to="/trade"><i className="fa fa-bar-chart-o"></i> Trade</Link>
                    <Link className="btn btn-outline-secondary" to="/swap"><i className="fa fa-exchange"></i> Swap</Link>
                  </ButtonGroup>
                  <ButtonGroup className="btn-group--justified">
                    <Link className="btn btn-outline-secondary" to="/pools"><i className="fa fa-database"></i> Liquidity Pools</Link>
                    <Link className="btn btn-outline-secondary" to="/exchange"><i className="fa fa-money"></i> Buy/Sell Crypto</Link>
                  </ButtonGroup>
                  <ButtonGroup className="btn-group--justified">
                    <Link className="btn btn-outline-secondary" to="/create-token"><i className="fa fa-cubes"></i> Create Token</Link>
                    <Link className="btn btn-outline-secondary" to="/rewards"><i className="fa fa-diamond"></i> Rewards</Link>
                    {/*<Link className="btn btn-outline-secondary" to="/claimable-balances"><i className="fa fa-clock-o"></i> Claimable Balance</Link>*/}
                    {/*<Link className="btn btn-outline-secondary" to="/explorer"><i className="fa fa-search"></i> Block Explorer</Link>*/}
                  </ButtonGroup>

                  <br/>

                  {
                    this.state.wallets_load ?
                      <div>
                        <div className="card__title">
                          <h5 className="bold-text">Summary to all Accounts</h5>
                        </div>
                        <p>Offers: <Link to={'/offers/'}><b>{this.state.offers_count}</b></Link></p>
                        <p>Claimable Balances: <Link to={'/claimable-balances/'}><b>{this.state.claimable_balance_count}</b></Link></p>
                        <p>Invoices: <Link to={'/invoices/'}><b>{this.state.all_invoices.length}</b></Link></p>

                        {/*<p>
                          <span>Potential Amount: <b className="text-info">45,098 XLM</b> / <b className="text-info">23,746 USD</b></span>
                        </p>*/}
                        <br/><br/><br/>
                        <ButtonGroup className="btn-group--justified">
                          <Button color="secondary text-muted" outline onClick={this.deauthorize.bind(this)}><i className="fa fa-times"></i> Log out all wallets</Button>
                        </ButtonGroup>
                      </div>
                    : null
                  }

                  {/*<br/>*/}

                  {/*<div className={'text-center'}>*/}
                  {/*  <a href="/launch/scopuly?ref=scopuly">*/}
                  {/*    <img src="https://coincodex.com/823kfxez4m/2022_05_06_scopuly/c187f0996600629285dc8dca017d12a8.jpg" alt="scopuly"/>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
              </CardBody>
            </Card>
          </Col>

          {/*<Col md={8} lg={8}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">GBWZHAVWY23QKKDJW7TXLSIHY5EX4NIB37O4NMRKN2SKNWOSE5TEPCY3</h5>
                  <h5 className="subhead">Name Account</h5>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum ornare dolor, quis
                      ullamcorper ligula sodales at. Nulla tellus elit, varius non commodo eget, mattis vel eros. In
                      sed ornare nulla. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus
                      et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula
                      ut id elit.
                </p>
              </CardBody>
            </Card>
          </Col>*/}
        </Row>

        <Footer />

      </Container>
    );
  }
}


Wallet.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuthProvider: state.customizer.isAuthProvider
  }
}


export default connect(mapStateToProps)(translate('common')(Wallet));
