import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import LoadingIcon from 'mdi-react/LoadingIcon';
import StellarCap from '../../Home/components/StellarCap';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { numFormat } from '../../../../modules/index';
import getPrice from "../../../App/GetPrice";
import Footer from "../../../Layout/footer/Footer";


class NativeXLM extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      lumens: false,
      distributed_program: 0,
      cap: false,
    };
  }

  count = 0;

  componentDidMount() {
    this.getLumens();
    this.getBasePrice()
  }


  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({
        price: price.crypto_price.xlm_usd,
        cap: price.crypto_price.usd.market_cap,
        volume: price.crypto_price.usd.volume_24h,
        change: price.crypto_price.usd.percent_change_24h,
        changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success'
      });
    }
    else if (price && price.local_price) {
      this.setState({
        price: price.local_price.USD,
      })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }



  formatCount(count, withAbbr = false, decimals = 2) {
    const COUNT_ABBRS = [ '', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y' ];
    const i     = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
    let result  = parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
    if (withAbbr) {
        result += `${COUNT_ABBRS[i]}`;
    }
    return result;
  }


  getLumens() {

    axios.post('https://api.scopuly.com/api/get_native_data')
    .then(response => {

      let programs = response.data.programs;
      let amount = (Number(programs.directDevelopment) + Number(programs.ecosystemSupport) + Number(programs.useCaseInvestment) + Number(programs.userAcquisition));

      this.setState({
        lumens: response,
        distributed_program: amount,
      })
    });
  }


  setOptions() {

    const theme = localStorage.getItem('theme');

    let options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            backgroundColor: 'transparent',
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    style: {
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                    }
                }
            }
        },
        series: [{
            name: 'Lumens (XLM)',
            colorByPoint: true,
            data: [{
                name: 'Available Coins',
                y: Number(this.state.lumens.data.availableCoins),
            }, {
                name: 'Direct Development',
                y: Number(this.state.lumens.data.programs.directDevelopment)
            }, {
                name: 'Ecosystem Support',
                y: Number(this.state.lumens.data.programs.ecosystemSupport)
            }, {
                name: 'Use CaseInvestment',
                y: Number(this.state.lumens.data.programs.useCaseInvestment)
            }, {
                name: 'User Acquisition',
                y: Number(this.state.lumens.data.programs.userAcquisition)
            }]
        }]
    }

    return options;
  }



  render() {

    if (this.state.lumens) {
      var options = this.setOptions();
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title"><b>XLM <small className={'text-secondary'}>
              <img src="/img/logo/stellar-logo-gray.png" alt="stellar-logo" className={'native-logo'} />
              <b>Stellar Lumens</b></small></b> {' '}
              <span style={{float: 'right'}}>
                <Link to={'/exchange'} className={'btn btn-secondary'}>Buy / Sell XLM
                  <img className={'pay-crypto'} src="/img/icons/card.png" />
                </Link>
              </span>
            </h3>
            <h3 className="page-subhead subhead">
                {/*Stellar was built to support digital representations of any currency, and it also has its own native <b>XLM</b> token called <b><i>lumen</i></b>, designed to fulfill a specific role in the network.*/}
                Stellar was built to support digital representations of any currency, and it also has its own native <b>XLM</b> token called <b><i>lumen</i></b>, designed to fulfill a specific role in the network.
            </h3>
          </Col>
        </Row>

        { this.state.cap && <StellarCap {...this.state} /> }

        <Row>
          <Col md={4} xl={4} lg={4} >
            <Card>
              <CardBody className="dashboard__card-widget text-center">
                {!this.state.lumens ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">TOTAL LUMENS</h5>
                  <h5 className="subhead">{this.state.lumens ? numFormat(this.state.lumens.data.totalCoins, 7) : '...'} XLM</h5>
                </div>
                <h3 className="bold-text">{this.state.lumens ? this.formatCount(this.state.lumens.data.totalCoins, true, 2) : '...'} XLM</h3>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Card>
              <CardBody className="dashboard__card-widget text-center">
                {!this.state.lumens ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">LUMENS AVAILABLE (NOT HELD BY SDF)</h5>
                  <h5 className="subhead">{this.state.lumens ? numFormat(this.state.lumens.data.availableCoins, 7) : '...'} XLM</h5>
                </div>
                <h3 className="bold-text">{this.state.lumens ? this.formatCount(this.state.lumens.data.availableCoins, true, 2) : '...'} XLM</h3>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} xl={4} lg={4} xs={12}>
            <Card>
              <CardBody className="dashboard__card-widget text-center">
                {!this.state.lumens ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">DISTRIBUTED PROGRAMS</h5>
                  <h5 className="subhead">{this.state.lumens ? numFormat(this.state.distributed_program, 7) : '...'} XLM</h5>
                </div>
                <h3 className="bold-text">{this.state.lumens ? this.formatCount(this.state.distributed_program, true, 2) : '...'} XLM</h3>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="dashboard__card-widget">
                {!this.state.lumens ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                <div className="card__title">
                  <h5 className="bold-text">Distribution Progress</h5>
                </div>
                {
                  this.state.lumens ?
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                    : null
                }
              </CardBody>
            </Card>


          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Why does Stellar require lumens?</h5>
                </div>
                <div>
                  The need for lumens (XLM) arose from the design features of the Stellar accounting system. Without any nominal barriers or costs, Stellar's registry can be filled with spam or used as a kind of arbitrary distributed database system. These results would destroy Stellar's core vision: to be a fast and efficient international payment system.
                  <br/><br/>
                  To solve this problem, even the slightest friction had to be introduced to scare away bad or frivolous participants. Setting a minimum balance on each account and a very low transaction fee were chosen as a deterrent cost. Currently, the minimum balance is 1 XLM and the minimum transaction fee is 0.00001 XLM. They are small enough to make Stellar widely available, but large enough to discourage large-scale bad behavior.
                  <br/><br/>
                  Since Stellar is a universal system for moving digital money, it would be possible to allow people to pay these expenses in dollars, pesos, yuan or whatever. First, there was no way for the network to "prefer" any particular national currency - if Stellar used, say, dollars, then network prices would remain fixed for Americans, but smooth for everyone else. Moreover, it was required to create an asset focused primarily on digital technologies, which embraces the openness of the Internet and does not depend on economic and political factors.
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col md={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">The lumen supply</h5>
                </div>
                <div>
                  Unlike other blockchain tokens, lumens are not mined or awarded by the SCP protocol. Instead, when the Stellar network went live, 100 billion lumens were created, and during the first 5 or so years of Stellar's existence, the number of lumens also increased by 1% annually, as intended.
                  <br/> <br/>
                  This inflation mechanism was terminated by a community vote in October 2019. And in November 2019, the total number of gaps was reduced. There are about 50 billion lumens now, and there won't be any more.
                  <br/> <br/>
                  With about 20 billion lumens going to the open market, the Stellar Development Foundation leaves another 30 billion or so to develop and market Stellar in accordance with its mandate. These lumens will hit the public markets over the next few years. Anyone looking to fully account for all of the existing XLMs should visit the Lumen Guide for detailed explanations of basic luminous flux numbers, as well as instructions on how to calculate flow data from scratch using the Stellar API.
                  <br/> <br/>
                  A natural and pleasant byproduct of having an XLM network token is that it facilitates the movement of money between users. Since everyone has and needs lumens, lumens can always be a medium of exchange between illiquid assets.
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>


        {/*<Row>
          <Col md={12}>
            asdfasdfasdf
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            asdfasdfasdf
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            asdfasdfasdf
          </Col>
        </Row>*/}

        <Footer />

      </Container>
    );
  }
}


NativeXLM.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(NativeXLM);

