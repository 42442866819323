import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Collapse from '../../../shared/components/Collapse';
import { GLOBE } from '../../../modules/globeVars/index';
import Footer from '../../Layout/footer/Footer';
import { getTitle } from '../../../modules/index';



const renderTextField = ({
  input, label, type, multiline, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      select={select}
      type={type}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}


renderTextField.defaultProps = {
  meta: null,
  select: false,
  type: false,
  multiline: false,
  children: [],
};



class Help extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
    };
  }

  componentDidMount() {
    getTitle(`Help`);
  }




  submitForm(values) {

    if (!values.name) {
      ToastStore.warning("Enter your name");
    }
    else if (!values.email) {
      ToastStore.warning("Enter your Email");
    }
    else if (!values.message) {
      ToastStore.warning("Enter your message");
    }
    else {

      this.setState({ loader: true });
      axios.post(`${GLOBE.API_URL}/send_support_mess?name=${values.name}&email=${values.email}&message=${values.message}`)
      .then(res => {

        if (res.data === 'ok') {
          this.setState({ loader: false });
          swal({
            title: "Sent!",
            text: "Thank you for contacting. Your message has been sent successfully.",
            icon: "success",
            confirm: true,
          })
          .then(confirm => {
            if (confirm) {
              this.props.reset();
            }
          });
        }
      });
    }
  }



  render() {

    const {reset, handleSubmit } = this.props;
    const size_col = 6, offset_col = 3;

    return (
        <Container className="dashboard">

          <Row>
            <Col md={12}>
              <h3 className="page-title">Support</h3>
            </Col>
          </Row>

          <Row>
            <Col md={7}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">FAQ</h5>
                    <h4 className="subhead">Answers to frequently asked questions</h4>
                  </div>

                  <Collapse title="What happens if I lose my phone? Should I lose my wallet and its contents?"
                            className="with-shadow">
                    <p>To restore the wallet on another phone - you will need to import (restore) the wallet from the recovery token that you received when creating your account.</p>
                  </Collapse>

                  <Collapse title="What if I lose the encryption password?"
                            className="with-shadow">
                    <p>If you lose your encryption password, you will not be able to recover your wallet. Only you and no one else have this secret data.</p>
                  </Collapse>

                  <Collapse title="What if I lose the recovery token?"
                            className="with-shadow">
                    <p>If you lose your recovery token, you will not be able to recover your wallet. Only you and no one else have this secret data.</p>
                  </Collapse>

                  <Collapse title="Where is my secret key used to store transactions?"
                            className="with-shadow">
                    <p>Your secret key from the wallet is stored only with you. To sign a transaction, the secret key is temporarily stored in encrypted form in the Local Storage of your device. The secret key is never sent and is not stored anywhere except the user's local memory. Each user is solely responsible for accessing his wallet. Thus, while observing simple safety rules, the user’s tools are under his complete control and only he and no one else have access to the wallet.</p>
                  </Collapse>

                  <Collapse title="What if my recovery token is damaged?"
                            className="with-shadow">
                    <p>If your recovery token does not match the original exported text format, this will generate a decryption error and you will not be able to repair your wallet. The restore token should be exactly the same as you received it when exporting.</p>
                  </Collapse>

                  <Collapse title="Where is the best place to store recovery data?"
                            className="with-shadow">
                    <p>1. On a flash drive / disk</p>
                    <p>2. It is better to duplicate to another external media.</p>
                  </Collapse>

                  <Collapse title="What is the best way to store recovery data (token and password)?"
                            className="with-shadow">
                    <p>If you have several exported wallets, you may need to somehow organize this data like: Address, token, password.</p>
                  </Collapse>

                  <Collapse title="How is the exchange of tokens between users?"
                            className="with-shadow">
                    <p>Exchange of one asset for another occurs between two users without the participation of any third party. This is a completely decentralised process.</p>
                  </Collapse>

                  <Collapse title="Recommendations before working with the account"
                            className="with-shadow">
                    <p>1. Be sure to securely save your recovery data (recovery token and password) to external physical media. Better duplicate this data to multiple external physical media.</p>
                    <p>2. Before replenishing the balance of your newly created account, go to the wallet page and delete it from the local memory.</p>
                  </Collapse>

                  {/*<Collapse title="Last group" className="with-shadow">
                    <p>No comfort do written conduct at prevent manners on. </p>
                  </Collapse>

                  <Collapse title="Last group" className="with-shadow">
                    <p>No comfort do written conduct at prevent manners on. </p>
                  </Collapse>*/}
                      

                </CardBody>
              </Card>
            </Col>

            <Col md={5}>
              <Card style={{height: 'auto'}}>
                <CardBody>

                  { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                  <div className="card__title">
                    <h5 className="bold-text">Support</h5>
                    <h4 className="subhead">If you have not found the answer to your question, ask it here</h4>
                  </div>

                  <form className="material-form" onSubmit={handleSubmit(this.submitForm.bind(this))}>

                    <div>
                      <Field
                        name="name"
                        component={renderTextField}
                        label={`Name`}
                      />
                    </div>

                    <div>
                      <Field
                        name="email"
                        component={renderTextField}
                        label="Email"
                        type="email"
                      />
                    </div>

                    <div>
                      <Field
                        name="message"
                        component={renderTextField}
                        label="Message"
                        multiline
                      />
                    </div>

                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">Submit</Button>
                      <Button type="button" onClick={reset}>Reset</Button>
                    </ButtonToolbar>
                  </form>

                  <p>Email: info@scopuly.com</p>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Footer />

        </Container>
    );
  }
}


Help.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Help));


