import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';





class Sep0007 extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      uri: false
    };
  }


  componentDidMount() {

    const uri = decodeURIComponent(this.props.match.params.id)
    const parse_uri = parseStellarUri(uri);

    this.setState({ uri: parse_uri })
  }




  render() {

    console.log('SEP state: ', this.state)

    if (this.state.uri && this.state.uri.operation === 'pay') {
      return <Redirect to={`/pay/${this.props.match.params.id}`} />
    }
    if (this.state.uri && this.state.uri.operation === 'tx') {
      return <Redirect to={`/tx/${this.props.match.params.id}`} />
    }

    return (
      <></>
    );
  }
}



export default Sep0007;
