import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';



class ForInvestors extends PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {  
    getTitle(`For Investors`);
  }



  render() {

    const token_path = '/asset/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ';

    return (
        <Container>

          {/*<Row>
            <Col md={12}>
              <h3 className="page-title">Contacts</h3>
              <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>fasdfasfasdf</h4>
            </Col>
          </Row>*/}

          <Row>
            <Col md={{ size: 12 }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">For Investors</h5>
                    <h5 className="subhead">Thank you for taking the time to explore Scopuly</h5>
                  </div>

                  <div>
                    <p>Scopuly is the perfect way to buy, trade, pay & transfer crypto and invest in promising startups.</p>

                    <p>Scopuly Coin (SCOP) is a Scopuly platform token, it allows you to: invest in IEO projects; launch IEO projects on the platform; pay fees from crypto-fiat exchange transactions; access future platform services.</p>

                    <p>The SCOP token is issued and works on the Stellar blockchain.</p>

                    <p>More information on the <a href={token_path} className="text-warning"><b>SCOP</b></a> token can be found <a href="http://scop.scopuly.com/">here</a>.</p>

                    <p>If the Scopuly project is of interest to you, or satisfies your investment strategy in digital assets and you want to expand your portfolio with a young and promising SCOP asset, you can participate in the Scopuly token sale and purchase SCOP tokens on exchanges right now.</p>

                    <p>You can buy a SCOP token in any Stellar wallet or exchange that supports Stellar Assets & SDEX, or directly in <a href="/asset/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ">Scopuly</a>.</p>

                    <p>If you have a proposal for a more standard investment model in the form of an entrance to equity participation, then contact us and we will discuss your proposal.</p>

                    <p>For any questions, write to our Telegram <i>@scopuly</i></p>
                  </div>
                </CardBody>
              </Card>   
            </Col>
          </Row>

          <Footer />
          
        </Container>
    );
  }
}


export default translate('common')(ForInvestors);

