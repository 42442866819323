import React, { PureComponent } from 'react';
import {translate} from "react-i18next";



class ScrollToTop extends PureComponent {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
    };
  }


  scrollStep() {
    if (window.scrollY === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.scrollY - this.props.scrollStepInPx);
  }

  scroll() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    //store the intervalId inside the state,
    //so we can use it later to cancel the scrolling
    this.setState({ intervalId: intervalId });
  }


  render () {

    return (
      <div >
        <button href='#' title='Back to top'
                className='scroll-to-top'
                onClick={ (event) => {
                  event.preventDefault();
                  this.scroll();
                }}>
          <i className={'fa fa-chevron-up'}></i>
        </button>
      </div>
    )
  }
}

export default (ScrollToTop);
