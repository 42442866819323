import React, { PureComponent } from 'react';

class ToggleShowNotification extends PureComponent {

  render() {
    const { toggleShowNotification, customizer } = this.props;

    return (
        <div className="toggle-btn customizer__toggle">
          <input
              className="toggle-btn__input"
              type="checkbox"
              name="notif_toggle"
              checked={customizer.showNotification}
              onChange={() => {}}
          />
          <button
              className="toggle-btn__input-label"
              onClick={toggleShowNotification}
          >
            Toggle
          </button>
          <span>{'Show Notifications'}</span>
        </div>
    );
  }
}

export default ToggleShowNotification;
