import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
  Popover,
  PopoverBody,
  UncontrolledTooltip
} from 'reactstrap';
import { Field, reduxForm, change} from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GLOBE } from '../../../modules/globeVars/index';
import axios from 'axios';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import {
  shortAddr,
  numFormat,
  formatDate,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle,
  showErrorMessage,
  getAsset,
  getIconConnectProvider,
  checkConnectProvider,
  signConnectProvider,
  getStellarFee,
  getBasePrice,
  getFloatValue,
  getSigner, shortAddress, itemType
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import { QRCode } from 'react-qrcode-logo';
import iconIdent from '../Account/components/iconIdent';
import AutorenewIcon from "mdi-react/AutorenewIcon";
import Collapse from "../../../shared/components/Collapse";
import {getAssetInfo, savePaymentToDB, saveQRPayment} from "../../../endpoints/API";
import {getOperationForTransaction} from "../../../endpoints/StellarAPI";


let wallets_arr = [];


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Pay extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      signer: false,
      is_auth: false,
      loader: false,
      select_addr: false,
      select_asset: false,
      uri: false,
      asset: false,
      amount: 0,
      memo: false,
      msg: false,
      domain: false,
      recipient: false,
      date: false,
      path_payment: false,
      path_result: false,
      pay_show: false,
      popoverOpen: false,
      disabled: true,
      asset_db: false,
      trustline: false,

      transaction: false,
      // invoice_id: false,
      // result_tx: false,
      fee_xlm: 0,
      fee_usd: 0,
      need_path: false,
      show_send_btn: true,
      loading_path: false,
      proc_message: 'Search Path Payments...',
      hash: false,
      operation: false,
    };
  }

  fee = getStellarFee()


  componentDidMount() {

    const params = this.props.match.params.id;
    const uri = decodeURIComponent(params)

    if (uri.indexOf('web+stellar') + 1) {
      this.setOptionsUri(uri)
    }
    else {
      this.getInvoice(params)
    }

    // this.filterAddress()
    this.getXLMPrice()

    //
    // this.testGetStrictReceivePaths()
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)

    if (stellar) {
      const base_price = stellar.market_data.current_price.usd
      const fee_xlm = (this.fee / 10000000)
      const fee_usd = (fee_xlm * base_price)

      this.setState({
        stellar,
        base_price,
        fee_xlm,
        fee_usd,
      })
    }
  }


  testGetStrictReceivePaths = () => {


    var sourceAccount = 'GBT2RH3RELBKMXVPEKTR7TMEV6YFSIEDOK63SH2XOHM74KFU3PZF2BMY';
    var destinationAmount = 100;

    // if (this.state.asset[1] === 'native') {
    //   var destinationAsset = StellarSdk.Asset.native();
    // } else {
    //   var destinationAsset = new StellarSdk.Asset(this.state.asset[0], this.state.asset[1])
    // }

    // var destinationAsset = StellarSdk.Asset.native();
    // var destinationAsset = new StellarSdk.Asset('SKY', 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA')
    var destinationAsset = new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ')

    Server.strictReceivePaths(sourceAccount, destinationAsset, destinationAmount)
      .call()
      .then( (pathResult) => {

        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }
      })
      .catch( (err) => {
        console.log(err)
      })

    Server.strictSendPaths(destinationAsset, destinationAmount, sourceAccount)
      .call()
      .then( (pathResult) => {

        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }
      })
      .catch( (err) => {
        console.log(err)
      })
  }



  setOptionsUri = (uri) => {

    const parse_uri = parseStellarUri(uri);
    const split = parse_uri.uri.href.split('&')
    const encodeUri = split.map((item, index) => {

      if (item.indexOf('memo')+1 && !item.indexOf('memo_type')-1) {
        item = 'memo=' + encodeURIComponent(item.split('memo=')[1]);
      }
      if (item.indexOf('msg')+1) {
        item = 'msg=' + encodeURIComponent(item.split('msg=')[1]);
      }
      if (item.indexOf('signature')+1) {
        item = 'signature=' + encodeURIComponent(item.split('signature=')[1]);
      }

      return item;
    })

    const asset = (parse_uri.assetCode && parse_uri.assetIssuer) ? [parse_uri.assetCode, parse_uri.assetIssuer] : ['XLM', 'native']

    this.setState({
      uri,
      parse_uri,
      encodeUri,
      asset,
      recipient: parse_uri.destination,
      amount: parse_uri.amount,
      memo: parse_uri.memo ? decodeURIComponent(parse_uri.memo) : false,
      msg: parse_uri.msg ? decodeURIComponent(parse_uri.msg) : false,
      domain: parse_uri.originDomain || false,
    }, () => {
      // this.checkRecipientAccount()
      getAssetInfo(asset[0], asset[1]).then(result => {

        if (result.data && result.data.length) {
          this.setState({ asset_db: result.data[0] })
        }
      })
    })
  }



  getInvoice = (invoice_id) => {

    const formData = new FormData();
    formData.append('invoice_id', invoice_id)

    axios.post(GLOBE.API_URL+`/get_invoice`, formData)
      .then(result => {

        if (result.data !== 'not_found') {
          this.setOptionsUri(result.data.uri)
        }
        else {
          swal({
            title: 'Payment request not found',
            text: `Make sure the payment request id in the URL is correct`,
            icon: 'info'
          })
          // this.setState({ loader: false })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
    })
  }



  getSigner = (address) => {

      const wallets = this.state.wallets;

      if (wallets) {
        const signer = wallets.filter((item) => {
          return item.pk === address;
        });

        this.setState({
          signer: signer,
        })
      }
  }



  // filterAddress = () => {
  //
  //   const wallets = this.state.wallets;
  //
  //   this.setState({ loader: true, });
  //
  //   if (wallets) {
  //     wallets.forEach((item, index) => {
  //       this.getAccount(item, index);
  //     })
  //   }
  //
  //   if (this.state.wallets) {
  //       setTimeout(() => {
  //         ToastStore.info(`Loading ${this.state.wallets.length} Accounts...`);
  //       }, 3000)
  //   }
  //   else {
  //       this.setState({
  //         loader: false
  //       })
  //   }
  // }


  getAccount = (address) => {

    const { asset } = this.state

    this.setState({
      loader: true,
      disabled: false,
      loading_path: true,
      need_path: false,
      proc_message: 'Loading Account...',
      show_send_btn: false,
    })

    Server.loadAccount(address)
      .then(account => {

        const balances = account.balances;

        balances.forEach((item, index) => {
          if (item.asset_type === 'native') {
            item.asset_code = 'XLM';
            item.asset_issuer = 'native';

            const balance_native = Number(account.balances[account.balances.length-1].balance)
            const reserve_balance = Number(account.subentry_count * baseReserve());
            const available_native = (balance_native - reserve_balance).toFixed(2);

            this.setState({
              available_native,
              balance_native,
              reserve_balance,
            })
          }
        });

        const trustline = balances.filter((item) => {
          return (item.asset_code === asset[0] && item.asset_issuer === asset[1])
        })[0];

        if (trustline === undefined) {
          swal({
            title: `No asset ${asset[0]}`,
            text: `There is no ${asset[0]} asset on the balance sheet. But you can make this payment using Path Payments.`,
            icon: 'info'
          })
        }
        else if (Number(trustline.balance) < Number(this.state.amount)) {
          this.showNotEnoughBalance() // message
        }

        if (trustline && Number(trustline.balance) > this.state.amount) {
          this.setState({ show_send_btn: true })
        }
        else {
          this.setState({ need_path: true, show_send_btn: false })
        }

        this.setState({
          // account,
          select_assets: balances.reverse(),
          select_addr: address,
          trustline,
          disabled: false,
          loader: false,
          loading_path: false,
        })
      })
      .catch((error) => {
        console.error('error.response: ', error.response);

        this.setState({
          loader: false,
          loading_path: true,
          proc_message: 'Account not found. Select another account.',
          show_send_btn: false
        })
    })
  }

  showNotEnoughBalance = () => {
    swal({
      title: `Not enough ${this.state.asset[0]}`,
      text: `There is no ${this.state.asset[0]} token in your address. You can complete this payment in your other tokens.`,
      icon: 'info'
    })

    this.setState({ need_path: true, show_send_btn: false })
  }


  changeSender = (value) => {

    this.setState({ path_result: false })

    const address = getFloatValue(value);

    this.getAccount(address)
    this.getSigner(address);
  }


  showToast = () => {
    ToastStore.info(`Search Path Payments...`);
  }


  getStrictReceivePaths = () => {

    const { select_addr, amount, asset } = this.state

    this.showToast()

    const sourceAccount = select_addr;
    const destinationAmount = amount;
    let destinationAsset

    if (asset[1] === 'native') {
      destinationAsset = StellarSdk.Asset.native();
    } else {
      destinationAsset = new StellarSdk.Asset(asset[0], asset[1])
    }

    this.setState({
      loader: true,
      need_path: false,
      loading_path: true,
      proc_message: 'Search Path Payments...',
    })

    Server.strictReceivePaths(sourceAccount, destinationAsset, destinationAmount)
      .call()
      .then( (pathResult) => {

        if (!pathResult.records.length) {
          swal({
            title: 'Path Payments not found',
            text: 'Choose another sender account or fund the selected account.',
            icon: 'info'
          })
        }

        this.setState({
          path_result: pathResult.records,
          path_payment: true,
          loader: false,
          disabled: true,
          show_send_btn: true,
          loading_path: false
        })
      })
      .catch( (err) => {
        console.log(err)

        ToastStore.error(`Path not found...`);

        this.setState({
          loader: false,
          loading_path: false,
          proc_message: 'Path not found',
        })
      })
  }


  changeSelectAsset = (select_asset) => {
    this.setState({
      select_asset,
      disabled: false,
    });
  }


  submitForm = (values) => {

    if (this.state.path_payment) {
      this.pathPayment(values)
    }
    else {
      this.payment()
    }
  }


  payment = () => {

    const { amount, select_assets, asset } = this.state

    const select_asset = select_assets.filter((item) => {
      return (item.asset_code === asset[0] && item.asset_issuer === asset[1])
    })[0];

    if (amount > Number(select_asset.balance)) {
      ToastStore.warning('Not enough funds for this transaction');
    }
    else {
      this.allowTransaction()
    }
  }

  allowTransaction = () => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      const message = `Send <b class="text-info">${this.state.amount}</b>  <b class="text-warning">${this.state.asset[0]}</b> right now?`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirm",
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((confirm) => {
        if (confirm) {
          this.setSendTx();
          this.setState({
            hash: false,
          })
        }
      })
    }
  }


  setSendTx = () => {

    const { select_addr, wallets, recipient, amount, memo, asset } = this.state

    const signer = wallets.filter((item) => {
      return item.pk === select_addr;
    });

    let select_asset;
    if (asset[0] === 'XLM') {
      select_asset = StellarSdk.Asset.native();
    } else {
      select_asset = new StellarSdk.Asset(asset[0], asset[1]);
    }

    this.setState({ loader: true, hash: false });

    // payment =============
    Server.loadAccount(select_addr)
      .then(sourceAccount => {

        let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: this.fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.payment({
            destination: recipient,
            asset: select_asset,
            amount: String(amount)
          }))
          .addMemo(StellarSdk.Memo.text(memo ? memo : ''))
          .setTimeout(180)
          .build();

        if (checkConnectProvider(select_addr)) {
          return signConnectProvider(transaction, select_addr).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          if (!getSigner(select_addr).sk || !window.atob(getSigner(select_addr).sk)) return
          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_addr).sk))
          transaction.sign(signer);
          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        this.setState({ loader: false, });

        if (result.hash) {
          this.swallSuccessSend();

          this.setState({
            transaction: false,
            hash: result.hash,
          })
          this.getOperation(result.hash)

          // save tx
          const formData = new FormData();
          formData.append('type', 'payment')
          formData.append('asset', `${asset[0]}-${asset[1]}`)
          formData.append('amount', amount)
          formData.append('sender', select_addr)
          formData.append('dest', recipient)
          formData.append('request_id', this.props.match.params.id)

          saveQRPayment(formData)
        }
        else {
          ToastStore.warning('Something went wrong. Try refreshing the page.');
        }
      })
      .catch((error) => {
        console.error('Something went wrong: ', error);
        this.setState({ loader: false, });

        ToastStore.error('Transaction error');
    });
    //--------------/
  }


  swallSuccessSend = () => {
    const message = `You sent <b class="text-info">${this.state.amount}</b>  <b class="text-warning">${this.state.asset[0]}</b> to <b class="text-info">${shortAddr(this.state.recipient, 4)}</b>.`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Sent!",
      content: element,
      icon: "success",
    })
      .then(() => {
        this.scrollTop()
      })
  }


  scrollTop = () => {
    window.scrollTo({ top: 800, behavior: "smooth" })
  }



  pathPayment = (values) => {

    const { select_addr, select_asset, recipient, } = this.state

    const path = select_asset.path.map(item => {
      if (item.asset_type === 'native') {
        return new StellarSdk.Asset.native()
      }
      else {
        return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
      }
    })


    const setPathPayment = (sendAsset, destAsset, destAmount, sendMax, path) => {

      const max_amount = (Number(sendMax) + (Number(sendMax) * 1 / 100) ).toFixed(7)

      this.setState({ loader: true, hash: false })

      Server.loadAccount(select_addr)
        .then((sourceAccount) => {

          let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({
                sendAsset,
                sendMax: max_amount,
                destination: recipient,
                destAsset,
                destAmount,
                path,
            }))
            .setTimeout(100)
            .build();

          if (checkConnectProvider(select_addr)) {
            return signConnectProvider(transaction, select_addr).then(result => {
              return Server.submitTransaction(result);
            })
          }
          else {
            if (!getSigner(select_addr).sk || !window.atob(getSigner(select_addr).sk)) return
            const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_addr).sk))
            transaction.sign(signer);
            return Server.submitTransaction(transaction);
          }
        })
        .then((result) => {

          this.setState({
            loader: false,
            hash: result.hash,
          })

          const message = `<div><b class="text-info">${select_asset.source_amount}</b> <b class="text-warning">${select_asset.source_asset_code ? select_asset.source_asset_code : 'XLM'}</b> <i class="fa fa-long-arrow-right text-secondary"></i> <b class="text-info">${numFormat(select_asset.destination_amount, 7)}</b> <b class="text-warning">${select_asset.destination_asset_code ? select_asset.destination_asset_code : 'XLM'}</b></div>`;
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: `Successful Payment!`,
            content: element,
            icon: "success",
          })
            .then(() => {
              this.scrollTop()
            })

          this.getOperation(result.hash)

          // save tx
          const formData = new FormData();
          formData.append('type', 'path')
          formData.append('asset', `${this.state.asset[0]}-${this.state.asset[1]}`)
          formData.append('amount', destAmount)
          formData.append('sender', select_addr)
          formData.append('dest', recipient)
          formData.append('request_id', this.props.match.params.id)

          saveQRPayment(formData)
        })
        .catch((error) => {
          console.error('Error! ', error);
          console.error('error.response: ', error.response);

          this.setState({
            buy_sell_loader: false,
            loader: false
          })
          if (error.response) {
            showErrorMessage(error, 'path_payment')
          } else {
            ToastStore.error(error.toString());
          }
      });
    }

    let destAsset, sendAsset, destAmount, sendMax;

    if (values.radio) {
      const send_asset_split = values.radio.split('-');
      const dest_asset_split = this.state.asset;

      destAmount = String(select_asset.destination_amount);
      sendMax = String(select_asset.source_amount);

      if (send_asset_split[1] === 'native') {
        sendAsset = StellarSdk.Asset.native();
      } else {
        sendAsset = new StellarSdk.Asset(send_asset_split[0], send_asset_split[1]);
      }

      if (dest_asset_split[1] === 'native') {
        destAsset = StellarSdk.Asset.native();
      } else {
        destAsset = new StellarSdk.Asset(dest_asset_split[0], dest_asset_split[1]);
      }

      const message = `<div>Send <b class="text-info">${select_asset.source_amount}</b> <b class="text-warning">${select_asset.source_asset_code ? select_asset.source_asset_code : 'XLM'}</b> <i class="fa fa-long-arrow-right text-secondary"></i> <b class="text-info">${numFormat(select_asset.destination_amount, 7)}</b> <b class="text-warning">${select_asset.destination_asset_code ? select_asset.destination_asset_code : 'XLM'}</b> right now?</div>`;
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Confirm!",
        // text: `${sendMax} ${this.state.asset[0]} now?`,
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {
            setPathPayment(sendAsset, destAsset, destAmount, sendMax, path)
          }
        })
    }
    else {

      if (!this.state.path_result) {
        ToastStore.warning('Choose Path Payment');
      } else {
        //...
      }
    }
  }


  getOperation = (hash) => {

    getOperationForTransaction(hash, 1).then(result => {
      if (result) {

        this.setState({
          operation: result.records[0],
        })
      }
    }).catch(error => {
      console.log('error: ', error)
    })
  }


  togglePopover = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }));
  }


  refreshPathPayments = () => {
    this.getStrictReceivePaths()
  }


  closeLastTransaction = () => {
    this.setState({ hash: false })
  }

  renderFirstAsset = (item) => {
    return <Link to={item.source_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
  }



  renderPath = (item, index) => {

    let asset_code;
    if (item.source_asset_type === 'native') {
      asset_code = 'XLM'
    } else {
      asset_code = item.source_asset_code;
    }

      return (
        <div className="form__form-group-field" key={index}>
          <Field
            name="radio"
            component={renderRadioButtonField}
            label={`${numFormat(item.source_amount, 7)} ${item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code} ${item.path.length ? `-> ${item.path.length ? `[ ${item.path.map(item => item.asset_type === 'native' ? 'XLM' : item.asset_code)} ]` : null}` : '' }  ->  ${numFormat(item.destination_amount, 7)} ${item.destination_asset_type === 'native' ? 'XLM' : item.destination_asset_code }`}
            radioValue={`${(item.source_asset_type === 'native' ? 'XLM-native' : item.source_asset_code+'-'+item.source_asset_issuer)}-${item.source_amount}`}
            onChange={() => this.changeSelectAsset(item)}
          />
        </div>
      )
    // }
  }




  render() {

    // console.log('state: ', this.state)

    const {reset, handleSubmit, match } = this.props;
    const { wallets, recipient, amount, asset, memo, msg, encodeUri, asset_db, fee_usd, is_auth, pay_show, loader,
      select_addr, trustline, path_result, select_asset, disabled, need_path, show_send_btn, loading_path, proc_message,
      hash, operation } = this.state

    let webStellar = false;
    const str = match.params.id;
    if (str.indexOf('web%2Bstellar') + 1) {
      webStellar = true
    }

    if (is_auth) {
      return <Redirect to='/auth' />
    }

    const logo = asset[1] === 'native' ? `/img/logo/stellar-logo.png` : asset_db ? asset_db.image : '/img/digitalcoin.png'


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text"><i className={'fa fa-qrcode'}></i> Payment Request {' '}

                  </h5>

                  <h5 className="subhead">Multi-currency cross-border fast crypto payments on Stellar</h5>
                </div>
                <div className="panel__btns">
                  <button className={'panel__btn text-secondary'}>
                    <i id="popover" onClick={this.togglePopover} className="fa fa-question-circle-o"></i> {' '}
                    <Popover
                      placement="top"
                      isOpen={this.state.popoverOpen}
                      target="popover"
                      trigger="legacy"
                      toggle={this.togglePopover}
                    >
                      <PopoverBody>
                        Pay invoices issued to you in cryptocurrency for goods, services, donations and for anything. <br/>
                        Supports {' '}
                        <a href="https://developers.stellar.org/docs/encyclopedia/path-payments"
                           target='_blank' rel='noopener noreferrer'>Path Payment</a> and {' '}
                        <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                           target='_blank' rel='noopener noreferrer'>SEP-007</a>.
                      </PopoverBody>
                    </Popover>
                  </button>
                </div>

                {
                  this.state.uri ?
                    <>
                      <div className="text-center">

                        <Collapse title="Web link" className="with-shadow ml-0">
                          <span className={'qr-url text-secondary'}>
                            {
                              webStellar ?
                                <span><span className={'text-secondary'}>{encodeUri.toString().substr(0, 43)}</span> {' '}</span>
                                :
                                <span><span className={'text-success'}>{window.location.origin}</span><span>{window.location.pathname}</span> {' '}</span>
                            }
                            <CopyToClipboard text={webStellar ? encodeUri : window.location.href}
                                             onCopy={() => ToastStore.success(`Copied`)}>
                              <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>
                            </CopyToClipboard>
                          </span>
                        </Collapse>

                        <QRCode
                          value={this.state.uri}
                          size="350"
                          quietZone="30"
                          // fgColor={f7.colors[color]}
                          qrStyle="dots"
                          // logoOpacity="0.8"
                          logoImage={logo}
                          removeQrCodeBehindLogo={true}
                          logoPadding={asset[1] === 'native' ? 0 : 2.5}
                          logoWidth={asset[1] === 'native' ? 39 : 38}
                          logoHeight={asset[1] === 'native' ? 39 : 38}
                          ecLevel="M"
                          eyeRadius={[
                            { // top/left eye
                              outer: [10, 10, 0, 10],
                              inner: [1, 1, 0, 1],
                            },
                            { // top/right eye
                              outer: [10, 10, 10, 0],
                              inner: [1, 1, 1, 0],
                            },
                            { // bottom/left
                              outer: [10, 0, 10, 10],
                              inner: [1, 0, 1, 1],
                            },
                          ]}
                          eyeColor={[
                            { // top/left eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                            { // top/right eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                            { // bottom/left eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                          ]}
                        />

                      </div>

                      {
                        (() => {
                          setTimeout(() => {
                            const qr_img = document.getElementById('react-qrcode-logo');
                            qr_img.setAttribute("style", "width: 100%;"); // .style = "width: 100%;"
                          }, 10)
                        })()
                      }

                      <br/>

                      <div className="text-secondary">
                        <p>Amount: <b className="text-info">{numFormat(amount, 7)}</b> {' '}
                          {
                            asset[1] === 'native' ?
                              <a href={`/trade/${asset[0]}-XLM/${asset[1]}/native`}
                                 target='_blank'
                                 rel='noopener noreferrer'>
                                <b className="text-warning"><img src={logo} className={'asset-icon-sm'} alt=""/>{asset[0]}</b> {' '}
                              </a>
                            :
                              getAsset({ asset_code: asset[0], asset_issuer: asset[1] }, asset_db && asset_db.image)
                          }
                        </p>
                        <p>Destination: {shortAddress(recipient, 4)} {' '}
                          <CopyToClipboard text={recipient}
                                           onCopy={() => ToastStore.success(`Address copied`)}>
                            <i className="fa fa-clone" aria-hidden="true" style={{cursor: 'pointer'}}></i>
                          </CopyToClipboard>
                        </p>
                        {memo ? <p><small>Memo: <span className="text-info">{memo}</span></small></p> : null}
                        {msg ? <p><small>Message: <span className="text-info">{msg}</span></small></p> : null}
                      </div>
                    </>
                  :
                    (loader ? (<Card><CardBody><div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div></CardBody></Card>) : 'Not found...' )
                }

                {
                  pay_show ?
                    <Card style={{paddingBottom: 0}}>

                      { loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null}

                      <br/>
                      <hr/>

                      <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                        {
                          wallets ?
                           <div>
                             <Field
                                name="select_addr"
                                component={renderTextField}
                                select
                                label="Select Sender Account"
                                onChange={(e) => {
                                  this.changeSender(e)
                                }}
                              >
                                {
                                  wallets.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        className={`material-form__option fs-12 ${item && item.provider ?  'connect-provider' : ''}`}
                                        value={item.pk}>
                                        <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                                      </MenuItem>
                                    )
                                  })
                                }
                              </Field>

                              {
                                select_addr && trustline ?
                                  <div style={{marginTop: -8}}>
                                    <div>
                                      <small className={'text-secondary'}>Asset Balance: {' '}
                                        {
                                          trustline ?
                                            <>
                                              <b className="text-info">{numFormat(trustline.balance, 7)}</b> {' '}
                                              <b className="text-warning">{trustline.asset_code}</b>
                                            </>
                                          :
                                            <span className="text-danger">No Trustline to <b>{asset[0]}</b></span>
                                        }
                                      </small>
                                    </div>
                                    <div className="text-secondary" style={{fontSize: 11, marginTop: 4}}>
                                      <small>Available XLM: <b className="text-info">{numFormat(this.state.available_native, 7)}</b> <b className="text-secondary">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-secondary">XLM</b></font></small></div>
                                  </div>
                                : null
                              }
                            </div>
                          : null
                        }

                        <br/>

                        {
                          path_result ?
                            <div>
                              <div className={'text-secondary'}><b>Select Path Payment:</b>
                                <button className="panel__btn refresh-bth" type="button" onClick={this.refreshPathPayments}><AutorenewIcon /></button>
                              </div><br/>
                              <div style={{marginBottom: 10}}>
                                {
                                  path_result.map(this.renderPath)
                                }
                              </div>
                            </div>
                          : null
                        }

                        {
                          path_result && select_asset ?
                            <div className="text-center">
                              <hr/>
                              <div style={{marginTop: 18}}>
                                <div><small className={'text-secondary'}><b>Swap</b>:</small></div>
                                <b className="text-info">{numFormat(select_asset.source_amount, 7)}</b> <b className="text-warning">{select_asset.source_asset_type === 'native' ? 'XLM' : select_asset.source_asset_code}</b>
                                {' '} <i className="fa fa-long-arrow-right text-secondary"></i> {' '}
                                <b className="text-info">{numFormat(this.state.amount, 7)}</b> <b className="text-warning">{asset[1] === 'native' ? 'XLM' : asset[0]}</b>
                                {/*<div><i className="fa fa-arrow-down text-secondary"></i></div>*/}
                              </div>
                            </div>
                          : null
                        }

                        {
                          need_path &&
                          <div className="text-center">
                            <br/>
                            <p><small>There is no {getAsset(asset_db, asset_db.image, 'secondary', 16)} token in your account. <br/> But you can pay with <b>Path Payments</b>.</small>
                            </p>
                            <br/><br/>
                            <Button color="primary"
                                    size={'lg'}
                                    onClick={this.getStrictReceivePaths}
                                    style={{color: 'white'}}
                                    // width: '100%',
                            ><i className="fa fa-random text-white"></i> <b>Pay with Path Payments</b></Button>
                            <br/><br/>
                          </div>
                        }

                        {
                          loading_path &&
                            <div className="text-center text-secondary" style={{marginTop: 7}}>
                              <b>{proc_message}</b>
                              <br/><br/>
                            </div>
                        }

                        {
                          show_send_btn &&
                            <div className="text-center" style={{marginTop: 7}}>
                              <br/>
                              <Button color="success"
                                      style={{width: '100%'}}
                                      size={'lg'}
                                      type="submit"
                                      disabled={disabled ? true : false}
                              >Send <b>{numFormat(this.state.amount, 7)} {asset[0]}</b></Button>
                            </div>
                        }

                      </form>
                    </Card>
                  :
                    this.state.uri ?
                      <div className='text-center'>
                        <br/><br/>
                        {
                          wallets ?
                            <Button color="success"
                                    style={{width: '100%'}}
                                    size={'lg'}
                                    disabled={this.state.is_auth ? true : false}
                                    onClick={() => this.setState({ pay_show: true })}>
                              <i className="fa fa-check"></i> Pay <b>{numFormat(this.state.amount, 7)} {this.state.asset[0]}</b>
                            </Button>
                            :
                            <div>
                              <p><small>To pay you need to connect your wallet</small></p>
                              <br/>
                              <Link to={'/auth'}
                                    className={'btn btn-primary btn-lg'}
                                    style={{width: '100%'}}><b>Connect Wallet</b></Link>
                            </div>
                        }
                      </div>
                    : null
                }



                <div className={'stats-block'}>
                  <p>Fee <a href={null}  id="fee"><i className="fa fa-question-circle-o text-secondary"></i></a>
                    <UncontrolledTooltip placement="top" target="fee">
                                    <span>To prevent ledger spam and maintain the efficiency of the network, Stellar requires small transaction
                                      fees and minimum balances on accounts. Transaction fees are also used to prioritize transactions when
                                      the network enters surge pricing mode.</span>
                    </UncontrolledTooltip>
                    <span className={'swap-data'}>
                                    <small>${numFormat(fee_usd, 7)}</small>
                                  </span>
                  </p>
                </div>

              </CardBody>
            </Card>


            {
              hash ?
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Transaction</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastTransaction}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      <span>
                        <p>
                          <span>Transaction:</span>
                          <span className={'swap-data'}>
                            <b><a href={`/transaction/${hash}`}>{ shortAddr(hash, 4) }</a></b> {' '}
                            <CopyToClipboard text={hash} onCopy={() => {}}>
                              <i className="fa fa-clone"
                                 color="secondary"
                                 style={{cursor: 'pointer', fontSize: 14}}
                                 onClick={() => ToastStore.success('Copied!')}></i>
                            </CopyToClipboard>
                          </span>
                        </p>

                        { !operation ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null }

                        {
                          operation ?
                            <span>
                              <p>
                                <span>Operation:</span>
                                <span className={'swap-data'}>
                                  <b><a href={`/operation/${operation.id}`}>{ shortAddr(operation.id, 4) }</a></b> {' '}
                                  <CopyToClipboard text={operation.id} onCopy={() => {}}>
                                    <i className="fa fa-clone"
                                       color="secondary"
                                       style={{cursor: 'pointer', fontSize: 14}}
                                       onClick={() => ToastStore.success('Copied!')}></i>
                                  </CopyToClipboard>
                                </span>
                              </p>

                              <p>
                                <span>Type:</span>
                                <span className={'swap-data'}>
                                  <b className="text-info">{ operation.type === 'payment' ? 'Payment' : 'Path Payment' }</b>
                                </span>
                              </p>

                              {
                                operation.type === 'payment' ?
                                  <span>
                                    <p>
                                      <span>Amount:</span>
                                      <span className={'swap-data'}>
                                        <b className="text-info">{ numFormat(amount, 7) }</b> { getAsset(`${asset[0]}:${asset[1]}`, asset_db && asset_db.image, '', 17) }
                                      </span>
                                    </p>
                                    <p>
                                      <span>Sender:</span>
                                      <span className={'swap-data'}>{shortAddress(select_addr)}</span>
                                    </p>
                                    <p>
                                      <span>Recipient:</span>
                                      <span className={'swap-data'}>{shortAddress(recipient)}</span>
                                    </p>
                                  </span>
                                :
                                  <span>
                                    <p>
                                      <span>{shortAddress(operation.from)} Sent:</span>
                                      <span className={'swap-data'}>
                                        <b className="text-info">{ numFormat(operation.source_amount, 7) }</b> {this.renderFirstAsset(operation)}
                                      </span>
                                    </p>

                                    <p>
                                      <span>{shortAddress(operation.to)} Received:</span>
                                      <span className={'swap-data'}>
                                        <b className="text-info">{ numFormat(operation.amount, 7) }</b> { getAsset(operation) }
                                      </span>
                                    </p>
                                  </span>
                              }

                              {
                                operation.type === 'path_payment_strict_receive' &&
                                  <p>
                                    <span>Path:</span>
                                    <span className={'swap-data'}>
                                    { this.renderFirstAsset(operation) } <i className="fa fa-angle-right"></i> {' '}
                                      {
                                        operation.path.map((pathItem, index) => {
                                          return (
                                            <b key={ index }> {getAsset(pathItem)} { operation.path.length > (index+1) ? <span> <i className="fa fa-angle-right"></i> </span> : '' }</b>
                                          )
                                        })
                                      }
                                      {' '} <i className="fa fa-angle-right"></i> {getAsset(operation)} {' '}
                                    </span>
                                  </p>
                              }

                              <p>
                                <span>Date:</span>
                                <span className={'swap-data'}>
                                  <small>{formatDate(operation.created_at)}</small>
                                </span>
                              </p>
                            </span>
                          : null
                        }

                        {/*<p>*/}
                        {/*  <span>Type:</span>*/}
                        {/*  <span className={'swap-data'}>*/}
                        {/*    <b className="text-info">{'Payment'}</b>*/}
                        {/*  </span>*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*  <span>Amount:</span>*/}
                        {/*  <span className={'swap-data'}>*/}
                        {/*    <b className="text-info">{ numFormat(amount, 7) }</b> { getAsset(`${asset[0]}:${asset[1]}`, asset_db && asset_db.image, '', 15) }*/}
                        {/*  </span>*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*  <span>Sender:</span>*/}
                        {/*  <span className={'swap-data'}>{shortAddress(select_addr)}</span>*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*  <span>Recipient:</span>*/}
                        {/*  <span className={'swap-data'}>{shortAddress(recipient)}</span>*/}
                        {/*</p>*/}

                        {/*{*/}
                        {/*  memo ?*/}
                        {/*    <p>*/}
                        {/*      <span>Memo:</span>*/}
                        {/*      <span className={'swap-data'}><b className={'text-info'}>{memo}</b></span>*/}
                        {/*    </p>*/}
                        {/*  : null*/}
                        {/*}*/}
                        {/*<p>*/}
                        {/*  <span>Date:</span>*/}
                        {/*  <span className={'swap-data'}>*/}
                        {/*    <small>{formatDate(new Date())}</small>*/}
                        {/*  </span>*/}
                        {/*</p>*/}

                      </span>

                    </div>
                  </CardBody>
                </Card>
              : null
            }


            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text"><i className="fa fa-question-circle-o text-secondary"></i> FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>

                <Collapse title="What is a Payment Request" className="with-shadow ml-0">
                  <p>Payment Request is a pre-created data object (recipient's account, asset, amount, memo) transmitted as a QR code or a web link for a deposit to the recipient's account specified in the payment request.</p>
                </Collapse>
                <Collapse title="How to pay for this request" className="with-shadow ml-0">
                  <p>To pay for such a payment request - you need to specify the account from which you will pay the bill, click the Pay button. If your account does not have an asset required for payment, you can pay with any other asset that is in your account.</p>
                </Collapse>
                <Collapse title="What assets can be paid" className="with-shadow ml-0">
                  <p>You can pay for payment requests with any assets that are in your address, in sufficient quantities to complete the transaction. If you pay with an asset other than the asset specified in the payment request, the exchange of your asset for the asset specified in the payment request will occur automatically at the most favorable rate on SDEX and AMM.</p>
                </Collapse>
                <Collapse title="How to pay with mobile wallet" className="with-shadow ml-0">
                  <p>Download the Scopuly mobile wallet for <a href="https://apps.apple.com/us/app/id1383402218?platform=iphone" target={'_blank'}><b>iOS</b></a> or <a href="https://play.google.com/store/apps/details?id=com.sdex.app" target={'_blank'}><b>Android</b></a>, create or authorize your wallet and click "Scan" on the bottom tapbar and scan the payment request QR code. After the scan, select your account and pay for the request.</p>
                </Collapse>
                <Collapse title="What are Path Payments" className="with-shadow ml-0">
                  <p>In a path payment, the asset received differs from the asset sent. Rather than the operation transferring assets directly from one account to another, path payments cross through the SDEX and/or liquidity pools before arriving at the destination account. For the path payment to succeed, there has to be a DEX offer or liquidity pool exchange path in existence.</p>
                </Collapse>
                <Collapse title="Why such low fees" className="with-shadow ml-0">
                  <p>
                    One of the main advantages of Stellar is its high speed and low transaction fees. The system can process up to <b>100,000</b> transactions per second and charge a fee of less than <b>$0.0001</b> per transaction. This makes Stellar one of the fastest and most affordable blockchains for real-world payments and money transfers around the world.
                    Read more: <a href="https://developers.stellar.org/docs/encyclopedia/fees-surge-pricing-fee-strategies" target={'_blank'}><b>Learn more</b></a></p>
                </Collapse>

              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Link to={'/payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-location-arrow'}></i> Payments</Link>
                <Link to={'/receive'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-qrcode'}></i> Payment Request</Link>
                <Link to={'/create-claimable-balance'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-clock-o'}></i> Pending Payments</Link>
                <Link to={'/mass-payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%', marginBottom: 0,}}><i className={'fa fa-users'}></i> Mass Payments</Link>
              </CardBody>
            </Card>

          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}



// export default translate('common')(Send);
export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(translate('common')(Pay));
