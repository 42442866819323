import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import StellarSdk from "stellar-sdk";
import axios from "axios";
import {GLOBE} from "../../../../modules/globeVars";
import {
  formatDate,
  get_loc_wallets, getAPY, getDurationTime, getRefLevel, getRefPositionLevel,
  getSigner, getStellarFee, getTitle, getRewardPools,
  numFormat,
  randomAmount,
  shortAddr,
  showErrorMessage, stellarStroop
} from "../../../../modules";
import swal from "sweetalert";
import {buildTx, getLiquidityPool, getOrderbook,} from '../../../../endpoints/StellarAPI'
import {Server} from "../../../../modules/stellar";
import BarLoader from "react-spinners/BarLoader";
import PacmanLoader from "react-spinners/PacmanLoader";
import {css} from "@emotion/core";
import {getAssetHistory, getTopPartners} from "../../../../endpoints/API";
import renderCheckBoxField from "../../../../shared/components/form/CheckBox";


const override = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 55px;
`;

const override_loader = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 73px;
`;



class Reward extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      account: false,
      base_asset: new StellarSdk.Asset.native(),
      counter_asset: new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'),
      trader_1: 'GAONNYNQF6UTDBFLZEVPCV5WWSL6UUTMQJODSO6F7JKZLNHV3QYNA4UI',
      // trader_2: 'GBE27GR2Y74PQK6OBU6DMD7CGZLU5S72DHV4O25ZJTSYBL6ZHW6HSONV',
      min_balance: 100, // 1000 100 10
      max_balance: 10000000,
      apr: getAPY('holder'),
      pool_apr: getAPY('pool'),
      holders: [],
      all_holders: [],
      count_holders: 0,
      count_filtered: 0,
      count_sent: 0,
      amount_sent: 0,
      start_time: '',
      start_time_sent: '',
      start_time_ref_sent: '',
      duration: '',
      duration_sent: '',

      pool: false,
      pool_aqua: false,
      pool_usdc: false,
      pool_yxlm: false,
      pool_lsp: false,
      pool_xxa: false,
      pool_sky: false,
      pool_mobi: false,
      pool_xrp: false,
      pool_yusdc: false,
      pool_ybtc: false,
      pool_yeth: false,
      pool_fidr: false,
      pool_fred: false,
      pool_xau: false,

      all_rewards: 0,
      pool_balances: 0,
      hold_balances: 0,
      all_balances: 0,

      count_pool: 0,
      found_loader: false,
      send_loader: false,
      error: false,

      ref_payments: [],
      partners: [],
      all_payments: 0,
      bonus_amount: 0,
      vol: 0,

      save_ref_payments: [],
      amount_bonus_sent: 0,

      opsReferrals: [],
      savePayments: [],
      top_10: [],
      fee: getStellarFee(),
      cycle_fee: 0,
      all_cycle_fee: 0,
      done: false,
      pool_holders: [],
      auto_start: false,
      filtered: false,
    };
  }

  // count_sent = 0
  amount_sent = 0
  amount_bonus_sent = 0
  amount_partner_sent = 0
  total_partner_amount = 0
  minItems = 100
  minRefItems = 50 // 100 / 2 payments
  result = false
  exception_1 = 'GBHPS4ONXUOITUCD6SWZXGA3SLWQBABDF5W2RQSN5GVN5Q5SNNBJJT3Z'
  exception_2 = 'GB55WIVZATDNQOFTYTLELVGISKCUPZBTRG6AAWCZQKSPTXWQDS4673EU'
  all_scop_balances = 0

  reward_pools = getRewardPools()


  componentDidMount() {

    // const { pool_id_scop_xlm } = this.state

    // this.startProcess() //.then(r => r)
    // this.holderAccounts()


    this.getPool()
    // this.getRefPayments()
    this.checkAutoStart()

    // this.testRequestAssetHistory()
  }


  // testRequestAssetHistory = () => {
  //
  //   const asset = {
  //     asset_code: 'SCOP',
  //     asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'
  //   }
  //
  //   getAssetHistory(asset).then(result => {
  //     console.log('result getAssetHistory: ', result)
  //
  //     let payments = 0, payments_amount = 0, trades = 0, traded_amount = 0, divider = 10000000
  //
  //     result.data.map(item => {
  //       payments += (item.payments)
  //       payments_amount += (item.payments_amount / divider)
  //       trades += (item.trades)
  //       traded_amount += (item.traded_amount / divider)
  //     })
  //
  //     console.log('payments: ', numFormat(payments))
  //     console.log('payments_amount: ', numFormat(payments_amount))
  //     console.log('trades: ', numFormat(trades))
  //     console.log('traded_amount: ', numFormat(traded_amount))
  //
  //   })
  // }


  setAllFee = (ops_fee) => {

    console.log('ops_fee: ', ops_fee)

    this.setState({
      cycle_fee: ops_fee,
      all_cycle_fee: (this.state.all_cycle_fee + Number(ops_fee))
    })
  }


  // 'feb83f3fe1ab32874787c96211b5c087c1f1ffdbeee6677c6d17b96f36c9b470', // 0 SCOP
  // '492f21d8c29d8523cc8a4adc33302484389e15f9f6ef53db59f76d429338cf97', // 1 AQUA
  // '1b9ad45819d885acbc0563f20346fea19804238a334d7645ac069357d78cbb87', // 2 USDC
  // '4e999c117fd77d2d194e643607a9501be5aef3353cd6258707d06bf0a467f3ed', // 3 yXLM
  // '933bbda5f08b0fa2202eae149365e3cc39bfede671f529a253cc180cbaa02bf1', // 4 LSP
  // '6372921738865f448de54a39093aad6796728c9b643dfe3ce85fe419a2de77b8', // 5 XXA
  // 'a6478345327b4c2123217933b7a4a286bc1d78bb9cb6d8d4fffbc75115ce6112', // 6 SKY
  // '19928dd01ca16ed146163782b73eb3da530f36cdd650a2c4be65be5245b3d7fb', // 7 MOBI
  // '9a95b95c9744d237be7a5b0a0f377810d9dbdf434e7b586b005a2e2bd99b7bb4', // 8 XRP / fchain
  // '9975fbcd5d0e9a7569fd52a450d5c0555570015c9fe3812ff312d8aca79550b8', // 9 yUSDC
  // '09dfd7cd30036fc8bbb36a37a4e8e6118298496e63b1e38f1603d04d2383cdba', // 10 yBTC
  // '4697dec247435b9e98b815b55539ab9b4651ada66ec735958878c8da46930306', // 11 yETH
  // '7a3bb88a3e5b0dc77d6b5f8ee31f6b77bc4f5c239a373bec8fe26c889ba01003', // 12 FIDR
  // '483421c73aaafb9d8fce344c564bde2712b0eab9a797b7bea20d7c465fc56f6a', // 13 FRED
  // '7cd132f6f4c25e4055302b3b3651605de73c07d3c8641c51a038732dc9f7299c', // 14 XAU


  getPool = () => {

    // 0 SCOP
    getLiquidityPool(this.reward_pools[0]).then(result => {
      this.setState({ pool: result })
    })
    // 1 AQUA
    getLiquidityPool(this.reward_pools[1]).then(result => {
      this.setState({ pool_aqua: result })
    })
    // 2 USDC
    getLiquidityPool(this.reward_pools[2]).then(result => {
      this.setState({ pool_usdc: result })
    })
    // 3 yXLM
    getLiquidityPool(this.reward_pools[3]).then(result => {
      this.setState({ pool_yxlm: result })
    })
    // 4 LSP
    getLiquidityPool(this.reward_pools[4]).then(result => {
      this.setState({ pool_lsp: result })
    })
    // 5 XXA
    getLiquidityPool(this.reward_pools[5]).then(result => {
      this.setState({ pool_xxa: result })
    })
    // 6 SKY
    getLiquidityPool(this.reward_pools[6]).then(result => {
      this.setState({ pool_sky: result })
    })
    // 7 MOBI
    getLiquidityPool(this.reward_pools[7]).then(result => {
      this.setState({ pool_mobi: result })
    })
    // 8 XRP
    getLiquidityPool(this.reward_pools[8]).then(result => {
      this.setState({ pool_xrp: result })
    })
    // 9 yUSDC
    getLiquidityPool(this.reward_pools[9]).then(result => {
      this.setState({ pool_yusdc: result })
    })
    // 10 yBTC
    getLiquidityPool(this.reward_pools[10]).then(result => {
      this.setState({ pool_ybtc: result })
    })
    // 11 yETH
    getLiquidityPool(this.reward_pools[11]).then(result => {
      this.setState({ pool_yeth: result })
    })
    // 12 FIDR
    getLiquidityPool(this.reward_pools[12]).then(result => {
      this.setState({ pool_fidr: result })
    })
    // 13 FRED
    getLiquidityPool(this.reward_pools[13]).then(result => {
      this.setState({ pool_fred: result })
    })
    // 14 XAU
    getLiquidityPool(this.reward_pools[14]).then(result => {
      this.setState({ pool_xau: result })
    })
  }


  getRefPayments = async () => {

    const { counter_asset, } = this.state

    axios.post(`${GLOBE.API_URL}/get_ref_payments`).then(result => {
      console.log('get_ref_payments result: ', result)

      if (result.data) {

        const ref_payments = result.data.referrals
        const all_payments = ref_payments.length
        const partners = result.data.partners
        const top_10 = result.data.top_10



        partners.forEach(async (item, index)  => {

          const account = await Server.loadAccount(item.address)
          console.log('loadAccount partner: ', account)

          account.balances.forEach(balance => {

            if (balance.asset_code === counter_asset.code && balance.asset_issuer === counter_asset.issuer) {

              item.balance = Number(balance.balance)
              //
              // if (balance.balance < 500) item.level = 5 // 1
              // if (balance.balance >= 500 && balance.balance < 1000) item.level = 10 // 2
              // if (balance.balance >= 1000 && balance.balance < 5000) item.level = 15 // 3
              // if (balance.balance >= 5000) item.level = 20 // 4

              item.level = getRefLevel(balance.balance)
              console.log('level: ', item.level)
              // item.reward = ((balance.balance * item.level) / 100).toFixed(7) // (balance.balance * 0.1)
            }
          })

          let position
          top_10.forEach((top, index) => {
            if (top.address === item.address) {
              position = index+1

              item.position = position
              console.log('position: ', position)

              item.level = getRefPositionLevel(item.level, position)

              console.log('level2: ', item.level)
            }
          })

          //
          // item.position = position
          //
          // if (position === 1)
          //   item.level = (item.level + 10)
          // if (position === 2)
          //   item.level = (item.level + 7.5)
          // if (position === 3)
          //   item.level = (item.level + 5)
          // if (position === 4)
          //   item.level = (item.level + 2.5)


          if (index+1 === partners.length) {

            let vol = 0, bonus_amount = 0, partner_amount = 0

            ref_payments.forEach(item => {
              vol += Number(item.counter_amount)
              // partner_amount = (partner_amount + Number(item.bonus_amount * 2))
              bonus_amount += Number(item.bonus_amount)
            })

            this.setState({
              partners,
              ref_payments,
              all_payments,
              bonus_amount,
              vol,
              top_10,
              // partner_amount,
            }, () => {
              this.setRefOperations()
            })
          }
        })
      }
    });
  }


  // checkPartnerBalance = async (address) => {
  //   return await Server.loadAccount(address).call();
  // }


  // getDurationTime = (start_time) => {
  //
  //   const dtc = new Date();
  //   const dtmb = new Date(start_time);
  //   const qminutes = Math.floor((dtc-dtmb)/1000/60);
  //   const hours = Math.floor(qminutes/60);
  //   // qminutes-=qhours*60;
  //
  //   // const hours = new Date(dtc - dtmb).getHours()
  //   const minutes = new Date(dtc - dtmb).getMinutes()
  //   const seconds = new Date(dtc - dtmb).getSeconds()
  //   const duration = `${hours > 9 ? hours : `0${hours}` }:${minutes > 9 ? minutes : `0${minutes}` }:${seconds > 9 ? seconds : `0${seconds}` }`
  //   return duration
  // }


  start = () => {

    const { done, all_holders } = this.state

    swal({
      title: "Confirm",
      // text: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {

          if (!done) { // start parse
            this.startProcess()
          }
          else { // repeat payments

            this.setState({
                // holders: all_holders,
                done: false,
                count_sent: 0,
                amount_sent: 0,
              },
              () => {
              this.setOperations()
            })
          }
        }
      })
  }


  startProcess = () => {


    const { counter_asset, count_filtered, auto_start } = this.state
    const start_time = String(new Date())

    this.setState({
      start_time,
      found_loader: true
    })

    const setDataState = (result) => {

      this.addArrayHolders(result.records)

      this.setState({
        count_holders: (this.state.count_holders + result.records.length),
        duration: getDurationTime(start_time),
      }, () => {
        // this.setOperations() // !!! demo
      })
    }


    const getAccounts = async () => {

      console.log('Load accounts...')

      try {

        const result = await Server
          .accounts()
          .forAsset(counter_asset)
          .order('desc')
          .limit(200)
          .call();

        console.log('result: ', result)

        if (result.records.length) {
          setDataState(result)
          whileAccounts(result)
        }

      } catch (e) {
        console.error(e);

        setTimeout(() => {
          getAccounts()
        }, 1000)
      }
    }

    const whileAccounts = async (result) => {

      console.log('while Accounts...')

      while (result.records.length) {

        const data = await result.next();
        console.log('result: ', data)

        if (data) {
          result = data
        }
        else {

          setTimeout(() => {
            whileAccounts(result)
          }, 10000)
          return
        }

        if (result.records.length) {
          setDataState(result)
        }
        else {

          this.setState({
            filtered: true,
            all_holders: this.state.holders.concat(this.state.pool_holders)
          }, () => {

            if (auto_start) {
              this.startSendingPayments()
            }
          })
        }
      }
    }

    getAccounts()
  }


  startSendingPayments = () => {

    // const { auto_start } = this.state

    this.setState({
      start_time_sent: String(new Date()),
      found_loader: false,
      send_loader: true,
    }, () => {

      this.setOperations()
    })
  }


  addArrayHolders = (accounts) => {

    const { counter_asset, min_balance, max_balance, count_filtered, count_pool, apr, pool_apr, all_rewards, pool_balances, hold_balances, all_balances,
      pool, pool_usdc, pool_aqua, pool_yxlm, pool_lsp, pool_xxa, pool_afr, pool_sky, pool_mobi, pool_xrp, pool_yusdc, pool_ybtc, pool_yeth, pool_fidr, pool_fred, pool_xau } = this.state

    const pool_holders = []
    const holders = []

    accounts.forEach(item => {

      // console.log('filterHolders item: ', item)

      // Exception Address
      if (item.id === this.exception_1 || item.id === this.exception_2) return

      const setHolderState = (balance, balance_asset) => {

        if (balance_asset > min_balance && balance_asset < max_balance) {

          const reward = (balance_asset * (pool_apr / 365) / 100).toFixed(7)

          pool_holders.push({
            id: item.id,
            balance: balance_asset,
            reward,
            type: 'pool',
            shares: balance.balance,
          })

          this.setState({
            all_rewards: this.state.all_rewards += Number(reward),
            pool_balances: this.state.pool_balances += Number(balance_asset),
            all_balances: this.state.all_balances += Number(balance_asset),
          })
        }
      }

      item.balances.forEach(balance => {

        if (balance.asset_type === 'liquidity_pool_shares') {

          let balance_asset = 0;

          if (pool && balance.liquidity_pool_id === this.reward_pools[0]) {
            balance_asset = (balance.balance / pool.total_shares * pool.reserves[1].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_aqua && balance.liquidity_pool_id === this.reward_pools[1]) {
            balance_asset = (balance.balance / pool_aqua.total_shares * pool_aqua.reserves[1].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_usdc && balance.liquidity_pool_id === this.reward_pools[2]) {
            balance_asset = (balance.balance / pool_usdc.total_shares * pool_usdc.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_yxlm && balance.liquidity_pool_id === this.reward_pools[3]) {
            balance_asset = (balance.balance / pool_yxlm.total_shares * pool_yxlm.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_lsp && balance.liquidity_pool_id === this.reward_pools[4]) {
            balance_asset = (balance.balance / pool_lsp.total_shares * pool_lsp.reserves[1].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_xxa && balance.liquidity_pool_id === this.reward_pools[5]) {
            balance_asset = (balance.balance / pool_xxa.total_shares * pool_xxa.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_sky && balance.liquidity_pool_id === this.reward_pools[6]) {
            balance_asset = (balance.balance / pool_sky.total_shares * pool_sky.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_mobi && balance.liquidity_pool_id === this.reward_pools[7]) {
            balance_asset = (balance.balance / pool_mobi.total_shares * pool_mobi.reserves[1].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_xrp && balance.liquidity_pool_id === this.reward_pools[8]) {
            balance_asset = (balance.balance / pool_xrp.total_shares * pool_xrp.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_yusdc && balance.liquidity_pool_id === this.reward_pools[9]) {
            balance_asset = (balance.balance / pool_yusdc.total_shares * pool_yusdc.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_ybtc && balance.liquidity_pool_id === this.reward_pools[10]) {
            balance_asset = (balance.balance / pool_ybtc.total_shares * pool_ybtc.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_yeth && balance.liquidity_pool_id === this.reward_pools[11]) {
            balance_asset = (balance.balance / pool_yeth.total_shares * pool_yeth.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }

          else if (pool_fidr && balance.liquidity_pool_id === this.reward_pools[12]) {
            balance_asset = (balance.balance / pool_fidr.total_shares * pool_fidr.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_fred && balance.liquidity_pool_id === this.reward_pools[13]) {
            balance_asset = (balance.balance / pool_fred.total_shares * pool_fred.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
          else if (pool_xau && balance.liquidity_pool_id === this.reward_pools[14]) {
            balance_asset = (balance.balance / pool_xau.total_shares * pool_xau.reserves[0].amount).toFixed(7)
            setHolderState(balance, balance_asset)
          }
        }

        else if (balance.asset_code === counter_asset.code && balance.asset_issuer === counter_asset.issuer) {

          if (balance.balance > min_balance && balance.balance < max_balance) {

            const reward = (balance.balance * (apr / 365) / 100).toFixed(7)

            holders.push({
              id: item.id,
              balance: balance.balance,
              reward,
              type: 'hold'
            })


            this.setState({
              all_rewards: this.state.all_rewards += Number(reward),
              hold_balances: this.state.hold_balances += Number(balance.balance),
              all_balances: this.state.all_balances += Number(balance.balance),
            })
          }
        }
      })
    })

    // const all_holders = holders.concat(pool_holders)

    console.log('holders: ', holders)
    console.log('pool_holders: ', pool_holders)
    // console.log('all_holders: ', all_holders)

    this.setState({
      holders: this.state.holders.concat(holders),
      pool_holders: this.state.pool_holders.concat(pool_holders),
      // all_holders: this.state.all_holders.concat(all_holders),
      count_filtered: (count_filtered + holders.length + pool_holders.length),
      count_pool: (count_pool + pool_holders.length),
    })
  }



  setOperations = () => {

    const { counter_asset, holders, pool_holders, all_holders, trader_1, start_time_sent } = this.state

    const opsHolders = []
    const savePayments = []

    if (!start_time_sent) {
      this.setState({ start_time_sent: String(new Date), })
    }

    // const set_holders = holders.length ? holders : pool_holders

    all_holders.forEach((item, index) => {

      if (index < this.minItems) {

        opsHolders.push(StellarSdk.Operation.payment({
          destination: item.id,
          asset: counter_asset,
          amount: String(item.reward),
        }))

        this.amount_sent = Number((Number(this.amount_sent) + Number(item.reward)).toFixed(7))

        savePayments.push({
          address: item.id,
          amount: item.reward,
          type: item.type,
          ref_code: '',
        })
      }
    })

    console.log('opsHolders: ', opsHolders)
    console.log('savePayments: ', savePayments)

    this.payRewards(opsHolders, savePayments, trader_1)
  }


  payRewards = (payments, save_payments, address) => {


    const { counter_asset, start_time, start_time_sent, holders, pool_holders, all_holders, apr, pool_apr, fee } = this.state

    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(address).sk))
    const type = save_payments[0].type
    let ops_fee

    console.log('loadAccount... ')

    Server.loadAccount(address)
      .then((sourceAccount) => {
        console.log('sourceAccount: ', sourceAccount)

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee, // StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        payments.forEach(op => tx.addOperation(op));
        // tx = tx.addMemo(StellarSdk.Memo.text(`${type === 'hold' ? `Holder Reward ${apr}% APY` : `Pool Reward ${pool_apr}% APY`}`))
        tx = tx.addMemo(StellarSdk.Memo.text(`scopuly.com/rewards 🔥`))
        tx = tx.setTimeout(0).build();
        tx.sign(signer);

        console.log('transaction: ', tx)

        ops_fee = tx._fee

        return Server.submitTransaction(tx);
      })
      .then((result) => {
        console.log('result tx: ', result)

        if (result.hash) {

          this.setAllFee(ops_fee)
          setTimeout(() => {
            this.setOpsState(payments, save_payments, type, result.hash)
          }, 5000)
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        showErrorMessage(error, 'payment')

        const reSubmit = () => {

          setTimeout(() => {
            this.payRewards(payments, save_payments, address)
          }, 10000) // 10 sec
        }

        if (error.response) {

          console.log('error.response: ', error.response)

          if (error.response.data && error.response.data.title === 'Timeout' ||
            error.response.data.extras.result_codes.transaction === "tx_bad_seq") {
            reSubmit()
          }
          else if (error.response.data.extras.result_codes.transaction === 'tx_insufficient_fee' ||
            error.response.data.extras.result_codes.transaction === 'tx_too_late') {

            console.log('Timeout 5 min...')

            setTimeout(() => {
              reSubmit()
            }, 60000) // 1 min
          }
          else if (error.response.data.extras.result_codes.operations &&
            error.response.data.extras.result_codes.operations[0] === 'op_success') {
            this.setOpsState(payments, save_payments, type, 'none')
          }
          else {
            reSubmit()
          }
        }
        else {
          reSubmit()
        }

        // setTimeout(() => {
        //   this.payRewards(payments, save_payments, address)
        // }, 100)
      });
  }


  setOpsState = (payments, save_payments, type, hash) => {

    const { count_sent, start_time, start_time_sent, all_holders, } = this.state

    this.setState({
        count_sent: (count_sent + payments.length),
        amount_sent: this.amount_sent,
        duration: getDurationTime(start_time),
        duration_sent: getDurationTime(start_time_sent),
        // holders: holders.length ? holders.slice(this.minItems) : [],
        // pool_holders: pool_holders.length ? pool_holders.slice(this.minItems) : [],
        all_holders: all_holders.length ? all_holders.slice(this.minItems) : [],
      },
      () => {

        if (this.state.all_holders.length) {
          this.setOperations()
        }
        else {

          this.setState({
            send_loader: false,
            done: true,
          })

          swal({
            title: "Done!",
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
        }

        const formData = new FormData();
        formData.append('payments', JSON.stringify(save_payments))
        formData.append('hash', hash)
        formData.append('type', type)

        axios.post(`${GLOBE.API_URL}/set_rewards`, formData).then(result => {
          console.log('set_rewards result: ', result)
        });
      })
  }


  startRefPayout = () => {

    swal({
      title: "Confirm",
      // text: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.payRefRewards() // setRefOperations()
        }
      })
  }



  setRefOperations = () => {

    const { counter_asset, ref_payments, partners, start_time_ref_sent, top_10 } = this.state

    const opsReferrals = []
    const opsPartners = []
    const savePayments = []

    if (!start_time_ref_sent) {
      this.setState({ start_time_ref_sent: String(new Date), })
    }

    ref_payments.forEach((item, index) => {

      console.log('item: ', item)

      if (index < this.minRefItems) {

        console.log('item.bonus_amount: ', item.bonus_amount)

        if (item.bonus_amount > 0) {

          opsReferrals.push(StellarSdk.Operation.payment({
            destination: item.account,
            asset: counter_asset,
            amount: String(item.bonus_amount),
          }))

          savePayments.push({
            address: item.account,
            amount: item.bonus_amount,
            buy_id: item.id,
            ref_code: item.ref_code,
            type: 'referral',
          })

          const partner = partners.filter(partner => partner.code === item.ref_code)[0]
          // const level = partner.level === 20 ? 4 : partner.level === 15 ? 3 : partner.level === 10 ? 2 : 1
          console.log('partner: ', partner)
          // console.log('level: ', partner.level)
          // const partner_amount = (item.bonus_amount * 2)
          const partner_amount = Number((item.counter_amount * partner.level / 100).toFixed(7))

          // console.log('Referral: ', item.account)
          // console.log('Partner: ', partner.address)
          console.log('partner_amount: ', partner_amount)


          if (item.account !== partner.address && partner.level && partner.level > 0) {

            console.log('SetOps!')

            opsReferrals.push(StellarSdk.Operation.payment({
              destination: partner.address,
              asset: counter_asset,
              amount: String(partner_amount),
            }))

            savePayments.push({
              address: partner.address,
              amount: partner_amount,
              buy_id: item.id,
              ref_code: item.ref_code,
              level: partner.level,
              type: 'partner',
            })

            this.amount_bonus_sent = Number((Number(this.amount_bonus_sent) + Number(item.bonus_amount)).toFixed(7))
            this.amount_partner_sent = Number((Number(this.amount_partner_sent) + partner_amount).toFixed(7))

            this.total_partner_amount += (partner_amount)
          }
        }
      }
    })

    console.log('opsReferrals: ', opsReferrals)
    console.log('savePayments: ', savePayments)

    if (opsReferrals.length) {

      this.setState({
        opsReferrals,
        savePayments,
        partner_amount: this.total_partner_amount,
      }, () => {

        // this.payRefRewards()
      })
      // this.payRefRewards(opsReferrals, savePayments)
    }
  }


  payRefRewards = () => {

    const { counter_asset, trader_1, ref_payments, opsReferrals, savePayments, fee } = this.state
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(trader_1).sk))

    console.log('opsReferrals: ', opsReferrals)
    console.log('savePayments: ', savePayments)

    console.log('loadAccount... ')

    // return

    Server.loadAccount(trader_1)
      .then((sourceAccount) => {
        console.log('sourceAccount: ', sourceAccount)

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        opsReferrals.forEach(op => tx.addOperation(op));
        tx = tx.addMemo(StellarSdk.Memo.text(`Referral Reward`))
        tx = tx.setTimeout(100).build();
        tx.sign(signer);
        console.log('transaction: ', tx)

        return Server.submitTransaction(tx);
      })
      .then((result) => {
        console.log('result tx: ', result)

        if (result.hash) {

          this.setState({
            // count_sent: (this.state.count_sent + payments.length),
            amount_bonus_sent: this.amount_bonus_sent,
            amount_partner_sent: this.amount_partner_sent,
            // duration: getDurationTime(start_time),
            // duration_sent: getDurationTime(start_time_sent),
            ref_payments: ref_payments.slice(this.minRefItems),
          }, () => {

            if (this.state.ref_payments.length) {
              this.setRefOperations()
            }
            else {

              // this.setState({ send_loader: false, })

              swal({
                title: "Referrals Done!",
                icon: "success",
                buttons: {
                  // cancel: true,
                  confirm: true,
                },
              })

              const formData = new FormData();
              formData.append('payments', JSON.stringify(savePayments))
              formData.append('hash', result.hash)
              formData.append('type', 'referral')

              axios.post(`${GLOBE.API_URL}/set_rewards`, formData).then(result => {
                console.log('set_rewards result: ', result)
              });
            }
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        showErrorMessage(error, 'payment')

        setTimeout(() => {
          // this.payRefRewards(opsReferrals, savePayments)
          this.payRefRewards()
        }, 100)
      });
  }


  autoStartPayments = (e) => {

    if (e.target) {

      this.setState({
        auto_start: e.target.value === 'true' ? false : true
      }, () => {

        localStorage.setItem('auto_start_pay', this.state.auto_start)
      })
    }
  }


  checkAutoStart = () => {
    const auto_start = localStorage.getItem('auto_start_pay')
    console.log('auto_start: ', auto_start)

    if (auto_start === 'true')
      this.setState({ auto_start: true })
  }


  startSending = () => {
    console.log('startSending...')

    this.startSendingPayments()
  }




  render() {

    console.log('Reward state: ', this.state)

    const { start_time, count_holders, count_filtered, count_sent, amount_sent, start_time_sent, duration, duration_sent, count_pool,
      min_balance, apr, pool_apr, error, ref_payments, all_payments, bonus_amount, partner_amount, vol, fee, cycle_fee, all_cycle_fee, price,
      auto_start, pool, filtered, all_rewards, pool_balances, hold_balances, all_balances,  } = this.state


    const strop_xlm = stellarStroop()
    const fee_xlm =  (fee * strop_xlm).toFixed(7)
    // const fee_usd =  (fee * strop_xlm * price).toFixed(7)
    const cycle_fee_xlm = (cycle_fee * strop_xlm).toFixed(7)
    // const cycle_fee_usd =  ((cycle_fee * strop_xlm) * price).toFixed(7)
    const all_cycle_fee_xlm = (all_cycle_fee * strop_xlm).toFixed(7)
    // const all_cycle_fee_usd = ((all_cycle_fee * strop_xlm) * price).toFixed(7)



    return(
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title">Reward</h3>
          </Col>
        </Row>


        <Card>
          <CardBody className={'text-secondary'}>
            <div className="card__title">
              <h5 className="bold-text">Holders & Pools</h5>
            </div>
            <Row>
              <Col md={2}>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="success"
                          onClick={this.start}>Start</Button>
                </ButtonToolbar>

                <form className={'wizard__form material-form'}>
                  {
                    pool ?
                      <div className="form__form-group form__form-group-field">
                        <Field
                          name="auto_start"
                          defaultChecked={auto_start}
                          component={renderCheckBoxField}
                          label="Auto Start"
                          className="colored-click"
                          onChange={this.autoStartPayments}
                        />
                      </div>
                    : null
                  }
                </form>

                {
                  filtered && !auto_start ?
                    <Button color="primary"
                            outline
                            size={'sm'}
                            onClick={this.startSending}>
                      Start sending</Button>
                  :  null
                }

                <br/>
                <div>{error ? <b className={'text-danger'}>Error</b> : null}</div>
              </Col>
              <Col md={2}>
                <div>Min balance: <b className={'text-info'}>{numFormat(min_balance)}</b> SCOP</div>
                <div>Holder APY: <b className={'text-info'}>{apr}%</b></div>
                <div>Pool APY: <b className={'text-info'}>{pool_apr}%</b></div>
              </Col>
              <Col md={3}>
                <div>Start: <b>{formatDate(start_time)}</b></div>
                <div>Found: {' '}
                  {
                    this.state.found_loader ?
                      <span style={{position: 'relative'}}>
                        <PacmanLoader
                          css={override}
                          size={8}
                          color={"#70bbfd"}
                          loading={true}
                        />
                      </span>
                    : null
                  }
                  <b className={'text-info'}>{numFormat(count_holders)}</b>
                </div>
                <div>Filtered: <b className={'text-info'}>{numFormat(count_filtered)}</b> &nbsp; <span><small>(Pool hold: <b className={'text-info'}>{count_pool}</b>)</small></span></div>
              </Col>
              <Col md={3}>
                <div>Start send: <b>{formatDate(start_time_sent)}</b></div>
                <div>Sent: {' '}
                  {
                    this.state.send_loader ?
                      <span style={{position: 'relative'}}>
                        <BarLoader
                          css={override_loader}
                          color={"#70bbfd"}
                          loading={true}
                          width={65}
                          height={4}
                        />
                      </span>
                    : null
                  }
                  <b className={'text-info'}>{numFormat(count_sent)}</b>
                </div>
                <div>Amount sent: <b className={'text-info'}>{numFormat(amount_sent, 7)}</b> SCOP</div>
              </Col>
              <Col md={2}>
                <div>Duration: <b className={'text-info'}>{duration}</b></div>
                <div>Duration sent: <b className={'text-info'}>{duration_sent}</b></div>
              </Col>
            </Row>

            <hr/>

            <Row>
              <Col md={4}>
                <div>All balances: <b className={'text-info'}>{numFormat(all_balances)}</b> SCOP <small>/ <b>${0}</b></small></div>
                <div>Hold balances: <b className={'text-info'}>{numFormat(hold_balances)}</b> SCOP <small>/ <b>${0}</b></small></div>
                <div>Pool balances: <b className={'text-info'}>{numFormat(pool_balances)}</b> SCOP <small>/ <b>${0}</b></small></div>
                <div>All rewards: <b className={'text-info'}>{numFormat(all_rewards)}</b> SCOP <small>/ <b>${0}</b></small></div>
              </Col>
              <Col md={4}>
                <div>Fee: <b className={'text-info'}>{numFormat(fee)}</b> stroop <small>/ <b>{fee_xlm}</b> XLM</small></div>
                <div>Cycle Fee: <b className={'text-info'}>{numFormat(cycle_fee, 7)}</b> stroop <small>/ <b>{cycle_fee_xlm}</b> XLM</small></div>
                <div>All Fees: <b className={'text-info'}>{numFormat(all_cycle_fee, 7)}</b> stroop <small>/ <b>{all_cycle_fee_xlm}</b> XLM</small></div>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Referrals</h5>
            </div>

            <Row>
              <Col md={1}>
                <ButtonToolbar className="form__button-toolbar">
                  {/*<Button disabled={true} color="success" onClick={this.startRefPayout}>Start</Button>*/}
                </ButtonToolbar>
                <div>{error ? <b className={'text-danger'}>Error</b> : null}</div>
              </Col>
              <Col md={2}>
                <div>Ref payments: <b className={'text-info'}>{numFormat(all_payments)}</b></div>
                <div>Volume: <b className={'text-info'}>{numFormat(vol)}</b> SCOP</div>
                <div>Bonus amount: <b className={'text-info'}>{numFormat(bonus_amount, 2)}</b> SCOP</div>
                <div>Partner amount: <b className={'text-info'}>{numFormat(partner_amount, 2)}</b> SCOP</div>
                <div>Payment amount: <b className={'text-info'}>{numFormat(partner_amount + bonus_amount, 2)}</b> SCOP</div>
              </Col>
            </Row>

          </CardBody>
        </Card>

      </Container>

    )
  }
}


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Reward));
