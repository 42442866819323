import React, { PureComponent } from 'react';
import {Button, ButtonToolbar, ButtonGroup, Table, Card, CardBody, Badge} from 'reactstrap';
import { Link } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
// import { ToastContainer, ToastStore } from 'react-toasts';
import PropTypes from 'prop-types';
import {shortAddr, numFormat, formatCount, formatDate, getAPY} from '../../../../modules/index';
import getPrice from "../../../App/GetPrice";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import NotificationSystem from 'react-notification-system';
import {ToastStore} from "react-toasts";

const loaderStyle = css`
  //display: inline-block;
  //position: absolute;
  //width: 40px;
  //top: 0;
  //left: 5px;
`;



class TokenInfo extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      locked: false,
      load_asset: false,
      load_toml: false,
      asset_db: this.props.asset,
      asset: false,
    };
  }

  notificationSystem = React.createRef();


  componentDidMount() {
    const asset = this.props.data.project.sell_asset.split('-');
    const account_id = this.props.data.project.distr_addr;

    this.getTrades(asset);
    // this.getOffers(account_id);

    // this.getXlmUsdPrice();
    this.getBasePrice()

    // this.addNotification(asset)
  }

  addNotification = (trade) => {

    const asset = this.props.data.project.sell_asset.split('-');
    const notification = this.notificationSystem.current;


    let account = trade.base_account

    // console.log('trade_type: ', trade.trade_type)

    if (trade.trade_type === 'liquidity_pool') {
      if (!trade.base_is_seller) {
        account = trade.counter_account
      }
    }

    notification.addNotification({
      // title: `Bought ${asset[0]}`,
      message: `${shortAddr(account, 4)} bought ${numFormat(trade.counter_amount, 7)} ${asset[0]}`,
      level: 'info'
    });

  };


  setAssetData = () => {
    const asset = this.state.asset_db
    // console.log('setAssetData asset: ', asset)

    // this.state.asset.amount = asset.supply

    this.setState({
      price_xlm: asset.price,
      price_usd: (asset.price * this.state.price),
      market_cap: ((asset.supply * asset.price) * this.state.price),
      asset: {
        amount: asset.supply,
        num_accounts: asset.holders
      },
      load_asset: true,
    })
  }

  // componentWillMount() {
  //   this.getXlmUsdPrice();
  // }


  toggleFavorit() {
    //...
  }


  // getXlmUsdPrice() {
  //
  //   const price = JSON.parse(localStorage.getItem('price'));
  //
  //   this.setState({
  //     xlm_price: price,
  //   });
  //
  //   this.props.price(price.crypto_price.xlm_usd);
  //
  //   const asset = this.props.data.project.sell_asset.split('-');
  //
  //   this.getAsset(asset);
  // }


  getBasePrice() {
    let price = JSON.parse(localStorage.getItem('price'));
    // console.log('price: ', price)

    const setPrice = (price) => {

      this.setState({ price }, () => {
        this.props.price(price);
        const asset = this.props.data.project.sell_asset.split('-');
        this.setAssetData();
        this.getAsset(asset);
      });
    }

    if (price && price.crypto_price) {
      setPrice(price.crypto_price.xlm_usd)
    }
    else if (price && price.local_price) {
      setPrice(price.local_price.USD)
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }



  getIssuerAccount(account_id) {

    Server.loadAccount(account_id)
      .then((account) => {
        this.getLockStatus(account);
      })
      .catch((err) => {
        console.log('error: ', err);
        this.setState({ loader: false });
    })
  }



  getLockStatus(account) {

    let med = account.thresholds.med_threshold;
    let signers_weight = 0;

    account.signers.forEach(function (item) {
      signers_weight = (item.weight + signers_weight);
    });


    if (signers_weight === 0 ) { // false emission
        this.setState({locked: true})
    }
    else if (signers_weight < med ) { // false emission
        this.setState({locked: true})
    }
    else { // true emission
        this.setState({locked: false})
    }
  }



  getAsset(asset) {

    const account_id = this.props.data.project.distr_addr;

    Server.assets()
      .forCode(asset[0])
      .forIssuer(asset[1])
      .call()
      .then((resp) => {
        // console.log('resp: ', resp)

        this.setState({
          asset: resp.records[0],
          load_asset: true,
        })

        this.getToml(resp.records[0]);
        this.getIssuerAccount(resp.records[0].asset_issuer);
        this.getOffers(account_id);
      })
      .catch(function(err) {
        console.error('err: ', err);
    });
  }



  getToml(asset) {

    // console.log('getToml asset: ', asset)

    const home_domain = asset._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
    // console.log('home_domain: ', home_domain)

    if (home_domain) {

      StellarSdk.StellarTomlResolver.resolve(home_domain)
        .then(assetToml => {

          assetToml.CURRENCIES.forEach((item) => {

            if (item.code === asset.asset_code && item.issuer === asset.asset_issuer) {

              this.setState({
                toml_full: assetToml,
                toml: item,
                home_domain: home_domain,
                load_toml: true,
              });
            }
          });
        })
        .catch(error => {
          console.log('toml err', error);
      });
    }
  }




  getTrades(asset) {

    if (this.props.data.base_asset === 'XLM-native') {
      var base_asset = new StellarSdk.Asset.native();
    }
    else {
      var split_asset = this.props.data.base_asset.split('-');
      var base_asset = new StellarSdk.Asset(split_asset[0], split_asset[1]);
    }

    const counter_asset = new StellarSdk.Asset(asset[0], asset[1]);

    // console.log('base_asset: ', base_asset)
    // console.log('counter_asset: ', counter_asset)

    this.streamTrades(base_asset, counter_asset);

    Server.trades()
      .forAssetPair(base_asset, counter_asset)
      .order('desc')
      .limit(30)
      .call()
      .then((result) => {

        // console.log('result trades: ', result)

        // if (result.records.length < limit_items || result.records.length === 0) {
        //   this.setState({trades_more_btn: false});
        // }
        // else {
        //   this.setState({trades_more_btn: true});
        // }

        if (result) {
          this.props.trades(result.records);

          this.setState({
            trades: result.records, // this.state.trades.concat(result.records),
            load_trades: true,
            // load_more: true,
          })
        }
      })
      .catch((err) => {
        console.log(err)
    })
  }



  streamTrades(base_asset, counter_asset) {

    Server.trades()
      .forAssetPair(base_asset, counter_asset)
      .cursor('now')
      .stream({
        onmessage: (message) => {

          // console.log('message: ', message)

          this.addNotification(message)

          this.props.trades(message);

          // ToastStore.info(`${shortAddr(message.base_account, 4)} bought ${numFormat(message.counter_amount, 7)} ${message.counter_asset_code}`);
        }
    });
  }



  getOffers(account_id) {

    // this.setState({ load_more: false })

    Server.offers().forAccount(account_id)
      .limit(50)
      .order('desc')
      .call()
      .then((result) => {
        // console.log('offers result: ', result)

        if (result.records.length) {
          const offers = result.records.reverse();
          // console.log('offers: ', offers)

          this.props.offers(offers);

          this.sessionCap(offers);

          this.setState({
            offers: offers, // this.state.offers.concat(result.records),
            load_offers: true,
            // load_more: true,
          })
        }

        // this.streamOffers(account_id);
      })
      .catch((err) => {
        console.error(err);
    })
  }


  // streamOffers(account_id) {
  //   var es = server.offers('accounts', account_id)
  //     .cursor('now')
  //     .stream({
  //       onmessage: (message) => {
  //         console.log('streamOffers message: ', message)
  //       }
  //     })
  // }



  sessionCap(offers) {

    const { trades } = this.state

    let amount_base_all = 0, amount_tokens_all = 0;

    offers.forEach((item) => {
      let amount_base = (Number(item.amount) * item.price);
      amount_tokens_all += Number(item.amount);
      amount_base_all += amount_base;
    });

    if (this.props.data.base_asset.split('-')[0] !== 'XLM') {
      var price_base_xlm = (trades[0].price.d / trades[0].price.n);
      var amount_xlm = (amount_base_all * price_base_xlm);
      var amount_usd = (amount_xlm * this.state.price).toFixed(7);
    }
    else {
      var amount_usd = Number(amount_base_all * this.state.price);
    }

    const price_xlm = (trades[0].price.d / trades[0].price.n)
    const session_cap_base = (this.props.data.project.session_supply * price_xlm);
    const session_cap_usd = (session_cap_base * this.state.price);
    let percent = 100 / (this.state.asset.amount / this.props.data.project.session_supply);
    let raised = (Number(session_cap_usd) - amount_usd);

    // let percent_raised = (100 / (this.props.data.project.session_cap / raised)).toFixed(2);
    // let remains = (this.props.data.project.session_cap - raised);


    this.setState({
      percent,
      amount_usd,
      session_cap_usd,
      amount_tokens: amount_tokens_all,
      raised,
      // percent_raised,
      price_xlm, // offers[0].price,
      price_usd: (price_xlm * this.state.price),
    });

    // const progress = {
    //   raised: raised,
    //   percent_raised: percent_raised,
    //   remains: remains,
    // }
    //
    // this.props.progress(progress);
  }


  // getBaseAsset(item) {
  //   const asset = item.split('-');
  //   return (
  //     <Link to={(item === 'XLM-native' ? '/native' : `` /asset/+asset[0]+'-'+asset[1])}>
  //       <b className="text-warning">{item === 'XLM-native' ? 'XLM' : asset[0]}</b>
  //     </Link>
  //   )
  // }

  getBaseAsset = (item) => {

    const asset = item.split('-');
    const base_asset = this.props.data.base_asset.split('-')
    return (
      <Link to={(item === 'XLM-native' ? '/native' :  `/trade/${asset[0]}-${base_asset[0]}/${asset[1]}/${base_asset[1]}`)}>
        <b className="text-warning">{item === 'XLM-native' ? 'XLM' : asset[0]}</b>
      </Link>
    )
  }





  render() {

    // console.log('props info: ', this.props)
    // console.log('state info: ', this.state)

    const { asset_db } = this.state

    return (
      <div>
        <NotificationSystem ref={this.notificationSystem} />

        <Card>
          <CardBody>

            {/*<ToastContainer store={ToastStore} position={ToastContainer.POSITION.BOTTOM_LEFT} />*/}

            {/*{*/}
            {/*  this.state.toml ?*/}
            {/*    */}
            {/*  : null*/}
            {/*}*/}

            <div className="profile__activity" style={{padding: '0 0 20px'}}>
              <div className="profile__activity-avatar">
                <Link to={`/trade/${this.props.asset.asset_code}-XLM/${this.props.asset.asset_issuer}/native`}>
                  <img src={this.props.asset.image} alt={`Scopuly | ${this.props.asset.asset_code}`} />
                </Link>
              </div>
              <div>
                <h3 className="profile__activity-code">
                  <Link to={`/trade/${this.props.asset.asset_code}-XLM/${this.props.asset.asset_issuer}/native`} className="text-secondary">{this.props.asset.asset_code}</Link>
                  <span className="profile__activity-date">&nbsp; {this.props.asset.name}</span>
                </h3>
                <Link className="text-secondary"
                      to={`/account/${this.props.asset.asset_issuer}`}
                      style={{fontSize: 12}}>
                  {shortAddr(this.props.asset.asset_issuer, 10)}{' '} {' '}
                  <span>
                      {this.state.locked ? <i className="fa fa-lock text-success"></i> : <i className="fa fa-unlock-alt text-info"></i>}
                    </span>
                </Link>
              </div>

              <a className="icon-favorit" onClick={() => this.toggleFavorit()}>
                  <span className="sidebar__link-icon lnr lnr-star"
                        style={{color: 'yelow', fontSize: '16px'}}/>
              </a>
            </div>


            <Table className="table--bordered asset-info" responsive>
              <tbody>
              <tr>
                <td>PRICE
                  {/*<small>(USD/{this.props.data.base_asset.split('-')[0]})</small>*/}
                </td>
                <td><small><b className={'text-secondary'}>${numFormat(this.state.price_usd, 7)}</b></small> / <b className="text-info">{numFormat(this.state.price_xlm, 7)}</b> <b className="text-warning">{this.getBaseAsset(this.props.data.base_asset)}</b></td>
              </tr>
              <tr>
                <td>CHANGE 24</td>
                <td>
                  <b className="text-info"><Badge color={asset_db.change_24h_color}>{asset_db.change24}%</Badge></b></td>
              </tr>
              <tr>
                <td>SESSION SUPPLY</td>
                <td>
                  {/*<small>{numFormat(this.state.percent, 2)}% / </small>*/}
                  <b className="text-info">{numFormat(this.props.data.project.session_supply, 0)}</b> {this.getBaseAsset(this.props.data.project.sell_asset)}</td>
              </tr>
              <tr>
                <td>SESSION CAP</td>
                <td>
                  {/*<small>${numFormat(this.state.session_cap_usd, 0)} / </small>*/}
                  <b className="text-info">${numFormat(this.props.data.project.session_cap, 0)}</b></td>
              </tr>
              <tr>
                <td>RAISED
                  {/*<small>(USD)</small>*/}
                </td>
                <td>

                  {
                    this.props.progress.raised ?
                      <span>
                        <small><b className={'text-success'}>{this.props.progress.raised_percent_usd}%</b> / </small>
                        <b className="text-info">${numFormat(this.props.progress.raised_usd, 0)}</b>
                      </span>
                      :
                      <BeatLoader
                        css={loaderStyle}
                        color={"#70bbfd"}
                        loading={true}
                        size={8}
                      />
                  }

                </td>
              </tr>
              <tr>
                <td>VOLUME 24
                  {/*<small>(USD/XLM)</small>*/}
                </td>
                <td>
                  <small><b className={'text-secondary'}>${numFormat(this.props.asset.base_volume * this.state.price)}</b> / </small>
                  <b className="text-info">{numFormat(this.props.asset.base_volume)}</b> <b className="text-warning">{this.getBaseAsset(this.props.data.base_asset)}</b>
                </td>
              </tr>
              <tr>
                <td>TOTAL SUPPLY</td>
                <td><b className="text-info">{this.state.load_asset ? `${numFormat(this.state.asset.amount)}` : '...'}</b> {this.getBaseAsset(this.props.data.project.sell_asset)}</td>
              </tr>
              <tr>
                <td>MARKET CAP</td>
                <td><b className="text-info">${numFormat(this.state.market_cap)}</b></td>
              </tr>
              <tr>
                <td>HOLDERS</td>
                <td><b className="text-info">{this.state.load_asset ? numFormat(this.state.asset.num_accounts, 0) : '...'}</b></td>
              </tr>
              {/*<tr>*/}
              {/*  <td>TOKEN TYPE</td>*/}
              {/*  <td><b className="text-info">{this.props.data.totals.type}</b></td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td>PLATRORM</td>*/}
              {/*  <td><b className="text-info">Stellar</b></td>*/}
              {/*</tr>*/}
              <tr>
                <td>MVP/PRODUCT</td>
                <td><b className="text-info">{this.props.data.project.product ? <a href={this.props.data.project.product} target='_blank' rel='noopener noreferrer' className="text-info">Available <i className="fa fa-external-link"></i></a> : '-'}</b></td>
              </tr>
              {/*<tr>*/}
              {/*  <td>COUNTRY</td>*/}
              {/*  <td><b className="text-info">{this.props.data.totals.country.value}</b></td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td>RESTRICTED AREAS</td>*/}
              {/*  <td style={{textAlign: 'right'}}><b className="text-info">{this.props.data.totals.restricted_areas.value}</b></td>*/}
              {/*</tr>*/}
              <tr>
                <td>ISSUER URL</td>
                <td><b><a className="text-info" href={`https://${this.props.asset.home_domain}`} target='_blank' rel='noopener noreferrer'>{this.props.asset.home_domain}</a></b></td>
              </tr>
              <tr>
                <td>START TIME</td>
                <td><b className="text-info">{formatDate(this.props.data.totals.time[0])}</b></td>
              </tr>
              <tr>
                <td>END TIME</td>
                <td><b className="text-info">{formatDate(this.props.data.totals.time[1])}</b></td>
              </tr>
              <tr>
                <td>REWARDS</td>
                <td>
                  {/*<b className="text-warning">{this.getBaseAsset(this.props.data.base_asset)}</b>*/}
                  <div>
                    <Link to={`/rewards`}><div className={'rewards pool-rewards pool-color-scop'}><b>+{numFormat(getAPY('pool'))}% SCOP <i className={'fa fa-question-circle-o'}></i></b></div></Link>
                  </div>
                </td>
              </tr>
              </tbody>
            </Table>
            <ButtonToolbar style={{marginTop: 28}}>
              <ButtonGroup className="btn-group--justified wr-docs">
                <Button color="secondary" outline href={this.props.data.project.website} target='_blank' rel='noopener noreferrer'>Website</Button>
                <Button color="secondary" outline href={this.props.data.project.white_paper} target='_blank' rel='noopener noreferrer'>WhitePaper</Button>
                <Button color="secondary" outline href={'https://scop.scopuly.com/'} target='_blank' rel='noopener noreferrer'>Tokenomics</Button>
              </ButtonGroup>
            </ButtonToolbar>
          </CardBody>
        </Card>

      </div>
    );
  }
}

export default TokenInfo;
