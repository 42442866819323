import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../Account/components/iconIdent';
import {numFormat, formatDate, getAsset, shortAddr, shortAddress} from '../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class AssetOffers extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: '1',
    };
  }


  renderItem = (item, index) => {
    // console.log('index: ', index++)
    // console.log('item: ', item)


    // XLM && source_amount -> sell XLM
    // !XLM && amount -> buy XLM
    //
    // XLM && amount -> buy SCOP
    // !XLM && source_amount -> sell SCOP

    let amountA, amountB, assetA, assetB, price = 0, type, typeElement,
      offer_id = item.offer ? item.offer : item.createdOffer

    if (item.assets[0] !== 'XLM' && item.assets[1] !== 'XLM') {
      if (item.amount) {
        typeElement = (<span className={'text-success'}>buy</span>)
        type = 'buy'
      }
      else {
        typeElement = (<span className={'text-danger'}>sell</span>)
        type = 'sell'
      }
      assetA = item.assets[item.source_amount ? 0 : 1].slice(0, -2).replace("-", ":")
      assetB = item.assets[item.source_amount ? 1 : 0].slice(0, -2).replace("-", ":")
      // price = (item.price.n / item.price.d)
      // price = item.price
      price = item.price.d ? (item.price.d / item.price.n) : item.price
      amountA = item.amount ? item.amount : item.source_amount
      amountB = (amountA * price)
    }

    else if (item.assets[0] === 'XLM' && item.source_amount) {

      typeElement = (<span className={'text-danger'}>sell</span>)
      type = 'sell'
      assetA = item.assets[1].slice(0, -2).replace("-", ":")
      assetB = 'native'
      // price = (item.price.d / item.price.n)
      amountA = item.source_amount
      amountB = (amountA / item.price)
      price = (amountB / amountA)
    }

    else if (item.assets[0] !== 'XLM' && item.amount) {
      typeElement = (<span className={'text-success'}>buy</span>)
      type = 'buy'
      assetA = 'native'
      assetB = item.assets[0].slice(0, -2).replace("-", ":")
      // price = (item.price.d / item.price.n)
      price = item.price.d ? (item.price.d / item.price.n) : item.price
      amountA = item.amount
      amountB = (amountA / price)
    }

    else if (item.assets[0] === 'XLM' && item.amount) {
      typeElement = (<span className={'text-success'}>buy</span>)
      type = 'buy'
      assetA = item.assets[1].slice(0, -2).replace("-", ":")
      assetB = 'native'
      price = item.price.d ? (item.price.n / item.price.d) : item.price
      // price = item.price
      amountA = item.amount
      amountB = (amountA * price)
    }

    else if (item.assets[0] !== 'XLM' && item.source_amount) {
      typeElement = (<span className={'text-danger'}>sell</span>)
      type = 'sell'
      assetA = item.assets[0].slice(0, -2).replace("-", ":")
      assetB = 'native'
      // price = (item.price.n / item.price.d)
      // price = item.price
      price = item.price.d ? (item.price.d / item.price.n) : item.price
      amountA = item.source_amount
      amountB = (amountA * price)
    }



    // console.log('price: ', price)

    if (price)

    return (
      <tr key={index}>
        <td><small>{index+1}</small></td>
        <td>
          <b>{shortAddress(item.accounts[0])}</b> {' '}
          <CopyToClipboard text={item.accounts[0]} onCopy={() => {}}>
            <i className="fa fa-clone"
               color="secondary"
               style={{cursor: 'pointer', fontSize: 14}}
               onClick={() => ToastStore.success('Copied!')}></i>
          </CopyToClipboard> {' '}
          {/*{amountA > 0 ? <span><span className={'text-info'}>updated</span> offer</span> : <span><span className={'text-secondary'}>canceled</span> offer</span>}*/}
        </td>
        <td>
          {
            item.createdOffer ?
              <span><i className="fa fa-check text-success"></i>&nbsp; <b className='text-success'>created</b> offer</span>
              :
              amountA > 0 ?
                <span><i className="fa fa-refresh text-info"></i>&nbsp; <b className='text-info'>updated</b> offer</span>
                :
                amountA === '0' || !offer_id ?
                  <span><i className="fa fa-close text-secondary"></i>&nbsp; <b className='text-secondary'>canceled</b> offer</span>
                : '---'

          } {' '}
        </td>
        <td>
          {
            offer_id ?
              <Link to={`/offer/${offer_id}`}><b>{ offer_id }</b></Link>
            :
              <b className='text-secondary'>canceled</b>
          }
        </td>
        {/*shortAddr(offer_id, 4)*/}
        <td>
          <div>
            {typeElement} <b className="text-info">{numFormat(amountA, 7)}</b> {' '}
            {getAsset(assetA)} / {type === 'buy' ? <span className={'text-danger'}>sell</span> : <span className={'text-success'}>buy</span>} {' '}
            <b className="text-info">{numFormat(amountB, 7)}</b> {getAsset(assetB)}
          </div>

        </td>
        <td>
          <b className="text-info">{ numFormat(price, 7) }</b> {' '}
          <small>{getAsset(assetB, false, 'secondary')}</small>
        </td>
        <td>{ formatDate(item.ts * 1000) }</td>
        <td><b><Link to={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</Link></b></td>
      </tr>
    )
  }


  render() {
    const items = this.props.items;

    return (
      <Table striped responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Action</th>
          <th>Offer</th>
          <th>Details</th>
          <th>Price</th>
          <th>Date</th>
          <th>Operation</th>
        </tr>
        </thead>
        <tbody>

        { items.map(this.renderItem) }

        </tbody>
      </Table>
    )
  }
}


export default translate('common')(AssetOffers);
