import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import getPrice from './GetPrice'
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faIgloo } from '@fortawesome/free-solid-svg-icons'
// library.add(faIgloo)

// if (window.location.host === 'scopuly.com') {
//   if (window.location.protocol === 'http:') {
//     window.location.href = 'https://scopuly.com'+window.location.pathname + window.location.hash;
//   }
// }



getPrice()

i18next.init(i18nextConfig);

class App extends Component {
  constructor() {
    super();
    this.state = {
      // loading: true,
      // loaded: false,
    };
  }

  componentDidMount() {

    // if (window.location.host === 'scopuly.com') {
    //   if (window.location.protocol === 'http:') {
    //     window.location.href = 'https://scopuly.com'+window.location.pathname + window.location.hash;
    //   }
    // }

    if (!localStorage.getItem('stellar_fee')) {
      localStorage.setItem('stellar_fee', 10000)
    }
  }

  render() {
    // const { loaded, loading } = this.state;

    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <I18nextProvider i18n={i18next}>
            <ScrollToTop>
              {/*{!loaded &&
                <div className={`load${loading ? '' : ' loaded'}`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              }*/}
              <Router />
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
