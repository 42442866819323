import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ToastContainer, ToastStore } from 'react-toasts';
import { shortAddr, getTitle } from '../../../modules/index';
import moment from 'moment';
import LoadingIcon from 'mdi-react/LoadingIcon';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';



class Contacts extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      contacts: JSON.parse(localStorage.getItem('contacts'))
    };
  }

  componentDidMount() {
    getTitle(`Contacts`);
  }


  removeItem(index) {

    swal({
      title: 'Confirmation',
      text: 'Do you really want to delete this contact right now?',
      icon: 'warning',
      buttons: {
        cancel: true,
        confirm: true,
      }
    })
    .then((vlaue) => {
      if (vlaue) {
        remove();
      }
    })

    const remove = () => {

      let local_contacts = localStorage.getItem('contacts');
      local_contacts = JSON.parse(local_contacts);

      local_contacts.splice(index, 1);
      ToastStore.success('Address removed from contacts');

      let local_contacts_str = JSON.stringify(local_contacts);
      localStorage.setItem('contacts', local_contacts_str);

      this.setState({
        contacts: local_contacts
      });
    }
  }


  renderAddress(item, index) {

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td><Link to={`/account/${item.address}`}><b>{shortAddr(item.address, 6)}</b></Link></td>
        <td>{item.name}</td>
        <td>{moment(item.date).format("DD MMMM YYYY")}</td>
        <td><Link to={`/send/${item.address}`} className="table-btn btn btn-outline-secondary btn-sm">
              <span className="lnr lnr-location" /></Link>
        </td>
        <td><Button color="secondary"
                    size="sm" outline
                    className="table-btn"
                    onClick={() => this.removeItem(index)}>
              <span className="lnr lnr-trash" />
            </Button>
        </td>
      </tr>
    )
  }



  render() {

    return (
        <Container>

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          {/*<Row>
            <Col md={12}>
              <h3 className="page-title">Contacts</h3>
              <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>fasdfasfasdf</h4>
            </Col>
          </Row>*/}

          <Row>
            <Col lg={{ size: 10, offset: 1 }} md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">My Contacts</h5>
                    <h5 className="subhead">Addresses I added to my contact list</h5>
                  </div>

                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Address</th>
                        <th>Name</th>
                        <th>Date added</th>
                        <th>Send</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        this.state.contacts ?
                          this.state.contacts.length > 0 ?
                            this.state.contacts.map(this.renderAddress.bind(this))
                          : null
                        :
                          <p>You have not added any addresses to your contacts yet.</p>
                      }

                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Footer />

        </Container>
    );
  }
}


Contacts.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Contacts);

