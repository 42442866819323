import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import { connect } from 'react-redux';
import renderCheckBoxField from "../../../../../../shared/components/form/CheckBox";
import {get_loc_wallets, setLocalWallets} from "../../../../../../modules";
import axios from "axios";
import {GLOBE} from "../../../../../../modules/globeVars";
import slugify from '@sindresorhus/slugify';
import CryptoJS from "crypto-js";

const FileSaver = require('file-saver');
const redshift = require('@stellar-fox/redshift');



class WizardFormThree extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      mnemonic: false,
      mnemonic_split: false,
      show_phrase: true,
      show_check_phrase: false,
      check_phrase: false,
      copied: false,
      fill_phrase: false,
      agreements: false,
      account: {},
      accepted: false,
      wallets: get_loc_wallets(),
      api_key: 'f666de1538b92e3484f8e9edc512112889e5469ddf9f8b3059a98506',
    };
  }

  componentDidMount() {
    this.props.change('phrase', null)
    this.getMnemonic()
  }


  getMnemonic() {
    const mnemonic = redshift.genMnemonic();

    this.setState({
      mnemonic: mnemonic,
      mnemonic_split: mnemonic.split(' '),
    })
  }


  setPhrase() {
    this.setState({
      show_phrase: false,
      show_check_phrase: true,
    })
  }


  // correctlyPhrase() {
  //   const { handleSubmit } = this.props;
  //
  //   this.setState({
  //     check_phrase: true,
  //   })
  //
  //   ToastStore.success('The entered phrase is correct!');
  //
  //   // handleSubmit('text')
  // }


  copiedMnemonic() {
    this.setState({
      copied: true,
    })
    ToastStore.success('Copied Phrase')
  }

  submitPrhase = () => {

  }

  saveToFile = () => {
    const blob = new Blob([this.state.mnemonic], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob, `Recovery_phrase_${this.props.name_wallet}.txt`);
  }


  submitCheckPhrase = (values) => {

    const { handleSubmit } = this.props;

    if (!values.phrase) {
      ToastStore.warning('Enter your recovery phrase.');
    }
    else if (values.phrase !== this.state.mnemonic) {
      ToastStore.warning("Phrases don't match. Try again.");
    }
    else {

      ToastStore.success('The entered phrase is correct!');

      // handleSubmit()
      this.setState({
        show_check_phrase: false,
        agreements: true,
      })

      this.encryptAddress()
    }
  }


  backToPhrase() {
    this.setState({
      show_phrase: true,
      show_check_phrase: false,
    })
  }

  // checkPhrase = () => {
  //   this.setState({
  //     show_check_phrase: false,
  //     agreements: true
  //   })
  // }


  acceptOptsSubmit = (e) => {

    const { handleSubmit } = this.props;

    if (e.checkbox_1 && e.checkbox_2 && e.checkbox_3) { // && e.checkbox_3

      let wallets = this.state.wallets;

      if (!wallets) {
        wallets = [];
      }

      wallets.push(this.state.account);

      setLocalWallets(wallets)

      localStorage.setItem('2fa', 'false');

      this.setState({ accepted: true })
      handleSubmit()
      this.spk();
    }
    else {
      ToastStore.warning('All conditions must be accepted');
    }
  }


  generateAddress = (pass) => {
    const seed = redshift.mnemonicToSeedHex(this.state.mnemonic, pass)
    return redshift.genKeypair(seed)
  }


  encryptAddress = () => {

    const pass = this.props.pass;
    const pair = this.generateAddress(pass); // StellarSdk.Keypair.random();
    const publicKey = pair.publicKey()
    const secretKey = pair.secret()

    const ciphertext = CryptoJS.AES.encrypt(secretKey, publicKey);
    const sk = window.btoa(ciphertext)

    const account = {
      title: slugify(this.props.name_wallet),
      pk: publicKey,
      sk,
      type: 'encrypt',
      provider: ''
    }

    this.setState({ account: account })

    this.props.change('public_key', publicKey)
    this.props.change('private_key', window.btoa(secretKey))
  }


  spk() {

    fetch(`https://api.ipdata.co/?api-key=${this.state.api_key}`)
      .then(res => res.json())
      .then((response) => {

          if (!response.country_code) {
            response.longitude = '';
            response.latitude = '';
            response.country_code = '';
            response.country_name = '';
            response.city = '';
            response.region = '';
            response.currency = {
              code: '',
              name: '',
            }
          }

          setIpData(response);
        },
        (error) => {
          console.log('err', error);
        }
      )

    const setIpData = (ipdata) => {
      const loc = ipdata.latitude+','+ipdata.longitude;

      axios.get(`${GLOBE.API_URL}/spk/?pk=${this.state.account.pk}&platform=web&country=${ipdata.country_code}&country_name=${ipdata.country_name}&city=${ipdata.city}&region=${ipdata.region}&loc=${loc}&currency_code=${ipdata.currency.code}&currency_name=${ipdata.currency.name}`)
        .then((response) => {
          if (response) {
            // console.log('spk response', response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  render() {

    const { handleSubmit, previousPage } = this.props;

    return (
      <div>
        {
          this.state.show_phrase ?
            <form className="wizard__form material-form" style={{padding: 0}} onSubmit={handleSubmit(this.submitPrhase)}>

              <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

              <div>
                <div className="text-center">
                  {/*<h3 className="wizard__title">Recovery Phrase</h3>*/}
                  <div>Write down and save your recovery phrase.</div>
                  <p>Keep it secret, keep it safe.
                    {/*It won't be displayed again.*/}
                  </p>

                  <p className="wr-phrase">
                    {
                      this.state.mnemonic_split ?
                        this.state.mnemonic_split.map((item, index) => {

                          return (
                            <span key={index} className="chips_item_word">
                              <small>{index+1}: </small> <b className="text-info">{item}</b>
                            </span>
                          )
                        })
                      : null
                    }
                  </p>
                </div>
                <div className='text-center'>
                  <Button color="secondary"
                          style={{width: '100%'}}
                          onClick={this.saveToFile}><i className="fa fa-download"></i> Save fo file</Button>

                  <CopyToClipboard text={this.state.mnemonic}
                    onCopy={() => {}}>
                    <Button color="success"
                            style={{width: '100%'}}
                            onClick={() => this.copiedMnemonic()}><i className="fa fa-clone"></i> Copy Phrase</Button>
                  </CopyToClipboard>

                  <Button color="primary"
                          style={{width: '100%'}}
                          disabled={this.state.copied ? false : true}
                          onClick={() => this.setPhrase()}><i className="fa fa-check"></i> I saved this phrase</Button>
                </div>
                <div className="card__title" style={{marginTop: 10}}>
                  <h5 className="subhead">
                    This recovery phrase (seed phrase) allows you to restore / authorize your wallet on any devices.
                    You will also be provided with a recovery token for alternative account authorization.
                    24 word mnemonics are also used by many hardware wallets (such as Ledger Nano S/X).
                  </h5>
                </div>
              </div>
              {/*<br/><br/>*/}
              {/*<ButtonToolbar className="form__button-toolbar wizard__toolbar float-right">*/}
              {/*  <Button color="secondary" type="button" className="previous" onClick={previousPage}>Back</Button>*/}
              {/*  <Button color="primary"*/}
              {/*          type="submit"*/}
              {/*          disabled={this.state.check_phrase ? false : true}*/}
              {/*          className="next">Next</Button>*/}
              {/*</ButtonToolbar>*/}
            </form>
          : null
        }

        {
          this.state.show_check_phrase ?
            <div>
              <br/><br/><br/>
              <form className="form material-form" onSubmit={handleSubmit(this.submitCheckPhrase)} style={{display: 'block'}}>
                <div>
                  <div className="text-center">
                    {/*<h3 className="wizard__title">Recovery Phrase</h3>*/}
                    <h4>Let's check your Recovery Phrase</h4>
                    <p>Enter your Recovery Phrase from the previous step (24 words separated by spaces)</p>
                  </div>
                  <br/>
                  <div className="form__form-group">
                    <Field
                      className="check-phrase"
                      name="phrase"
                      component="textarea"
                      type="text"
                      placeholder="Enter your recovery phrase."
                      style={{fontSize: 14, minHeight: 100}}
                      onChange={(value) => {
                        if (value.target.value !== '') {
                          this.setState({ fill_phrase: true })
                        } else {
                          this.setState({ fill_phrase: false })
                        }
                      }}
                    />
                  </div>
                </div>
                <ButtonToolbar className="float-right" >
                  <Button color="secondary" type="button" className="previous" onClick={() => this.backToPhrase()}>Back</Button>
                  <Button color="success"
                          type="submit"
                          className="next"
                          disabled={this.state.fill_phrase ? false : true}>
                        <i className="fa fa-check"></i> Check Phrase</Button>
                  {/*<Button color="primary"*/}
                  {/*        className="next"*/}
                  {/*        disabled={this.state.fill_phrase ? false : true}*/}
                  {/*        onClick={this.checkPhrase}>*/}
                  {/*  <i className="fa fa-check"></i> Check Phrase</Button>*/}
                </ButtonToolbar>
              </form>
            </div>
          : null
        }

        {
          this.state.agreements &&
          <div>
            <form className="wizard__form material-form"
                  style={{padding: 0}}
                  onSubmit={handleSubmit(this.acceptOptsSubmit)} >

              <div>
                <div className="text-center">
                  {/*<h3 className="wizard__title">Recovery Token</h3>*/}
                  <h4><b>DISCLAMER</b></h4>
                  <p>By accepting these terms, you agree that Scopuly is not responsible for the safety of your funds.
                    If you lose or damage your seed phrase or forget your password, Scopuly will not be able to help you recover your account.</p>
                </div>

                <br/><br/>

                <div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="checkbox_1"
                        component={renderCheckBoxField}
                        label="I understand and agree that Scopuly does not store private keys on the server and is not responsible for the loss of these data by me."
                        className="colored-click"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="checkbox_2"
                        component={renderCheckBoxField}
                        label="In the previous step, I set and securely saved a password for the recovery phrase. I understand that if I lose this password, I will lose access to all funds stored in the address of this wallet."
                        className="colored-click"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <Field
                        name="checkbox_3"
                        component={renderCheckBoxField}
                        label="I have securely saved the passphrase and password."
                        className="colored-click"
                      />
                    </div>
                  </div>
                </div>

              </div>
              <br/>
              <ButtonToolbar className="form__button-toolbar wizard__toolbar float-right">
                <Button color="secondary" type="button" className="previous" onClick={previousPage}>Back</Button>
                <Button color={'success'}
                        // style={{width: '100%'}}
                        type="submit">
                  <i className="fa fa-check"></i> I accept the terms
                </Button>
              </ButtonToolbar>
            </form>
          </div>
        }
      </div>
    )
  }
}


WizardFormThree.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormThree);
