import React, { PureComponent, useState } from 'react';
import {
  Col, Container, Row, Card, CardBody, Button, ButtonGroup, Badge, Alert,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToolbar, UncontrolledTooltip, Modal
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import {shortAddr, numFormat, get_loc_wallets, getTitle, setLocalWallets, getBasePrice} from '../../../modules/index';
import {
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
  LinkedinShareButton, LinkedinIcon,
  RedditShareButton, RedditIcon,
  TelegramShareButton, TelegramIcon,
  TwitterShareButton, TwitterIcon,
  ViberShareButton, ViberIcon,
  WhatsappShareButton, WhatsappIcon,
} from "react-share";
import LoadingIcon from 'mdi-react/LoadingIcon';
import WalletItem from './components/WalletItem'
import Footer from "../../Layout/footer/Footer";
import {QRCode} from "react-qrcode-logo";
import {connect} from "react-redux";
import swal from "sweetalert";
import {toggleAuthProvider} from "../../../redux/actions/customizerActions";
import {getDataImgIdent} from "../Account/components/iconIdent";



class Wallet extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
      address: false,
      accounts: [],
      wallets: get_loc_wallets(),
      wallets_format: false,
      wallets_load: false,
      loader: true,
      all_balances_xlm: 0,
      all_balances_usd: 0,
      total_balances_usd: 0,
      all_balances: 0,
      all_pools: 0,
      all_offers: 0,
      native_price: 0,
      native_balances: 0,
      asset_balances: 0,
      price: 0,
      changeColor: 'secondary',
      change: false,
      all_invoices: [],
      connect_provider: false,
      base_price: 0,
      modal: false,
      dropdownOpen: false,
      share_open: false,
    };
  }

  count = 0;

  componentDidMount() {

    const title = 'Stellar Wallet | DeFi | Non-Custodial';
    const description = 'Make payments or payments with exchange along the path of offers (PathPayment) in crypto or in any of your tokens, around the world, without restrictions, without fees.';

    getTitle(title, description);

    this.getXLMPrice()
  }



  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)

    if (stellar) {

      this.setState({
        stellar,
        base_price: stellar.market_data.current_price.usd,
      })
    }
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen, });
  }


  allXlmBalances = (balance) => {

    const { all_balances_xlm, base_price } = this.state

    this.setState({
      all_balances_xlm: all_balances_xlm + Number(balance) ,
      all_balances_usd: (all_balances_xlm * base_price),
    })
  }


  totalAssetsUsd = (value) => {
    this.setState({
      total_balances_usd: (this.state.total_balances_usd + Number(value)),
    })
  }

  totalBalances = (value) => {
    this.setState({
      all_balances: (this.state.all_balances + Number(value.length))
    })
  }

  totalPools = (value) => {
    this.setState({
      all_pools: (this.state.all_pools + Number(value))
    })
  }

  totalOffers = (value) => {
    this.setState({
      all_offers: (this.state.all_offers + Number(value))
    })
  }


  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  showQrCode = (address) => {
    this.setState({ address, modal: !this.state.modal });
  }


  toggleShare = (e) => {
    e.preventDefault()
    this.setState({ share_open: !this.state.share_open, });
  }


  deauthAddress = (address, e) => {
    e.preventDefault()

    swal({
      title: "Attention!",
      text: "Are you sure you want to log out an account from local memory right now?",
      icon: "warning",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then(confirm => {
        if (confirm) {

          const wallets = this.state.wallets.filter(item => item.pk !== address)
          console.log('wallets: ', wallets)

          if (!wallets.length) {
            localStorage.removeItem('connect_provider')
            localStorage.removeItem('wallets')
            this.setState({
              wallets: false,
              wallets_load: false
            })
          }
          else {

            setLocalWallets(wallets)
            this.setState({ wallets })

            this.state.wallets.forEach(item => {
              if (item.provider) {
                localStorage.removeItem('connect_provider')
                this.setState({ wallets })
              }
            })
          }

          swal({
            title: "Log out!",
            text: "Wallet successfully deauthorized.",
            icon: "success",
            confirm: true,
          })
            .then(confirm => {
              if (confirm) {
                this.props.dispatch(toggleAuthProvider(false));
              }
            });
        }
      });
  }



  renderModal = () => {

    const { address, share_open } = this.state

    let classElem = '', theme = 'theme-light';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = ''
      theme = 'theme-dark'
    }

    const title = `No memo required. Stellar Address: ${address}`

    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        className={`${theme} modal-dialog--primary modal-dialog--header modal-dialog--swap modal-vertical`}
      >
        <div className={`modal__body text-center ${classElem}`}>

          <h4><b>Address</b></h4>
          <br/>

          <QRCode
            value={address}
            size="250"
            quietZone="30"
            // fgColor={f7.colors[color]}
            qrStyle="dots"
            // logoOpacity="0.8"
            logoImage={getDataImgIdent(address)}
            removeQrCodeBehindLogo={true}
            logoPadding={6}
            logoWidth="28" // 46
            logoHeight="28" // 46
            ecLevel="M"
            eyeRadius={[
              { // top/left eye
                outer: [10, 10, 0, 10],
                inner: [1, 1, 0, 1],
              },
              { // top/right eye
                outer: [10, 10, 10, 0],
                inner: [1, 1, 1, 0],
              },
              { // bottom/left
                outer: [10, 0, 10, 10],
                inner: [1, 0, 1, 1],
              },
            ]}
            eyeColor={[
              { // top/left eye
                outer: '#217fe4',
                inner: 'black',
              },
              { // top/right eye
                outer: '#217fe4',
                inner: 'black',
              },
              { // bottom/left eye
                outer: '#217fe4',
                inner: 'black',
              },
            ]}
          />

          <br/><br/><b>No memo required</b><br/><br/>

          <div>
            <div style={{wordWrap: 'break-word'}}>
              <small className={'qr-address'}>{address}</small>
            </div>

            <div className={'space-center mt-35'}>
              <div>
                <CopyToClipboard text={address}
                                 onCopy={() => {}}
                                 style={{display: 'block', marginRight: 50}}
                                 className={'text-secondary'}
                >
                          <span><i className="fa fa-clone wallet-button text-white"
                                   style={{cursor: 'pointer', fontSize: 14, padding: '14px 15px'}}
                                   onClick={() => ToastStore.success('Copied!')}></i> <br/><small>Copy</small></span>
                </CopyToClipboard>
              </div>
              <div>
                <a href={'#'}
                   className={'text-secondary'}
                   onClick={this.toggleShare}
                >
                  <span><i className={'wallet-button fa fa-share-alt text-white'}></i><br/><small>Share</small></span>
                </a>
              </div>
            </div>
          </div>

          <br/>

          {
            share_open &&
              <div>
                <div>
                  <TwitterShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>

                  <TelegramShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <TelegramIcon size={40} round />
                  </TelegramShareButton>

                  <WhatsappShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>

                  <FacebookShareButton url={'https://scopuly.com'} quote={title} className="share-item">
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                </div>

                <div>
                  <FacebookMessengerShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <FacebookMessengerIcon size={40} round />
                  </FacebookMessengerShareButton>

                  <RedditShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <RedditIcon size={40} round />
                  </RedditShareButton>

                  <LinkedinShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>

                  <EmailShareButton url={'https://scopuly.com'} title={title} className="share-item">
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                </div>

                {/*<ViberShareButton url={'scopuly.com'} title={'title'} className="share-item">*/}
                {/*  <ViberIcon size={40} round />*/}
                {/*</ViberShareButton>*/}

                <br/>
              </div>
          }

        </div>

      </Modal>
    )
  }


  render() {
    // console.log('state: ', this.state)

    const { wallets, base_price, all_balances_xlm, all_balances_usd, total_balances_usd, all_balances, all_pools, all_offers, address,
      dropdownOpen, } = this.state


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          address ? this.renderModal() : null
        }

        <Row>
          <Col md={{size: 8, offset: 2}} lg={{size: 4, offset: 4}} >

            <Card style={{height: 'auto'}}>
              <CardBody className={'text-white'}
                        style={{background: 'linear-gradient(102deg, rgb(102 40 250), rgb(3 185 224))'}}>
                <div className="card__title">
                  <h5 className="bold-text text-white">ALL WALLETS</h5>

                  <div className="subhead justify-content-start">
                    <div className={'fs-12 mr-14'}>Wallets: <b>{wallets ? wallets.length : 0}</b></div>
                    <div className={'fs-12 mr-14'}>Balances: <b>{all_balances}</b></div>
                    <div className={'fs-12 mr-14'}>Pools: <b>{all_pools}</b></div>
                    <div className={'fs-12 mr-14'}>Offers: <b>{all_offers}</b></div>
                    {/*<span>Wallets: <b>7</b></span> / <span>Balances: <b>790</b></span> / <span>Pools: <b>7</b></span> / <span>Offers: <b>7</b></span>*/}
                  </div>
                  {/*<h4 className="subhead text-white"><span>Wallets: <b>7</b></span> / <span>Balances: <b>790</b></span> / <span>Pools: <b>7</b></span> / <span>Offers: <b>7</b></span></h4>*/}
                </div>
                <div className="panel__btns">

                  <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="wallet-dropdown m-min-6">
                    <DropdownToggle  size="sm" className={'dropdown-round-btn'}><i className="fa fa-align-justify text-white"></i></DropdownToggle>

                    <DropdownMenu style={{fontSize: 14}}>
                      <DropdownItem><Link to={`/auth/create`} className="text-secondary"><i className="fa fa-plus"></i> Create Wallet</Link></DropdownItem>
                      <DropdownItem><Link to={`/auth/connect`} className="text-secondary"><i className="fa fa-sign-in"></i> Connect Wallet</Link></DropdownItem>
                      <DropdownItem><Link to={`/payments`} className="text-secondary"><i className="fa fa-location-arrow"></i> Payments</Link></DropdownItem>
                      <DropdownItem><Link to={`/mass-payments`} className="text-secondary"><i className="fa fa-list"></i> Mass Payments</Link></DropdownItem>
                      <DropdownItem><Link to={`/create-claimable-balance`} className="text-secondary"><i className="fa fa-clock-o"></i> Pending Payments</Link></DropdownItem>
                      <DropdownItem><Link to={`/receive`} className="text-secondary"><i className="fa fa-plus"></i> Payment Request</Link></DropdownItem>
                      <DropdownItem><Link to={`/pools#create`} className="text-secondary"><i className="fa fa-plus"></i> Create Pool</Link></DropdownItem>
                      <DropdownItem><Link to={`/create-token`} className="text-secondary"><i className="fa fa-plus"></i> Create Token</Link></DropdownItem>
                      <DropdownItem><Link to={`/invoices`} className="text-secondary"><i className="fa fa-qrcode"></i> Invoices</Link></DropdownItem>
                      <DropdownItem><Link to={`/settings`} className="text-secondary"><i className="fa fa-cog"></i> Settings</Link></DropdownItem>
                    </DropdownMenu>
                  </Dropdown>

                </div>

                <div className={'text-center'}>

                  <div className={'space-around'}>
                    <div>
                      <div>Native balances <a href={null} id="xlm_balances"><i className="fa fa-question-circle-o text-white"></i></a>
                        <UncontrolledTooltip placement="top" target="xlm_balances">
                          Total XLM balances for all connected wallets
                        </UncontrolledTooltip>
                      </div>
                      <div className={'fs-24 text-white'}><b>{numFormat(all_balances_xlm)} XLM</b></div>
                      <div className={'mt-min-6'} style={{opacity: 0.7}}><b>${numFormat(all_balances_usd, 2)}</b></div>
                    </div>

                    <div>
                      <br/>
                      <div>Total <a href={null} id="all_blances"><i className="fa fa-question-circle-o text-white"></i></a>
                        <UncontrolledTooltip placement="top" target="all_blances">
                          The total balance of all assets for all connected wallets. This value is not accurate,
                          since it doesn't calculate the liquidity of assets but is calculated from the current market price of assets.
                        </UncontrolledTooltip>
                      </div>
                      <div className={'text-white'}>≈ <b>${numFormat(total_balances_usd)}</b></div>
                    </div>
                  </div>

                  <hr style={{opacity: 0.2}}/>

                  <div className={'space-around mt-35'}>
                    <div><Link to={'/send'} className={'text-white'}><span><i className={'wallet-button fa fa-location-arrow'} style={{padding: '12px 15px'}}></i><br/><small>Send</small></span></Link></div>
                    <div><Link to={'/receive'} className={'text-white'}><span><i className={'wallet-button fa fa-qrcode'} style={{padding: '12px 15px'}}></i><br/><small>Receive</small></span></Link></div>
                    <div><Link to={'/swap'} className={'text-white'}><span><i className={'wallet-button fa fa-refresh'}></i><br/><small>Swap</small></span></Link></div>
                    <div><Link to={'/trade'} className={'text-white'}><span><i className={'wallet-button fa fa-bar-chart'} style={{padding: '12px'}}></i><br/><small>Trade</small></span></Link></div>
                    <div><Link to={'/rewards'} className={'text-white'}><span><i className={'wallet-button fa fa-usd'} style={{padding: '12px 17px'}}></i><br/><small>Earn</small></span></Link></div>
                  </div>

                </div>
              </CardBody>
            </Card>


            {
              wallets && base_price ?
                wallets.map((item, index) => {
                  return(
                    <WalletItem item={item}
                                index={index+1}
                                key={item.pk}
                                base_price={base_price}
                                allXlmBalances={this.allXlmBalances}
                                totalAssetsUsd={this.totalAssetsUsd}
                                totalBalances={this.totalBalances}
                                totalPools={this.totalPools}
                                totalOffers={this.totalOffers}
                                showQrCode={this.showQrCode}
                                deauthAddress={this.deauthAddress}

                    />
                  )
              })
              :
                null
            }

            {
              !wallets ?
                <Card style={{height: 'auto'}}>
                  <CardBody>
                    <div className={'text-center'}>
                      <img src="/img/connect-blue.png" alt="" style={{width: '100px'}}/>
                      <br/><br/>
                      <h4><b>Wallet is not connected</b></h4>
                      <p>Create a new Stellar account or connect your existing wallet.</p>
                      <br/>

                      <div>
                        <Link to={"/auth/create"} className={'btn btn-success w-100'}><i className={'fa fa-plus'}></i> <b>Create Account</b></Link>
                        <Link to={"/auth/connect"} className={'btn btn-outline-secondary w-100'}><i className={'fa fa-sign-in'}></i> <b>Connect Wallet</b></Link>
                      </div>

                    </div>
                  </CardBody>
                </Card>
              : null
            }


          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}




const mapStateToProps = (state) => {
  return {
    isAuthProvider: state.customizer.isAuthProvider
  }
}

export default connect(mapStateToProps)(translate('common')(Wallet));
