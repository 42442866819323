import React, {PureComponent} from "react";
import {
  getAsset,
  getClaimantStatus,
  getDirectory, getNameAddr,
  getTitle,
  numFormat,
  shortAddr,
  shortAddress,
  shortPool, formatDate, get_loc_wallets
} from "../../../modules";
import {Server} from "../../../modules/stellar";
import {ToastStore} from "react-toasts";
import moment from "moment";
import {Link} from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";
import LoadingIcon from "mdi-react/LoadingIcon";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import Effects from "../Account/components/Effects/Effects";
import Footer from "../../Layout/footer/Footer";
import PropTypes from "prop-types";
import {translate} from "react-i18next";
import {getClaimableBalance} from "../../../endpoints/StellarAPI";
import Operations from "../Account/components/Effects/Operations";
import ClaimableBalances from "./ClaimableBalances";


class ClaimableBalance extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      claimable_balance: false,
      not_found: false,
      operation: false,
      transaction: false,
      effects: [],
      // wallets: get_loc_wallets(),
      // is_my_address: false,
    };
  }


  componentDidMount() {

    const balance_id = this.props.match.params.id

    getTitle(`Claimable Balance: ${shortAddr(balance_id, 4)} | Stellar Explorer`);

    this.getClaimableBalance(balance_id)
  }



  getClaimableBalance = (balance_id) => {

    getClaimableBalance(balance_id).then( (result) => {

      this.setState({ loading: false })

      if (result.name && result.name === 'NotFoundError') {

        this.setState({ not_found: true, })
        return
      }

      this.setState({
        claimable_balance: result,
      })

      this.getOperation(result)
      this.getTransaction(result)
    })
      .catch(error => {
        console.log('getClaimableBalance error: ', error)
      })
  }


  getOperation = async (result) => {
    const operation = await result.operations()
    this.setState({ operation })
    this.getEffectsForOperation(operation)
  }

  getTransaction = async (result) => {
    const transaction = await result.transactions()
    this.setState({ transaction: transaction.records[0] })
  }

  getEffectsForOperation = async (operation) => {
    const effects = await operation.records[0].effects()
    this.setState({ effects: effects.records })
  }





  render() {

    // console.log('state: ', this.state)

    const { claimable_balance, operation, transaction, effects, not_found, loading, } = this.state;
    const tx = transaction


    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{textTransform: 'none'}}>Claimable Balance ID: <b className="text-info">{ shortAddr(this.props.match.params.id, 4) }</b> &emsp;
              <CopyToClipboard text={this.props.match.params.id} onCopy={() => {}}>
                <i className="fa fa-clone"
                   color="secondary"
                   style={{cursor: 'pointer', fontSize: 14}}
                   onClick={() => ToastStore.success('Copied!')}></i>
              </CopyToClipboard>

              <span style={{float: 'right'}}>
                <Link to={'/claimable-balances'}
                      style={{padding: '5px 14px'}}
                      className={`btn btn-secondary btn-sm`}><b><i className={'fa fa-search'}></i></b></Link>
                <Link to={'/create-claimable-balance'}
                      style={{padding: '5px 14px'}}
                      className={`btn btn-success btn-sm`}><b><i className={'fa fa-plus'}></i></b></Link>
              </span>
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Balance</h5>
                  {/*<h5 className="subhead">Operation ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                </div>

                { loading ? <div className="panel__refresh" style={{height: '75%'}}><LoadingIcon /></div> : null }

                {
                  not_found &&
                    <div>
                      <p><b>Not found...</b></p>
                      <p>Perhaps this balance has been claimed</p>
                    </div>
                }

                {
                  claimable_balance &&
                    <div>
                      <p>Claimant: {shortAddress(claimable_balance.claimants[claimable_balance.claimants.length-1].destination)}</p><br/>
                      {/*claimable_balance.claimants.length-1*/}
                      <ClaimableBalances
                        account={claimable_balance.claimants[claimable_balance.claimants.length-1].destination}
                        claimable_balances={[claimable_balance]}
                        path={'balance'}
                      />
                    </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        {
          !loading && !not_found &&
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Operation</h5>
                    {/*<h5 className="subhead">Operation ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                  </div>

                  {
                    operation && operation.records.length ?
                      <Operations items={operation.records} />
                    :
                      <div className="panel__refresh" style={{height: '75%'}}><LoadingIcon /></div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        }



        {
          !loading && !not_found && operation && operation.records.length &&
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Effects for Operation
                      <b className={'float-right'}>(<span className={'text-info'}>{effects.length}</span>)</b>
                    </h5>
                    {/*<h5 className="subhead">Operation ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                  </div>

                  {
                    effects.length ?
                      <Effects items={effects} />
                    :
                      <div className="panel__refresh" style={{height: '75%'}}><LoadingIcon /></div>
                  }

                </CardBody>

              </Card>
            </Col>
          </Row>
        }

        {
          !loading && !not_found &&
          <Row>
            <Col md={12} lg={12}>
              <Card>
                <CardBody>

                  <div className="card__title">
                    <h5 className="bold-text">Transaction</h5>
                    {
                      tx && <h5 className="subhead">Transaction ID: <Link to={`/transaction/${tx.id}`}><b>{shortAddr(tx.id, 6)}</b></Link></h5>
                    }
                  </div>

                    {
                      transaction ?
                        <Row>
                          <Col md={6} lg={6}>
                            <p>Source account: {shortAddress(tx.source_account)} {' '}
                              <CopyToClipboard text={tx.source_account} onCopy={() => {}}>
                                <i className="fa fa-clone"
                                   color="secondary"
                                   style={{cursor: 'pointer', fontSize: 14}}
                                   onClick={() => ToastStore.success('Copied!')}></i>
                              </CopyToClipboard>
                            </p>
                            <p>Memo type: <b className="text-info">{ tx.memo_type } </b></p>
                            <p>Memo: <b className="text-info">{ tx.memo_type === "none" ? "none" :  tx.memo }</b></p>
                            <p>Ledger: <b className="text-info">{ tx.ledger_attr } </b></p>
                          </Col>
                          <Col md={6} lg={6}>
                            <p>Created at: <b className="text-info">{ tx ? formatDate(tx.created_at) : '...'} </b></p>
                            <p>Operations: <b className="text-info"><Link to={`/transaction/${tx.id}`}>{ tx.operation_count }</Link> </b></p>
                            <p>Fee paid: <b className="text-info">{ numFormat(tx.fee_charged / 10000000, 7) } <Link to="/XLM-native" className="text-warning">XLM</Link> </b> </p>
                            <p>Sequence: <b className="text-info">{ tx.source_account_sequence } </b></p>
                          </Col>
                          <Col md={12} lg={12}>
                            <br/>
                            <p><b>Signatures ({tx.signatures ? tx.signatures.length : '...'}):</b></p>

                            { tx ? tx.signatures.map((item, index) => <p key={index}><b>{index+1}.</b> {item}</p>) : '' }
                          </Col>
                        </Row>
                      :
                        <div className="panel__refresh" style={{height: '75%'}}><LoadingIcon /></div>
                    }
                </CardBody>
              </Card>
            </Col>
          </Row>
        }


        <Footer />

      </Container>
    );
  }
}


ClaimableBalance.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(ClaimableBalance);
