import React, { PureComponent } from 'react';
import {
  Col, Card, Row, CardBody,
} from 'reactstrap';
import { ToastContainer, ToastStore } from 'react-toasts';
import PropTypes from 'prop-types';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import WizardFormThree from './WizardFormThree';
import WizardFormFour from './WizardFormFour';
import WizardFormFive from './WizardFormFive';

export default class WizardForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
    };
  }

  nextPage = (values) => {

    this.setState(prevState => ({ page: prevState.page + 1, values }));
  };

  previousPage = () => {
    this.setState(prevState => ({ page: prevState.page - 1 }));
  };


  // setNextPage() {
  //   this.setState(prevState => ({ page: prevState.page + 1 }));
  // }


  render() {

    const { onSubmit } = this.props;
    const { page } = this.state;

    return (
      <Row>
        <Col md={12} lg={12}>
          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

            <div className="wizard">
              <div className="wizard__steps">
                <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>1</p></div>
                <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>2</p></div>
                <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}><p>3</p></div>
                {/*<div className={`wizard__step${page === 5 ? ' wizard__step--active' : ''}`}><p>Step 5</p></div>*/}
              </div>
              <div className="">
                {page === 1 &&
                  <WizardFormOne
                    onSubmit={this.nextPage} />}

                {/*{page === 2*/}
                {/*&& (*/}
                {/*  <WizardFormTwo*/}
                {/*    previousPage={this.previousPage}*/}
                {/*    onSubmit={this.nextPage}*/}
                {/*/>*/}
                {/*)}*/}

                {page === 2
                && (
                  <WizardFormThree
                    previousPage={this.previousPage}
                    onSubmit={this.nextPage}
                    name_wallet={this.state.values.name_wallet}
                    pass={this.state.values.password1}
                />
                )}
                {page === 3
                && (
                  // <WizardFormFour
                  //   previousPage={this.previousPage}
                  //   pass={this.state.values.password1}
                  //   phrase={this.state.values.phrase}
                  //   name_wallet={this.state.values.name_wallet}
                  //   onSubmit={this.nextPage}
                  // />
                  <WizardFormFive
                    previousPage={this.previousPage}
                    name_wallet={this.state.values.name_wallet}
                    public_key={this.state.values.public_key}
                    private_key={this.state.values.private_key}
                    onSubmit={onSubmit}
                  />
                )}
                {/*{ page === 4*/}
                {/*&& (*/}
                {/*  <WizardFormFive*/}
                {/*    previousPage={this.previousPage}*/}
                {/*    name_wallet={this.state.values.name_wallet}*/}
                {/*    public_key={this.state.values.public_key}*/}
                {/*    private_key={this.state.values.private_key}*/}
                {/*    onSubmit={onSubmit}*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            </div>
        </Col>
      </Row>
    );
  }
}
