import React from 'react';
import {Col, Row, Card, CardBody, Button} from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {getAsset, getSCOPAsset, numFormat, shortAddress} from "../../../modules";
import {ToastStore} from "react-toasts";
import {CopyToClipboard} from "react-copy-to-clipboard";


const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo-gray.png`;
const android_icon = `${process.env.PUBLIC_URL}/img/icons/google-store-logo.svg`;
const appstore_icon = `${process.env.PUBLIC_URL}/img/icons/app-store-logo.svg`;
const mac_icon = `${process.env.PUBLIC_URL}/img/icons/mac-store-logo.png`;
const windows_icon = `${process.env.PUBLIC_URL}/img/icons/windows-store-logo.png`;
const bomb = `${process.env.PUBLIC_URL}/img/banners/bomb.png`;

const date = new Date();
const show_banner = false;

const style = {
  title: {fontSize: 22, marginBottom: '5px'},
  features: {fontSize: 24, marginBottom: '5px'}, // color: '#c567ff',
  button: {margin: '7px 10px 10px'},
  ieo: {
    // position: 'absolute', right: '-126px', top: 0, color: '#fdb271'
    float: 'right'
  },
  scop: {color: 'orange'},
  img: {width: '7%', marginTop: '-2px', opacity: 0.5},
  bold: {color: '#71bbfd'}
}

const scop = getSCOPAsset()

const Banner = () => {

  return (
    <Row className="wr-row">
      <Col md={12} className="trade-col right-last">
        <Card>
          <CardBody className={'text-center'} style={{padding: 10}}>
            {/*<a href="/launch/scopuly?ref=scopuly">*/}
            {/*  <img src="https://coincodex.com/823kfxez4m/2022_05_06_scopuly/3b179eedfe091077be0dece921896fbf.jpg"*/}
            {/*       style={{width: '66%'}}*/}
            {/*       alt="scopuly ido"/>*/}
            {/*</a>*/}


            <div
              style={{
                backgroundImage: 'url(/img/banners/banner-bg.png)',
                backgroundSize: 'cover',
                backgroundPosition: 'bottom'}}
            >


              <Row style={{padding: '13px 20px 10px'}}>
                <Col md={7} className={'text-center'}>
                  {/*<div style={style.title}><b>SCOP is a Scopuly Ecosistem token</b></div>*/}
                  <h3 className={'text-white'}>
                    <b>{getAsset(`${scop.code}:${scop.issuer}`, scop.image, 'white', 30)} Rewards Program. Get <span className={'text-success fs-24'}>+18%</span> APY.</b> {' '}
                    <Link to={'/rewards'}
                          outline
                          className={'btn btn-outline-success'}
                          style={style.button}><b><i className="fa fa-rocket"></i> Start Earning</b>
                    </Link> {' '}
                    <Link to={'/rewards'}
                          className={'text-white fs-16'}><small><i className="fa fa-calculator"></i> %</small></Link>
                  </h3>

                </Col>

                <Col md={5} className={'mt-6'}>
                  {/*<div>*/}
                  {/*  <div style={style.features}>*/}
                  {/*    <b style={style.scop}>*/}
                  {/*      <Link to={'/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native'}>SCOP</Link>*/}
                  {/*    </b> features:*/}
                  {/*    <div style={style.ieo}><img style={style.img} src={bomb} alt="scopuly ieo"/> Listing on exchanges soon</div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <Row className={'text-white'}>
                    {/*<Col md={4}>*/}
                    {/*  <div>* Access to the <b style={style.bold}>crypto card</b> service</div>*/}
                    {/*  <div>* Access to the <b style={style.bold}>staking</b> service</div>*/}
                    {/*</Col>*/}
                    <Col md={6}>
                      <div><b>Holder Rewards: <b className={'text-success'}>+10%</b> APY</b></div>
                      <div><b>Liquidity Rewards: <b className={'text-success'}>+18%</b> APY</b></div>
                    </Col>
                    <Col md={6}>
                      <div><b>Referral Program: up to <b className={'text-success'}>+20%</b> VOL</b></div>
                      <div><b>Staking: <b className={'text-success'}>+35%</b> APR</b> <small className={'text-secondary'}>(soon)</small></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}



const Footer = (props) => {

  // console.log('props: ', props)

  const donate_addr = 'GBHLRDX2U5N5UGB5CVDFY3DB2YMGXCCCIXRI4Q7R3YC5VVE4VCYS3OYM'

  return (

    <div>
      {
        show_banner && !props.hide ?
          <Banner />
        : null
      }

      <Row className="wr-row">
        <Col md={12} className="trade-col right-last">
          <div className="footer-card text-secondary">
            <Card>
              <CardBody>
                <Row >
                  <Col md={5} className="footer-back">
                    <h4>
                      <img src={stellar_logo} className="stellar-logo-sm" />  Stellar Wallet | DEX | DeFi {' '}
                      {/*<br/>*/}
                      {/*<small style={{fontSize: '11px'}} className="text-secondary">*/}
                      {/*  <span className={'label-footer'}>Non-custodial wallet</span>*/}
                      {/*</small>*/}
                    </h4>

                    <p><small><b>Your Keys. You Crypto.</b></small></p>
                    <p><small>Manage money in a new way.</small></p>
                    <p><small>Stellar is an open network for storing, moving, and converting money.</small></p>
                    {/*<p><small>Scopuly is the perfect way to buy, store, invest, trade, pay, and transfer crypto assets.</small></p>*/}
                    <p><small>Scopuly allows you to make payments worldwide in any digital currency, <br/>
                      trade digital assets on SDEX, issue tokens and manage your Stellar account.<br/>
                      No fees, cross-border, decentralized.
                    </small></p>
                    <p><small>Only <b>you control</b> your funds.</small></p>


                    {/*<p><small>Scopuly is a Wallet, SDEX, IEO and Block Explorer for Stellar Network. Stellar is an open network for storing and moving money. Scopuly is the perfect way to buy, decentrally trade, pay and transfer crypto and invest in promising startups.</small></p>*/}
                    {/*<p><small>Scopuly does not store the user's secret keys and, accordingly, does not have access to wallets recovery. Scopuly is not responsible for the safety of the user's secret keys.</small></p>*/}
                    {/*<br/>*/}
                    {/*<div>*/}
                    {/*  <small><b>Donations</b>: {shortAddress(donate_addr)} {' '}*/}
                    {/*    <CopyToClipboard text={donate_addr} onCopy={() => {}}>*/}
                    {/*      <i className="fa fa-clone"*/}
                    {/*         color="secondary"*/}
                    {/*         style={{cursor: 'pointer'}}*/}
                    {/*         onClick={() => ToastStore.success('Copied!')}></i>*/}
                    {/*    </CopyToClipboard>*/}
                    {/*  </small>*/}
                    {/*</div>*/}
                  </Col>


                  <Col md={2} className="mt-15">
                    <div className="footer-lable"><b>Services</b></div>
                    <small>
                      <span><a href="/wallet" className="text-secondary">Stellar Wallet</a></span><br/>
                      {/*<span><Link to="/assets" className="text-secondary">Assets</Link></span><br/>*/}
                      {/*<span><Link to="/markets" className="text-secondary">Markets</Link></span><br/>*/}
                      <span><Link to="/trade" className="text-secondary">Trade</Link></span><br/>
                      <span><Link to="/swap" className="text-secondary">Swap Assets</Link></span><br/>
                      <span><Link to="/pools" className="text-secondary">Liquidity Pools</Link></span><br/>
                      <span><Link to="/payments" className="text-secondary">Payments</Link></span><br/>
                      <span><Link to="/receive" className="text-secondary">QR Payments</Link></span><br/>
                      <span><Link to="/mass-payments" className="text-secondary">Mass Payments</Link></span><br/>
                      <span><Link to="/create-claimable-balance" className="text-secondary">Claimable Balance</Link></span><br/>
                      <span><Link to="/exchange" className="text-secondary">Buy/Sell Crypto</Link></span><br/>
                      <span><Link to="/create-token" className="text-secondary">Create Token</Link></span><br/>
                      {/*<span><Link to="#" className="text-secondary">Launchpad</Link></span><br/>*/}
                      <span><Link to="/explorer" className="text-secondary">Ledger Explorer</Link></span><br/>
                      <span><Link to="/rewards" className="text-secondary">Rewards</Link></span>
                    </small>
                  </Col>




                  <Col md={2} className="mt-15">
                    <div className="footer-lable"><b>Company</b></div>
                    <small>
                    <span><a href="https://about.scopuly.com/"
                             className="text-secondary"
                             target='_blank'
                             rel='noopener noreferrer'><i className="fa"></i>About Scopuly</a></span><br/>

                      <span><a href="/support"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Help & Support</a></span><br/>

                      <span><a href="https://scopuly.com/docs/whitepaper.pdf"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Whitepaper</a></span><br/>

                      <span><a href="https://scopuly.com/docs/onepager.pdf"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Onepager</a></span><br/>

                      <span><a href="https://scop.scopuly.com/"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>SCOP</a></span><br/>

                      {/*<span><a href="/referral"*/}
                      {/*         className="text-secondary"*/}
                      {/*         target='_blank'*/}
                      {/*         rel='noopener noreferrer'><i className="fa"></i>Referral Program</a></span><br/>*/}

                      <span><a href="https://about.scopuly.com/brand.html"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Brand Page</a></span><br/>

                      <span><a href="https://about.scopuly.com/terms.html"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Terms of Service</a></span><br/>

                      <span><a href="https://about.scopuly.com/privacy.html"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa"></i>Privacy Policy</a></span><br/>

                    </small>
                  </Col>

                  <Col md={2} className="mt-15">
                    <div className="footer-lable"><b>Community</b></div>
                    <small>

                      <span><a href="https://twitter.com/scopuly"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-twitter"></i> Twitter</a></span><br/>

                      <span><a href="https://t.me/scopuly"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-telegram"></i> Telegram</a></span><br/>

                      <span><a href="https://scopuly.medium.com"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-medium"></i> Medium</a></span><br/>

                      <span><a href="https://www.youtube.com/scopuly"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-youtube"></i> YouTube</a></span><br/>

                      <span><a href="https://coinmarketcap.com/community/profile/Scopuly/"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'>
                        <img src="/img/icons/cmc.png"
                             alt="CoinMarketCap"
                             style={{
                               width: '13px',
                               filter: 'grayscale(100%)',
                               margin: '0px -1px 0px -2px',
                               opacity: 0.7
                             }}
                        /> CoinMarketCap</a></span><br/>

                      {/*<span><a href="https://galactictalk.org/u/Scopuly"*/}
                      {/*         className="text-secondary"*/}
                      {/*         target='_blank'*/}
                      {/*         rel='noopener noreferrer'>*/}
                      {/*  <img src="/img/logo/galactic.png"*/}
                      {/*   alt="galactic talk"*/}
                      {/*   style={{*/}
                      {/*     width: '16px',*/}
                      {/*     filter: 'grayscale(100%)',*/}
                      {/*     margin: '0 -2px 0 -3px'}}/> GalacticTalk</a></span><br/>*/}


                      {/*<span><a href="https://www.facebook.com/scopuly/"*/}
                      {/*         className="text-secondary"*/}
                      {/*         target='_blank'*/}
                      {/*         rel='noopener noreferrer'><i className="fa fa-facebook"></i> Facebook</a></span><br/>*/}

                      <span><a href="https://reddit.com/r/Scopuly"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-reddit"></i> Reddit</a></span><br/>
                      <span><a href="https://github.com/Scopuly"
                               className="text-secondary"
                               target='_blank'
                               rel='noopener noreferrer'><i className="fa fa-github"></i> GitHub</a></span>
                    </small>
                  </Col>


                  <Col md={1} className="mt-15" >
                    <div className="footer-lable"><b>Apps</b></div>
                    <small>


                 <span className="icon-apps"><a href="https://play.google.com/store/apps/details?id=com.sdex.app"
                                                className="text-secondary"
                                                target='_blank'
                                                rel='noopener noreferrer'><img src={android_icon} alt='Android | Scopuly | Stellar Wallet'/></a></span><br/>

                      <span className="icon-apps"><a href="https://apps.apple.com/us/app/id1383402218?platform=iphone"
                                                     className="text-secondary"
                                                     target='_blank'
                                                     rel='noopener noreferrer'><img src={appstore_icon} alt='iPhone | Scopuly | Stellar Wallet'/></a></span><br/>


                      <span className="icon-apps"><a href="https://github.com/Scopuly/scopuly/releases/download/v2.0.4/Scopuly-macOS.dmg"
                                                     className="text-secondary"
                      ><img src={mac_icon} alt='MacOS | Scopuly | Stellar Wallet'/></a></span><br/>


                      {/*<span className="icon-apps"><a href="https://github.com/Scopuly/scopuly/releases"*/}
                      {/*                               className="text-secondary"*/}
                      {/*                               target='_blank'*/}
                      {/*                               rel='noopener noreferrer'*/}
                      {/*                               ><img src={windows_icon} alt='Windows | Scopuly | Stellar Wallet'/></a></span><br/>*/}


                      {/*<span><a href="https://github.com/Scopuly/scopuly/releases/download/v2.0.4/Scopuly-macOS.dmg"*/}
                      {/*         className="text-secondary"*/}
                      {/*         // target='_blank'*/}
                      {/*         rel='noopener noreferrer'><i className="fa fa-apple"></i> MacOS</a></span><br/>*/}

                      {/*<span><a href="https://github.com/Scopuly/scopuly/releases"*/}
                      {/*         className="text-secondary"*/}
                      {/*         target='_blank'*/}
                      {/*         rel='noopener noreferrer'><i className="fa fa-windows"></i> Windows</a></span><br/>*/}

                      {/*<span><a href="https://github.com/Scopuly"*/}
                      {/*         className="text-secondary"*/}
                      {/*         target='_blank'*/}
                      {/*         rel='noopener noreferrer'><i className="fa fa-github"></i> GitHub</a></span><br/>*/}
                    </small>
                  </Col>

                  {/*<Col md={12}>
                <small>
                  <br/>
                  <font>info@scopuly.com</font>
                </small>
              </Col>*/}

                  <Col md={12}>
                    <small>
                      <br/>
                      <span className="text-secondary">
                     <span style={{fontFamily: 'Joy Kim'}}>Scopuly</span> @ {date.getFullYear()}
                    </span>
                      <span className="float-right">
                  <a href="mailto:info@scopuly.com" className="text-secondary" style={{marginRight: '20px'}}>info@scopuly.com</a>
                    <a href="/docs/PRIVACY_EN.pdf" className="text-secondary" style={{marginRight: '20px'}}>Privacy Policy</a>
                    <a href="/docs/TERMS_EN.pdf" className="text-secondary">Terms of Service</a>
                  </span>
                    </small>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}


export default Footer;
