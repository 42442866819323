import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import {
  get_loc_wallets,
  shortAddr,
  getSigner,
  showErrorMessage,
  getStellarFee,
  numFormat,
  getFloatValue,
  baseReserve,
  minBalance,
  balancesFormat,
  getIconConnectProvider, checkAuth,
} from '../../../../modules/index';
import swal from "sweetalert";
import MenuItem from "@material-ui/core/MenuItem";
import {Link, Redirect} from "react-router-dom";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getRelativeAssets,} from "../../../../endpoints/API";
import {getAccount} from "../../../../endpoints/StellarAPI";
import iconIdent from "../../Account/components/iconIdent";



const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type, disabled
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    style={{width: '100%'}}
  />
);



class Pay extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      hash: false,
      loader: false,
      addresses: [],
      assets: [],
      asset: false,
      select_address: false,
      filter_type: 'payment',
      amount: 0,
      is_auth: false,
      memo: false,
    };
  }

  scop_asset = new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ')
  fee = getStellarFee()

  componentDidMount() {
    //
  }


  submitForm = (value) => {

    const { filter_type, select_address, asset, addresses, amount } = this.state

    console.log('asset: ', asset)
    console.log('value: ', value)

    this.setState({ hash: false })

    // const addresses = value.addresses.split('\n')
    // const address = value.select_address
    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
    const send_asset = asset.asset_type === 'native' ?
      StellarSdk.Asset.native() :
      new StellarSdk.Asset(asset.asset_code, asset.asset_issuer);

    console.log('send_asset: ', send_asset)


    let ops = []

    addresses.forEach((item, index) => {

      console.log('item: ', item)


      if (filter_type === 'payment') {

        ops.push(StellarSdk.Operation.payment({
          destination: item,
          asset: send_asset,
          amount: String(amount),
        }))
      }

      if (filter_type === 'claimable') {

        const claimants = [
          new StellarSdk.Claimant(item, StellarSdk.Claimant.predicateUnconditional()),
          new StellarSdk.Claimant(select_address, StellarSdk.Claimant.predicateUnconditional())
        ]

        ops.push(StellarSdk.Operation.createClaimableBalance({
          claimants: claimants,
          asset: send_asset,
          amount: String(amount),
        }))
      }
    })

    swal({
      title: `Confirm`,
      text: 'Do you want to send a payments right now?',
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.payouts(ops, select_address, signer)
        }
      })
  }


  payouts = (ops, address, signer) => {

    const memo = this.state.memo

    this.setState({ loader: true })

    Server.loadAccount(address)
      .then((sourceAccount) => {
        // console.log('sourceAccount: ', sourceAccount)

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: this.fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        ops.forEach(op => tx.addOperation(op));
        tx = tx.addMemo(StellarSdk.Memo.text(memo ? memo : ''))
        tx = tx.setTimeout(0).build();
        tx.sign(signer);

        return Server.submitTransaction(tx);
      })
      .then((result) => {
        // console.log('result tx: ', result)

        if (result.hash) {

          this.setState({
            loader: false,
            hash: result.hash,
          })

          swal({
            title: "Done!",
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        showErrorMessage(error, 'payment')

        this.setState({ loader: false })

        setTimeout(() => {
          // this.payouts(ops, address, signer)
        }, 100)
      });

  }

  reset = (reset) => {
    reset()
  }

  changeInput = (value) => {
    const addresses = value.target.value.split('\n')
    this.setState({ addresses })
  }


  changeAccount = (value) => {

    const select_address = getFloatValue(value);

    this.setState({
      select_address,
      hash: false,
      loader: true,
    })

    getAccount(select_address).then(account => {
      console.log('account: ', account)

      const balances = account.balances.reverse();

      balances.forEach((item, index) => {

        if (item.asset_type === 'native') {

          item.asset_code = 'XLM';
          item.asset_issuer = 'native';
          item.reserve = (account.subentry_count * baseReserve() + minBalance());
          item.avaliable = (item.balance - item.reserve).toFixed(7);

          this.setState({
            balance_native: item.balance,
            loader: false,
          })
        }
        else {
          item.avaliable = item.balance;
        }
      })

      const query = balancesFormat(balances)

      getRelativeAssets(query.pools, query.codes, query.issuers).then(result => {
        console.log('getRelativeAssets result: ', result)

        if (result) {
          const assets = result.data.assets

          balances.map((item, index) => {

            if (item.asset_type !== 'liquidity_pool_shares') {

              if (item.asset_type === 'native') {
                item.image = '/img/logo/stellar-logo.png'
              }
              else {
                const asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

                if (asset) {
                  item.image = asset.image
                  item.asset = asset
                } else {
                  item.image = '/img/digitalcoin.png'
                }
              }

              return true
            }
          })

          this.setState({
            assets: balances,
          })
        }
        else {

          this.setState({
            assets: balances,
          })
        }
      })
    })
      .catch(error => {
        console.log('error: ', error)
      })
  }


  changeAsset = (value) => {

    const { assets, } = this.state

    const part = getFloatValue(value).split('-');
    const asset = assets.filter(item => item.asset_code === part[0] && item.asset_issuer === part[1])[0]

    this.setState({
      select_asset_code: part[0],
      select_asset: part,
      asset,
    });
  }

  changeAmount = (value) => {
    this.setState({ amount: getFloatValue(value) });
  }

  changeMemo = (value) => {
    this.setState({ memo: getFloatValue(value) })
  }


  filterWatchlist = (type) => {
    this.setState({
      filter_type: type,
    })
  }

  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }



  render() {

    console.log('state: ', this.state)

    const { handleSubmit, reset } = this.props
    const { wallets, hash, loader, addresses, assets, select_address, filter_type, amount, asset, is_auth, memo } = this.state

    if (is_auth) {
      return <Redirect to='/auth' />
    }


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />


        <Row>

          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text"><span className="lnr lnr-location"></span>&nbsp; Mass Payments</h5>
                  <h5 className="subhead">Send bulk payments of the same type by specifying an array of recipients</h5>
                </div>

                { loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }

                <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                  <div onClick={this.checkAuthAddr}>
                    <Field
                      style={{fontSize: 12}}
                      name="select_address"
                      component={renderTextField}
                      select
                      label="Select Account"
                      onChange={this.changeAccount}
                    >
                      {
                        wallets ?
                          wallets.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                style={{fontSize: '12px'}}
                                className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                                value={item.pk}>
                                <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                              </MenuItem>
                              // <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                            );
                          })
                          : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="select_asset"
                      component={renderTextField}
                      select
                      label="Select Asset"
                      onChange={this.changeAsset}
                    >
                      {
                        assets.length ? assets.map((item, index) => {

                          if (item.asset_type !== 'liquidity_pool_shares') {
                            return (
                              <MenuItem
                                key={index}
                                className="material-form__option"
                                value={`${item.asset_code}-${item.asset_issuer}`}
                              >
                                { item.image ? <img src={item.image} alt="" className={'asset-icon-sm'} /> : null} &nbsp;
                                <b className="text-secondary">{item.asset_code}</b> &nbsp; <span className={'text-secondary'}>({item.asset_code === 'XLM' ? numFormat(item.avaliable, 7) : numFormat(item.balance, 7)})</span>
                              </MenuItem>
                            )
                          }
                        }) : null
                      }

                    </Field>
                  </div>


                  <div>
                    <Field
                      name="amount"
                      type="text"
                      label="Amount"
                      component={renderTextField}
                      onChange={this.changeAmount}
                    />
                  </div>

                  <div>
                    <Field
                      name="memo"
                      component={renderTextField}
                      label="Memo (optional)"
                      onChange={this.changeMemo}
                    />
                  </div>


                  <div className={'form'}>

                    <div className="form__form-group">
                      <span className="form__form-group-label" style={{fontSize: 12}}>Addresses { addresses ? <font>(<b className={'text-info'}>{addresses.length}</b>/100)</font> : null }</span>
                      <div className="form__form-group-field">
                        <Field
                          name="addresses"
                          component="textarea"
                          type="text"
                          placeholder="Each address is on a new line"
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>

                  </div>

                  <div>
                    <span className="form__form-group-label" style={{fontSize: 12}}>Operation type</span>
                    <ButtonToolbar style={{marginTop: 4}}>
                      <ButtonGroup className="btn-group--icons">
                        <Button onClick={() => this.filterWatchlist('payment')}
                                color={filter_type === 'payment' ? 'primary' : 'secondary'}> Payment </Button>
                        <Button onClick={() => this.filterWatchlist('claimable')}
                                color={filter_type === 'claimable' ? 'primary' : 'secondary'}> Claimable Balance </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>

                  <br/><br/>

                  <div>
                    {/*<ButtonToolbar className="form__button-toolbar float-right">*/}
                    {/*  <Button type="button" onClick={() => this.reset(reset)}>Reset</Button>*/}
                    {/*  <Button color="primary" type="submit">Pay</Button>*/}
                    {/*</ButtonToolbar>*/}
                    {
                      wallets ?
                        <Button
                          type="submit"
                          color="primary"
                          size={'lg'}
                          style={{width: '100%'}}>
                          Send { amount ? <b>{numFormat(amount, 7)} {asset.asset_code}</b> : null }
                        </Button>
                        :
                        <Link to={'/auth'}
                              className={'btn btn-secondary btn-lg'}
                              style={{width: '100%'}}>Connect Wallet</Link>
                    }
                  </div>
                </form>

                {
                  hash ?
                    <div>
                      <br/><br/><br/>
                      <span>Hash: <Link to={`/transaction/${hash}`}><b>{shortAddr(hash, 4)}</b></Link></span>
                    </div>
                  : null
                }
              </CardBody>
            </Card>

          </Col>
        </Row>



        {/*<Card>*/}
        {/*  <CardBody>*/}

        {/*    { loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }*/}

        {/*    <Row>*/}
        {/*      <Col md={{ size: 6, offset: 2 }} >*/}
        {/*        <br/>*/}
        {/*        */}

        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </CardBody>*/}
        {/*</Card>*/}

      </Container>
    );
  }
}



export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(translate('common')(Pay));
