import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import ExchangeForm from './components/ExchangeForm';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { numFormat, getTitle } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import classnames from 'classnames';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import AssetChart from '../Asset/components/AssetChart';
import Orderbook from '../Asset/components/Orderbook';
import Trades from '../Account/components/Effects/Trades';
import Offers from '../Account/components/Effects/Offers';
import Footer from '../../Layout/footer/Footer';



class Exchange extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      loader: false,
      asset: [],
      trade_agregation: [],
      load_trade_agregation: false,
      trades: [],
      orderbook: [],
      direct_ex: false,
      exchange_pairs: localStorage.getItem('pairs') ? JSON.parse(localStorage.getItem('pairs')).reverse() : false,
      address: '',

      load_orderbook: false,

      trades_pair: [],
      load_trades_pair: false,
      get_trades: false,

      offers: [],
      load_offers: false,
    };

    this.directExchange = this.directExchange.bind(this);
    this.selectAsset = this.selectAsset.bind(this);
    this.getOrderbook = this.getOrderbook.bind(this);
    this.getShortOrderbook = this.getShortOrderbook.bind(this);
    this.getTradesPair = this.getTradesPair.bind(this);
    this.getOffers = this.getOffers.bind(this);
  }

  componentDidMount() {

    const title = 'Decentralised Asset Exchange (SDEX)';
    const description = 'Trade on SDEX, the global digital asset market. Without registration, without intermediaries, without minimum deposits, without commissions, at a speed of 3-5 sec / tx.';
    getTitle(title, description);
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    switch(tab) {

      case '2':
        // this.getTradesPair();
        break

      default:
        break
    }
  };


  directExchange(value){

    this.setState({
      trade_agregation: value.trade_agregation,
      load_trade_agregation: true,
      buy_asset: value.buy_asset,
      sell_asset: value.sell_asset,
      loader: false,
      address: value.address,
      asset_db: [],
      load_asset_db: false,
    })
  }



  // getAssetDataFromDB() {

  //   axios.get(`${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${this.state.asset_url.asset_code}&
  //                                                       asset_issuer=${this.state.asset_url.asset_issuer}`)
  //     .then((response) => {

  //       console.log('response', response);

  //       if (response.data.length) {
  //         this.setState({
  //           asset_db: response.data[0],
  //           oad_asset_db: true,
  //         })
  //       }

  //       // this.setState({
  //       //   load_asset_db: true,
  //       // })
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //   });
  // }



  selectAsset(value) {

    if (value.sell_asset.asset_code !== '' && value.buy_asset.asset_code !== '') {
      this.setState({
        loader: true,
      })
    }

    this.setState({
      load_trade_agregation: false,
    })
  }


  getOrderbook(value) {

    this.setState({
      orderbook: value,
      load_orderbook: true,
    })
  }

  getShortOrderbook(value) {

    this.setState({
      orderbook_short: value,
      load_orderbook_short: true,
    })
  }


  getTradesPair(value) {

    this.setState({
      trades_pair: value,
      load_trades_pair: true,
      get_trades: true,
    })
  }


  getOffers(value) {

    this.setState({
      offers: value,
      load_offers: true,
    })
  }


  renderPair(item, index) {
    return (
      <a key={index} href={`/exchange/${item.sell_asset.asset_code}-${item.sell_asset.asset_issuer}:${item.buy_asset.asset_code}-${item.buy_asset.asset_issuer}:${item.address}`}
         className="ex_pair"><span className="text-warning"><b>{item.sell_asset.asset_code}/{item.buy_asset.asset_code}</b></span>
      </a>
    )
  }



  render() {

    const ex_direct_text = 'Select exchange direction';

    return (
      <Container className="dashboard">
        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Distributed Exchange</h3>
          </Col>
        </Row>*/}
        <Row>

          <Col md={4} lg={4}>

            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Select exchange direction</h5>
                  <h5 className="subhead">Direct P2P Exchange of Tokens that are on your Address</h5>
                </div>

                <ExchangeForm direct_exchange={this.directExchange}
                              select_asset={this.selectAsset}
                              getOrderbook={this.getOrderbook}
                              getShortOrderbook={this.getShortOrderbook}
                              trades={this.getTradesPair}
                              offers={this.getOffers} />

              </CardBody>
            </Card>

          </Col>


          <Col md={8} lg={8}>
            <Card>
              <CardBody style={{position: 'relative'}}>

                {/*<div className="card__title">
                  <h5 className="bold-text">gkhgkjhgkjhgkjh</h5>
                  <h5 className="subhead">Use default tabs with class
                    <span className="red-text"> tabs--bordered-bottom</span>
                  </h5>
                </div>*/}



                { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                {
                  this.state.load_trade_agregation && this.state.load_orderbook
                  ?
                    <AssetChart trade_agregation={this.state.trade_agregation}
                                orderbook={this.state.orderbook}
                                buy_asset={this.state.buy_asset}
                                sell_asset={this.state.sell_asset}
                                asset_db={false}
                                point={'exchange'} />
                  :
                    <p className="dir-ex-txt"><span className="lnr lnr-arrow-left" /> {ex_direct_text}</p>
                }

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>Orderbook</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          <b>Trades</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>My Offers</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          <b>My Exchange Pairs</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {
                          this.state.load_orderbook && this.state.orderbook_short ?
                            <Orderbook orderbook={this.state.orderbook}
                                       orderbook_short={this.state.orderbook_short}
                                       asset={this.state.orderbook.counter} />
                          :
                            <p><span className="lnr lnr-arrow-up" /> {ex_direct_text}</p>
                        }
                      </TabPane>

                      <TabPane tabId="2">

                        {/*{ !this.state.load_trades_pair ? <div className="panel__refresh"><LoadingIcon /></div> : ''}*/}
                        {
                          this.state.load_trades_pair ?
                            <Trades items={this.state.trades_pair} />
                          :
                            <p><span className="lnr lnr-arrow-up" /> {ex_direct_text}</p>
                        }

                      </TabPane>

                      <TabPane tabId="3">

                        {
                          this.state.load_offers ?
                            <Offers items={this.state.offers} />
                          :
                            <p><span className="lnr lnr-arrow-up" /> Select Address</p>
                        }

                      </TabPane>

                      <TabPane tabId="4">
                        <Row>
                          <Col md={12}>
                            <Card>
                              <CardBody>
                                <div className="card__title">
                                  <h5 className="bold-text">My exchange pairs</h5>
                                </div>
                                <p>
                                  {
                                    this.state.exchange_pairs ?
                                      this.state.exchange_pairs.map(this.renderPair)
                                    : null
                                  }
                                  {/*<a href="#" className="ex_pair"><span className="text-warning"><b>SKY/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/MOBI</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SLT/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>ETH/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/USD</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XRP/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>CNZ/DHT</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SCOP/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>EOS/BTH</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/BTC</b></span></a>*/}
                                </p>
                              </CardBody>
                            </Card>
                          </Col>

                          {/*<Col md={6} lg={6}>
                            <Card>
                              <CardBody>
                                <div className="card__title">
                                  <h5 className="bold-text">Recommended exchange pairs</h5>
                                </div>
                                <p>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>SKY/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/SKY</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/XLM</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>XLM/BTC</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>BTC/PULS</b></span></a>
                                  <a href="#" className="ex_pair"><span className="text-warning"><b>PULS/BTC</b></span></a>
                                </p>
                              </CardBody>
                            </Card>
                          </Col>*/}
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Exchange.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Exchange);
