import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const background = `${process.env.PUBLIC_URL}/img/landing/header_bg.png`;
const img = `${process.env.PUBLIC_URL}/img/landing/macbook_2.png`;
const osx = `${process.env.PUBLIC_URL}/img/landing/osx.png`;
const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo.png`;
const logo = `${process.env.PUBLIC_URL}/img/logo/500x200.png`

const Header = ({ onClick }) => (
  <div>

    <div className="landing__header" style={{ backgroundImage: `url(${background})` }}>
      <Container>
        <Row>
          <Col md={12}>
            {/*<img src={logo} style={{width: '43%'}} />*/}
            <h1>Stellar Wallet & SDEX</h1>
            <h2 className="landing__header-title" >
              Fast. <b>Safely. Decentralized. Cross-border.</b>
              {/*<b>Decentralized</b> Real-Time <b>Crypto Asset Trading</b> (SDEX), <b>Secure</b> Cryptocurrency <b>Wallet</b> and <b>Asset Tokenization</b>*/}
            </h2>
            <p className="landing__header-subhead">
              The easiest and safest way to store, pay, trade, invest and issue crypto assets. Built on{' '}
              <a className="landing__header-subhead-update" href="https://stellar.org" style={{textDecoration: 'none', whiteSpace: 'nowrap'}}>
                <img src={stellar_logo} style={{width: '3%'}}/>
                &nbsp;Stellar
              </a>.
            </p>
            <br/><br/>
            <Link className="landing__btn landing__btn--header" to="/auth/create">
              Create Account
            </Link>
            <Link className="landing__btn landing__btn--header landing__btn--gradient" to="/trade">
              Trade on SDEX
            </Link>

            <p className="landing__footer text-center">

              <a target='_blank'
                 rel='noopener noreferrer'
                 href="https://play.google.com/store/apps/details?id=com.sdex.app"
                 className="platforms-icon"><i className="fa fa-android"></i></a>

              <a target='_blank'
                 rel='noopener noreferrer'
                 href="https://itunes.apple.com/us/app/id1383402218"
                 className="platforms-icon"><i className="fa fa-apple"></i></a>

              <a target='_blank'
                 rel='noopener noreferrer'
                 href="https://github.com/Scopuly/scopuly/releases"
                 className="platforms-icon"><i className="fa fa-windows"></i></a>

              <Link to="/" className="platforms-icon"><i className="fa fa-chrome"></i></Link>

              {/*<a target='_blank'
               rel='noopener noreferrer'
               href="https://github.com/Scopuly/scopuly/releases"
               className="platforms-icon"><i className="fa fa-github"></i></a>*/}
            </p>

            <img className="landing__header-img" src={img} alt="macbook" />

          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

Header.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Header;
