import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Button,
  ButtonToolbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  Popover, PopoverBody
} from 'reactstrap';
import { Field, reduxForm, change} from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { GLOBE } from '../../../modules/globeVars/index';
import axios from 'axios';
import StellarSdk, { Transaction } from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { parseStellarUri, PayStellarUri } from '@stellarguard/stellar-uri';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import {
  shortAddr,
  numFormat,
  formatDate,
  get_loc_wallets,
  baseReserve,
  minBalance,
  getAuth,
  needDeauthAll,
  checkAuth,
  getTitle, getAsset, shortAddress,
  getIconConnectProvider, getFloatValue, balancesFormat
} from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import { QRCode } from 'react-qrcode-logo';
import iconIdent from "../Account/components/iconIdent";
import Collapse from "../../../shared/components/Collapse";
import {getRelativeAssets} from "../../../endpoints/API";


const wallets_arr = [];
const stellar_logo = '/img/logo/stellar-logo.png'


const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Receive extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      loader: false,
      assets: false,
      select_addr: false,
      select_asset: false,
      select_assets: [],
      paste_recepient_address: true,
      select_recepient_address: false,
      modal: false,
      uri: false,
      amount: 0,
      memo: false,
      msg: false,
      msg_show: false,
      msg_length: false,
      invoice_id: false,
      show_invoice: false,
      popoverOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }


  componentDidMount() {
    // this.filterAddress()
    // this.setState({ key: 'value' }, () => {
    //     console.log('State modified: ', this.state);
    // })

    const title = `Create Payment Request on Stellar`
    const description = 'You can create or pay such a QR crypto invoice using any assets on your balance sheet. Even if your balance does not contain the token required to pay for the payment request, you can still pay the bill with any of your tokens.'
    getTitle(title, description);
  }



  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }


  filterAddress = () => {

    const wallets = this.state.wallets;

    this.setState({ loader: true, });

    if (wallets) {
      wallets.forEach((item, index) => {
        this.getAccount(item, index);
      })
    }

    if (this.state.wallets) {
        setTimeout(() => {
          ToastStore.info(`Loading ${this.state.wallets.length} Accounts...`);
        }, 3000)
    }
    else {
        this.setState({
          loader: false
        })
    }
  }


  getAccount = (item, index) => {

    this.setState( { loader: true })

    Server.loadAccount(item)
      .then(account => {

        this.setState({
          select_addr: account.id,
          select_account: account,
          select_assets: account.balances,
          loader: false,
        }, () => {
          this.getRelativeAssets(account.balances)
        })

      })
      .catch((err) => {
        console.error(err);

        this.setState( { loader: false })
    })
  }


  // getPasteAccount = (account_id) => {
  //
  //   this.setState({ loader: true })
  //   ToastStore.info(`Loading Account...`);
  //
  //   Server.loadAccount(account_id)
  //     .then(account => {
  //
  //       const balances = account.balances.reverse().map(item => {
  //         // console.log('item: ', item)
  //
  //         if (item.asset_type === 'native') {
  //           item.asset_code = 'XLM';
  //           item.asset_issuer = 'native';
  //           item.reserve = (account.subentry_count * baseReserve() + minBalance());
  //           item.avaliable = (item.balance - item.reserve).toFixed(7);
  //         }
  //         return item;
  //       })
  //
  //       this.getRelativeAssets(balances)
  //
  //       this.setState({
  //         loader: false,
  //         select_assets: balances,
  //         load_select_assets: true,
  //         select_addr: account_id,
  //         show_invoice: false,
  //       })
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       this.setState({ loader: false })
  //
  //       // wallets_arr.push(item)
  //       //
  //       // if (this.state.wallets.length === wallets_arr.length) {
  //       //   newState();
  //       // }
  //     })
  //
  //   // const newState = () => {
  //   //   this.setState({
  //   //     select_addrs: wallets_arr,
  //   //     load_select_addrs: true,
  //   //     loader: false
  //   //   })
  //   // }
  // }



  changeSender = (value) => {

    let address = getFloatValue(value);
    this.getAccount(address)
  }


  getRelativeAssets = (balances) => {

    const query = balancesFormat(balances)

    getRelativeAssets(query.pools, query.codes, query.issuers).then(result => {

      if (result) {
        const assets = result.data.assets

        balances.forEach((item, index) => {

          if (item.asset_type !== 'liquidity_pool_shares') {

            if (item.asset_type === 'native') {
              item.image = stellar_logo
              item.asset_code = 'XLM'
              item.asset_issuer = 'native'
            }
            else {
              const asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

              if (asset) {
                item.image = asset.image
                item.asset = asset
              } else {
                item.image = '/img/digitalcoin.png'
              }
            }
          }
        })

        balances.reverse()

        this.setState({ select_assets: []},
          () => this.setState({ select_assets: balances, assets }))
      }
      else {

        this.setState({ select_assets: []},
          () => this.setState({ select_assets: balances }))
      }
    })
  }



  changeSelectAsset = (value) => {

    let select_asset = getFloatValue(value);
    let part = select_asset.split('-');

    if (part[1] === 'native') {
      part[2] = stellar_logo
    } else {
      part[2] = this.state.assets.filter(asset => asset.asset_code === part[0] && asset.asset_issuer === part[1])[0].image
    }

    this.setState({
      select_asset_code: part[0],
      select_asset: part,
    });
  }


  changeMsg(value) {
    let text = getFloatValue(value);

    const limit = 300;
    if (text.length <= limit) {
      this.setState({ msg_length: text.length })
    }
    else {
      ToastStore.warning('Maximum length 300 characters');
    }
  }



  changeMemo = (value) => {

    let memo = getFloatValue(value);

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }



  submitForm = (values) => {

    const { select_asset } = this.state


    const origin_domain = 'scopuly.com';
    const secret_key = 'SDPHNZINMTDMKVO5UQKAXAGUEEOAL6U3FDPBIKWDWJW5URECCDI3APJK';
    const public_key = 'GCTBATCNXTDRHDRBJ2PZREPN5GNCI5GVZUNF6CQ22LZVF2TIFXQ5V4XF';

    if (values.select_addr && values.amount && values.select_asset) {

      const asset_code = values.select_asset.split('-')[0];
      const asset_issuer = values.select_asset.split('-')[1];

      let uri = `web+stellar:pay?destination=${values.select_addr}&amount=${values.amount}&origin_domain=${origin_domain}`;
      const parse_uri = parseStellarUri(uri);

      if (asset_issuer !== 'native') {
        parse_uri.assetCode = asset_code;
        parse_uri.assetIssuer = asset_issuer;
      }
      if (values.memo) {
        parse_uri.memo = encodeURIComponent(values.memo);
        parse_uri.memoType = 'MEMO_TEXT';
      }
      if (values.msg) {
        parse_uri.msg = encodeURIComponent(values.msg);
      }

      parse_uri.addSignature(StellarSdk.Keypair.fromSecret(secret_key))
      const res_uri = parse_uri.toString();

      parse_uri.verifySignature().then(isVerified => {


        if (isVerified) {
          this.setState({
            uri: res_uri,
            amount: values.amount,
            memo: values.memo,
            msg: values.msg,
          },
          () => this.setInvoice(res_uri, values.select_addr))
        }
        else {
          // something is wrong, warn the user
        }
      });
    }
    else {
      ToastStore.warning('Fill in all the fields');
    }
  }


  setInvoice(uri, address) {

    this.setState({ loader: true })

    const formData = new FormData();
    formData.append('uri', uri)
    formData.append('address', address)

    axios.post(GLOBE.API_URL+`/create_qr_pay`, formData)
      .then(result => {

        this.setState({ loader: false })

        if (result.data) {
          this.setState({
              invoice_id: result.data,
              show_invoice: true,
            }, () => window.scrollTo({ top: 600, behavior: "smooth" }) // this.toggleModal()
          )
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        this.setState({ loader: false })
        ToastStore.error(`Server request error...`)
    })
  }


  addMsg(e) {
    e.preventDefault()
    this.setState({
      msg_show: !this.state.msg_show,
      msg: false,
    })

    this.props.change('msg', '')
  }



  selectTypeAddress = (type) => {

    if (type === 'paste') {
      this.setState({
        paste_recepient_address: true,
        select_recepient_address: false,
      })
    }
    if (type === 'select') {
      this.setState({
        paste_recepient_address: false,
        select_recepient_address: true,
      })

      // this.filterAddress()
    }
  }


  closeInvoice = () => {
    this.setState({ show_invoice: false })
  }


  togglePopover = () => {
    this.setState(prevState => ({
      popoverOpen: !prevState.popoverOpen
    }));
  }



  render() {

    // console.log('state: ', this.state)

    const {reset, handleSubmit } = this.props;
    const { load_select_addrs, loader, select_addr, wallets, invoice_id, select_asset, show_invoice, select_assets } = this.state

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Send Assets</h3>
          </Col>true
        </Row>*/}
        <Row>

          <Col md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }} className="align-self-center">
            <Card>
              <CardBody>

                { loader && !load_select_addrs ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null}

                <div className="card__title">
                  <h5 className="bold-text"><i className={'fa fa-qrcode'}></i> Create Payment Request {' '}
                    <i id="popover" onClick={this.togglePopover} className="fa fa-question-circle-o"></i> {' '}
                    <Popover
                      placement="top"
                      isOpen={this.state.popoverOpen}
                      target="popover"
                      trigger="legacy"
                      toggle={this.togglePopover}
                    >
                      <PopoverBody>
                        Create Payment Requests and receive crypto assets from your customers for goods, services, donations, for anything. <br/>
                        Supports {' '}
                        <a href="https://www.stellar.org/developers/guides/concepts/list-of-operations.html#path-payment-strict-send"
                           target='_blank' rel='noopener noreferrer'>Path Payment</a> and {' '}
                        <a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                           target='_blank' rel='noopener noreferrer'>SEP-0007</a>.
                      </PopoverBody>
                    </Popover>
                  </h5>
                  <h5 className="subhead">Accept QR code payments worldwide in any convenient currency in seconds, no fees.</h5>
                </div>

                <form
                  className="material-form"
                  onSubmit={handleSubmit(this.submitForm)}>

                  <div className="form__form-group">
                    <div className="form__form-group-field">

                      <Field
                        name="radio"
                        component={renderRadioButtonField}
                        label="Select Address"
                        radioValue="select"
                        defaultChecked
                        onChange={() => this.selectTypeAddress('select')}
                      />
                      <Field
                        name="radio"
                        component={renderRadioButtonField}
                        label="Paste Address"
                        radioValue="paste"
                        onChange={() => this.selectTypeAddress('paste')}
                      />

                    </div>

                  </div>

                  {
                    this.state.paste_recepient_address ?
                      <div>
                        <Field
                          name="select_addr"
                          component={renderTextField}
                          label="Paste Recipient Address"
                          onChange={this.changeSender}
                        />
                      </div>
                    : null
                  }

                  {
                    this.state.select_recepient_address ?
                       <div>
                         {
                           wallets ?
                             <Field
                               name="select_addr"
                               component={renderTextField}
                               select
                               label="Select Destination Address"
                               onChange={this.changeSender}
                             >
                               {
                                 wallets.map((item, index) => {
                                   return (
                                     <MenuItem
                                       key={index}
                                       className={`material-form__option fs-12 ${item && item.provider ?  'connect-provider' : ''}`}
                                       value={item.pk}>
                                       <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                                     </MenuItem>
                                   )
                                 })
                               }
                             </Field>
                             :
                             <Link to={'/auth'}
                                   className={'btn btn-secondary btn-sm fs-12'}
                                   style={{marginTop: -5}}
                             >Connect Wallet</Link>
                         }
                       </div>
                    : null
                  }

                  <div>
                    <Field
                      name="select_asset"
                      component={renderTextField}
                      select
                      label="Select Asset"
                      onChange={(e) => {
                        this.changeSelectAsset(e)
                      }}
                    >
                      {
                        select_assets.length ? select_assets.map((item, index) => {

                          if (item.asset_type !== 'liquidity_pool_shares') {
                            return (
                              <MenuItem
                                key={index}
                                className="material-form__option"
                                value={`${item.asset_code}-${item.asset_issuer}-${item.image ? item.image : null }`}
                              >
                                { item.image ? <img src={item.image} alt="" className={'asset-icon-sm'} /> : null} &nbsp;
                                <b className="text-secondary">{item.asset_code}</b> &nbsp; <span className={'text-secondary'}>({ numFormat(item.balance, 7) })</span>
                              </MenuItem>
                            )
                          }
                        }) : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="amount"
                      component={renderTextField}
                      placeholder="Name"
                      label="Amount"
                      onChange={(e) => {
                        // this.changeSelectAmount(e)
                      }}
                    />
                  </div>

                  <div>
                    <Field
                      name="memo"
                      component={renderTextField}
                      label={`Memo ${this.state.memo ? `(${this.state.memo.length}/28)` : '(optional)'}`}
                      onChange={this.changeMemo}
                    />
                  </div>

                  <br/>
                  <ButtonToolbar className="form__button-toolbar">
                    {/*<Button type="button" onClick={reset}>Reset</Button>*/}
                    <Button color="success"
                            type="submit"
                            size={'lg'}
                            style={{width: '100%'}}
                    ><b>Create Payment Request</b></Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>

            {
              show_invoice ?
                <Card>
                  <CardBody>

                    <div className="card__title">
                      <h5 className="bold-text">Payment Request (QR Code)</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeInvoice}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>


                    <div className="text-center">

                      <QRCode
                        value={this.state.uri}
                        size="350"
                        quietZone="30"
                        // fgColor={f7.colors[color]}
                        qrStyle="dots"
                        // logoOpacity="0.8"
                        logoImage={select_asset[2]}
                        removeQrCodeBehindLogo={true}
                        logoPadding={select_asset[1] === 'native' ? 0 : 2.5}
                        logoWidth={select_asset[1] === 'native' ? 39 : 38}
                        logoHeight={select_asset[1] === 'native' ? 39 : 38}

                        ecLevel="M"
                        eyeRadius={[
                          { // top/left eye
                            outer: [10, 10, 0, 10],
                            inner: [1, 1, 0, 1],
                          },
                          { // top/right eye
                            outer: [10, 10, 10, 0],
                            inner: [1, 1, 1, 0],
                          },
                          { // bottom/left
                            outer: [10, 0, 10, 10],
                            inner: [1, 0, 1, 1],
                          },
                        ]}
                        eyeColor={[
                          { // top/left eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                          { // top/right eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                          { // bottom/left eye
                            outer: '#217fe4',
                            inner: 'black',
                          },
                        ]}
                      />

                    </div>

                    <br/>
                    <div className="text-secondary" style={{marginLeft: 30}}>
                      <p>Recipient: { shortAddress(this.state.select_addr) }</p>
                      <p>Asset: { getAsset({asset_code: select_asset[0], asset_issuer: select_asset[1]}, select_asset[2]) }
                      </p>
                      <p>Amount: <b className="text-info">{numFormat(this.state.amount, 7)}</b></p>
                      {this.state.memo ? <p><small>Memo: <span className="text-info">{this.state.memo}</span></small></p> : null}
                      {this.state.msg ? <p>Message: <span className="text-info">{this.state.msg}</span></p> : null}
                      {/*<div><small>{formatDate(new Date())}</small></div>*/}
                    </div>
                    <br/><br/>

                    <div>
                      <CopyToClipboard text={`${window.location.origin}/pay/${invoice_id}`} onCopy={() => {}}>
                        <Button color="success"
                                style={{width: '100%'}}
                                size={'lg'}
                                onClick={() => ToastStore.success(`copied`)}
                        ><i className="fa fa-clone"></i> Copy link to QR code
                        </Button>
                      </CopyToClipboard>

                      <CopyToClipboard text={this.state.uri} onCopy={() => {}}>
                        <Button color="secondary"
                                style={{width: '100%'}}
                                onClick={() => ToastStore.success(`web+stellar:pay... copied!`)}
                        ><i className="fa fa-clone"></i> Copy link SEP-007
                        </Button>
                      </CopyToClipboard>

                      <Link
                        to={`/pay/${invoice_id}`}
                        className="btn btn-secondary"
                        style={{width: '100%', marginBottom: 0}}
                        size={'lg'}
                      >Go to Payment Request</Link>
                    </div>

                  </CardBody>
                </Card>
              : null
            }

            {
              wallets ?
                <Card style={{height: 'auto'}}>
                  <CardBody>
                    <Link to={'/invoices'}
                          className={'btn btn-outline-secondary btn-lg'}
                          style={{
                            width: '100%',
                            marginBottom: 0,
                          }}><i className={'fa fa-list-ul'}></i> My Invoices</Link>
                  </CardBody>
                </Card>
              : null
            }


            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>

                <Collapse title="What is a Payment Request" className="with-shadow ml-0">
                  <p>Payment Request is a pre-created data object (recipient's account, asset, amount, memo) transmitted as a
                    QR code or a web link for a deposit to the recipient's account specified in the payment request.</p>
                </Collapse>
                <Collapse title="How to create a Payment Request" className="with-shadow ml-0">
                  <p>To create a Payment Request, you need to specify the address of the recipient of this payment request, the asset and
                    the amount you want to receive.</p>
                </Collapse>
                <Collapse title="Who can pay for a payment request" className="with-shadow ml-0">
                  <p>Any Stellar user can pay for such a payment request. Even if the user does not have such an asset on the balance
                    that you specified when creating the payment request, the user will still be able to pay such an invoice with any
                    of his assets, and you will receive the asset that you specified.</p>
                </Collapse>
                <Collapse title="Where to see my Payment Requests" className="with-shadow ml-0">
                  <p>All generated payment requests are
                    located at <a href="https://scopuly.com/invoices" target={'_blank'}>https://scopuly.com/invoices</a>.</p>
                </Collapse>
                <Collapse title="How can I pay for such a request?" className="with-shadow ml-0">
                  <p>To pay, you need to send your client a link to the QR code of the payment request. You can pay for your request directly
                    from the link in the Scopuly web app or scan the QR code in the Scopuly mobile app for iOS or Android. You can use
                    Payment Requests to sell your products online or offline, to receive payments from your customers, or to accept donations
                    from your fans.</p>
                </Collapse>
                <Collapse title="What is Memo" className="with-shadow ml-0">
                  <p>If you specify a memo when creating a Payment Request, then all payments from your customers will
                    be sent to your address with the note that was specified when creating the Payment Request.
                    This may be useful when identifying incoming payments.</p>
                </Collapse>

              </CardBody>
            </Card>


            <Card>
              <CardBody>
                <Link to={'/payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-location-arrow'}></i> Payments</Link>
                <Link to={'/create-claimable-balance'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-clock-o'}></i> Pending Payments</Link>
                <Link to={'/mass-payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%', marginBottom: 0,}}><i className={'fa fa-users'}></i> Mass Payments</Link>
              </CardBody>
            </Card>

          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}



// export default translate('common')(Send);
export default reduxForm({
  form: 'floating_labels_form', // a unique identifier for this form
})(translate('common')(Receive));
