import React, { PureComponent, useState } from 'react';
import {
  Col, Row, Card, CardBody,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {
  numFormat,
} from '../../../../../modules/index';
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import {getRelativeAssets} from "../../../../../endpoints/API";
import BalanceItem from './BalanceItem';
import LPoolItem from './LPoolItem';



const loader = css`
  text-align: center;
  display: inline-block;
`


class Balance extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      balances: [],
      pools: [],
      assets: [],
      total_balances_usd: 0,
      dropdownOpen: false,
    };
  }


  componentDidMount() {

    this.formatAssets(this.props.balances)
  }


  formatAssets = (balances) => {

    let query = {
      pools: [],
      asset_codes: [],
      asset_issuers: [],
    }

    balances.map((item, index) => {

      if (item.asset_type === 'liquidity_pool_shares') {
        query.pools.push(item.liquidity_pool_id)
      }
      else {

        if (item.asset_type !== 'native') {
          query.asset_codes.push(item.asset_code)
          query.asset_issuers.push(item.asset_issuer)
        }
      }
    })


    getRelativeAssets(query.pools, query.asset_codes, query.asset_issuers).then(result => {

      balances.reverse().map((item) => {
        if (item.asset_type === 'liquidity_pool_shares') {
          item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
        }
        else if (item.asset_type !== 'native') {
          item.asset = result.data.assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]
        }
      })

      this.setState({
        balances,
        pools: result.data.pools,
        assets: result.data.assets,
      }, () => {
        // this.getAssetPrice(balances)
      })
    })
  }


  totalAssetsUsd = (value) => {

    this.setState({
      total_balances_usd: (this.state.total_balances_usd + Number(value)),
    })
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen, });
  }


  render() {


    const { total_balances_usd, pools, balances, assets, dropdownOpen } = this.state
    const { price, account } = this.props


    return (
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Balances {balances.length ? `(${balances.length})` : null}</h5>
                {/*<h5 className="subhead">Account : <span className="red-text">{this.props.match.params.id}</span></h5>*/}
              </div>

              <div className="panel__btns">

                <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="wallet-dropdown m-min-6">
                  <DropdownToggle  size="sm" className={'dropdown-round-btn'}><i className="fa fa-align-justify text-secondary"></i></DropdownToggle>

                  <DropdownMenu style={{fontSize: 14}}>
                    <DropdownItem><Link to={`/trustlines/${account.id}`} className="text-secondary"><i className="fa fa-check"></i> Trustlines</Link></DropdownItem>
                    <DropdownItem><Link to={`/offers/${account.id}`} className="text-secondary"><i className="fa fa-area-chart"></i> Offers</Link></DropdownItem>
                    <DropdownItem><Link to={`/claimable-balances/${account.id}`} className="text-secondary"><i className="fa fa-clock-o"></i> Claims</Link></DropdownItem>
                  </DropdownMenu>
                </Dropdown>

              </div>

              {
                balances.length  ? balances.map((item, index) => {
                  return (
                    <BalanceItem key={index}
                                 item={item}
                                 price={price}
                                 pools={pools}
                                 assets={assets}
                                 totalAssetsUsd={this.totalAssetsUsd}
                    />
                  )
                }) : null
              }

              {
                pools.length ?
                  <div>
                    <div className="card__title mt-20">
                      <h5 className="bold-text">Liquidity Pools {pools.length ? `(${pools.length})` : null}</h5>
                      {/*<h5 className="subhead">Account : <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                    </div>

                    {
                      balances.length && pools.length ? balances.map((item, index) => {
                        return (
                          <LPoolItem key={index}
                                     item={item}
                                     price={price}
                                     pools={pools}
                                     assets={assets}
                                     totalAssetsUsd={this.totalAssetsUsd}
                          />
                        )
                      }) : null
                    }
                  </div>
                : null
              }

              <div className={'total-usd'}>Total {' '}
                {
                  !total_balances_usd ?
                    <BeatLoader
                      css={loader}
                      color={"#70bbfd"}
                      loading={true}
                      size={7}
                    />
                    :
                    <span>&#8776; <b className="text-secondary">${numFormat(total_balances_usd, 2)}</b></span>
                }
              </div>

            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}



export default (translate('common')(Balance));
