import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, ToastStore } from 'react-toasts';
import EyeIcon from 'mdi-react/EyeIcon';
import PropTypes from 'prop-types';
import { get_loc_wallets } from '../../../../../../modules/index';
import { Field, reduxForm, change } from 'redux-form';


const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}



class WizardFormOne extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  };


  // nameSubmit(values) {
  //
  //   const { handleSubmit } = this.props;
  //
  //   if (!values.name_wallet) {
  //     ToastStore.warning('Please enter the local name of your wallet');
  //   }
  //   // else if (!values.password1) {
  //   //   ToastStore.warning('Please enter the local name of your wallet');
  //   // }
  //   else {
  //     handleSubmit()
  //   }
  // }


  passSubmit = (values) => {

    const { handleSubmit } = this.props;

    let password1 = values.password1;
    let password2 = values.password2;

    if (!password1) {
      ToastStore.warning('Please enter your password');
    }
    else if (!password2) {
      ToastStore.warning('Please enter the password again');
    }
    else if (password1.length < 8 || password2.length < 8) {
      ToastStore.error('Password must be at least 8 characters');
    }
    else if (password1 !== password2) {
      ToastStore.error('Passwords do not match');
    }
    else {
      if (!values.name_wallet) {
        const name_wallet = `Wallet ${get_loc_wallets() ? get_loc_wallets().length : ''}`
        this.props.change('name_wallet', name_wallet);
      }

      setTimeout(() => handleSubmit(), 100)
    }
  }


  render() {
    const { handleSubmit } = this.props;

    return (
      <form className="wizard__form material-form" onSubmit={handleSubmit(this.passSubmit)}>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />


        {/*<div className="text-center">*/}
        {/*  <h3 className="wizard__title">Password</h3>*/}
        {/*</div>*/}

        <div>
          <p>
            Set your wallet password. This password is very important, without it you will not be able to log into your account!
            Store it safe and secure. You can also set a local wallet name that is visible only to you.
          </p>
          <div className="card__title">
            <h5 className="subhead">
              * The password must contain at least 8 characters. It is recommended to establish a
              reliable complex password, consisting of different characters and numbers.
            </h5>
          </div>

          {/*<h3 className="wizard__title">Password</h3>*/}

          <div>
            <Field
              name="password1"
              component={renderTextField}
              label="Password"
              type="password"
            />
          </div>

          <div>
            <Field
              name="password2"
              component={renderTextField}
              placeholder="Password again"
              label="Password again"
              type="password"
            />
          </div>
        </div>

        {/*<h3 className="wizard__title">Local wallet name</h3>*/}

        {/*<div className="card__title">*/}
        {/*  <h5 className="subhead">*/}
        {/*    Set your wallet password. This password is very important, without it you will not be able to log into your account!*/}
        {/*    Store it safe and secure. You can also set a local wallet name that is visible only to you.</h5>*/}
        {/*</div>*/}

        <div>
          <Field
            name="name_wallet"
            component={renderTextField}
            placeholder="Name"
            label="Local wallet name (optional)"
          />
        </div>

        <br/><br/>

        <div>
          <Button color="primary"
                  type="submit"
                  style={{width: '100%'}}
                  className="next">Next</Button>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormOne);
