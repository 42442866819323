import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  ButtonToolbar,
  UncontrolledTooltip
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import {
  get_loc_wallets,
  shortAddr,
  getSigner,
  showErrorMessage,
  getStellarFee,
  numFormat,
  getFloatValue,
  baseReserve,
  minBalance,
  balancesFormat,
  getIconConnectProvider,
  checkAuth,
  getAsset,
  shortAddress,
  formatDate,
  getBasePrice,
  checkConnectProvider,
  signConnectProvider, getTitle,
} from '../../../modules/index';
import swal from "sweetalert";
import MenuItem from "@material-ui/core/MenuItem";
import {Link, Redirect} from "react-router-dom";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getRelativeAssets,} from "../../../endpoints/API";
import {getAccount} from "../../../endpoints/StellarAPI";
import iconIdent from "../Account/components/iconIdent";
import Footer from "../../Layout/footer/Footer";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Collapse from "../../../shared/components/Collapse";



const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type, disabled
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
    style={{width: '100%'}}
  />
);



class MassPayments extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      fee: getStellarFee(),
      hash: false,
      loader: false,
      addresses: [],
      assets: [],
      asset: false,
      select_address: false,
      filter_type: 'payment',
      amount: 0,
      is_auth: false,
      memo: false,
      fee_usd: 0,
    };
  }

  scop_asset = new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ')
  fee = getStellarFee()

  componentDidMount() {

    const title = `Mass Payments on Stellar`
    const description = 'Send mass crypto payments to your clients or partners, up to 100 recipients per transaction, at a speed of 3 to 5 seconds. Send bulk payments of the same type by specifying an array of recipients'
    getTitle(title, description);

    const id = this.props.match.params.id
    if (id === 'payment') this.filterWatchlist('payment')
    if (id === 'claimable') this.filterWatchlist('claimable')
  }


  getFeeUsd = async () => {

    const { addresses } = this.state

    const stellar = await getBasePrice()
    const base_price = stellar.market_data ? stellar.market_data.current_price.usd : 0
    const fee_xlm = (this.fee / 10000000)
    const fee_usd = (fee_xlm * base_price)

    this.setState({
      stellar,
      base_price,
      fee_xlm,
      fee_usd: (addresses.length * fee_usd),
    })
  }


  submitForm = (value) => {

    const { filter_type, select_address, asset, addresses, amount } = this.state

    this.setState({ hash: false })

    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
    const send_asset = asset.asset_type === 'native' ?
      StellarSdk.Asset.native() :
      new StellarSdk.Asset(asset.asset_code, asset.asset_issuer);

    let ops = []

    addresses.forEach((item, index) => {

      if (filter_type === 'payment') {

        ops.push(StellarSdk.Operation.payment({
          destination: item,
          asset: send_asset,
          amount: String(amount),
        }))
      }

      if (filter_type === 'claimable') {

        const claimants = [
          new StellarSdk.Claimant(item, StellarSdk.Claimant.predicateUnconditional()),
          new StellarSdk.Claimant(select_address, StellarSdk.Claimant.predicateUnconditional())
        ]

        ops.push(StellarSdk.Operation.createClaimableBalance({
          claimants: claimants,
          asset: send_asset,
          amount: String(amount),
        }))
      }
    })

    swal({
      title: `Confirm`,
      text: 'Do you want to send a payments right now?',
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.payouts(ops, select_address, signer)
        }
      })
  }


  payouts = (ops, address, signer) => {

    const { memo, fee } = this.state

    this.setState({ loader: true })

    Server.loadAccount(address)
      .then((sourceAccount) => {

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        ops.forEach(op => tx.addOperation(op));
        tx = tx.addMemo(StellarSdk.Memo.text(memo ? memo : ''))
        tx = tx.setTimeout(0).build();

        if (checkConnectProvider(address)) {
          return signConnectProvider(tx, address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          tx.sign(signer);
          return Server.submitTransaction(tx);
        }
      })
      .then((result) => {

        if (result.hash) {

          this.setState({
            loader: false,
            hash: result.hash,
          })

          swal({
            title: "Done!",
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error)
        showErrorMessage(error, 'payment')

        this.setState({ loader: false })

        setTimeout(() => {
          // this.payouts(ops, address, signer)
        }, 100)
      });

  }

  reset = (reset) => {
    reset()
  }

  changeInput = (value) => {
    const addresses = value.target.value.split('\n')
    this.setState({ addresses },
      () => {
      this.getFeeUsd()
    })
  }


  changeAccount = (value) => {

    const select_address = getFloatValue(value);

    this.setState({
      select_address,
      hash: false,
      loader: true,
    })

    getAccount(select_address).then(account => {

      const balances = account.balances.reverse();

      balances.forEach((item, index) => {

        if (item.asset_type === 'native') {

          item.asset_code = 'XLM';
          item.asset_issuer = 'native';
          item.reserve = (account.subentry_count * baseReserve() + minBalance());
          item.avaliable = (item.balance - item.reserve).toFixed(7);

          this.setState({
            balance_native: item.balance,
            loader: false,
          })
        }
        else {
          item.avaliable = item.balance;
        }
      })

      const query = balancesFormat(balances)

      getRelativeAssets(query.pools, query.codes, query.issuers).then(result => {

        if (result) {
          const assets = result.data.assets

          balances.map((item, index) => {

            if (item.asset_type !== 'liquidity_pool_shares') {

              if (item.asset_type === 'native') {
                item.image = '/img/logo/stellar-logo.png'
              }
              else {
                const asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

                if (asset) {
                  item.image = asset.image
                  item.asset = asset
                } else {
                  item.image = '/img/digitalcoin.png'
                }
              }

              return true
            }
          })

          this.setState({
            assets: balances,
          })
        }
        else {

          this.setState({
            assets: balances,
          })
        }
      })
    })
      .catch(error => {
        console.log('error: ', error)
      })
  }


  changeAsset = (value) => {

    const { assets, } = this.state

    const part = getFloatValue(value).split('-');
    const asset = assets.filter(item => item.asset_code === part[0] && item.asset_issuer === part[1])[0]

    this.setState({
      select_asset_code: part[0],
      select_asset: part,
      asset,
    });
  }

  changeAmount = (value) => {
    this.setState({ amount: getFloatValue(value) });
  }

  changeMemo = (value) => {

    let memo = getFloatValue(value);

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }




  filterWatchlist = (type) => {
    this.setState({
      filter_type: type,
    })
  }

  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }

  closeLastTransaction = () => {
    this.setState({ hash: false })
  }



  render() {

    // console.log('state: ', this.state)

    const { handleSubmit, reset } = this.props
    const { wallets, hash, loader, addresses, assets, select_address, filter_type, amount, asset, is_auth, memo, fee_usd } = this.state

    if (is_auth) {
      return <Redirect to='/auth' />
    }


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />


        <Row>

          <Col md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}  className="align-self-center">
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text"><i className={'fa fa-users'}></i> Mass Payments</h5>
                  <h5 className="subhead">Send bulk payments of the same type by specifying an array of recipients</h5>
                </div>

                { loader ? <div className="panel__refresh" style={{height: '95%'}}><LoadingIcon /></div> : null }

                <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                  <div onClick={this.checkAuthAddr}>
                    <Field
                      style={{fontSize: 12}}
                      name="select_address"
                      component={renderTextField}
                      select
                      label="Select Account"
                      onChange={this.changeAccount}
                    >
                      {
                        wallets ?
                          wallets.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                style={{fontSize: '12px'}}
                                className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                                value={item.pk}>
                                <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                              </MenuItem>
                              // <MenuItem key={index} className="material-form__option" value={item.pk}><b className="text-secondary">{shortAddr(item.pk, 8)}</b> <small> / {item.title}</small></MenuItem>
                            );
                          })
                          : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="select_asset"
                      component={renderTextField}
                      select
                      label="Select Asset"
                      onChange={this.changeAsset}
                    >
                      {
                        assets.length ? assets.map((item, index) => {

                          if (item.asset_type !== 'liquidity_pool_shares') {
                            return (
                              <MenuItem
                                key={index}
                                className="material-form__option"
                                value={`${item.asset_code}-${item.asset_issuer}`}
                              >
                                { item.image ? <img src={item.image} alt="" className={'asset-icon-sm'} /> : null} &nbsp;
                                <b className="text-secondary">{item.asset_code}</b> &nbsp; <span className={'text-secondary'}>({item.asset_code === 'XLM' ? numFormat(item.avaliable, 7) : numFormat(item.balance, 7)})</span>
                              </MenuItem>
                            )
                          }
                        }) : null
                      }

                    </Field>
                  </div>


                  <div>
                    <Field
                      name="amount"
                      type="text"
                      label="Amount"
                      component={renderTextField}
                      onChange={this.changeAmount}
                    />
                  </div>

                  <div>
                    <Field
                      name="memo"
                      component={renderTextField}
                      label={`Memo ${this.state.memo ? `(${this.state.memo.length}/28)` : '(optional)'}`}
                      onChange={this.changeMemo}
                    />
                  </div>


                  <div className={'form'}>

                    <div className="form__form-group">
                      <span className="form__form-group-label" style={{fontSize: 12}}>Addresses { addresses ? <font>(<b className={'text-info'}>{addresses.length}</b> / 100)</font> : null }</span>
                      <div className="form__form-group-field">
                        <Field
                          name="addresses"
                          component="textarea"
                          type="text"
                          placeholder="Each address is on a new line"
                          onChange={this.changeInput}
                        />
                      </div>
                    </div>

                  </div>

                  <div>
                    <span className="form__form-group-label" style={{fontSize: 12}}>Operation type</span>
                    <ButtonToolbar style={{marginTop: 4}}>
                      <ButtonGroup className="btn-group--icons">
                        <Button onClick={() => this.filterWatchlist('payment')}
                                color={filter_type === 'payment' ? 'primary' : 'secondary'}> Payment </Button>
                        <Button onClick={() => this.filterWatchlist('claimable')}
                                color={filter_type === 'claimable' ? 'primary' : 'secondary'}> Claimable balance </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </div>

                  <br/><br/>

                  <div>
                    {
                      wallets ?
                        <Button
                          type="submit"
                          color="primary"
                          size={'lg'}
                          style={{width: '100%'}}>
                          Send { amount ? <b>{numFormat(amount, 7)} {asset.asset_code}</b> : null }
                        </Button>
                        :
                        <Link to={'/auth'}
                              className={'btn btn-secondary btn-lg'}
                              style={{width: '100%'}}>Connect Wallet</Link>
                    }
                  </div>

                  {
                    fee_usd ?
                      <div className={'stats-block'}>
                        <p>Fee <a href={null}  id="fee"><i className="fa fa-question-circle-o text-secondary"></i></a>
                          <UncontrolledTooltip placement="top" target="fee">
                                    <span>To prevent ledger spam and maintain the efficiency of the network, Stellar requires small transaction
                                      fees and minimum balances on accounts. Transaction fees are also used to prioritize transactions when
                                      the network enters surge pricing mode.</span>
                          </UncontrolledTooltip>
                          <span className={'swap-data'}>
                                    <small>${numFormat(fee_usd, 7)}</small>
                                  </span>
                        </p>
                      </div>
                    : null
                  }

                </form>

              </CardBody>
            </Card>


            {
              hash ?
                <Card>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Transaction</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastTransaction}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      <span>
                        <p>
                          <span>Transaction:</span>
                          <span className={'swap-data'}>
                            <b><a href={`/transaction/${hash}`}>{ shortAddr(hash, 4) }</a></b> {' '}
                            <CopyToClipboard text={hash} onCopy={() => {}}>
                              <i className="fa fa-clone"
                                 color="secondary"
                                 style={{cursor: 'pointer', fontSize: 14}}
                                 onClick={() => ToastStore.success('Copied!')}></i>
                            </CopyToClipboard>
                          </span>
                        </p>
                        <p>
                          <span>Type:</span>
                          <span className={'swap-data'}>
                            <b className="text-info">{filter_type === 'payment' ? 'Payment' : 'Claimable balance'}</b>
                          </span>
                        </p>
                        <p>
                          <span>Amount:</span>
                          <span className={'swap-data'}>
                            <b className="text-info">{ numFormat(amount, 7) }</b> { getAsset(`${asset.asset_code}:${asset.asset_issuer}`, asset && asset.image, '', 15) }
                          </span>
                        </p>
                        <p>
                          <span>Sender:</span>
                          <span className={'swap-data'}>{shortAddress(select_address)}</span>
                        </p>
                        <p>
                          <span>Recipients:</span>
                          <span className={'swap-data'}><b className="text-info">{addresses.length}</b></span>
                        </p>

                        {
                          memo ?
                            <p>
                              <span>Memo:</span>
                              <span className={'swap-data'}><b className={'text-info'}>{memo}</b></span>
                            </p>
                            : null
                        }
                        <p>
                          <span>Fee:</span>
                          <span className={'swap-data'}><b className="text-info">${numFormat(fee_usd, 7)}</b></span>
                        </p>
                        <p>
                          <span>Date:</span>
                          <span className={'swap-data'}>
                            <small>{formatDate(new Date())}</small>
                          </span>
                        </p>

                      </span>

                    </div>
                  </CardBody>
                </Card>
                : null
            }

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>

                <Collapse title="What is memo" className="with-shadow ml-0">
                  <p>Memo is a note to your payments. Your recipients will be able to read a short message when viewing the transaction.</p>
                </Collapse>

                <Collapse title="What is the type of operation" className="with-shadow ml-0">
                  <p>You can choose between a simple payment, which is a non-refundable payment, or a pending payment,
                    which can be delayed in time for the recipient.</p>
                </Collapse>

                <Collapse title="What is a claimable balance" className="with-shadow ml-0">
                  <p>Claimable Balance - this can be said to be a type of payment that is deferred in time. Upon receipt of such a payment,
                    the recipient must personally claim it in his wallet.</p>
                </Collapse>

                <Collapse title="How Stellar fees are calculated" className="with-shadow ml-0">
                  <p>Each recipient of your payment is a separate transaction on Stellar Ledger.
                    For bulk payments, the minimum fee amount for one Stellar transaction, which is specified in the general settings of Scopuly,
                    is multiplied by the number of recipient addresses and the total amount of fees for this transaction is obtained.</p>
                </Collapse>

                <Collapse title="How to enter addresses correctly" className="with-shadow ml-0">
                  <p>Each new address must start on a new line without spaces, punctuation or indentation.</p>
                </Collapse>

                <Collapse title="How many addresses can be entered" className="with-shadow ml-0">
                  <p>One Stellar transaction can include up to 100 operations. Therefore, you can enter no more than 100 recipient
                    addresses for your payments at a time.</p>
                </Collapse>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Link to={'/payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-location-arrow'}></i> Payments</Link>
                <Link to={'/receive'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-qrcode'}></i> Payment Request</Link>
                <Link to={'/create-claimable-balance'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%', marginBottom: 0,}}><i className={'fa fa-clock-o'}></i> Pending Payments</Link>
              </CardBody>
            </Card>

          </Col>
        </Row>


        <Footer />

      </Container>
    );
  }
}



export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(translate('common')(MassPayments));
