import React, { PureComponent, useState } from 'react';
import {
  Col, Container, Row, Card, CardBody, Button, ButtonGroup, Badge, Alert,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, UncontrolledTooltip
} from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import axios from 'axios';
import { GLOBE } from '../../../../modules/globeVars/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import {
  shortAddr,
  numFormat,
  get_loc_wallets,
  getTitle,
  setLocalWallets,
  shortAddress, getAsset,
  getBasePrice, getIconConnectProvider
} from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {getAccount, getClaimableBalancesForClaimant, getOffers} from "../../../../endpoints/StellarAPI";
import swal from "sweetalert";
import {toggleAuthProvider} from "../../../../redux/actions/customizerActions";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import {getPaymentRequests, getRelativeAssets} from "../../../../endpoints/API";
import {connect} from "react-redux";


const loaderAccountData = css`
  text-align: center;
  display: inline-block;
`


class WalletItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      loader: true,
      offers: [],
      claimable_balances: [],
      invoices: [],
      offers_loader: true,
      claims_loader: true,
      asset_balances: 0,
      connect_provider: false,
      account: false,
      address: this.props.item.pk,
      balance_xlm: 0,
      balance_xlm_usd: 0,
      total_usd: 0,
      base_price: this.props.base_price,
      assets: [],
      pools: 0,
      dropdownOpen: false,
    };
  }


  componentDidMount() {

    const pk = this.props.item.pk

    this.getAccount(pk)
    this.getInvoices(pk)
  }



  getOffers = (address) => {

    getOffers(address).then(offers => {
      this.setState({ offers: offers.records, offers_loader: false })
      this.props.totalOffers(offers.records.length)
    })
  }


  getClaimableBalancesForClaimant = (address) => {
    getClaimableBalancesForClaimant(address).then(claimable_balances => {
      this.setState({ claimable_balances, claims_loader: false })
    })
  }

  getAccount = (address) => {

    const { base_price } = this.state

    getAccount(address).then(account => {

      this.setState({ loader: false })

      if (account.name && account.name === 'NotFoundError') {
        return
      }

      account.pools = 0
      account.balances.forEach(item => {
        if (item.asset_type === 'liquidity_pool_shares') {
          account.pools += 1
        }
      })

      const balance_xlm = account.balances.reverse()[0].balance //
      const balance_xlm_usd = (balance_xlm * base_price)

      this.setState({
        account,
        balance_xlm,
        balance_xlm_usd,
      })

      this.props.allXlmBalances(balance_xlm)
      this.props.totalAssetsUsd(balance_xlm_usd)
      this.props.totalBalances(account.balances)
      this.props.totalPools(account.pools)

      this.formatAssets(account.balances)
      // this.getAssetPrice(account.balances)
      this.getOffers(address)
      this.getClaimableBalancesForClaimant(address)
    })
  }


  getAssetPrice = (balances) => {

    const { pools, assets, base_price } = this.state

    const base_asset = new StellarSdk.Asset.native()
    let total_usd = 0

    balances.map( async (item, index) => {

      if (item.asset_type !== 'native' && item.asset_type !== 'liquidity_pool_shares') {

        const counter_asset = new StellarSdk.Asset(item.asset_code, item.asset_issuer)
        const lastTrade = await Server.trades().forAssetPair(base_asset, counter_asset).order('desc').limit(1).call()

        let price = lastTrade.records[0].price

        if (price) {
          price = (price.d / price.n)

          const amount_asset_xlm = (item.balance * price)
          const amount_asset_usd = (amount_asset_xlm * base_price)
          total_usd = (this.state.total_usd + amount_asset_usd)

          if (balances.length >= index+1) {

            this.setState({ total_usd })
            this.props.totalAssetsUsd(total_usd)
          }
        }
      }

      if (item.asset_type === 'liquidity_pool_shares' && pools) {

        const pool = pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]

        if (pool) {
          const asset = assets.filter(asset => asset.asset_code === pool.code_a && asset.asset_issuer === pool.issuer_a)[0]

          if (asset) {

            const reserves_a = pool.amount_a
            const total = pool.shares
            const amountA = Number((reserves_a / total * item.balance).toFixed(7)) // from shares

            const amount_usd = (pool.issuer_a === 'native' ? (amountA * base_price) : ((amountA * asset.price) * base_price)) * 2
            total_usd += amount_usd

            if (balances.length >= index+1) {

              this.setState({ total_usd })
              this.props.totalAssetsUsd(total_usd)
            }
          }
        }
      }
    })
  }


  formatAssets = (balances) => {

    const { assets } = this.state

    let query = {
      pools: [],
      asset_codes: [],
      asset_issuers: [],
    }

    balances.map((item, index) => {

      if (item.asset_type === 'liquidity_pool_shares') {
        query.pools.push(item.liquidity_pool_id)
      }
      else {

        if (item.asset_type !== 'native') {
          query.asset_codes.push(item.asset_code)
          query.asset_issuers.push(item.asset_issuer)
        }
      }
    })


    getRelativeAssets(query.pools, query.asset_codes, query.asset_issuers).then(result => {

      balances.reverse().map((item) => {

        if (item.asset_type === 'liquidity_pool_shares') {
          item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
        }

        else {

          result.data.assets.map(itemAsset => {
            if (item.asset_code && itemAsset.asset_code === item.asset_code) {
              itemAsset.balance = item.balance
            }
          })
        }
      })

      this.setState({
        balances,
        pools: result.data.pools,
        assets: result.data.assets,
      }, () => {
        this.getAssetPrice(balances)
      })
    })
  }


  showQR = (address, e) => {
    e.preventDefault()
    this.props.showQrCode(address)
  }


  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen, });
  }


  getInvoices = (address) => {

    getPaymentRequests(address, 50)
      .then(result => {

        if (result.data !== 'not_found') {
          this.setState({
            invoices: result.data,
          })

          // this.props.allInvoices && this.props.allInvoices(result.data)
        }
      })
  }



  render() {
    // console.log('state: ', this.state)

    const { item } = this.props
    const { account, loader, claims_loader, offers_loader, offers, claimable_balances, assets, balance_xlm, balance_xlm_usd, total_usd,
      dropdownOpen, invoices } = this.state
    const assets_limit = 10


    return (
      <Card style={{height: 'auto'}}>
        <span className="text-secondary item-count">{this.props.index}</span>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text fs-16" >
              {shortAddress(item.pk)} &nbsp;&nbsp;
              <CopyToClipboard text={item.pk} onCopy={() => {}}>
                <i className="fa fa-clone text-secondary"
                   style={{cursor: 'pointer', fontSize: 14}}
                   onClick={() => ToastStore.success('Copied!')}></i>
              </CopyToClipboard>
              &nbsp;&nbsp;
              <a href={'#'} onClick={(e) => this.showQR(item.pk, e)}><i className="fa fa-qrcode text-secondary"></i></a>
            </h5>
            {
              account ?
                <div className="subhead text-secondary justify-content-start">
                  <div className={'fs-12 mr-14'}>Balances: <b>{account.balances.length}</b></div>
                  <div className={'fs-12 mr-14'}>Pools: <b>{account.pools}</b></div>
                  <div className={'fs-12 mr-14'}>Offers: {' '}
                    {
                      offers_loader ?
                        <BeatLoader
                          css={loaderAccountData}
                          color={"#70bbfd"}
                          loading={true}
                          size={6}
                        />
                        :
                        <b> {offers.length}</b>
                    }
                  </div>
                  <div className={'fs-12 mr-14'}>Claims: {' '}
                    {
                      claims_loader ?
                        <BeatLoader
                          css={loaderAccountData}
                          color={"#70bbfd"}
                          loading={true}
                          size={6}
                        />
                        :
                        <b> {claimable_balances.length}</b>
                    }
                  </div>
                </div>
              :
                !loader ?
                <h4 className="subhead"><i className={'fa fa-info'}></i> Account not funded</h4>
              : null
            }

          </div>
          <div className="panel__btns">

            <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown} className="wallet-dropdown m-min-6">
              <DropdownToggle  size="sm" className={'dropdown-round-btn'}><i className="fa fa-align-justify text-secondary"></i></DropdownToggle>

              <DropdownMenu style={{fontSize: 14}}>
                <DropdownItem><Link to={`/account/${item.pk}`} className="text-secondary"><i className="fa fa-eye"></i> View Account</Link></DropdownItem>
                <DropdownItem><Link to={`/trustlines/${item.pk}`} className="text-secondary"><i className="fa fa-check"></i> Trustlines <small>(<b>{account && account.balances.length}</b>)</small></Link></DropdownItem>
                <DropdownItem><Link to={`/account/${item.pk}`} className="text-secondary"><i className="fa fa-usd"></i> Pools <small>(<b>{account.pools}</b>)</small></Link></DropdownItem>
                <DropdownItem><Link to={`/offers/${item.pk}`} className="text-secondary"><i className="fa fa-area-chart"></i> Offers <small>(<b>{offers.length}</b>)</small></Link></DropdownItem>
                <DropdownItem><Link to={`/claimable-balances/${item.pk}`} className="text-secondary"><i className="fa fa-clock-o"></i> Claims <small>(<b>{claimable_balances.length}</b>)</small></Link></DropdownItem>
                <DropdownItem><Link to={`/invoices/${item.pk}`} className="text-secondary"><i className="fa fa-qrcode"></i> Invoices <small>(<b>{invoices.length}</b>)</small></Link></DropdownItem>
                <DropdownItem><Link to={`/account/${item.pk}#settings`} className="text-secondary"><i className="fa fa-cog"></i> Settings</Link></DropdownItem>
                <DropdownItem><a onClick={(e) => this.props.deauthAddress(item.pk, e)} href="#" className="text-secondary"><i className="fa fa-sign-out"></i> Log out</a></DropdownItem>
              </DropdownMenu>
            </Dropdown>

          </div>

          { loader ?  <Card><div className="panel__refresh"><LoadingIcon /></div></Card> : null }

          {
            account ?
              <div className={'text-secondary'}>

                <div className="avatars">

                  <div className="avatars__item" >
                    <Link to={`/native`} title={`${numFormat(balance_xlm, 7)} XLM`}>
                      <div className="avatars__image">
                        <img src={'/img/logo/stellar-logo-icon.png'}
                             style={{borderRadius: '50%'}}
                             alt={'XLM'} />
                      </div>
                    </Link>
                  </div>

                  {
                    assets.length ?
                      assets.map((item, index) => {
                        if (index < assets_limit) {
                          return(
                            <div className="avatars__item" key={index}>
                              <Link to={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}
                                    title={`${numFormat(item.balance, 7)} ${item.asset_code}`}
                                    id={`tooltip-${index}`}>
                                <div className="avatars__image">
                                  <img src={!item.image || item.image === 'undefined' ? '/img/digitalcoin.png' : item.image }
                                       style={{borderRadius: '50%'}}
                                       alt={item.asset_code} />
                                </div>
                              </Link>
                            </div>
                          )
                        }
                    })
                    :
                      account.balances.length > 1 ?
                        <span>
                          &nbsp;&nbsp;
                          <BeatLoader
                            css={loaderAccountData}
                            color={"#70bbfd"}
                            loading={true}
                            size={6}
                          />
                        </span>
                    : null
                  }

                  {
                    assets.length > assets_limit ?
                      <small style={{margin: '6px 0 0 6px'}}>
                        <Link to={`/account/${account.id}`} className={'text-secondary'}>+{assets.length - assets_limit}</Link>
                      </small>
                    : null
                  }
                </div>

                <br/>

                <div>
                  <div><b className={'fs-16 text-info'}>{numFormat(balance_xlm, 7)}</b> <b className={'text-warning'}>XLM</b></div>
                  <div><small><b>${numFormat(balance_xlm_usd, 7)}</b></small></div>
                  <div className={'text-right'}
                       style={{marginTop: '-23px'}}>
                    <small>Total: <b>${numFormat(total_usd)}</b></small>
                  </div>
                </div>

              </div>
            :
              !loader ?
                <div className={'text-center'}>
                  <p>Send at least <b>2 <Link to={'/native'} className={'text-warning'}>XLM</Link></b> to this address to activate this account</p>
                  <br/>
                  <Link to={'/exchange'} className={'btn btn-sm btn-success mb-0'}><i className="fa fa-credit-card"></i> Buy XLM</Link>
                  {/*<Link to={'/receive'} className={'btn btn-sm btn-secondary mb-0'}>QR Request</Link>*/}
                </div>
            : null
          }
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthProvider: state.customizer.isAuthProvider
  }
}

export default connect(mapStateToProps)(translate('common')(WalletItem));
