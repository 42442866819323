import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, ButtonToolbar, ButtonGroup, Button, Table, Progress } from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import StellarSdk from 'stellar-sdk';
import renderSelectField from '../../../../shared/components/form/Select';
import TextEditor from '../../../../shared/components/text-editor/TextEditor';
import { Server } from '../../../../modules/stellar/index';
import { GLOBE } from '../../../../modules/globeVars/index';
import renderDropZoneField from '../../../../shared/components/form/DropZone';
import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import { shortAddr, numFormat, get_loc_wallets, getAuth } from '../../../../modules/index';
import Footer from '../../../Layout/footer/Footer';


const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo.png`;



const items = [
  {
    name: 'Scopuly',
    description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    img: 'https://g4.dcdn.lt//images/pix/430x260/arKG5I1rmgI/fintech-technologijos-blockchain-77581553.jpg',
    alt: 'Scopuly',
    type: 'current', // furure, ended
  },
]



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type, disabled
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    disabled={disabled}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Edit extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      loader: false,
      wallets: get_loc_wallets(),
      locked: true,
      distributor_assets: false,
      auth_user: localStorage.getItem('auth_user'),
      project_db: false,
      public_btn: false,
      save_btn: false,
      progress: false,
    };
  }

  componentDidMount() {

    let id = this.props.match.params.id;
    // console.log('id: ', id)

    if (this.state.auth_user) {

      const formData = new FormData();
      formData.append('user_id', JSON.parse(this.state.auth_user).user_id)
      formData.append('path', this.props.match.params.id)

      axios.post(GLOBE.API_URL+`/get_edit_ido`, formData)
        .then(response => {
          // console.log('response: ', response)

          if (response.data.conditions) {
            this.setState({
              conditions: response.data.conditions
            })
          }

          if (response.data.status === 'edit') {
            this.setState({
              public_btn: true,
            })
          }

          let part = response.data.sell_asset.split('-');
          let options_assets = [];

          options_assets.push({
            value: response.data.sell_asset,
            label: `${part[0]}`,
          });

          this.setState({
            project_db: response.data,
            options_assets: options_assets,
          })

          this.props.change('distributor_address', response.data.distr_addr)
          this.props.change('select_asset', options_assets[0])
          // this.props.change('conditions', response.data.conditions)
          this.props.change('white_paper', response.data.white_paper)
          this.props.change('one_pager', response.data.one_pager)
          this.props.change('website', response.data.website)
          this.props.change('website', response.data.website)
          this.props.change('session_cap', response.data.session_cap)
          this.props.change('session_supply', response.data.session_supply)
          this.props.change('icobench_url', 'https://icobench.com/ico/'+response.data.path)
      });
    }
    else {

      swal({
        title: 'Authorization required',
        text: `You must login to continue. Want to do it right now? `,
        icon: 'warning',
        closeOnClickOutside: false,
        buttons: {
          cancel: false,
          confirm: true,
        },
      })
      .then((value) => {
        if (value) {
          this.setState({
            redirect: true,
            redirect_path: '/login#edit/'+this.props.match.params.id,
          })
        }
      })
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };



  submitForm = (values) => {
    // console.log('values: ', values)

    // let conditions = JSON.stringify(`<p>${values.conditions.replace(/\n/g, "<br/>")}</p>`);
    // console.log('conditions: ', conditions)

    if (!this.state.project_db.cover_url && !values.cover_file) {
      ToastStore.warning('Select image for cover');
    }
    else if (!values.distributor_address) {
      ToastStore.warning('Enter Distributor Address');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(values.distributor_address)) {
      ToastStore.error("Distributor Address is not valid");
    }
    else if (!values.select_asset) {
      ToastStore.warning('Select Asset');
    }
    else if (!values.session_supply) {
      ToastStore.warning('Enter Session Supply (in USD)');
    }
    else if (!values.session_cap) {
      ToastStore.warning('Enter Session Cap (in USD)');
    }
    else if (!this.state.conditions) {
      ToastStore.warning('Enter Conditions');
    }
    else if (!values.white_paper) {
      ToastStore.warning('Enter White Paper URL');
    }
    else if (!values.one_pager) {
      ToastStore.warning('Enter One Paper URL');
    }
    else if (!values.website) {
      ToastStore.warning('Enter Website URL');
    }
    else if (!values.icobench_url) {
      ToastStore.warning('Enter ICOBench URL');
    }
    else {

      const data = JSON.stringify({
        user_id: JSON.parse(this.state.auth_user).user_id,
        project_id: this.props.match.params.id,
        distr_addr: values.distributor_address,
        select_asset: values.select_asset.value,
        session_supply: values.session_supply,
        session_cap: values.session_cap,
        conditions: this.state.conditions,
        white_paper: values.white_paper,
        one_pager: values.one_pager,
        website: values.website,
        icobench_url: values.icobench_url.split('/')[4],
      });

      // const formData = new FormData();
      // formData.append('email', values.email)
      // formData.append('pass', values.password)


      axios.post(GLOBE.API_URL+`/update_project?data=${data}`)
        .then(response => {
          console.log('response: ', response)

          if (response.data === 'update') {
            ToastStore.success('Project data successfully updated!');

            this.setState({
              save_btn: false,
              public_btn: true,
            });
          }
      });
    }
  }



  changeIssuerAddress(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }
  }



  changeDistributorAddress(value) {
    this.getDistributorAssets(value.target.value);
  }



  getDistributorAssets(address) {

    this.setState({ loader: true });

    Server.loadAccount(address)
      .then((account) => {
        // console.log('getDistributorAssets account: ', account)

        this.setState({ loader: false });

        let options_assets = [];
        account.balances.forEach((item, index) => {
          if (item.asset_type !== 'native') {
            options_assets.push({
              value: item.asset_code+'-'+item.asset_issuer,
              label: `${item.asset_code} (${numFormat(item.balance, 7)})`,
            })
          }
        })

        // console.log('options_assets: ', options_assets)

        this.setState({
          distributor_assets: account.balances,
          options_assets: options_assets,
        })

        // account.balances.forEach((item, index) => {
        //   if (item.asset_code === this.state.asset_code && item.asset_issuer === this.state.issuer_address) {
        //     this.setState({
        //       distributor_trust: true,
        //       distributor_assets: account.balances,
        //     })

        //     if (item.asset_type === 'native') {
        //       item.asset_code = 'XLM';
        //     }
        //   }
        // });

        // if (!this.state.distributor_trust) {
        //   swal({
        //     title: 'No trustline',
        //     text: `There is no Trustline for the ${this.state.asset_code} token on the Distributor Account. Want to install Trustline to ${this.state.asset_code} right now?`,
        //     icon: 'warning',
        //     buttons: {
        //       cancel: true,
        //       confirm: true,
        //     },
        //   })
        //   .then((value) => {
        //     if (value) {
        //       this.setTrustline(address);
        //     }
        //   })
        // }
      })
      .catch((err) => {
        console.log('error: ', err);
        this.setState({ loader: false });
        ToastStore.error('Error: '+err.toString());
    })
  }


  fileChangedHandler(files) {

    const file = files[0];
    // console.log('file: ', file);

    if (file) {

      this.state.project_db.cover_url = file.preview;

      this.setState({
        load_preview: true,
        progress: true,
      })

      const formData = new FormData();
        formData.append(
          'myfile',
          file,
          file.name,
        );
        formData.append('user_id', JSON.parse(this.state.auth_user).user_id)
        formData.append('project_id', this.props.match.params.id)

        axios.post(GLOBE.API_URL+`/save_image`, formData, {
          onUploadProgress: progressEvent => {
            let progress = Math.round(progressEvent.loaded / progressEvent.total * 100);
            // console.log('progress: ', progress)

            this.setState({
              progress: progress,
            })

            if (progress === 100) {
              ToastStore.success('Cover image successfully updated!');

              setTimeout(() => {
                this.setState({
                  progress: false,
                })
              }, 2000)
            }
          }
          }).then(response => {
            // console.log('response: ', response)
        });
    }
  }



  changeTextEditor(value) {


    this.setState({
      conditions: value.replace(/&nbsp;/gi, ''),
    })
  }


  focusForm() {

    this.setState({
      save_btn: true,
      public_btn: false,
    });
  }


  publishProject() {

    const formData = new FormData();
    formData.append('user_id', JSON.parse(this.state.auth_user).user_id)
    formData.append('project_id', this.props.match.params.id)

    axios.post(GLOBE.API_URL+`/public_project`, formData)
      .then(response => {
        // console.log('response: ', response)

        if (response.data === 'success') {
          this.setState({
            save_btn: false,
            public_btn: false,
          });

          swal({
            title: 'Request successfully sent!',
            text: `Your request to publish a project has been successfully submitted. Expect it to take some time.`,
            icon: 'success',
          });
        }
    });
  }





  render() {

    // console.log('Edit state: ', this.state)

    const { reset, handleSubmit } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect_path} />
    }

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Scopuly <font className={`status-ieo ieo-current`}>current</font></h3>
            <h4 className="subhead" style={{marginBottom: '18px'}}>
              Trading Platform & Block Explorer for Stellar Network
            </h4>
          </Col>
        </Row>*/}

        <Row>
          <Col md={{ size:8, offset:2 }}>
            <Card>
              <CardBody>

                { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                {/*<p dangerouslySetInnerHTML={{__html: 'ывафыа↵аа'}}></p>*/}

                <div className="card__title">
                  <h5 className="bold-text">IEO PROJECT SETTINGS</h5>
                  <h5 className="subhead">To publish an IEO project, you must fill in all the form fields.</h5>
                </div>
                <br/>
                <form className="form form--horizontal"
                      onSubmit={handleSubmit(this.submitForm)}
                      onClick={() => this.focusForm()}>





                  {
                    this.state.project_db && this.state.project_db.cover_url  ?
                      <div className="form__form-group">
                        <span className="form__form-group-label">Current cover image</span>
                        <div className="form__form-group-field">
                          <div>
                            <img src={this.state.project_db.cover_url} />
                          </div>
                        </div>
                      </div>
                    : null
                  }

                  <div className="form__form-group">
                    <span className="form__form-group-label">Select New Image <small>(For Cover)</small></span>
                    <div className="form__form-group-field">
                      <Field
                        name="cover_file"
                        component={renderDropZoneField}
                        customHeight
                        onChange={(value) => {
                          this.fileChangedHandler(value)
                        }}
                      />
                    </div>
                  </div>

                  {
                    this.state.progress ?
                      <div className="form__form-group">
                        <span className="form__form-group-label"></span>
                        <div className="form__form-group-field">
                          <div className="progress-wrap" style={{width: '100%'}}>
                            <Progress value={this.state.progress} />
                          </div>
                        </div>
                      </div>
                    : null
                  }


                  <div className="form__form-group">
                    <span className="form__form-group-label">Distributor Address</span>
                    <div className="form__form-group-field">
                      <Field
                        name="distributor_address"
                        component="input"
                        type="text"
                        placeholder="Distributor Address"
                        onChange={(value) => {
                          this.changeDistributorAddress(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Select Asset</span>
                    <div className="form__form-group-field">
                      <Field
                        name="select_asset"
                        component={renderSelectField}
                        placeholder="Select an Asset for distribution"
                        options={this.state.options_assets}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Session Supply <small>(Amount Tokens)</small></span>
                    <div className="form__form-group-field">
                      <Field
                        name="session_supply"
                        component="input"
                        type="text"
                        placeholder="Session Supply (in Tokens)"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Session Cap <small>(Amount USD)</small></span>
                    <div className="form__form-group-field">
                      <Field
                        name="session_cap"
                        component="input"
                        type="text"
                        placeholder="Hard Сap for this Session (in USD)"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Conditions</span>
                    <div className="form__form-group-field">

                      {
                        this.state.project_db ?
                          <TextEditor content={this.state.project_db.conditions}
                                      onChange={(value) => {
                                        this.changeTextEditor(value)
                                      }} />
                        : null
                      }
                    </div>
                  </div>

                  {/*<div className="form__form-group">
                    <span className="form__form-group-label">Conditions {this.state.conditions_format}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="conditions"
                        component="textarea"
                        type="text"
                      />
                    </div>
                  </div>*/}

                  <div className="form__form-group">
                    <span className="form__form-group-label">White Paper URL</span>
                    <div className="form__form-group-field">
                      <Field
                        name="white_paper"
                        component="input"
                        type="text"
                        placeholder="White Paper URL"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">One Paper URL</span>
                    <div className="form__form-group-field">
                      <Field
                        name="one_pager"
                        component="input"
                        type="text"
                        placeholder="One Paper URL"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Website</span>
                    <div className="form__form-group-field">
                      <Field
                        name="website"
                        component="input"
                        type="text"
                        placeholder="https://your_website.com"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">ICOBench Project URL</span>
                    <div className="form__form-group-field">
                      <Field
                        name="icobench_url"
                        component="input"
                        type="text"
                        placeholder="https://icobench.com/ico/your_project"
                      />
                      {/*<div className="form__form-group-icon up-bench-btn">
                        <a href="#"><i class="fa fa-refresh" style={{color: '#4ce1b5'}}></i></a>
                      </div>*/}
                    </div>
                  </div>

                  <div>
                    <br/>
                    <ButtonToolbar className="form__button-toolbar float-right">
                      <Button color="primary"
                              type="submit"
                              disabled={this.state.save_btn ? false : true}>Save</Button>

                      <Button color="success"
                              disabled={this.state.public_btn ? false : true}
                              onClick={(e) => {this.publishProject()}}>Publish</Button>

                      <Link to={`/ieo/${this.props.match.params.id}`}
                            style={{margin: '9px 0 0 10px'}}>Go to the project</Link>
                    </ButtonToolbar>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Footer />

      </Container>
    );
  }
}


Edit.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'edit_project',
})(translate('common')(Edit));



