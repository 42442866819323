import React, { PureComponent } from 'react';
import { Col, Card, CardBody, Alert, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Wizard from './WizardForm/components/WizardForm';
// import classnames from 'classnames';
import CryptoJS from 'crypto-js';
import StellarSdk from 'stellar-sdk';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
// import LoadingIcon from 'mdi-react/LoadingIcon';
import CommentAlertOutlineIcon from 'mdi-react/CommentAlertOutlineIcon';
import {get_loc_wallets, getTitle, setLocalWallets} from '../../../../modules/index';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import slugify from '@sindresorhus/slugify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { GLOBE } from '../../../../modules/globeVars/index';
const redshift = require('@stellar-fox/redshift');




const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}


class CreateAccount extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      show_message: true,
      show_name: false,
      show_phrase: false,
      show_check_phrase: false,
      show_pass: false,
      show_token: false,
      copy_token: false,
      accept_options: false,
      name_wallet: '',
      publicKey: '',
      account: {},
      value_copy: '',
      copied: false,
      api_key: 'f666de1538b92e3484f8e9edc512112889e5469ddf9f8b3059a98506',
      my_wallets: get_loc_wallets(),
      mnemonic: false,
    };
  }

  componentDidMount() {
    const title = 'Creating a Stellar Account is safe and easy';
    const description = 'Scopuly - convenient web interface for creating and restoring Stellar Account. Displays the private key. Local encryption. Connecting browser extensions wallets.';
    getTitle(title, description);
  }


  setNameWallet(e) {

    let name_wallet = e.name_wallet;
    if (!name_wallet) {
      ToastStore.warning('Please enter your wallet name');
    }
    else {
      this.setState({
        name_wallet: name_wallet,
        show_phrase: true,
        show_name: false,
      })

      this.getMnemonic();
    }
  }


  getMnemonic() {
    const mnemonic = redshift.genMnemonic();
    // console.log('mnemonic: ', mnemonic.split(' '))

    this.setState({
      mnemonic,
      mnemonic_split: mnemonic.split(' '),
    })
  }


  setPhrase() {

    this.setState({
      show_phrase: false,
      show_check_phrase: true,
    })
  }


  changeCheckPrase(value) {
    // if (value.target && value.target.value === this.state.mnemonic) {
    //   this.correctlyPhrase();
    // }
  }


  submitCheckPhrase(values) {

    if (!values.phrase) {
      ToastStore.warning('Enter your recovery phrase.');
    }
    else if (values.phrase !== this.state.mnemonic) {
      ToastStore.warning('Phrases do not match. Try again.');
    }
    else {

      this.correctlyPhrase();
    }
  }


  correctlyPhrase() {

    ToastStore.success('Phrase entered correctly!');

    this.setState({
      show_check_phrase: false,
      show_pass: true,
    })
  }


  encryptAddress(e) {

    let password1 = e.password1;
    let password2 = e.password2;

    if (!password1) {
      ToastStore.warning('Please enter your encryption password');
    }
    else if (!password2) {
      ToastStore.warning('Please enter the password again');
    }
    else if (password1.length < 8 || password2.length < 8) {
      ToastStore.error('Password must be at least 8 characters');
    }
    else if (password1 !== password2) {
      ToastStore.error('Passwords do not match');
    }
    else {

      var pair = this.generateAddress(password1); // StellarSdk.Keypair.random();

      const publicKey = pair.publicKey()
      const secretKey = pair.secret()
      // console.log('publicKey: ', publicKey)
      // console.log('secretKey: ', secretKey)

      var ciphertext = CryptoJS.AES.encrypt(secretKey, publicKey);


      var account = {
          title: slugify(this.state.name_wallet),
          pk: publicKey,
          sk: window.btoa(ciphertext),
          type: 'encrypt',
      }

      this.setState({
        show_token: true,
        show_pass: false,
        show_name: false,
        accepted: false,
        token: this.genToken(account, password2),
        publicKey: pair.publicKey(),
        account: account,
        // my_wallets: get_my_wallets(),
      })
    }
  }


  generateAddress(pass) {
    const seed = redshift.mnemonicToSeedHex(this.state.mnemonic, pass)
    const kyepair = redshift.genKeypair(seed)

    return kyepair;
  }


  acceptOpts(e) {

    if (e.checkbox_1 && e.checkbox_2 && e.checkbox_3 && e.checkbox_4 && e.checkbox_5) {

      var wallets = this.state.my_wallets;

      if (!wallets) {
        wallets = [];
      }

      wallets.push(this.state.account);

      setLocalWallets(wallets)

      localStorage.setItem('2fa', 'false');

      this.spk();

      this.setState({
        accepted: true,
        show_token: false,
      })
    }
    else {
      ToastStore.warning('All conditions must be accepted!');
    }
  }


  spk() {

    fetch(`https://api.ipdata.co/?api-key=${this.state.api_key}`)
    .then(res => res.json())
    .then((response) => {
      // console.log('ipdata response', response);

        if (!response.country_code) {
          response.longitude = '';
          response.latitude = '';
          response.country_code = '';
          response.country_name = '';
          response.city = '';
          response.region = '';
          response.currency = {
            code: '',
            name: '',
          }
        }

        setIpData(response);
      },
      (error) => {
        console.log('err', error);
      }
    )

    const setIpData = (ipdata) => {
      const loc = ipdata.latitude+','+ipdata.longitude;

      axios.get(`${GLOBE.API_URL}/spk/?pk=${this.state.publicKey}&platform=web&country=${ipdata.country_code}&country_name=${ipdata.country_name}&city=${ipdata.city}&region=${ipdata.region}&loc=${loc}&currency_code=${ipdata.currency.code}&currency_name=${ipdata.currency.name}`)
        .then((response) => {
          if (response) {
            //...
          }
        })
        .catch(function (error) {
          console.log(error);
      });
    }
  }


  genToken(account, pass) {

    var stringify_account = window.btoa(JSON.stringify(account)); // base64
    var ciphertext = CryptoJS.AES.encrypt(stringify_account, pass); // encrypt CryptoJS

    // Decrypt
    var plaintext;
    var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), pass);

    try {
        plaintext = bytes.toString(CryptoJS.enc.Utf8);

        if (plaintext) {
            // var parse_account = JSON.parse(window.atob(plaintext));

            var label_addr = account.pk.substring(0,8);
            var token = '<'+label_addr+ciphertext.toString()+'>';

            return token;
        }
        else {
          this.genToken(account, pass)
        }
    }
    catch (err) {
        console.log('err: ', err);
        plaintext = "";
        ToastStore.error('Encryption error...');
    }
  }



  copiedMnemonic() {

    this.setState({
      copied: true,
    })

    ToastStore.success('Copied!')
  }


  submitForm(values) {
    // console.log('submitForm values: ', values);
  }




  render() {

    const {reset, handleSubmit} = this.props;

    // console.log('state: ', this.state);

    return (
      <Col md={{ size: 7, offset: 3 }} lg={{ size: 4, offset: 4 }}>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Create Stellar Account</h5>
            </div>

            { this.state.show_message ?
              <div>

                <div>
                  <div className="" style={{padding: '20px'}}>
                    <p><span className="bold-text text-primary"><i className={'fa fa-exclamation'}></i> <span className={'uppercase'}>Account security</span> <i className={'fa fa-exclamation'}></i></span><br/><br/>
                      For security reasons, Scopuly doesn't in any way store your passphrases or wallet secrets on the server or anywhere else.
                      Your data is temporarily stored in encrypted form in the browser memory of your device.
                      <br/><br/>
                      You must take care of the security of your private key, password, and passphrase yourself.
                      This data is necessary to restore the wallet in the future, in case of authorization of the
                      wallet on another device, replacement or loss of the device, or reinstallation of the browser / app.
                    </p>
                    <br/>
                    <p><i><b>Securely store this authorization data that you receive when you create a wallet</b></i></p>
                    {/*<br/>*/}
                    {/*<ol>*/}
                    {/*  <li>Public key (address)</li>*/}
                    {/*  <li>Secret key</li>*/}
                    {/*  <li>Seed phrase (mnemonic 24 words)</li>*/}
                    {/*  <li>Your personal password</li>*/}
                    {/*</ol>*/}
                    {/*<br/>*/}
                    <p><small>* Your <b>Secret key</b> can be obtained in your account settings by accepting the necessary agreements.</small></p>
                  </div>
                </div>
                <br/>
                <div className='text-center'>
                  <Button color="primary"
                          style={{width: '100%'}}
                          onClick={() => this.setState({
                    show_name: true,
                    show_message: false,
                  })}><i className={'fa fa-plus'}></i> <b>Create Account</b></Button>
                </div>
              </div>
            :  null }


            { this.state.show_name ?
              <Wizard onSubmit={this.submitForm.bind(this)}/>

              // <div>
              //   <div className="card__title">
              //     <h5 className="subhead">Set any convenient wallet name for you. You can change this name at any time.</h5>
              //   </div>
              //
              //   <form className="material-form" onSubmit={handleSubmit(this.setNameWallet.bind(this))}>
              //
              //     <div>
              //       <Field
              //         name="name_wallet"
              //         component={renderTextField}
              //         placeholder="Name"
              //         label="Wallet name (only you can see)"
              //       />
              //     </div>
              //
              //     <br/>
              //     <div className='text-center'>
              //       <Button color="primary" type="submit">Next</Button>
              //     </div>
              //   </form>
              // </div>
            : null }


            { this.state.show_phrase ?
              <div>
                <div className="text-center">
                  <h4>Write down and save your recovery phrase.</h4>
                  <p>Keep it secret, keep it safe. You will not be shown again.</p>
                  <br/>
                  <p>
                    {
                      this.state.mnemonic_split.map((item, index) => {

                        return (
                          <span key={index} className="chips_item">
                            <small>{index+1}: </small> <b className="text-info">{item}</b>
                          </span>
                        )
                      })
                    }
                  </p>
                </div>
                <div className="card__title">
                  <h5 className="subhead">
                    This recovery phrase (seed phrase) allows you to restore / authorize your wallet on any devices.
                    You will also be provided with a recovery token for alternative account authorization.
                    24 word mnemonics are also used by many hardware wallets (such as Ledger Nano S/X).
                  </h5>
                </div>
                <br/>
                <div className='text-center'>

                  <CopyToClipboard text={this.state.mnemonic}
                    onCopy={() => {}}>
                    <Button color="primary" onClick={() => this.copiedMnemonic()}><i className="fa fa-files-o"></i> Copy Phrase</Button>
                  </CopyToClipboard>

                  <Button color="primary"
                          disabled={this.state.copied ? false : true}
                          onClick={() => this.setPhrase()}><i className="fa fa-check"></i> I saved this phrase</Button>
                </div>
              </div>
            : null }


            {
              this.state.show_check_phrase ?
                <div>
                  <div className="card__title">
                    <h5 className="bold-text">Let's check your recovery phrase</h5>
                    <h5 className="subhead">Enter the 24-word (space-separated) recovery phrase you received in the previous step.</h5>
                  </div>

                  <form className="form" onSubmit={handleSubmit(this.submitCheckPhrase.bind(this))}>

                    <div className="form__form-group">
                      <Field
                        name="phrase"
                        component="textarea"
                        type="text"
                        placeholder="Enter your recovery phrase."
                        onChange={(value) => this.changeCheckPrase(value)}
                      />
                    </div>

                    <br/>
                    <div className='text-center'>
                      <Button color="primary" type="submit"><i className="fa fa-check"></i> Check Phrase</Button>
                    </div>
                  </form>
                </div>
              : null
            }


            { this.state.show_pass ?
              <div>
                <p>
                  Enter the password to export the account. Keep this password in a safe place.
                  Without this password you will not be able to recover your wallet in the future.
                </p>
                <p>
                  For security and safety reasons, it is recommended to store this data not in the cloud,
                  but somewhere on a removable USB-drive.
                </p>
                <div className="card__title">
                  <h5 className="subhead">
                    The password must contain at least 8 characters. It is recommended to establish a
                    reliable complex password, consisting of different characters and numbers.
                  </h5>
                </div>

                <form className="material-form" onSubmit={handleSubmit(this.encryptAddress.bind(this))}>

                  <div>
                    <Field
                      name="password1"
                      component={renderTextField}
                      label="Password"
                      type="password"
                    />
                  </div>

                  <div>
                    <Field
                      name="password2"
                      component={renderTextField}
                      placeholder="Password again"
                      label="Password again"
                      type="password"
                    />
                  </div>

                  <br/>
                  <div className='text-center'>
                    <Button color="primary" type="submit"><i className="fa fa-lock"></i> Encrypt Secret Key</Button>
                  </div>
                </form>
              </div>
            : null }


            { this.state.show_token ?
              <div>
                <div className="text-center">
                  <h4>Write down and save your Recovery Token.</h4>
                  <p>Keep it secret, keep it safe. You will not be shown again.</p>
                </div>
                <br/>
                <div className="recovery-token">
                  <p>{this.state.token}</p>
                </div>
                <br/>
                <div className='text-center'>

                  <CopyToClipboard text={this.state.token}
                    onCopy={() => {}}>
                    <Button color="warning" onClick={() => ToastStore.success('Copied!')}><i className="fa fa-files-o"></i> Copy this Token</Button>
                  </CopyToClipboard>

                </div>
                <br/>
                <div>
                  <form className="" onSubmit={handleSubmit(this.acceptOpts.bind(this))}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_1"
                          component={renderCheckBoxField}
                          label="I understand and agree that Scopuly does not store secret keys on the server and is not responsible for the loss of this data by me."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_2"
                          component={renderCheckBoxField}
                          label="In the previous step, I installed and reliably saved the encryption / decryption password for this recovery token. I understand that if I lose this encryption password, I will lose access to all the tokens stored in the address of this purse associated with this secret key."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_3"
                          component={renderCheckBoxField}
                          label="I understand that if I lose / destroy this secret recovery token, encrypted with my personal password - I will lose access to the wallet and to all the assets stored in the address of this purse associated with this secret key."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_4"
                          component={renderCheckBoxField}
                          label="I copied this secret recovery token and saved it in a safe place."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_5"
                          component={renderCheckBoxField}
                          label="I have securely saved: 1) Recovery phrase, 2) Recovery token, 3) Password."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <br/>
                    <div className='text-center'>
                      <Button color="success" type="submit"><i className="fa fa-check"></i> I accept the terms</Button>
                    </div>

                  </form>
                </div>
              </div>
            : null }

            { this.state.accepted ?
              <div>
                <b>Your Stellar Address (public key):</b>
                <br/><br/>
                <p className="create-field"><b className="text-info">{this.state.publicKey}</b></p>
                <br/><br/>
                <div className='text-center'>

                  <CopyToClipboard text={this.state.publicKey}
                    onCopy={() => {}}>
                    <Button color="success" onClick={() => ToastStore.success('Copied!')}><i className="fa fa-files-o"></i> Copy Address</Button>
                  </CopyToClipboard>

                  <a className='btn btn-primary' href={'/wallet'}><i className="fa fa-sign-in"></i> Go to Wallet</a>
                </div>
              </div>
            : null }

          </CardBody>
        </Card>
      </Col>
    );
  }
}


CreateAccount.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form, defaults_controls_colored_click', // a unique identifier for this form
})(translate('common')(CreateAccount));
