export function numFormat(value, separ) {

	if (value) {
		var res, slice;
		if (typeof value === 'string') {
			value = Number(value).toFixed(separ)
			slice = value.slice(-1);
		}
		if (typeof value === 'number') {
			value = value.toFixed(separ)
			slice = value.slice(-1);
		}

		if (slice === '0') {
			value = parseFloat(value).toString();
		}

		var indexOf = value.indexOf('.') > -1;

		if (indexOf) {
			var a = value.split('.')[0]; 
			var b = value.split('.')[1]; 
			var replace = a.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
			res = (replace+'.'+b);
		}
		else {
			res = value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,')
		}
		return res;
	}
	else {
		return 0;
	}
}