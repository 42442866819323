import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import { ToastContainer, ToastStore } from 'react-toasts';
// import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
// import moment from 'moment';
import {
  get_loc_wallets,
  baseReserve,
  shortAddr,
  checkConnectProvider,
  signConnectProvider, setPairToLocal, showErrorMessage, randomAmount, getStellarFee, getSigner, numFormat,
} from '../../../modules/index';
import swal from "sweetalert";
import {Redirect} from "react-router-dom";
import {strictReceivePaths, strictSendPaths} from "../../../endpoints/StellarAPI";
import StellarAuthClient from 'stellar-auth-client';
import {
  openTransferServer,
  fetchTransferInfos,
  TransferServer
} from "@satoshipay/stellar-transfer"
import {
  predicateFromHorizonResponse,
  getPredicateInformation,
  flattenPredicate,
  isPredicateClaimableAt
} from 'stellar-resolve-claimant-predicates';


const  passType = 'Ghd6#_d7S';



let total_count = 0, sell_count = 0;


class Dashboard extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      loader: false,
      addressess: [],
      amount_max: 0.001,
      interval: 15000,
      orderbook: false,
      loc_asset: localStorage.getItem('loc_asset'),
      fee: getStellarFee(),

      trader_1: 'GBZMS3BN2MJ7EZG7SASDEGKEY6KMXWDBMETBT7ISVYBLKPDRYCJJ3TZN',
      sendAsset: new StellarSdk.Asset.native(),
      destAsset: new StellarSdk.Asset('yXLM', 'GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55'),

    };
  }

  fee = getStellarFee()

  componentDidMount = () => {
    this.checkAt();

    // this.addManageBuyOffer()

    // this.testTradeBot()
    // this.testStreamBot()


    // server.claimableBalances();
    // server.claimableBalances().claimant(claimant);
    // server.claimableBalances().sponsor(sponsorID);
    // server.claimableBalances().asset(asset);
    // server.claimableBalances().claimableBalance(balanceID);


    // ================\

    // console.log('claimableBalances', Server.claimableBalances())

    Server.claimableBalances()
      .claimableBalance('00000000d4b0c59309c56ce273e15b0ab129d6a64235ed98fdc8f1082f51d5b1248e373a')
      // .claimant('GBZMS3BN2MJ7EZG7SASDEGKEY6KMXWDBMETBT7ISVYBLKPDRYCJJ3TZN')
      // .order('desc')
      // .limit(100)
      .call()
      .then((result) => ({...result, claimants: result.claimants.map(c => ({...c, predicate: predicateFromHorizonResponse(c.predicate)}))}) )
      .then(async (result) => {
        console.log('c result: ', result)

        const txs = await result.transactions()
        console.log('txs: ', txs)

        const ops = await result.operations()
        console.log('ops: ', ops)



      });
    //================/

  }



  //======================== BOT ============\

  testStreamBot = () => {

    // const { sendAsset, destAsset } = this.state

    var callback = function (resp) {
      console.log('stream resp: ', resp);
    };

    const source_amount = 0.1;
    const source_asset = [new StellarSdk.Asset.native()]
    // const destination_asset = [new StellarSdk.Asset('yXLM', 'GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55')]
    const destination_asset = new StellarSdk.Asset('yXLM', 'GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55')

    // Server.strictReceivePaths(source_asset, destination_asset, source_amount)
    //   .cursor("now")
    //   .stream({ onmessage: callback });

    var callback = function (resp) {
      console.log(resp);
    };

    Server
      .strictReceivePaths(
        source_asset,
        destination_asset,
        "1",
      )
      .cursor("now")
      .stream({
        onmessage: (effect) => {
          console.log('effect: ', effect)
        }
      });

    // console.log('es: ', es)
  }


  testTradeBot = () => {

    console.log('loadPathSend...')


    // const source_asset = (base_asset.asset_issuer === 'native') ? new StellarSdk.Asset.native() : new StellarSdk.Asset(base_asset.asset_code, base_asset.asset_issuer)
    // const destination_asset = (counter_asset.asset_issuer === 'native') ? [new StellarSdk.Asset.native()] : [new StellarSdk.Asset(counter_asset.asset_code, counter_asset.asset_issuer)]

    const source_amount = 0.1;
    const source_asset = [new StellarSdk.Asset.native()]
    // const destination_asset = [new StellarSdk.Asset('yXLM', 'GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55')]
    const destination_asset = new StellarSdk.Asset('yXLM', 'GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55')

    strictReceivePaths(source_asset, destination_asset, 1).then(result => {
      console.log('strictReceivePaths result: ', result)

      const sendAsset = new StellarSdk.Asset.native()
      // const sendMax = result.records[0].source_amount
      const path_index = result.records.length > 2 ? result.records.length - 2 : result.records.length > 1 ? result.records.length - 1 : 0
      console.log('path_index: ', path_index)

      const sendMax = result.records[path_index].source_amount
      const destination = this.state.trader_1
      const destAsset = destination_asset
      const destAmount = '1'
      const path = result.records[path_index].path.map(item => {
        if (item.asset_type === 'native') {
          return new StellarSdk.Asset.native()
        }
        else {
          return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
        }
      })

      // console.log('sendAsset: ', sendAsset)
      console.log('path: ', path)
      console.log('sendMax: ', sendMax)
      // console.log('destination: ', destination)
      // console.log('destAsset: ', destAsset)

      const diff = (destAmount - sendMax).toFixed(7)
      const fee_xlm = (this.state.fee / 10000000)
      const income = (diff - fee_xlm).toFixed(7)
      console.log('fee_xlm: ', fee_xlm)
      console.log('diff: ', diff)
      console.log('income: ', income)

      if (diff <= fee_xlm) {
        this.testTradeBot()
        return
      }

      // alert('+'+income+' INCOME')
      // this.testTradeBot()

      this.setSwap(sendAsset, sendMax, destination, destAsset, destAmount, path)

        // .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
    })

    // strictSendPaths(source_asset, 0.1, destination_asset).then(result => {
    //   this.setSwap(result)
    // })

    // strictSendPaths(source_asset, 1, destination_asset).then(result => {
    //   // console.log('strictReceivePaths result: ', result)
    //   this.setSwap(result)
    // })
    //
    // strictSendPaths(source_asset, 10, destination_asset).then(result => {
    //   console.log('strictReceivePaths result: ', result)
    // })
    //
    // strictSendPaths(source_asset, 100, destination_asset).then(result => {
    //   console.log('strictReceivePaths result: ', result)
    // })

    // var callback = function (resp) {
    //   console.log('resp: ', resp);
    // };
    //
    // var es = Server
    //   .strictSendPaths(
    //     new StellarSdk.Asset(
    //       "yXLM",
    //       "GARDNV3Q7YGT4AKSDF25LT32YSCCW4EV22Y2TV3I2PU2MMXJTEDL5T55",
    //     ),
    //     "1",
    //     "GAWLY7WEF5GGT5WCX2MMJEZG3CHLW73P7QJWOL7YWKV2LVH7NVL67ZVN",
    //   )
    //   .cursor("now")
    //   .stream({ onmessage: callback });
  }


  setSwap = (sendAsset, sendMax, destination, destAsset, destAmount, path) => {

    // console.log('result: ', result)
    //
    const { fee } = this.state

    // const destination = trader_1
    // const sendAmount = result.records[0].source_amount
    // const destMin = result.records[0].destination_amount
    // // const destMin = result.records[result.records.length - 1].destination_amount
    // const path = result.records[0].path.map(item => {
    //   if (item.asset_type === 'native') {
    //     return new StellarSdk.Asset.native()
    //   }
    //   else {
    //     return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
    //   }
    // })
    //
    // console.log('sendAmount: ', sendAmount)
    // console.log('destMin: ', destMin)
    // console.log('path: ', path)
    //
    // const diff = (destMin - sendAmount).toFixed(7)
    // const fee_xlm = (fee / 10000000)
    // const income = (diff - fee_xlm).toFixed(7)
    // console.log('fee_xlm: ', fee_xlm)
    // console.log('diff: ', diff)
    // console.log('income: ', income)
    //
    // if (diff <= fee_xlm) {
    //   this.testTradeBot()
    //   return
    // }

    Server.loadAccount(destination)
      .then((sourceAccount) => {

        let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          // .addOperation(StellarSdk.Operation.pathPaymentStrictSend({ sendAsset, sendAmount, destination, destAsset, destMin, path }))
          .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
          .setTimeout(100)
          .build();

        const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(destination).sk))
        transaction.sign(signer);

        console.log('transaction: ', transaction)

        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {
          console.log('result: ', result)

          this.testTradeBot()
        }
      })
      .catch((error) => {
        console.error('Error! ', error);

        this.setState({ loader: false })
        showErrorMessage(error, 'path_payment')

        this.testTradeBot()
      });
  }

  //==================== bot ===== /


  checkAt() {
    let at = localStorage.getItem('at');
    if (!at) {
      let result = prompt('Enter Password');
      if (result !== passType) {
        window.location = window.location.origin + '/home';
        return;
      }
      else {
        localStorage.setItem('at', 'true');
      }
    }
    else {

      if (this.state.loc_asset) {
        this.getAssetData();
      }
    }
  }


  getAssetData() {
    this.checkAddressess();
    this.getOrderbook();
    this.checkLocalAsset();
  }


  checkAddressess() {

    let local_addressess = localStorage.getItem('buying_addressess');
    let amount_max = localStorage.getItem('amount_max');
    let interval = localStorage.getItem('interval');

    if (amount_max) {
      this.props.change('amount_max', amount_max)
    }
    if (interval) {
      this.props.change('interval', interval)
    }

    if (local_addressess) {
      local_addressess = JSON.parse(local_addressess);
      // console.log('local_addressess: ', local_addressess)

      local_addressess.forEach((item, index) => {
        // console.log('item: ', item)

        if (index === 0) {
          this.props.change('address_1', item)
        }
        if (index === 1) {
          this.props.change('address_2', item)
        }
        if (index === 2) {
          this.props.change('address_3', item)
        }
        if (index === 3) {
          this.props.change('address_4', item)
        }
        if (index === 4) {
          this.props.change('address_5', item)
        }
        if (index === 5) {
          this.props.change('address_6', item)
        }
        if (index === 6) {
          this.props.change('address_7', item)
        }
        if (index === 7) {
          this.props.change('address_8', item)
        }
        if (index === 8) {
          this.props.change('address_9', item)
        }
        if (index === 9) {
          this.props.change('address_10', item)
        }
        if (index === 10) {
          this.props.change('address_11', item)
        }
        if (index === 11) {
          this.props.change('address_12', item)
        }
        if (index === 12) {
          this.props.change('address_13', item)
        }
        if (index === 13) {
          this.props.change('address_14', item)
        }
        if (index === 14) {
          this.props.change('address_15', item)
        }
        if (index === 15) {
          this.props.change('address_16', item)
        }
        if (index === 16) {
          this.props.change('address_17', item)
        }
        if (index === 17) {
          this.props.change('address_18', item)
        }
        if (index === 18) {
          this.props.change('address_19', item)
        }
        if (index === 19) {
          this.props.change('address_20', item)
        }
      })
    }
  }



  startTradesSubmit(values) {
    // console.log('values: ', values)

    if (!values.radio) { ToastStore.warning('Select Asset') }
    else if (!values.address_1) { ToastStore.warning('Enter Address 1') }
    // else if (!values.address_2) { ToastStore.warning('Enter Address 2') }
    // else if (!values.address_3) ToastStore.warning('Enter Address 3');
    // else if (!values.address_4) ToastStore.warning('Enter Address 4');
    // else if (!values.address_5) ToastStore.warning('Enter Address 5');
    else {

      let addressess = [];
      Object.keys(values).map((item, index) => {
        if (item !== 'radio' && item !== 'interval' && item !== 'amount_max') {
          addressess.push(values[item]);
        }
      })

      let interval = (Number(values.interval) * 1000);

      this.setState({
        addressess: addressess,
        amount_max: values.amount_max,
        interval: interval,
      })

      localStorage.setItem('buying_addressess', JSON.stringify(addressess))
      localStorage.setItem('amount_max', values.amount_max)
      localStorage.setItem('interval', values.interval)

      setTimeout(() => {
        this.setOffer(addressess);
      }, 100)
    }
  }



  getOrderbook() {

    const asset = JSON.parse(this.state.loc_asset);

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(5)
      .call()
      .then((result) => {
        // console.log('getOrderbook result: ', result)

        if (result) {
          this.setState({
            orderbook: result,
          })

          ToastStore.info(`You can run bot!`);
        }

      })
      .catch((err) => {
        console.log(err);
    })
  }



  setOffer(addressess) {

    let count = 0;

    const asset = JSON.parse(this.state.loc_asset);
    // console.log('asset: ', asset)


    const randomAmount = (max) => {
      var min = 0;
      var random = (Math.random() * (+max - +min) + +min).toFixed(6);
      return random;
    }


    const offer = (item) => {
      // console.log('offer address: ', item)

      let price = '0.9';
      let type = 'Buy'

      const signer = this.state.wallets.filter((item2) => {
        return item2.pk === item;
      })

      if (sell_count >= 3) {
        price   = (this.state.orderbook.asks[3].price_r.d / this.state.orderbook.asks[3].price_r.n).toFixed(7);
        type = 'Sell'
        sell_count = 0;
      }


      // if (count+1 < addressess.length) {

        this.setState({ loader: true });

        Server.loadAccount(item)
          .then((sourceAccount) => {
            // console.log('sourceAccount ', sourceAccount)
            // console.log('type ', type)

            var transaction;

            if (type == 'Sell') {
              transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.manageSellOffer({
                  selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                  buying: new StellarSdk.Asset.native(),
                  amount: String(randomAmount(this.state.amount_max)),
                  price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buying: new StellarSdk.Asset.native(),
                amount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .setTimeout(100)
              .build();
            }

            if (type == 'Buy')  {

              transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                  selling: new StellarSdk.Asset.native(),
                  buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                  buyAmount: String(randomAmount(this.state.amount_max)),
                  price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: new StellarSdk.Asset.native(),
                buying: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
                buyAmount: String(randomAmount(this.state.amount_max)),
                price: price,
              }))
              .setTimeout(100)
              .build();
            }

            transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(signer[0].sk)));
            return Server.submitTransaction(transaction);
          })
          .then((result) => {
            console.log(`result offer: `, result)

            total_count++;
            sell_count++;

            // ToastStore.success(`${type} Success - count: ${total_count} ${shortAddr(item, 4)}`);

            this.setState({ loader: false });

            setTimeout(() => {
              // console.log(`setTimeout ->`)

              nextOffer();

            }, this.state.interval);



            const nextOffer = () => {

              // console.log(`nextOffer`)
              // console.log(`count: `, count)
              // console.log(`addressess[]: `, addressess[0])

              if (count+1 === addressess.length) {

                setTimeout(() => {
                  count = 0;
                  // console.log(`setTimeout -> next round`)

                  // this.setOffer(this.state.addressess);

                  offer(addressess[0]);

                }, this.state.interval);
              }
              else {
                // console.log(`next offer`)

                count++;
                let address = addressess[count];
                // console.log(`address: `, address)

                offer(address);
              }
            }

          })
          .catch((error) => {
            console.log('error tx: ', error);

            // this.setState({ loader: false });

            this.setOffer(this.state.addressess);
            // count++;
            // console.log('offer count: ', count);

            // offer(addressess[0])
        });
      // }
      // else if (count+1 === addressess.length) {

      //   // count = 0;
      //   // setTimeout(() => {
      //   //   // console.log('----------> new cicle')
      //   //   // console.log('this.state.interval: ', this.state.interval)

      //   //   this.setOffer(this.state.addressess);
      //   // }, this.state.interval);
      // }

      //--------
    };



    // if (addressess.length > count) {
    //   offer(addressess[count]);
    // }
    // else {

    //   this.setOffer(addressess);
    // }

    // console.log('next cicle: ', count)
    // console.log('addressess[count]: ', addressess[count])

    offer(addressess[count]);
  }


  checkLocalAsset() {
    if (!this.state.loc_asset) {
      const asset = {
        asset_code: 'SKY',
        asset_issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
      }
      // console.log('asset: ', asset)
    }
    else {

      const asset = JSON.parse(this.state.loc_asset);
      // console.log('loc_asset: ', asset)
    }
  }


  changeAsset(value) {
    // console.log('value: ', value)

    if (value[0] === '1') {
      var asset = {
        asset_code: 'SKY',
        asset_issuer: 'GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
      }
    }
    if (value[0] === '2') {
      var asset = {
        asset_code: 'SCOP',
        asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
      }
    }

    // console.log('asset: ', asset)

    var asset = JSON.stringify(asset);

    localStorage.setItem('loc_asset', asset)

    this.setState({
      loc_asset: asset,
    })

    this.getAssetData();
  }




  render() {

    const { handleSubmit, reset, t } = this.props;

    console.log('state: ', this.state)

    const searchParams = new URLSearchParams(window.location.search);

    if (this.props.match.params.id === 'reward') {
      return <Redirect to={'/dashboard/reward'} />;
    }

    if (this.props.match.params.id === 'volume') {

      let params = ''
      const trader_1 = searchParams.get('trader_1')
      const trader_2 = searchParams.get('trader_2')
      const max = searchParams.get('max')
      const profit = searchParams.get('profit')
      const timeout = searchParams.get('timeout')

      if (trader_1 && trader_2 && max)
        params = `?trader_1=${trader_1}&trader_2=${trader_2}&max=${max}&profit=${profit}&timeout=${timeout}`

      return <Redirect to={`/dashboard/volume/${params}`} />;
    }

    if (this.props.match.params.id === 'volume_amm') {
      let params = ''
      const trader_1 = searchParams.get('trader_1')
      const trader_2 = searchParams.get('trader_2')
      const max = searchParams.get('max')
      const profit = searchParams.get('profit')
      const timeout = searchParams.get('timeout')

      if (trader_1 && trader_2 && max)
        params = `?trader_1=${trader_1}&trader_2=${trader_2}&max=${max}&profit=${profit}&timeout=${timeout}`

      return <Redirect to={`/dashboard/volume_amm/${params}`} />;
    }

    if (this.props.match.params.id === 'pay') {
      return <Redirect to={'/dashboard/pay'} />;
    }


    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card>

              {/*{ this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : '' }*/}

              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Auto Trades </h5>
                  {/*<h5 className="subhead">Addresses I added to my contact list</h5>*/}
                </div>

                <form className="form form--horizontal" onSubmit={handleSubmit(this.startTradesSubmit.bind(this))}>

                  <div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="radio"
                          component={renderRadioButtonField}
                          label="SKY"
                          radioValue="1"
                          defaultChecked={this.state.loc_asset &&  JSON.parse(this.state.loc_asset).asset_code === 'SKY' ? true : null}
                          onChange={(value) => this.changeAsset(value)}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="radio"
                          component={renderRadioButtonField}
                          label="SCOP"
                          radioValue="2"
                          defaultChecked={this.state.loc_asset && JSON.parse(this.state.loc_asset).asset_code === 'SCOP' ? true : null}
                          onChange={(value) => this.changeAsset(value)}
                        />
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 1</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_1"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 2</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_2"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 3</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_3"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 4</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_4"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 5</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_5"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 6</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_6"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 7</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_7"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 8</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_8"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 9</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_9"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 10</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_10"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 11</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_11"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 12</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_12"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 13</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_13"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 14</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_14"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 15</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_15"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 16</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_16"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 17</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_17"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 18</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_18"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 19</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_19"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Address 20</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address_20"
                        component="input"
                        type="text"
                        placeholder="Enter Address"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Options</span>
                    <Row>
                      <Col md={6} >
                        <div className="form__form-group-field">
                          <Field
                            name="amount_max"
                            component="input"
                            placeholder="Amont XLM (max)"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group-field">
                          <Field
                            name="interval"
                            component="input"
                            placeholder="Interval (sec)"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/*<div className="form__form-group">
                    <Row>
                      <Col md={6}>
                        <span className="form__form-group-label">Options</span>
                        <div className="form__form-group-field">
                          <Field
                            name="amount_max"
                            component="input"
                            type="number"
                            placeholder="Amont SKY (max)"
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form__form-group-field">
                          <Field
                            name="interval"
                            component="input"
                            type="number"
                            placeholder="Interval (minutes)"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>*/}


                  <ButtonToolbar className="form__button-toolbar">
                    <Button type="button" onClick={reset}>Reset</Button>
                    {
                      this.state.orderbook ?
                        <Button color="primary" type="submit" >Start Trading</Button>
                      :
                        <Button color="primary" type="submit" disabled >Start Trading</Button>
                    }

                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    );
  }
}


Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(translate('common')(Dashboard));
