import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../Account/components/iconIdent';
import {numFormat, formatDate, getAsset, shortAddr, shortAddress} from '../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class PoolHolders extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: '1',
    };
  }


  renderItem = (item, index) => {
    // console.log('item: ', item)

    const { asset_a, asset_b, pool_db, base_price } = this.props

    const stake = (item.balance / 10000000)
    const percent = numFormat((stake / pool_db.shares * 100), 3)
    const balance_a = ((stake / pool_db.shares) * pool_db.amount_a).toFixed(7)
    const balance_b = ((stake / pool_db.shares) * pool_db.amount_b).toFixed(7)
    const amount_usd = (pool_db.issuer_a === 'native' ? (balance_a * pool_db.price_a) : ((balance_a * pool_db.price_a) * base_price)) * 2

    return (
      <tr key={index}>
        <td><small className={'text-secondary'}>{index+1}</small></td>
        <td>
          {/*<Link to={`/account/${item.account}`}>{iconIdent(item.account, 'icon-indent-sm')} <b>{item.account}</b></Link> {' '}*/}
          {shortAddress(item.account)} {' '}
          <CopyToClipboard text={item.account} onCopy={() => {}}>
            <i className="fa fa-clone"
               color="secondary"
               style={{cursor: 'pointer', fontSize: 14}}
               onClick={() => ToastStore.success('Copied!')}></i>
          </CopyToClipboard>
        </td>
        <td>
          <b className="text-info">{numFormat(stake, 7)}</b> {' '}
          <span className={'wr-balance-poll-item'}>
            <span className={'balance-pool-item'}>
              <span className={'pool-share'}><img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/></span>
              <span className={'pool-share'}><img src={asset_b.image} alt="" style={{width: 23}}/></span>
            </span>
          </span>
        </td>
        {/*<td>*/}
        {/*  <b className={'text-info'}>{numFormat(balance_a, 7)}</b> {getAsset(asset_a, asset_a.image, false)} / <b className={'text-info'}>{numFormat(balance_b, 7)}</b> {getAsset(asset_b, asset_b.image, false)} {' '}*/}
        {/*  <small className={'text-secondary'}>( <b>${numFormat(amount_usd, 2)}</b> )</small>*/}
        {/*</td>*/}
        <td><b>{percent}%</b></td>
      </tr>
    )
  }


  render() {

    // console.log('state: ', this.state)
    // console.log('props: ', this.props)

    const items = this.props.pool_holders;

    return (
      <Table striped responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Pool Shares</th>
          {/*<th>Details</th>*/}
          <th>Share</th>
        </tr>
        </thead>
        <tbody>

        { items.map(this.renderItem) }

        </tbody>
      </Table>
    )
  }
}


export default translate('common')(PoolHolders);
