import React, { PureComponent } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import swal from 'sweetalert';

class Forgot extends PureComponent {

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      show_email: true,
      show_code: false,
      show_pass: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }


  validateEmail(email) {
      var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,6})?$/;
      if( !emailReg.test(email) ) {
          return false;
      } else {
          return true;
      }
  }



  submitEmail(values) {
    // console.log('submitEmail values: ', values)

    if (!this.validateEmail(values.email)) {
      ToastStore.error(`Incorrect email`);
    }
    else {

      const random = this.getRandom();
      // console.log('random: ', random)

      // send on email ...
      const formData = new FormData();
      formData.append('email', values.email)
      formData.append('code', random)

      axios.post(GLOBE.API_URL+`/send_code`, formData)
        .then(response => {
          // console.log('response: ', response)

          if (response.data === 'send_code') {

            this.setState({
              secure_code: random,
              email: values.email,
              show_email: false,
              show_code: true,
            });

            swal({
              title: 'Code sent to email',
              text: `We sent the Security Code to your email. Enter this six-digit code to change the password.`,
              icon: 'info',
            });
          }
          else if (response.data === 'no_user') {
            ToastStore.warning(`No user with such email found.`);
          }
      });
    }
  }


  submitSecureCode(values) {
    // console.log('submitSecureCode values: ', values)
    
    if (Number(values.code) === this.state.secure_code) {

      swal({
        title: 'Successful code check',
        text: `You have confirmed your email and you can proceed to change the password for your account.`,
        icon: 'success',
      });

      this.setState({
        show_code: false,
        show_pass: true,
      })
    }
    else {
      ToastStore.error(`Security code is invalid`);
    }
  }


  submitNewPass(values) {
    // console.log('submitNewPass values: ', values)

    if (!values.password1) {
      ToastStore.warning(`Enter new password`);
    }
    else if (!values.password2) {
      ToastStore.warning(`Enter new password again`);
    }
    else if (values.password1 !== values.password2) {
      ToastStore.warning(`Entered passwords do not match`);
    }
    else {

      const formData = new FormData();
      formData.append('email', values.email)
      formData.append('password', values.password2)

      axios.post(GLOBE.API_URL+`/change_pass`, formData)
        .then(response => {
          // console.log('response: ', response)

          if (response.data > 0) {

            const user = {
              email: this.state.email,
              user_id: response.data,
            }

            localStorage.setItem('auth_user', JSON.stringify(user));

            ToastStore.success(`Account successfully created and authorized!`);
          }
      });
    }
  }


  getRandom() {
    return Math.floor(100000 + Math.random() * 900000);
  }



  render() {
    const { handleSubmit } = this.props;

    return (

      <div className="account">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 


        <div className="account__wrapper">

          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to
                <span className="account__logo"> Scopuly
                  {/*<span className="account__logo-accent">DEV</span>*/}
                </span>
              </h3>
              <h4 className="account__subhead subhead">Forgot Password</h4>
            </div>
            
            {
              this.state.show_email ? 
                <form className="form" onSubmit={handleSubmit(this.submitEmail.bind(this))}>

                  <div className="form__form-group auth-bl">
                    <span className="form__form-group-label">Email from your Account</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <MailRuIcon />
                      </div>
                      <Field
                        name="email"
                        component="input"
                        type="email"
                        placeholder="example@mail.com"
                      />
                    </div>
                  </div>

                  <div className="account__btns ml-0">
                    <Button className="account__btn" color="primary" type="submit">Next</Button>
                  </div>

                </form>
              : null
            }

            {
              this.state.show_code ? 
                <form className="form" onSubmit={handleSubmit(this.submitSecureCode.bind(this))}>

                  <div className="form__form-group auth-bl">
                    <span className="form__form-group-label">Enter Security Code</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <i className="fa fa-shield text-secondary" style={{padding: '7px 9px'}}></i>
                      </div>
                      <Field
                        name="code"
                        component="input"
                        type="text"
                        placeholder={`Example: ${this.getRandom()}`}
                      />
                    </div>
                  </div>
                  <br />

                  <div className="account__btns ml-0">
                    <Button className="account__btn" color="primary" type="submit">Next</Button>
                  </div>

                </form>
              : null
            }

            {
              this.state.show_pass ? 
                <form className="form" onSubmit={handleSubmit(this.submitNewPass.bind(this))}>

                  <div className="form__form-group auth-bl">
                    <span className="form__form-group-label">New password</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <Field
                        name="password1"
                        component="input"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder="New password"
                      />
                      <button
                        className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                        onClick={e => this.showPassword(e)}
                      ><EyeIcon />
                      </button>
                    </div>
                  </div>

                  <div className="form__form-group form__form-group--forgot">
                    <span className="form__form-group-label">New password again</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <Field
                        name="password2"
                        component="input"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder="New password again"
                      />
                      <button
                        className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                        onClick={e => this.showPassword(e)}
                      ><EyeIcon />
                      </button>
                    </div>
                  </div>

                  <div className="account__btns ml-0">
                    <Button className="account__btn" color="primary" type="submit">Update Password</Button>
                  </div>

                </form>
              : null
            }

            <div className="account__have-account">
              <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
          </div>
        </div>



        {/*<div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to
                <span className="account__logo"> Scopuly</span>
              </h3>
              <h4 className="account__subhead subhead">Forgot Password</h4>
            </div>

            {
              this.state.show_email ? 
                <form className="form" onSubmit={handleSubmit(this.submitEmail.bind(this))}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email from your Account</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <MailRuIcon />
                      </div>
                      <Field
                        name="email"
                        component="input"
                        type="email"
                        placeholder="example@mail.com"
                      />
                    </div>
                  </div>

                  <div className="account__btns">
                    <Button className="account__btn" 
                            color="primary" 
                            type="submit" 
                            style={{margin: '10px 0 20px 11px'}}>Next</Button>
                  </div>
                </form> 
              : null
            }

            {
              this.state.show_code ? 
                <form className="form" onSubmit={handleSubmit(this.submitSecureCode.bind(this))}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Enter Security Code</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <MailRuIcon />
                      </div>
                      <Field
                        name="code"
                        component="input"
                        type="text"
                        placeholder={this.getRandom()}
                      />
                    </div>
                  </div>

                  <div className="account__btns">
                    <a className="btn btn-primary account__btn" href="#">Check Code</a>
                  </div>
                </form> 
              : null
            }

            {
              this.state.show_pass ? 
                <form className="form" onSubmit={handleSubmit(this.submitNewPass.bind(this))}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">New Password</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <MailRuIcon />
                      </div>
                      <Field
                        name="password"
                        component="input"
                        type="password"
                        placeholder="New password"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">New Password</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <MailRuIcon />
                      </div>
                      <Field
                        name="password2"
                        component="input"
                        type="password"
                        placeholder="New password again"
                      />
                    </div>
                  </div>

                  <div className="account__btns">
                    <a className="btn btn-primary account__btn" href="#">Update Password</a>
                  </div>
                </form> 
              : null
            }

          </div>
        </div>*/}
      </div>
    );
  }
}

export default reduxForm({
  form: 'forgot_form', // a unique identifier for this form
})(Forgot);
