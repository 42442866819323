/* eslint-disable react/no-typos */
import React, { PureComponent } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../../shared/prop-types/ReducerProps';

const crypto = `${process.env.PUBLIC_URL}/img/landing/demos/4.png`;
const eCommerce = `${process.env.PUBLIC_URL}/img/landing/demos/3.png`;
const analytics = `${process.env.PUBLIC_URL}/img/landing/demos/1.png`;
const fitness = `${process.env.PUBLIC_URL}/img/landing/demos/2.png`;

const cryptoDark = `${process.env.PUBLIC_URL}/img/landing/demos/1_3.png`;
const eCommerceDark = `${process.env.PUBLIC_URL}/img/landing/demos/1_2.png`;
const analyticsDark = `${process.env.PUBLIC_URL}/img/landing/demos/1_1.png`;
const fitnessDark = `${process.env.PUBLIC_URL}/img/landing/demos/1_4.png`;

const background = `${process.env.PUBLIC_URL}/img/landing/right_bg.png`;

const DemoThemeToggle = ({ theme, changeToLight, changeToDark }) => (
  <div className="landing__demo-toggle-wrap">
    <span>Light</span>
    <div className="toggle-btn landing__demo-toggle">
      <input
        className="toggle-btn__input"
        type="checkbox"
        name="demos"
        checked={theme.className === 'theme-dark'}
        onChange={() => {}}
      />
      <button
        className="toggle-btn__input-label"
        onClick={theme.className === 'theme-dark' ? changeToLight : changeToDark}
      >Toggle
      </button>
    </div>
    <span>Dark</span>
  </div>
);

DemoThemeToggle.propTypes = {
  theme: ThemeProps.isRequired,
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
};

class Demos extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
  };

  render() {
    const { theme, changeToLight, changeToDark } = this.props;

    return (
      <section className="landing__section">
        <img className="landing__section-background" src={background} alt="" />
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="landing__section-title"><b>Scopuly</b> Demos</h3>
            </Col>
          </Row>
          <Row className="landing__demo-wrap">
            <Col lg={8}>
              <Link className="landing__demo" to="/account/GBT2RH3RELBKMXVPEKTR7TMEV6YFSIEDOK63SH2XOHM74KFU3PZF2BMY">
                <div className="landing__demo-img-wrap">
                  <img src={theme.className === 'theme-dark' ? cryptoDark : crypto} alt="" />
                </div>
              </Link>
            </Col>
            <Col lg={4} className="landing__demo-theme-toggle">
              <p className="landing__demo-title">Viewing Addresses</p>
              <DemoThemeToggle theme={theme} changeToLight={changeToLight} changeToDark={changeToDark} />
              <p>Detailed view of all public data for each address on the network. View balances and history.</p>
              <p>Useful tools for analyzing blockchain data. Analysis of all transactions and transactions related to the address.</p>
            </Col>
          </Row>
          <Row className="landing__demo-wrap">
            <Col lg={8}>
              <Link className="landing__demo" to="/transaction/e4b5929e527d350a808b8469bdd0cd679f83f39828a405babcd13dcd769b7828">
                <div className="landing__demo-img-wrap">
                  <img src={theme.className === 'theme-dark' ? eCommerceDark : eCommerce} alt="" />
                </div>
              </Link>
            </Col>
            <Col lg={4} className="landing__demo-theme-toggle">
              <p className="landing__demo-title">View transaction</p>
              <DemoThemeToggle theme={theme} changeToLight={changeToLight} changeToDark={changeToDark} />
              <p>Easily view the entire transaction history for each Stellar account. Transaction analysis for each Asset.</p>
              <p>The trading and analytical platform Scopuly allows you to get all the data from the Stelar Network.</p>
            </Col>
          </Row>
          <Row className="landing__demo-wrap">
            <Col lg={8}>
              <Link className="landing__demo" to="/asset/SKY-GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA">
                <div className="landing__demo-img-wrap">
                  <img src={theme.className === 'theme-dark' ? analyticsDark : analytics} alt="" />
                </div>
              </Link>
            </Col>
            <Col lg={4} className="landing__demo-theme-toggle">
              <p className="landing__demo-title">View / Trade Asset</p>
              <DemoThemeToggle theme={theme} changeToLight={changeToLight} changeToDark={changeToDark} />
              <p>Analysis of all statistical data for each crypto asset allows you to make the right decisions in trading.</p>
              <p>Viewing transactions and payments related to a specific asset allows you to identify the manipulation of rates.</p>
            </Col>
          </Row>
          <Row className="landing__demo-wrap">
            <Col lg={8}>
              <Link className="landing__demo" to="/exchange">
                <div className="landing__demo-img-wrap">
                  <img src={theme.className === 'theme-dark' ? fitnessDark : fitness} alt="" />
                </div>
              </Link>
            </Col>
            <Col lg={4} className="landing__demo-theme-toggle">
              <p className="landing__demo-title">Exchange of assets</p>
              <DemoThemeToggle theme={theme} changeToLight={changeToLight} changeToDark={changeToDark} />
              <p>The exchange of assets is not only paired with the base currency XLM, but also an exchange in a pair of asset / asset.</p>
              <p>Ability to exchange some tokens for others directly (SKY / ETH), without resorting to the use of a currency bridge.</p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Demos;
