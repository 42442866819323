import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Badge, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { numFormat, shortAddr } from '../../../../modules/index';



class StellarAssets extends PureComponent {

  renderAssets(item, index) {

  	return (
  	  <tr key={index}>
  	    <td>{index+1}</td>
  	    {/*<td>
  	      <Link className="text-warning" to={`/asset/${item.asset_code}-${item.asset_issuer}`}>
  	      	<img className="asset-logo" src={item.image} alt="value" /><b>{item.asset_code}</b>
  	      </Link>
  	    </td>*/}
  	    <td><Link to={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}><b className="text-warning">{item.asset_code}</b></Link></td>
  	    <td><Link to={`/account/${item.asset_issuer}`}><b>{shortAddr(item.asset_issuer, 4)}</b></Link></td>
  	    <td><b className="text-info">{numFormat(item.num_accounts)}</b></td>
  	    <td><a href={`https://${item.home_domain}`} target='_blank' rel='noopener noreferrer'><b>{item.home_domain}</b></a></td>
  	    <td><b className="text-info">{numFormat(item.amount, 0)}</b></td>
  	    {/*<td>
  	    	Auth_immutable: <font className="text-info">{item.flags.auth_immutable ? 'true' : 'false'}</font><br/>
  	    	Auth_required: <font className="text-info">{item.flags.auth_required ? 'true' : 'false'}</font><br/>
  	    	Auth_revocable: <font className="text-info">{item.flags.auth_revocable ? 'true' : 'false'}</font><br/>
  	    </td>*/}
  	  </tr>
  	);
  }



  render() {
  	return (
  		<Table responsive className="table--bordered">
  		  <thead>
  		    <tr>
  		      <th>#</th>
  		      <th>Asset Code</th>
  		      <th>Asset Issuer</th>
  		      <th>Holders</th>
  		      <th>home_domain</th>
  		      <th>Supply</th>
  		      {/*<th>Flags</th>*/}
  		      {/*<th>name</th>*/}
  		      {/*<th>image</th>*/}
  		    </tr>
  		  </thead>
  		  <tbody>

  		    { this.props.assets ? this.props.assets.map(this.renderAssets) : null }

  		  </tbody>
  		</Table>
  	)
  }
}


StellarAssets.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(StellarAssets);

