import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Link } from 'react-router-dom';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import PropTypes from 'prop-types';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';

class Signup extends PureComponent {

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loader: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }


  signUpSubmit = (values) => {
    console.log('values: ', values)

    if (!values.reg_email) {
      ToastStore.warning(`Enter your email`);
    }
    else if (!values.reg_password) {
      ToastStore.warning(`Enter your Password`);
    }
    else {

      this.setState({ loader: true })

      const formData = new FormData();
      formData.append('email', values.reg_email)
      formData.append('pass', values.reg_password)

      // post - check user
      axios.post(GLOBE.API_URL+`/reg_user`, formData)
        .then(response => {
          console.log('response: ', response)

          this.setState({ loader: false })

          if (response.data === 'success_reg') {

            const user = {
              email: values.email,
              user_id: response.data,
            }

            localStorage.setItem('auth_user', JSON.stringify(user));

            ToastStore.success(`Account successfully created and authorized!`);
          }
          else if (response.data === 'isset') {
            ToastStore.warning(`A user with this email already exists.`);
          }
      });

      
    }
  }



  render() {
    const { handleSubmit } = this.props;

    return (

      <div>
        {this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null}

        <div className="account">

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

          <div className="account__wrapper">

            <div className="account__card">
              <div className="account__head">
                <h3 className="account__title">Welcome to
                  <span className="account__logo"> Scopuly
                    {/*<span className="account__logo-accent">DEV</span>*/}
                  </span>
                </h3>
                <h4 className="account__subhead subhead">Create an Account</h4>
              </div>
              

              <form className="form" onSubmit={handleSubmit(this.signUpSubmit)}>
                <div className="form__form-group">
                  <span className="form__form-group-label">E-mail</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <MailRuIcon />
                    </div>
                    <Field
                      name="reg_email"
                      component="input"
                      type="email"
                      placeholder="example@mail.com"
                    />
                  </div>
                </div>
                <div className="form__form-group form__form-group--forgot">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <KeyVariantIcon />
                    </div>
                    <Field
                      name="reg_password"
                      component="input"
                      type={this.state.showPassword ? 'text' : 'password'}
                      placeholder="Password"
                    />
                    <button
                      className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                      onClick={e => this.showPassword(e)}
                    ><EyeIcon />
                    </button>
                  </div>
                </div>
                <div className="account__btns">
                  <Button className="account__btn" color="primary" type="submit">Sign Up</Button>
                </div>
              </form>


              <div className="account__have-account">
                <p>Already have an account? <Link to="/login">Login</Link></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'register_form', // a unique identifier for this form
})(Signup);
