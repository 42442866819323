import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import iconIdent from '../Account/components/iconIdent';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';
import { shortAddr } from '../../../modules/index';


class Success extends PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    getTitle(`Information`);
  }


  render() {

    const params = Object.fromEntries(new URLSearchParams(window.location.search))

    const style = {
      width: '100%',
  		margin: '20px auto',
  		textAlign: 'center',
  		color: '#444444',
      img: {
        width: '10%'
      }
    };

    return (
      <Container>

        <Row>
          <Col md={12}>
            <div style={style}>
              <br/><br/>
              <img style={style.img} src="https://scopuly.com/img/logo/icon-transtparent-512.png"  />
              <br/><br/><br/><br/>
              <h1>Successful payment!</h1>
              <br/>
              <h3>
                <b className="text-info">{params.amount_sell_asset}</b> <b className="text-warning">{params.sell_asset}</b>&emsp;
                <i className="fa fa-long-arrow-right"></i>&emsp;
                <b className="text-info">{params.amount_buy_asset}</b> <b className="text-warning">{params.buy_asset}</b></h3>
              <br/><br/>
              <h2>Expect <b className="text-info">{params.amount_buy_asset}</b> <b className="text-warning">{params.buy_asset}</b> to your Account:</h2>
              <br/>
              <h1><Link to={`/account/${params.address}`}>{iconIdent(params.address, 'icon-indent-xl')} <b>{shortAddr(params.address, 4)}</b></Link></h1>
            </div>
          </Col>
        </Row>

        <br/><br/><br/><br/><br/><br/><br/>

        <Footer />

      </Container>
    )
  }
}


export default translate('common')(Success);
