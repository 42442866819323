import React, { PureComponent } from 'react';
import {Col, Container, Row, Card, CardBody, Table, Badge, ButtonGroup, Button, Popover, PopoverBody} from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortAddr, numFormat, get_loc_wallets } from '../../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import iconIdent from "../../Account/components/iconIdent";
import AcceptAsset from "../../Assets/components/AcceptAsset";
import '../../../../scss/containers/trade.scss'
import StellarSdk from "stellar-sdk";
import { Server } from '../../../../modules/stellar/index';



class ItemCard extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      asset: this.props.asset,
      wallets: get_loc_wallets(),
      show_accept_modal: false,
    };
  }

  componentDidMount() {
    console.log('componentDidMount .props: ', this.props)

    this.getPriceAsset(this.props.asset)
  }

  getPriceAsset = (asset) => {

    console.log('asset: ', asset)

    const base = new StellarSdk.Asset('QADSAN', 'GAKGQRKHKIL5DMOG45JVKATVHUFXJGJBM6EIG5STOS2JFFKD5TVQKZSY')
    const counter = new StellarSdk.Asset(asset.asset_code, asset.asset_issuer)

    const startTime = 0;
    const endTime = 0;
    const resolution = 86400000;
    const offset = 0;

    Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
      .limit(7)
      .call()
      .then((result) => {
        console.log('message tradeAggregation: ', result);
      })
      .catch((err) => {
        console.log('tradeAggregation', err);
      })
  }


  toggleAcceptModal = (value) => {
    console.log('vaue: ', value)

    if (value) {
      this.setState({ show_accept_modal: true, })
      return
    }
    setTimeout(() => this.setState({ show_accept_modal: !this.state.show_accept_modal }), 1000)
  }


  handleClick = (item) => {
    this.setState({ asset: item }, () => {
      this.toggleAcceptModal()
    })
  }



  render() {

    console.log('state: ', this.state.asset)

    return (
      <Col md={3}>
        <Card>
          <CardBody>

            <div className="card__title" style={{marginBottom: 19}}>
              <h5 className="bold-text">{this.state.asset.asset_code}</h5>
            </div>

            <div className="profile__activity" style={{padding: '0 0 12px'}}>
              <div className="profile__activity-avatar">
                <img className="icon-asset" src={this.state.asset.image} alt="" />
              </div>
              <div>
                <h4 className="profile__activity-code">{this.state.asset.asset_code}
                  <div className="profile__activity-date" style={{margin: '-8px 0 4px 0', display: this.state.asset.asset_code.length > 7 ? 'block' : 'inline'}}>&nbsp; {this.state.asset.name}</div>
                </h4>
                <Link className="text-secondary"
                      to={`/account/${this.state.asset.asset_issuer}`}
                      style={{fontSize: 11}}>{iconIdent(this.state.asset.asset_issuer, 'icon-indent-sm')}{' '}
                  {shortAddr(this.state.asset.asset_issuer, 8)}&nbsp;&nbsp;
                </Link>
              </div>
              <div>
                <button className="btn btn-outline-secondary btn-sm accept-btn"
                        onClick={() => this.handleClick(this.state.asset)}
                        disabled={this.state.asset.asset_code ? false : true}
                ><i className="fa fa-plus"></i></button>
              </div>
            </div>

            <Table className="table--bordered asset-info" responsive>
              <tbody>
              {/*<tr>*/}
              {/*  <td>PRICE {'XLM'} </td>*/}
              {/*  <td>*/}
              {/*    <b className={`text-info`}>{numFormat(item.price, 7)}</b>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              <tr>
                <td>PRICE $</td>
                <td>
                  {/*base_xlm_price_for_cap*/}
                  <span className="text-secondary">$<b>{numFormat(this.state.asset.price, 5)}</b></span>
                </td>
              </tr>
              <tr>
                <td>CHANGE 24</td>
                <td><b className="text-info"><Badge color={`badge badge-${this.state.asset.change_24h_color}`}>{this.state.asset.change24}%</Badge></b></td>
              </tr>
              <tr>
                <td>VOL24 {'XLM'}</td>
                <td>
                  <b className="text-info">{numFormat(this.state.asset.base_volume)}</b>
                </td>
              </tr>
              <tr>
                <td>VOL24 USD</td>
                <td>
                  <b className="text-secondary">${numFormat(9999999)}</b>
                </td>
              </tr>
              <tr>
                <td>SUPPLY</td>
                <td><b className="text-info">{numFormat(this.state.asset.supply, 0)}</b></td>
              </tr>
              <tr>
                <td>MARKETCAP</td>
                <td>&asymp; <b className="text-info">${numFormat(66666666)}</b></td>
              </tr>
              {/*<tr>
                        <td>TOTAL VOLUME <small>(XLM)</small></td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.overall_trade_volume, 0)}</b></td>
                      </tr>
                      <tr>
                        <td>TRADES COUNT</td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.total_trades_count, 0)}</b></td>
                      </tr>*/}

              <tr>
                <td>HOLDERS</td>
                <td><b className="text-info">{numFormat(this.state.asset.holders, 0)}</b></td>
              </tr>
              <tr>
                <td>DOMAIN</td>
                <td>
                  {
                    true ?
                      <b className={'isssuer-domain'}>
                        <a className="text-info"
                           href={`https://${'https://qadsan.com'}`}
                           target='_blank' rel='noopener noreferrer'
                           style={{float: 'right'}}
                        >{'qadsan.com'}</a>
                      </b> : '...'
                  }
                </td>
              </tr>
              </tbody>
            </Table>

            <div>
              <br />

              <ButtonGroup className="btn-group--justified">
                <Link to={`/trade/${this.state.asset.asset_code}-QADSAN/${this.state.asset.asset_issuer}/GAKGQRKHKIL5DMOG45JVKATVHUFXJGJBM6EIG5STOS2JFFKD5TVQKZSY`} className={'btn btn-secondary'}>Trade <b>{this.state.asset.asset_code}</b></Link>
              </ButtonGroup>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}


ItemCard.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(ItemCard);
