import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../Account/components/iconIdent';
import {numFormat, formatDate, getAsset, shortAddr, shortAddress} from '../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";



class Trustlines extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // activeTab: '1',
    };
  }


  renderItem = (item, index) => {
    // console.log('item: ', item)

    const asset = item.assets[0].slice(0, -2).replace("-", ":")

    return (
      <tr key={index}>
        <td><small>{index+1}</small></td>
        <td>
          <b>{shortAddress(item.accounts[0])}</b> {' '}
          <CopyToClipboard text={item.accounts[0]} onCopy={() => {}}>
            <i className="fa fa-clone"
               color="secondary"
               style={{cursor: 'pointer', fontSize: 14}}
               onClick={() => ToastStore.success('Copied!')}></i>
          </CopyToClipboard>
        </td>
        <td>
          {
            item.amount > 0 ?
              <span><i className="fa fa-check text-success"></i>&nbsp;</span>
              :
              <span><i className="fa fa-trash-o text-danger"></i>&nbsp;</span>
          } {' '}
          {/*<b className='text-secondary'>{item.amount > 0 ? 'created' : 'removed'}</b>*/}
          {item.amount > 0 ? <b className={'text-success'}>added</b> : <b className={'text-danger'}>removed</b>} trustline to {getAsset(asset)}
        </td>
        <td> <b className="text-info">{numFormat(item.amount, 7)}</b></td>
        <td>{ formatDate(item.ts * 1000) }</td>
        <td><b><a href={`/operation/${item.id}`}>{ shortAddr(item.id, 4) }</a></b></td>
      </tr>
    )
  }


  render() {
    const items = this.props.items;

    return (
      <Table striped responsive>
        <thead>
        <tr>
          <th>#</th>
          <th>Address</th>
          <th>Action</th>
          <th>Limit</th>
          <th>Date</th>
          <th>Operation</th>
        </tr>
        </thead>
        <tbody>

        { items.map(this.renderItem) }

        </tbody>
      </Table>
    )
  }
}


export default translate('common')(Trustlines);
