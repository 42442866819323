import {
  CHANGE_BORDER_RADIUS,
  TOGGLE_BOX_SHADOW,
  TOGGLE_TOP_NAVIGATION,
  TOGGLE_SHOW_NOTIFICATION,
  ADD_NOTIFICATION,
  TOGGLE_AUTH_PROVIDER,
} from '../actions/customizerActions';

const isShowNotification = localStorage.getItem('is-show-notif');

const initialState = {
  squaredCorners: false,
  withBoxShadow: false,
  topNavigation: false,
  showNotification: isShowNotification > 0 ? true : false,
  notifications: [],
  isAuthProvider: false,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case CHANGE_BORDER_RADIUS:
      return { ...state, squaredCorners: !state.squaredCorners };
    case TOGGLE_BOX_SHADOW:
      return { ...state, withBoxShadow: !state.withBoxShadow };
    case TOGGLE_AUTH_PROVIDER:
      return { ...state, isAuthProvider: action.payload };
    case TOGGLE_SHOW_NOTIFICATION:
      return { ...state, showNotification: !state.showNotification };
    case ADD_NOTIFICATION:
      return { ...state, notifications: action.payload };
    case TOGGLE_TOP_NAVIGATION:
      return { ...state, topNavigation: !state.topNavigation };
    default:
      return state;
  }
}
