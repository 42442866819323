import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, ToastStore } from 'react-toasts';
import {getAPY, getFloatValue, getStellarFee, numFormat, renderTextField, getAsset} from '../../../../modules/index';
import MenuItem from "@material-ui/core/MenuItem";
import LoadingIcon from "mdi-react/LoadingIcon";
import ReactApexChart from "react-apexcharts";



class Calculator extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      asset: 'usd',
      default_amount: 1000,
      amount: 1000,
      term: '1y',
      type: 'pool',
      price: 1, // current
      apy: 0,

      year: 365,
      earn_chart: [],

      reward_rate: 0,
      all_rate: 0,
      monthly_earn: 0,
      yearly_earn: 0,

      scop: this.props.asset,
      xlm_usd: this.props.price,

      series_volume: [],
      options_volume: {},
      terms: [],
      convert_amount: 0,
    };
  }

  fee = getStellarFee()
  apy_hold = getAPY('holder')
  apy_pool = getAPY('pool')


  componentDidMount() {

    this.setState({

      terms: [
        // { title: '7 days', value: '7d' },
        { title: '30 days', value: '30d' },
        { title: '90 days', value: '90d' },
        { title: '180 days', value: '180d' },
        { title: '1 year', value: '1y' },
        { title: '3 year', value: '3y' },
        { title: '5 year', value: '5y' },
        { title: '10 years', value: '10y' },
      ],

      types: [
        { title: <span>Holder <span className={'text-info'}>+10%</span> APY</span>, value: 'hold' },
        { title: <span>Liquidity <span className={'text-info'}>+14%</span> APY</span>, value: 'pool' },
      ],

      prices: [
        { title: 'Super Bearish (-50%)', value: '0.5' },
        { title: 'Bearish (-25%)', value: '0.75' },
        { title: 'Current', value: '1' },
        { title: 'Bullish (+50%)', value: '1.5' },
        { title: 'Very Bullish (+100%)', value: '2' },
        { title: 'Super Bullish (+500%)', value: '6' },
        { title: 'Moon (+1000%)', value: '11' },
      ],
    }, () => {

      this.props.change('asset', this.state.asset)
      this.props.change('amount', this.state.amount)
      this.props.change('term', this.state.term)
      this.props.change('type', this.state.type)
      this.props.change('price', this.state.price)
    })

    this.calculateInput()

  }


  calculateInput = () => {

    const { year, scop, xlm_usd, asset, amount, term, type, price } = this.state

    let apy = type === 'pool' ? this.apy_pool : this.apy_hold
    let earn_model = [], volume = [], labels = [], count = 0, monthly_earning = 0, yearly_earning = 0, total_amount = 0, total_rate = 0, earned_amount
    let days = year
    let amount_asset = Number(amount)
    let time = new Date()
    let earn_day, convert_amount

    if (asset === 'scop') convert_amount = amount
    else convert_amount = (amount / xlm_usd / scop.price)

    if (term === '30d') days = (30)
    if (term === '90d') days = (90)
    if (term === '180d') days = (180)
    if (term === '1y') days = (year)
    if (term === '3y') days = (year * 3)
    if (term === '5y') days = (year * 5)
    if (term === '10y') days = (year * 10)

    for (let i = 0; i < days; i++) {

      count++

      if (term === '30d') earn_day = ((amount_asset * apy / 100) / days / 12)
      if (term === '90d') earn_day = ((amount_asset * apy / 100) / days / 4)
      if (term === '180d') earn_day = ((amount_asset * apy / 100) / days / 2)
      if (term === '1y') earn_day = ((amount_asset * apy / 100) / days)
      if (term === '3y') earn_day = (((amount_asset * apy / 100) / days) * 3)
      if (term === '5y') earn_day = (((amount_asset * apy / 100) / days) * 5)
      if (term === '10y') earn_day = (((amount_asset * apy / 100) / days) * 10)

      amount_asset = Number((amount_asset + earn_day).toFixed(7))
      let diff = (amount_asset - amount).toFixed(2)

      earn_model.push({
        earn_day,
        amount_asset,
      })

      const count_term = (term === '30d' ? (count) : term === '90d' ? 2 : term === '180d' ? (3) : term === '1y' ? 5 : term === '3y' ? (5*3) : term === '5y' ? (5*5) : term === '10y' ? (5*10) : 5)
      if (count === count_term) {

        volume.push(diff)

        time.setDate(time.getDate() + count_term)
        labels.push(new Date(time.getTime()).toDateString())

        count = 0
      }

      if (i+1 === days) {

        total_amount = amount_asset
        total_rate = (diff / amount * 100) * price
        earned_amount = Number((total_amount - amount) * price)
        monthly_earning = (term === '30d' ? (earned_amount) : term === '90d' ? (earned_amount / 3) : term === '180d' ? (earned_amount / 6) : term === '1y' ? (earned_amount / 12) : term === '3y' ? (earned_amount / (12*3)) : term === '5y' ? (earned_amount / (12*5)) : term === '10y' ? (earned_amount / (12*10)) : 0)
        yearly_earning = (term === '30d' ? (earned_amount * 12) : term === '90d' ? (earned_amount * 4) : term === '180d' ? (earned_amount * 2) : term === '1y' ? (earned_amount) : term === '3y' ? (earned_amount / 3) : term === '5y' ? (earned_amount / 5) : term === '10y' ? (earned_amount / 10) : 0)
        total_amount = (amount + earned_amount)
      }
    }

    const series_volume = [{
        name: 'Earned',
        type: 'column',
        data: volume,
      }]


    const earned_scop = Number((earned_amount / price) / xlm_usd / scop.price)
    const monthly_scop = ((monthly_earning / price) / xlm_usd / scop.price)
    const yearly_scop = ((yearly_earning / price) / xlm_usd / scop.price)
    const total_scop = asset === 'usd' ? (convert_amount + earned_scop) : (total_amount / xlm_usd / scop.price)

    this.setState({
      apy,
      total_amount,
      earned_amount,
      monthly_earning,
      yearly_earning,
      total_rate,
      series_volume,
      options_volume: this.setOptions(series_volume, labels),
      earned_scop,
      monthly_scop,
      yearly_scop,
      total_scop,
      convert_amount,
    })
  }


  changeAsset = (value) => {

    const { default_amount, amount, xlm_usd, scop } = this.state
    const asset = getFloatValue(value)

    this.setState({ asset },
      () => {

      if (asset === 'scop') {
        const amount_scop = Number((amount / xlm_usd / scop.price).toFixed(2))
        this.props.change('amount', amount_scop)
      }
      else if (asset === 'usd') {
        this.props.change('amount', default_amount)
        this.setState({ amount: default_amount })
      }

      setTimeout(() => {
        this.calculateInput()
      },100)
    })
  }

  changeAmount = (value) => {

    const { asset, scop, xlm_usd } = this.state

    let amount = Number(getFloatValue(value))
    // let convert_amount = 0
    //
    if (asset === 'scop') {
      amount = Number(amount * scop.price * xlm_usd)
      // convert_amount = amount
    }
    // else {
    //   convert_amount = (amount / xlm_usd / scop.price)
    // }

    this.setState({
      amount,
      // convert_amount
    }, () => {
      this.calculateInput()
    })
  }

  changeTerm = (value) => {
    this.setState({ term: getFloatValue(value) }, () => {
      this.calculateInput()
    })
  }

  changeType = (value) => {
    this.setState({ type: getFloatValue(value) }, () => {
      this.calculateInput()
    })
  }

  changePrice = (value) => {
    this.setState({ price: getFloatValue(value) }, () => {
      this.calculateInput()
    })
  }


  setOptions = (series, labels, decimels = 2) => {

    const theme_loc = localStorage.getItem('theme')
    const theme = theme_loc === 'theme-dark' ? 'dark' : 'light'

    const options = {
      chart: {
        // height: 350,
        // type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        }
      },
      colors:['#03A9F4', '#13D8AA'],
      stroke: {
        width: [0, 2, 5],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      },

      grid: {
        show: true,
        borderColor: '#64677726', //#2a2a30
        row: {
          colors: undefined,
          opacity: 0.5
        },
        column: {
          colors: undefined,
          opacity: 0.5
        },
      },

      legend: { show: false },

      theme: {
        mode: theme,
        palette: 'palette1',
        monochrome: {
          enabled: false,
          color: '#255aee',
          // shadeTo: 'light',
          shadeIntensity: 0.65
        },
      },

      fill: {
        opacity: [0.85, 0.25, 1],
        // type: "gradient",
        gradient: {
          inverseColors: false,
          // shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }

      },
      labels: labels // ['01/01/2003', '02/01/2003', ...]
      ,
      markers: {
        size: 0
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        title: {
          text: '',
          style: {
            color: '#a5a5a5',
          },
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        fillSeriesColor: false,
        theme: true,
        y: {
          formatter: function (y, x) {

            if (typeof y !== "undefined") {

              if (series[0].name === 'APY') {
                return `${numFormat(y, decimels)}%`
              }
              else {
                return `$${numFormat(y, decimels)}`
              }
            }
            return y;

          }
        }
      },

    }

    return options
  }




  render() {

    // console.log('state: ', this.state)
    // console.log('props: ', this.props)

    const { handleSubmit, reset } = this.props
    const { terms, types, prices, series_volume, options_volume, total_amount, earned_amount, total_rate, apy, term, amount, xlm_usd,
      monthly_earning, yearly_earning, earned_scop, monthly_scop, yearly_scop, total_scop, scop, convert_amount, asset } = this.state

    const scop_icon = 'https://scopuly.com/img/logo/icon.png'
    const usd_icon = 'https://www.centre.io/images/usdc/usdc-icon-86074d9d49.png'
    const term_title = terms.length ? terms.filter(item => item.value === term)[0].title : '...'

    return (
      <div>
        <div className={'text-center'}>
          <br/>
          <h4>Calculate how much you can earn by staking {getAsset(scop, scop.image, 'secondary')}. <br/> Results vary based on the staking amount, term, and type selected.</h4>
        </div>
        <br/><br/><br/>
        <form className="material-form" onSubmit={handleSubmit}>
          <Row>
            <Col md={{size: 12}} lg={{size: 2, offset: 1}}>

              <div>
                <Field
                  name="asset"
                  component={renderTextField}
                  label="Asset"
                  select
                  onChange={this.changeAsset}
                >
                  <MenuItem value={'scop'} className="material-form__option"><span className={'text-secondary'}>
                    <img
                      src={scop_icon}
                      className={'calc-select-asset-icon'}
                      alt=""/> {' '}
                    <b>SCOP</b></span>
                  </MenuItem>
                  <MenuItem value={'usd'} className="material-form__option"><span className={'text-secondary'}>
                    <img
                      src={usd_icon}
                      className={'calc-select-asset-icon'}
                      alt=""/> {' '}
                    <b>USDC</b></span>
                  </MenuItem>
                </Field>
              </div>


              <div className={'calc'} style={{position: 'relative'}}>
                <Field
                  name="amount"
                  component={renderTextField}
                  type="text"
                  label="Amount"
                  onChange={this.changeAmount}
                />
                <small className={'material-label text-secondary'}>≈ {numFormat(convert_amount)} {' '}
                  <img src={asset === 'usd' ? scop_icon : usd_icon}
                       className={'asset-icon-xs'}
                       alt=""/>
                </small>
              </div>

              <div>
                <Field
                  name="term"
                  component={renderTextField}
                  label="Term"
                  select
                  onChange={this.changeTerm}
                >

                  {
                    terms ? terms.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                            className="material-form__option">
                            <span className={'text-secondary'}><b>{item.title}</b></span>
                          </MenuItem>
                        )
                      })
                      : null
                  }
                </Field>
              </div>

              <div>
                <Field
                  name="type"
                  component={renderTextField}
                  label="Type"
                  select
                  onChange={this.changeType}
                >

                  {
                    types ? types.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                            className="material-form__option">
                            <span className={'text-secondary'}><b>{item.title}</b></span>
                          </MenuItem>
                        )
                      })
                      : null
                  }
                </Field>
              </div>

              <div>
                <Field
                  name="price"
                  component={renderTextField}
                  label="Price"
                  select
                  onChange={this.changePrice}
                >

                  {
                    prices ? prices.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={item.value}
                            className="material-form__option">
                            <span className={'text-secondary'}><b>{item.title}</b></span>
                          </MenuItem>
                        )
                      })
                      : null
                  }
                </Field>
              </div>

            </Col>

            <Col md={8} lg={6}>
              {/*<img src="https://static.anychart.com/images/gallery/v8/column-charts-column-chart.png" alt=""/>*/}

              {
                series_volume.length ?
                  <ReactApexChart options={options_volume} series={series_volume} type="line" height={330} />
                  : null
              }
            </Col>

            <Col md={{size: 3}} lg={{size: 2}}>

              <p><b>Earned amount:</b> {' '}
                <div className={'fs-28 mt-min-6 text-info'}><b>${numFormat(earned_amount)}</b> {' '}
                  <small className={'fs-12'}><span className={'text-secondary'}>for</span> <b>{term_title}</b></small>
                </div>
                <div className={'mt-min-10 text-secondary'}><small><b>{numFormat(earned_scop)}</b> SCOP / <b>{numFormat(earned_amount / xlm_usd)}</b> XLM</small></div>
              </p>
              <p><b>Total reward rate:</b> {' '}
                <div className={'fs-24 mt-min-6 text-success'}><b>+{numFormat(total_rate, 2)}%</b></div>
                <div className={'mt-min-10 text-secondary'}><small>Total rate for <b className={'text-info'}>{term_title}</b></small></div>
              </p>
              <p>Monthly rewards: {' '}
                <div className={'fs-16 mt-min-6 text-info'}><b>${numFormat(monthly_earning, 2)}</b></div>
                <div className={'mt-min-10 text-secondary'}><small><b>{numFormat(monthly_scop)}</b> SCOP / <b>{numFormat(monthly_earning / xlm_usd)}</b> XLM</small></div>
              </p>
              <p>Yearly rewards: {' '}
                <div className={'fs-16 mt-min-6 text-info'}><b>${numFormat(yearly_earning, 2)}</b></div>
                <div className={'mt-min-10 text-secondary'}><small><b>{numFormat(yearly_scop)}</b> SCOP / <b>{numFormat(yearly_earning / xlm_usd)}</b> XLM</small></div>
              </p>
              <p>Total amount: {' '}
                <div className={'fs-16 mt-min-6 text-info'}><b>${numFormat(total_amount)}</b></div>
                <div className={'mt-min-10 text-secondary'}><small><b>{numFormat(total_scop)}</b> SCOP / <b>{numFormat(total_amount / xlm_usd)}</b> XLM</small></div>
              </p>

            </Col>
          </Row>
        </form>
        <br/>
        <p className={'text-center'}><i className={'fa fa-info-circle'}></i> Your assets belong only to you and are not blocked for a period, so they can be spent by you at any time.</p>
        <br/>
      </div>
    );
  }
}



export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(translate('common')(Calculator));
