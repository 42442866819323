import React, { PureComponent } from 'react';
import { Row, Col, Button, ButtonToolbar, Table, Card, CardBody, Modal, Badge } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../../modules/stellar/index';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import PropTypes from 'prop-types';
import { get_loc_wallets, shortAddr, nativeBalance, baseReserve, minBalance, getAuth, needDeauthAll, signTransaction } from '../../../../modules/index';
import Collapse from '../../../../shared/components/Collapse';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';



const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}




class RequiredSigners extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      wallets: get_loc_wallets(),
      sum_weight: 1,
      signers_arr: [],
      xdr: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {

    let threshold_num;
    switch (this.props.type_tx) {
      case 'low':
        threshold_num = this.props.account.thresholds.low_threshold;
        break;

      case 'med':
        threshold_num = this.props.account.thresholds.med_threshold;
        break;

      case 'high':
        threshold_num = this.props.account.thresholds.high_threshold;
        break;
      default:
        // ...
    };

    this.setState({
      threshold_num: threshold_num,
    })
  }


  toggleModal() {
    this.setState({
      modal: !this.state.modal,
    });
  }


  changeCheckbox(e, item, index) {

    if (e.target) {

      let sum_weight;
      if (e.target.checked) {

        sum_weight = (this.state.sum_weight + item.weight)
        this.state.signers_arr.push(item.key);
      }
      else {
        sum_weight = (this.state.sum_weight - item.weight)
        this.state.signers_arr.splice(item.key, 1);
      }

      this.setState({
        sum_weight: sum_weight,
        accept_singers: (this.state.threshold_num >= this.state.sum_weight ? true : false),
        signers_arr: this.state.signers_arr,
        xdr: false,
      })
    }
  }


  getXDR() {

    var transaction = signTransaction(this.state.signers_arr, this.props.transaction);
    let xdr = transaction.toEnvelope().toXDR('base64');
    // console.log('xdr: ', xdr);

    // var transaction = new StellarSdk.Transaction(xdr);
    // console.log('transaction from XDR: ', transaction); 

    this.setState({
      xdr: xdr,
    })
  }


  sendTransaction() {
    // console.log('signers_arr: ', this.state.signers_arr);

    var transaction = signTransaction(this.state.signers_arr, this.props.transaction);

    this.props.add_signers(transaction);
    this.toggleModal();
  }




  render() {
    const { handleSubmit } = this.props;

    // console.log('Required state: ', this.state)

    // if (!this.state.modal) {
    //   this.props.add_signers(false);
    // }

    return (

      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        className={`modal-dialog--primary modal-dialog--header`}
      >
        <div>

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 


          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text modal__title">Additional Signing Accounts Required</h4>
          </div>
          <div className="modal__body">

            <Collapse title={`All Signing Accounts for this Wallet (${this.props.account.signers.length})`} className="with-shadow text-secondary">
              {
                this.props.account.signers.map((item, index) => {
                  return (
                    <p key={index}>
                      <Link to={`/account/${item.key}`} className={this.props.account.id === item.key ? 'text-warning' : ''}><b>{shortAddr(item.key, 8)}</b> <small className="text-secondary">(weight: {item.weight}) {this.props.account.id === item.key ? 'Root Signer' : ''}</small></Link>
                    </p>
                  )
                })
              }
            </Collapse>

            <Card style={{paddingBottom: '0'}}>
              <CardBody className="text-secondary" style={{padding: '30px 0 0'}}>
                <div>
                  <div className="card__title">
                    <h5 className="bold-text">Additional Signing Accounts</h5>
                    <h5 className="subhead">Select Signers to allow this transaction or send them an XDR to sign.</h5>
                  </div>

                  <form className="form form--preview" onSubmit={handleSubmit}>
                    <div id="signers-box">

                      {
                        this.props.account.signers.map((item, index) => {

                          if (item.key !== this.props.account.id) {
                            let auth_addr = false;

                            this.state.wallets.map((item_wallet, index) => {
                              if (item_wallet.pk === item.key) {
                                auth_addr = true;
                              }
                            })

                            if (auth_addr) {
                              return (
                                <div className="form__form-group" key={index}>
                                  <div className="form__form-group-field">
                                    <Field
                                      name={`checkbox_${index}`}
                                      component={renderCheckBoxField}
                                      label={`${shortAddr(item.key, 4)} / weight: ${item.weight}`}
                                      className="colored"
                                      address={item.key}
                                      onChange={(e) => {
                                        this.changeCheckbox(e, item, index)
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            }
                          }
                        })
                      }
                    </div>
                  </form>
                  <hr/>
                  <div>Minimum threshold value: <Badge color={`${this.state.threshold_num === this.state.sum_weight ? 'success' : 'warning' }`}>{this.state.threshold_num}</Badge> of <Badge color={`${this.state.threshold_num === this.state.sum_weight ? 'success' : this.state.sum_weight > 1 ? 'warning' : 'secondary' }`}>{this.state.sum_weight}</Badge></div>
                  <hr/>
                  <div>
                    <Button color="success" 
                            type="submit" 
                            disabled={this.state.threshold_num === this.state.sum_weight ? false : true}
                            onClick={() => this.sendTransaction()}><i className={`fa fa-${this.props.icon}`}></i> {this.props.btn_name}</Button>
                    <Button color="primary" 
                            type="submit" 
                            disabled={this.state.threshold_num >= this.state.sum_weight ? false : true}
                            onClick={() => this.getXDR()}><i className="fa fa-code"></i> Get XDR</Button>
                  </div>
                </div>

                {
                  this.state.xdr ?
                    <div className="text-center">
                      <hr/>
                      <h3>XDR:</h3>
                      <div style={{wordWrap: 'break-word'}}>{this.state.xdr}</div>
                      <br/>
                      <CopyToClipboard text={this.state.xdr}
                        onCopy={() => {}}>
                        <Button color="secondary" onClick={() => ToastStore.success('Copied!')}>
                          <i className="fa fa-file"></i> Copy XDR
                        </Button>
                      </CopyToClipboard>
                    </div>
                  : null
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </Modal>
    );
  }
}

export default reduxForm({
  form: 'colored_controls, defaults_controls_colored_click', 
})(RequiredSigners);
