import React, { PureComponent } from 'react';
import {Col, Container, Row, Card, CardBody, Table, Badge, ButtonGroup, Button, Popover, PopoverBody} from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortAddr, numFormat, getNameAddr, getDirectory, getTitle, shortAddress, get_loc_wallets } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import moment from 'moment';
import { ToastContainer, ToastStore } from 'react-toasts';
import Operations from '../Account/components/Effects/Operations';
import Footer from '../../Layout/footer/Footer';
import {CopyToClipboard} from "react-copy-to-clipboard";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import iconIdent from "../Account/components/iconIdent";
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import getPrice from "../../App/GetPrice";
import AcceptAsset from "../Assets/components/AcceptAsset";
import '../../../scss/containers/trade.scss'
import ItemCard from './components/ItemCard'



class Qadsan extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      asset: false,
      wallets: get_loc_wallets(),
      show_accept_modal: false,
    };
  }

  componentDidMount() {
    this.getBasePrice()
    this.getAssetsFromDB()
  }


  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({
        price: price.crypto_price.xlm_usd,
        // changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success',
        // change: price.crypto_price.usd.percent_change_24h,
      });
    }
    else if (price && price.local_price) {
      this.setState({
        price: price.local_price.USD,
      })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  toggleAcceptModal = (value) => {
    console.log('vaue: ', value)

    if (value) {
      this.setState({ show_accept_modal: true, })
      return
    }
    setTimeout(() => this.setState({ show_accept_modal: !this.state.show_accept_modal }), 1000)
  }


  handleClick = (item) => {
    this.setState({ asset: item }, () => {
      this.toggleAcceptModal()
    })
  }


  assetCard = (item, index) => {
    console.log('iAssetCard tem: ', item)

    return (
      <Col key={index} md={3}>
        <Card>
          <CardBody>

            <div className="card__title" style={{marginBottom: 19}}>
              <h5 className="bold-text">{item.asset_code}</h5>
            </div>

            <div className="profile__activity" style={{padding: '0 0 12px'}}>
              <div className="profile__activity-avatar">
                <img className="icon-asset" src={item.image} alt="" />
              </div>
              <div>
                <h4 className="profile__activity-code">{item.asset_code}
                  <div className="profile__activity-date" style={{margin: '-8px 0 4px 0', display: item.asset_code.length > 7 ? 'block' : 'inline'}}>&nbsp; {item.name}</div>
                </h4>
                <Link className="text-secondary"
                      to={`/account/${item.asset_issuer}`}
                      style={{fontSize: 11}}>{iconIdent(item.asset_issuer, 'icon-indent-sm')}{' '}
                  {shortAddr(item.asset_issuer, 8)}&nbsp;&nbsp;
                </Link>
              </div>
              <div>
                <button className="btn btn-outline-secondary btn-sm accept-btn"
                        onClick={() => this.handleClick(item)}
                        disabled={item.asset_code ? false : true}
                ><i className="fa fa-plus"></i></button>
              </div>
            </div>

            <Table className="table--bordered asset-info" responsive>
              <tbody>
              {/*<tr>*/}
              {/*  <td>PRICE {'XLM'} </td>*/}
              {/*  <td>*/}
              {/*    <b className={`text-info`}>{numFormat(item.price, 7)}</b>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              <tr>
                <td>PRICE $</td>
                <td>
                  {/*base_xlm_price_for_cap*/}
                  <span className="text-secondary">$<b>{numFormat(item.price, 5)}</b></span>
                </td>
              </tr>
              <tr>
                <td>CHANGE 24</td>
                <td><b className="text-info"><Badge color={'badge badge-success'}>{item.change24}%</Badge></b></td>
              </tr>
              <tr>
                <td>VOL24 {'XLM'}</td>
                <td>
                  <b className="text-info">{numFormat(item.base_volume)}</b>
                </td>
              </tr>
              <tr>
                <td>VOL24 USD</td>
                <td>
                  <b className="text-secondary">${numFormat(9999999)}</b>
                </td>
              </tr>
              <tr>
                <td>SUPPLY</td>
                <td><small className="text-secondary">{item.asset_code}</small> <b className="text-info">{numFormat(item.supply, 0)}</b></td>
              </tr>
              <tr>
                <td>MARKETCAP</td>
                <td>&asymp; <b className="text-info">${numFormat(66666666)}</b></td>
              </tr>
              {/*<tr>
                        <td>TOTAL VOLUME <small>(XLM)</small></td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.overall_trade_volume, 0)}</b></td>
                      </tr>
                      <tr>
                        <td>TRADES COUNT</td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.total_trades_count, 0)}</b></td>
                      </tr>*/}

              <tr>
                <td>HOLDERS</td>
                <td><b className="text-info">{numFormat(item.holders, 0)}</b></td>
              </tr>
              <tr>
                <td>DOMAIN</td>
                <td>
                  {
                    true ?
                      <b className={'isssuer-domain'}>
                        <a className="text-info"
                           href={`https://${'https://qadsan.com'}`}
                           target='_blank' rel='noopener noreferrer'
                           style={{float: 'right'}}
                        >{'qadsan.com'}</a>
                      </b> : '...'
                  }
                </td>
              </tr>
              </tbody>
            </Table>

            <div>
              <br />

              <ButtonGroup className="btn-group--justified">
                <Link to={`/trade/${item.asset_code}-QADSAN/${item.asset_issuer}/GAKGQRKHKIL5DMOG45JVKATVHUFXJGJBM6EIG5STOS2JFFKD5TVQKZSY`} class={'btn btn-secondary'}>Trade {item.asset_code}</Link>
              </ButtonGroup>
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }

  assets = [
    {
      assetCode: 'ELGOOG',
      assetIssuer: 'GCXGEFMH3MAXCESR2E6TT2TEIVKJ6NO6B2OYDKUQF3FJGP35OBHXU2KU',
    },
    {
      assetCode: 'ASIV',
      assetIssuer: 'GDLA2ZKWK2ITJV5DE5WSSPVR43UAOTQUXIZCVQYRJL5WA2E6JLSGGGIS',
    },
    {
      assetCode: 'LAPYAP',
      assetIssuer: 'GARM47SEALFZXCN235Z7MWLBT7ST6TE7QYNYWE6ERQ4PNB52VJXTNQA4',
    },
    {
      assetCode: 'MARGELET',
      assetIssuer: 'GBVQD4AY2XHSEMGCX2QCXAGLSONCOHZJHSDBIMHVSF67PYBGIL4YZLX6',
    },
    {
      assetCode: 'EKIN',
      assetIssuer: 'GAAXV3E7MYFTZDREN2W4XKUWK4PUCXNQYB3P4AJUUIMFPYLWESWYAXIC',
    },
    {
      assetCode: 'KOOBECAF',
      assetIssuer: 'GCBUALEQ2QX3IYV7R7LN47YL7QHIEICQKQLX2HKDGOODYP5HEP7SYYYH',
    },
    {
      assetCode: 'TFOSORCIM',
      assetIssuer: 'GBXCVJRI4S4Y3MAN4LTSYJEPUNL6UUWLP7IZJNAZPNSJ4QZ4GQENZIEV',
    },
    {
      assetCode: 'ELPPA',
      assetIssuer: 'GDHIELFPBA3Q5M4WN32U67PBHNRUFRSADNVLBQ5W3F7VJPL6H62QBKGN',
    },
    {
      assetCode: 'ISPEP',
      assetIssuer: 'GCJXS3GGPPFU2F75J74GOHHQBSTNKSW74NR6ER7KDHKZXRRS6WC3NOX6',
    },
    {
      assetCode: 'ALSET',
      assetIssuer: 'GC6RHO5KWZFKDSFOOU2FTO7DTN4HUHZ5FWF2BLSHGOHHLO5NGJPWRGUB',
    },
    {
      assetCode: 'QADSAN',
      assetIssuer: 'GAKGQRKHKIL5DMOG45JVKATVHUFXJGJBM6EIG5STOS2JFFKD5TVQKZSY',
    }
  ]

  qadsan = {
    code: 'QADSAN',
    issuer: 'GAKGQRKHKIL5DMOG45JVKATVHUFXJGJBM6EIG5STOS2JFFKD5TVQKZSY',
  }


  getAssetsFromDB = () => {
    console.log('this.asssets: ', this.assets)

    axios.get(`${GLOBE.API_URL}/get_qadsan_assets` ,
      {
      params: {
        codes: this.assets.map(item => item.assetCode),
        issuers: this.assets.map(item => item.assetIssuer),
      }
    }
    )
      .then( (response) => {
        console.log('response: ', response);
        this.setState({
          assets: response.data
        })
      })
      .catch( (error) => {
        console.log(error);
      })
  }

  // fetchAssets = (item, index) => {
  //   console.log('item: ', item)
  //
  //   return <AssetCard item={item} />
  // }



  render() {

    console.log('state: ', this.state)

    return (
      <Container className="dashboard padding-cardbody-16 trade">

        {
          this.state.show_accept_modal &&
          <AcceptAsset
            asset={this.state.asset}
            toggleAcceptModal={this.toggleAcceptModal}
            wallets={this.state.wallets}/>
        }

        <Row>
          <Col md={12}>
            <h3 className="page-title">Qadsan</h3>
            <h3 className="page-subhead subhead">QADSAN работает без перебоев, 24 часа в сутки, 365 дней в году.</h3>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <img className="d-block w-100" src={'https://qadsan.com/wp-content/uploads/2021/05/tg_image_2487659914-3-2048x504.jpeg'} alt={'qadsan | scopuly'} />
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/*<Row>*/}
        {/*  <Col md={6}>*/}
        {/*    <Card>*/}
        {/*      <CardBody>*/}
        {/*        <div className="card__title" style={{marginBottom: 19}}>*/}
        {/*          <h5 className="bold-text">Title</h5>*/}
        {/*        </div>*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*  <Col md={6}>*/}
        {/*    <Card>*/}
        {/*      <CardBody>*/}
        {/*        <div className="card__title" style={{marginBottom: 19}}>*/}
        {/*          <h5 className="bold-text">Title</h5>*/}
        {/*        </div>*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*        asdfasdfasdfasdf asdf asdf asdfasdf asdf asdfas dfasdfasd fasdfas dfas dfasdfasd fasdfasdf as*/}
        {/*        df asdfasdfa sdfasdfasd fasdfas df asdf asdf asdfasdf asd*/}
        {/*      </CardBody>*/}
        {/*    </Card>*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row>
          {
            this.state.assets.length ?
              this.state.assets.map((item, index) => <ItemCard key={index} asset={item} />)
            : null // <AssetCard key={index} item={item} />
          }
        </Row>

        <Footer />

      </Container>
    );
  }
}


Qadsan.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Qadsan);
