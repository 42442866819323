import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Badge,
  Modal,
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import StellarSdk from "stellar-sdk";
import axios from "axios";
import {GLOBE} from "../../../modules/globeVars";
import {
  formatDate,
  get_loc_wallets, getBasePrice, getIconConnectProvider, getSigner, numFormat, randomAmount,
  shortAddr, shortAddress, shortTransaction, getAsset, showErrorMessage, getTitle, getAPY, animateElems
} from "../../../modules";
import swal from "sweetalert";
// import {buildTx, getLiquidityPool, getOrderbook,} from '../../../../endpoints/StellarAPI'
import {Server} from "../../../modules/stellar";
import BarLoader from "react-spinners/BarLoader";
import PacmanLoader from "react-spinners/PacmanLoader";
import {css} from "@emotion/core";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../Account/components/iconIdent";
import TextField from "@material-ui/core/TextField";
import SpinnerLoader from "../../../modules/SpinnerLoader";
import LoadingIcon from "mdi-react/LoadingIcon";
import Footer from "../../Layout/footer/Footer";
import Draggable from "react-draggable";
import classnames from "classnames";
import Calculator from "./components/Calculator";


const override = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 55px;
`;

const override_loader = css`
  border-color: #418fba;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 73px;
`;

const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Reward extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      payouts: [],
      address_payouts: [],

      all_payouts: '0.00',
      holder_payouts: '0.00',
      pool_payouts: '0.00',
      staking_payouts: '0.00',
      trader_payouts: '0.00',
      card_payouts: '0.00',
      referral_payouts: '0.00',

      all_payouts_addr: '0.00',
      holder_payouts_addr: '0.00',
      pool_payouts_addr: '0.00',
      staking_payouts_addr: '0.00',
      trader_payouts_addr: '0.00',
      card_payouts_addr: '0.00',
      referral_payouts_addr: '0.00',

      all_payouts_addr_usd: '0.00',
      holder_payouts_addr_usd: '0.00',
      pool_payouts_addr_usd: '0.00',
      staking_payouts_addr_usd: '0.00',
      trader_payouts_addr_usd: '0.00',
      card_payouts_addr_usd: '0.00',
      referral_payouts_addr_usd: '0.00',

      all_payouts_xlm: '0.00',
      holder_payouts_xlm: '0.00',
      pool_payouts_xlm: '0.00',

      all_payouts_usd: '0.00',
      holder_payouts_usd: '0.00',
      pool_payouts_usd: '0.00',
      staking_payouts_usd: '0.00',
      trader_payouts_usd: '0.00',
      card_payouts_usd: '0.00',
      referral_payouts_usd: '0.00',

      load_more: true,
      select_address: '',
      is_all_payouts: true,

      payouts_type: 'address',
      activeTab: '1',
      modal: false,
      holder_reward: getAPY('holder'),
      pool_reward: getAPY('pool'),
      referral_reward: getAPY('referral'),
      partner_reward: getAPY('partner'),
      asset: {
        asset_code: 'SCOP',
        asset_issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
        image: 'https://scopuly.com/img/logo/icon.png'
      },
      min_balance: 500,
    };
  }


  limit = 30
  page = 0
  page_address = 0



  componentDidMount() {

    getTitle(`SCOP Rewards. Earn up to 20% APR on SCOP token.`);

    this.getXLMPrice()
    animateElems()

    // setTimeout(() => {
    //   window.scrollBy({
    //     top: 500,
    //     behavior: 'smooth'
    //   });
    // }, 1000)
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
          stellar,
          base_price: stellar.market_data.current_price.usd
        },
        () => {
          this.getPayoutsData()
        })
    }
  }


  getPayoutsData = () => {

    const { base_price } = this.state

    axios.post(`${GLOBE.API_URL}/get_stat_rewards`).then(result => {
      // console.log('getPayoutsData result: ', result)

      if (result.data) {

        const asset = result.data.asset
        const payouts = result.data.payouts
        const all_payouts = result.data.all_payouts
        const holder_payouts = result.data.holder_payouts
        const pool_payouts = result.data.pool_payouts
        const partner_payouts = result.data.partner_payouts
        const referral_payouts = result.data.referral_payouts
        const all_referral_payouts = result.data.all_referral_payouts

        const all_payouts_xlm = (all_payouts * asset.price)
        const holder_payouts_xlm = (holder_payouts * asset.price)
        const pool_payouts_xlm = (pool_payouts * asset.price)
        const all_referral_payouts_xlm = (all_referral_payouts * asset.price)

        this.setState({
          asset,
          payouts,
          all_payouts,
          holder_payouts,
          pool_payouts,
          all_payouts_xlm,
          holder_payouts_xlm,
          pool_payouts_xlm,
          all_referral_payouts_xlm,
          all_payouts_usd: (all_payouts_xlm * base_price).toFixed(7),
          holder_payouts_usd: (holder_payouts_xlm * base_price).toFixed(7),
          pool_payouts_usd: (pool_payouts_xlm * base_price).toFixed(7),
          all_referral_payouts_usd: (all_referral_payouts_xlm * base_price).toFixed(7),
          partner_payouts,
          referral_payouts,
          all_referral_payouts,
        })

        const address = this.props.match.params.id

        if (address) {

          getTitle(`SCOP payouts for address: ${address}`);

          this.props.change('search', address)

          this.setState({
            select_address: address,
            is_all_payouts: false,
            address_payouts: [],
          }, () => {
            this.getStatForAddress()
            this.getPayoutsForAddress()
            this.scrollPage()
          })
        }
      }
    });
  }


  submitSearchForm = () => {

  }


  scrollPage = () => {
    window.scrollBy({
      top: 1450,
      behavior: 'smooth'
    });
  }


  changeAddress = (value) =>{

    let select_address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        select_address = (select_address + value[key])
      }
      else {

        this.setState({
          select_address,
          is_all_payouts: false,
          address_payouts: [],
        }, () => {

          this.page = 0
          // this.getPayouts('address')
          this.props.change('search', select_address)
          this.getStatForAddress()
          this.getPayoutsForAddress()
          this.changeUrl(select_address)
          this.payoutsType('address')
        })
      }
    }
  }


  searchChange = (e) => {

    if (e.target.value.length === 56) {

      const select_address = e.target.value

      this.setState({
        select_address,
        is_all_payouts: false,
        address_payouts: [],
      }, () => {

        // this.page = 0
        this.getStatForAddress()
        this.getPayoutsForAddress()
        this.changeUrl(select_address)
      })
    }
  }


  changeUrl = (address) => {

    if (address) {
      this.props.history.push({ pathname: `/rewards/${address}`, });
      this.scrollPage()
    }
    else {
      this.props.history.push({ pathname: `/rewards`, });
    }
  }


  getStatForAddress = () => {

    const { select_address, asset, base_price } = this.state

    const formData = new FormData();
    formData.append('address', select_address)

    axios.post(`${GLOBE.API_URL}/get_stat_rewards_for_address`, formData).then(result => {
      // console.log('get_stat_rewards_for_address result: ', result)

      if (result.data) {

        const all_payouts = result.data.all_payouts
        const holder_payouts = result.data.holder_payouts
        const pool_payouts = result.data.pool_payouts
        const referral_payouts = result.data.referral_payouts

        const all_payouts_xlm = (all_payouts * asset.price)
        const holder_payouts_xlm = (holder_payouts * asset.price)
        const pool_payouts_xlm = (pool_payouts * asset.price)
        const referral_payouts_xlm = (referral_payouts * asset.price)

        this.setState({
          all_payouts_addr: all_payouts,
          holder_payouts_addr: holder_payouts,
          pool_payouts_addr: pool_payouts,
          referral_payouts_addr: referral_payouts,

          all_payouts_addr_usd: (all_payouts_xlm * base_price).toFixed(7),
          holder_payouts_addr_usd: (holder_payouts_xlm * base_price).toFixed(7),
          pool_payouts_addr_usd: (pool_payouts_xlm * base_price).toFixed(7),
          referral_payouts_addr_usd: (referral_payouts_xlm * base_price).toFixed(7),
        })
      }
    });
  }


  question = (e, type) => {
    e.preventDefault()
    this.toggleTab(type)
    this.toggleModal()
  }



  getPayouts = (type) => {

    const { select_address, payouts, address_payouts } = this.state

    this.setState({ load_more: false, })

    if (payouts.length) this.page++

    const formData = new FormData();
    formData.append('page', this.page)
    formData.append('limit', this.limit)
    // formData.append('address', select_address)


    axios.post(`${GLOBE.API_URL}/get_payouts`, formData).then(result => {
      // console.log('get_payouts result: ', result)

      if (result.data) {

        this.setState({
          payouts: payouts.concat(result.data),
          load_more: true,
        })

        // if (select_address) {
        //
        //   this.setState({
        //     address_payouts: address_payouts.concat(result.data),
        //     load_more: true,
        //   })
        // }
        // else {
        //
        //   this.setState({
        //     payouts: payouts.concat(result.data),
        //     load_more: true,
        //   })
        // }
      }
    });
  }


  getPayoutsForAddress = () => {

    const { select_address, address_payouts } = this.state

    this.setState({ load_more: false, })

    if (address_payouts.length >=30) {
      this.page_address++
    }

    const formData = new FormData();
    formData.append('page', this.page_address)
    formData.append('limit', this.limit)
    formData.append('address', select_address)


    axios.post(`${GLOBE.API_URL}/get_payouts_address`, formData).then(result => {
      // console.log('get_payouts result: ', result)

      if (result.data) {

        this.setState({
          address_payouts: address_payouts.concat(result.data),
          load_more: true,
        })
      }
    });
  }


  payoutsType = (type) => {

    const { select_address } = this.state

    if (type === 'all') {
      this.changeUrl()
      // this.setState({ select_address: '' })
    }
    else {
      this.changeUrl(select_address)
    }

    this.setState({
      payouts_type: type,
      is_all_payouts: type === 'address' ? false : true
    })
  }


  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }


  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }


  renderAllPayouts = (item, index) => {

    // console.log('item: ', item)

    const { asset, base_price, holder_reward, pool_reward, referral_reward, partner_reward } = this.state

    const amount_usd = ((item.amount * asset.price) * base_price)

    const typeIcon = item.type === 'hold' ? 'diamond' : item.type === 'pool' ? 'database' : item.type === 'partner' ? 'users' : item.type === 'referral' ? 'user' : ''
    const rewardType = item.type === 'hold' ? `APY` : item.type === 'pool' ? `APY` : item.type === 'partner' || item.type === 'referral' ? 'VOL' : ''
    const rewardPercent = item.type === 'hold' ? holder_reward : item.type === 'pool' ? pool_reward : item.type === 'referral' ? referral_reward : item.type === 'partner' ? item.level : ''

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>
          <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(asset, asset.image, 'warning')} {' '}
          <small className={'text-secondary'}> / ${numFormat(amount_usd,5)}</small>
        </td>
        <td>{shortAddress(item.address)}</td>
        <td><b className={'text-secondary'}><i className={`fa fa-${typeIcon}`}></i> {item.type}</b></td>
        <td><b className={'text-secondary'}><span className={`text-success`}>+{rewardPercent}%</span> {rewardType}</b></td>
        <td>{shortTransaction(item.hash)}</td>
        <td>{formatDate(item.date)}</td>
      </tr>
    )
  }


  renderModal() {

    const { asset, min_balance, holder_reward, pool_reward } = this.state

    const { reset, handleSubmit } = this.props;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'modal-dark'
    }

    const pool = {
      XLMSCOP: 'feb83f3fe1ab32874787c96211b5c087c1f1ffdbeee6677c6d17b96f36c9b470',
      AQUASCOP: '492f21d8c29d8523cc8a4adc33302484389e15f9f6ef53db59f76d429338cf97',
      SCOPUSDC: '1b9ad45819d885acbc0563f20346fea19804238a334d7645ac069357d78cbb87',
    }

    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggleModal}
        className={`modal-dialog--primary modal-dialog--header`}
      >
        <div className={`modal-content ${classElem}`}>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text  modal__title"><b>SCOP</b> Rewards Program</h4>
          </div>
          <div className={`modal__body`}>

            <div className="tabs tabs--vertical tabs--vertical-colored">
              <div className="tabs__wrap">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggleTab('1'); }}
                    >
                      Holder
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggleTab('2'); }}
                    >
                      Liquidity
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '6' })}
                      onClick={() => { this.toggleTab('6'); }}
                    >
                      Referral
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggleTab('3'); }}
                    >
                      Staking
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggleTab('4'); }}
                    >
                      Trader
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '5' })}
                      onClick={() => { this.toggleTab('5'); }}
                    >
                      Card
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <h4>Holder Rewards</h4><br/>

                    <p>Each {getAsset(asset, '', 'secondary')} token holder with a balance of <b className={'text-info'}>{numFormat(min_balance)}</b> {getAsset(asset, asset.image, 'secondary')} or more will automatically receive <b className={'text-success'}>+{holder_reward}%</b> <b className={'text-secondary'}>APY</b>.</p>
                    <p>Daily payments.</p>
                    <p>To start participating in this rewards program, you do not need to do anything, if you have <b className={'text-info'}>{numFormat(min_balance)}</b> {getAsset(asset, asset.image, 'secondary')} on your balance - you are already participating.</p>
                    <p>The more {getAsset(asset, '', 'secondary')} on your address balance, the more % of rewards you earn.</p>
                    <br/>
                    { this.renderButtons() }
                  </TabPane>

                  <TabPane tabId="2">
                    <h4>Liquidity Rewards</h4><br/>
                    <p>Each <Link to={`/pools/SCOP#rewards`}><b>SCOP liquidity pool</b></Link> participant receives +14% per annum in addition to the liquidity pool yield.</p>
                    <p>Daily payments.</p>
                    <p>To participate in this rewards program, you need to deposit liquidity into one of the above pools in the form of an asset pair.
                      After providing liquidity, rewards will be delivered to your address on a daily basis.</p>
                    <p>The larger your share of {getAsset(asset, '', 'secondary')} in liquidity pools, the more % APY in {getAsset(asset, asset.image, 'secondary')} you will receive.</p>
                    <br/>
                    { this.renderButtons() }
                  </TabPane>

                  <TabPane tabId="6">
                    <h4>Referral Rewards</h4><br/>
                    <p>We have launched a referral program to attract new users paying with {getAsset(asset, asset.image, 'secondary')} tokens, and now everyone will be able to get a reward (up to <b className={'text-success'}>30%</b>) for inviting new users to Scopuly and {getAsset(asset, '', 'secondary')} holders.</p>
                    <p>Daily payments.</p>
                    {/*<p>*/}
                    {/*  <br/>*/}
                    {/*  <Link to={'/referral'}  className="btn btn-success btn-sm mb-0"><b>Referral Program</b></Link>*/}
                    {/*  <Link to={'/top-partners'}  className="btn btn-secondary btn-sm mb-0"><b>Top Partners</b></Link>*/}
                    {/*</p>*/}
                    <br/>
                    { this.renderButtons() }
                  </TabPane>

                  <TabPane tabId="3">
                    <h4>Staking</h4><br/>
                    <p>Staking is a passive income method in which users hold assets in their addresses in a locked form until a certain date, ensuring that the assets are not sold before a set date.</p>
                    <p>The more assets are locked in staking and the longer the staking period, the more % of rewards will be credited to the participant's address.</p>
                    <br/>
                    { this.renderButtons() }
                  </TabPane>
                  <TabPane tabId="4">
                    <h4>Trader Rewards</h4><br/>
                    <p>Rewards will be distributed at the end of each month and will be based on SCOP trading volume for each verified address.</p>
                    <br/>
                    { this.renderButtons() }
                  </TabPane>
                  <TabPane tabId="5">
                    <h4>Card Rewards</h4><br/>
                    <p>Wherever you are and for whatever you pay with your Scopuly Card, you will receive a SCOP cash back on every payment made on your card.</p>
                    <br/>
                    { this.renderButtons() }
                  </TabPane>
                </TabContent>
              </div>
            </div>

          </div>
        </div>
      </Modal>
    )
  }


  renderButtons = () => {
    return (
      <div>
        <a href="https://scop.scopuly.com" target={'_blank'}>Learn more about <b>SCOP</b></a>
        <br/><br/><br/>

        <ButtonGroup className="btn-group--justified">
          <Link to={`/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native`} className="btn btn-secondary btn-small">Trade </Link>
          <Link to={`/swap/XLM-SCOP/native/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ`} className="btn btn-secondary btn-small">Swap </Link>
          <Link to={`/pool/feb83f3fe1ab32874787c96211b5c087c1f1ffdbeee6677c6d17b96f36c9b470`} className="btn btn-secondary btn-small">Pool </Link>
        </ButtonGroup>

        {/*<a href={`https://scopuly.com/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native`}*/}
        {/*   className={'btn btn-outline-primary btn-sm'}*/}
        {/*   target={'_blank'}>Trade <b>SCOP</b></a>*/}

        {/*<a href={`https://scopuly.com/swap/XLM-SCOP/native/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ`}*/}
        {/*   className={'btn btn-outline-primary btn-sm'}*/}
        {/*   target={'_blank'}>Swap <b>SCOP</b></a>*/}

        {/*<a href={`https://scopuly.com/pool/feb83f3fe1ab32874787c96211b5c087c1f1ffdbeee6677c6d17b96f36c9b470`}*/}
        {/*   className={'btn btn-outline-primary btn-sm'}*/}
        {/*   target={'_blank'}>Pool</a>*/}
      </div>
    )
  }



  render() {

    // console.log('state: ', this.state)

    const { base_price, wallets, asset, all_payouts, holder_payouts, pool_payouts, staking_payouts, trader_payouts, card_payouts,
      all_payouts_usd, holder_payouts_usd, pool_payouts_usd, staking_payouts_usd, card_payouts_usd, referral_payouts_usd,
      trader_payouts_usd, all_payouts_xlm, holder_payouts_xlm, pool_payouts_xlm, payouts, is_all_payouts, select_address,
      address_payouts, all_payouts_addr, holder_payouts_addr, pool_payouts_addr, all_payouts_addr_usd, holder_payouts_addr_usd, pool_payouts_addr_usd,
      payouts_type, staking_payouts_addr_usd, trader_payouts_addr_usd, card_payouts_addr_usd, referral_payouts_addr_usd,
      staking_payouts_addr, trader_payouts_addr, card_payouts_addr, referral_payouts_addr,
      all_referral_payouts_xlm, all_referral_payouts_usd, all_referral_payouts, holder_reward, pool_reward, min_balance } = this.state

    const { handleSubmit, t } = this.props;



    return(
      <Container className="dashboard">

        { this.renderModal() }

        {/*<renderModal/>*/}

        <Row>
          <Col md={12}>
            <h3 className="page-title">
              <img src="https://scopuly.com/img/logo/icon.png" alt="SCOP Rewards | scopuly | stellar"
              style={{width: 23, margin: '-4px 4px 0 0'}}/>
              <b>SCOP</b> Rewards
            </h3>
            <h4 className="subhead subhead-b page-subhead">
              Scopuly presents <b>6</b> types of rewards for <b>SCOP</b> holders. Participate and increase the capitalization of your assets by earning up to <b className={'text-success'}>+35%</b> <b>SCOP</b>.
            </h4>
          </Col>
        </Row>


        <Card className={'element-animation'}>
          <CardBody
            style={{background: 'linear-gradient(80deg, rgb(121 0 255), rgb(160 235 255))'}}
          >
            <br/>
            <Row>
              <Col md={{ size: 6, offset: 1 }} lg={{ size: 5, offset: 1 }}>
                <br/>
                <br/>
                <br/>
                <h1 className={'text-white'}><b>Rewards Program for SCOP Token Holders</b></h1>
                <br/>
                <h4 className={'text-white'}>Daily payouts of Rewards for participating, being active, promoting and providing liquidity to the <b>SCOP</b> markets</h4>
                <br/>
                <div className={'text-white'}>

                  <ul>
                    <li>Holders: <b className={'text-success'}>+10%</b> <b>APY</b></li>
                    <li>Liquidity providers: <b className={'text-success'}>+18%</b> <b>APY</b></li>
                    <li>Referral Partners: <b className={'text-success'}>+20%</b> <b>VOL</b></li>
                    <li>Staking: <b className={'text-success'}>+35%</b> <b>APR</b> <small>(soon)</small></li>
                  </ul>

                </div>
                <br/><br/>

                <div className={'element-animation'}>
                  <Link className={'btn btn-lg btn-success'}
                        to={`/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native`}>
                    Trade <b>SCOP</b>
                  </Link>

                  <a href="https://scop.scopuly.com"
                     className={'btn btn-lg btn-primary'}
                     target={'_blank'}>More about <b>SCOP</b></a>
                </div>

              </Col>

              <Col md={{ size: 5}} className={'element-animation'}>
                <br/><br/>
                <img src="/img/referral/earn.png" alt=""  />
                <br/><br/>
              </Col>
            </Row>
            <br/><br/>
          </CardBody>
        </Card>


        <Row>
          <Col md={4}>
            <Card>
              <CardBody className={'text-secondary'}>
                <img src="/img/rewards/holder-r.png" alt=""/>
                <div className="card__title">
                  <h5 className="bold-text">Holder Rewards</h5>
                </div>
                Get <b className={'text-success'}>+{holder_reward}%</b> APY reward every day. Your balance must be at least <b className={'text-info'}>500</b> <b>SCOP</b> to participate in this rewards program. Just hold and earn. {' '}
                <a href="https://scopulyplatform.medium.com/scop-token-holder-reward-program-f8e9744a99ab"
                   target={'_blank'}><b>Learn more</b></a>
                <br/><br/>
                <Link to={`/swap#${min_balance}`}
                      className="btn btn-secondary mb-0 element-animation"><i className={'fa fa-diamond'}></i> <b>Get SCOP tokens</b></Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody className={'text-secondary'}>
                <img src="/img/rewards/liquidity-r.png" alt=""/>
                <div className="card__title">
                  <h5 className="bold-text">Liquidity Rewards</h5>
                </div>
                Earn rewards from <b className={'text-success'}>+{pool_reward}-18%</b> APY on liquidity pools. To participate in the program, your pool share must be at least <b className={'text-info'}>500</b> <b>SCOP</b>. {' '}
                <a href="https://scopulyplatform.medium.com/stellar-liquidity-pools-on-scopuly-amm-169bf40381ba"
                   target={'_blank'}><b>Learn more</b></a>
                <br/><br/>
                <Link to={'/pools/SCOP#rewards'}
                      className="btn btn-secondary mb-0 element-animation"><i className={'fa fa-database'}></i> <b>Select Liquidity Pool</b></Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody className={'text-secondary'}>
                <img src="/img/rewards/referral-r.png" alt=""/>
                <div className="card__title">
                  <h5 className="bold-text">Referral Program</h5>
                </div>
                Invite your friends and followers and earn cryptocurrency together. Earn up to <b className={'text-success'}>+25%</b> for every <b>SCOP</b> purchase with your referral link. {' '}

                {/*<a href="https://scopulyplatform.medium.com/scop-referral-program-on-stellar-7b8fae885e99"*/}
                {/*   target={'_blank'}><b>Learn more</b></a>*/}
                <br/><br/>
                <Link to={'#'} // /referral
                      className="btn btn-secondary mb-0 element-animation"><i className={'fa fa-users'}></i> <b>Create Referral Link</b></Link>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Card className={'element-animation'}>
          <CardBody>
            {
              asset && asset.price ?
                <Calculator asset={asset} price={base_price} />
                :
                <div className="panel__refresh"><LoadingIcon /></div>
            }
          </CardBody>
        </Card>



        <Card className={'element-animation'}>
          <CardBody >
            <div>
              <h4>
                Paid out:
              </h4>
              <h3>
                <b className={'text-info'}>{numFormat(all_payouts)}</b> {' '}
                <Link to={`/trade/${asset ? asset.asset_code : ''}-XLM/${asset ? asset.asset_issuer : ''}/native`}>
                  <img src="https://scopuly.com/img/logo/icon.png"
                       alt="scopuly"
                       className={'reward-asset-icon-top'}/> <b className={'text-secondary'}>SCOP</b>
                </Link> {' '}
                <small className={'text-secondary'}> / ${numFormat(all_payouts_usd)}</small>
              </h3>
            </div>

            <br/><br/>

            <Row>
              <Col lg={2} md={4} xs={6} className={'item-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '1')}>
                  <div>
                    {/*<div><i className="fa fa-diamond fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Holder.png" className={'w-60'} alt="Holder rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Holder Rewards</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'primary'} className={'fs-16'}><b>+{holder_reward}% APY</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                    </div>
                    <small className={'text-secondary'}>Paid out:</small>
                    <h4><b className={'text-info'}>{numFormat(holder_payouts)}</b> <small>SCOP</small></h4>
                  <div><small className={'text-secondary'}><b>{numFormat(holder_payouts_xlm)}</b> XLM / $<b>{numFormat(holder_payouts_usd)}</b></small></div>
                </a>
              </Col>

              <Col lg={2} md={4} xs={6} className={'item-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '2')}>
                  <div>
                    {/*<div><i className="fa fa-database fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Liquidity.png" className={'w-60'} alt="Liquidity rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Liquidity Pools</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'primary'} className={'fs-16'}><b>+{pool_reward}% APY</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                  </div>
                  <small className={'text-secondary'}>Paid out:</small>
                  <h4><b className={'text-info'}>{numFormat(pool_payouts)}</b> <small>SCOP</small></h4>
                  <div><small className={'text-secondary'}><b>{numFormat(pool_payouts_xlm)}</b> XLM / $<b>{numFormat(pool_payouts_usd)}</b></small></div>
                </a>
              </Col>

              <Col lg={2} md={4} xs={6} className={'item-reward opacity-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '6')}>
                  <div>
                    {/*<div><i className="fa fa-users text-secondary fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Referral.png" className={'w-60'} alt="Referral rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Referral Program</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'secondary'} className={'fs-16'}><b>+20% VOL</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                  </div>
                  <small className={'text-secondary'}>Paid out:</small>
                  <h4><b className={'text-info'}>{numFormat(all_referral_payouts)}</b> <small>SCOP</small></h4>
                  {/*<div><small className={'text-secondary'}><b>{numFormat(all_referral_payouts_xlm)}</b> XLM / $<b>{numFormat(all_referral_payouts_usd)}</b></small></div>*/}
                  <div><small className={'text-secondary'}><b>{numFormat(0)}</b> XLM / $<b>{numFormat(0)}</b></small></div>
                  <i className="fa fa-clock-o soon-icon"></i>
                </a>
              </Col>

              <Col lg={2} md={4} xs={6} className={'item-reward opacity-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '3')}>
                  <div>
                    {/*<div><i className="fa fa-university text-secondary fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Staking.png" className={'w-60'} alt="Staking rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Staking</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'secondary'} className={'fs-16'}><b>+35% APR</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                  </div>
                  <small className={'text-secondary'}>Paid out:</small>
                  <h4><b className={'text-info'}>{numFormat(staking_payouts)}</b> <small>SCOP</small></h4>
                  <div><small className={'text-secondary'}><b>{'0.00'}</b> XLM / $<b>{numFormat(staking_payouts_usd)}</b></small></div>
                  <i className="fa fa-clock-o soon-icon"></i>
                </a>
              </Col>

              <Col lg={2} md={4} xs={6} className={'item-reward opacity-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '4')}>
                  <div>
                    {/*<div><i className="fa fa-line-chart text-secondary fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Trade.png" className={'w-60'} alt="Trader rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Trader Rewards</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'secondary'} className={'fs-16'}><b>+5% VOL</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                  </div>
                  <small className={'text-secondary'}>Paid out:</small>
                  <h4><b className={'text-info'}>{numFormat(trader_payouts)}</b> <small>SCOP</small></h4>
                  <div><small className={'text-secondary'}><b>{'0.00'}</b> XLM / $<b>{numFormat(trader_payouts_usd)}</b></small></div>
                  <i className="fa fa-clock-o soon-icon"></i>
                </a>
              </Col>

              <Col lg={2} md={4} xs={6} className={'item-reward opacity-reward text-center'}>
                <a href="#" onClick={(e) => this.question(e, '5')}>
                  <div>
                    {/*<div><i className="fa fa-credit-card text-secondary fs-60"></i></div>*/}
                    <div><img src="/img/rewards/Card.png" className={'w-60'} alt="Crypto Card rewards scopuly"/></div>
                    <br/>
                    <div><b className={'text-secondary'}>Crypto Card</b></div>
                    <div className={'badge-block'}>
                      <Badge color={'secondary'} className={'fs-16'}><b>+5% CASHBACK</b></Badge> <i className="fa fa-question-circle-o"></i></div>
                  </div>
                  <small className={'text-secondary'}>Paid out:</small>
                  <h4><b className={'text-info'}>{numFormat(card_payouts)}</b> <small>SCOP</small></h4>
                  <div><small className={'text-secondary'}><b>{'0.00'}</b> XLM / $<b>{numFormat(card_payouts_usd)}</b></small></div>
                  <i className="fa fa-clock-o soon-icon"></i>
                </a>
              </Col>

              {/*<Col md={2} xs={6} className={'item-reward opacity-reward'}>*/}
              {/*  <a href="#" onClick={(e) => this.question(e, '6')}>*/}
              {/*    <div><b className={'text-secondary'}><i className="fa fa-users"></i> Referral</b> &nbsp;<Badge color={'primary'}><b>+20% VOL</b></Badge> <i className="fa fa-question-circle-o"></i></div>*/}
              {/*    <h4><b className={'text-info'}>{numFormat(referral_payouts)}</b> <small>SCOP</small></h4>*/}
              {/*    <div><small className={'text-secondary'}>${numFormat(referral_payouts_usd)}</small></div>*/}
              {/*    <i className="fa fa-clock-o soon-icon"></i>*/}
              {/*  </a>*/}
              {/*</Col>*/}
            </Row>
          </CardBody>
        </Card>


        <Card>
          <CardBody>
            <br/>

            <Row>
              <Col md={3}>
                <div className={'text-center'} style={{marginTop: 49}}>
                  <div className={'text-secondary'}>
                    Account: {select_address ? shortAddress(select_address) : null}
                  </div>
                </div>
              </Col>
              <Col md={{size:6}}>
                <form className="form"
                      onSubmit={handleSubmit(this.submitSearchForm)}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{marginBottom: 20}}><b>Enter Stellar address to check payouts</b></span>
                    <div className="form__form-group-field">
                      <Field
                        name="search"
                        component="input"
                        type="text"
                        placeholder="Stellar Account"
                        onChange={this.searchChange}
                      />
                      <button
                        type="button"
                        className={`form__form-group-button btn btn-secondary`}
                        style={{width: '90px'}}
                        onClick={this.handleSearchClick}
                      ><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </form>
                <p><small>Can be searched by <b className="text-lightblue">Address</b> or <b className="text-lightblue">Federation name</b>.</small></p>
                <br/>
              </Col>

              <Col md={3}>
                <form className="material-form" style={{marginTop: 32}}>
                  <div>
                    {
                      wallets ?

                        <div>
                          <Field
                            name="select_addr"
                            component={renderTextField}
                            select
                            label="Select Account"
                            onChange={(e) => {
                              this.changeAddress(e)
                            }} >
                            {
                              wallets.length ? wallets.map((item, index) => {

                                if (item) {
                                  return (
                                    <MenuItem
                                      key={index}
                                      style={{fontSize: '12px'}}
                                      className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                      value={item.pk}>
                                      <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                    </MenuItem>
                                  )
                                }
                              }) : null
                            }
                          </Field>

                        </div>

                        : null
                    }
                  </div>
                </form>
              </Col>

            </Row>
          </CardBody>
        </Card>


        {
          select_address ?
            <Col md={{size: 3}}>
              <ButtonToolbar className={'toolbar-filter'}>
                <ButtonGroup className="btn-group--icons">
                  <Button onClick={(e) => this.payoutsType('all')}
                          color={payouts_type === 'all' ? 'primary' : 'secondary'}> All Payouts</Button>
                  <Button onClick={(e) => this.payoutsType('address')}
                          color={payouts_type === 'address' ? 'primary' : 'secondary'}>Payouts to Address</Button>
                </ButtonGroup>
              </ButtonToolbar>
              <br/>
            </Col>
          : null
        }



        {
          is_all_payouts ?
            <Card>
              <CardBody>
                <h4>All Payouts</h4>

                { !payouts.length ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                <br/>

                <Table striped responsive hover>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Reward amount</th>
                    <th>Recipient</th>
                    <th>Type</th>
                    <th>Reward rate</th>
                    <th>Transaction</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    payouts.length ?
                      payouts.map(this.renderAllPayouts)
                      : null
                  }

                  </tbody>
                </Table>

                <div className="text-center">
                  <br/>
                  {
                    payouts.length ?
                      <Button color="secondary" onClick={() => {this.getPayouts('all')}}>
                        {
                          this.state.load_more ? 'Load more...' :
                            <SpinnerLoader />
                        }
                      </Button> : null
                  }
                </div>
              </CardBody>
            </Card>
          : null
        }


        {
          !is_all_payouts && address_payouts.length ?
            <Card>
              <CardBody>

                <div>
                  <h4>Paid to: {shortAddress(select_address)}</h4>
                  {/*<h5>*/}
                  {/*  {shortAddress(select_address)} received:*/}
                  {/*</h5>*/}
                  <h4><b className={'text-info'}>{numFormat(all_payouts_addr, 2)}</b> {' '}
                    <Link to={`/trade/${asset ? asset.asset_code : ''}-XLM/${asset ? asset.asset_issuer : ''}/native`}>
                      <img src="https://scopuly.com/img/logo/icon.png"
                           alt="scopuly"
                           className={'reward-asset-icon'}/> <b className={'text-secondary'}>SCOP</b>
                    </Link> {' '} <small> / ${numFormat(all_payouts_addr_usd, 2)}</small>
                  </h4>
                </div>

                <br/><br/>

                <Row className={'text-secondary'}>
                  <Col md={2} xs={6}>
                    <div><b>Holder</b></div>
                    <h5><b className={'text-info'}>{numFormat(holder_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(holder_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                  <Col md={2} xs={6}>
                    <div><b>Liquidity Pool</b></div>
                    <h5><b className={'text-info'}>{numFormat(pool_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(pool_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                  <Col md={2} xs={6}>
                    <div><b>Referral Programm</b></div>
                    <h5><b className={'text-info'}>{numFormat(referral_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(referral_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                  <Col md={2} xs={6} className={'opacity-reward'}>
                    <div><small>Staking</small></div>
                    <h5><b className={'text-info'}>{numFormat(staking_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(staking_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                  <Col md={2} xs={6} className={'opacity-reward'}>
                    <div><small>Trader</small></div>
                    <h5><b className={'text-info'}>{numFormat(staking_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(trader_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                  <Col md={2} xs={6} className={'opacity-reward'}>
                    <div><small>Card</small></div>
                    <h5><b className={'text-info'}>{numFormat(staking_payouts_addr, 2)}</b> SCOP</h5>
                    <div><small className={'text-secondary'}>${numFormat(card_payouts_addr_usd, 2)}</small></div>
                    <br/>
                  </Col>
                </Row>

                <br/><br/>

                <Table striped responsive hover>
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Reward Amount</th>
                    <th>Address</th>
                    <th>Type</th>
                    <th>Reward rate</th>
                    <th>Transaction</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    address_payouts.length ?
                      address_payouts.map(this.renderAllPayouts)
                      : null
                  }

                  </tbody>
                </Table>

              </CardBody>
            </Card>
          :
            !is_all_payouts && select_address ?
              <Card className={'element-animation'}>
                <CardBody className={'text-center'}>
                  <h5>No payouts found for account {shortAddress(select_address)}</h5>
                </CardBody>
              </Card>
            : null
        }

        <Footer hide={true} />

      </Container>

    )
  }
}


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Reward));
