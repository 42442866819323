import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';



class ForExchanges extends PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {  
    getTitle(`For Exchanges`);
  }



  render() {

    const token_path = '/asset/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ';

    return (
        <Container>

          {/*<Row>
            <Col md={12}>
              <h3 className="page-title">Contacts</h3>
              <h4 className="subhead subhead-b" style={{marginBottom: '18px'}}>fasdfasfasdf</h4>
            </Col>
          </Row>*/}

          <Row>
            <Col md={{ size: 12 }}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">For Exchanges</h5>
                    <h5 className="subhead">Thank you for taking the time to explore Scopuly</h5>
                  </div>
                  <div>
                    <p>Scopuly Coin (SCOP) is a Scopuly platform token, it allows you to: invest in IEO projects; launch IEO projects on the platform; pay fees from crypto-fiat exchange transactions; access future platform services.</p>

                    <p>The SCOP token is issued and works on the Stellar blockchain.</p>

                    <p>More information on the <a href={token_path} className="text-warning"><b>SCOP</b></a> token can be found <a href="http://scop.scopuly.com/">here</a>.</p>

                    <p>Historical data on the <a href={token_path} className="text-warning"><b>SCOP</b></a> token can be obtained <a href={token_path}>here</a>.</p>

                    <p>If you have an SCOP affiliate listing offer on your exchange, make sure your platform supports Stellar Assets and you can add a SCOP token to the list of your trading pairs.</p>

                    <p>Technical information on Sellar Assets integration can be found <a href="https://www.stellar.org/developers/guides/concepts/assets.html">here</a>, or in the <a href="https://www.stellar.org/developers/">official documentation</a>.</p>

                    <p>For any questions, write to our Telegram <i>@scopuly</i></p>
                  </div>
                </CardBody>
              </Card>   
            </Col>
          </Row>

          <Footer />
          
        </Container>
    );
  }
}


export default translate('common')(ForExchanges);

