import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {Button, ButtonGroup} from 'reactstrap';
import { translate } from 'react-i18next';
import StellarSdk from 'stellar-sdk';
import PropTypes from 'prop-types';
import { Server } from '../../../../modules/stellar/index';
import { numFormat, get_loc_wallets, getAsset } from '../../../../modules/index';
import axios from 'axios';
import {getPaymentRequests, getRelativeAssets} from "../../../../endpoints/API";
import getPrice from "../../../App/GetPrice";
import {GLOBE} from "../../../../modules/globeVars";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";


const beatLoader = css`
  display: inline-block;
  margin: 0 5px;
`


// var wallets = get_my_wallets();
// let total_amount_usd = 0;


class Balances extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      balances: this.props.account.balances.reverse(),
      balances_formated: false,
      potencial_amount_xlm: 0,
      potencial_amount_fiat: 0,
      refresh: false,
      offers: [],
      claimable_balances: [],
      load_offers: false,
      my_wallet: false,
      price: 0,
      trades_item: {},
      rades_item_load: true,
      total_amount_usd: 0,
      total_amount_usd_is: false,
      invoices: false,
      assets: false,
      pools: [],
      // wallets: get_loc_wallets(),
    };
  }


  total_amount_usd = 0
  countPools = 0
  countGetTrades = 0
  xlm_asset = {
    asset_code: 'XLM',
    asset_issuer: 'native',
    name: 'Stellar',
    image: '/img/logo/stellar-logo.png',
    home_domain: 'stellar.org',
  }


  componentDidMount() {

    this.total_amount_usd = 0

    this.formatBalances()
    this.getOffers(this.props.account.id);
    this.getClaimableBalances(this.props.account.id);
    this.getWallets();
    // this.getTrades();

    getPaymentRequests(this.props.account.id, 50)
      .then(result => {
        // console.log('result: ', result)

        if (result.data !== 'not_found') {
          this.setState({
            invoices: result.data,
          })

          this.props.allInvoices && this.props.allInvoices(result.data)
        }
      })
  }


  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.total_amount_usd_is !== nextState.total_amount_usd_is) {
  //     return true;
  //   }
  //   return false;
  // }



  formatBalances = async () => {

    const { balances } = this.state

    let assets, pools, query

    if (balances.length === 1) {
      assets = [{
        asset_code: 'XLM',
        asset_issuer: 'native',
        image: '',
      }]

      this.setState({ assets, balances_formated: true, })

      return
    }

    query = {
      pools: [],
      asset_codes: [],
      asset_issuers: [],
    }

    balances.map( (item, index) => {
      // console.log('item: ', item)

      if (item.asset_type === 'liquidity_pool_shares') {
        query.pools.push(item.liquidity_pool_id)
      }
      else {

        if (item.asset_type !== 'native') {
          query.asset_codes.push(item.asset_code)
          query.asset_issuers.push(item.asset_issuer)
        }
      }
    })

    // console.log('query: ', query)

    const formData = new FormData();
    formData.append('pools', query.pools)
    formData.append('codes', query.asset_codes)
    formData.append('issuers', query.asset_issuers)

    axios.post(`${GLOBE.API_URL}/get_relative_assets`, formData).then(result => {
      // console.log('getRelativeAssets result: ', result)

      if (result) {

          assets = result.data.assets
          pools = result.data.pools

          balances.map((item, index) => {
            if (item.asset_type === 'liquidity_pool_shares') {
              item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
            }
          })

          this.setState({
            balances,
            pools,
            assets,
            balances_formated: true,
          })
      }
    })


    // const result = await getRelativeAssets(query.pools, query.asset_codes, query.asset_issuers)
    // console.log('getRelativeAssets result: ', result)
    //
    // if (result) {
    //
    //     assets = result.data.assets
    //     pools = result.data.pools
    //
    //     balances.map((item, index) => {
    //       if (item.asset_type === 'liquidity_pool_shares') {
    //         item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
    //       }
    //     })
    //
    //     this.setState({
    //       balances,
    //       pools,
    //       assets,
    //       balances_formated: true,
    //     })
    // }

    // getRelativeAssets(query.pools, query.asset_codes, query.asset_issuers).then(result => {
    //   // console.log('get_relative_assets result: ', result)
    //
    //   let assets = result.data.assets
    //   let pools = result.data.pools
    //
    //   // if (balances.length === 1) {
    //   //   assets = [{
    //   //     asset_code: 'XLM',
    //   //     asset_issuer: 'native',
    //   //     image: '',
    //   //   }]
    //   // }
    //
    //   balances.map((item, index) => {
    //     if (item.asset_type === 'liquidity_pool_shares') {
    //       item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
    //     }
    //   })
    //
    //   this.setState({
    //     balances,
    //     pools,
    //     assets,
    //     balances_formated: true,
    //   })
    // })

  }


  getWallets() {
    const wallets = this.props.wallets;

    if (wallets) {
      wallets.forEach((wallet, index) => {
        if (wallet.pk === this.props.account.id) {
          wallet.id = index;

          this.setState({
            my_wallet: true,
          })
        }
      });
    }
  }


  change24(val) {

    var today = Number((val.records[0].close_r.d / val.records[0].close_r.n).toFixed(7));
    var yestoday = Number((val.records[1].close_r.d / val.records[1].close_r.n).toFixed(7));

     if (today > yestoday) {
       more();
     }
     else if (today === yestoday) {
       val.change24 = '0.00';
       val.change_24h_color = 'secondary';
     }
     else {
       less();
     }

     function more() {
       val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
       val.change_24h_color = 'text-success';
     }

     function less() {
       val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
       val.change_24h_color = 'text-danger';
     }

    return val;
  }


  // renderBalance(elem, index) {

  //   let url = `/asset/${elem.asset_code}-${elem.asset_issuer}`;
  //   if (elem.asset_type === 'native') {
  //     elem.asset_code = 'XLM';
  //     url = `/asset/native`;
  //   }

  //   return (
  //     <span key={index}>
  //       <font className="asset-amount">{elem.balance}</font>
  //       <Link className="asset-code text-warning" to={ url }>
  //         <b>{elem.asset_code}</b>
  //       </Link>
  //     </span>
  //   )
  // }


  getClaimableBalances = async (address) => {
    // console.log('address: ', address)

    const { records } = await Server
      .claimableBalances()
      .claimant(address)
      .limit(200)
      .call()

    this.setState({
      claimable_balances: records,
    })
  }


  getOffers(account_id) {

    Server.offers().forAccount(account_id)
      .order('desc')
      .limit(200)
      .call()
      .then((offerResult) => {

        this.setState({
          offers: offerResult.records,
          load_offers: true,
        })
      })
      .catch(function (err) {
        console.error(err);
      })
  }



  getTrades = async (item, index) => {

    const { balances, pools, assets } = this.state

    if (item.asset_type === 'liquidity_pool_shares') {

      const pool = pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
      // console.log('pool: ', pool)

      if (pool) {
        const asset = assets.filter(asset => asset.asset_code === pool.code_a && asset.asset_issuer === pool.issuer_a)[0]
        // console.log('asset: ', asset)

        if (asset) {

          const reserves_a = pool.amount_a
          const total = pool.shares
          const amountA = Number((reserves_a / total * item.balance).toFixed(7)) // from shares
          // console.log('amountA: ', amountA)

          const amount_usd = (pool.issuer_a === 'native' ? (amountA * this.props.price) : ((amountA * asset.price) * this.props.price)) * 2
          // console.log('amount_usd pool: ', amount_usd)

          this.total_amount_usd += amount_usd
        }
      }
    }

    if (item.asset_type === 'native') {

      const total_amount_usd = Number(item.balance * this.props.price)

      if (balances.length === 1) {

        this.setState({
          total_amount_usd_is: true,
          total_amount_usd,
        }, () => {
          // total_amount_usd = 0
        })
      }
      else {
        this.total_amount_usd += total_amount_usd
      }

      // console.log('total_amount_usd: ', this.total_amount_usd)
    }

    else if (item.asset_type !== 'native' && item.asset_type !== 'liquidity_pool_shares') { // asset

      const base = new StellarSdk.Asset.native();
      const counter = new StellarSdk.Asset(item.asset_code, item.asset_issuer);
      const startTime = 0;
      const endTime = 0;
      const resolution = 86400000;
      const offset = 0;

      if (!this.state.total_amount_usd_is) {

        // console.log('load tradeAggregation...')

        // const tradesRes = await
        Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
          .order('desc')
          .limit(1)
          .call()

        .then(tradesRes => {
          // console.log('tradesRes: ', tradesRes)

          let amount_usd = 0, price_xlm = 0, amount_xlm = 0, amount_xlm_usd = 0;

          if (tradesRes.records.length) {

            price_xlm = (tradesRes.records[0].close_r.d / tradesRes.records[0].close_r.n);
            amount_xlm = (item.balance * price_xlm);
            amount_usd = Number(amount_xlm * this.props.price);

            this.total_amount_usd += amount_usd
          }

          // console.log('balances.length: ', balances.length)
          // console.log('(index): ', (index + 1))

          if ((balances.length) === (index + 1 + pools.length) && !this.state.total_amount_usd_is) { //  + this.countPools

            this.setState({
              total_amount_usd_is: true,
              total_amount_usd: this.total_amount_usd,
            }, () => {
              this.total_amount_usd = 0
              // this.countPools = 0
            })
          }

          // if (tradesRes.records.length) {
          //
          //   let amount_usd = 0, price_xlm = 0, amount_xlm = 0, amount_xlm_usd = 0;
          //
          //   price_xlm = (tradesRes.records[0].close_r.d / tradesRes.records[0].close_r.n);
          //   amount_xlm = (item.balance * price_xlm);
          //   amount_usd = Number(amount_xlm * this.props.price);
          //
          //   this.total_amount_usd += amount_usd
          //
          //   if ((balances.length) === (index + 1)) { //  + this.countPools
          //
          //     this.setState({
          //       total_amount_usd_is: true,
          //       total_amount_usd: this.total_amount_usd,
          //     }, () => {
          //       this.total_amount_usd = 0
          //       this.countPools = 0
          //     })
          //   }
          // }
        })
        .catch((err) => {
          console.log('err trades count', err);

          if ((balances.length) === (index + 1 + pools.length) && !this.state.total_amount_usd_is) { //  + this.countPools

            this.setState({
              total_amount_usd_is: true,
              total_amount_usd: this.total_amount_usd,
            }, () => {
              this.total_amount_usd = 0
              // this.countPools = 0
            })
          }
        })

        // console.log('resTrades: ', tradesRes)
        //
        // let amount_usd = 0, price_xlm = 0, amount_xlm = 0, amount_xlm_usd = 0;
        //
        // if (tradesRes.records.length) {
        //
        //   price_xlm = (tradesRes.records[0].close_r.d / tradesRes.records[0].close_r.n);
        //   amount_xlm = (item.balance * price_xlm);
        //   amount_usd = Number(amount_xlm * this.props.price);
        //
        //   this.total_amount_usd += amount_usd
        // }
        //
        // // console.log('balances.length: ', balances.length)
        // // console.log('(index): ', (index + 1))
        //
        // if ((balances.length) === (index + 1 + pools.length) && !this.state.total_amount_usd_is) { //  + this.countPools
        //
        //   this.setState({
        //     total_amount_usd_is: true,
        //     total_amount_usd: this.total_amount_usd,
        //   }, () => {
        //     this.total_amount_usd = 0
        //     this.countPools = 0
        //   })
        // }
      }
    }
  }



  renderBalances = (limit) => {

    const { assets, pools } = this.state

    // Map balances...
    const balances = this.state.balances.map( (item, index) => {

      // console.log('item: ', item)

      let url, asset, pool

      if (index < limit) {
        url = `/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`;

        if (item.asset_type === 'native') {
          item.asset_code = 'XLM';
          url = `/native`;
          asset = this.xlm_asset
        }
        // else {
        //   this.getTrades(item, index);
        // }

        if (window.location.pathname === '/account/'+this.props.account.id) {
          this.getTrades(item, index);
        }

        const image = '/img/digitalcoin.png'

        let asset_a = { image }
        let asset_b = { image }
        let pair_name = 'SHARES'


        if (item.asset_type === 'liquidity_pool_shares') {

          url = `/pool/${item.liquidity_pool_id}`;

          if (item.pool) {
            asset_a = assets.filter(asset => asset.asset_code === item.pool.code_a && asset.asset_issuer === item.pool.issuer_a)[0]
            asset_b = assets.filter(asset => asset.asset_code === item.pool.code_b && asset.asset_issuer === item.pool.issuer_b)[0]

            if (item.pool.issuer_a === 'native') {
              asset_a = this.xlm_asset
            }

            if (asset_a && asset_b) {
              pair_name = <span>{asset_a.asset_code}<b className={'text-secondary'}> / </b>{asset_b.asset_code}</span>
            }
          }


          if (!asset_a) {
            asset_a = this.xlm_asset
          }
          if (!asset_b) {
            asset_b = { image }
          }
        }

        if (item.asset_type !== 'liquidity_pool_shares' && item.asset_type !== 'native') {
          asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

          if (!asset) {
            asset = {
              asset_code: item.asset_code,
              asset_issuer: item.asset_issuer,
              image: image,
            }
          }
        }


        // console.log('asset_a: ', asset_a)
        // console.log('asset_b: ', asset_b)


        return (
          <span key={index} className="chips_item">
            <span>
              {
                item.pool ?
                  <Link className="text-warning" to={ url }>
                    <span className={'wr-balance-poll-item'}>
                      <span className={'balance-pool-item'}>
                        <span className={'pool-share'}><img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/></span>
                        <span className={'pool-share'}><img src={asset_b.image} alt="" style={{width: 23}}/></span>
                      </span>
                    </span>
                  </Link>
                :
                  <span>
                    {
                      asset ?
                        getAsset(`${asset.asset_code}:${asset.asset_issuer}`, asset.image)
                      : null
                    }
                  </span>
              }
              <span className="asset-amount">{' '}
                <b className="text-info">{numFormat(item.balance, 7)}</b> {' '}
                {
                  item.asset_type === 'liquidity_pool_shares' &&
                    <span>
                      <Link to={url}><b className={'text-warning'}>{pair_name}</b></Link> {' '}
                      <small className={'text-secondary'}>pool</small>
                    </span>
                }
              </span>
            </span> {' '}
            {/*<span className="asset-amount"><b className="text-info">{numFormat(item.balance, 7)}</b> {item.pool && ' pool shares'}</span>*/}
              {/*<small className={elem.change_24h_color}>{elem.change24}</small>*/}
          </span>
        )
      }
    })

    return balances;
  }



  render() {

    // console.log('B state: ', this.state)
    // console.log('props: ', this.props)

    const { total_amount_usd_is, balances_formated, assets, pools, total_amount_usd } = this.state

    let more_balances = false,
        balances = false,
        limit = this.props.limit;

    if (this.state.balances) {

      balances = this.state.balances;
    //   console.log('balances: ', balances)

      if (balances.length > this.props.limit) {
        more_balances = (balances.length - this.props.limit)
      } else {
        limit = balances.length;
      }
    }

    return (
      <div>

        {
          this.state.claimable_balances.length && window.location.pathname === '/wallet' ?
            <div>
              <a className={'pending-payment btn btn-secondary'} href={`/claimable-balances/${this.props.account.id}`}>
                <i className={'fa fa-clock-o'}></i> Claimable balances <b>{this.state.claimable_balances.length}{this.state.claimable_balances.length === 200 && '+'}</b>
              </a>
            </div>
          : null
        }

        <p style={{margin: '-11px 0 15px 0'}}>

          {
            balances_formated && assets ?
              this.renderBalances(limit)
            : null
          }

          { more_balances ? <Link to={ `/account/${this.props.account.id}` }>... +{more_balances} balances</Link> : '' }
        </p>

        <p>
          <ButtonGroup className="balancess-data">
            {
              this.state.my_wallet ?
                <Link to={`/trustlines/${this.props.account.id}`}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Trustlines: <b>{balances.length-1}</b></Link>
                : null
            }
            {
              this.state.invoices ?
                <Link to={`/invoices/${this.props.account.id}`}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Invoices: <b>{this.state.invoices.length}</b></Link>
                : null
            }
            {
              this.state.load_offers && this.state.offers.length > 0 ?
                <Link to={'/offers/'+this.props.account.id}
                      className="btn btn-outline-secondary btn-sm"
                      style={{marginBottom: '0'}}>Offers: <b>{this.state.offers.length}</b></Link>
                : null
            }
          </ButtonGroup>
          <br/>

          {/*&nbsp;&nbsp;&nbsp;*/}
          {
            window.location.pathname === '/account/'+this.props.account.id ?
              <div className={'total-usd'}>Total <b className="text-secondary">USD</b> {' '}
                {
                  !total_amount_usd_is ?
                    <BeatLoader
                      css={beatLoader}
                      color={"#70bbfd"}
                      loading={true}
                      size={7}
                    />
                  :
                  <span>&#8776; <b className="text-secondary">{numFormat(total_amount_usd, 2)}</b></span>
                }
              </div>
            : null
          }
        </p>
      </div>
    );
  }
}


export default translate('common')(Balances);
