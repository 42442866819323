import React, { PureComponent } from 'react';
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabPane, TabContent, Modal, Button, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import TopAssets from '../Home/components/TopAssets';
import SearchField from '../Search/components/SearchField';
import StellarAssets from './components/StellarAssets';
import { ToastContainer, ToastStore } from 'react-toasts';
import Footer from '../../Layout/footer/Footer';
import getPrice from '../../App/GetPrice';
import {
  baseReserve, domainValid, formatCount,
  get_loc_wallets,
  getAuth, getBasePrice,
  getIconConnectProvider, getStellarFee,
  getTitle,
  needDeauthAll,
  numFormat,
  shortAddr
} from '../../../modules/index';
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import swal from "sweetalert";
import RequiredSigners from "../Auth/components/RequiredSigners";
import PulseLoader from "react-spinners/PulseLoader";
import {css} from "@emotion/core";
import SpinnerLoader from "../../../modules/SpinnerLoader";
import iconIdent from "../Account/components/iconIdent";
import AcceptAsset from "./components/AcceptAsset";
import axios from "axios";
import {GLOBE} from "../../../modules/globeVars";
import {getStateAssets} from "../../../endpoints/API";
// import AcceptAsset from "./components/AcceptAsset";

const override = css`
  display: inline-block;
  margin-bottom: -2px;
`;


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
    <TextField
        className="material-form__field"
        label={label}
        error={touched && error}
        value={input.value}
        children={children}
        select={select}
        type={type}
        onChange={(e) => {
          e.preventDefault();
          input.onChange(e.target.value);
        }}
    />
);


class Assets extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: get_loc_wallets(),
      limit: 30,
      account: false,
      asset: false,
      assets: [],
      activeTab: '1',
      search_data: false,
      load_search_data: false,
      start_get_assets: false,
      stellar_assets: [],
      price: false,
      loader: false,
      // modal: false,
      // trust: false,
      transaction: false,
      need_add_signers: false,
      signer: false,
      loading_stellar_assets: false,
      search_asets_db: false,
      show_accept_modal: false,
      filter: 'rating',
      all_assets: 0,
      vol_24: 0,
      vol_24_usd: 0,
      holders: 0,
      today_assets: 0,
      week_assets: 0,
      month_assets: 0,
      domain: '',
      // cap: 0,
    };
  }

  count = 0;
  fee = getStellarFee()

  componentDidMount() {

    const title = 'All Stellar Assets, Decentralised Asset Exchange (SDEX)';
    const description = 'Trade on SDEX, the global digital asset market. Without registration, without intermediaries, without minimum deposits, without fees, at a speed of 3-5 sec / tx.';
    getTitle(title, description);

    this.getAccountsBalances()
    const url_id = this.props.match.params.id

    if (!url_id) {
      this.setState({ start_get_assets: true, })
    }
    else { // search

      this.setState({
        load_search_data: true,
      })

      const is_domain = domainValid(url_id)

      if (is_domain) { // domain
        this.setState({
          domain: url_id,
        })
        this.props.change('home_domain', url_id)
      }
      else { // code
        this.setState({
          asset_code: url_id,
        })
        this.props.change('asset_code', url_id)

        this.getStellarAssets(url_id)
      }
    }

    this.getBasePrice()
  }


  getBasePrice = () => {

    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 3) {
        getPrice()

        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }

    this.getAllStatAssets()
  }


  // getBasePrice = async () => {
  //
  //   const stellar = await getBasePrice()
  //
  //   this.setState({
  //       stellar,
  //       price: stellar.market_data.current_price.usd
  //     },
  //     () => {
  //
  //       this.getAllStatAssets()
  //     })
  // }


  getAllStatAssets = () => {

    getStateAssets().then(result => {

      const { price } = this.state

      const data = result.data
      const vol_24_usd = (data.vol_24 * price)
      const cap = (data.base_cap * price)

      this.setState({
        all_assets: data.all_assets,
        vol_24: data.vol_24,
        vol_24_usd,
        holders: data.holders,
        today_assets: data.today_assets,
        week_assets: data.week_assets,
        month_assets: data.month_assets,
        cap
      })
    })
  }


  toggleAcceptModal = (value) => {
    if (value) {
      this.setState({ show_accept_modal: true })
      return
    }
    setTimeout(() => this.setState({ show_accept_modal: !this.state.show_accept_modal }), 1000)
  }


  getAccountsBalances = () => {

    const getItemAccount = (item, index) => {
      // var item = item;
      item.id = index;
      let wallets_arr = []

      Server.loadAccount(item.pk)
        .then((account) => {

          if (account) {
            item.account = account;
            item.balance = numFormat(account.balances[account.balances.length-1].balance, 2);
            item.reserve = (account.subentry_count * baseReserve());
            item.avaliable = (item.balance - item.reserve).toFixed(2);
          }

          wallets_arr.push(item);
          setNewState(wallets_arr)
        })
        .catch((err) => {
          console.error(err);

          wallets_arr.push(item)
          setNewState(wallets_arr)
        })

      const setNewState = (wallets_arr) => {
        if (this.state.wallets.length === wallets_arr.length) {
          newState();
        }
      }

      const newState = () => {
        this.setState({
          wallets: wallets_arr,
        })
      }
    }

    if (this.state.wallets) {
      this.state.wallets.forEach((item, index) => {
        getItemAccount(item, index);
      })
    }
  }


  getSearchFieldData = () => {

    const { filter, asset_code } = this.state

    this.setState({
      load_search_data: false,
      start_get_assets: false,
      stellar_assets: false,
    }, () => {

      setTimeout(() => {
        this.setState({
          // search_data: query.asset.asset_code || query.asset.asset_issuer ? query.asset : false,
          // filter: query.filter,
          // page_reset: query.filter !== filter ? 'reset' : '',
          load_search_data: true,

        })
      }, 100)
    });

    if (asset_code !== '') {
      // window.history.replaceState(null,null, `/assets/${asset_code}`)
      this.getStellarAssets(asset_code)
    }
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


  stellarAssetsCount = (count) => {
    this.setState({
      stellar_assets_count: count,
    })

    ToastStore.info(`${count} ${this.props.match.params.id} tokens found in Stellar Ecosystem. Click on All Stellar Assets to view.`);
  }

  acceptAsset = (asset) => {

    if (this.state.account) {
      const trustItem = this.state.account.balances.filter(item => {
        return (item.asset_code === asset.asset_code && item.asset_issuer === asset.asset_issuer)
      })
      if (trustItem.length) {
        this.setState({
          trust: trustItem,
          accept_asset: asset,
        })
      }
    }

    this.setState({asset})

    // this.toggleModal()
    this.toggleAcceptModal(true)
  }


  toggleModal = () => {
    this.setState({modal: !this.state.modal})
  }


  getSigner(address) {
    const wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        signer: signer,
      })
    }
  }


  setTrust = () => {

    getAuth()
        .then((value) => {
          if (value === 'accept') {
            setTrustTx()
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });


    const setTrustTx = () => {

      this.setState({ loader: true })

      Server.loadAccount(this.state.address)
          .then((sourceAccount) => {

            const submitTx = (num) => {
              return Server.submitTransaction(this.state.transaction);
            }

            if (!this.state.transaction) {

              this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(this.state.asset.asset_code, this.state.asset.asset_issuer),
              }))
              .setTimeout(100)
              .build();

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
                // alert(sourceAccount.thresholds.med_threshold)

                this.setState({
                  // transaction: this.state.transaction,
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Set Trustline',
                  btn_icon: 'cube',
                  type_tx: 'med',
                  type_set_tx: 'trust'
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
            else {
              return submitTx();
            }
          })
          .then((result) => {
            if (result) {

              this.setState({ loader: false, transaction: false })

              swal({
                title: `Added new balance ${this.state.asset.asset_code}!`,
                text: `Trustline to ${this.state.asset.asset_code} token has been successfully installed. Now ${this.state.asset.asset_code} token is available on your balance.`,
                icon: "success",
                confirm: true,
              })
              .then(confirm => {
                // if (confirm) {
                //   this.toggleModal();
                // }
              });
            }
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader: false })
            ToastStore.error('Transaction error');
          });
    }
  }

  selectAccount(value) {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      address,
      account: false,
      trust: false
    })

    this.checkAccount(address);
    this.getSigner(address);
  }


  checkAccount = (address) => {

    ToastStore.info("Checking Account...");
    this.setState({ loader: true, });

    // Check Trust
    Server.loadAccount(address)
      .then((account) => {

        this.setState({ loader: false, });

        let trust = account.balances.filter((balance) => {
          return balance.asset_code === this.state.asset.asset_code &&
              balance.asset_issuer === this.state.asset.asset_issuer;
        });

        this.setState({
          trust:(trust.length ? trust  : false),
          account
        })

        if (trust.length) {
          ToastStore.success(`${this.state.asset.asset_code} asset is already on your balance`);
        }
        else {
          swal({
            title: "Adding a new balance",
            text: `Add ${this.state.asset.asset_code} asset to your balance?`,
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          .then((confirm) => {
            if (confirm)
              this.setTrust()
          })
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setState({ loader: false, });
      });
  }



  addSigners = (value) => {
    this.setState({
      transaction: value,
      need_add_signers: false,
    })
  }


  submitForm = (values) => {

    if (Object.keys(values).length === 0) {
      ToastStore.warning(`Account not selected`);
    }
    else if (this.state.account && !this.state.trust) {
      this.setTrust()
    }
  }


  getStellarAssets = (asset_code) => {

    this.setState({ loading_stellar_assets: true })

    Server.assets()
      .forCode(asset_code)
      .limit(200)
      .call()
      .then((result) => {
        this.setState({ loading_stellar_assets: false })

        let assets = result.records;

        if (assets.length > 0) {
          assets.forEach((item, index) => {
            item.home_domain = item._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
          })

          assets = assets.sort((a, b) => b.num_accounts > a.num_accounts ? 1 : -1);

          this.setState({
            stellar_assets: assets,
          })
        }

      })
      .catch( (err) => {
        console.log(err)
        this.setState({ loading_stellar_assets: false })
      })
  }



  searchResult = (value) => {
    this.setState({ search_asets_db: value })
    if (!value) {
      this.getStellarAssets(this.props.match.params.id);
    }
  }


  filterAssets = (value) => {

    let filter = '', count = 0;
    for (const key of Object.keys(value)) {
      filter = (filter + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({ filter }, () => {
      this.getSearchFieldData()
    })
  }


  assetForm = (values) => {

    // this.getSearchFieldData()

    if (!values.asset_code && !values.asset_issuer && !values.home_domain) {
      ToastStore.warning('Enter data to search');
    }
    else {
      this.getSearchFieldData()
    }
  }


  checkFields = () => {
    const { filter, asset_code, asset_issuer, domain } = this.state

    // console.log('filter: ', filter)
    // console.log('asset_code: ', asset_code)
    // console.log('asset_issuer: ', asset_issuer)
    // console.log('domain: ', domain)

    // this.setState({
    //   load_search_data: true,
    //   start_get_assets: false,
    // })

    // if (!asset_code && !asset_issuer && !domain) {
      this.getSearchFieldData()
    // }
  }


  changeSearchCode = (value) => {

    let asset_code = '', count = 0;
    for (const key of Object.keys(value)) {
      asset_code = (asset_code + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({
      asset_code,
    }, () => {

      this.checkFields()
    })
  }


  changeSearchIssuer = (value) => {

    let asset_issuer = '', count = 0;
    for (const key of Object.keys(value)) {
      asset_issuer = (asset_issuer + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({
      asset_issuer,
    }, () => {

      this.checkFields()
    })
  }


  changeSearchDomain = (value) => {

    let domain = '', count = 0;
    for (const key of Object.keys(value)) {
      domain = (domain + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({
      domain,
    }, () => {

      this.checkFields()
    })
  }


  clearCode = (e, type) => {
    e.preventDefault()
    // localStorage.removeItem('lq_code')

    if (type === 'code') {

      this.props.change('asset_code', '')

      this.setState({ asset_code: '' })
    }

    if (type === 'issuer') {

      this.props.change('asset_issuer', '')

      this.setState({ asset_issuer: '' })
    }

    if (type === 'domain') {

      this.props.change('home_domain', '')

      this.setState({ domain: '' })
    }

    setTimeout(() => {
      this.getSearchFieldData()
    }, 100)
  }



  render() {
    // console.log('state: ', this.state)

    const { handleSubmit, reset, t } = this.props;
    const { asset_code, asset_issuer, all_assets, vol_24, vol_24_usd, holders, today_assets, week_assets, month_assets, price, domain } = this.state



    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.need_add_signers && this.state.transaction ?
              <RequiredSigners account={this.state.account}
                               add_signers={this.addSigners}
                               transaction={this.state.transaction}
                               btn_name={this.state.btn_name}
                               icon={this.state.btn_icon}
                               type_tx={this.state.type_tx} />
              : null
        }

        {/*{ this.renderModal() }*/}

        {/*<AcceptAsset asset={this.state.asset} />*/}

        {
          this.state.show_accept_modal &&
          <AcceptAsset
            asset={this.state.asset}
            toggleAcceptModal={this.toggleAcceptModal}
            wallets={this.state.wallets}/>
        }


        <Row>
          <Col md={12}>
            <h3 className="page-title">Stellar Assets
              <span style={{float: 'right'}}>
                  <Link to={'/create-token'}
                        style={{padding: '5px 14px'}}
                        className={`btn btn-success btn-sm`}><b><i className={'fa fa-plus'}></i> Create</b></Link>
                </span>
            </h3>
            <h3 className="page-subhead subhead">
              Scopuly does not track all assets that exist on Stellar, but only those that users have added. <b className={'text-success'}>+</b><b className={'text-success'}>{numFormat(month_assets)}</b> new assets monthly.
            </h3>
          </Col>
        </Row>


        <Card>
          <CardBody>

            {/*{*/}
            {/*  this.state.load_search_data || this.state.start_get_assets ?*/}
            {/*    <SearchField search_data={this.getSearchFieldData}*/}
            {/*                 asset={this.state.search_data}*/}
            {/*                 filter_assets={this.filterAssets}*/}
            {/*    />*/}
            {/*  : null*/}
            {/*}*/}


            {
              all_assets ?
                <div className={''}>

                  <Row className={'text-secondary uppercase'}>
                    <Col md={6} xs={6}>
                      <Row>
                        <Col md={6}>
                          <div><small><b className={'text-secondary'}>Vol 24</b> <b className={'text-warning'}>USD</b>:</small> <b className={'text-info'}>${formatCount(vol_24_usd, true, 2)}</b></div>
                          <div><small><b className={'text-secondary'}>Vol 24</b> <b className={'text-warning'}>XLM</b>:</small> <b className={'text-info'}>{formatCount(vol_24, true, 2)}</b></div>
                        </Col>
                        <Col md={6}>
                          <div><small><b className={'text-secondary'}>Assets:</b></small> <b className={'text-info'}>{numFormat(all_assets)}</b></div>
                          <div><small><b className={'text-secondary'}>Month:</b></small> <b className={'text-info'}><span className={'text-success'}>+</span>{numFormat(month_assets)}</b></div>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={6} xs={6}>
                      <Row>
                        <Col md={6}>
                          <div><small><b className={'text-secondary'}>Week:</b></small> <b className={'text-info'}><span className={'text-success'}>+</span>{numFormat(week_assets)}</b></div>
                          <div><small><b className={'text-secondary'}>Today:</b></small> <b className={'text-info'}><span className={'text-success'}>+</span>{numFormat(today_assets)}</b></div>
                        </Col>
                        <Col md={6}>
                          <div><small><b className={'text-secondary'}>Holders:</b></small> <b className={'text-info'}>{formatCount(holders, true, 2)}</b></div>
                          <div><small><b className={'text-secondary'}>XLM</b> <b className={'text-secondary'}>Price:</b></small> <b className={'text-info'}>${numFormat(price, 4)}</b></div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                </div>
                : null
            }

            <hr/>


            <form className="material-form" onSubmit={handleSubmit(this.assetForm)}>

              <Row>
                <Col md={2}>
                  {/*<div className="form__form-group">*/}
                  {/*  <span className="form__form-group-label"><b>Asset Code</b></span>*/}
                  {/*  <div className="form__form-group-field">*/}
                  {/*    <Field*/}
                  {/*      name="asset_code"*/}
                  {/*      component="input"*/}
                  {/*      type="text"*/}
                  {/*      placeholder="Code"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div>
                    <Field
                      name="asset_code"
                      component={renderTextField}
                      type="text"
                      label="Asset Code"
                      value={asset_code}
                      onChange={this.changeSearchCode}
                    />
                    <span className={'material-field-icon'}>
                         {
                           asset_code ?
                             <a href="#" onClick={(e) => this.clearCode(e, 'code')}>
                               <i className={'fa fa-close'}></i>
                             </a>
                             :
                             <i className={'fa fa-search'}></i>
                         }
                       </span>
                  </div>
                </Col>

                <Col md={2}>
                  {/*<div className="form__form-group">*/}
                  {/*  <span className="form__form-group-label"><b>Asset Issuer</b></span>*/}
                  {/*  <div className="form__form-group-field">*/}
                  {/*    <Field*/}
                  {/*      name="asset_issuer"*/}
                  {/*      component="input"*/}
                  {/*      type="text"*/}
                  {/*      placeholder="Issuer"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div>
                    <Field
                      name="asset_issuer"
                      component={renderTextField}
                      type="text"
                      label="Asset Issuer"
                      value={asset_issuer}
                      onChange={this.changeSearchIssuer}
                    />
                    <span className={'material-field-icon'}>
                         {
                           asset_issuer ?
                             <a href="#" onClick={(e) => this.clearCode(e, 'issuer')}>
                               <i className={'fa fa-close'}></i>
                             </a>
                             :
                             <i className={'fa fa-search'}></i>
                         }
                       </span>
                  </div>
                </Col>

                <Col md={2}>

                  <div>
                    <Field
                      name="home_domain"
                      component={renderTextField}
                      type="text"
                      label="Domain"
                      value={domain}
                      onChange={this.changeSearchDomain}
                    />
                    <span className={'material-field-icon'}>
                         {
                           domain ?
                             <a href="#" onClick={(e) => this.clearCode(e, 'domain')}>
                               <i className={'fa fa-close'}></i>
                             </a>
                             :
                             <i className={'fa fa-search'}></i>
                         }
                       </span>
                  </div>
                </Col>


                <Col md={{offset: 4, size: 2}}>
                  <div className="material-form">
                    <div className={''}>
                       {' '}
                      <Field
                        name="select_filter"
                        component={renderTextField}
                        select
                        label={(<span><i className={'fa fa-filter'}></i> Filter</span>)}
                        onChange={(e) => {
                          this.filterAssets(e)
                        }} >
                        <MenuItem
                          className={`material-form__option`}
                          value={'rating'}>
                          <p>Rating</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'liquidity'}>
                          <p>Liquidity</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'vol_24'}>
                          <p>Vol 24h</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'volume7d'}>
                          <p>Vol 7d</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'holders'}>
                          <p>Holders</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'trades'}>
                          <p>Trades</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'payments'}>
                          <p>Payments</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'interop'}>
                          <p>Interoperability</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'spread'}>
                          <p>Spread</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'supply'}>
                          <p>Supply</p>
                        </MenuItem>
                        <MenuItem
                          className={`material-form__option`}
                          value={'age'}>
                          <p>Age</p>
                        </MenuItem>
                      </Field>
                    </div>
                  </div>
                </Col>



                {/*<Col md={2} >*/}
                {/*  <div className="form__form-group">*/}
                {/*    <span className="form__form-group-label"></span>*/}
                {/*    <div className="form__form-group-field">*/}
                <Button color="secondary" type="submit" outline style={{display: 'none'}} >Search</Button>
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</Col>*/}
              </Row>

            </form>

            {/*<hr/>*/}


            <br/>

            {
              this.state.price ?

                this.state.start_get_assets ?
                  <TopAssets
                    acceptAsset={this.acceptAsset}
                    limit={this.state.limit}
                    {...this.state} />
                :
                  this.state.load_search_data ?
                    <TopAssets
                      acceptAsset={this.acceptAsset}
                      // search_data={this.state.search_data}
                      filter={this.state.filter}
                      // searchResult={this.searchResult}
                      {...this.state} />
                :

                  !this.state.search_asets_db &&
                  !this.state.stellar_assets.length &&
                  this.props.match.params.id ?
                    <p className={'text-center'}>The asset was not found in Scopuly. Searching from Stellar Ledger... <br/><br/></p>
                  : null
                // : null


                : null
            }

            {
              this.state.stellar_assets.length ?
                <div>
                  <p className={'card__title'}><b>All Stellar Assets ({this.state.stellar_assets.length})</b></p>
                  <br/>
                  <StellarAssets filter_asset={this.state.search_data.asset_code}
                                 stellar_assets_count={this.stellarAssetsCount}
                                 assets={this.state.stellar_assets}
                  />
                </div>
                : null
            }

            {
              this.state.search_data &&
              !this.state.loading_stellar_assets &&
              !this.state.stellar_assets.length ?
                <div className={'text-center'}>
                  <Button color="secondary" onClick={() => this.getStellarAssets(this.props.match.params.id)} >
                    Get all <b className={'text-info'}>{this.state.search_data.asset_code}</b> from Stellar</Button>
                </div>
                : null
            }

            {
              this.state.loading_stellar_assets ?
                <p className={'text-center'}>
                  <PulseLoader
                    css={override}
                    size={10}
                    color={"#4ce1b6"}
                    loading={true} />
                  <br/>Search all <b className={'text-warning'}>{this.state.search_data.asset_code}</b> tokens from Stellar<br/><br/>
                </p>
                : null
            }
          </CardBody>
        </Card>

        <Footer />

      </Container>
    );
  }
}


Assets.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'assets_form',
})(translate('common')(Assets));
