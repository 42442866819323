import React, { PureComponent } from 'react';
import {Container, Card, CardBody, Col, Row, Table} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../Account/components/iconIdent';
import {numFormat, formatDate, getAsset, shortAddr, shortAddress, formatCount} from '../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";
import ReactApexChart from "react-apexcharts";
import getPrice from "../../App/GetPrice";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getMarkets} from "../../../endpoints/API";
import AllMarkets from '../Asset/components/Markets';
import Footer from "../../Layout/footer/Footer";



class Markets extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      price: 0,
      markets: [],
      cursor_markets: '',
      markets_more_btn: false,
      assets: [],
      load_more: false,
    };
  }

  limit_items = 60

  componentDidMount = () => {

    // console.log('params.id: ', this.props.match.params.id)

    // this.getBasePrice()
  }


  // getBasePrice = () => {
  //   let price = JSON.parse(localStorage.getItem('price'));
  //
  //   if (price && price.crypto_price) {
  //     this.setState({ price: price.crypto_price.xlm_usd });
  //   }
  //   else if (price && price.local_price) {
  //     this.setState({ price: price.local_price.USD })
  //   }
  //   else {
  //     if (this.count < 3) {
  //       getPrice()
  //
  //       setTimeout(() => {
  //         this.getBasePrice()
  //         this.count++;
  //       }, 2000)
  //     }
  //   }
  //
  //   this.getMarkets()
  // }


  // getMarkets = () => {
  //
  //   const { markets, cursor_markets  } = this.state;
  //
  //   this.setState({ load_more: false })
  //
  //   getMarkets('', this.limit_items, cursor_markets).then(result => {
  //
  //     const markets_parse = JSON.parse(result.data.markets)
  //
  //     if (markets_parse) {
  //       const records = markets_parse._embedded.records;
  //
  //       if (records.length < this.limit_items || records.length === 0) {
  //         this.setState({ markets_more_btn: false });
  //       } else {
  //         this.setState({ markets_more_btn: true });
  //       }
  //
  //       this.setState({
  //         markets: markets.concat(records),
  //         cursor_markets: records[records.length - 1].paging_token,
  //         load_more: true,
  //         assets: result.data.assets,
  //       })
  //     }
  //   })
  // }



  render() {
    // console.log('this.props: ', this.props)
    // console.log('state: ', this.state)

    const { markets, assets, price } = this.state
    const params_id = this.props.match.params.id

    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title"> {params_id ? <span>All <b>{params_id.split('-')[0]}</b> Markets</span> : <span>Markets</span>}
              <span style={{float: 'right'}}>
                {/*<Link to={'/create-token'} className={`btn btn-secondary`}>Create Token</Link>*/}
              </span>
            </h3>
            <h3 className="page-subhead subhead">
              Market data is provided by the <a href="stellar.expert" target={'_blank'}>stellar.expert</a> service.
            </h3>
          </Col>
        </Row>

        <Card>
          <CardBody>

            <AllMarkets asset={params_id ? params_id.split('-') : false}  />

          </CardBody>
        </Card>

        <Footer />

      </Container>
    )
  }
}


export default translate('common')(Markets);
