import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import TimeLineItem from '../../../shared/components/TimeLineItem';

const icon_logo = `${process.env.PUBLIC_URL}/img/logo/sky_icon.png`;

const Roadmap = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="landing__section-title"><b>Roadmap</b> 2019-2022</h3>
      </Col>
    </Row>
    <Row className="">
      <Col md={12}>
        <div className="timeline">
          <TimeLineItem img={icon_logo} title="Expansion and improvement">
            <p>Active expansion and improvement of Scopuly platform services. Community development and support.
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Manage multi-signatures">
            <p>Manage multi-signatures (getting xdr transactions for signing or sending for signature). Multisig Coordinator.
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Development of a trading platform">
            <p>Development of a trading platform (adding new trading tools: analytics of assets, prediction of markets, etc., control of income / expenditure on investments, more flexible management of orders).
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Development of Block Explorer">
            <p>Development of Block Explorer and its connection with other functions of the application (aggregation and display of even more data for analysis, comparative and auxiliary functions of blockchain research, event subscriptions by Addresses and Assets).
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Improved Path Payment">
            <p>Improved management of payment method Path Payment (search path payment + selection mechanism and add path payment).
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Stablecoin PULX">
            <p>Run stablecoin PULX. We will launch stablecoin so that users of the Scopuly platform can enjoy all the advantages of a stable coin where they already have confidence.
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Crowdfunding platform">
            <p>Crowdfunding platform (STO / IEO / Crowdfunding). Scopuly will have an additional service for startups and investors.
            </p>
          </TimeLineItem>
          <TimeLineItem img={icon_logo} title="Loyalty Programs">
            <p>Loyalty program project (global replacement of plastic loyalty cards with loyalty tokens with a global market for loyalty tokens).
            </p>
          </TimeLineItem> 
        </div>
        <br/><br/><br/><br/><br/><br/>
      </Col>
    </Row>
    
  </Container>
);

export default Roadmap;
