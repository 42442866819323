import React, { PureComponent } from 'react';
import { Redirect } from 'react-router-dom'
import { Button, ButtonGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';

class Login extends PureComponent {

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      loader: false,
      redirect: false,
    };

    this.showPassword = this.showPassword.bind(this);
  }

  showPassword(e) {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }



  loginSubmit = (values) => {
    // console.log('values: ', values)

    if (!values.email) {
      ToastStore.warning(`Enter your email`);
    }
    else if (!values.password) {
      ToastStore.warning(`Enter your Password`);
    }
    else {

      this.setState({ loader: true })

      const formData = new FormData();
      formData.append('email', values.email)
      formData.append('pass', values.password)

      axios.post(GLOBE.API_URL+`/login_user`, formData)
        .then(response => {
          // console.log('response: ', response)

          if (response.data > 0) {

            const user = {
              email: values.email,
              user_id: response.data,
            }

            localStorage.setItem('auth_user', JSON.stringify(user));

            ToastStore.success(`Account successfully authorized!`);

            if (window.location.hash === '#launchpad') {
              this.setState({ 
                redirect: true,
                redirect_path: '/launchpad',
              })
            }
            else if (window.location.hash.indexOf('edit') === 1) {
              let path_hash = window.location.hash.split('/');
              // console.log('path_hash: ', path_hash)
              
              this.setState({ 
                redirect: true,
                redirect_path: '/edit/'+path_hash[1],
              })
            }
            else {
              setTimeout(() => {
                this.setState({
                  redirect: true,
                  redirect_path: '/profile',
                })
              }, 1000)
            }
          }
          else if (response.data === 'no-email') {
            ToastStore.warning(`There is no user with such email.`);
          }
          else if (response.data === 'no-pass') {
            ToastStore.warning(`The password for this email does not match the one set.`);
          }
      });

      // return

      // post - check user
      // axios.post(GLOBE.API_URL+`/login_user?email=${values.email}&pass=${values.password}`)
      //   .then(response => {
      //     console.log('response: ', response)

      //     return

      //     this.setState({ loader: false })

      //     if (response.data > 0) {

      //       localStorage.setItem('auth_user', values.email);

      //       ToastStore.success(`Account successfully created and authorized!`);

      //       if (window.location.hash === '#launchpad') {
      //         this.setState({ 
      //           redirect: true,
      //           redirect_path: '/launchpad',
      //         })
      //       }
      //       if (window.location.hash.indexOf('edit') === 1) {
      //         let path_hash = window.location.hash.split('/');
      //         console.log('path_hash: ', path_hash)
              
      //         this.setState({ 
      //           redirect: true,
      //           redirect_path: '/edit/'+path_hash[1],
      //         })
      //       }
      //     }
      //     else if (response.data === 'no-email') {
      //       ToastStore.warning(`There is no user with such email.`);
      //     }
      //     else if (response.data === 'no-pass') {
      //       ToastStore.warning(`The password for this email does not match the one set.`);
      //     }
      // });

      
    }
  }



  comingSoon() {
    ToastStore.info(`Coming soon.`);
  }




  render() {
    const { handleSubmit } = this.props;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect_path}/>
    }



    return (

      <div className="account">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} /> 

        <div className="account__wrapper">
          <div className="account__card">
            <div className="account__head">
              <h3 className="account__title">Welcome to
                <span className="account__logo"> Scopuly
                  {/*<span className="account__logo-accent">DEV</span>*/}
                </span>
              </h3>
              <h4 className="account__subhead subhead">User Authorization</h4>
            </div>
            

            <form className="form" onSubmit={handleSubmit(this.loginSubmit)}>
              <div className="form__form-group">
                <span className="form__form-group-label">Username</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <MailRuIcon />
                  </div>
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    placeholder="example@mail.com"
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <KeyVariantIcon />
                  </div>
                  <Field
                    name="password"
                    component="input"
                    type={this.state.showPassword ? 'text' : 'password'}
                    placeholder="Password"
                  />
                  <button
                    className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                    onClick={e => this.showPassword(e)}
                  ><EyeIcon />
                  </button>
                </div>
                <div className="account__forgot-password">
                  <Link to="/forgot">Forgot a password?</Link>
                </div>
              </div>
              <div className="account__btns">
                <Button className="account__btn" 
                        color="primary" 
                        type="submit" 
                        style={{margin: '10px 0 20px 11px'}}>Login</Button>
                <Link className="btn btn-outline-primary account__btn" to="/signup">Create
                  Account
                </Link>
              </div>
            </form>



            <div className="account__or">
              <p>Or Easily Using</p>
            </div>
            <div className="account__social">
              <a
                className="account__social-btn account__social-btn--facebook"
                href="#"
                onClick={this.comingSoon}
              ><FacebookIcon />
              </a>
              <a
                className="account__social-btn account__social-btn--google"
                href="#"
                onClick={this.comingSoon}
              ><GooglePlusIcon />
              </a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: 'login_form', // a unique identifier for this form
})(Login);
