import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Field, reduxForm, change } from 'redux-form';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import { getTitle } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';



class Profile extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      auth_user: localStorage.getItem('auth_user'),
    };
  }

  componentDidMount() {
    getTitle(`Profile`);
  }



  logout = () => {

    swal({
      title: 'Confirmation!',
      text: "Do you really want to log out right now?",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((value) => {
        if (value) {
          localStorage.removeItem('auth_user');

          ToastStore.success('You have successfully logged out of your account.');

          this.setState({ auth_user: false, });
        }
      });
  }



  render() {

    const size_col = 6, offset_col = 3;


    return (
      <Container>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <h3 className="page-title">Profile</h3>
            {
              this.state.auth_user ?
                <h4 className="subhead" style={{marginBottom: '18px'}}>
                  <b>{this.state.auth_user ? JSON.parse(this.state.auth_user).email : null}</b> {' '}
                  <a href="#" onClick={this.logout} style={{float: 'right'}}><i className="fa fa-sign-out"></i> Logout</a>
                </h4>
                :
                <h4 className="subhead" style={{marginBottom: '18px'}}>
                  <Link to="login" ><i className="fa fa-sign-in"></i> Login</Link>
                </h4>
            }
          </Col>
        </Row>

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Referral Program</h5>
                  <h4 className="subhead">Referral Program</h4>
                </div>

                <p>Referral Program</p>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Staking</h5>
                  <h4 className="subhead">Token staking</h4>
                </div>

                <p>Token staking</p>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Home Domain Package</h5>
                  <h4 className="subhead">Home Domain Package</h4>
                </div>

                <p>20 USD</p>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Launchpad</h5>
                  <h4 className="subhead">IDO Project</h4>
                </div>

                <p>IDO Project: view/edit</p>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: size_col, offset: offset_col }}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">KYC</h5>
                  <h4 className="subhead">KYC</h4>
                </div>

                <p>Not requirement</p>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Profile.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'profile_form',
})(translate('common')(Profile));
