import React, { PureComponent } from 'react';
import { Col, Row, Card, CardBody, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { numFormat } from '../../../../modules/index';


let depthBuy = 0, 
    depthSell = 0, 
    resolution = 86400000,
    limit_items = 30, 
    maxDepthBuy = 0,
    maxDepthSell = 0,
    render_buy = 0,
    render_sell = 0,
    depthBuyShow = 0,
    depthSellShow = 0,
    allMaxDepthBuy = 0,
    allMaxDepthSell = 0




class Orderbook extends PureComponent {
  // static propTypes = {
  //   t: PropTypes.func.isRequired,
  // };

  constructor(props) {
    super(props);

    this.state = {
      asks_length: 0,
      bids_length: 0,
      asks_sum: 0,
      bids_sum: 0,
      maxDepthBuy: 0,
      maxDepthSell: 0,
      theme: localStorage.getItem('theme'),
      allMaxDepthSell: 0,
      allMaxDepthBuy: 0,
    };
  }

  componentDidMount() {  
    // 
  }



  renderBuyOffers(item, index) {

    let price = Number(item.price_r.d / item.price_r.n);

    depthBuy += Number(item.amount);
    depthBuyShow = depthBuy;

    let depthPercentage = Math.min(100, Number(depthBuy / maxDepthBuy * 100).toFixed(1)) * 2;

    if (this.props.orderbook_short.asks.length === index+1) {
      depthBuy = 0;
      maxDepthBuy = 0;
      render_buy = 0;
    }

    let them_color = '#fafbfefc'; 
    let striped_color = 'rgb(255, 255, 255)';
    if (this.state.theme === 'theme-dark') {
      them_color = '#232329';
      striped_color = '#38373f';
    }

    const green_color = '168, 251, 186'; // 231, 255, 231

    return (
      <tr key={index} style={{
                        borderBottom: '1px solid rgba(158, 158, 158, 0.1)', 
                        background: 'linear-gradient(to left, rgba('+green_color+') '+depthPercentage+'%, '+(index % 2 === 0 ? striped_color : them_color)+' '+depthPercentage+'%)'}}>
        <td><small>{numFormat(depthBuyShow, 2)} </small></td>
        <td>{numFormat(item.amount, 7)}</td>
        <td>{numFormat((item.amount * item.price), 7)}</td>
        <td>{numFormat(price, 7)}</td>
      </tr>
    ) 
  }


  renderSellOffers(item, index) {

    let price = Number(item.price_r.d / item.price_r.n);

    depthSell += Number(item.amount / item.price);
    depthSellShow = depthSell;

    let depthPercentage = Math.min(100, Number(depthSell / maxDepthSell * 100).toFixed(1)) * 2;
    // console.log('depthPercentage: ', depthPercentage)

    if (this.props.orderbook_short.bids.length === index+1) {
      depthSell = 0;
      maxDepthSell = 0;
      render_sell = 0;
    }

    let them_color = '#fafbfefc'; 
    let striped_color = 'rgb(255, 255, 255)';
    if (this.state.theme === 'theme-dark') {
      them_color = '#232329';
      striped_color = '#38373f';
    }

    const red_color = '255, 191, 191'; // 255, 243, 243

    return (
      <tr key={index} style={{borderBottom: '1px solid rgba(158, 158, 158, 0.1)', 
                              background: 'linear-gradient(to right, rgba('+red_color+') '+depthPercentage+'%, '+(index % 2 === 0 ? striped_color : them_color)+' '+depthPercentage+'%)'}}>
        <td>{numFormat(price, 7)}</td>
        <td>{numFormat(item.amount, 7)}</td>
        <td>{numFormat((item.amount / item.price), 7)}</td>
        <td><small>{numFormat(depthSellShow, 2)}</small></td>
      </tr>
    ) 
  }


  sumAskBid() {
    let asks_sum = 0, 
        bids_sum = 0,
        asks_each = false,
        bids_each = false;
        // allMaxDepthBuy = 0;

    this.props.orderbook.asks.forEach((item, index) => {

      asks_sum = (asks_sum + Number(item.amount * item.price));
      allMaxDepthBuy = (allMaxDepthBuy + Number(item.amount));

      if (this.props.orderbook.asks.length === (index+1)) {
        asks_each = true;
        // this.setState({
        //   allMaxDepthBuy,
        // })
      }
    })
    this.props.orderbook_short.asks.forEach((item, index) => {
      maxDepthBuy = (maxDepthBuy + Number(item.amount));
    })


    this.props.orderbook.bids.forEach((item, index) => { // all

      bids_sum = (bids_sum + Number(item.amount));
      allMaxDepthSell = (allMaxDepthSell + Number(item.amount / item.price));

      if (this.props.orderbook.bids.length === (index+1)) {
        bids_each = true;
        // this.setState({
        //   allMaxDepthSell,
        // })
      }
    })
    this.props.orderbook_short.bids.forEach((item, index) => { // short
      maxDepthSell = (maxDepthSell + Number(item.amount / item.price));
    })


    if (asks_each && asks_each) {

      this.setState({
        asks_length: this.props.orderbook.asks.length,
        asks_sum: asks_sum,
        bids_length: this.props.orderbook.bids.length,
        bids_sum: bids_sum,
      });
    }
  }

 
  render() {  

    render_buy++;
    render_sell++;

    if (render_buy >= 1 && render_sell >= 1) {
      if (render_buy < 2) {
        this.sumAskBid(); 
      } 
    } 


    let base_asset_code;
    let counter_asset_code = this.props.orderbook.counter.asset_code;
    if (this.props.orderbook.base.asset_type === 'native') {
      base_asset_code = 'XLM';
    }
    else {
      base_asset_code = this.props.orderbook.base.asset_code;
    }

    return (
      <div>
        {/*<div>
          <select>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </select>
        </div>*/}
        <Row>
          <Col md={6} lg={6} xl={6}>
            <Card>
              <div className="card__title">
                <h5 className="bold-text">Buy Offers ({this.state.asks_length})</h5>
                <h5 className="subhead">ASKS: <b className="text-info">{numFormat(this.state.asks_sum)}</b> <b className="text-warning">{counter_asset_code}</b> / <span><b className="text-info">{numFormat(allMaxDepthBuy)}</b> <b className="text-warning">{base_asset_code}</b></span></h5>
              </div>
              <Table striped responsive hover className="text-right">
                <thead>
                  <tr>
                    <th><small>Depth {base_asset_code}</small></th>
                    <th>{base_asset_code}</th>
                    <th>{counter_asset_code}</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>

                  { render_buy >= 2 ? this.props.orderbook_short.asks.map(this.renderBuyOffers.bind(this)) : null }

                  {/*{this.state.orderbook_buy ? this.state.orderbook_buy.map(this.renderBuyOffers.bind(this)) : null}*/}

                </tbody>
              </Table>
            </Card>
          </Col>

          <Col md={6} lg={6} xl={6}>
            <Card>
              <div className="card__title">
                <h5 className="bold-text">Sell Offers ({this.state.bids_length})</h5>
                <h5 className="subhead">BIDS: <b className="text-info">{numFormat(this.state.bids_sum)}</b> <b className="text-warning">{counter_asset_code}</b> / <span><b className="text-info">{numFormat(allMaxDepthSell)}</b> <b className="text-warning">{base_asset_code}</b></span></h5>
              </div>
              <Table striped responsive hover>
                <thead>
                  <tr>
                    <th>Price</th>
                    <th>{counter_asset_code}</th>
                    <th>{base_asset_code}</th>
                    <th><small>Depth {base_asset_code}</small></th>
                  </tr>
                </thead>
                <tbody>
                  
                  { render_sell >= 2 ? this.props.orderbook_short.bids.map(this.renderSellOffers.bind(this)) : null }
                  {/*{this.state.orderbook_sell ? this.state.orderbook_sell.map(this.renderSellOffers.bind(this)) : null}*/}

                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default translate('common')(Orderbook);
