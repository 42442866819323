import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo.png`;

const Purchase = () => (
  <section className="landing__section">
    <Container>
      <Row style={{
        padding: '140px 35px 140px',
        borderRadius: '12px',
        boxShadow: '14px 14px 30px 0 rgb(0 0 0 / 30%)',
        margin: 0,
      }}>
        <Col md={12}>
          <h3 className="landing__section-title">
            {/*Multicurrency <b>payments</b> and <b>decentralized trade</b> in tokens on the most progressive and fast blockchain
            <br/><span><img src={stellar_logo} style={{width: '3%'}}/> <b>Stellar</b></span>.*/}
            Scopuly is the perfect way to <b>buy</b>, <b>store</b>, <b>invest</b>, <b>trade</b>, <b>pay</b> and <b>transfer</b> crypto assets.
          </h3>
          <br/>
          <div className="landing__center-btn">
            <Link
              className="landing__btn landing__btn--header landing__btn--gradient"
              to="/auth/create"
            >
              Create Account
            </Link>
            {/*<Link*/}
            {/*  className="landing__btn landing__btn--header landing__btn--gradient"*/}
            {/*  to="/auth/connect"*/}
            {/*>*/}
            {/*  Connect Wallet*/}
            {/*</Link>*/}
            <Link
              className="landing__btn landing__btn--header"
              to="/auth/connect"
            >
              Connect Wallet
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default Purchase;
