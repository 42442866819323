import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Server } from '../../../modules/stellar/index';
import { shortAddr, shortPool, numFormat, getClaimantStatus, getNameAddr, getDirectory, getTitle, getAsset, shortAddress, formatDate } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import moment from 'moment';
import Footer from '../../Layout/footer/Footer';
import {ToastStore} from "react-toasts";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Effects from "../Account/components/Effects/Effects";
import iconIdent from "../Account/components/iconIdent";



class Operation extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      operation: {},
      oper_load: false,
      transaction: false,
      source_dir_name: false,
      effects: [],
      effects_loaded: false,
    };
  }

  componentDidMount() {
    getTitle(`Operation: ${this.props.match.params.id}`);

    this.getOperation();
    this.getEffects()
    this.onRefresh();
  }

  onRefresh = () => {
    this.setState({ refresh: true });
  };


  getOperation() {

    const id = this.props.match.params.id

    Server.operations()
      .operation(id)
      .call()
      .then( (operationsResult) => {
        console.log('operationsResult: ', operationsResult)

        this.getTransaction(operationsResult.transaction_hash)

        this.setState({
          operation: operationsResult,
          oper_load: true,
          refresh: false
        })
      })
      .catch( (err) => {
        console.log(err)
      })
  }


  getTransaction(hash) {

    Server.transactions()
      .transaction(hash)
      .call()
      .then( (result) => {

        this.setState({ transaction: result });

        getDirectory(result.source_account)
          .then(data => {
            this.setState({
              source_dir_name: data.name
            })
          })
          .catch(error => {
            console.log('getDirectory error: ', error)
          });
      })
      .catch( (err) => {
        console.log('err: ', err)
        ToastStore.error('Error receiving transaction...');
      })
  }


  formatDate(time) {
    return moment(time).format("DD MMMM YYYY HH:mm:ss");
  }


  itemType(type) {
    return type.replace(/!/g," ")[0].toUpperCase() + type.replace(/!/g," ").slice(1);
  }

  renderHomeDomain(domain) {
    return (
      <p>
      Home domain set to: <a href={'https://'+domain}> <b>{ domain }</b> </a>
      </p>
      )
  }

  renderSigner(signer_key, signer_weight) {
    return (
      <p>
      Add new signer <b>{ shortAddress(signer_key) }</b> with weight: <b className="text-info"> { signer_weight } </b>
      </p>
      )
  }

  renderLow(low_threshold) {
    return (
      <p>
      Low threshold set to: <b className="text-info"> { low_threshold } </b>
      </p>
      )
  }

  renderMed(med_threshold) {
    return (
      <p>
      Med threshold set to: <b className="text-info"> { med_threshold } </b>
      </p>
      )
  }

  renderHigh(high_threshold) {
    return (
      <p>
      High threshold set to: <b className="text-info"> { high_threshold } </b>
      </p>
      )
  }

  renderInfl(inflation_dest) {
    return (
      <p>
      Inflation dest set to: <b>{ shortAddress(inflation_dest) }</b>
      </p>
      )
  }

  renderMaster(master_key_weight) {
    return (
      <p>
      Master key weight set to: <b className="text-info"> { master_key_weight } </b>
      </p>
      )
  }

  renderSetFlags(set_flags_s) {
    var rows = [];
    for(var i = 0; i < set_flags_s.length; i++) {
      rows.push(<p key={i}> Set flag <b className="text-info"> { set_flags_s[i] }</b></p>);
    }
    return rows;
  }

  renderClearFlags(clear_flags_s) {

    var rows = [];
    for(var i = 0; i < clear_flags_s.length; i++) {
      rows.push(<p key={i}> Clear flag <b className="text-info"> { clear_flags_s[i] }</b></p>);
    }
    return rows;
  }

  renderTxHash(tx_hash) {
    return (
      <p>Transaction: <b className="text-info"><Link to={`/transaction/${tx_hash}`}>{ shortAddr(tx_hash, 4) }</Link></b> {' '}
        <CopyToClipboard text={tx_hash} onCopy={() => {}}>
          <i className="fa fa-clone"
             color="secondary"
             style={{cursor: 'pointer', fontSize: 14}}
             onClick={() => ToastStore.success('Copied!')}></i>
        </CopyToClipboard>
      </p>
    )
  }


  renderPathPayment = (item) => {

    const renderFirstAsset = () => {
      return <Link to={item.source_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.source_asset_code}-XLM/${item.source_asset_issuer}/native`} className="text-warning"> <b>{item.source_asset_type === 'native' ? 'XLM' : item.source_asset_code}</b></Link>
    }

    return (
      <div>
        <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
        <p>{ shortAddress(item.from) } sent <b className="text-info">{ numFormat(item.source_amount, 7) }</b> { renderFirstAsset() }</p>
        <p>{ shortAddress(item.to) } received <b className="text-info">{ numFormat(item.amount, 7) }</b> { getAsset(item) }</p>
        <p>
          {/*{item.path.length ? 'Path:' : ''}*/}
          Path: [ {' '}
          { renderFirstAsset() } {' '}
          <i className="fa fa-angle-right"></i> {' '}
            <span>
              {
                item.path.map((pathItem, index) => {
                  return (
                    <b key={ index }> {getAsset(pathItem)} { item.path.length > (index+1) ?
                      (<span> <i className="fa fa-angle-right"></i> </span>) : '' }</b>
                  )
                })
              }
            </span> {' '}
          {item.path.length ? <i className="fa fa-angle-right"></i> : ''}  { getAsset(item) } {' '}
          ]
        </p>
        { this.renderTxHash(item.transaction_hash) }
        <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
      </div>
    )
  }

  getEffects = () => {
    const operationId = this.props.match.params.id

    Server
      .effects()
      .forOperation(operationId)
      .limit(200)
      .call()
      .then((resp) => {
        console.log('effects resp: ', resp);

        if (resp.records) {
          this.setState({
            effects: resp.records,
            effects_loaded: true,
          })
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }


  renderOperation() {
    const item = this.state.operation;

    // console.log('renderOperation item: ', item)

    switch(item.type_i) {
      case 0:
        return (
          <div>
            <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
            <p>Created: <b className="text-info">{ this.formatDate(item.created_at) }</b></p>
            <p>From { shortAddress(item.funder) } sent <b className="text-info">{numFormat(item.starting_balance, 7)}&nbsp;
              <Link to={'/asset/XLM-native'} className="text-warning">XLM</Link></b> to { shortAddress(item.account) }</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 1:
        return (
          <div>
            <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
            <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
            <p>From { shortAddress(item.from) } sent <b className="text-info">{numFormat(item.amount, 7)} { getAsset(item) } </b> to { shortAddress(item.to) }</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 2:
        return this.renderPathPayment(item)

      case 13:
        return this.renderPathPayment(item)

      case 3: // "manage_sell_offer"
        return (
            <div>
              <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
              <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
              <p>Offer id: <b className="text-info">{item.offer_id}</b> { item.offer_id ? '' : '(close)' }</p>
              {/*<p>Buy: <b className="text-info">{numFormat(item.amount / item.price, 7)}</b> <Link to={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></Link></p>*/}
              <p>Sell: <b className="text-info">{numFormat(item.amount, 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link> for <Link to={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></Link> { parseFloat(item.amount) ? '' : '(cancelled)' }</p>
              {/*<p>Price: {' '}*/}
              {/*  <b className="text-info">{numFormat((item.price), 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link>*/}
              {/*</p>*/}
              { this.renderTxHash(item.transaction_hash) }
            </div>
        )

      case 12:
        return (
            <div>
              <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
              <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
              <p>OfferId: <b className="text-info">{item.offer_id}</b> { item.offer_id ? '' : '(close)' }</p>
              <p>Buy: <b className="text-info">{numFormat(item.amount, 7)} </b> <Link to={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></Link> for <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link></p>
              {/*<p>Sell: <b className="text-info">{numFormat(item.amount * item.price, 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link> { parseFloat(item.amount) ? '' : '(cancelled)' }</p>*/}
              {/*<p>Price: <b className="text-info">{numFormat((item.price), 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link></p>*/}
              { this.renderTxHash(item.transaction_hash) }
            </div>
        )

      case 4:
        return (
            <div>
              <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
              <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
              <p>OfferId: <b className="text-info">{item.offer_id}</b> { item.offer_id ? '' : '(close)' }</p>
              <p>Buy: <b className="text-info">{numFormat(item.amount * item.price, 7)}</b> <Link to={item.buying_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.buying_asset_code}-XLM/${item.buying_asset_issuer}/native`} className="text-warning"><b>{item.buying_asset_type === 'native' ? 'XLM' : item.buying_asset_code}</b></Link></p>
              <p>Sell: <b className="text-info">{numFormat(item.amount, 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link> { parseFloat(item.amount) ? '' : '(cancelled)' }</p>
              <p>Price: <b className="text-info">{numFormat(item.price, 7)}</b> <Link to={item.selling_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.selling_asset_code}-XLM/${item.selling_asset_issuer}/native`} className="text-warning"><b>{item.selling_asset_type === 'native' ? 'XLM' : item.selling_asset_code}</b></Link></p>
              { this.renderTxHash(item.transaction_hash) }
            </div>
        )

      case 5:
        return (
            <div>
              <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
              <p>Created: <b className="text-info">{this.formatDate(item.created_at)}</b></p>
              { item.home_domain ? this.renderHomeDomain(item.home_domain) : '' }
              { item.signer_key ? this.renderSigner(item.signer_key, item.signer_weight) : '' }
              { item.low_threshold >= 0 ? this.renderLow(item.low_threshold) : '' }
              { item.med_threshold >= 0 ? this.renderMed(item.med_threshold) : '' }
              { item.high_threshold >= 0 ? this.renderHigh(item.high_threshold,) : '' }
              { item.master_key_weight >= 0 ? this.renderMaster(item.master_key_weight) : '' }
              { item.inflation_dest ? this.renderInfl(item.inflation_dest) : '' }
              { item.set_flags_s ? this.renderSetFlags(item.set_flags_s) : '' }
              { item.clear_flags_s ? this.renderClearFlags(item.clear_flags_s) : ''}
              { this.renderTxHash(item.transaction_hash) }
            </div>
        )

      case 6:
        return(
          <div>
            <p>Type: <b className="text-info">{ this.itemType(item.type) }</b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>{shortAddress(item.source_account)} {parseFloat(item.limit) ? 'Created trustline for ' : 'Removed trustline for ' } { item.liquidity_pool_id ? <span> {shortPool(item.liquidity_pool_id)} </span> : getAsset(item) }</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 7:
        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>For { getAsset(item) } set <b className="text-info"> ALLOW TRUST </b> to <b className="text-info"> { item.authorize ? ' true ' : ' false ' } </b> for { shortAddress(item.trustor) }</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 8:
        return(
          <div>
          <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
          <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
          <p>{ shortAddress(item.source_account) }  deleted and merge with { shortAddress(item.into) }</p>
          { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 9:
        return(
          <div>
          <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
          <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
          <p>Inflation</p>
          { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 10:
        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>{ item.value ? 'Create or modify data' : 'Remove data' }</p>
            <p>Name: <b className="text-info"> { item.name }</b></p>
            <p>{ item.value ? 'Value: ' : '' } <b className="text-info"> { item.value ? item.value : '' }</b></p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 14:
        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>{shortAddress(item.sponsor)} sponsors <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item.asset)}</p>
            <p>Claimants:
              <ul>
                {
                  item.claimants.map((claimant, index) => {
                    console.log('claimant: ', claimant)

                    const status = getClaimantStatus(item, claimant).status
                    const end_time = getClaimantStatus(item, claimant).end_time

                    // console.log('end_time: ', end_time)

                    return (
                      <li key={index}> {index === 1 ? 'Sponsor: ' : 'Claimaint: '}
                        {shortAddress(claimant.destination)} {' '}
                        {' '} start time {' '}
                        <b className={'text-info'}>
                          {
                            claimant.predicate.and ?
                              formatDate(claimant.predicate.and[0].not ? claimant.predicate.and[0].not.abs_before : claimant.predicate.and[0].abs_before) :
                              (claimant.predicate.not) ? formatDate(claimant.predicate.not.abs_before)
                            : 'Not set'
                          }
                        </b>
                        {' '} / end time {' '}
                        <b className={'text-info'}>
                          {
                            (claimant.predicate.and) ?
                              (claimant.predicate.and[1].not ? formatDate(claimant.predicate.and[1].not.abs_before) : formatDate(claimant.predicate.and[1].abs_before)) :
                                (claimant.predicate.abs_before) ? formatDate(claimant.predicate.abs_before) :
                                  (claimant.predicate.not && claimant.predicate.not.abs_before_epoch) ? <b>Not set</b> :
                                    (end_time) ? formatDate(end_time)
                            : 'Not set'
                          }
                        </b> {' '}
                        {(status === 'Pending') ? <small className={'pending-status'}><b>{status}</b></small> : (status === 'Expired') ? <small className={'expired-status'}><b>{status}</b></small> : <small className={'upcoming-status'}><b>{status}</b></small>}
                        {' '}&nbsp; <small><Link to={`/claimable-balances/${claimant.destination}`}><i className="fa fa-clock-o"></i> <b>all claims</b></Link></small>
                      </li>
                    )
                  })
                }
              </ul>
            </p>
            { this.renderTxHash(item.transaction_hash) }
            {/*<p><Link to={`/claimable-balances/${item.claimants[0].destination}`}>All Claimable Balances</Link></p>*/}
          </div>
        )

      case 15:
        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>Claimant {shortAddress(item.claimant)}</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )


      case 22:

        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>
              <i className="fa fa-arrow-down text-success" ></i>&nbsp; {shortAddress(item.source_account)} <b className={'text-success'}>deposited</b> <b className="text-info">{numFormat(item.reserves_deposited[0].amount, 7)}</b> {getAsset(item.reserves_deposited[0].asset)} / <b className="text-info">{numFormat(item.reserves_deposited[1].amount, 7)}</b> {getAsset(item.reserves_deposited[1].asset)} <b className="text-info">{numFormat(item.shares_received, 7)}</b> <b>pool shares</b> {' '}
              to {shortPool(item.liquidity_pool_id)}
            </p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )

      case 23:

        // const income_a = (item.reserves_received[0].amount - item.reserves_min[0].amount).toFixed(7)
        // const income_b = (item.reserves_received[1].amount - item.reserves_min[1].amount).toFixed(7)
        // const income_percent_a = (item.reserves_received[0].amount * income_a / 100).toFixed(2)
        // const income_percent_b = (item.reserves_received[1].amount * income_b / 100).toFixed(2)

        return(
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>
              <i className="fa fa-arrow-down text-danger" ></i>&nbsp; {shortAddress(item.source_account)} <b className={'text-danger'}>withdrew</b> <b className="text-info">{numFormat(item.reserves_received[0].amount, 7)}</b> {getAsset(item.reserves_received[0].asset)} / <b className="text-info">{numFormat(item.reserves_received[1].amount, 7)}</b> {getAsset(item.reserves_received[1].asset)} (<b className="text-info">{numFormat(item.shares, 7)}</b> <b>pool shares</b>) from {shortPool(item.liquidity_pool_id)} <br/>
              {/*income <span className={'text-success'}>+<b>{income_a}</b></span> {getAsset(item.reserves_received[0].asset)} <small>(<span className={'text-success'}>+{income_percent_a}%</span>)</small> / <span className={'text-success'}>+<b>{income_b}</b></span>  {getAsset(item.reserves_received[1].asset)} <small>(<span className={'text-success'}>+{income_percent_b}%</span>)</small>*/}
            </p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )


      default:
        console.log('default operations', item);

        return (
          <div>
            <p>Type: <b className="text-info"> { this.itemType(item.type) } </b></p>
            <p>Created: <b className="text-info"> {this.formatDate(item.created_at)} </b></p>
            <p>Cooming soon</p>
            { this.renderTxHash(item.transaction_hash) }
          </div>
        )
    }
  }

  loading = () => <div className="panel__refresh" style={{heigh: '76%'}}><LoadingIcon /></div>


  render() {
    // console.log('state: ', this.state)

    const { source_dir_name } = this.state;
    const tx = this.state.transaction;
    const name = getNameAddr(tx.source_account);

    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{textTransform: 'none'}}>Operation ID: <b className="text-info">{ this.props.match.params.id }</b> &emsp;
              <CopyToClipboard text={this.props.match.params.id} onCopy={() => {}}>
                <i className="fa fa-clone"
                   color="secondary"
                   style={{cursor: 'pointer', fontSize: 14}}
                   onClick={() => ToastStore.success('Copied!')}></i>
              </CopyToClipboard>
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Operation</h5>
                  {/*<h5 className="subhead">Operation ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                </div>

                {
                  this.state.oper_load ?
                    this.renderOperation()
                  :
                    this.loading()
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Effects for Operation
                    <b className={'float-right'}>(<span className={'text-info'}>{this.state.effects.length}</span>)</b>
                  </h5>
                  {/*<h5 className="subhead">Operation ID: <span className="red-text">{this.props.match.params.id}</span></h5>*/}
                </div>

                {
                  this.state.effects.length ?
                    <Effects items={this.state.effects} />
                    :
                    !this.state.effects_loaded ? this.loading() : <p>No effects</p>
                }

              </CardBody>

            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Transaction</h5>
                  <h5 className="subhead">Transaction ID: <Link to={`/transaction/${tx.hash}`}><b>{shortAddr(tx.hash, 6)}</b></Link></h5>
                </div>

                <div>
                  { !tx ? this.loading() : null }

                  {
                    tx &&
                    <Row>
                      <Col md={6} lg={6}>
                        <p>Source account: {shortAddress(tx.source_account)} {' '}
                          <CopyToClipboard text={tx.source_account} onCopy={() => {}}>
                            <i className="fa fa-clone"
                               color="secondary"
                               style={{cursor: 'pointer', fontSize: 14}}
                               onClick={() => ToastStore.success('Copied!')}></i>
                          </CopyToClipboard>
                        </p>
                        <p>Memo type: <b className="text-info">{ tx.memo_type } </b></p>
                        <p>Memo: <b className="text-info">{ tx.memo_type === "none" ? "none" :  tx.memo }</b></p>
                        <p>Ledger: <b className="text-info">{ tx.ledger_attr } </b></p>
                      </Col>
                      <Col md={6} lg={6}>
                        <p>Created: <b className="text-info">{ tx ? this.formatDate(tx.created_at) : '...'} </b></p>
                        <p>Operations: <b className="text-info"><Link to={`/transaction/${tx.id}`}>{ tx.operation_count }</Link> </b></p>
                        <p>Fee paid: <b className="text-info">{ numFormat(tx.fee_charged / 10000000, 7) } <Link to="/XLM-native" className="text-warning">XLM</Link> </b></p>
                        <p>Sequence: <b className="text-info">{ tx.source_account_sequence } </b></p>
                      </Col>
                      <Col md={12} lg={12}>
                        <br/>
                        <p><b>Signatures ({tx.signatures ? tx.signatures.length : '...'}):</b></p>

                        { tx ? tx.signatures.map((item, index) => <p key={index}><b>{index+1}.</b> {item}</p>) : '' }
                      </Col>
                    </Row>
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Operation.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Operation);
