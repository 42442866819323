import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Popover, PopoverHeader, PopoverBody, UncontrolledPopover,
  Button,
  ButtonGroup,
  ButtonToolbar,
  UncontrolledTooltip
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import StellarSdk from "stellar-sdk";
import swal from "sweetalert";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../Account/components/iconIdent";
import LoadingIcon from "mdi-react/LoadingIcon";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import {ToastContainer, ToastStore} from "react-toasts";
import DatePicker from "react-datepicker";
import DatepickerField from './components/DatepickerField'
import {
  get_loc_wallets,
  getSigner,
  shortAddr,
  numFormat,
  getIconConnectProvider,
  renderTextField,
  getSelectAddress,
  getSCOPAsset,
  formatDate,
  getStellarFee,
  stellarStroop,
  getFloatValue,
  baseReserve,
  minBalance,
  showErrorMessage,
  checkConnectProvider,
  signConnectProvider,
  shortAddress, itemType, getAsset, getBasePrice, balancesFormat, checkAuth, getTitle
} from "../../../modules";
import {Link, Redirect} from "react-router-dom";
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import {getAccount, getOperationForTransaction} from "../../../endpoints/StellarAPI";
import {Server} from "../../../modules/stellar";
import Footer from "../../Layout/footer/Footer";
import {getRelativeAssets} from "../../../endpoints/API";
import Collapse from "../../../shared/components/Collapse";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';


class CreateClaimableBalance extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      sponsor_balances: [],
      sender_address: false,
      amount: 0,
      recipient_address: false,
      select_start_time: new Date(), // null, // new Date(),
      select_end_time: '',
      memo: '',
      select_asset: false,
      balance_native: 0,
      balance_token: 0,
      reserve_balance: 0,
      loading: false,
      fee: getStellarFee(),
      operation: false,
      hash: false,
      time_limit: false,
      sponsor_rules: false,
      sponsor_rule: 'anytime',
      confirm_display: 'none',
      form_display: 'display',
      balances: [],
      assets: [],
      balances_formated: false,
      isPicker: false,
      isPicker2: false,
    }
  }


  xlm_asset = {
    asset_code: 'XLM',
    asset_issuer: 'native',
    name: 'Stellar',
    image: '/img/logo/stellar-logo.png',
    home_domain: 'stellar.org',
  }

  componentDidMount = () => {

    const title = `Create Claimable Balance on Stellar`
    const description = 'Pending payments indicating execution time. You pay today and the recipient will receive it, for example, in 1 month, but he sees that the payment has already been received to his account. You can set execution conditions, and if the counterparty does not fulfill the terms of the transaction, the payment can be returned back.'
    getTitle(title, description);

    this.getXLMPrice()

    // setTimeout(() => {
    //   this.setState({ isPicker: true })
    // }, 2000)
  }

  toggle = () => {
    this.setState({ isPicker: !this.state.isPicker })
  }
  toggle2 = () => {
    this.setState({ isPicker2: !this.state.isPicker2 })
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
        stellar,
        base_price: stellar.market_data.current_price.usd
      })
    }
  }


  changeSender = (e) => {

    const sender_address = getSelectAddress(e)

    if (sender_address) {

      this.setState({ sender_address })
      this.selectAccount(sender_address)
    }
  }


  changeAsset = (e) => {
    let asset = getFloatValue(e)
    asset = asset.split('-');

    this.setState({
      select_asset: {
        code: asset[0],
        issuer: asset[1],
      },
      balance_token: this.state.sponsor_balances.filter(item => item.asset_code === asset[0])[0].balance
    });

  }


  changeAmount = (e) => {
    let amount = Number(getFloatValue(e)).toFixed(7)
    this.setState({ amount })
  }


  changeStartDate = (date) => {
    this.setState({ select_start_time: date, isPicker: false })
  }


  changeEndDate = (date) => {
    this.setState({ select_end_time: date, isPicker2: false })
  }


  changeMemo = (value) => {

    let memo = getFloatValue(value);

    if (memo.length > 28) {
      memo = memo.substr(0, 28)

      setTimeout(() => {
        this.props.change('memo', memo)
      }, 200)
    }
    else {
      this.setState({ memo })
    }
  }

  changeRecipient = (e) => {
    const recipient_address = getSelectAddress(e)

    if (recipient_address) {
      // this.selectAccount(recipient_address)
      this.setState({ recipient_address })
    }
  }


  selectAccount = async (sponsor) => {

    this.setState({ loading: true, })

    const account = await getAccount(sponsor)

    account.balances.forEach((item, index) => {

      if (item.asset_type === 'native') {
        item.asset_code = 'XLM';
        item.asset_issuer = 'native';
        item.image = '/img/logo/stellar-logo.png'

        const sponsored = account.num_sponsored;
        const sponsoring = account.num_sponsoring;
        const sponsorReserve = (Number(sponsored) + Number(sponsoring))
        item.reserve  = (((account.subentry_count + sponsorReserve) * baseReserve()) + minBalance())
        item.avaliable = (item.balance - item.reserve).toFixed(2);

        this.setState({
          balance_native: item.balance,
          reserve_balance: item.reserve,
        })
      }
      else {
        item.token = item.balance;
      }
    })

    this.setState({
      sponsor_balances: account.balances.reverse(),
      loading: false,
    },
    () => {
      // this.getSponsorClaimants(true)
    })

    // if (account) {
    //   this.setState({
    //     sponsor_balances: account.balances.reverse()
    //   })
    // }


    const query = balancesFormat(account.balances)

    getRelativeAssets(query.pools, query.codes, query.issuers).then(result => {

      if (result) {

        const assets = result.data.assets

        account.balances.map((item, index) => {

          if (item.asset_type !== 'liquidity_pool_shares') {

            if (item.asset_type === 'native') {
              item.image = '/img/logo/stellar-logo.png'
            }
            else {
              const asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]

              if (asset) {
                item.image = asset.image
              } else {
                item.image = '/img/digitalcoin.png'
              }
            }

            return true
          }
        })

        // console.log('account.balances: ', account.balances)


        this.setState({
          sponsor_balances: account.balances,
          assets,
          balances_formated: true,
        })
      }
    })

  }



  createClaimableBalance = () => {

    const { recipient_address, amount, sender_address, select_asset, select_start_time, select_end_time, fee } = this.state

    if (!sender_address) {
      ToastStore.warning('Select Sender Address');
    }
    else if (!select_asset) {
      ToastStore.warning('Select Asset');
    }
    else if (!amount) {
      ToastStore.warning('Enter Amount');
    }
    else if (!select_start_time) {
      ToastStore.warning('Specify Start of claim');
    }
    // else if (!select_end_time) {
    //   ToastStore.warning('Specify End of Claim');
    // }
    else if (!recipient_address) {
      ToastStore.warning('Enter Recipient Address');
    }
    else if (!StellarSdk.StrKey.isValidEd25519PublicKey(recipient_address)) {
      ToastStore.error("Recipient's Address is not valid");
    }
    else {

      this.setState({
        form_display: 'none',
        confirm_display: 'block',
      })

      // swal({
      //   title: "Confirm",
      //   text: 'Create a claimable balance right now?',
      //   icon: "info",
      //   buttons: {
      //     cancel: true,
      //     confirm: true,
      //   },
      // })
      //   .then((confirm) => {
      //     if (confirm) {
      //       this.sendTransaction()
      //     }
      //   })
    }
  }


  sendTransaction = () => {

    const { recipient_address, amount, sender_address, select_asset, select_start_time, select_end_time, fee, memo, sponsor_rule } = this.state

    if (!getSigner(sender_address).sk || !window.atob(getSigner(sender_address).sk)) return

    const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(sender_address).sk))
    const now_time = new Date()
    const start_time = new Date(select_start_time);
    const end_time = select_end_time ? new Date(select_end_time) : 0
    const now_date_format = now_time.toLocaleDateString("en-US")
    const start_date_format = start_time.toLocaleDateString("en-US")

    const now_seconds = Math.trunc(now_time / 1000)
    const start_seconds = Math.trunc((start_time - now_time) / 1000)
    const interval_seconds = Math.trunc((end_time - start_time) / 1000)
    const end_seconds = Math.trunc((end_time - now_time) / 1000)

    const now_claim_timestamp = Math.ceil(now_time / 1000);
    const start_claim_timestamp = Math.ceil((now_time / 1000) + start_seconds);
    const end_claim_timestamp = Math.ceil((now_time / 1000) + end_seconds);

    let claimant_predicate, sponsor_predicate

    if (sponsor_rule === 'anytime') {
      sponsor_predicate = StellarSdk.Claimant.predicateUnconditional()
    }
    if (sponsor_rule === 'after_claimant') {
      sponsor_predicate = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(start_claim_timestamp.toString()))
    }
    if (sponsor_rule === 'never') {
      sponsor_predicate = StellarSdk.Claimant.predicateBeforeAbsoluteTime(now_claim_timestamp.toString())
    }

    // + Unconditional
    if (!select_start_time && !select_end_time) {
      claimant_predicate = StellarSdk.Claimant.predicateUnconditional()
      // sponsor_predicate = StellarSdk.Claimant.predicateUnconditional()
    }

    // + start time
    else if (select_start_time && !select_end_time) {
      claimant_predicate = StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(start_claim_timestamp.toString())) // end claim
    }

    // + end time
    else if (!select_start_time && select_end_time) {
      // claimant_predicate = StellarSdk.Claimant.predicateBeforeAbsoluteTime(end_claim_timestamp.toString())
      claimant_predicate = StellarSdk.Claimant.predicateBeforeRelativeTime(end_seconds.toString())
    }

    // + start & end time
    else if (select_start_time && select_end_time) {

      if (now_date_format === start_date_format) {
        claimant_predicate = StellarSdk.Claimant.predicateBeforeRelativeTime(end_seconds.toString())
      }
      else {

        claimant_predicate = StellarSdk.Claimant.predicateAnd(
          StellarSdk.Claimant.predicateNot(StellarSdk.Claimant.predicateBeforeAbsoluteTime(start_claim_timestamp.toString())),
          StellarSdk.Claimant.predicateBeforeAbsoluteTime(end_claim_timestamp.toString())
        )
      }
    }


    const claimants = [
      new StellarSdk.Claimant(recipient_address, claimant_predicate), // [0] claimant
      new StellarSdk.Claimant(sender_address, sponsor_predicate) // [1] sponsor
    ]

    const claimable_balance = StellarSdk.Operation.createClaimableBalance({
      claimants: claimants,
      asset: select_asset.issuer === 'native' ? new StellarSdk.Asset.native() : new StellarSdk.Asset(select_asset.code, select_asset.issuer),
      amount: String(amount),
    })

    this.closeConfirm()

    this.setState({ loading: true})


    Server.loadAccount(sender_address)
      .then((sourceAccount) => {

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee, // StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        })
          .addOperation(claimable_balance)
          .addMemo(StellarSdk.Memo.text(memo ? memo : ''))
          .setTimeout(100).build();


        if (checkConnectProvider(sender_address)) {
          return signConnectProvider(tx, sender_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          tx.sign(signer);

          return Server.submitTransaction(tx);
        }
      })
      .then((result) => {

        this.setState({
          loading: false,
          hash: result.hash,
        })

        this.getOperation(result.hash)

        const message = `<div><a href="/account/${recipient_address}" target={'_blank'}><b>${shortAddr(recipient_address, 4)}</b></a> received the expected payment <b class="text-info">${numFormat(amount, 7)}</b> <b class="text-warning">${select_asset.code}</b> <br><br><small>hash: <a href="/transaction/${result.hash}" target="_blank"><b>${shortAddr(result.hash, 4)}</b></a></small></div>`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: `Claimable Balance Created!`,
          content: element,
          icon: "success",
        })
      })
      .catch((error) => {
        console.error('error!', error);
        console.log('error resp!', error.response);
        this.setState({ loading: false, })

        showErrorMessage(error, 'claim')
      });
  }


  getOperation = (hash) => {

    getOperationForTransaction(hash, 1).then(result => {
      if (result) {
        this.setState({
          operation: result.records[0],
        })
      }
    }).catch(error => {
      console.log('error: ', error)
    })
  }


  closeLastTransaction = () => {
    this.setState({ hash: false })
  }


  addTimeLimit = (e) => {
    e.preventDefault()

    this.setState({ time_limit: true })
  }


  sponsorRules = (e) => {
    e.preventDefault()

    this.setState({ sponsor_rules: true })
  }


  closeConfirm = () => {

    this.setState({
      confirm_display: 'none',
      form_display: 'block',
    })
  }


  changeRule = (value) => {

    if (value[0] === '1') {
      this.setState({ sponsor_rule: 'anytime' })
    }
    if (value[0] === '2') {
      this.setState({ sponsor_rule: 'after_claimant' })
    }
    if (value[0] === '3') {
      this.setState({ sponsor_rule: 'never' })
    }
  }


  checkAuthAddr = () => {
    checkAuth().then((value) => {
      if (value) {
        this.setState({
          is_auth: true
        })
      }
    })
  }




  render() {

    console.log('state: ', this.state)

    const { wallets, sponsor_balances, select_start_time, select_end_time, memo, select_asset, balance_native, balance_token,
      reserve_balance, sender_address, recipient_address, loading, operation, hash, amount, time_limit, sponsor_rules, claim_card_class,
      confirm_display, form_display, fee, base_price, is_auth } = this.state


    const fee_xlm = (fee / 10000000)
    const fee_usd = (fee_xlm * base_price)
    const base_reserve = (baseReserve() * 2)


    if (is_auth) {
      return <Redirect to='/auth' />
    }


    return (

      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>

          <Col md={{size: 8, offset: 2}} lg={{size: 4, offset: 4}}>

            <Card style={{
              display: confirm_display,
              // height: 'auto',
              // marginTop: 80,
              // marginBottom: 101,
            }}>

              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Confirm Transaction</h5>
                </div>
                <div className="panel__btns">
                  <button className="panel__btn text-secondary" type="button" onClick={this.closeConfirm}>
                    <i className={'fa fa-close'}></i>
                  </button>
                </div>
                <br/>
                <div className={'stats-block'}>
                    <span>
                      <p>
                        <span>Type</span>
                        <span className={'swap-data'}>
                          <b className="text-info">Create claimable balance</b>
                        </span>
                      </p>
                      <p>
                        <span>Amount</span>
                        <span className={'swap-data'}>
                          <b className="text-info">{ numFormat(amount, 7) }</b> { getAsset(`${select_asset.code}:${select_asset.issuer}`) }
                        </span>
                      </p>
                      <p>
                        <span>Sponsor</span>
                        <span className={'swap-data'}>{shortAddress(sender_address)}</span>
                      </p>
                      <p>
                        <span>Claimant</span>
                        <span className={'swap-data'}>{shortAddress(recipient_address)}</span>
                      </p>

                      <p>
                        <span>Start Claim</span>
                        <span className={'swap-data'}><b className={'text-info'}>{select_start_time ? formatDate(select_start_time) : 'not set'}</b></span>
                      </p>
                      <p>
                        <span>End Claim</span>
                        <span className={'swap-data'}><b className={'text-info'}>{select_end_time ? formatDate(select_end_time) : 'not set'}</b></span>
                      </p>
                      {
                        memo ?
                          <p>
                            <span>Memo</span>
                            <span className={'swap-data'}><b className={'text-info'}>{memo}</b></span>
                          </p>
                          : null
                      }
                      <br/>
                      <div>
                        <p>
                        <span className={'text-secondary'}>Fee</span>
                        <span className={'swap-data'}><small className={'text-secondary'}><b>{numFormat(fee_xlm, 7)}</b> XLM / </small> <b className={'text-info'}>${numFormat(fee_usd, 7)}</b></span>
                      </p>
                      <p>
                        <span className={'text-secondary'}>Reserve</span>
                        <span className={'swap-data'}><b className={'text-info'}>{numFormat(base_reserve, 7)}</b> <b className={'text-secondary'}>XLM</b></span>
                      </p>
                      </div>
                    </span>
                </div>

                <br/><br/><br/>

                <div>
                  <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
                    <Button
                      onClick={this.sendTransaction}
                      color={'success'}
                      size={'lg'}
                      style={{width: '100%'}}>
                      <b>Create Claimable Balance</b>
                    </Button>
                  </ButtonToolbar>
                </div>

              </CardBody>
            </Card>

            <Card
              // className={claim_card_class}
              style={{display: form_display}}
            >

              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text"><i className={'fa fa-clock-o'}></i> Create Claimable Balance</h5>
                  <h5 className="subhead">Send assets in the form of payments deferred in time for the recipient</h5>
                </div>

                {
                  loading ?
                    <div className="panel__refresh" style={{height: '93%'}}><LoadingIcon /></div>
                    : null
                }

                <form className="material-form" onClick={this.checkAuthAddr}>
                  <div>
                    <Field
                      name="select_addr"
                      component={renderTextField}
                      select
                      label="Select Sponsor Account"
                      onChange={this.changeSender}
                    >
                      {
                        wallets.length ? wallets.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              style={{fontSize: '12px'}}
                              className={`material-form__option ${item && item.provider ?  'connect-provider' : ''}`}
                              value={item.pk}>
                              <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? item.balance : null} XLM</b></small> { getIconConnectProvider(item) }
                            </MenuItem>
                          )
                        }) : null
                      }
                    </Field>

                    {
                      sponsor_balances.length ?
                        <div style={{margin: '-9px 0 7px'}}>
                          <h5 className="subhead"><b className="text-warning">XLM</b>: <b className="text-info">{numFormat(balance_native, 7)}</b> &nbsp; / &nbsp;
                            <b className="text-warning">{select_asset.code}</b>: <b className="text-info">{numFormat(balance_token, 7)}</b>
                            <span className="float-right">Reserve: <b className="text-info">{numFormat(reserve_balance, 7)} <font className="text-warning">XLM</font></b></span>
                          </h5>
                        </div>
                      : null
                    }

                  </div>

                  <div>
                    <Field
                      name="select_asset"
                      component={renderTextField}
                      select
                      label="Select Asset"
                      onChange={this.changeAsset}
                    >
                      {
                        sponsor_balances.length ? sponsor_balances.map((item, index) => {

                          if (item.asset_type !== 'liquidity_pool_shares') {
                            return (
                              <MenuItem
                                key={index}
                                className="material-form__option"
                                value={`${item.asset_code}-${item.asset_issuer}`}
                              >
                                { item.image ? <img src={item.image} alt="" className={'asset-icon-sm'} /> : null} &nbsp;
                                <b className="text-secondary">{item.asset_code}</b> &nbsp; <span className={'text-secondary'}>({item.asset_code === 'XLM' ? numFormat(item.avaliable, 7) : numFormat(item.balance, 7)})</span>
                              </MenuItem>
                            )
                          }
                        }) : null
                      }
                    </Field>
                  </div>

                  <div>
                    <Field
                      name="amount"
                      component={renderTextField}
                      label={`Amount`}
                      onChange={this.changeAmount}
                    />
                  </div>

                  <Field
                    name="recipient"
                    component={renderTextField}
                    label={`Recipient Address`}
                    onChange={this.changeRecipient}
                  />

                  <Field
                    name="memo"
                    component={renderTextField}
                    label={`Memo ${memo ? `(${memo.length}/28)` : '(optional)'}`}
                    onChange={this.changeMemo}
                  />

                  <Row className={'create-claimable'}>
                    <Col md={{size: 6}}>
                      <div className="datepicker-wrapper">

                        <p><small>Start Claim</small></p>
                        <div >
                          <Button
                            id="Popover1"
                            type="button"
                            size={'sm'}
                            onClick={this.toggle}
                            color={'primary'}
                            className={'w-100'}
                          >
                            <span>{formatDate(select_start_time)}</span>
                          </Button>

                          <Popover flip target="Popover1" placement="top" isOpen={this.state.isPicker} className={'date-picker-popover'}>
                            <DayPicker
                              mode="single"
                              selected={select_start_time}
                              onSelect={this.changeStartDate}
                            />
                          </Popover>
                        </div>
                      </div>
                    </Col>
                    <Col md={{size: 6}}>

                      <div className="datepicker-wrapper">

                        <div className="datepicker-wrapper">

                          <p><small>End Claim</small></p>
                          <div >
                            <Button
                              id="Popover2"
                              type="button"
                              size={'sm'}
                              onClick={this.toggle2}
                              color={select_end_time ? 'primary' : 'secondary'}
                              className={'w-100'}
                            >
                              <span>{select_end_time ? formatDate(select_end_time) : <span>Set End Time <br/> <small>not set</small></span>}</span>
                            </Button>

                            <Popover flip target="Popover2" placement="top" isOpen={this.state.isPicker2} className={'date-picker-popover'}>
                              <DayPicker
                                mode="single"
                                selected={select_end_time}
                                onSelect={this.changeEndDate}
                              />
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {
                    !sponsor_rules ?
                      <div>
                        <a href="#"
                           onClick={this.sponsorRules}
                           className={'text-secondary'}><small><b>+ Sponsor Claim Rules</b> (optional)</small></a>
                        <br/><br/>
                      </div>
                    :
                      <div>
                        <br/>
                        <small>
                          <p>Specify when the sponsor can claim the balance</p>
                        </small>

                        <br/>
                        <div>
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <Field
                                name="radio"
                                component={renderRadioButtonField}
                                label="Anytime"
                                radioValue="1"
                                defaultChecked={'Anytime'}
                                onChange={this.changeRule}
                              />
                            </div>
                          </div>
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <Field
                                name="radio"
                                component={renderRadioButtonField}
                                label="After Claimant"
                                radioValue="2"
                                onChange={this.changeRule}
                              />
                            </div>
                          </div>
                          <div className="form__form-group">
                            <div className="form__form-group-field">
                              <Field
                                name="radio"
                                component={renderRadioButtonField}
                                label="Never"
                                radioValue="3"
                                onChange={this.changeRule}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                  }



                </form>

                <br/>

                <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
                  {
                    wallets ?
                      <Button
                              onClick={this.createClaimableBalance}
                              color={'primary'}
                              size={'lg'}
                              // disabled={trustline || loading_account || error ? true : false}
                              style={{width: '100%'}}>
                        <b>Create Claimable Balance</b>
                      </Button>
                      :
                      <Link to={'/auth/'}
                            className={'btn btn-primary btn-lg'}
                            style={{width: '100%'}}><b>Connect Wallet</b></Link>
                  }
                </ButtonToolbar>

                <div className={'stats-block'}>
                  <p>Fee <a href={null}  id="fee"><i className="fa fa-question-circle-o text-secondary"></i></a>
                    <UncontrolledTooltip placement="top" target="fee">
                                    <span>To prevent ledger spam and maintain the efficiency of the network, Stellar requires small transaction
                                      fees and minimum balances on accounts. Transaction fees are also used to prioritize transactions when
                                      the network enters surge pricing mode.</span>
                    </UncontrolledTooltip>
                    <span className={'swap-data'}>
                                    <small>${numFormat(fee_usd, 7)}</small>
                                  </span>
                  </p>
                </div>

              </CardBody>
            </Card>
          </Col>


          <Col md={{size: 8, offset: 2}} lg={{size: 4, offset: 4}}>
            {
              hash ?
                <Card style={{height: 'auto'}}>
                  <CardBody>
                    <div className="card__title">
                      <h5 className="bold-text">Last Transaction</h5>
                    </div>
                    <div className="panel__btns">
                      <button className="panel__btn text-secondary" type="button" onClick={this.closeLastTransaction}>
                        <i className={'fa fa-close'}></i>
                      </button>
                    </div>
                    <div className={'stats-block'}>

                      <p>
                        <span>Transaction:</span>
                        <span className={'swap-data'}>
                          <b><a href={`/transaction/${this.state.hash}`}>{ shortAddr(this.state.hash, 4) }</a></b>
                        </span>
                      </p>

                      { !operation ? <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null }

                      {
                        operation ?
                          <span>
                            <p>
                              <span>Operation:</span>
                              <span className={'swap-data'}>
                                <b><a href={`/operation/${operation.id}`}>{ shortAddr(operation.id, 4) }</a></b>
                              </span>
                            </p>

                            <p>
                              <span>Type:</span>
                              <span className={'swap-data'}>
                                <b className="text-info">{ itemType(operation.type) }</b>
                              </span>
                            </p>
                            <p>
                              <span>Amount:</span>
                              <span className={'swap-data'}>
                                <b className="text-info">{ numFormat(amount, 7) }</b> { getAsset(`${select_asset.code}:${select_asset.issuer}`) }
                              </span>
                            </p>
                            <p>
                              <span>Sponsor:</span>
                              <span className={'swap-data'}>{shortAddress(sender_address)}</span>
                            </p>
                            <p>
                              <span>Claimant:</span>
                              <span className={'swap-data'}>{shortAddress(recipient_address)}</span>
                            </p>

                            <p>
                              <span>Start Claim:</span>
                              <span className={'swap-data'}><b className={'text-info'}>{select_start_time ? formatDate(select_start_time) : 'not set'}</b></span>
                            </p>
                            <p>
                              <span>End Claim:</span>
                              <span className={'swap-data'}><b className={'text-info'}>{select_end_time ? formatDate(select_end_time) : 'not set'}</b></span>
                            </p>
                            {
                              memo ?
                                <p>
                                  <span>Memo:</span>
                                  <span className={'swap-data'}><b className={'text-info'}>{memo}</b></span>
                                </p>
                              : null
                            }
                            <p>
                              <span>Date:</span>
                              <span className={'swap-data'}>
                                <small>{formatDate(operation.created_at)}</small>
                              </span>
                            </p>

                          </span>
                          : null
                      }

                    </div>
                  </CardBody>
                </Card>
                : null
            }

            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>
                <Collapse title="What is Stellar claimable balances" className="with-shadow ml-0">
                  <p>Claimable balances allow an account to send a payment to another account that is not necessarily ready to receive a payment.
                    These are payments that may be delayed in time for the recipient to claim. <br/><br/>
                    They can be used when you are sending an asset to an account that does not yet have a trustline set up, which
                    can be useful for linking new users or sending delayed payments.</p>
                </Collapse>
                <Collapse title="What assets can be sent" className="with-shadow ml-0">
                  <p>You can send any assets that are on your Stellar account. If your recipient does not have a trustline for the asset you are sending,
                    they will still be able to receive the payment. When the recipient requests such a payment, the trustline will be
                    set automatically and the payment will be credited to the address.</p>
                </Collapse>
                <Collapse title="Can this payment be claimed back?" className="with-shadow ml-0">
                  <p>Yes, it is possible to claim such a payment back, as long as there were no restrictive temporary rules for the
                    original sponsor account when creating the claimable balance.</p>
                </Collapse>
                <Collapse title="Can the recipient refuse such a payment?" className="with-shadow ml-0">
                  <p>The user cannot delete unnecessary claimable balances yet, he can ignore them. Soon the ability to refuse or hide
                    such a payment will be added.</p>
                </Collapse>
              </CardBody>
            </Card>


            <Card style={{height: 'auto'}}>
              <CardBody>
                <Link to={'/payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-location-arrow'}></i> Payments</Link>
                <Link to={'/receive'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%'}}><i className={'fa fa-qrcode'}></i> Payment Request</Link>
                <Link to={'/mass-payments'}
                      className={'btn btn-secondary btn-lg'}
                      style={{width: '100%', marginBottom: 0,}}><i className={'fa fa-users'}></i> Mass Payments</Link>
              </CardBody>
            </Card>

          </Col>

        </Row>


        <Footer />


      </Container>
    );
  }
}

export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(CreateClaimableBalance));
