import React, { PureComponent } from 'react';
import {Button, Table, Card, CardBody, Col, Row, ButtonGroup, ButtonToolbar} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../../Account/components/iconIdent';
import {numFormat, renderTextField, getAsset, shortAddr, shortAddress, formatCount} from '../../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ToastStore} from "react-toasts";
import ReactApexChart from "react-apexcharts";
import getPrice from "../../../App/GetPrice";
import {getMarkets} from "../../../../endpoints/API";
import SpinnerLoader from "../../../../modules/SpinnerLoader";
import LoadingIcon from "mdi-react/LoadingIcon";
import MenuItem from "@material-ui/core/MenuItem";
import {Field, reduxForm} from "redux-form";



class Markets extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      markets: [],
      cursor_markets: 0,
      markets_more_btn: false,
      assets: [],
      load_more: false,
      filter_type: 'all',
      filter: 'volume24h',
      asset: {
        asset_code: '',
        asset_issuer: '',
      },
    };
  }

  limit_items = 30

  componentDidMount = () => {

    this.getBasePrice()
  }


  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 3) {
        getPrice()

        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }

    this.getMarkets()
  }


  getMarkets = () => {

    const { markets, cursor_markets, filter_type, filter, asset } = this.state;

    if (this.props.asset) {

      const assetP = this.props.asset
      // console.log('assetP: ', assetP)

      asset.asset_code = assetP.asset_code ? assetP.asset_code : assetP[0]
      asset.asset_issuer = assetP.asset_issuer ? assetP.asset_issuer : assetP[1]
    }

    this.setState({ load_more: false, })

    getMarkets(asset ? asset : '', this.limit_items, cursor_markets, filter, filter_type).then(result => {
      // console.log('getMarkets result: ', result)

      const markets_parse = JSON.parse(result.data.markets)

      if (markets_parse) {
        const records = markets_parse._embedded.records;

        if (records.length < this.limit_items || records.length === 0) {
          this.setState({ markets_more_btn: false });
        } else {
          this.setState({ markets_more_btn: true });
        }

        this.setState({
          markets: markets.concat(records),
          cursor_markets: records[records.length - 1].paging_token,
          load_more: true,
          assets: result.data.assets,
        })
      }
    })
  }


  filterForm = (values) => {
    // console.log('values: ', values)
  }


  filterType = (type) => {
    // console.log('type: ', type)

    this.setState({
      filter_type: type,
      cursor_markets: 0,
      markets: [],
      markets_more_btn: false,
    }, () => {

      this.getMarkets()
    })
  }


  filterAssets = (value) => {

    let filter = '', count = 0;
    for (const key of Object.keys(value)) {
      filter = (filter + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.setState({
      filter,
      cursor_markets: 0,
      markets: [],
      markets_more_btn: false,
    }, () => {

      this.getMarkets()
    })
  }



  renderItem = (item, index) => {

    const { assets, price } = this.state

    const stellar_logo = '/img/logo/stellar-logo.png'
    const default_logo = '/img/digitalcoin.png'

    const assetB = item.asset[0] === 'XLM' ? 'native' : item.asset[0].slice(0, -2).replace("-", ":")
    const assetA = item.asset[1].slice(0, -2).replace("-", ":")

    let counterAssetDB = (assetB === 'native') ? { asset_code: 'XLM', asset_issuer: 'native', image: stellar_logo, home_domain: 'Native', price } : assets.filter(item => item.asset_code === assetB.split(':')[0] && item.asset_issuer === assetB.split(':')[1])[0]

    let color
    if (item.price7d.length > 1) {
      color = item.price7d[item.price7d.length-1][1] > item.price7d[item.price7d.length-2][1] ? '#4ce1b6' : '#ff4861' // item.change7d < 0 ? '#ff4861' : '#4ce1b6'
    } else {
      color = 'gray'
    }


    let series = item.price7d.length ? item.price7d.map(item => Number(item[1] + 10).toFixed(7)) : []

    series = [{data: series}]

    const options7d =  {
      chart: {
        sparkline: {
          enabled: true
        }
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          return '<small class="tooltip-7d">' + (series[seriesIndex][dataPointIndex] - 10).toFixed(5) +'</small>'
        },

        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          //
        },
        marker: {
          show: false
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth',
        // lineCap: 'round',
        colors: color,
      }
    };


    let asset_a = assets.filter(item => item.asset_code === assetA.split(':')[0] && item.asset_issuer === assetA.split(':')[1])[0]
    let asset_b = counterAssetDB

    // console.log('asset_a: ', asset_a)
    // console.log('asset_b: ', asset_b)

    if (!asset_a) {
      asset_a = {
        asset_code: assetA.split(':')[0],
        asset_issuer: assetA.split(':')[1],
        image: default_logo,
        home_domain: '---',
        price
      }
    }
    if (!asset_b) {
      asset_b = {
        asset_code: assetB.split(':')[0],
        asset_issuer: assetB.split(':')[1],
        price: 0,
        image: default_logo,
        home_domain: '---',
      }
    }


    // if (asset_a && asset_b && asset_a.asset_code && asset_b.asset_code) {
    //
    // }

    // if (!counterAssetDB) counterAssetDB.price = 0

    const counterVolume24h = (item.counterVolume24h / 10000000)
    const counterVolume7d = (item.counterVolume7d / 10000000)
    const counterVolume24hUSD = assetB === 'native' ? (counterVolume24h * price) : ((counterVolume24h * asset_b.price) * price)
    const counterVolume7dUSD = assetB === 'native' ? (counterVolume7d * price) : ((counterVolume7d * asset_b.price) * price)
    const priceUSD = (item.price * price)

    const url = `/trade/${asset_a.asset_code}-${asset_b.asset_code}/${asset_a.asset_issuer}/${asset_b.asset_issuer}`


    return (
      <tr key={index}>
        <td><small>{index+1}</small></td>
        <td>
          {/*<b className="text-info">{getAsset(assetA)} / {getAsset(assetB)}</b>*/}

          <div>
            <a href={url}>
              <div className={`icon-asset-pair-market ${asset_a === 'native' && 'native-item'}`}>
                <img src={asset_a.image} alt={``}/>
              </div>
              <div className={'icon-asset-pair-market ml-6'}>
                <img src={asset_b.image} alt={``}/>
              </div>

              <div className={'ml-86'}>
                <span>
                  <span className={''}><b>{asset_a.asset_code}</b> <span className={'text-secondary'}>/</span> <b>{asset_b.asset_code}</b></span>
                  <div className={'text-secondary mt-min-6'}>
                    <small className={'overflow'}>
                      <span>{asset_a.home_domain}</span> / {' '}
                      <span>{asset_b.home_domain}</span>
                    </small>
                  </div>
                  </span>
              </div>
            </a>
          </div>

        </td>
        <td>
          <b className="text-info">{numFormat(item.price, 7)}</b> <small>{getAsset(assetB, false, 'secondary')}</small>
          <div className={'text-secondary mt-min-10'}>
            <small>${numFormat(priceUSD, 7)}</small>
          </div>
        </td>
        <td>
          <b className="text-info">{counterVolume24h >= 1 ? formatCount(counterVolume24h, true, 2) : numFormat(counterVolume24h, 2)}</b> <small>{getAsset(assetB, false, 'secondary')}</small> <br/> {' '}
          <div className={'text-secondary mt-min-10'}>
            <small>${counterVolume24hUSD >= 1 ? formatCount(counterVolume24hUSD, true, 2) : numFormat(counterVolume24hUSD, 2)}</small>
          </div>
        </td>
        <td>
          <b className="text-info">{counterVolume7d >= 1 ? formatCount(counterVolume7d, true, 2) : numFormat(counterVolume7d, 2)}</b> <small>{getAsset(assetB, false, 'secondary')}</small> <br/> {' '}
          <div className={'text-secondary mt-min-10'}>
            <small>${counterVolume7dUSD >= 1 ? formatCount(counterVolume7dUSD, true, 2) : numFormat(counterVolume7dUSD, 2)}</small>
          </div>
        </td>
        {/*<td><b className={`text-${item.change24h < 0 ? 'danger' : 'success'}`}>{item.change24h > 0 ? '+' : ''}{item.change24h}</b></td>*/}
        {/*<td><b className={`text-${item.change7d < 0 ? 'danger' : 'success'}`}>{item.change7d > 0 ? '+' : ''}{item.change7d}</b></td>*/}
        <td><b className="text-info">{numFormat((item.spread * 100), 2)}</b>%</td>
        <td><b className="text-info">{numFormat(item.trades24h, 7)}</b></td>
        <td><b className="text-info">{numFormat(item.trades, 7)}</b></td>
        <td>
          <ReactApexChart options={options7d} series={series} type="line" height={28} width={90} />
        </td>
      </tr>
    )
  }




  render() {

    // console.log('this.state: ', this.state)
    // console.log('this.props: ', this.props)

    const { markets, assets, filter_type } = this.state

    return (
      <div>

        <form className="material-form" onSubmit={this.props.handleSubmit(this.filterForm)}>
          <Row>

            <Col md={4}>
              <ButtonToolbar className={'toolbar-filter'}>
                <ButtonGroup className="btn-group--icons">
                  <Button onClick={() => this.filterType('all')}
                          color={filter_type === 'all' ? 'primary' : 'secondary'}>All</Button>
                  <Button onClick={() => this.filterType('xlm')}
                          color={filter_type === 'xlm' ? 'primary' : 'secondary'}>XLM</Button>
                  <Button onClick={() => this.filterType('crypto')}
                          color={filter_type === 'crypto' ? 'primary' : 'secondary'}>Crypto</Button>
                  <Button onClick={() => this.filterType('fiat')}
                          color={filter_type === 'fiat' ? 'primary' : 'secondary'}>Fiat</Button>
                  <Button onClick={() => this.filterType('other')}
                          color={filter_type === 'other' ? 'primary' : 'secondary'}>Other</Button>
                </ButtonGroup>
              </ButtonToolbar>
              <br/>
            </Col>

            <Col md={{offset: 6, size: 2}}>
              <div className="material-form">
                <div className={''}>
                  {' '}
                  <Field
                    name="select_filter"
                    component={renderTextField}
                    select
                    label={(<span><i className={'fa fa-filter'}></i> Filter</span>)}
                    onChange={(e) => {
                      this.filterAssets(e)
                    }} >
                    <MenuItem
                      className={`material-form__option`}
                      value={'volume24h'}>
                      <p>Volume 24h</p>
                    </MenuItem>
                    <MenuItem
                      className={`material-form__option`}
                      value={'trades24h'}>
                      <p>Trades 24h</p>
                    </MenuItem>
                    <MenuItem
                      className={`material-form__option`}
                      value={'trades'}>
                      <p>Trades</p>
                    </MenuItem>
                    <MenuItem
                      className={`material-form__option`}
                      value={'created'}>
                      <p>Age</p>
                    </MenuItem>

                  </Field>
                </div>
              </div>
            </Col>
          </Row>
        </form>

        <Table striped responsive>
          <thead>
          <tr>
            <th>#</th>
            <th>Pair</th>
            <th>Price</th>
            <th>Vol 24</th>
            <th>Vol 7d</th>
            {/*<th>Change 24</th>*/}
            {/*<th>Change 7d</th>*/}
            <th>Spread</th>
            <th>Trades 24</th>
            <th>Trades All</th>
            <th>Last 7d</th>
          </tr>
          </thead>
          <tbody>

          {
            markets.length && assets.length ?
              markets.map(this.renderItem)
            : null
          }

          </tbody>
        </Table>

        {
          !markets.length ?
            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
          : null
        }

        <div className="text-center">
          <br/>
          {
            this.state.markets_more_btn ?

              <Button color="secondary" onClick={this.getMarkets}>
                {
                  this.state.load_more ? 'Load more...' :
                    <SpinnerLoader />
                }
              </Button>
              : null
          }
        </div>
      </div>
    )
  }
}



export default reduxForm({
  form: 'markets_form',
})(translate('common')(Markets));
