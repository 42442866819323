import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm, change } from 'redux-form';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, ToastStore } from 'react-toasts';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";


const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type, value,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class SearchField extends PureComponent {
  // static propTypes = {
  //   t: PropTypes.func.isRequired,
  //   handleSubmit: PropTypes.func.isRequired,
  //   reset: PropTypes.func.isRequired,
  // };

  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }

  componentDidMount() {
    if (this.props.asset) {
      this.props.change('asset_code', this.props.asset.asset_code)
    }
  }


  assetForm = (values) => {
    // console.log('values: ', values)

    if (!values.asset_code && !values.asset_issuer) {
      ToastStore.warning('Enter Asset Code or Code Issuer, or both.');
    }
    else {

      let query = {
        asset: {
          asset_code: values.asset_code ? values.asset_code : '',
          asset_issue: values.asset_issuer ? values.asset_issuer : '',
        },
        filter: false,
      }

      this.props.search_data(query);
    }
  }


  filterAssets = (value) => {

    let type = '', count = 0;
    for (const key of Object.keys(value)) {
      type = (type + (Object.keys(value).length-1 > count ? value[key] : ''))
      count++
    }

    this.props.filter_assets(type);
  }


  render() {
    const { handleSubmit, reset, t } = this.props;


    return (
      <div>
        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <form className="form" onSubmit={handleSubmit(this.assetForm)}>

          <Col md={2}>
            <div className="form__form-group">
              <span className="form__form-group-label"><b>Asset Code</b></span>
              <div className="form__form-group-field">
                <Field
                  name="asset_code"
                  component="input"
                  type="text"
                  placeholder="Code"
                />
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="form__form-group">
              <span className="form__form-group-label"><b>Asset Issuer</b></span>
              <div className="form__form-group-field">
                <Field
                  name="asset_issuer"
                  component="input"
                  type="text"
                  placeholder="Issuer"
                />
              </div>
            </div>
          </Col>


          <Col md={{offset: 4, size: 2}}>
            <div className="material-form">
              <div>
                <Field
                  name="select_filter"
                  component={renderTextField}
                  select
                  label="Filter"
                  onChange={(e) => {
                    this.filterAssets(e)
                  }} >
                  <MenuItem
                    className={`material-form__option`}
                    value={'rating'}>
                    <p>Rating</p>
                  </MenuItem>
                  <MenuItem
                    className={`material-form__option`}
                    value={'vol_24'}>
                    <p>Vol 24h</p>
                  </MenuItem>
                  <MenuItem
                    className={`material-form__option`}
                    value={'supply'}>
                    <p>Supply</p>
                  </MenuItem>
                  <MenuItem
                    className={`material-form__option`}
                    value={'holders'}>
                    <p>Holders</p>
                  </MenuItem>
                  <MenuItem
                    className={`material-form__option`}
                    value={'spread'}>
                    <p>Spread</p>
                  </MenuItem>
                </Field>
              </div>
            </div>
          </Col>


          {/*<Col md={2} >*/}
          {/*  <div className="form__form-group">*/}
          {/*    <span className="form__form-group-label"></span>*/}
          {/*    <div className="form__form-group-field">*/}
                <Button color="secondary" type="submit" outline style={{display: 'none'}} >Search</Button>
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Col>*/}

        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'search_form', // a unique identifier for this form
})(translate('common')(SearchField));
