import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const background = `${process.env.PUBLIC_URL}/img/landing/bottom_bg.png`;

const year = new Date().getFullYear();

const Footer = () => (
  <footer className="landing__footer">
    <img className="landing__footer-background" src={background} alt="" />
    <Container>
      <Row>
        <Col md={12}>
          <p>
           <a target='_blank'
               rel='noopener noreferrer'
               href="https://twitter.com/scopuly" className="footer-icon"><i className="fa fa-twitter"></i></a>

            {/*<a target='_blank'*/}
            {/*   rel='noopener noreferrer'*/}
            {/*   href="https://www.facebook.com/scopuly/" className="footer-icon"><i className="fa fa-facebook"></i></a>*/}

            <a target='_blank'
               rel='noopener noreferrer'
               href="https://t.me/scopuly" className="footer-icon"><i className="fa fa-telegram"></i></a>

            <a target='_blank'
               rel='noopener noreferrer'
               href="https://www.reddit.com/r/scopuly" className="footer-icon"><i className="fa fa-reddit"></i></a>

            <a href="https://scopulyplatform.medium.com/"
               target='_blank'
               rel='noopener noreferrer' className="footer-icon"><i className="fa fa-medium"></i></a>

            <a target='_blank'
               rel='noopener noreferrer'
               href="https://www.youtube.com/channel/UC-b8hH2c3ZnegEJhw4K9JOw" className="footer-icon"><i className="fa fa-youtube"></i></a>

            <a target='_blank'
               rel='noopener noreferrer'
               href="https://github.com/Scopuly/scopuly/releases" className="footer-icon"><i className="fa fa-github"></i></a>
          </p>

          <p className="landing__footer-text" style={{paddingTop: '30px'}}>
            <span className="text-info">© {year} Scopuly</span> &nbsp;&nbsp; | &nbsp;&nbsp;
            <a href="/docs/PRIVACY_en.pdf" target='_blank' rel='noopener noreferrer'> Privacy policy</a> &nbsp;&nbsp; | &nbsp;&nbsp;
            <a href="/docs/TERMS_en.pdf" target='_blank' rel='noopener noreferrer'> Terms of Service</a> &nbsp;&nbsp; | &nbsp;&nbsp;
            <a href="https://scop.scopuly.com" target='_blank' rel='noopener noreferrer'><img className={'scop-icon'} src="/img/icons/scop.png" alt="scop"/> SCOP</a> &nbsp;&nbsp; | &nbsp;&nbsp;
            <a href="mailto:info@scopuly.com"> info@scopuly.com</a>
          </p>

          {/*<div style={{margin: '-30px 0px 10px'}}>*/}
          {/*  <small className="text-secondary">Scopuly is not affiliated with the Stellar Development Foundation</small>*/}
          {/*</div>*/}
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
