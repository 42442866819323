import React, { PureComponent } from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Popover,
  PopoverBody,
  Table,
  Badge,
  ButtonGroup,
  Button,
  ButtonToolbar
} from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import iconIdent from '../Account/components/iconIdent';
import {
  numFormat,
  formatDate,
  getAsset,
  shortAddr,
  shortAddress,
  getAPY,
  getSCOPAsset,
  getIconConnectProvider,
  renderTextField,
  get_loc_wallets,
  orderAssets,
  checkConnectProvider,
  signConnectProvider,
  showErrorMessage, getSigner, getStellarFee
} from '../../../modules/index';
import {CopyToClipboard} from "react-copy-to-clipboard";
import { ToastContainer, ToastStore } from 'react-toasts';
import AutorenewIcon from "mdi-react/AutorenewIcon";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getAssetInfo, getMarkets} from "../../../endpoints/API";
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import StellarSdk from "stellar-sdk";
import Footer from "../../Layout/footer/Footer";
import getPrice from "../../App/GetPrice";
import ReactApexChart from "react-apexcharts";
import {getAccount} from "../../../endpoints/StellarAPI";
import swal from "sweetalert";
import {Server} from "../../../modules/stellar";
import Collapse from "../../../shared/components/Collapse";
import {QRCode} from "react-qrcode-logo";
import {TransactionStellarUri} from "@stellarguard/stellar-uri";



class Trustline extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      select_address: false,
      account: false,
      asset: this.props.match.params.id.split('-'),
      markets: [],
      assets: [],
      base_asset: false,
      counter_asset: {
        asset_code: 'XLM',
        asset_issuer: 'native',
        image: '/img/logo/stellar-logo-gray.png',
        name: 'Stellar',
        home_domain: 'Native'
      },
      popoverCapOpen: false,
      popoverQueryOpen: false,
      loading_account: false,
      trustline: false,
      error: false,
      confirm_accept: false,
      uri: false,
      show_qr: false,
    };
  }

  apy_scop = getAPY('pool')
  scop = getSCOPAsset()
  fee = getStellarFee()


  componentDidMount = () => {

    this.getBasePrice()

    this.getAssetInfo()
    this.getMarkets()

    const public_key = 'GCTBATCNXTDRHDRBJ2PZREPN5GNCI5GVZUNF6CQ22LZVF2TIFXQ5V4XF';
    const account = new StellarSdk.Account(public_key, '99638409428992014');

    this.createURI(account)
  }


  createURI = (account) => {

    const asset = this.props.match.params.id.split('-')

    const transaction = new StellarSdk.TransactionBuilder(account, {
      fee: this.fee,
      networkPassphrase: StellarSdk.Networks.PUBLIC
    })
      .addOperation(StellarSdk.Operation.changeTrust({
        asset: new StellarSdk.Asset(asset[0], asset[1]),
      }))
      .setTimeout(30)
      .build();

    const xdr = transaction.toEnvelope().toXDR('base64')
    const uri = TransactionStellarUri.forTransaction(transaction);

    this.setState({ uri: uri.toString(), xdr })
  }


  getBasePrice = () => {

    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 3) {
        getPrice()

        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  togglePopoverCap = () => {
    this.setState(prevState => ({
      popoverCapOpen: !prevState.popoverCapOpen
    }));
  }

  togglePopoverQuery = () => {
    this.setState(prevState => ({
      popoverQueryOpen: !prevState.popoverQueryOpen
    }));
  }


  getMarkets = () => {

    const asset = {
      asset_code: this.state.asset[0],
      asset_issuer: this.state.asset[1],
    }

    // getMarkets(asset, 10, 0, '', '').then(result => {
    //   // console.log('getMarkets result: ', result)
    //
    //   const markets_parse = JSON.parse(result.data.markets)
    //
    //   if (markets_parse) {
    //     const records = markets_parse._embedded.records;
    //
    //     this.setState({
    //       markets: records,
    //       assets: result.data.assets,
    //     })
    //   }
    // })
  }


  getAssetInfo = () => {

    const asset = this.props.match.params.id.split('-')
    const assetA = new StellarSdk.Asset(asset[0], asset[1])
    const assetB = new StellarSdk.Asset.native()

    const [ A, B ] = orderAssets(...[assetA, assetB]);
    const poolShareAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
    const pool_id = StellarSdk.getLiquidityPoolId("constant_product", poolShareAsset.getLiquidityPoolParameters()).toString("hex");

    this.setState({ pool_id })


    getAssetInfo(asset[0], asset[1]).then(result => {
      // console.log('getAssetInfo result: ', result)

      const base_asset = result.data[0]
      const usd_price = (base_asset.price * this.state.price)
      const vol_usd = (base_asset.base_volume * this.state.price)
      const market_cap_usd = ((base_asset.supply * base_asset.price) * this.state.price)

      if (result.data.length) {
        this.setState({
          base_asset,
          usd_price,
          vol_usd,
          market_cap_usd
        })
      }
    })
  }


  changeAccount = (value) => {
    // console.log('value: ', value)

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address + value[key])
      }
      else {

        this.setState({
          select_address: address,
          error: false,
          trustline: false,
          account: false,
        })

        this.checkAccount(address)
      }
    }
  }


  checkAccount = (address) => {

    const { base_asset } = this.state

    this.setState({ loading_account: true })

    getAccount(address).then(result => {

      this.setState({ loading_account: false })

      if (result.balances) {
        const trustline = result.balances.some(item => item.asset_type !== 'native' && item.asset_code === base_asset.asset_code && item.asset_issuer === base_asset.asset_issuer)

        this.setState({
          account: result,
          trustline,
        })
      }
      else {

        this.setState({
          error: true,
          trustline: false,
          account: false,
        })

        // swal({
        //   title: 'Not found',
        //   text: 'This account does not exist or is not funded by XLM.',
        //   icon: 'info'
        // })
      }
    })
      .catch(error => {
        console.log('error: ', error)

      })
  }



  addTrustline = () => {

    const { select_address, asset, confirm_accept } = this.state

    if (!select_address) {
      ToastStore.info(`Account not selected`)
    }
    else if (confirm_accept) {
      this.setTrustline();
    }
    else {

      const message = `<div>Add trustline for <b class="text-warning">${asset[0]}</b> right now?</div>`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: `Confirm`,
        content: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {

            this.setState({ confirm_accept: true })

            this.setTrustline();
          }
        })
    }

  }


  setTrustline = () => {

    const { asset, select_address } = this.state

    this.setState({ loading_account: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: this.fee, // StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.changeTrust({
            asset: new StellarSdk.Asset(asset[0], asset[1]),
          }))
          .setTimeout(100)
          .build();

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        this.setState({
          trustline: true,
          loading_account: false,
        })

        const message = `<div>Trustline to <b>${asset[0]}</b> token has been successfully installed. Now <b>${asset[0]}</b> token is available on your balance.</div>`
        const element = document.createElement("span");
        element.innerHTML = message;

        swal({
          title: `Added new balance ${asset[0]}!`,
          content: element,
          icon: "success",
          confirm: true,
        })
          .then(confirm => {
            if (confirm) {
              // ...
            }
          });
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loading_account: false })

        showErrorMessage(error, 'change_trust')
      });
  }


  toggleQR = () => {
    this.setState({ show_qr: !this.state.show_qr })
  }


  renderItem = (item, index) => {
    // console.log('item: ', item)

    const { assets, price } = this.state

    const stellar_logo = '/img/logo/stellar-logo.png'
    const default_logo = '/img/digitalcoin.png'

    const assetB = item.asset[0] === 'XLM' ? 'native' : item.asset[0].slice(0, -2).replace("-", ":")
    const assetA = item.asset[1].slice(0, -2).replace("-", ":")

    let counterAssetDB = (assetB === 'native') ? {
      asset_code: 'XLM',
      asset_issuer: 'native',
      image: stellar_logo,
      home_domain: 'Native',
      price
    } : assets.filter(item => item.asset_code === assetB.split(':')[0] && item.asset_issuer === assetB.split(':')[1])[0]

    let asset_a = assets.filter(item => item.asset_code === assetA.split(':')[0] && item.asset_issuer === assetA.split(':')[1])[0]
    let asset_b = counterAssetDB

    if (!asset_a) {
      asset_a = {
        asset_code: assetA.split(':')[0],
        asset_issuer: assetA.split(':')[1],
        image: default_logo,
        home_domain: '---',
        price
      }
    }
    if (!asset_b) {
      asset_b = {
        asset_code: assetB.split(':')[0],
        asset_issuer: assetB.split(':')[1],
        price: 0,
        image: default_logo,
        home_domain: '---',
      }
    }

    const url = `/trade/${asset_a.asset_code}-${asset_b.asset_code}/${asset_a.asset_issuer}/${asset_b.asset_issuer}`
    let series = item.price7d.length ? item.price7d.map(item => Number(item[1] + 10).toFixed(7)) : []
    const color = item.change7d < 0 ? '#ff4861' : '#4ce1b6'

    series = [{data: series}]

    const options7d =  {
      chart: {
        sparkline: {
          enabled: true
        }
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          return '<small class="tooltip-7d">' + (series[seriesIndex][dataPointIndex] - 10).toFixed(5) +'</small>'
        },

        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          //
        },
        marker: {
          show: false
        }
      },
      stroke: {
        width: 2,
        curve: 'smooth',
        // lineCap: 'round',
        colors: color,
      }
    };

    return (
      <p key={index} className={'mb-20'}>
        <a href={url}>
          <div className={'icon-asset-pair-market'}>
            <img src={asset_a.image} alt={``}/>
          </div>
          <div className={`icon-asset-pair-market ml-4`}>
            <img src={asset_b.image} alt={``}/>
          </div>

          <div className={'ml-75'}>
            <span>
              <span className={''}><b>{asset_a.asset_code}</b> <span className={'text-secondary'}>/</span> <b>{asset_b.asset_code}</b></span>
              <div className={'text-secondary mt-min-6'}>
                <small className={'overflow'}>
                  <span>{asset_a.home_domain}</span> / {' '}
                  <span>{asset_b.home_domain}</span>
                </small>
              </div>
            </span>
          </div>

          <div align={'right'} style={{marginTop: '-32px'}}>
            <ReactApexChart options={options7d} series={series} type="line" height={28} width={90} />
          </div>
        </a>
      </p>
    )
  }


  render() {

    console.log('state: ', this.state)

    const { account, base_asset, counter_asset, popoverCapOpen, wallets, pool_id, markets, assets, usd_price, vol_usd, market_cap_usd,
      select_address, loading_account, trustline, error, asset, uri, show_qr } = this.state

    let border_bottom = '1px solid #eff1f57a'
    if (localStorage.getItem('theme') === 'theme-dark') {
      border_bottom = '1px solid #33324657'
    }

    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col lg={{size: 4, offset: 4}} md={{size: 8, offset: 2}} sm={{size: 12, offset: 0}} >


            {
              show_qr ?
                <Card style={{height: 'auto'}}>
                  <CardBody >

                    <div className="card__title">
                      <h5 className="bold-text">QR Code (SEP-0007)</h5>
                      <h5 className="subhead">Scan this QR code on Scopuly mobile app</h5>
                    </div>

                    <div className="panel__btns">
                      <button className="panel__btn" type="button" onClick={this.toggleQR}>
                        <i className="fa fa-close text-secondary"></i>
                      </button>
                    </div>

                    <div className={'text-center'}>
                      <div>

                        <QRCode
                          value={uri}
                          size="280"
                          quietZone="20"
                          // fgColor={f7.colors[color]}
                          qrStyle="dots"
                          // logoOpacity="0.8"
                          logoImage={base_asset.image}
                          removeQrCodeBehindLogo={true}
                          logoPadding={2.5}
                          logoWidth="32" // 46
                          logoHeight="32" // 46
                          ecLevel="M"
                          eyeRadius={[
                            { // top/left eye
                              outer: [10, 10, 0, 10],
                              inner: [1, 1, 0, 1],
                            },
                            { // top/right eye
                              outer: [10, 10, 10, 0],
                              inner: [1, 1, 1, 0],
                            },
                            { // bottom/left
                              outer: [10, 0, 10, 10],
                              inner: [1, 0, 1, 1],
                            },
                          ]}
                          eyeColor={[
                            { // top/left eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                            { // top/right eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                            { // bottom/left eye
                              outer: '#217fe4',
                              inner: 'black',
                            },
                          ]}
                        />

                      </div>

                      <div style={{marginTop: '4px'}}>
                        <small>
                          <CopyToClipboard text={window.location.origin+`/trustline/${asset[0]}-${asset[1]}`}
                                           onCopy={() => ToastStore.success(`Copied!`)}>
                            <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> Link</Button>
                          </CopyToClipboard>
                          <CopyToClipboard text={this.state.uri} onCopy={() => ToastStore.success(`Copied!`)}>
                            <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> web+stellar</Button>
                          </CopyToClipboard>
                          <CopyToClipboard text={this.state.xdr} onCopy={() => ToastStore.success(`Copied!`)}>
                            <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> XDR</Button>
                          </CopyToClipboard>
                        </small>
                      </div>
                    </div>

                  </CardBody>
                </Card>
                : null
            }


            <Card style={{height: 'auto'}}>
              <CardBody>

                <div className="card__title" style={{marginBottom: 19}}>
                  <h5 className="bold-text">Add Trustline</h5>
                  {/*({this.props.match.params.id.split('-')[0]})*/}
                </div>
                {
                  base_asset ?
                    <div className="panel__btns">
                      {
                        this.scop.code === base_asset.asset_code && this.scop.issuer === base_asset.asset_issuer ?
                          <div>
                            <Link to={`/rewards`}>
                              <div className={'rewards pool-rewards pool-color-scop'}
                                   style={{right: 25}}
                              >Get <b>+{numFormat(this.apy_scop)}% SCOP <i className={'fa fa-question-circle-o'}></i></b></div>
                            </Link>
                          </div>
                        : null
                      }

                      <button className="panel__btn" type="button" onClick={this.toggleQR}>
                        <i className="fa fa-qrcode text-secondary"></i>
                      </button>
                    </div>
                  : null
                }

                {
                  base_asset ?
                    <div>
                      <div className="profile__activity" style={{padding: '0 0 12px'}}>
                        <div className="profile__activity-avatar">
                          <Link to={`/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}>
                            <img className="icon-asset" src={ base_asset.image } alt="" />
                          </Link>
                        </div>
                        <div>
                          <Link to={`/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}>
                            <h3 className="profile__activity-code">{base_asset.asset_code}
                              <div className="profile__activity-date" style={{margin: '-8px 0 4px 0', display: base_asset.asset_code.length > 8 ? 'block' : 'inline'}}>&nbsp; {base_asset.name}</div>
                            </h3>
                          </Link>

                          <span><i className={'fa fa-lock text-success fs-12'}></i></span> &nbsp;

                          <Link className="text-secondary"
                                to={`/account/${base_asset.asset_issuer}`}
                                style={{fontSize: 11}}>{iconIdent(base_asset.asset_issuer, 'icon-indent-sm')}{' '}
                            {shortAddr(base_asset.asset_issuer, 8)}&nbsp;&nbsp;
                          </Link> {' '}

                          <CopyToClipboard text={base_asset.asset_issuer} onCopy={() => {}}>
                            <i className="fa fa-clone"
                               color="secondary"
                               style={{cursor: 'pointer', fontSize: 11}}
                               onClick={() => ToastStore.success('Copied!')}></i>
                          </CopyToClipboard>

                        </div>

                      </div>


                      <Table className="table--bordered asset-info" responsive >
                        <tbody>
                        <tr>
                          <td>PRICE</td>
                          <td>
                            <span className="text-info">$<b>{numFormat(usd_price, 7)}</b></span>
                          </td>
                        </tr>
                        <tr>
                          <td>CHANGE 24</td>
                          <td><b className="text-info"><Badge color={base_asset.change_24h_color}>{base_asset.change24}%</Badge></b></td>
                        </tr>
                        <tr>
                          <td>VOL 24</td>
                          <td>
                            <b className="text-info">${numFormat((vol_usd))}</b>
                          </td>
                        </tr>
                        <tr>
                          <td>SUPPLY</td>
                          <td><small className="text-secondary">{base_asset.asset_code}</small> <b className="text-info">{numFormat(base_asset.supply, 0)}</b></td>
                        </tr>
                        <tr>
                          <td>MARKETCAP {' '}
                            <i id="popover-cap" onClick={this.togglePopoverCap} className="fa fa-question-circle-o text-secondary"></i> {' '}

                            <Popover
                              placement="bottom"
                              isOpen={popoverCapOpen}
                              target="popover-cap"
                              trigger="legacy"
                              toggle={this.togglePopoverCap}
                            >
                              <PopoverBody>
                                This is not an accurate estimate of the token capitalization. A more accurate estimate of capitalization
                                should be calculated by multiplying the number of tokens held by holders by the market price. It is almost
                                impossible to find out how many tokens on hand, and now the total number of tokens issued to the market is
                                multiplied by the current price. <i className="fa fa-exclamation-triangle"></i> Be careful, the real capitalization of an asset can be 10 or 100 or more times lower.
                              </PopoverBody>
                            </Popover>
                          </td>
                          <td>&asymp; <b className="text-info">${numFormat(market_cap_usd)}</b></td>
                        </tr>
                        {/*<tr>
                        <td>TOTAL VOLUME <small>(XLM)</small></td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.overall_trade_volume, 0)}</b></td>
                      </tr>
                      <tr>
                        <td>TRADES COUNT</td>
                        <td><b className="text-info">{numFormat(this.props.state.asset_db.total_trades_count, 0)}</b></td>
                      </tr>*/}
                        <tr>
                          <td>HOLDERS</td>
                          <td><b className="text-info">{numFormat(base_asset.holders, 0)}</b></td>
                        </tr>
                        <tr>
                          <td>
                            RATING {' '}
                            <i id="PopoverTop" onClick={this.togglePopoverQuery} className="fa fa-question-circle-o text-secondary"></i> {' '}

                            <Popover
                              placement="top"
                              isOpen={this.state.popoverQueryOpen}
                              target="PopoverTop"
                              trigger="legacy"
                              toggle={this.togglePopoverQuery}
                            >
                              <PopoverBody>
                                Composite rating based on asset age, established trustlines, weekly trading volume, payments and trades count. <br/> The asset rating is determined based on data from <a href="https://stellar.expert/" target='_blank' rel='noopener noreferrer'>stellar.expert</a>
                              </PopoverBody>
                            </Popover>
                          </td>
                          <td>
                            <b className="text-info">{numFormat(base_asset.expert_rating, 1)}</b>
                          </td>
                        </tr>
                        <tr style={{borderBottom: border_bottom}}>
                          <td>DOMAIN</td>
                          <td>
                            <b className={'text-info'} style={{float: 'right'}}>
                              {
                                base_asset.home_domain ?
                                  <a className="text-info"
                                     href={`https://${base_asset.home_domain}`}
                                     target='_blank' rel='noopener noreferrer'
                                  >{base_asset.home_domain}</a>
                                  : '...'
                              }
                            </b>
                          </td>
                        </tr>
                        </tbody>
                      </Table>

                      <br/>

                      {
                        !loading_account ?
                          <form className="material-form">
                            <div>
                              {
                                wallets ?

                                  <Field
                                    name="select_addr"
                                    component={renderTextField}
                                    select
                                    label="Select Account"
                                    onChange={(e) => {
                                      this.changeAccount(e)
                                    }} >
                                    {
                                      wallets.map((item, index) => {

                                        if (item) {
                                          return (
                                            <MenuItem
                                              key={index}
                                              style={{fontSize: '12px'}}
                                              className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                              value={item.pk}>
                                              <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                            </MenuItem>
                                          )
                                        }
                                      })
                                    }
                                  </Field>
                                  : null
                              }
                            </div>
                          </form>
                        :
                          <Card>
                            <CardBody>
                              <div className="panel__refresh text-center text-secondary" style={{height: '100%'}}>
                                <LoadingIcon />
                              </div>
                            </CardBody>
                          </Card>
                      }

                      {
                        trustline ?
                          <div className={'text-center text-success trustline-mess-success'}>
                            <div><b>{base_asset.asset_code}</b> added to this address!</div>
                          </div>
                        : null
                      }

                      {
                        error ?
                          <div className={'text-center text-danger trustline-mess-error'}>
                            <div>This account does not exist or is not funded by <Link to={`/native`}><b className={'text-danger'}>XLM</b></Link>.</div>
                          </div>
                        : null
                      }

                      <ButtonToolbar className="form__button-toolbar" style={{width: '100%'}}>
                        {
                          wallets ?
                            (
                              trustline ?
                                <Link to={`/trustlines/${select_address}`} className={'btn btn-lg btn-outline-secondary'} style={{width: '100%'}}>
                                  <i className={'fa fa-trash-o'}></i> Delete trustline <b>{base_asset.asset_code}</b>
                                </Link>
                                : error ?
                                <Link to={`/exchange`} className={'btn btn-lg btn-primary'} style={{width: '100%'}}>
                                  <i className={'fa fa-money'}></i> Buy <b>{counter_asset.asset_code}</b>
                                </Link>
                                :
                                <Button onClick={this.addTrustline}
                                        color={'success'}
                                        size={'lg'}
                                        disabled={trustline || loading_account || error ? true : false}
                                        style={{width: '100%'}}>
                                  <i className={'fa fa-check'}></i> Accept <b>{base_asset.asset_code}</b>
                                </Button>
                            )
                            :
                            <Link to={'/auth/'}
                                  className={'btn btn-primary btn-lg'}
                                  style={{width: '100%'}}>Connect Wallet</Link>
                        }
                      </ButtonToolbar>

                      <ButtonGroup className="btn-group--justified" style={{marginBottom: '-19px'}}>
                        <Link to={`/trade/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}
                              className={'btn btn-outline-secondary'} >Trade</Link>

                        <Link to={`/swap/${base_asset.asset_code}-${counter_asset.asset_code}/${base_asset.asset_issuer}/${counter_asset.asset_issuer}`}
                              className={'btn btn-outline-secondary'} >Swap</Link>

                        <Link to={`/pool/${pool_id}`}
                              className={'btn btn-outline-secondary'} >Pool</Link>
                      </ButtonGroup>

                    </div>
                  :
                    <Card><CardBody><div className="panel__refresh" style={{height: '89%'}}><LoadingIcon /></div></CardBody></Card>
                }

              </CardBody>
            </Card>


            <Card style={{height: 'auto'}}>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">FAQ</h5>
                  {/*<h4 className="subhead">Answers to frequently asked questions</h4>*/}
                </div>

                <Collapse title="What is Stellar Trustline?" className="with-shadow ml-0">
                  <p>In order to trade, receive, or hold any asset on the Stellar network you must first establish a trustline with the anchor’s issuing account. <br/><br/>
                    Trustlines are added to your Stellar account and let you hold a token as well as specify the amount of that token you're willing to hold. Trustlines also keep you from receiving unwanted assets.</p>
                </Collapse>
                <Collapse title="How to add trustline to a token?" className="with-shadow ml-0">
                  <p>Select your Stellar Account in the form above (if you don't have an address yet, you can create one -->> <a href="/auth/create"><b>here</b></a>) and click on the Accept Asset button. <br/><br/>
                    <a href="https://developers.stellar.org/docs/issuing-assets/anatomy-of-an-asset#trustlines" target={'_blank'}><b>Trustlines</b></a> are set using the Change Trust operation and cost a base reserve fee of <b>0.5 XLM</b> each. What this means is that for each trustline you set, your account will be required to maintain <b>0.5 XLM</b> in reserve that can’t be spent until you close your trustlines.</p>
                </Collapse>
                <Collapse title="How to remove trustline?" className="with-shadow ml-0">
                  <p>Select your account and go to the page to manage (add/remove) all existing Trustlines at this address. There you can delete existing and add new Trustlines. <br/><br/>
                    To remove Trustlines, the balance of the asset to be removed must be <b>zero</b> (0.0000000).</p>
                </Collapse>
                <Collapse title="How to fund a new account?" className="with-shadow ml-0">
                  <p>If you have just created your Stellar Address, you will need to fund this address in order for it to be activated and appear on the network. To do this, you need to send XLM (Stellar Lumens) cryptocurrency to this address. Recommended minimum <b>2 XLM</b>. <br/><br/>
                    <a href="https://developers.stellar.org/docs/fundamentals-and-concepts/lumens#base-reserves" target={'_blank'}><b>Learn more</b></a> about address activation.</p>
                </Collapse>
                <Collapse title="Where to buy XLM?" className="with-shadow ml-0">
                  <p>You can buy XLM (as well as any other cryptocurrency) for fiat -->> <a href="/exchange"><b>here</b></a>. Enter your new address where you want to receive XLM and then enter your bank card and the required amount in your fiat currency (USD, EUR, etc.). After that, pay for the order and the cryptocurrency will be credited to your specified address.</p>
                </Collapse>

              </CardBody>
            </Card>


            {
              markets.length && assets.length ?

                <Card style={{height: 'auto'}}>
                  <CardBody>
                    <div className="card__title" >
                      <h5 className="bold-text">Markets for { getAsset(`${asset[0]}:${asset[1]}`) }</h5>
                    </div>

                    <div>

                      { markets.map(this.renderItem) }

                    </div>

                    <div className={'text-center'} style={{marginBottom: '-19px'}}>
                      <br/>
                      <Link to={`/markets/${base_asset.asset_code}-${base_asset.asset_issuer}`} className={'btn btn-secondary'}>All <b>{base_asset.asset_code}</b> Markets</Link>
                    </div>
                  </CardBody>
                </Card>

              : null
            }

          </Col>
        </Row>

        <Footer />

      </Container>
    )
  }
}



export default reduxForm({
  form: 'trustline_form',
})(translate('common')(Trustline));
