import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, ButtonToolbar, Button, Card, CardBody, Col } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import LoadingIcon from 'mdi-react/LoadingIcon';
import CreateAccount from './components/CreateAccount';
import RestoreAccount from './components/RestoreAccount';
import Footer from '../../Layout/footer/Footer';
import {getTitle, animateElems, } from '../../../modules/index';
// import moment from 'moment';


class Auth extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  componentDidMount() {
    //
    getTitle('Connect Wallet', 'Create or Connect Stellar Wallet')
    animateElems()
  }

  onRefresh = () => {
    this.setState({ refresh: true });
  };



  render() {

    // console.log('state: ', this.state)

    return (
      <Container className="dashboard">

        {this.state.refresh ? <div className="panel__refresh"><LoadingIcon /></div> : ''}



        {
          !this.props.match.params.id ?
            <Row>
              <Col md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 4 }}>
                <Card>
                  <CardBody className={'text-center'}>

                    <div className={'text-center'}>
                      <br/>
                      <h2>Connect to Stellar</h2>
                      <br/><br/>
                      <img src="/img/home/wallet free_2@4x.png" alt="Connect to Stellar" />
                      <br/><br/>
                      <p>If you don't have a Stellar account yet, you can create one right now in 1 minute.
                        If you already have a Stellar account, you can connect it through a browser extension or directly with a passphrase.</p>
                    </div>

                    <br/><br/>

                    <div>
                      <Link to={"/auth/create"} className={'btn btn-success w-100'}><i className={'fa fa-plus'}></i> <b>Create Account</b></Link>
                      <Link to={"/auth/connect"} className={'btn btn-outline-secondary w-100'}><i className={'fa fa-sign-in'}></i> <b>Connect Wallet</b></Link>
                    </div>

                    <hr/>
                    <div>
                      <small className={'text-secondary'}><i className={'fa fa-exclamation'}></i> {' '}
                        Scopuly doesn't store your secret keys or passphrases. And doesn't ask for your personal information.
                        Store your login information securely on external media. If you lose your passphrase or secret key,
                        you'll not be able to recover your account.</small>
                    </div>
                    <br/>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          : null
        }

        <Row>

          { this.props.match.params.id === 'create' ? <CreateAccount /> : null }

          { this.props.match.params.id === 'connect' ? <RestoreAccount /> : null }

        </Row>

        <div className='text-center'>
          {
            this.props.match.params.id === 'connect' ? <Link to='/auth/create' className={'btn btn-outline-secondary'}><i className="fa fa-plus"></i> Create Account</Link> : null
          }
          {
            this.props.match.params.id === 'create' ? <Link to='/auth/connect' ><i className="fa fa-sign-in"></i> Connect Wallet</Link> : null
          }
        </div>

        <br/><br/>

        <Footer />

      </Container>
    );
  }
}


Auth.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Auth);
