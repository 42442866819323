import React, { PureComponent } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { AreaChart, Tooltip, Area, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import { Link } from 'react-router-dom';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import TrendingDownIcon from 'mdi-react/TrendingDownIcon';
import PropTypes from 'prop-types';
import { GLOBE } from '../../../../modules/globeVars/index';
import { numFormat } from '../../../../modules/index';
import getPrice from '../../../App/GetPrice';


const CustomTooltip = ({ active, payload }) => {

  if (active) {
    return (
      <div className="dashboard__total-tooltip">
        <p className="label">{numFormat(payload[0].value, 7)} XLM</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

export default class Top4Assets extends PureComponent {
  constructor() {
    super();
    this.state = {
      activeIndex: 0,
      items: false,
      price: 0,
    };
  }

  componentDidMount() {

    axios.post(GLOBE.API_URL+`/get_top4_assets`)
      .then(response => {

        if (response.data.length) {
          this.setState({
            items: response.data
          })
        }
    });
  }


  getBasePrice() {
    // const price = JSON.parse(localStorage.getItem('price'));

    this.setState({
      price: this.props.price,
    })

    // if (price) {
    //   this.setState({
    //     price: this.props.price;
    //   })
    // }
    // else {
    //   getPrice()
    //   setTimeout(() => {
    //     this.getBasePrice()
    //   }, 1000)
    // }
  }


  renderAssetItem(item, index) {

    // console.log('item: ', item)

    const chart7d = JSON.parse(item.chart7d);

    this.getBasePrice()

    // price_usd = (item.price * price_usd[0].price_usd);


    if (item.change_24h_color === 'danger') {
      var color_fill = '#f198ca';
    } else {
      var color_fill = '#4ce1b6';
    }

    return (
      <Col md={12} xl={3} lg={6} xs={12} key={index}>
        <Card>
          <CardBody className="dashboard__card-widget">
            <div className="card__title" style={{marginBottom: 53}}>
              <Link to={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}>
                <h4 className="bold-text">
                  <img src={item.image ? item.image : '/img/digitalcoin.png'} className="image-icon" />
                  <span>
                    <b>{item.asset_code}</b>
                    <div className="subhead" style={{color: '#636777'}}>{!item.name || item.name === 'undefined' ? item.asset_code : item.name}</div>
                  </span>
                </h4>

                <h5 className="subhead">{item.home_domain}</h5>
              </Link>
            </div>
            <div className="dashboard__total dashboard__total--area">
              {
                item.change_24h_color === 'danger' ?
                  <TrendingDownIcon className="dashboard__trend-icon" />
                :
                  <TrendingUpIcon className="dashboard__trend-icon" />
              }
              <div>{' '} {' '}</div>
              <ResponsiveContainer height={70} className="dashboard__chart-container">
                <AreaChart data={chart7d} margin={{ top: 0, left: 0, bottom: 0 }}>
                  <Tooltip content={<CustomTooltip />} />
                  <Area
                    name={item.asset_code}
                    type="monotone"
                    dataKey="price"
                    fill={color_fill}
                    stroke={color_fill}
                    fillOpacity={0.2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className="text-secondary" style={{marginTop: '8px'}}>
              <small><b>${numFormat((this.state.price * item.price), 2)}</b> / {numFormat(item.price, 7)} XLM <font className={`text-${item.change_24h_color}`}>{item.change24}%</font></small>
            </div>

          </CardBody>
        </Card>
      </Col>
    )
  }

  render() {

    return (
      <Row>
        {
          this.state.items ?
            this.state.items.map((item, index) => this.renderAssetItem(item, index))
          : null
        }
      </Row>
    );
  }
}
