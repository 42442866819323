import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { ToastContainer, ToastStore } from 'react-toasts';
import PropTypes from 'prop-types';
import renderSelectField from '../../../../../../shared/components/form/Select';


const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}



class WizardFormTwo extends PureComponent {

  constructor() {
    super();
    this.state = {
      // showPassword: false,
    };
  }


  passSubmit = (values) => {

    const { handleSubmit } = this.props;

    let password1 = values.password1;
    let password2 = values.password2;

    if (!password1) {
      ToastStore.warning('Please enter your password');
    }
    else if (!password2) {
      ToastStore.warning('Please enter the password again');
    }
    else if (password1.length < 8 || password2.length < 8) {
      ToastStore.error('Password must be at least 8 characters');
    }
    else if (password1 !== password2) {
      ToastStore.error('Passwords do not match');
    }
    else {
      handleSubmit()
    }
  }


  render() {

    const { handleSubmit, previousPage } = this.props;

    return (
      <form className="wizard__form material-form" onSubmit={handleSubmit(this.passSubmit)}>

        {/*<div className="text-center">*/}
        {/*  <h3 className="wizard__title">Encryption Password</h3>*/}
        {/*</div>*/}

        {/*<div>*/}
        {/*  <p>*/}
        {/*    Enter the password to export the account. Keep this password in a safe place.*/}
        {/*    Without this password you will not be able to recover your wallet in the future.*/}
        {/*  </p>*/}
        {/*  <p>*/}
        {/*    For security and safety reasons, it is recommended to store this data not in the cloud,*/}
        {/*    but somewhere on a removable USB-drive.*/}
        {/*  </p>*/}
        {/*  <div className="card__title">*/}
        {/*    <h5 className="subhead">*/}
        {/*      The password must contain at least 8 characters. It is recommended to establish a*/}
        {/*      reliable complex password, consisting of different characters and numbers.*/}
        {/*    </h5>*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <Field*/}
        {/*      name="password1"*/}
        {/*      component={renderTextField}*/}
        {/*      label="Password"*/}
        {/*      type="password"*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div>*/}
        {/*    <Field*/}
        {/*      name="password2"*/}
        {/*      component={renderTextField}*/}
        {/*      placeholder="Password again"*/}
        {/*      label="Password again"*/}
        {/*      type="password"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<ButtonToolbar className="form__button-toolbar wizard__toolbar">*/}
        {/*  <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>*/}
        {/*  <Button color="primary" type="submit" className="next">Next</Button>*/}
        {/*</ButtonToolbar>*/}
      </form>
    )
  }
}


WizardFormTwo.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormTwo);
