import React from 'react';
import { Col, Row, Container } from 'reactstrap';

const stellar_logo = `${process.env.PUBLIC_URL}/img/logo/stellar-logo.png`; 

const stellarx = `${process.env.PUBLIC_URL}/img/landing/exchanges/stellarx.png`;
const stellarport = `${process.env.PUBLIC_URL}/img/landing/exchanges/stellarport.png`;
const interstellar = `${process.env.PUBLIC_URL}/img/landing/exchanges/interstellar.png`;
const stellarterm = `${process.env.PUBLIC_URL}/img/landing/exchanges/stellarterm.png`;
const scopuly = `${process.env.PUBLIC_URL}/img/landing/exchanges/scopuly.png`;

const idax = `${process.env.PUBLIC_URL}/img/landing/exchanges/soon/idax.png`;
const latoken = `${process.env.PUBLIC_URL}/img/landing/exchanges/soon/latoken.png`;
const p2pb2b = `${process.env.PUBLIC_URL}/img/landing/exchanges/soon/P2PB2B.png`;
const probit = `${process.env.PUBLIC_URL}/img/landing/exchanges/soon/probit.png`;
const vindax = `${process.env.PUBLIC_URL}/img/landing/exchanges/soon/vindax.png`;



const FeatureRequest = () => (
  <section className="landing__section">
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">Scopuly Coin (SCOP)</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <p className="landing__request">
            SCOP gives access to crypto cards, a staking service,
            allows you to invest and launch IEO projects, pay fees from crypto/fiat exchange transactions,
            rewards, bonuses and access to future Scopuly services.  Token issued by <img src={stellar_logo} style={{width: '3%'}}/> Stellar.
          </p>
        </Col>
      </Row>

      <br/><br/><br/>

      <Row>
        <Col md={12}>
          <h3 className="landing__section-title">SCOP on exchanges</h3>
        </Col>
      </Row>

      <Row>
        <Col md={2} sm={{ size: 4, offset: 0 }} xs={{ size: 6, offset: 3 }}>
          <div className="landing__technology landing__technology--small reset-mt">
            <div className="landing_icon">
              <a href="https://scopuly.com/asset/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ"
                 target="_blank" rel='noopener noreferrer' ><img src={scopuly} /></a>
            </div>
          </div>
        </Col>
        <Col md={{ size: 2, offset: 1 }} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small reset-mt">
            <div className="landing_icon">
              <a href="https://www.stellarx.com/markets/SCOP:GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ" 
                 target="_blank" rel='noopener noreferrer' ><img src={stellarx} /></a>
            </div>
          </div>
        </Col>
        <Col md={2} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small reset-mt">
            <div className="landing_icon">
              <a href="https://stellarport.io/exchange/alphanum4/SCOP/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native/XLM/Stellar" 
                 target="_blank" rel='noopener noreferrer' ><img src={stellarport} /></a>
            </div>
          </div>
        </Col>
        <Col md={{ size: 2, offset: 0 }} sm={{ size: 4, offset: 2 }} xs={6}>
          <div className="landing__technology landing__technology--small reset-mt">
            <div className="landing_icon">
              <a href="https://interstellar.exchange/app/#/trade/guest/SCOP/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/XLM/native" 
                 target="_blank" rel='noopener noreferrer' ><img src={interstellar} /></a>
            </div>
          </div>
        </Col>
        <Col md={2} sm={4} xs={6}>
          <div className="landing__technology landing__technology--small reset-mt">
            <div className="landing_icon">
              <a href="https://stellarterm.com/exchange/SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/XLM-native" 
                 target="_blank" rel='noopener noreferrer' ><img src={stellarterm} /></a>
            </div>
          </div>
        </Col>
      </Row>

      <br/>
      
      <Row>
        <Col md={12}>
          <h5 className="text-center">Coming soon:  
            <a href="https://coinmarketcap.com/exchanges/p2pb2b/" target="_blank">
              <img src={p2pb2b} style={{maxWidth: '9%'}} alt="p2pb2b | scopuly" />
            </a>
            <a href="https://coinmarketcap.com/exchanges/idax/" target="_blank">
              <img src={idax} style={{maxWidth: '3%', marginRight: '18px'}} alt="idax | scopuly" />
            </a>
            <a href="https://coinmarketcap.com/exchanges/vindax/" target="_blank">
              <img src={vindax} style={{maxWidth: '7%', marginRight: '7px'}} alt="vindax | scopuly" />
            </a>
            <a href="https://coinmarketcap.com/exchanges/latoken/" target="_blank">
              <img src={latoken} style={{maxWidth: '8%'}} alt="latoken | scopuly" />
            </a>
            <a href="https://coinmarketcap.com/exchanges/probit-exchange/" target="_blank">
              <img src={probit} style={{maxWidth: '8%'}} alt="probit-exchange | scopuly" />
            </a>
            and other exchanges...</h5>
        </Col>
      </Row>

      <br/><br/><br/><br/>

      <Row>
        <Col md={12}>
          <div className="landing__center-btn">
            <a
              className="landing__btn"
              href="http://scop.scopuly.com/"
              style={{marginRight: '24px'}}
            >
              About SCOP
            </a>
            <a
              className="landing__btn landing__btn--gradient"
              href="/trade/SCOP-XLM/GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ/native"
            >
              Buy on <b>Scopuly</b>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export default FeatureRequest;
