import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';


 let apiUrl = 'https://api.scopuly.com';
 let price = {};


export default function getPrice() {

  //== GET CURRENT PRICE =============\

	let interval = 30;
	let last_time = localStorage.getItem('last_time_price');

  if (!last_time) {
  	setLocalTimePrice();
  	get_price();
  }
  else {
  	let dateB = moment();
  	let dateC = moment(last_time);

  	let differ = dateB.diff(dateC, 'minutes');

  	if (differ >= interval) {
  		setLocalTimePrice();
  		get_price();
  	}
  	else {

  		let loc_price = localStorage.getItem('price');
  		if (loc_price) {
  			price = JSON.parse(loc_price)
  		}
  		else {
  			get_price()
  		}
  	}
  }

  function setLocalTimePrice() {
  	localStorage.setItem('last_time_price', moment().format());
  }

  function get_price() {

    fetch(apiUrl+'/api/get_cmc_map')
    .then(res => res.json())
    .then((result) => {
      // console.log('get_cmc_map result', result);

        if (result.data) {
					// const string = JSON.stringify(result);
					// localStorage.setItem('price_usd', string)

					// let stellar_id;
					result.data.forEach((item, i) => {
						if (item.name === 'Stellar' && item.symbol === 'XLM') {
							// stellar_id = item.id
							get_quotes_cmc(item.id)
						}
					});
				}
        else {
					get_local_prices()
				}
      },
      (error) => {
        console.log('err', error);
				get_local_prices()
      }
    )


  	function get_quotes_cmc(stellar_id) {

      const formData = new FormData();
      formData.append('id', stellar_id)
      // console.log('stellar_id: ', stellar_id)

      axios.post(apiUrl+'/api/get_cmc_quotes', formData)
        .then(result => {
          // console.log('get_cmc_quotes response: ', result)

          const data = result.data.data;
            price.crypto_price = {
      				xlm: data[stellar_id],
      				usd: data[stellar_id].quote.USD,
      				xlm_usd: data[stellar_id].quote.USD.price
      			}

      			get_local_prices()
      });
  	}

  	function get_local_prices() {

      fetch('https://min-api.cryptocompare.com/data/price?fsym=XLM&tsyms=USD,EUR,RUB,CNY,AUD,BRL,CAD,CHF,CLP,CNY,CZK,GBP,HKD,HUF,IDR,ILS,INR,JPY,KRW,MXN,MYR,NOK,NZD,PHP,PKR,PLN,SEK,SGD,THB,TRY,TWD,ZAR')
        .then(res => res.json())
        .then((result) => {
          // console.log('result local_price: ', result);
            price.local_price = result;
    				localStorage.setItem('price', JSON.stringify(price));

    				setLocalTimePrice();
          },
          (error) => {
            console.log('error api.cryptocompare.com', error);
          }
        )
  	}
  }
}
