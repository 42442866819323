export const CHANGE_BORDER_RADIUS = 'CHANGE_BORDER_RADIUS';
export const TOGGLE_BOX_SHADOW = 'TOGGLE_BOX_SHADOW';
export const TOGGLE_TOP_NAVIGATION = 'TOGGLE_TOP_NAVIGATION';
export const TOGGLE_SHOW_NOTIFICATION = 'TOGGLE_SHOW_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const TOGGLE_AUTH_PROVIDER = 'TOGGLE_AUTH_PROVIDER';

export function changeBorderRadius() {
  return {
    type: CHANGE_BORDER_RADIUS,
  };
}

export function toggleBoxShadow() {
  return {
    type: TOGGLE_BOX_SHADOW,
  };
}

export function toggleShowNotification() {
  return {
    type: TOGGLE_SHOW_NOTIFICATION,
  };
}

export function toggleTopNavigation() {
  return {
    type: TOGGLE_TOP_NAVIGATION,
  };
}

export function addNotification(notifications) {
  return {
    type: ADD_NOTIFICATION,
    payload: notifications
  };
}

export function toggleAuthProvider(isAuth) {
  return {
    type: TOGGLE_AUTH_PROVIDER,
    payload: isAuth
  };
}
