import React, { PureComponent } from 'react';
import { Table, Button, ButtonToolbar, Modal } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  shortAddr,
  numFormat,
  get_loc_wallets,
  baseReserve,
  getAuth,
  needDeauthAll,
  getTitle,
  getAsset,
  formatDate,
  shortAddress,
  showErrorMessage, checkConnectProvider, signConnectProvider, getStellarFee
} from '../../../../../modules/index';
import iconIdent from '../iconIdent';
import RequiredSigners from '../../../Auth/components/RequiredSigners';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { Field, reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Server } from '../../../../../modules/stellar/index';
import StellarSdk from 'stellar-sdk';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Draggable from 'react-draggable';
import renderCheckBoxField from "../../../../../shared/components/form/CheckBox";



const renderTextField = ({
  input, label, meta: { touched, error }, children, select, type,
}) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);

const pathArray = window.location.pathname.split('/')
if (pathArray[1] === 'offers') {
  getTitle('Offers for Stellar Account')
}



class Offers extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
			account: false,
	    my_address: false,
			modal: false,
			wallets: get_loc_wallets(),
			loader: false,
			remove_loader: false,

			offers: this.props.items,

      trade_account: {},
      load_trade_account: false,

      price_buy_change: 0,
      price_sell_change: 0,
      amount_buy_change: 0,
      amount_sell_change: 0,
      total_buy_change: 0,
      total_sell_change: 0,

      native_balance: 0,
      counter_balance: 0,
      base_asset_code: '',
			counter_asset_code: '',

      need_add_signers: false,
      transaction: false,

			offer_type: '',
			ask_price: 0,
			bid_price: 0,
      disable_draggable: false,
      del_offers: [],
      defaultChecked: false,
      fee: getStellarFee(),
	  };

		// this.toggleModal = this.toggleModal.bind(this);
    this.addSigners = this.addSigners.bind(this);
	}

	fee = getStellarFee()


	componentDidMount() {

		if (this.state.wallets) {
			this.state.wallets.forEach((item, index) => {
				if (item.pk === this.props.account_id) {
					this.setState({
						my_address: true,
						signer: item,
					})
				}
			})
		}
	}


	toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }



	addSigners(value) {

    this.setState({
      transaction: value,
      need_add_signers: false,
    })

    if (this.state.type_set_tx == 'update_offer') {
      this.setUpdateOfferTx();
    }
    else if (this.state.type_set_tx == 'remove_offer') {
      this.setRemoveOfferTx();
    }
  }



	editOffer(e, offer, offer_type, type) {
		e.preventDefault()

		let counter_asset_code, base_asset_code;

		if (offer_type === 'Buy') {
			counter_asset_code = offer.buying.asset_code
			base_asset_code = (offer.selling.asset_type === 'native' ? 'XLM' : offer.selling.asset_code)
		}
		if (offer_type === 'Sell')  {
			counter_asset_code = offer.selling.asset_code
			base_asset_code = (offer.buying.asset_type === 'native' ? 'XLM' : offer.buying.asset_code)
		}

		this.setState({
			offer,
			offer_type,
			counter_asset_code,
			base_asset_code
		})

		if (type === 'remove') {
			this.removeOffer(offer, offer_type)
		}
		else {
			this.setFormValue(offer, offer_type)
			this.toggleModal();
			this.getOrderbook(offer, offer_type)
			this.getAccount()
		}
	}




	getOrderbook(offer, offer_type) {

    let asset = {};

    if (offer_type === 'Buy') {
      if (offer.buying.asset_type !== "native" && offer.selling.asset_type !== "native") {
        asset.buying = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer);
        asset.selling = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer);
      }
      else {
        if (offer.buying.asset_type === "native") {
          asset.buying = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer);
          asset.selling = new StellarSdk.Asset.native();
        }
        else {
          asset.buying = new StellarSdk.Asset.native();
          asset.selling = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer);
        }
      }

      this.setState({
        asset_buying: asset.selling,
        asset_selling: asset.buying,
      })
    }

    if (offer_type === 'Sell') {

      if (offer.buying.asset_type !== "native" && offer.selling.asset_type !== "native") {
        asset.buying = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer);
        asset.selling = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer);
      }
      else {
        if (offer.buying.asset_type === "native") {
          asset.buying = new StellarSdk.Asset.native();
          asset.selling = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer);
        }
        else {
          asset.buying = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer);
          asset.selling = new StellarSdk.Asset.native();
        }
      }

      this.setState({
        asset_buying: asset.buying,
        asset_selling: asset.selling,
      })
    }


    Server.orderbook(asset.buying, asset.selling)
      .limit(1)
      .call()
      .then((result) => {
        if (result) {
					// console.log('orderbook result: ', result);

          // let base_asset_code, counter_asset_code;
					//
          // if (result.base.asset_type === 'native') {
          //   base_asset_code = 'XLM';
          // } else {
          //   base_asset_code = result.base.asset_code;
          // }

          // counter_asset_code = result.counter.asset_code;

          this.setState({
            orderbook: result,
            // load_orderbook: true,
            ask_price: (result.asks[0].price_r.d / result.asks[0].price_r.n),
            bid_price: (result.bids[0].price_r.d / result.bids[0].price_r.n),
            // base_asset_code: base_asset_code,
            // counter_asset_code: counter_asset_code,
          })
        }

      })
      .catch((err) => {
        console.log(err);
    })
  }



	getAccount(asset_code) {

    Server.accounts()
      .accountId(this.props.account_id)
      .call()
      .then(accountResult => {
				// console.log('accountResult: ', accountResult);
        let native_balance, counter_balance, reserve_balance;

        accountResult.balances.forEach((item) => {
          if (item.asset_type === 'native') {
            native_balance = item.balance;
            reserve_balance = (accountResult.subentry_count * baseReserve())
          }
          else if (item.asset_code === this.state.counter_asset_code) {
            counter_balance = item.balance;
          }
        })

        this.setState({
          account: accountResult,
          native_balance: native_balance,
          counter_balance: counter_balance,
          reserve_balance: reserve_balance,
        })
      })
      .catch(err => {
        console.error(err);
    })
  }



	priceChange(value) {
    let price = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        price = price + value[key];
      }
    }

    if (this.state.offer_type === 'Buy') {

      let total = (price * this.state.amount_buy_change).toFixed(7)

      this.setState({
        price_buy_change: Number(price),
        total_buy_change: Number(total),
      })

      this.props.change('buy_total_counter', Number(total))
    }

    if (this.state.offer_type === 'Sell') {

      let total = (price * this.state.amount_sell_change).toFixed(7)

      this.setState({
        price_sell_change: Number(price),
        total_sell_change: Number(total),
      })

      this.props.change('sell_total_counter', Number(total))
    }
  }


	amountChange(value) {
    let amount = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        amount = amount + value[key];
      }
    }

    if (this.state.offer_type === 'Buy') {
      this.setState({
        amount_buy_change: Number(amount),
      })

      if (this.state.price_buy_change) {
        let total = (amount * this.state.price_buy_change).toFixed(7)
        this.setState({
          total_buy_change: Number(total),
        })

        this.props.change('buy_total_counter', Number(total))
      }
    }

    if (this.state.offer_type === 'Sell') {
      this.setState({
        amount_sell_change: Number(amount),
      })

      if (this.state.price_sell_change) {
        let total = (amount * this.state.price_sell_change).toFixed(7)
        this.setState({
          total_sell_change: Number(total),
        })

        this.props.change('sell_total_counter', Number(total))
      }
    }
  }


  totalChange(value) {
    let total = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        total = total + value[key];
      }
    }

    if (this.state.offer_type === 'Buy') {
      this.setState({
        total_buy_change: Number(total),
      })

      if (this.state.price_buy_change) {
        let amount = (total / this.state.price_buy_change).toFixed(7)
        this.setState({
          amount_buy_change: Number(amount),
        })

        this.props.change('buy_amount', Number(amount))
      }
    }
    if (this.state.offer_type === 'Sell') {
      this.setState({
        total_sell_change: Number(total),
      })

      if (this.state.price_sell_change) {
        let amount = (total / this.state.price_sell_change).toFixed(7)
        this.setState({
          amount_sell_change: Number(amount),
        })

        this.props.change('sell_amount', Number(amount))
      }
    }
  }



	setFormValue(offer, offer_type) {

    const setBuyValues = (price) => {
      this.props.change('buy_amount', Number((offer.amount / price).toFixed(7)))
      this.props.change('buy_price', Number(price))
      this.props.change('buy_total_counter', Number(offer.amount))
    }

    const setSellValues = () => {
      this.props.change('sell_amount', Number(offer.amount))
      this.props.change('sell_price', Number(offer.price))
      this.props.change('sell_total_counter', Number((offer.amount * offer.price).toFixed(7)))
    }

    if (offer_type === 'Buy') {

      let price = Number((offer.price_r.d / offer.price_r.n).toFixed(7))

      this.setState({
        price: Number(price),
        asset_code: offer.buying.asset_code,
        amount_buy_change: Number((offer.amount / price).toFixed(7)),
        price_buy_change: price,
      });

      setBuyValues(price);
    }

    if (offer_type === 'Sell') {

      this.setState({
        price: Number(offer.price),
        asset_code: offer.selling.asset_code,
        amount_sell_change: Number(offer.amount),
        price_sell_change: Number(offer.price),
      })

      setSellValues();
    }
  }


  confirmUpdate = (type, values) => {

    const amount = (type === 'sell' ? values.sell_amount : values.buy_amount)
    const message = `Update order ${type === 'sell' ? `<span class="text-danger">sell</span>` : `<span class="text-success">buy</span>`} <b class="text-info">${numFormat(amount, 7)}</b> <b class="text-warning">${this.state.counter_asset_code}</b> right now?`;
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: "Confirmation!",
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm)
          this.updateOffer(values);
      })
  }



	submitForm = (values) => {

    if (this.state.offer_type === 'Buy') {
      if (!values.buy_price) {
        ToastStore.warning('Enter your price');
      }
      else if (!values.buy_amount) {
        ToastStore.warning('Enter Asset amount');
      }
      else if (!values.buy_total_counter) {
        ToastStore.warning('Not total amount');
      }
      else {

        this.confirmUpdate('buy', values)
      }
    }

    if (this.state.offer_type === 'Sell') {
      if (!values.sell_price) {
        ToastStore.warning('Enter your price');
      }
      else if (!values.sell_amount) {
        ToastStore.warning('Enter Asset amount');
      }
      else if (!values.sell_total_counter) {
        ToastStore.warning('Not total amount');
      }
      else {

        getAuth()
          .then((value) => {
            if (value === 'accept') {
              acceptSendTX();
            }
            else if (value === 'need_deauth_all') {
              needDeauthAll();
            }
          });

        const acceptSendTX = () => {

          this.confirmUpdate('sell', values)
        }
      }
    }
  }




	updateOffer(values) {

    let asset = {};

    if (this.state.offer_type === 'Buy') {
      asset.amount = values.buy_amount;
      asset.price = values.buy_price;
    }
    if (this.state.offer_type === 'Sell') {
      asset.amount = values.sell_amount;
      asset.price = values.sell_price;
    }

    this.setState({
      amount: asset.amount,
      price: asset.price,
    });

    // setTimeout(() => {
      this.setUpdateOfferTx();
    // }, 100)
  }




	setUpdateOfferTx() {

    this.setState({ loader: true, })

    Server.loadAccount(this.props.account_id)
      .then((sourceAccount) => {

        const submitTx = () => {
          return Server.submitTransaction(this.state.transaction);
        }

        if (!this.state.transaction) {

          if (this.state.offer_type === 'Buy') {
            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.manageBuyOffer({
                selling: this.state.asset_selling,
                buying: this.state.asset_buying,
                buyAmount: String(this.state.amount),
                price: String(this.state.price),
                offerId: this.state.offer.id,
            }))
            .setTimeout(100)
            .build();
          }

          if (this.state.offer_type === 'Sell') {
            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: this.state.asset_selling,
                buying: this.state.asset_buying,
                amount: String(this.state.amount),
                price: String(this.state.price),
                offerId: this.state.offer.id,
            }))
            .setTimeout(100)
            .build();
          }


          if (checkConnectProvider(this.props.account_id)) {
            return signConnectProvider(this.state.transaction, this.props.account_id).then(result => {
              this.state.transaction = result
              return submitTx();
            })
          }
          else {
            this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer.sk)));

            const master_weight = sourceAccount.signers.reverse()[0].weight;

            // console.log('master_weight: ', master_weight);
            // console.log('sourceAccount.thresholds.med_threshold: ', sourceAccount.thresholds.med_threshold);

            if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {

              this.setState({
                // transaction: this.state.transaction,
                account: sourceAccount,
                need_add_signers: true,
                btn_name: 'Update Offer',
                btn_icon: 'undo',
                type_tx: 'med',
                type_set_tx: 'update_offer'
              })
            }
            else {
              return submitTx();
            }
          }
        }
        else {
          return submitTx();
        }
      })
      .then((result) => {
        // console.log('result: ', result)

        this.setState({ loader: false, transaction: false })

        swal({
          title: `Offer updated!`,
          text: `Your exchange Offer (${this.state.offer.id}) has been successfully updated.`,
          icon: "success",
          confirm: true,
        })
        .then((confirm) => {
          if (confirm) {
						this.getOffers(this.props.account_id)
            this.toggleModal();
          }
        })
      })
      .catch((error) => {
        console.error('Error: ', error);
        this.setState({ loader: false })

        if (error.response) {
          showErrorMessage(error, 'manage_offer')
        } else {
          ToastStore.error(error.toString());
        }
    });
  }


  checkedOffer = (offer, e) => {

    const { del_offers } = this.state

    if (e.target) {
      const check = e.target.value === 'true' ? false : true

      if (check) {
        this.setState({ del_offers: del_offers.concat(offer) })
      }
      else {
        this.setState({ del_offers: del_offers.filter(item => item.id !== offer.id) })
      }
    }
  }


  checkedAllOffers = (e) => {

    const { offers } = this.state
    const check = e.target ? (e.target.value === 'true' ? false : true) : false

    if (check) {
      this.setState({
        defaultChecked: true,
        del_offers: offers,
        offers: []
      }, () => {
        this.setState({ offers })
      })
    }
    else {
      this.setState({
        defaultChecked: false,
        offers: [],
        del_offers: [],
      }, () => {
        this.setState({ offers })
      })
    }
  }


  removeOffers = (e) => {

	  e.preventDefault()
    const { del_offers } = this.state

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      swal({
        title: 'Confirm',
        text: `Delete offer${del_offers.length > 1 ? 's' : ''} right now?`,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then(confirm => {
        if (confirm) {
          this.confirmRmOffers();
        }
      });
    }
  }


  confirmRmOffers = () => {

	  const { offers, del_offers, fee } = this.state

    const del_operations = del_offers.map(item => {
      // console.log('item: ', item)

      let price, type, selling, buying;

      if (item.selling.asset_type === 'native' || (item.price_r.d / item.price_r.n) === Number(item.amount)) {
        price = Number(item.price_r.d / item.price_r.n);
        type = 'buy';
      }
      else {
        price = item.price;
        type = 'sell';
      }


      if (item.buying.asset_type === 'native') {
        buying = new StellarSdk.Asset.native();
        selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer)
      }
      else if (item.selling.asset_type === 'native') {
        buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer)
        selling = new StellarSdk.Asset.native();
        // price = Number((item.price_r.d / item.price_r.n).toFixed(7));
      }
      else {
        buying = new StellarSdk.Asset(item.buying.asset_code, item.buying.asset_issuer)
        selling = new StellarSdk.Asset(item.selling.asset_code, item.selling.asset_issuer)
      }


      if (type === 'buy') {
        return StellarSdk.Operation.manageBuyOffer({
          selling,
          buying,
          buyAmount: '0',
          price: String(price),
          offerId: item.id
        })
      }

      if (type === 'sell') {
        return StellarSdk.Operation.manageSellOffer({
          selling: selling,
          buying: buying,
          amount: '0',
          price: String(price),
          offerId: item.id
        })
      }
    })

    const account_id = this.props.account_id

    this.setState({ remove_loader: true })

    Server.loadAccount(account_id)
      .then((sourceAccount) => {

        let tx = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee, // StellarSdk.BASE_FEE,
          networkPassphrase: StellarSdk.Networks.PUBLIC,
        });

        del_operations.forEach(op => tx.addOperation(op));
        tx = tx.setTimeout(100).build();

        if (checkConnectProvider(account_id)) {
          return signConnectProvider(tx, account_id).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          tx.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer.sk)));

          return Server.submitTransaction(tx);
        }
      })
      .then((result) => {
        if (result) {

          this.setState({ remove_loader: false })

          swal({
            title: 'Deleted!',
            text: `Offer${del_offers.length > 1 ? 's' : ''} successfully deleted!`,
            icon: "success",
            buttons: {
              confirm: true,
            },
          })
          .then(() => {

            const filter_offers = offers.filter(item => !del_offers.includes(item))

            this.setState({
              offers: [],
              del_offers: [],
              defaultChecked: false,
            }, () => {
              this.setState({ offers: filter_offers }, () => {
                this.getOffers(account_id, 'del')
              })
            })
          })
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);
        this.setState({ remove_loader: false })

        if (error.response) {
          showErrorMessage(error, 'manage_offer')
        } else {
          ToastStore.error(error.toString());
        }
    });
  }


	removeOffer = (offer, offer_type) => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      swal({
        title: 'Confirm',
        text: "Delete offer right now?",
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then(confirm => {
        if (confirm) {
          this.confirmRemove(offer, offer_type);
        }
      });
    }
  }


  confirmRemove(offer, offer_type) {

    this.setState({ remove_loader: true })

    Server.loadAccount(this.props.account_id)
      .then((sourceAccount) => {

          let selling,
              buying,
              price = offer.price;

          if (offer.buying.asset_type === 'native') {
            buying = new StellarSdk.Asset.native();
            selling = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer)
          }
          else if (offer.selling.asset_type === 'native') {
            buying = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer)
            selling = new StellarSdk.Asset.native();
            price = Number((offer.price_r.d / offer.price_r.n).toFixed(7));
          }
          else {
            buying = new StellarSdk.Asset(offer.buying.asset_code, offer.buying.asset_issuer)
            selling = new StellarSdk.Asset(offer.selling.asset_code, offer.selling.asset_issuer)
          }

        var transaction;

        if (this.state.offer_type === 'Buy') {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: this.fee,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
          .addOperation(StellarSdk.Operation.manageBuyOffer({
              selling: selling,
              buying: buying,
              price: price,
              buyAmount: '0',
              offerId: offer.id
          }))
          .setTimeout(100)
          .build();
        }

        if (this.state.offer_type === 'Sell')  {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
            .addOperation(StellarSdk.Operation.manageSellOffer({
                selling: selling,
                buying: buying,
                price: price,
                amount: '0',
                offerId: offer.id
            }))
            .setTimeout(100)
            .build();
        }

        if (checkConnectProvider(this.props.account_id)) {
          return signConnectProvider(transaction, this.props.account_id).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {
          transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer.sk)));
          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {
        if (result) {
          this.setState({ remove_loader: false })
          ToastStore.success('Offer successfully deleted!');

					this.getOffers(this.props.account_id)
          // setTimeout(() => window.location.reload(), 1500)
        }
      })
      .catch((error) => {
        console.error('Something went wrong!', error);
        this.setState({ remove_loader: false })

        if (error.response) {
          showErrorMessage(error, 'manage_offer')
        } else {
          ToastStore.error(error.toString());
        }
    });
  }



	getOffers = (address, type) => {

	  if (!type) this.setState({ offers: [] })

    Server.offers()
      .forAccount(address)
      .limit(200)
      .order('desc')
      .call()
      .then((offers) => {

        if (offers.records.length) {
          this.setState({
            offers: offers.records
          })
        }
      })
      .catch( (err) => {
        console.error(err);
      })
  }


	renderTradeAddress = () => {

    return (
        <div>
          <p>{ iconIdent(this.props.account_id, 'icon-indent-sm') } <Link to={this.props.account_id}><b>{shortAddr(this.props.account_id, 8)}</b> <small className="text-secondary"> / {this.state.signer ? this.state.signer.title : ''}</small></Link></p>

          {
            this.state.account ?
              <div>
                <h5 className="subhead">Balances: <b className="text-info">{numFormat(this.state.native_balance, 7)} <font className="text-warning">XLM</font></b> &nbsp; / &nbsp;
                  <b className="text-info">{numFormat(this.state.counter_balance, 7)} <font className="text-warning">{this.state.counter_asset_code}</font></b>
                  <span className="float-right">Reserve: <b className="text-info">{this.state.reserve_balance} <font className="text-warning">XLM</font></b></span>
                </h5>
                <br/>
              </div>
            :
              <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div>
          }
        </div>
    )
  }




	renderItem = (item, index) => {
		// console.log('item: ', item)

		let price, price_reverse, text_color, type, asset_code;

		if (item.selling.asset_type === 'native' || (item.price_r.d / item.price_r.n) === Number(item.amount)) {
			price = Number(item.price_r.d / item.price_r.n);
			price_reverse = item.price;
			asset_code = item.buying.asset_code;
			text_color = 'text-success';
			type = 'Buy';
		}
		else {
			price = item.price;
			price_reverse = Number(item.price_r.d / item.price_r.n);
			asset_code = item.selling.asset_code;
			text_color = 'text-danger';
			type = 'Sell';
		}

		let settings = false;
		if (this.state.wallets) {
			this.state.wallets.forEach((item2, index) => {
				if (item.seller === item2.pk) {
					settings = true;
					return
				}
			})
		}

		return (
			<tr key={index}>
				<td><small>{index+1}</small></td>
				<td>{item.base_is_seller} { <div>{shortAddress(item.seller)} <font className='text-danger'>sells</font> <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item.selling)} / <font className='text-success'>buys</font> <b className="text-info">{numFormat((item.amount * item.price), 7)}</b> {getAsset(item.buying)}</div> }</td>
				<td><b className={text_color}>{numFormat(price, 7)} <small>/ {numFormat(price_reverse, 7)}</small></b></td>
				<td className={text_color}>{type}</td>
				<td>{formatDate(item.last_modified_time)}</td>
				<td><Link to={`/offer/${item.id}`}><b>{item.id}</b></Link></td>
				{ settings ? <td><a href="#" onClick={(e) => this.editOffer(e, item, type, 'edit')}><i className="fa fa-pencil-square-o"></i></a></td> : null }
				{/*{ settings ? <td><a href="#" onClick={(e) => this.editOffer(e, item, type, 'remove')}><i className="fa fa-close"></i></a></td> : null}*/}
        <td>
          <div className="form__form-group form__form-group-field">
            <Field
              name={`offer-${index}`}
              component={renderCheckBoxField}
              className="colored-click"
              defaultChecked={this.state.defaultChecked}
              onChange={(e) => this.checkedOffer(item, e)}
            />
          </div>
        </td>
			</tr>
		);
	}


  manageDraggable = (type) => {

    if (type === 'header') { this.setState({ disable_draggable: false }) }
    if (type === 'body') { this.setState({ disable_draggable: true }) }
    // if (type === 'body-up') { this.setState({ disable_draggable: false }) }
  }



	renderEditModal() {

    const { reset, handleSubmit } = this.props;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'modal-dark'
    }

    return (
      <Draggable disabled={this.state.disable_draggable}>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={`modal-dialog--${this.state.offer_type === 'Buy' ? 'success' : 'warning'} modal-dialog--header`}
        >
          <div className={`modal-content ${classElem}`}>
            <div className="modal__header" onMouseDown={() => this.manageDraggable('header')}>
              <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
              <h4 className="bold-text  modal__title">{this.state.offer_type} <b>{this.state.counter_asset_code}</b></h4>
            </div>
            <div className={`modal__body`}
                 onMouseDown={() => this.manageDraggable('body')}>

              <div className="card__title" style={{marginBottom: 18}}>
                <h5 className="subhead">
                  ASK: <b className="text-success">{numFormat(this.state.ask_price, 7)} <span className="text-warning">{this.state.base_asset_code}</span></b> &nbsp; / &nbsp;
                  BID: <b className="text-danger">{numFormat(this.state.bid_price, 7)} <span className="text-warning">{this.state.base_asset_code}</span></b>
                  {/*SPREAD: <b className="text-secondary">{numFormat((this.state.bid_price - this.state.ask_price), 7)} <span className="text-warning">{this.state.base_asset_code}</span></b>*/}
                </h5>
              </div>

              <div style={{position: 'relative'}}>
                { this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

                {
                  this.state.offer_type === 'Buy' ?
                    <div>
                      <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                        { this.renderTradeAddress() }

                        <div>
                          <Field
                            name="buy_price"
                            component={renderTextField}
                            label={`Price ${this.state.counter_asset_code}/${this.state.base_asset_code}`}
                            className="m-form"
                            onChange={this.priceChange.bind(this)}
                          />
                        </div>

                        <div>
                          <Field
                            name="buy_amount"
                            component={renderTextField}
                            label={`Amount buy ${this.state.counter_asset_code}`}
                            onChange={this.amountChange.bind(this)}
                          />
                        </div>


                        <div>
                          <Field
                            name="buy_total_counter"
                            component={renderTextField}
                            label={`Total sell ${this.state.base_asset_code}`}
                            onChange={this.totalChange.bind(this)}
                          />
                        </div>

                        <ButtonToolbar className="form__button-toolbar float-right">
                          <Button type="button" onClick={reset}>Reset</Button>
                          <Button color="success" type="submit">buy <b>{numFormat(this.state.amount_buy_change, 7)} {this.state.counter_asset_code}</b></Button>
                        </ButtonToolbar>
                      </form>
                    </div>
                    : null
                }


                {
                  this.state.offer_type === 'Sell' ?
                    <div>
                      <form className="material-form" onSubmit={handleSubmit(this.submitForm)}>

                        { this.renderTradeAddress() }

                        <div>
                          <Field
                            name="sell_price"
                            component={renderTextField}
                            label={`Price ${this.state.counter_asset_code}/${this.state.base_asset_code}`}
                            className="m-form"
                            onChange={this.priceChange.bind(this)}
                          />
                        </div>

                        <div>
                          <Field
                            name="sell_amount"
                            component={renderTextField}
                            label={`Amount sell ${this.state.counter_asset_code}`}
                            onChange={this.amountChange.bind(this)}
                          />
                        </div>

                        <div>
                          <Field
                            name="sell_total_counter"
                            component={renderTextField}
                            label={`Total buy ${this.state.base_asset_code}`}
                            onChange={this.totalChange.bind(this)}
                          />
                        </div>

                        <ButtonToolbar className="form__button-toolbar float-right">
                          <Button type="button" onClick={reset}>Reset</Button>
                          <Button color="warning" type="submit">Update sell <b>{numFormat(this.state.amount_sell_change, 7)} {this.state.counter_asset_code}</b></Button>
                        </ButtonToolbar>
                      </form>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </Modal>
      </Draggable>
    )
  }




	render() {
    // console.log('Offers state: ', this.state)

    const { del_offers, offers } = this.state

		return(
			<div>

				<ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

				{
					this.state.need_add_signers && this.state.transaction ?
						<RequiredSigners account={this.state.account}
														 add_signers={this.addSigners}
														 transaction={this.state.transaction}
														 btn_name={this.state.btn_name}
														 icon={this.state.btn_icon}
														 type_tx={this.state.type_tx} />
					: null
				}

				{ this.renderEditModal() }

				{ this.state.remove_loader ? <div className="panel__refresh" style={{height: '94%'}}><LoadingIcon /></div> : null }

        <form className={'wizard__form material-form'}>
          <Table striped responsive>
            <thead>
            <tr>
              <th>#</th>
              <th>Details</th>
              <th>Price</th>
              <th>Type</th>
              <th>Date</th>
              <th>Offer</th>
              { this.state.my_address ? <th><i className="fa fa-pencil-square-o"></i></th> : null }
              {/*{ this.state.my_address ? <th><i className="fa fa-trash-o"></i></th> : null }*/}
              {
                this.state.my_address ?
                  <th>
                    <div className="form__form-group form__form-group-field"
                         style={{marginBottom: del_offers.length ? 0 : '10px' }}>
                      <Field
                        name={`all_checked`}
                        component={renderCheckBoxField}
                        className="colored-click"
                        defaultChecked={this.state.defaultChecked}
                        onChange={this.checkedAllOffers}
                      />
                      {
                        del_offers.length ?
                          <a href="#"
                             // style={{marginLeft: 30}}
                             onClick={this.removeOffers}
                          ><i className="fa fa-trash-o fs-18 text-danger"></i></a>
                          : null
                      }
                    </div>
                  </th>
                : null
              }
            </tr>
            </thead>
            <tbody>

            {
              offers.length ?
                offers.map(this.renderItem)
                :
                <div className="panel__refresh"><LoadingIcon /></div>
            }

            </tbody>
          </Table>
        </form>
			</div>
		)
	}
}

Offers.propTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Offers));
