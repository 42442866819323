import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, ButtonGroup } from 'reactstrap';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getTitle } from '../../../modules/index';
import Footer from '../../Layout/footer/Footer';
import AboutSKY from './SKY';



class Info extends PureComponent {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    getTitle(`Information`);
  }


  render() {

    const route_id = this.props.match.params.id;

    switch (route_id) {
      case 'sky':
        return <AboutSKY />;
      case 'any':
        return (<div></div>);
      default:
        return null;
    }
  }
}


export default translate('common')(Info);
