import React, { PureComponent } from 'react';
import {Col, Container, Row, Card, CardBody, Button, ButtonGroup, ButtonToolbar, Modal} from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Server } from '../../../../modules/stellar/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import {Field, reduxForm} from "redux-form";
import MenuItem from "@material-ui/core/MenuItem";
import {
  checkConnectProvider,
  get_loc_wallets,
  getAuth, getIconConnectProvider, getStellarFee,
  needDeauthAll,
  numFormat,
  shortAddr, showErrorMessage,
  signConnectProvider
} from "../../../../modules";
import {ToastContainer, ToastStore} from "react-toasts";
import StellarSdk from "stellar-sdk";
import swal from "sweetalert";
import RequiredSigners from "../../Auth/components/RequiredSigners";
import TextField from "@material-ui/core/TextField";
import Draggable from 'react-draggable';
import {parseStellarUri, TransactionStellarUri} from '@stellarguard/stellar-uri';
import {QRCode} from "react-qrcode-logo";
import iconIdent from "../../Account/components/iconIdent";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getTomlInfo} from "../../../../endpoints/API";



const android_icon = `${process.env.PUBLIC_URL}/img/icons/google-store-logo.svg`;
const appstore_icon = `${process.env.PUBLIC_URL}/img/icons/app-store-logo.svg`;

const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class AcceptAsset extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      wallets: this.props.wallets,
      modal: true,
      loader: false,
      trust: false,
      transaction: false,
      address: false,
      need_add_signers: false,
      signer: false,
      uri: false,
      xdr: false,
    };
  }

  fee = getStellarFee()

  componentDidMount = () => {

    this.createURI()
  }



  createURI = () => {

    if (!this.state.uri) {

      getTomlInfo().then(result => {
        if (result.data) {
          const public_key = result.data.public;
          const secret_key = window.atob(result.data.secret);
          const account = new StellarSdk.Account(public_key, '99638409428992014');

          const transaction = new StellarSdk.TransactionBuilder(account, {
            fee: this.fee,
            networkPassphrase: StellarSdk.Networks.PUBLIC
          })
            .addOperation(StellarSdk.Operation.changeTrust({
              asset: new StellarSdk.Asset(this.props.asset.asset_code, this.props.asset.asset_issuer),
            }))
            .setTimeout(30)
            .build();

          // transaction.sign(StellarSdk.Keypair.fromSecret(secret_key));

          const xdr = transaction.toEnvelope().toXDR('base64')
          const txFromXDR = new StellarSdk.Transaction(xdr, StellarSdk.Networks.PUBLIC);
          const uri = TransactionStellarUri.forTransaction(transaction);
          uri.originDomain = 'scopuly.com';
          uri.addSignature(StellarSdk.Keypair.fromSecret(secret_key));

          this.setState({
            uri: uri.toString(),
            xdr,
          })
        }
      })
    }
  }



  toggleModal = () => {
    this.setState({ modal: !this.state.modal })
  }


  submitForm = (values) => {

    if (Object.keys(values).length === 0) {
      ToastStore.warning(`Account not selected`);
    }
    else if (this.props.account && !this.state.trust) {
      this.setTrust()
    }
  }


  setTrust = () => {

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          setTrustTx()
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });


    const setTrustTx = () => {

      this.setState({ loader: true })

      Server.loadAccount(this.state.address)
        .then((sourceAccount) => {

          const submitTx = (num) => {
            return Server.submitTransaction(this.state.transaction);
          }

          if (!this.state.transaction) {

            this.state.transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: this.fee,
              networkPassphrase: StellarSdk.Networks.PUBLIC
            })
              .addOperation(StellarSdk.Operation.changeTrust({
                asset: new StellarSdk.Asset(this.props.asset.asset_code, this.props.asset.asset_issuer),
              }))
              .setTimeout(100)
              .build();

            if (checkConnectProvider(this.state.address)) {
              return signConnectProvider(this.state.transaction, this.state.address).then(result => {
                this.state.transaction = result
                return submitTx();
              })
            }
            else {

              this.state.transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));

              const master_weight = sourceAccount.signers.reverse()[0].weight;

              if (sourceAccount.signers.length > 0 && sourceAccount.thresholds.med_threshold > master_weight) {
                // alert(sourceAccount.thresholds.med_threshold)

                this.setState({
                  // transaction: this.state.transaction,
                  account: sourceAccount,
                  need_add_signers: true,
                  btn_name: 'Set Trustline',
                  btn_icon: 'cube',
                  type_tx: 'med',
                  type_set_tx: 'trust'
                })

                return false;
              }
              else {
                return submitTx();
              }
            }
          }
          else {
            return submitTx();
          }
        })
        .then((result) => {
          if (result) {
            // console.log('result: ', result)

            this.setState({ loader: false, transaction: false })

            const message = `Trustline to <b>${this.props.asset.asset_code}</b> token has been successfully added. Now <b>${this.props.asset.asset_code}</b> token is available on your balance.`
            const element = document.createElement("span");
            element.innerHTML = message;

            swal({
              title: `${this.props.asset.asset_code} Accepted!`,
              content: element,
              icon: "success",
            })
          }
        })
        .catch((error) => {
          console.error('Something went wrong!', error);

          this.setState({ loader: false })
          showErrorMessage(error, 'change_trust')
        });
    }
  }


  getSigner = (address) => {
    const wallets = this.state.wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({
        signer: signer,
      })
    }
  }


  selectAccount = (value) => {

    let address = '';
    for (const key of Object.keys(value)) {
      if (!isNaN(key)) {
        address = address + value[key];
      }
    }

    this.setState({
      address,
      account: false,
      trust: false
    })

    this.checkAccount(address);
    this.getSigner(address);
  }


  checkAccount = (address) => {

    ToastStore.info("Checking Account...");
    this.setState({ loader: true, });

    // Check Trust
    Server.loadAccount(address)
      .then((account) => {

        this.createURI(account)

        this.setState({ loader: false, });

        let trust = account.balances.filter((balance) => {
          return balance.asset_code === this.props.asset.asset_code &&
            balance.asset_issuer === this.props.asset.asset_issuer;
        });

        this.setState({
          trust:(trust.length ? trust  : false),
          account
        })

        if (trust.length) {
          ToastStore.success(`${this.props.asset.asset_code} asset is already on your balance`);
        }
        else {

          const message = `Add <b class="text-warning">${this.props.asset.asset_code}</b> to your balance right now?`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: "Add Trustline",
            content: element,
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
            .then((confirm) => {
              if (confirm)
                this.setTrust()
            })
        }
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setState({ loader: false, });
      });
  }


  addSigners(value) {
    this.setState({
      transaction: value,
      need_add_signers: false,
    })
  }


  componentDidUpdate(prevProps) {
    // console.log('componentDidUpdate prevProps: ', prevProps)

    this.props.toggleAcceptModal(this.state.modal)
  }



  render() {

    const {reset, handleSubmit } = this.props;

    let classElem = '';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = 'modal-dark'
    }

    return (
      <Draggable disabled={false}>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={`modal-dialog--primary modal-dialog--header`}
        >

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          {
            this.state.need_add_signers && this.state.transaction ?
              <RequiredSigners account={this.state.account}
                               add_signers={this.addSigners}
                               transaction={this.state.transaction}
                               btn_name={this.state.btn_name}
                               icon={this.state.btn_icon}
                               type_tx={this.state.type_tx} />
              : null
          }

          <div className={`modal-content ${classElem}`}>
            <div className="modal__header">
              <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
              <h4 className="bold-text  modal__title">Trustline for {this.props.asset.image && <img src={this.props.asset.image} className={'accept-token-logo'} alt={this.props.asset.asset_code} />} <b>{this.props.asset ? this.props.asset.asset_code : null}</b>&nbsp;&nbsp;|&nbsp;&nbsp;
                <small className="">
                  <small className=""><b>{this.props.asset.name !== 'undefined' ? this.props.asset.name : null}</b></small>&nbsp;&nbsp;|&nbsp;&nbsp;
                  <small className="">{this.props.asset.home_domain}</small>
                </small></h4>
            </div>
            <div className={`modal__body`}>

              <div>
                {this.state.loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : ''}
                <div>Select Account to add Trustline</div>

                <form className="material-form"
                      onSubmit={handleSubmit(this.submitForm)}
                  // onClick={this.checkAuthAddr.bind(this)}
                >
                  <div>
                    <Field
                      name="sender_addr"
                      component={renderTextField}
                      select
                      label="Select Account"
                      onChange={(value) => {
                        this.selectAccount(value)
                      }}
                    >
                      {
                        this.state.wallets && this.state.wallets.map((item, index) => {
                          // if (item.balance) {
                          return (
                            <MenuItem
                              key={index}
                              className={`material-form__option ${item && item.provider ? 'connect-provider' : ''}`}
                              value={item.pk}>
                              <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 8)}</b> <small>&nbsp;/ {item.title} / {item.balance} XLM</small> { getIconConnectProvider(item) }
                            </MenuItem>
                            // (<b>{item.balance}</b>/<b>{item.reserve}</b>/<b className="text-success">{item.avaliable} XLM</b>)
                          )
                          // }
                        })
                      }

                    </Field>

                    {
                      this.state.account && this.state.trust ?
                        <h5 className="subhead">Balance: <b className="text-info">{this.state.trust.length ? numFormat(this.state.trust[0].balance, 7) : '...'}</b> <b className="text-warning">{this.props.asset.asset_code}</b>
                          <br/><br/><span className="text-success">The <b>{this.props.asset.asset_code}</b> asset is allowed</span></h5>
                        : <h5 className="subhead">Select the Account to which you want to add the <b>{this.props.asset.asset_code}</b> token.</h5>
                    }
                  </div>

                  <br/>

                  <div>
                    <div className="text-center">
                      {/*<Button type="button" onClick={reset}>Reset</Button>*/}
                      <Button color="success" type="submit"
                      >Accept <b>{this.props.asset.asset_code}</b></Button>
                      {
                        (window.location.pathname.indexOf('trade') - 1) ?
                          <Link
                            to={`/trade/${this.props.asset.asset_code}-XLM/${this.props.asset.asset_issuer}/native`}
                            className={'btn btn-secondary'}>Trade <b>{this.props.asset.asset_code}</b></Link>
                          : null
                      }
                    </div>
                  </div>

                </form>
              </div>

              <div className={'text-center text-secondary'}>
                <br/>
                <div>Scan QR in mobile app <small>(<a href="https://github.com/stellar/stellar-protocol/blob/master/ecosystem/sep-0007.md"
                                                     target={'_blank'}>SEP-0007</a> Support)</small>
                </div>
                <br/>
                <Row>
                  <Col md={7}>
                    {/*<QRCode*/}
                    {/*  value={this.state.uri}*/}
                    {/*  size="230"*/}
                    {/*  quietZone="10"*/}
                    {/*  fgColor="#217fe4"*/}
                    {/*  qrStyle="dots"*/}
                    {/*  logoOpacity="0.8"*/}
                    {/*  logoImage={this.props.asset.image}*/}
                    {/*  logoWidth="30"*/}
                    {/*  logoHeight="30"*/}
                    {/*  style={{width: '100%'}}*/}
                    {/*/>*/}

                    <QRCode
                      value={this.state.uri}
                      size="230"
                      quietZone="10"
                      // fgColor={f7.colors[color]}
                      qrStyle="dots"
                      // logoOpacity="0.8"
                      logoImage={this.props.asset.image}
                      removeQrCodeBehindLogo={true}
                      logoPadding={2.5}
                      logoWidth="20" // 46
                      logoHeight="20" // 46
                      ecLevel="M"
                      eyeRadius={[
                        { // top/left eye
                          outer: [10, 10, 0, 10],
                          inner: [1, 1, 0, 1],
                        },
                        { // top/right eye
                          outer: [10, 10, 10, 0],
                          inner: [1, 1, 1, 0],
                        },
                        { // bottom/left
                          outer: [10, 0, 10, 10],
                          inner: [1, 0, 1, 1],
                        },
                      ]}
                      eyeColor={[
                        { // top/left eye
                          outer: '#217fe4',
                          inner: 'black',
                        },
                        { // top/right eye
                          outer: '#217fe4',
                          inner: 'black',
                        },
                        { // bottom/left eye
                          outer: '#217fe4',
                          inner: 'black',
                        },
                      ]}
                    />

                    <div style={{marginTop: '4px'}}>
                      <small>
                        <CopyToClipboard text={window.location.origin+`/trustline/${this.props.asset.asset_code}-${this.props.asset.asset_issuer}`}
                                         onCopy={() => ToastStore.success(`Copied!`)}>
                          <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> Link</Button>
                        </CopyToClipboard>
                        <CopyToClipboard text={this.state.uri} onCopy={() => ToastStore.success(`Copied!`)}>
                          <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> web+stellar</Button>
                        </CopyToClipboard>
                        <CopyToClipboard text={this.state.xdr} onCopy={() => ToastStore.success(`Copied!`)}>
                          <Button className={'btn btn-outline-secondary btn-xss'}><i className={'fa fa-clone'}></i> XDR</Button>
                        </CopyToClipboard>
                      </small>
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className={'text-center'} style={{padding: '31px 35px 5px'}}>
                      <p>Scan this QR code on <b>Scopuly</b> mobile app</p>
                      <br/>
                      <div>
                        <span className="icon-apps" style={{width: 100}}><a href="https://play.google.com/store/apps/details?id=com.sdex.app"
                                                       className="text-secondary"
                                                       alt='Android | Scopuly | Stellar Wallet'
                                                       target='_blank'
                                                       rel='noopener noreferrer'><img src={android_icon}/></a></span><br/>

                        <span className="icon-apps" style={{width: 100}}><a href="https://itunes.apple.com/us/app/id1383402218"
                                                       className="text-secondary"
                                                       alt='iPhone | Scopuly | Stellar Wallet'
                                                       target='_blank'
                                                       rel='noopener noreferrer'><img src={appstore_icon}/></a></span><br/>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Modal>
      </Draggable>
    );
  }
}



// export default translate('common')(AcceptAsset);

export default reduxForm({
  form: 'accept_asset',
})(translate('common')(AcceptAsset));
