import React, { PureComponent } from 'react';
import {Container, Col, Row, Card, CardBody, Table, Button, Badge} from "reactstrap";
import LoadingIcon from "mdi-react/LoadingIcon";
import { Link } from 'react-router-dom';
import SpinnerLoader from "../../../../modules/SpinnerLoader";
import Footer from "../../../Layout/footer/Footer";
import {
  formatDate,
  getAsset,
  getBasePrice,
  getTitle,
  numFormat, animateElems,
  shortAddress,
  shortTransaction
} from "../../../../modules";
import axios from "axios";
import {GLOBE} from "../../../../modules/globeVars";
import StellarSdk from "stellar-sdk";
import {ToastStore} from "react-toasts";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {getTopPartners} from "../../../../endpoints/API";


class TopPartners extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      asset: false,
      all_amount: 0,
      all_partners: 0,
      all_referrals: 0,
      usd_amount: false,
      load_more: true,
    };
  }

  componentDidMount() {
    this.getXLMPrice()

    animateElems()
  }

  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
          stellar,
          base_price: stellar.market_data.current_price.usd
        },
        () => {
          this.getPartners()
        })
    }
  }


  getPartners = () => {

    const { base_price, } = this.state

    getTopPartners(0, 30).then(result => {

      if (result.data) {

        this.setState({
          partners: result.data.partners,
          asset: result.data.asset,
          all_amount: result.data.all_amount,
          all_partners: result.data.all_partners,
          all_referrals: result.data.all_referrals,
          usd_amount: ((result.data.all_amount * result.data.asset.price) * base_price)
        })
      }
    })
  }


  getPayouts = () => {

  }


  renderPartners = (item, index) => {

    const { asset, base_price, } = this.state

    const amount_usd = ((item.amount * asset.price) * base_price)
    const link = `${window.location.origin}/ref/${item.code}`
    const position = index+1;

    return (
      <tr key={index}>
        <td>{position < 5 ? <b>{position}</b> : position}</td>
        <td>{shortAddress(item.address)}</td>
        <td>
          <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(asset, asset.image, 'warning')} {' '}
          <small className={'text-secondary'}> / ${numFormat(amount_usd,2)}</small>
        </td>
        <td><b className={'text-info'}>{item.referrals}</b></td>
        <td>
          <b className={'text-secondary'}><Link to={`/ref/${item.code}`}>{item.code}</Link></b> &nbsp;
          <CopyToClipboard text={link} onCopy={() => {}}>
            <i className="fa fa-clone"
               color="secondary"
               style={{cursor: 'pointer', fontSize: 14}}
               onClick={() => ToastStore.success('Copied!')}></i>
          </CopyToClipboard>
        </td>
        <td><b className={'text-secondary'}>{item.name}</b></td>
        <td className={''}>
          {
            index+1 === 1 ?
              <b className={'text-secondary'}><span className={`text-success`}>+30%</span> VOL</b>
              : index+1 === 2 ?
              <b className={'text-secondary'}><span className={`text-success`}>+27.5%</span> VOL</b>
              : index+1 === 3 ?
                <b className={'text-secondary'}><span className={`text-success`}>+25%</span> VOL</b>
                : index+1 === 4 ?
                  <b className={'text-secondary'}><span className={`text-success`}>+22.5%</span> VOL</b>
                  :
                  <b className={'text-secondary'}><span className={`text-info`}>+20%</span> VOL</b>
          }
        </td>
        <td>{formatDate(item.date)}</td>
      </tr>
    )
  }


  render() {

    const { partners, asset, load_more, all_amount, all_partners, all_referrals, usd_amount } = this.state
    const restart = true

    // console.log('state: ', this.state)


    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title">Referral Partners</h3>
          </Col>
        </Row>

        {
          !restart ?
            <Card>
              <CardBody className={'text-center'}>
                <h4>The referral program is temporarily suspended for important changes.</h4>
                <p>We will announce the relaunch soon.</p>
              </CardBody>
            </Card>
          :
            <div>
              <Card className={'element-animation'}>
                <CardBody
                  style={{background: 'linear-gradient(80deg, rgb(107 0 255), rgb(113 202 255))'}}
                >
                  <br/>
                  <Row>
                    <Col md={{ size: 5, offset: 1 }}>
                      <br/>
                      <br/>
                      <br/>
                      <h1 className={'text-white'}><b>Increased payouts for the most active Scopuly partners</b> 🔥</h1>
                      <br/>
                      <h4 className={'text-white'}>For the most active partners from the <b className={'text-warning'}>TOP-4</b>, there is an additional motivation (up to <b className={'text-success'}>+30%</b>) to share their referral link with people</h4>
                      <br/>
                      <div className={'text-white'}>

                        <ul>
                          <li>TOP <b>1</b>: <b className={'text-success'}>+10%</b> <b>VOL</b> <small>/ total: <b className={'text-success'}>+30%</b> </small></li>
                          <li>TOP <b>2</b>: <b className={'text-success'}>+7.5%</b> <b>VOL</b> <small>/ total: <b className={'text-success'}>+27.5%</b> </small></li>
                          <li>TOP <b>3</b>: <b className={'text-success'}>+5%</b> <b>VOL</b> <small>/ total: <b className={'text-success'}>+25%</b> </small></li>
                          <li>TOP <b>4</b>: <b className={'text-success'}>+2.5%</b> <b>VOL</b> <small>/ total: <b className={'text-success'}>+22.5%</b> </small></li>
                        </ul>

                      </div>
                      <br/><br/>

                      <Link to="/referral" className={'btn btn-lg btn-success'}><b>Create referral link</b></Link>

                    </Col>

                    <Col md={{ size: 5}}>
                      <br/><br/>
                      <img src="/img/referral/invite.png" alt=""/>
                      <br/><br/>
                    </Col>
                  </Row>
                  <br/><br/>
                </CardBody>
              </Card>


              <Row>
                <Col md={3}>
                  <Card>
                    <CardBody>

                      <p className={'uppercase'}><b>TOP 1: <Badge color={'success'} className={'fs-16'}><b>+30%</b></Badge> VOL</b></p>
                      <img src="/img/referral/level-4.png" alt=""/>
                      <br/><br/>
                      <p>Invite more friends and get an additional <b className={'text-success'}>+10%</b> being in the <b className={'text-info'}>TOP-1</b> list of partners</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <p className={'uppercase'}><b>TOP 2: <Badge color={'success'} className={'fs-16'}><b>+27.5%</b></Badge> VOL</b></p>
                      <img src="/img/referral/level-3.png" alt=""/>
                      <br/><br/>
                      <p>Invite more friends and get an additional <b className={'text-success'}>+7.5%</b> being in the <b className={'text-info'}>TOP-2</b> list of partners</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <p className={'uppercase'}><b>TOP 3: <Badge color={'success'} className={'fs-16'}><b>+25%</b></Badge> VOL</b></p>
                      <img src="/img/referral/level-2.png" alt=""/>
                      <br/><br/>
                      <p>Invite more friends and get an additional <b className={'text-success'}>+5%</b> being in the <b className={'text-info'}>TOP-3</b> list of partners</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <p className={'uppercase'}><b>TOP 4: <Badge color={'success'} className={'fs-16'}><b>+22.5%</b></Badge> VOL</b></p>
                      <img src="/img/referral/level-1.png" alt=""/>
                      <br/><br/>
                      <p>Invite more friends and get an additional <b className={'text-success'}>+2.5%</b> being in the <b className={'text-info'}>TOP-4</b> list of partners</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>


              <Card>
                <CardBody>
                  <h4>
                    <b>Top partners</b>
                    <span className={'text-right'}>
                <Link to={'/referral'} className={'btn btn-secondary'} style={{float: 'right'}} >All Payouts</Link>
              </span>
                  </h4>
                  <p>All Partnes: <b className={'text-info'}>{numFormat(all_partners)}</b> <span className={'text-secondary'}>&nbsp;|&nbsp;</span> All Referrals: <b className={'text-info'}>{numFormat(all_referrals)}</b> <span className={'text-secondary'}>&nbsp;|&nbsp;</span> VOL: <b className={'text-info'}>{numFormat(all_amount)}</b> { asset ? getAsset(asset, asset.image, 'secondary') : null } <small>/ ${numFormat(usd_amount)}</small></p>
                  <br/>

                  { !partners.length ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                  <br/>

                  <Table striped responsive hover>
                    <thead>
                    <tr>
                      <th>Top</th>
                      <th>Partner</th>
                      <th>VOL</th>
                      <th>Referrals</th>
                      <th>Link</th>
                      <th>Name</th>
                      <th>Rate</th>
                      <th>Created</th>
                    </tr>
                    </thead>
                    <tbody>

                    {
                      partners.length ?
                        partners.map(this.renderPartners)
                        : null
                    }

                    </tbody>
                  </Table>

                  <div className="text-center">
                    <br/>
                    {
                      partners.length ?
                        <Button color="secondary" onClick={this.getPayouts}>
                          {
                            load_more ? 'Load more...' :
                              <SpinnerLoader />
                          }
                        </Button> : null
                    }
                  </div>
                </CardBody>
              </Card>
            </div>
        }

        <Footer />

      </Container>
    );
  }
}



export default (TopPartners);
