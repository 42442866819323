/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col, Row, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { translate } from 'react-i18next';
import { numFormat } from '../../../../modules/numFormat/index';
// import getPrice from '../../../App/GetPrice';


class StellarCap extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      // price: 0,
      cap: 0,
      volume: 0,
      change: 0,
      changeColor: 'badge-light',
      transactions: null,
      loadTx: false,
      operations: null,
      loadOp: false,
      loadChart: false,
      // data: [],
      // intervalIdOp: 0,
      // intervalIdTx: 0,
    };
  }

  componentDidMount() {
    // this.getBasePrice();
  }

  // getBasePrice() {
  //   let price = JSON.parse(localStorage.getItem('price'));
  //
  //   if (price) {
  //     this.setState({
  //       price: price.crypto_price.xlm_usd,
  //       cap: price.crypto_price.usd.market_cap,
  //       volume: price.crypto_price.usd.volume_24h,
  //       change: price.crypto_price.usd.percent_change_24h,
  //       changeColor: Number(price.crypto_price.usd.percent_change_24h) < 0 ? 'danger' : 'success'
  //     });
  //   }
  //   else {
  //     getPrice()
  //     setTimeout(() => {
  //       this.getBasePrice()
  //     }, 1000)
  //   }
  // }

  // componentWillMount() {
  //   axios.get(`https://api.coinmarketcap.com/v1/ticker/stellar/?convert=USD`)
  //   .then(res => {
  //     const data = res.data[0];
  //     this.setState({
  //       price: data.price_usd,
  //       cap: data.market_cap_usd,
  //       volume: data['24h_volume_usd'],
  //       change: data.percent_change_24h,
  //       changeColor: Number(data.percent_change_24h) < 0 ? 'danger' : 'success'
  //     });
  //   })
  // }


  render() {

    return (
      <Row>
        <Col md={4} xl={4} lg={4} xs={12}>
          <Card>
            <CardBody className="dashboard__card-widget text-center table">
              <h5 className="bold-text">Price <Link to={'/native'} className="text-warning">XLM</Link>&nbsp;
                <span className="text-info">{numFormat(this.props.price, 4)}</span>&nbsp;
                {/*<Link to={'/native'} className="text-warning">XLM</Link> /&nbsp;*/}
                <font className="text-warning">USD</font>&nbsp;&nbsp;
                {/*<span className={ "badge " + this.state.changeColor } > {this.state.change > 0 ? '+' : ''} {this.state.change}%</span>*/}

                <Badge className="badge-round" color={this.props.changeColor}>{this.props.change > 0 ? '+' : ''}{numFormat(this.props.change, 2)}%</Badge>

              </h5>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} xl={4} lg={4} xs={12}>
          <Card>
            <CardBody className="dashboard__card-widget text-center">
              <h5 className="bold-text">Market Cap <Link to={'/native'} className="text-warning">XLM</Link> <span className="text-info"> {numFormat(this.props.cap, 0)}</span> <font className="text-warning">USD</font></h5>
            </CardBody>
          </Card>
        </Col>
        <Col md={4} xl={4} lg={4} xs={12}>
          <Card>
            <CardBody className="dashboard__card-widget text-center">
              <h5 className="bold-text">Vol24 <Link to={'/native'} className="text-warning">XLM</Link> <span className="text-info"> {numFormat(this.props.volume, 0)}</span> <font className="text-warning">USD</font></h5>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default translate('common')(StellarCap);
