import React, { PureComponent } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Col, Container, Row, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Button, UncontrolledCarousel } from 'reactstrap';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import axios from 'axios';
import { GLOBE } from '../../../modules/globeVars/index';
import { numFormat, getTitle } from '../../../modules/index';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { ToastContainer, ToastStore } from 'react-toasts';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import moment from 'moment';


const items_slider = [
  {
    src: `${process.env.PUBLIC_URL}/img/IDO/banners-new1.png`,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/IDO/banners-new2.png`,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/IDO/banners-new3.png`,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/IDO/banners-new4.png`,
  },
  {
    src: `${process.env.PUBLIC_URL}/img/IDO/banners-new5.png`,
  }
];


const tags = [
  { tag: 'current', title: 'current' },
  { tag: 'future', title: 'future' },
  { tag: 'ended', title: 'ended' },
];


// let render_count = 0;



class Project extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      items: false,
      currentTag: 'all',
      tags: tags,
      redirect: false,
      projects: false,
      auth_user: localStorage.getItem('auth_user'),
    };
  }

  componentDidMount() {
    const title = 'Scopuly Launchpad - launch and participation in crowdfunding and IEO projects';
    const description = 'Investing in crowdfunding and IEO projects. Starting a crowdfunding company / IEO without listing fees. Project tokens will immediately appear on the market without listing fees.';

    getTitle(title, description);

    this.getProjects();
  }


  getProjects() {

    axios.post(GLOBE.API_URL+`/get_ieos`)
      .then(response => {
        // console.log('response: ', response)

        if (response.data !== 'no-projects') {
          this.setState({
            projects: response.data,
            items: response.data,
          })
        }
    });
  }


  onFilter = (tag, e) => {
    e.preventDefault();
    // render_count = 0;

    // console.log('tag: ', tag)

    if (tag === 'all') {
      // const imgs = items;
      this.setState({
        items: this.state.projects,
        currentTag: 'all',
      });
    }
    else {

      // let items_filter = items.filter((item) => {
      //   return item.type === tag
      // });

      let items_filter = this.state.projects.filter((item) => {
        return item.type === tag
      });

      // console.log('items_filter: ', items_filter)

      this.setState({
        items: items_filter,
        currentTag: tag,
      });
    }
  };


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };


  createProject(e) {
    e.preventDefault();

    if (this.state.auth_user) {

      swal({
        title: 'Confirmation',
        text: `Do you really want to create an IEO project right now?`,
        icon: 'info',
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((value) => {
        // console.log('value: ', value)

        if (value) {

          const formData = new FormData();
          formData.append('user_id', JSON.parse(this.state.auth_user).user_id)

          this.setState({ loader: true })

          axios.post(GLOBE.API_URL+`/create_project`, formData)
            .then(response => {
              // console.log('response: ', response)

              this.setState({ loader: false })

              if (response.data) {
                // window.location.href = origin+'/edit/'+response.data;

                this.setState({
                  redirect: true,
                  redirect_path: '/edit/'+response.data,
                })
              }
              else {
                ToastStore.warning(`The server is not responding. Try later.`);
              }
          });
        }
      })
    }
    else {

      swal({
        title: 'Authorization required',
        text: `You must login to continue. Want to do it right now? `,
        icon: 'warning',
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
      .then((value) => {
        if (value) {
          this.setState({
            redirect: true,
            redirect_path: '/login#launchpad',
          })
        }
      })
    }
  }


  getStatusIDO(time) {

    var now = moment();
    var date_now = moment(now);
    var date_start = moment(time ? time[0] : '0000-00-00');
    var date_end = moment(time ? time[1] : '0000-00-00');

    var days_start = date_start.diff(date_now, 'days');
    var days_end = date_end.diff(date_now, 'days');

    let status = 'ended';

    if (days_start > 0) {
      status = 'future';
    }
    else if (days_start <= 0 && days_end >= 0) {
      status = 'current';
    }
    else if (days_start < 0 && days_end <= 0) {
      status = 'ended';
    }
    // else {
    //   status = 'ended';
    // }

    return status;
  }



  renderProjects(item, i) {
    // render_count++;

    // console.log('item: ', item)

    let totals = JSON.parse(item.totals)
    // console.log('totals: ', totals)
    // console.log('totals: ', totals.time[1])

    item.time = [];
    const defoltTime = () => {
      item.time[0] = '0000-00-00';
      item.time[1] = '0000-00-00';
    }

    defoltTime();

    if (!totals.time) {
      defoltTime();
    }
    else if (totals.time[0] === '') {
      defoltTime();
    }
    else {
      item.time = totals.time;
    }

    // console.log('item.time: ', item.time)

    item.type = this.getStatusIDO(totals.time);
    item.asset_code = item.sell_asset.split('-')[0];


    return (
      <Col md={3} key={i}>
        <Link to={`/launch/${item.path}`}>
          <Card style={{heigh: 'auto'}}>
            <img src={item.cover_url} alt={item.title} className="card-img-top" />
            <CardBody className="item-list">
              <h5 className="card-title"><b>{item.title} ( {item.asset_code} )</b>
                <font className={`ieo-${item.type}`}>{item.type}</font>
              </h5>
              <p className="card-text">{item.subtitle}</p>
              <p>
                <small>Session Suply: <font><b>{numFormat(item.session_supply, 7)} {item.asset_code}</b></font></small><br/>
                <small>Time: <font>{item.time[0]} - {item.time[1]}</font></small>
              </p>
            </CardBody>
          </Card>
        </Link>
      </Col>
    )
  }



  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect_path}/>
    }

    const img_create = `${process.env.PUBLIC_URL}/img/IDO/245x134.png`;

    // console.log('state: ', this.state)



    return (
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <Card>
               <CardBody style={{padding: '10px'}}>
                <UncontrolledCarousel items={items_slider} />
               </CardBody>
            </Card>
          </Col>
        </Row>

        {/*<Row>
          <Col md={12}>
            <h3 className="page-title">Page title</h3>
          </Col>
        </Row>*/}


        <Row>
          <Col md={12}>
            <Card style={{heigh: 'auto'}}>
              <CardBody>

                <div className="card__title">
                  <h5 className="bold-text">Crowdfunding and IEO projects</h5>
                  <h5 className="subhead">Investing in crowdfunding and IEO projects. Starting a crowdfunding company / IEO without listing fees.</h5>
                  <span className="add-ido">
                    <Button color="success"
                            className="rounded"
                            outline
                            onClick={(e) => {
                              this.createProject(e);
                            }}><i className="fa fa-plus"></i></Button>
                  </span>
                </div>
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>Projects</b>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          <b>Advisors</b>
                        </NavLink>
                      </NavItem>*/}
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>How it works</b>
                        </NavLink>
                      </NavItem>
                    </Nav>


                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">

                        <div className="gallery">
                          <div className="gallery__btns">
                            <button
                              className={`gallery__btn${this.state.currentTag === 'all' ? ' gallery__btn--active' : ''}`}
                              onClick={e => this.onFilter('all', e)}
                            ><b>all</b>
                            </button>
                            {this.state.tags.map((btn, i) => (
                              <button
                                key={i}
                                className={`gallery__btn${btn.tag === this.state.currentTag ? ' gallery__btn--active' : ''}`}
                                onClick={e => this.onFilter(btn.tag, e)}
                              ><b>{btn.title}</b>
                              </button>
                              ))}
                          </div>

                          <Row>
                            {
                              this.state.items ?
                                this.state.items.map(this.renderProjects.bind(this))
                              : null
                            }


                            <Col md={this.state.items.length > 0 ? 3 : 12} key={0}>
                              <a href={`#`} onClick={(e) => {
                                              this.createProject(e);
                                            }}>
                                <Card style={{heigh: 'auto'}}>
                                  <img src={img_create} alt={'Create IEO Project'} className="card-img-top" />
                                  <CardBody className="item-list">
                                    <h5 className="card-title"><b>{'Your project'} ( {'TOKEN'} )</b>
                                      <font className={`ieo-${'future'}`}>future</font>
                                    </h5>
                                    <p className="card-text">
                                      {'Launch your crowdfunding / IEO project right now'}
                                      {/*<Button color="success" outline size="sm">Create</Button>*/}
                                    </p>
                                    <p>
                                      <small>Session Suply: <font><b>{numFormat(100000000, 7)} {'TOKEN'}</b></font></small><br/>
                                      <small>Time: <font>0000-00-00 - 0000-00-00</font></small>
                                    </p>
                                  </CardBody>
                                </Card>
                              </a>
                            </Col>


                          </Row>
                        </div>
                      </TabPane>

                      {/*<TabPane tabId="2">
                        <p>Direction has strangers now believing. Respect enjoyed gay far exposed parlors towards. Enjoyment
                          use tolerably dependent listening men. No peculiar in handsome together unlocked do by.
                        </p>
                      </TabPane>*/}

                       <TabPane tabId="3">
                        <p>How it works.</p>
                        <br/>

                        <p>To place your IEO project in Scopuly Launchpad, you need to complete only 2 steps:</p>
                        <p style={{marginLeft: '30px'}}>
                          <ol>
                            <li>Issue your token in Scopuly (or in any other Stellar wallet)</li>
                            <li>Create your IEO project and fill out the necessary data</li>
                          </ol>
                        </p>
                        <br/>

                        <p>The exact list of steps to run your IEO project in Scopuly:</p>
                        <p style={{marginLeft: '30px'}}>
                          <ol>
                            <li>Token Issue</li>
                            <li>Placing an offer on SDEX</li>
                            <li>Create an IEO project</li>
                            <li>Add project cover</li>
                            <li>Indicate the address of the distributor</li>
                            <li>Choose a token for sale</li>
                            <li>Indicate Session Supply (how many tokens are allocated for the current IEO step)</li>
                            <li>Indicate Session Cap (the amount of the planned fee for the current step in USD)</li>
                            <li>Fill in the Conditions section (indicate the conditions of sale: how many stages, at what dates, at what prices, etc. ..)</li>
                            <li>Indicate links to documents: Whitepaper, Onepager</li>
                            <li>Insert a link to your project with ICObench</li>
                          </ol>
                        </p>
                        <br/>

                        <p>After that it is necessary to save the entered data. When you click Save - Scopuly by API will receive all the data for your project from ICObench (team, advisers, KYC, IEO start date, etc. ..)</p>
                        <p>After you save the changes, you can proceed to view the project. The publication of the project in the general Scopuly Launchpad list is possible only after studying the project and approval by the Scopuly team of experts.</p>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Footer />

      </Container>
    );
  }
}


Project.propTypes = {
  t: PropTypes.func.isRequired,
};


export default translate('common')(Project);
