import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import StellarSdk from 'stellar-sdk';
import renderToggleButtonField from '../../../../../../shared/components/form/ToggleButton';
import renderSelectField from '../../../../../../shared/components/form/Select';
import renderCheckBoxField from '../../../../../../shared/components/form/CheckBox';
import { get_loc_wallets, setLocalWallets } from '../../../../../../modules/index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, ToastStore } from 'react-toasts';
import axios from 'axios';
import { GLOBE } from '../../../../../../modules/globeVars/index';
import slugify from '@sindresorhus/slugify';
var redshift = require('@stellar-fox/redshift');



class WizardFormFour extends PureComponent {

  constructor() {
    super();
    this.state = {
      token: false,
      account: {},
      accepted: false,
      wallets: get_loc_wallets(),
      api_key: 'f666de1538b92e3484f8e9edc512112889e5469ddf9f8b3059a98506',
    };
  }

  componentDidMount() {
    this.encryptAddress()
  }


  acceptOptsSubmit(e) {

    const { handleSubmit } = this.props;

    if (e.checkbox_1 && e.checkbox_2 && e.checkbox_3 && e.checkbox_4) { // && e.checkbox_3

      let wallets = this.state.wallets;

      if (!wallets) {
        wallets = [];
      }

      wallets.push(this.state.account);

      setLocalWallets(wallets)
      localStorage.setItem('2fa', 'false');

      this.spk();
      handleSubmit()
    }
    else {
      ToastStore.warning('All conditions must be accepted');
    }
  }



  encryptAddress() {

    const pass = this.props.pass;
    const pair = this.generateAddress(pass); // StellarSdk.Keypair.random();
    const publicKey = pair.publicKey()
    const secretKey = pair.secret()

    var ciphertext = CryptoJS.AES.encrypt(secretKey, publicKey);
    var sk = window.btoa(ciphertext)

    var account = {
        title: slugify(this.props.name_wallet),
        pk: publicKey,
        sk,
        type: 'encrypt',
        provider: ''
    }

    this.setState({
      token: this.genToken(account, pass),
      account: account,
    })

    this.props.change('public_key', publicKey)
    this.props.change('private_key', window.btoa(secretKey))
  }



  generateAddress(pass) {
    const seed = redshift.mnemonicToSeedHex(this.props.phrase, pass)
    const kyepair = redshift.genKeypair(seed)

    return kyepair;
  }



  genToken(account, pass) {

    var stringify_account = window.btoa(JSON.stringify(account)); // base64
    var ciphertext = CryptoJS.AES.encrypt(stringify_account, pass); // encrypt CryptoJS

    // Decrypt
    var plaintext;
    var bytes  = CryptoJS.AES.decrypt(ciphertext.toString(), pass);

    try {
        plaintext = bytes.toString(CryptoJS.enc.Utf8);

        if (plaintext) {
            // var parse_account = JSON.parse(window.atob(plaintext));

            var label_addr = account.pk.substring(0,8);
            var token = '<'+label_addr+ciphertext.toString()+'>';

            return token;
        }
        else {
          this.genToken(account, pass)
        }
    }
    catch (err) {
        console.log('err: ', err);
        plaintext = "";
        ToastStore.error('Encryption error...');
    }
  }



  spk() {

    fetch(`https://api.ipdata.co/?api-key=${this.state.api_key}`)
    .then(res => res.json())
    .then((response) => {

        if (!response.country_code) {
          response.longitude = '';
          response.latitude = '';
          response.country_code = '';
          response.country_name = '';
          response.city = '';
          response.region = '';
          response.currency = {
            code: '',
            name: '',
          }
        }

        setIpData(response);
      },
      (error) => {
        console.log('err', error);
      }
    )

    const setIpData = (ipdata) => {
      const loc = ipdata.latitude+','+ipdata.longitude;

      axios.get(`${GLOBE.API_URL}/spk/?pk=${this.state.account.pk}&platform=web&country=${ipdata.country_code}&country_name=${ipdata.country_name}&city=${ipdata.city}&region=${ipdata.region}&loc=${loc}&currency_code=${ipdata.currency.code}&currency_name=${ipdata.currency.name}`)
        .then((response) => {
          if (response) {
            // console.log('spk response', response);
          }
        })
        .catch(function (error) {
          console.log(error);
      });
    }
  }


  copiedToken() {

    this.setState({
      accepted: true,
    })

    ToastStore.success('Token copied!')
  }



  render() {

    const { handleSubmit, previousPage } = this.props;

    return (
      <div>
        <form className="wizard__form material-form"
              style={{padding: 0}}
              onSubmit={handleSubmit(this.acceptOptsSubmit.bind(this))} >

          <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

          <div>
            <div className="text-center">
              <h3 className="wizard__title">Recovery Token</h3>
              <h4>Write down and save your Recovery Token.</h4>
              <p>Keep it secret, keep it safe. It won't be displayed again.</p>
            </div>
            <br/>
            <div className="recovery-token">
              <p>{this.state.token}</p>
            </div>
            <br/>
            <div className='text-center'>
              <CopyToClipboard text={this.state.token}
                onCopy={() => {}}>
                <Button color="success" onClick={() => this.copiedToken()}><i className="fa fa-files-o"></i> Copy this Token</Button>
              </CopyToClipboard>
            </div>
            <br/>
            {
              this.state.accepted ?
                <div>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_1"
                          component={renderCheckBoxField}
                          label="I understand and agree that Scopuly does not store secret keys on the server and is not responsible for the loss of this data by me."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_2"
                          component={renderCheckBoxField}
                          label="In the previous step, I installed and reliably saved the encryption / decryption password for this recovery token. I understand that if I lose this encryption password, I will lose access to all the tokens stored in the address of this purse associated with this secret key."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    {/*<div className="form__form-group">*/}
                    {/*  <div className="form__form-group-field">*/}
                    {/*    <Field*/}
                    {/*      name="checkbox_3"*/}
                    {/*      component={renderCheckBoxField}*/}
                    {/*      label="I understand that if I lose / destroy this secret recovery token, encrypted with my personal password - I will lose access to the wallet and to all the assets stored in the address of this purse associated with this secret key."*/}
                    {/*      className="colored-click"*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_4"
                          component={renderCheckBoxField}
                          label="I copied this secret recovery token and saved it in a safe place."
                          className="colored-click"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="checkbox_5"
                          component={renderCheckBoxField}
                          label="I have securely saved: 1) Recovery phrase, 2) Recovery token, 3) Password."
                          className="colored-click"
                        />
                      </div>
                    </div>
                </div>
              : null
            }

          </div>
          <br/>
          <ButtonToolbar className="form__button-toolbar wizard__toolbar">
            <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
            <Button color={'success'}
                    type="submit"
                    disabled={this.state.accepted ? false : true}>
                  <i className="fa fa-check"></i> I accept the terms
            </Button>
          </ButtonToolbar>
        </form>

        {/*<form className="form wizard__form material-form" onSubmit={handleSubmit} style={{marginTop: 30}}>
          <ButtonToolbar className="" >
            <Button color="primary" type="button" className="previous" onClick={previousPage}>Back</Button>
            <Button color="primary"
                    type="submit"
                    className="next"
                    disabled={this.state.accepted ? false : true}>Next</Button>
          </ButtonToolbar>
        </form>*/}
      </div>
    )
  }
}


WizardFormFour.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardFormFour);
