import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
	shortAddr,
	numFormat,
	getAsset,
	formatDate,
	shortAddress,
	itemType,
	shortPool
} from '../../../../../modules/index';
import iconIdent from "../iconIdent";
import {Link} from "react-router-dom";


class Effects extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}


	renderEffects = (item, index) => {
		// console.log('item: ', item)

		const operation_id = item.paging_token.split('-')[0];

		if (item.type === 'account_created' ||
			item.type === 'account_removed' ||
			item.type === 'account_flags_updated' ||
			item.type === 'account_inflation_destination_updated' ||
			item.type === 'signer_created' ||
			item.type === 'signer_removed' ||
			item.type === 'signer_updated' ||
			item.type === 'offer_created' ||
			item.type === 'offer_removed' ||
			item.type === 'offer_updated' ||
			item.type === 'trustline_authorized' ||
			item.type === 'trustline_deauthorized' ||
			item.type === 'trustline_flags_updated' ||
			item.type === 'data_created' ||
			item.type === 'data_removed' ||
			item.type === 'data_updated') {

			return (
				<tr key={index}>
					<td><small>{index+1}</small></td>
					<td>
						{/*Icons*/}
						{ item.type === 'account_created' ? <i className="fa fa-user-o"></i> : null }
						{ item.type === 'account_removed' ? <i className="fa fa-trash-o"></i> : null }
						{ item.type === 'account_flags_updated' ? <i className="fa fa-repeat"></i> : null }
						{ item.type === 'account_inflation_destination_updated' ? <i className="fa fa-fa-repeat"></i> : null }
						{ item.type === 'signer_created' ? <i className="fa fa-key"></i> : null }
						{ item.type === 'signer_removed' ? <i className="fa fa-trash-o"></i> : null }
						{ item.type === 'signer_updated' ? <i className="fa fa-repeat"></i> : null }
						{ item.type === 'offer_created' ? <i className="fa fa-area-chart"></i> : null }
						{ item.type === 'offer_removed' ? <i className="fa fa-trash-o"></i> : null }
						{ item.type === 'offer_updated' ? <i className="fa fa-repeat"></i> : null }
						{ item.type === 'trustline_authorized' ? <i className="fa fa-sign-in"></i> : null }
						{ item.type === 'trustline_deauthorized' ? <i className="fa fa-sign-out"></i> : null }
						{ item.type === 'trustline_flags_updated' ? <i className="fa fa-repeat"></i> : null }
						{ item.type === 'data_created' ? <i className="fa fa-plus-square-o"></i> : null }
						{ item.type === 'data_removed' ? <i className="fa fa-trash-o"></i> : null }
						{ item.type === 'data_updated' ? <i className="fa fa-repeat"></i> : null }

						{' '} {itemType(item.type)}
					</td>
					<td><b className="text-info">{ itemType(item.type) }</b></td>
					<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
					<td>{ formatDate(item.created_at) }</td>
				</tr>
			)
		}
		else {
			// ... switch
		}

		switch(item.type) {

		  // case 'account_created':
		  //   return (
		  //     <tr key={index}>
		  //     	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		  //     	<td><b className="text-info">{{itemType(item.type) }</b></td>
		  //     	<td>{ this.formatDate(item.created_at) }</td>
		  //       <td>{itemType(item.type)}</td>
		  //     </tr>
		  //   )

		   // case 'account_removed':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{{itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   case 'account_debited':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-arrow-left text-danger"></i>&nbsp; {itemType(item.type)} <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item)} from {shortAddress(item.account)}</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
		        <td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   case 'account_credited':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-arrow-right text-success"></i>&nbsp; {itemType(item.type)} <b className="text-info">{numFormat(item.amount, 7)}</b> {getAsset(item)} to {shortAddress(item.account)}</td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
		      	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   case 'account_thresholds_updated':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td>
		      		<p>High threshold set to: <b className="text-info"> { item.high_threshold } </b></p>
		      		<p>Low threshold set to: <b className="text-info"> { item.low_threshold } </b></p>
		      		<p>Med threshold set to: <b className="text-info"> { item.med_threshold } </b></p>
		      	</td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   // case 'account_flags_updated':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'account_inflation_destination_updated':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   case 'account_home_domain_updated':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-globe"></i>&nbsp; Establish a home domain <a href={item.home_domain}><b>{item.home_domain}</b></a></td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   // case 'signer_created':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'signer_updated':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'signer_removed':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{{itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'offer_created':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'offer_removed':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   // case 'offer_updated':
		   //  return (
		   //    <tr key={index}>
		   //    	<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		   //    	<td><b className="text-info">{ itemType(item.type) }</b></td>
		   //    	<td>{ this.formatDate(item.created_at) }</td>
		   //      <td>{itemType(item.type)}</td>
		   //    </tr>
		   //  )

		   case 'trade':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
	      		<td><i className="fa fa-exchange"></i>&nbsp; {shortAddress(item.account)} bought <b className="text-info">{numFormat(item.bought_amount, 7)}</b>&nbsp;
	      	  	<a href={item.bought_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.bought_asset_code}-XLM/${item.bought_asset_issuer}/native`} className="text-warning"><b>{item.bought_asset_type === 'native' ? 'XLM' : item.bought_asset_code}</b></a>
	      	  	&nbsp; sold <b className="text-info">{numFormat(item.sold_amount, 7)}</b>&nbsp;
	      	  	<a href={item.sold_asset_type === 'native' ? '/asset/XLM-native' : `/trade/${item.sold_asset_code}-XLM/${item.sold_asset_issuer}/native`} className="text-warning"><b>{item.sold_asset_type === 'native' ? 'XLM' : item.sold_asset_code}</b></a>
	      	  	&nbsp; to {shortAddress(item.seller)}
	      	  </td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{formatDate(item.created_at)}</td>
		      </tr>
		    )

		   case 'trustline_created':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-check text-success"></i>&nbsp; {shortAddress(item.account)} add trustline { item.asset_type === 'liquidity_pool_shares' ? <span>to {shortPool(item.liquidity_pool_id)}</span> : <span>to {getAsset(item)}</span> } limit <b className="text-info">{numFormat(item.limit, 7)}</b></td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   case 'trustline_removed':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-trash-o"></i>&nbsp; {shortAddress(item.account)} removed trustline { item.asset_type === 'liquidity_pool_shares' ? <span>from {shortPool(item.liquidity_pool_id)}</span> : <span>to {getAsset(item)}</span> }</td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

		   case 'trustline_updated':
		    return (
		      <tr key={index}>
						<td><small>{index+1}</small></td>
		      	<td><i className="fa fa-repeat"></i>&nbsp; Trustline updated to {getAsset(item)} limit <b className="text-info">{numFormat(item.limit, 7)}</b></td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{ formatDate(item.created_at) }</td>
		      </tr>
		    )

			case 'claimable_balance_claimant_created':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>
							<i className="fa fa-clock-o"></i>&nbsp; <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(item.asset)} sponsored for {shortAddress(item.account)} {' '}
							{
								item.predicate.and && item.predicate.and.length > 1 ? <span>valid until <b className={'text-info'}>{formatDate(item.predicate.and[1].abs_before)}</b></span> : null
							}
						</td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{formatDate(item.created_at)}</td>
					</tr>
				)

			case 'claimable_balance_claimed':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><i className="fa fa-clock-o text-success"></i>&nbsp; {shortAddress(item.account)} claimed <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(item.asset)}</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'claimable_balance_sponsorship_created':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><i className="fa fa-clock-o"></i>&nbsp; {shortAddress(item.account)} created claimable balance sponsorship</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'claimable_balance_sponsorship_removed':

				console.log('item: ', item)

				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><i className="fa fa-trash-o"></i>&nbsp; {shortAddress(item.former_sponsor)} deleted sponsored balance for {shortAddress(item.account)} {' '}
							<i className="fa fa-check-circle-o text-success"></i></td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'claimable_balance_created':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><i className="fa fa-clock-o"></i>&nbsp; {shortAddress(item.account)} claimable balance created for <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(item.asset)}</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'liquidity_pool_trade':
			 	return (
				 	<tr key={index}>
						<td><small>{index+1}</small></td>
						<td><i className="fa fa-exchange"></i>&nbsp; {shortAddress(item.account)} bought <b className="text-info">{numFormat(item.sold.amount, 7)}</b> {getAsset(item.sold.asset)} sold <b className="text-info">{numFormat(item.bought.amount, 7)}</b> {getAsset(item.bought.asset)}</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
				  </tr>
			 	)

			case 'liquidity_pool_deposited':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>
							<i className="fa fa-arrow-up text-success" ></i>&nbsp; {shortAddress(item.account)} <b className={'text-success'}>deposited</b> <b className="text-info">{numFormat(item.reserves_deposited[0].amount, 7)}</b> {getAsset(item.reserves_deposited[0].asset)} / <b className="text-info">{numFormat(item.reserves_deposited[1].amount, 7)}</b> {getAsset(item.reserves_deposited[1].asset)} (<b className="text-info">{numFormat(item.shares_received, 7)}</b> <b>pool shares</b>) <br/>
							to {shortPool(item.liquidity_pool.id)}
						</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'liquidity_pool_withdrew':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>
							<i className="fa fa-arrow-down text-danger"></i>&nbsp; {shortAddress(item.account)} <b className={'text-danger'}>withdrew</b> <b className="text-info">{numFormat(item.reserves_received[0].amount, 7)}</b> {getAsset(item.reserves_received[0].asset)} / <b className="text-info">{numFormat(item.reserves_received[1].amount, 7)}</b> {getAsset(item.reserves_received[1].asset)} (<b className="text-info">{numFormat(item.shares_redeemed, 7)}</b> <b>pool shares</b>) <br/>
							from {shortPool(item.liquidity_pool.id)}
						</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'liquidity_pool_created':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>
							<i className="fa fa-check text-success" ></i>&nbsp; {shortAddress(item.account)} created {shortPool(item.liquidity_pool.id)}
						</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)

			case 'liquidity_pool_removed':
				return (
					<tr key={index}>
						<td><small>{index+1}</small></td>
						<td>
							<i className="fa fa-trash-o" ></i>&nbsp; {shortAddress(item.account)} removed {shortPool(item.liquidity_pool_id)}
						</td>
						<td><b className="text-info">{ itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
						<td>{ formatDate(item.created_at) }</td>
					</tr>
				)


		  default:
		    console.log('default effect', item);

		    return (
		      <tr key={index}>
						<td>{index+1}</td>
		      	<td>Cooming soon</td>
						<td><b className="text-info">{itemType(item.type) }</b></td>
						<td><b><a href={`/operation/${operation_id}`}>{ shortAddr(operation_id, 4) }</a></b></td>
		      	<td>{formatDate(item.created_at)}</td>
		      </tr>
		    )
		}
	}


	render() {

		const effects = this.props.items;

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
						<th>#</th>
			    	<th>Details</th>
			    	<th>Effect</th>
						<th>Operation</th>
						<th>Date</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ effects.map(this.renderEffects) }

			  </tbody>
			</Table>
		)
	}
}

Effects.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(Effects);
