import React, { PureComponent } from 'react';
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Button,
  ButtonGroup,
  ButtonToolbar,
  UncontrolledCarousel, Badge, Modal
} from 'reactstrap';
import { translate } from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import { Field, reduxForm, change} from 'redux-form';
import StellarSdk from "stellar-sdk";
import {
  baseReserve,
  checkConnectProvider,
  formatDate,
  get_loc_wallets,
  getAsset,
  getBasePrice,
  getIconConnectProvider,
  getRefLevel,
  getTitle,
  numFormat,
  randomAmount,
  shortAddr,
  shortAddress,
  shortTransaction,
  signConnectProvider,
  getSigner,
  getRefPositionLevel,
  getStellarFee,
  getFloatValue,
  animateElems
} from "../../../modules";
import swal from "sweetalert";
import {getAccount, getOrderbook,} from '../../../endpoints/StellarAPI'
import {Server} from "../../../modules/stellar";
import {ToastContainer, ToastStore} from "react-toasts";
import Footer from "../../Layout/footer/Footer";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../Account/components/iconIdent";
import TextField from "@material-ui/core/TextField";
import LoadingIcon from "mdi-react/LoadingIcon";
import SpinnerLoader from "../../../modules/SpinnerLoader";
import axios from "axios";
import {GLOBE} from "../../../modules/globeVars";
import DepositWithdraw from "../LiquidityPools/components/DepositWithdraw";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {QRCode} from "react-qrcode-logo";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import renderRadioButtonField from "../../../shared/components/form/RadioButton";
import {checkPartner} from "../../../endpoints/API";


const loaderStyle = css`
  display: inline-block;
  width: 40px;
  top: 0;
`;


const items_slider = [
  {
    src: `${process.env.PUBLIC_URL}/img/slider/1.png`,
    header: 'Crypto / Fiat exchange',
    altText: 'Crypto / Fiat exchange',
    caption: 'Buying / selling crypto for fiat money'
  },
  // {src: `${process.env.PUBLIC_URL}/img/slider/2.png`,},
  // {src: `${process.env.PUBLIC_URL}/img/slider/3.png`,},
  // {src: `${process.env.PUBLIC_URL}/img/slider/4.png`,},
  // {src: `${process.env.PUBLIC_URL}/img/slider/5.png`,}
];

const renderTextField = ({
                           input, label, meta: { touched, error }, children, select, type,
                         }) => (
  <TextField
    className="material-form__field"
    label={label}
    error={touched && error}
    value={input.value}
    children={children}
    select={select}
    type={type}
    onChange={(e) => {
      e.preventDefault();
      input.onChange(e.target.value);
    }}
  />
);



class Referrals extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      signer: false,
      select_address: false,
      select_account: false,
      search_address: false,
      is_all_payouts: false,
      address_payouts: [],
      account_name: false,
      payouts: [],
      load_more: true,
      modal: false,
      isset_referral: false,
      isset_address: false,
      isset_name: false,
      ref_code: false,
      scop_asset: {
        code: 'SCOP',
        issuer: 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'
      },
      level: 0,
      loader: false,
      top: false,
      paste_address: false,
    };
  }

  page = 0
  limit = 30
  fee = getStellarFee()


  componentDidMount() {

    this.getXLMPrice()
    this.checkReferral()

    animateElems()
  }



  checkReferral = () => {
    const referral = localStorage.getItem('referral')
    if (referral) {
      this.setState({
        isset_referral: true,
        select_address: this.state.wallets && referral,
      }, () => {
        this.checkAddress(referral)
        // this.getPayoutsForAddress(referral)
        this.props.change('select_addr', referral)
      })
    }
    else {
      this.props.change('select_addr', '')
    }
  }


  getXLMPrice = async () => {

    const data = await getBasePrice()
    const stellar = JSON.parse(data.data)
    if (stellar) {
      this.setState({
          stellar,
          base_price: stellar.market_data.current_price.usd
        },
        () => {
          this.getPayoutsData()
        })
    }
  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  }


  getPayoutsData = () => {

    const { base_price, page, payouts } = this.state

    this.setState({ load_more: false, })

    if (payouts.length) this.page++

    const formData = new FormData();
    formData.append('page', this.page)
    formData.append('limit', this.limit)
    // formData.append('address', address)

    axios.post(`${GLOBE.API_URL}/get_stat_referrals`, formData).then(result => {
      // console.log('getPayoutsData result: ', result)

      if (result.data) {

        this.setState({
          payouts: payouts.concat(result.data.payouts),
          asset: result.data.asset,
          load_more: true,
        })

        const address = this.props.match.params.id

        if (StellarSdk.StrKey.isValidEd25519PublicKey(address)) {

          getTitle(`SCOP payouts for address: ${address}`);

          this.props.change('search', address)
          // select_stat_addr

          this.setState({
            select_address: address,
            is_all_payouts: false,
            address_payouts: [],
          }, () => {
            // this.getStatForAddress()
            this.getPayoutsForAddress(address)
            this.scrollPage()
          })
        }
        else if (address === 'top') {
          this.setState({ top: true })
        }
      }
    });
  }


  getPayoutsForAddress = (address) => {
    // console.log('address: ', address)

    const { base_price } = this.state

    const formData = new FormData();
    formData.append('page', 0)
    formData.append('limit', 30)
    formData.append('address', address)

    axios.post(`${GLOBE.API_URL}/get_stat_referrals_for_address`, formData).then(result => {
      // console.log('get_stat_referrals_for_address result: ', result)

      if (result.data) {

        this.setState({
          payouts: result.data.payouts,
          asset: result.data.asset,
        })
      }
    });
  }


  submitSearchForm = () => {

  }


  startCreateRefCode = (type) => {

    this.toggleModal()

    if (type === 'start') {
      this.resetReferral('all')
    }

    // swal({
    //   title: "Confirm",
    //   text: 'Create a referral code right now?',
    //   icon: "info",
    //   buttons: {
    //     cancel: true,
    //     confirm: true,
    //   },
    // })
    //   .then((confirm) => {
    //     if (confirm) {
    //
    //       this.toggleModal()
    //
    //     }
    //   })
  }


  createRefCode = () => {

    const { select_address, account_name } = this.state

    if (!select_address) {
      ToastStore.warning('Select Account');
    }
    else if (!account_name) {
      ToastStore.warning('Enter Account Name');
    }
    else {

      const formData = new FormData();
      formData.append('address', select_address)
      formData.append('name', account_name)

      axios.post(`${GLOBE.API_URL}/create_referral`, formData).then(result => {
        // console.log('create_referral result: ', result)

        if (result.data !== 'isset_code') {

          this.setState({
            isset_address: { code: result.data },
          })

          this.setReferral()

          const message = `<div>Referral code successfully generated. <br><br> <h2 class="text-info"><b>${result.data}</b></h2></div>`
          const element = document.createElement("span");
          element.innerHTML = message;

          swal({
            title: "Created!",
            content: element,
            icon: "success",
          })
        }
      });
    }
  }


  setReferral = () => {
    localStorage.setItem('referral', this.state.select_address)
  }


  changeAccount = (value) => {
    // console.log('value: ', value)

    let address = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        address = (address + value[key])
      }
      else {

        this.setState({
          select_address: address,
          loader: true
        })

        this.checkAddress(address)
      }
    }
  }


  changeName = (value) => {

    let account_name = getFloatValue(value)

    if (account_name.length > 15) {
      // account_name = account_name.slice(0, 20)
      ToastStore.warning('No more than 15 characters');
      return
    }

    this.setState({ account_name })

    this.checkName(account_name)
  }


  checkAddress = (address) => {

    this.loadAccount(address)

    checkPartner(address).then(result => {
      // console.log('result: ', result)

      if (result.data.referral.length) {
        const referral = result.data.referral[0]

        this.setState({
          isset_address: referral,
          top_10: result.data.top_10,
        })
        this.props.change('username', referral.name)
        this.setReferral()
      }
      else {

        this.resetReferral('part')
      }
    })

  }


  loadAccount = (address) => {

    this.getSigner(address);

    getAccount(address).then((result) => {
      const { scop_asset, top_10 } = this.state

      if (result) {
        // console.log('result: ', result)

        if (!result.balances) {
          swal({
            title: "Not Found",
            text: "Perhaps there is no XLM at this address. Try making an XLM deposit to this address.",
            icon: "info",
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
            .then((confirm) => {
              this.toggleModal()
            })

          this.setState({ loader: false })
          this.checkReferral()
        }
        else {

          const balance = result.balances.filter((item) => item.asset_code === scop_asset.code && item.asset_issuer === scop_asset.issuer)[0]
          // console.log('balance: ', balance)

          if (balance) {

            this.setState({ loader: false })

            let level = getRefLevel(balance.balance)

            let position
            top_10.forEach((top, index) => {
              if (top.address === address) {
                position = index+1

                // console.log('position: ', position)

                level = getRefPositionLevel(level, position)
              }
            })

            // console.log('level: ', level)

            this.setState({ level })
          }
          else {
            this.alertNoTrustline(address)
          }
        }
      }
    })
      .catch(error => {
        console.log('ERR: ', error)
      })
  }


  alertNoTrustline(address) {

    const { scop_asset } = this.state

    const message = `<div>Add trustline for <b class="text-warning">${scop_asset.code}</b> right now?</div>`
    const element = document.createElement("span");
    element.innerHTML = message;

    swal({
      title: `No trustline for ${scop_asset.code}`,
      content: element,
      icon: "info",
      buttons: {
        cancel: true,
        confirm: true,
      },
    })
      .then((confirm) => {
        if (confirm) {
          this.setTrustline(address);
        }
      })
  }


  setTrustline = (select_address) => {

    const { scop_asset } = this.state

    this.setState({ loader: true })

    Server.loadAccount(select_address)
      .then((sourceAccount) => {

        let transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: this.fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.changeTrust({
            asset: new StellarSdk.Asset(scop_asset.code, scop_asset.issuer),
          }))
          .setTimeout(100)
          .build();

        if (checkConnectProvider(select_address)) {
          return signConnectProvider(transaction, select_address).then(result => {
            return Server.submitTransaction(result);
          })
        }
        else {

          const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(select_address).sk))
          transaction.sign(signer);

          return Server.submitTransaction(transaction);
        }
      })
      .then((result) => {

        this.setState({
          loader: false,
          level: 5,
        })

        swal({
          title: `Added new balance ${scop_asset.code}!`,
          text: `Trustline to ${scop_asset.code} token has been successfully installed. Now ${scop_asset.code} token is available on your balance.`,
          icon: "success",
          confirm: true,
        })
          .then(confirm => {
            if (confirm) {
              // this.getAccount(account_id);
            }
          });
      })
      .catch((error) => {
        console.error('Something went wrong!', error);

        this.setState({ loader: false })
        ToastStore.error('Transaction error');
      });
  }


  getSigner(address) {

    const wallets = this.state.wallets;

    if (wallets) {
      const signer = wallets.filter((item) => {
        return item.pk === address;
      });

      this.setState({ signer: signer, })
    }
  }


  resetReferral = (type) => {
    this.setState({ isset_address: false })

    this.props.change('username', '')
    if (type === 'all') {
      this.props.change('select_addr', '')
      this.setState({ select_address: false })
    }
  }


  checkName = (name) => {

    const formData = new FormData();
    formData.append('name', name)

    axios.post(`${GLOBE.API_URL}/check_referral_name`, formData).then(result => {

      if (result.data.referral.length) {
        this.setState({ isset_name: true, })
      }
      else {
        this.setState({ isset_name: false, })
      }
    });
  }


  updateRefName = () => {

    const { account_name, select_address, isset_name } = this.state

    if (!isset_name) {

      const formData = new FormData();
      formData.append('address', select_address)
      formData.append('name', account_name)

      axios.post(`${GLOBE.API_URL}/update_referral_name`, formData).then(result => {

        if (result.data === 'update') {
          swal({
            title: "Updated!",
            text: 'Referral name updated successfully',
            icon: "success",
          })
        }
      });
    }
    else {
      ToastStore.warning('This name already exists');
    }
  }


  // createCode = () => {
  //
  //   const { select_address, account_name } = this.state
  //
  //   const formData = new FormData();
  //   formData.append('address', select_address)
  //   formData.append('name', account_name)
  //
  //   axios.post(`${GLOBE.API_URL}/create_referral`, formData).then(result => {
  //     console.log('check_referral_address result: ', result)
  //
  //     if (!result.data.referral.length) {
  //       alert(1)
  //
  //       // this.setState({
  //       //   payouts: result.data.payouts,
  //       //   asset: result.data.asset,
  //       // })
  //     }
  //   });
  // }


  copyRef = (e) => {
    e.preventDefault()
    //...
  }


  searchChange = (e) => {

    if (e.target.value.length === 56) {

      const search_address = e.target.value

      this.setState({
        search_address,
        // is_all_payouts: false,
        // address_payouts: [],
      }, () => {

        // this.getStatForAddress()
        this.getPayoutsForAddress(search_address)
        // this.getPayoutsData(search_address)
        this.changeUrl(search_address)
      })
    }
  }


  changeUrl = (address) => {

    if (address) {
      this.props.history.push({ pathname: `/referral/${address}`, });
      // this.scrollPage()
    }
    else {
      this.props.history.push({ pathname: `/referral`, });
    }
  }


  changeAddress = (value) =>{

    let select_account = '';
    for (const key of Object.keys(value)) {
      if (key < 56) {
        select_account = (select_account + value[key])
      }
      else {

        this.setState({
          select_account,
          is_all_payouts: false,
          address_payouts: [],
        }, () => {

          this.page = 0
          // this.getPayouts('address')
          this.props.change('search', select_account)
          // this.getStatForAddress()
          this.getPayoutsForAddress(select_account)
          this.changeUrl(select_account)
          // this.payoutsType('address')
        })
      }
    }
  }



  scrollPage = () => {
    window.scrollBy({
      top: 1000,
      behavior: 'smooth'
    });
  }


  loadMore = () => {

  }


  selectTypeAddress = (type) => {

    if (type === 'paste') {
      this.setState({
        paste_address: true,
      })
    }
    if (type === 'select') {
      this.setState({
        paste_address: false,
      })
    }
  }



  renderModal = () => {

    const { modal, wallets, account_name, isset_address, select_address, ref_code, level, loader, paste_address, isset_name } = this.state;
    const { handleSubmit } = this.props

    let classElem = '', theme = 'theme-light';
    if (localStorage.getItem('theme') === 'theme-dark') {
      classElem = ''
      theme = 'theme-dark'
    }

    const ref_link = `${window.location.origin}/ref/${isset_address.code}`

    return (
      <Modal
        isOpen={modal} //
        toggle={this.toggleModal}
        className={`${theme} modal-dialog--primary modal-dialog--header modal-dialog--swap`}
      >
        <div>
          <div className="modal__header">
            <button className="lnr lnr-cross modal__close-btn" onClick={this.toggleModal} />
            <h4 className="bold-text modal__title"><b>Create referral code</b></h4>
          </div>
          <div className={`modal__body ${classElem}`}>

            { loader ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

            <form className="material-form">

              {/*<div className="form__form-group">*/}
              {/*  <div className="form__form-group-field">*/}
              {/*    <Field*/}
              {/*      name="radio"*/}
              {/*      component={renderRadioButtonField}*/}
              {/*      label="Select Account"*/}
              {/*      radioValue="select"*/}
              {/*      onChange={() => this.selectTypeAddress('select')}*/}
              {/*      defaultChecked*/}
              {/*    />*/}
              {/*    <Field*/}
              {/*      name="radio"*/}
              {/*      component={renderRadioButtonField}*/}
              {/*      label="Paste Address"*/}
              {/*      radioValue="paste"*/}
              {/*      onChange={() => this.selectTypeAddress('paste')}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div>
                {
                  wallets && !paste_address ?

                    <div>
                      <Field
                        name="select_addr"
                        component={renderTextField}
                        select
                        label="Select Account"
                        onChange={this.changeAccount}
                      >
                        {
                          wallets.length ? wallets.map((item, index) => {

                            if (item) {
                              return (
                                <MenuItem
                                  key={index}
                                  style={{fontSize: '12px'}}
                                  className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                  value={item.pk}>
                                  <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                </MenuItem>
                              )
                            }
                          }) : null
                        }
                      </Field>

                      {
                        // select_account ?
                        //   <div style={{marginTop: -9}}>
                        //     <h5 className="subhead">
                        //       {
                        //         balance_shares ?
                        //           <span><b className="text-info">{numFormat(balance_shares, 7)} <font className="text-warning">SHARES</font></b> &nbsp; / &nbsp;</span>
                        //           : null
                        //       }
                        //       Avaliable: <b className="text-info">{numFormat(select_account.avaliable, 7)} <font className="text-warning">XLM</font></b>
                        //       <span className="float-right">Reserve: <b className="text-info">{numFormat(select_account.reserve, 7)} <font className="text-warning">XLM</font></b></span>
                        //     </h5>
                        //     <br/>
                        //   </div>
                        //   : null
                      }
                    </div>

                  :
                    null
                }
              </div>

              {
                paste_address ?
                  <div>
                    <Field
                      name="paste_address"
                      component={renderTextField}
                      type="text"
                      label="Paste Address"
                      onChange={this.changeAccount}
                    />
                  </div>
                : null
              }

              {
                select_address ?
                  <div>
                    <Field
                      name="username"
                      component={renderTextField}
                      type="text"
                      label="Name"
                      // value={account_name}
                      onChange={this.changeName}
                    />
                  </div>
                : null
              }

            </form>

            {/*{*/}
            {/*  wallets ?*/}
            {/*    <form className="form" onSubmit={handleSubmit}>*/}
            {/*      <div className="form__form-group">*/}
            {/*        <span className="form__form-group-label">Account name</span>*/}
            {/*        <div className="form__form-group-field">*/}
            {/*          <Field*/}
            {/*            name="username"*/}
            {/*            className={'br-5'}*/}
            {/*            component="input"*/}
            {/*            type="text"*/}
            {/*            placeholder="Name"*/}
            {/*            onChange={this.changeName}*/}
            {/*          />*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </form>*/}
            {/*  : null*/}
            {/*}*/}

            <br/>
            {
              isset_address && !loader ?
                <div className={'text-center'}>

                  <Row>
                    <Col md={3}>
                      <p>Rate:</p>
                      {
                        level ?
                          <h3><b className={'text-success'}>+{level}%</b></h3>
                        :
                          <span style={{position: "relative"}}>
                            <BeatLoader
                              css={loaderStyle}
                              color={"#70bbfd"}
                              loading={true}
                              size={8}
                            />
                          </span>
                      }
                      <br/>
                    </Col>
                    <Col md={6}>
                      <div className={'mb-6'}>QR Link:</div>
                      <QRCode
                        value={ref_link}
                        // logoWidth="50"
                        // logoHeight="50"
                        size="130"
                        quietZone="10"
                        fgColor="#217fe4"
                        qrStyle="dots"
                        // logoOpacity="0.9"
                        // logoImage="/img/logo/icon-transtparent-512.png"
                        style={{width: '100%'}}
                      />
                      <br/>
                    </Col>
                    <Col md={3}>
                      <p>Code:</p>
                      <h4><b className={'text-info'}>{isset_address.code}</b>
                        <br/>
                        <span>
                      {/*<a href="#" onClick={this.copyRef}><i className={'fa fa-copy'}></i></a>*/}
                          <CopyToClipboard text={isset_address.code} onCopy={() => {}}>
                        <i className="fa fa-clone fs-14"
                           color="secondary"
                           style={{cursor: 'pointer'}}
                           onClick={() => ToastStore.success('Copied!')}></i>
                      </CopyToClipboard>
                    </span>
                      </h4>
                    </Col>
                  </Row>

                  <br/>
                  <p>Referral Link:</p>

                  <div><a href={ref_link}
                        target={'_blank'}
                        className={''}>https://scopuly.com/ref/<b>{isset_address.code}</b></a> {' '}

                    <CopyToClipboard text={ref_link} onCopy={() => {}}>
                      <i className="fa fa-clone"
                         color="secondary"
                         style={{cursor: 'pointer'}}
                         onClick={() => ToastStore.success('Copied!')}></i>
                    </CopyToClipboard>
                  </div>
                  <br/>
                </div>
              : null
            }



            <div className="text-right">

              {
                wallets || paste_address ?
                  <div>
                    {
                      !isset_address ?
                        <Button onClick={this.createRefCode}
                                color={'primary'}
                                size={'lg'}
                                className={'mb-0'}
                          // disabled={!wallets_loaded ? true : false}
                                style={{width: '100%'}}><b>Create referral link</b></Button>
                        :
                        <Button onClick={this.updateRefName}
                                color={'primary'}
                          // size={'lg'}
                                className={'mb-0'}
                                disabled={isset_name ? true : false}
                                style={{width: '100%'}}><b>Update referral name</b></Button>
                    }
                  </div>
                  :
                  <div>
                    <p className="text-left">To continue, you need to connect a wallet</p>
                    <br/>
                    <Link to={`/auth/`}
                          color={'primary'}
                          size={'lg'}
                          className={'mb-0 btn btn-lg btn-primary'}
                          style={{width: '100%'}}><b>Connect Wallet</b></Link>
                  </div>
              }
            </div>

          </div>
        </div>
      </Modal>
    )
  }



  renderAllPayouts = (item, index) => {

    const { asset, base_price,} = this.state

    const amount_usd = ((item.amount * asset.price) * base_price)

    // const typeIcon = 'referral'
    // const rewardType = 'partner'
    // const rewardPercent = '20'

    const referral_reward = 5
    const partner_reward = 20


    const typeIcon = item.type === 'partner' ? 'users' : item.type === 'referral' ? 'user' : ''
    const rewardType = item.type === 'partner' || item.type === 'referral' ? 'VOL' : ''
    // const rewardPercent = item.type === 'referral' ? referral_reward : item.type === 'partner' ? partner_reward : ''
    const rewardPercent = item.type === 'referral' ? referral_reward : item.type === 'partner' ? item.level : ''

    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>
          <b className={'text-info'}>{numFormat(item.amount, 7)}</b> {getAsset(asset, asset.image, 'warning')} {' '}
          <small className={'text-secondary'}> / ${numFormat(amount_usd,5)}</small>
        </td>
        <td>{shortAddress(item.address)}</td>
        <td><b className={'text-secondary'}><i className={`fa fa-${typeIcon}`}></i> {item.type}</b></td>
        <td><b className={'text-secondary'}><span className={`text-success`}>+{rewardPercent}%</span> {rewardType}</b></td>
        <td>{shortTransaction(item.hash)}</td>
        <td>{formatDate(item.date)}</td>
      </tr>
    )
  }




  render() {

    // console.log('state: ', this.state)

    const { select_address, select_account, wallets, payouts, isset_referral, top, load_more } = this.state

    const { handleSubmit, t } = this.props;

    if (top) {
      return <Redirect to={'/referral/top'} />;
    }

    return(
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        { this.renderModal() }

        <Row>
          <Col md={12} >
            <h3 className="page-title">
              <img src="https://scopuly.com/img/logo/icon.png" alt="SCOP Rewards | scopuly | stellar"
                   style={{width: 23, margin: '-4px 4px 0 0'}}/>
              <b>SCOP</b> Referral Program
              <span style={{float: 'right'}}>
                {
                  isset_referral ?
                    <button className={`btn btn-primary`}
                            onClick={() => this.startCreateRefCode('settings')}><b><i className={'fa fa-user'}></i> Account</b></button>
                    :
                    <button className={`btn btn-success`}
                            onClick={() => this.startCreateRefCode('start')}><b>Start earning</b></button>
                }
              </span>
            </h3>
            <h3 className="page-subhead subhead">
              Invite friends and earn together! The referral program pays out high <b className={'text-success'}>+25%</b> of income to its partners, clients and crowdfunders.
            </h3>
          </Col>
        </Row>


        <Card className={'element-animation'}>
          <CardBody
            style={{
              background: 'linear-gradient(80deg, rgb(166 90 255), rgb(108 151 255))'
            }}
          >
            <br/>
            <Row>
              <Col md={{ size: 10, offset: 1}} lg={{ size: 5, offset: 1 }}>
                <br/>
                <br/>
                <br/>
                <h1 className={'text-white'}><b>Invite your friends and earn cryptocurrency together!</b></h1>
                <br/>
                <h4 className={'text-white'}>
                  The <b>SCOP</b> referral program pays out a high <b className={'text-success'}>+25%</b> income daily to its partners, clients and crowdfunders.
                </h4>
                <br/>
                <div className={'text-white'}>
                  <ul>
                    <li>Buyer: <b className={'text-success'}>+5%</b> <b>VOL</b></li>
                    <li>Partner: <b className={'text-success'}>+20%</b> <b>VOL</b></li>
                  </ul>
                </div>
                <br/><br/>
                <Button color={'success'}
                        onClick={() => this.startCreateRefCode('start')}
                        size={'lg'}><i className="fa fa-plus"></i> <b>Create referral link</b></Button>
              </Col>

              <Col md={{ size: 5}} className={'d-md-none d-lg-block'}>
                {/*className={'d-md-none d-lg-block'}*/}
                <br/><br/>
                <img src="/img/home/referral@4x.png" alt=""/>
                <br/><br/>
              </Col>
            </Row>
            <br/><br/>
          </CardBody>
        </Card>


        <Row>
          <Col md={4} >
            <Card>
              <CardBody>
                <div>
                  <img src="/img/referral/create.png" alt=""/>
                </div>
                <div className="card__title">
                  <h5 className="bold-text">Create your referral code</h5>
                </div>
                <p>
                  <span>
                    Create a referral link that you want to share with your friends. Each of your referrals who will buy <b>SCOP</b> {' '}
                    tokens using your referral link will receive a <b className={'text-success'}>+5%</b> bonus.
                  </span>
                  {/*<br/><br/>*/}
                  {/*<a className={'btn btn-primary mb-0 text-right'}*/}
                  {/*   href={'https://about.scopuly.com/'}*/}
                  {/*   target={'_blank'}*/}
                  {/*>Learm more</a>*/}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div>
                  <img src="/img/referral/invite.png" alt=""/>
                </div>
                <div className="card__title">
                  <h5 className="bold-text">Invite friends and followers</h5>
                </div>
                <p>
                  <span>
                    Share your referral link or QR code with your friends and on social networks. Earn <b>SCOP</b> tokens with your friends and followers through the Scopuly referral program.
                  </span>
                  {/*<br/><br/>*/}
                  {/*<a className={'btn btn-primary mb-0 text-right'}*/}
                  {/*   href={'https://about.scopuly.com/'}*/}
                  {/*   target={'_blank'}*/}
                  {/*>Learm more</a>*/}
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <CardBody>
                <div>
                  <img src="/img/referral/earn.png" alt=""/>
                </div>
                <div className="card__title">
                  <h5 className="bold-text">Earn crypto</h5>
                </div>
                <p>
                  <span>
                    Earn up to <b className={'text-success'}>+30%</b> every time your friends buy <b>SCOP</b> tokens on Scopuly Launchpad. Daily payments. Transparent statistics on all referral payments.
                  </span>
                  {/*<br/><br/>*/}
                  {/*<a className={'btn btn-primary mb-0 text-right'}*/}
                  {/*   href={'https://about.scopuly.com/'}*/}
                  {/*   target={'_blank'}*/}
                  {/*>Learm more</a>*/}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card className={'element-animation'}>
          <CardBody >
            <br/>
            <div className={'text-center'}>
              <h1>Start earning crypto right now</h1>
              <br/>
              <Button color={'success'}
                      onClick={() => this.startCreateRefCode('start')}
                      size={'lg'}><b>Create referral link</b></Button>
              <Link to={'/rewards'} className={'btn btn-secondary'}>All Rewards</Link>
            </div>
          </CardBody>
        </Card>


        <Row className={'element-animation'}>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                {/*<div className="card__title">*/}
                {/*  <h5 className="bold-text">1. 500 SCOP = 5%</h5>*/}
                {/*</div>*/}
                <img src="/img/referral/level-1.png" alt=""/>
                <p className={'uppercase'}><b>Level 1: <Badge color={'success'} className={'fs-16'}><b>+5%</b></Badge></b></p>
                <p><b>From 0 SCOP on balance</b></p>
                <p>Even if you don't have SCOP tokens on your balance, you can still get a referral link and start earning crypto assets.</p>
                <br/>
                <Link to={'/swap'}  className="btn btn-primary mb-0"><b>Add SCOP</b></Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                {/*<div className="card__title">*/}
                {/*  <h5 className="bold-text">1. 500 SCOP = 5%</h5>*/}
                {/*</div>*/}
                <img src="/img/referral/level-2.png" alt=""/>
                <p className={'uppercase'}><b>Level 2: <Badge color={'success'} className={'fs-16'}><b>+10%</b></Badge></b></p>
                <p><b>From 500 SCOP on balance</b></p>
                <p>If you have <b>500</b> or more SCOP on your balance, you will earn <b>10%</b> on SCOP purchases through your link.</p>
                <br/>
                <Link to={'/swap#500'}  className="btn btn-primary mb-0"><b>Buy 500 SCOP</b></Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                {/*<div className="card__title">*/}
                {/*  <h5 className="bold-text">1. 500 SCOP = 5%</h5>*/}
                {/*</div>*/}
                <img src="/img/referral/level-3.png" alt=""/>
                <p className={'uppercase'}><b>Level 3: <Badge color={'success'} className={'fs-16'}><b>+15%</b></Badge></b></p>
                <p><b>From 1,000 SCOP on balance</b></p>
                <p>If you have <b>1,000</b> or more SCOP on your balance, you will earn <b>15%</b> on SCOP purchases through your link.</p>
                <br/>
                <Link to={'/swap#1000'}  className="btn btn-primary mb-0"><b>Buy 1,000 SCOP</b></Link>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} lg={3}>
            <Card>
              <CardBody>
                {/*<div className="card__title">*/}
                {/*  <h5 className="bold-text">1. 500 SCOP = 5%</h5>*/}
                {/*</div>*/}
                <img src="/img/referral/level-4.png" alt=""/>
                <p className={'uppercase'}><b>Level 4: <Badge color={'success'} className={'fs-16'}><b>+20%</b></Badge></b></p>
                <p><b>From 5,000 SCOP on balance</b></p>
                <p>If you have <b>5,000</b> or more SCOP on your balance, you will earn <b>20%</b> on SCOP purchases through your link.</p>
                <br/>
                <Link to={'/swap#5000'}  className="btn btn-primary mb-0"><b>Buy 5,000 SCOP</b></Link>
              </CardBody>
            </Card>
          </Col>
        </Row>


        <Card>
          <CardBody>
            <br/>

            <Row>
              <Col md={3}>
                <div className={'text-center'} style={{marginTop: 49}}>
                  <div className={'text-secondary'}>
                    Account: {select_account ? shortAddress(select_account) : null}
                  </div>
                </div>
              </Col>
              <Col md={{size:6}}>
                <form className="form"
                      onSubmit={handleSubmit(this.submitSearchForm)}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label" style={{marginBottom: 20}}><b>Enter Stellar address to check payouts</b></span>
                    <div className="form__form-group-field">
                      <Field
                        name="search"
                        component="input"
                        type="text"
                        placeholder="Stellar Account"
                        onChange={this.searchChange}
                      />
                      <button
                        type="button"
                        className={`form__form-group-button btn btn-secondary`}
                        style={{width: '90px'}}
                        onClick={this.handleSearchClick}
                      ><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </form>
                <p><small>Can be searched by <b className="text-lightblue">Address</b> or <b className="text-lightblue">Federation name</b>.</small></p>
                <br/>
              </Col>

              <Col md={3}>
                <form className="material-form" style={{marginTop: 32}}>
                  <div>
                    {
                      wallets ?

                        <div>
                          <Field
                            name="select_stat_addr"
                            component={renderTextField}
                            select
                            label="Select Account"
                            onChange={this.changeAddress}
                          >
                            {
                              wallets.length ? wallets.map((item, index) => {

                                if (item) {
                                  return (
                                    <MenuItem
                                      key={index}
                                      style={{fontSize: '12px'}}
                                      className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                      value={item.pk}>
                                      <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                    </MenuItem>
                                  )
                                }
                              }) : null
                            }
                          </Field>

                        </div>

                        : null
                    }
                  </div>
                </form>
              </Col>

            </Row>
          </CardBody>
        </Card>



        <Card>
          <CardBody>
            <h4>
              <span>All Payouts</span>
              {/*<span style={{float: 'right'}}>*/}
              {/*  <Link to={'/top-partners'} className={`btn btn-secondary`}>Top Partners</Link>*/}
              {/*</span>*/}
            </h4>

            { !payouts.length ? <div className="panel__refresh"><LoadingIcon /></div> : null }

            <br/>

            <Table striped responsive hover>
              <thead>
              <tr>
                <th>#</th>
                <th>Reward</th>
                <th>Recipient</th>
                <th>Type</th>
                <th>Reward rate</th>
                <th>Transaction</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>

              {
                payouts.length ?
                  payouts.map(this.renderAllPayouts)
                : null
              }

              </tbody>
            </Table>

            <div className="text-center">
              <br/>
              {
                payouts.length ?
                  <Button color="secondary" onClick={this.getPayoutsData}>
                    {
                      load_more ? 'Load more...' :
                        <SpinnerLoader />
                    }
                  </Button> : null
              }
            </div>
          </CardBody>
        </Card>

        <Footer />

      </Container>

    )
  }
}


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(Referrals));
