import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../../shared/components/Panel';


class ABTestingAnalytics extends PureComponent {

  render() {
    const t = this.props.t;

    return (
      <Panel md={12} lg={12} xl={12} title={t('home.tx_op')}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={this.props.data}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip/>
            <Legend />
            <Bar dataKey="Transactions" fill="#4ce1b6" minPointSize={5}>
            </Bar>
            <Bar dataKey="Operations" fill="#70bbfd" minPointSize={5}/>
          </BarChart>
        </ResponsiveContainer> 
      </Panel>
    );
  }
}


ABTestingAnalytics.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(ABTestingAnalytics);
