import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { Server } from '../../../../modules/stellar/index';
import StellarSdk from 'stellar-sdk';
import {
  shortAddr,
  get_loc_wallets,
  numFormat,
  getAuth,
  needDeauthAll,
  getTitle,
  baseReserve,
  checkConnectProvider, signConnectProvider, showErrorMessage, getAsset, getStellarFee, orderAssets
} from '../../../../modules/index';
import { ToastContainer, ToastStore } from 'react-toasts';
import LoadingIcon from 'mdi-react/LoadingIcon';
import swal from 'sweetalert';
import Footer from "../../../Layout/footer/Footer";
import axios from "axios";
import {GLOBE} from "../../../../modules/globeVars";
import {getRelativeAssets} from "../../../../endpoints/API";

// getTitle(`Balances for Stellar Account`)

const renderTextField = ({
  input, label, type, multiline, rowsMax, meta: { touched, error }, children, select, inputType
}) => {
  return(
    <TextField
      className="material-form__field"
      label={label}
      error={touched && error}
      value={input.value}
      children={children}
      multiline={multiline}
      type={type}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    />
  );
}



class BalancesEdit extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      loader_rm: false,
      my_address: false,
      account: false,
      balances: false,
      load_account: false,
      balances_count: 0,
      offers: [],
      load_offers: false,
      signer: false,
      my_wallets: get_loc_wallets(),
      reserve_trustlines: 0,
      reserve_offers: 0,
      assets: [],
      pools: [],
    };
  }

  xlm_asset = {
    asset_code: 'XLM',
    asset_issuer: 'native',
    name: 'Stellar',
    image: '/img/logo/stellar-logo.png',
    home_domain: 'stellar.org',
  }
  fee = getStellarFee()

  componentDidMount() {
    const account_id = this.props.match.params.id;

    getTitle(`Balances for Stellar Account ${shortAddr(account_id, 4)}`)

    this.getWallets(account_id);
    this.getAccount(account_id);
    this.getOffers(account_id);
  }


  getWallets(account_id) {
    var wallets = this.state.my_wallets;

    if (wallets) {
      var signer = wallets.filter((item) => {
        return item.pk === account_id;
      });

      this.setState({
        signer: signer,
      })

      wallets.forEach((wallet, index) => {
        if (wallet.pk === this.props.match.params.id) {
          this.setState({
            my_address: true,
          })
        }
      });
    }
  }


  getAccount(account_id) {

    Server.loadAccount(account_id)
      .then((account) => {

        if (account) {

          const balances_count = (account.balances.length-1)
          const reserve_trustlines = (balances_count * baseReserve());

          // const pools = account.balances.filter(item => (item.asset_type === "liquidity_pool_shares"))
          // console.log('pools: ', pools)

          this.setState({
            account: account,
            // balances: account.balances,
            load_account: true,
            balances_count,
            reserve_trustlines,
          })

          // this.getOffers(item.pk);

          this.formatAssets(account.balances)
        }
      })
      .catch((err) => {
        console.error(err);
    })
  }


  formatAssets = (balances) => {

    let query = {
      pools: [],
      asset_codes: [],
      asset_issuers: [],
    }

    balances.map((item, index) => {

      if (item.asset_type === 'liquidity_pool_shares') {
        query.pools.push(item.liquidity_pool_id)
      }
      else {

        if (item.asset_type !== 'native') {
          query.asset_codes.push(item.asset_code)
          query.asset_issuers.push(item.asset_issuer)
        }
      }
    })


    getRelativeAssets(query.pools, query.asset_codes, query.asset_issuers).then(result => {
      // console.log('get_relative_assets result: ', result)

      balances.reverse().map((item, index) => {
        if (item.asset_type === 'liquidity_pool_shares') {
          item.pool = result.data.pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]
        }
      })

      this.setState({
        balances,
        pools: result.data.pools,
        assets: result.data.assets,
      })
    })
  }


  getOffers(account_id) {

    Server.offers().forAccount(account_id)
      .order('desc')
      .call()
      .then((offerResult) => {

        this.setState({
          load_offers: true,
          offers: offerResult.records,
          reserve_offers: (offerResult.records.length * baseReserve()),
        })

      })
      .catch((err) => {
        console.error(err);
      })
  }


  getOffersCount(item_balance) {
    let count = 0;

    this.state.offers.forEach((item) => {
      if (item.buying.asset_code === item_balance.asset_code ||
          item.selling.asset_code === item_balance.asset_code) {
        count++;
      }
    })

    return count;
  }


  addBalance(values) {

    const address = this.props.match.params.id

    getAuth()
      .then((value) => {
        if (value === 'accept') {
          acceptSendTX();
        }
        else if (value === 'need_deauth_all') {
          needDeauthAll();
        }
      });

    const acceptSendTX = () => {

      if (!values.asset_code) {
        ToastStore.warning('Enter Asset Code');
      }
      else if (!values.asset_issuer) {
        ToastStore.warning('Enter Asset Issue');
      }
      else {

        this.setState({ loader: true })

        Server.loadAccount(address)
          .then((sourceAccount) => {

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                  asset: new StellarSdk.Asset(values.asset_code, values.asset_issuer),
              }))
              .setTimeout(100)
              .build();

            if (checkConnectProvider(address)) {
              return signConnectProvider(transaction, address).then(result => {
                return Server.submitTransaction(result);
              })
            }
            else {
              transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
              return Server.submitTransaction(transaction);
            }
          })
          .then((result) => {
            this.setState({ loader: false })

            swal({
              title: `Balance ${values.asset_code} added!`,
              text: "The trustline (balance) has been successfully added to your Account.",
              icon: "success",
              confirm: true,
            })
            .then(confirm => {
              if (confirm) {
                window.location.reload();
              }
            });
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader: false })
            ToastStore.error('Transaction error');
        });
      }
    }
  }



  removeBalance = (asset, item) => {

    // console.log('asset: ', asset)
    // console.log('item: ', item)

    let rmAsset, trustCode

    if (asset.asset_code) {
      rmAsset = new StellarSdk.Asset(asset.asset_code, asset.asset_issuer)
      trustCode = asset.asset_code
    }
    else {
      const [A, B] = orderAssets(...[new StellarSdk.Asset(asset.asset_a.asset_code, asset.asset_a.asset_issuer), new StellarSdk.Asset(asset.asset_b.asset_code, asset.asset_b.asset_issuer)],);
      rmAsset = new StellarSdk.LiquidityPoolAsset(A, B, StellarSdk.LiquidityPoolFeeV18);
      trustCode = `${asset.asset_a.asset_code}/${asset.asset_b.asset_code}`
    }

    if (item.balance > 0) {
      swal({
        title: 'Unable to delete',
        text: `To remove the trustline, the balance must be 0 ${trustCode}. Send all tokens to another address and retry the operation.`,
        icon: "warning",
      });
    }
    else {

      const message = `Remove Trustline to <b class="text-warning">${trustCode}</b> right now?`
      const element = document.createElement("span");
      element.innerHTML = message;

      swal({
        title: "Remove Trustline",
        content: element,
        icon: "warning",
        confirm: true,
      })
      .then(confirm => {
        if (confirm) {
          acceptRemove();
        }
      });
    }

    const acceptRemove = () => {

      getAuth()
        .then((value) => {
          if (value === 'accept') {
            acceptSendTX();
          }
          else if (value === 'need_deauth_all') {
            needDeauthAll();
          }
        });

      const acceptSendTX = () => {
        const address = this.props.match.params.id

        this.setState({ loader_rm: true })

        Server.loadAccount(address)
          .then((sourceAccount) => {

            var transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
                fee: this.fee,
                networkPassphrase: StellarSdk.Networks.PUBLIC
              })
              .addOperation(StellarSdk.Operation.changeTrust({
                  asset: rmAsset,
                  limit: '0'
              }))
              .setTimeout(100)
              .build();

            if (checkConnectProvider(address)) {
              return signConnectProvider(transaction, address).then(result => {
                return Server.submitTransaction(result);
              })
            }
            else {
              transaction.sign(StellarSdk.Keypair.fromSecret(window.atob(this.state.signer[0].sk)));
              return Server.submitTransaction(transaction);
            }
          })
          .then((result) => {

            this.setState({ loader_rm: false })

            swal({
              title: `Balance ${trustCode} removed`,
              icon: "success",
              confirm: true,
            })
            .then(confirm => {

              if (confirm) {
                this.setState({
                  account: {
                    ...this.state.account,
                    balances: this.state.account.balances.filter(balance => {
                      if (balance.liquidity_pool_id) {
                        if (balance.liquidity_pool_id !== item.liquidity_pool_id) return true
                      }
                      else if (balance.asset_code !== asset.asset_code && balance.asset_issuer !== asset.asset_issuer) return true
                    })
                  }
                });
              }
            });
          })
          .catch((error) => {
            console.error('Something went wrong!', error);

            this.setState({ loader_rm: false })
            showErrorMessage(error, 'change_trust')
        });
      }
    }
  }


  renderBalance = (item, index) => {
    // console.log('item: ', item)

    const { assets } = this.state

    const default_image = '/img/digitalcoin.png'

    if (item.asset_type !== 'native') {

      let offers_count = this.getOffersCount(item);
      let asset, url, asset_a, asset_b

      if (item.asset_type === 'native') {
        asset = this.xlm_asset
      }

      if (item.asset_type !== 'liquidity_pool_shares' && item.asset_type !== 'native') {
        asset = assets.filter(asset => asset.asset_code === item.asset_code && asset.asset_issuer === item.asset_issuer)[0]
      }

      if (item.asset_type === 'liquidity_pool_shares') {

        url = `/pool/${item.liquidity_pool_id}`;
        offers_count = 0

        if (item.pool && item.pool !== 'not_found') {
          asset_a = assets.filter(asset => asset.asset_code === item.pool.code_a && asset.asset_issuer === item.pool.issuer_a)[0]
          asset_b = assets.filter(asset => asset.asset_code === item.pool.code_b && asset.asset_issuer === item.pool.issuer_b)[0]
        }

        if (!asset_a) {
          asset_a = this.xlm_asset
        }

        // console.log('asset_a: ', asset_a)
        // console.log('asset_b: ', asset_b)

        if (!asset_b) {
          asset_b = {
            image: default_image,
            asset_code: '-',
          }
        }
        // if (!asset_a) {
        //   asset_a.image = default_image
        //   asset_a.asset_code = '-'
        // }

      }

      // console.log('asset: ', asset)

      if (!asset) asset = { image: default_image }

      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td>
            {/*<Link to={`/trade/${item.asset_code}-XLM/${item.asset_issuer}/native`}><b className="text-warning">{item.asset_code}</b></Link>*/}
            {/*{ getAsset(`${item.asset_code}:${item.asset_issuer}`, asset.image) }*/}

            {
              item.pool ?
                <Link className="text-warning" to={ url }>
                  <span className={'wr-balance-poll-item'}>
                    <span className={'balance-pool-item'}>
                      <span className={'pool-share'}>
                        <img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/>
                      </span>
                      <span className={'pool-share'}>
                        <img src={asset_b.image} alt="" style={{width: 23}}/>
                      </span>
                    </span>
                  </span> {' '}
                  <b>
                    {asset_a.asset_code}/{asset_b.asset_code}
                  </b>
                </Link>
                :
                <span>
                  {getAsset(`${item.asset_code}:${item.asset_issuer}`, asset.image)}
                </span>
            }

          </td>
          <td><b className="text-info">{numFormat(item.balance, 7)}</b></td>
          <td>
            {
              item.pool ?
                <Link to={`/pool/${item.pool.pool_id}`}><b>{shortAddr(item.pool.pool_id, 4)}</b></Link>
              :
                <Link to={`/account/${item.asset_issuer}`}><b>{shortAddr(item.asset_issuer, 4)}</b></Link>
            }
          </td>
          <td><b className={'text-info'}>{numFormat(item.buying_liabilities, 7)}</b></td>
          <td><b className={'text-info'}>{numFormat(item.selling_liabilities, 7)}</b></td>
          <td><Link to={`/offers/${this.props.match.params.id}/${item.asset_code}/${item.asset_issuer}`}><b>{offers_count}</b></Link></td>
          <td>{item.asset_type}</td>
          <td>{numFormat(item.limit, 2)}</td>
          <td>
            {
              item.asset_type === 'liquidity_pool_shares' ?
                <Link to={url}
                      target={'_blank'}><b><i className={'fa fa-external-link'}></i></b>
                </Link>
              :
                <Link to={`/trustline/${item.asset_code}-${item.asset_issuer}`}
                      target={'_blank'}><b><i className={'fa fa-external-link'}></i></b>
                </Link>
            }
          </td>
          {
            this.state.my_address ?
              <th><Button outline size="sm"
                          color="secondary"
                          style={{marginBottom: '0'}}
                          onClick={() => {
                            if (item.asset_type === 'liquidity_pool_shares') {
                              this.removeBalance({ asset_a, asset_b }, item)
                            } else {
                              this.removeBalance(asset, item)
                            }
                          }}>
                <span className="lnr lnr-cross-circle" />
              </Button></th>
            : null
          }
        </tr>
      )
    }
  }


  render() {

    // console.log('state: ', this.state)

    const { handleSubmit, reset } = this.props;
    const { balances_count, offers, reserve_offers, reserve_trustlines, account, assets, pools, balances } = this.state;

    return (
      <Container className="dashboard">

        <Row>
          <Col md={12}>
            <h3 className="page-title">Trustlines { account ? `(${balances_count})` : null }
              {
                offers.length ?
                  <span style={{float: 'right'}}>
                    <Link to={`/offers/${this.props.match.params.id}`} className={'btn btn-outline-secondary btn-sm'} size="sm">Offers (<b>{offers.length}</b>)</Link>
                  </span>
                  : null
              }
            </h3>
            <h4 className="subhead subhead-b">
              For Account: <Link to={`/account/${this.props.match.params.id}`}><b>{shortAddr(this.props.match.params.id, 4)}</b></Link>
            </h4>
          </Col>
        </Row>

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        {
          this.state.balances_count > 0 ?
            <Row className="addr-list-wr">
              <Col md={12}>
                <Card>
                  <CardBody className={this.state.my_address ? 'my_elem' : null}>

                    { this.state.loader_rm ? <div className="panel__refresh" style={{height: '89%'}}><LoadingIcon /></div> : null }

                    { this.state.my_address ? <h4 className="subhead">My Account</h4> : null }

                    { account &&
                      <p>
                        Reserve <b className={'text-info'}>{ (reserve_offers + reserve_trustlines) }</b> <Link to={`/native`}><b className={'text-warning'}>XLM</b></Link>{' '}
                        <small>(Trustlines: <b>{ balances_count }</b> / <b className={'text-info'}>{ reserve_trustlines }</b> <b>XLM</b>, Offers: <b>{offers.length}</b> / <b className={'text-info'}>{ reserve_offers }</b> <b>XLM</b>)</small>
                      </p>
                    }
                    <br/>

                    <Table striped responsive style={{fontSize: 13}}>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Asset Code</th>
                          <th>Balance</th>
                          <th>Asset Issuer</th>
                          <th>Buying</th>
                          <th>Selling</th>
                          <th>Offers</th>
                          <th>Asset type</th>
                          <th>Limit</th>
                          <th>Link</th>
                          {this.state.my_address ? <th>Remove</th> : null}
                        </tr>
                      </thead>
                      <tbody>

                        {
                          balances && assets ?
                            balances.map(this.renderBalance)
                          : null
                        }

                      </tbody>
                    </Table>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          :
            !account ?
              <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card> : null

        }


        {
          this.state.my_address ?
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>

                    { this.state.loader ? <div className="panel__refresh"><LoadingIcon /></div> : null }

                    <Col md={6}>
                      <div>
                        <div className="card__title">
                          <h5 className="bold-text">Add New Trustline</h5>
                          <h5 className="subhead">Adding a new <b>trust line</b> to an Asset</h5>
                        </div>

                        <form className="material-form" onSubmit={handleSubmit(this.addBalance.bind(this))}>

                          <div>
                            <Field
                              name="asset_code"
                              component={renderTextField}
                              label="Asset Code"
                            />
                          </div>

                          <div>
                            <Field
                              name="asset_issuer"
                              component={renderTextField}
                              label="Asset Issuer"
                            />
                          </div>

                          <br/>
                          <Button color="primary" type="submit">Add Balance</Button>
                        </form>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          : null
        }

        <Footer />

      </Container>
    );
  }
}


BalancesEdit.propTypes = {
  t: PropTypes.func.isRequired,
};


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(BalancesEdit));
