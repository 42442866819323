import React, { PureComponent, useState } from 'react';
import { translate } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import {
  numFormat,
} from '../../../../../modules/index';




class LPoolItem extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      amount_usd: 0,
      asset: false,
      price_usd: 0,
    };
  }

  xlm_asset = {
    asset_code: 'XLM',
    asset_issuer: 'native',
    name: 'Stellar',
    image: '/img/logo/stellar-logo.png',
    home_domain: 'stellar.org',
  }


  componentDidMount() {

    this.getAssetPrice(this.props.item)
  }



  getAssetPrice = async (item) => {

    const { price, pools, assets } = this.props

    if (item.asset_type === 'liquidity_pool_shares') {

      const pool = pools.filter(pool => pool.pool_id === item.liquidity_pool_id)[0]

      if (pool) {
        const asset = assets.filter(asset => asset.asset_code === pool.code_b && asset.asset_issuer === pool.issuer_b)[0]

        if (asset) {

          const reserves_b = pool.amount_b
          const total = pool.shares
          const amountB = Number((reserves_b / total * item.balance).toFixed(7))

          const amount_usd = ((amountB * asset.price) * price) * 2

          this.setState({ amount_usd, })
          this.props.totalAssetsUsd(amount_usd)
        }
      }
    }
  }




  render() {


    const { item, assets, pools } = this.props
    const { amount_usd, price_usd } = this.state

    const image = '/img/digitalcoin.png'
    let url
    let asset


    let asset_a = { image }
    let asset_b = { image }
    let pair_name = 'SHARES'


    if (item.asset_type === 'liquidity_pool_shares') {

      url = `/pool/${item.liquidity_pool_id}`;

      if (item.pool) {
        asset_a = assets.filter(asset => asset.asset_code === item.pool.code_a && asset.asset_issuer === item.pool.issuer_a)[0]
        asset_b = assets.filter(asset => asset.asset_code === item.pool.code_b && asset.asset_issuer === item.pool.issuer_b)[0]

        if (item.pool.issuer_a === 'native') {
          asset_a = this.xlm_asset
        }

        if (asset_a && asset_b) {
          pair_name = <span>{asset_a.asset_code}<b className={'text-secondary'}> / </b>{asset_b.asset_code}</span>
        }
      }


      if (!asset_a) {
        asset_a = this.xlm_asset
      }
      if (!asset_b) {
        asset_b = { image }
      }
    }



    return (
      <span>

        {
          item.asset_type === 'liquidity_pool_shares' ?

            <span className="chips_item">

              <span className="asset-amount">
                <Link className="text-warning" to={ url }>
                  <span className={'wr-balance-poll-item'}>
                    <span className={'balance-pool-item'}>
                      <span className={'pool-share'}><img src={asset_a.image} alt="" style={{width: asset_a.asset_issuer === 'native' ? 25 : 23}}/></span>
                      <span className={'pool-share'}><img src={asset_b.image} alt="" style={{width: 23}}/></span>
                    </span>
                  </span>
                </Link> {' '}
                {
                  item.asset_type === 'liquidity_pool_shares' &&
                  <span>
                      <Link to={url}><b className={'text-warning'}>{pair_name}</b></Link> {' '}
                    {/*<small className={'text-secondary'}>pool</small>*/}
                    </span>
                }
                <b className="text-info">{numFormat(item.balance, 7)}</b> <br/>
                <small className={'space-between text-secondary'}>
                  <span className={'mr-10'}><b>${ numFormat(amount_usd, 2) }</b></span>
                  {/*<span>${ numFormat(price_usd, 7) }</span>*/}
                </small>
              </span>
            </span>


          : null
        }
      </span>
    );
  }
}



export default (translate('common')(LPoolItem));
