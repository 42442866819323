import React, { PureComponent } from 'react';
import { Table } from 'reactstrap';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { shortAddr, numFormat, getNameAddr } from '../../../../modules/index';
import moment from 'moment';
import iconIdent from '../../Account/components/iconIdent';



class PaymentsForAsset extends PureComponent {

	constructor(props) {
	  super(props);
	  this.state = {
	    // activeTab: '1',
	  };
	}



	formatDate(time) {
	  return moment(time).format("DD MMMM YYYY HH:mm:ss");
	}

	shortAddress(address) {
		const name = getNameAddr(address);
	  return (
	    <a href={`/account/${address}`}>{iconIdent(address, 'icon-indent')} <b>{shortAddr(address, 4)}</b> <small className="text-info">{name ? `[${name}]` : ''}</small></a>
	  )
	}

	itemType(type) {
	  return type.replace(/_/g," ")[0].toUpperCase() + type.replace(/_/g," ").slice(1);
	}

	getAsset(asset) {
	  return (
	    <Link to={`/asset/${asset[0]}-${asset[1]}`} className="text-warning"><b>{asset[0]}</b></Link>
	  )
	}


	renderItem = (item, index) => {
		const asset = item.asset.split('-');
		return (
			<tr key={index}>
				<td>{this.shortAddress(item.from)} sent <b className="text-info">{numFormat(item.amount, 7)}</b> {this.getAsset(asset)} to {this.shortAddress(item.to)}</td>
				<td> <b className="text-info">{numFormat(item.amount, 7)}</b> {this.getAsset(asset)} </td>
				<td>{this.formatDate(item.ts)}</td>
				<td><Link to={`/operation/${item.id}`}><b>{shortAddr(item.id, 4)}</b></Link></td>

				{/*<td>{this.getAsset(asset)}</td>*/}
			</tr>
		)
	}


	render() {
		const items = this.props.items;
		// console.log('items: ', items)

		return(
			<Table striped responsive>
			  <thead>
			    <tr>
			    	<th>Detail d</th>
						<th>Amount</th>
						<th>Date</th>
						<th>Operation</th>
			    </tr>
			  </thead>
			  <tbody>

			  	{ items.map(this.renderItem) }

			  </tbody>
			</Table>
		)
	}
}

PaymentsForAsset.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(PaymentsForAsset);
