import React, { PureComponent } from 'react';


class Ref extends PureComponent {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     //
  //   };
  // }

  componentDidMount() {

    const ref_code = this.props.match.params.id

    if (ref_code) {
      localStorage.setItem('ref_code', ref_code)
      // window.location.href = `${window.location.origin}/launch/scopuly/?ref=${ref_code}`
      window.location.href = `${window.location.origin}/swap`
    }
  }




  render() {
    return (<div> </div>);
  }
}



export default (Ref);
