import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Table, Button, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { translate } from 'react-i18next';
import { Field, reduxForm, change} from 'redux-form';
import StellarSdk from "stellar-sdk";
import {
  get_loc_wallets,
  randomAmount,
  getSigner,
  shortAddr,
  numFormat,
  getIconConnectProvider,
  renderTextField,
  getSelectAddress,
  getSCOPAsset,
  getDurationTime,
  formatDate,
  getStellarFee,
  stellarStroop, getFloatValue
} from "../../../../modules";
import swal from "sweetalert";
import {getOrderbook, strictSendPaths,} from '../../../../endpoints/StellarAPI'
import {Server} from "../../../../modules/stellar";
import MenuItem from "@material-ui/core/MenuItem";
import iconIdent from "../../Account/components/iconIdent";
import axios from "axios";
import {GLOBE} from "../../../../modules/globeVars";
import LoadingIcon from "mdi-react/LoadingIcon";
import {getAssetInfo,} from "../../../../endpoints/API";
import ClockLoader from "react-spinners/ClockLoader";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/core";
import {ToastContainer, ToastStore} from "react-toasts";


const loaderProcess = css`
  display: inline-block;
`

const loaderDuration = css`
  display: inline-block;
  margin-bottom: -3.5px;
`



class VolumeAMM extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      wallets: get_loc_wallets(),
      account: false,
      base_asset: new StellarSdk.Asset.native(),
      counter_asset: new StellarSdk.Asset('SCOP', 'GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ'),
      min_counter_amount: 500,
      counter_amount: 0,
      base_amount: 0,
      buy_amount_counter: 0, // this.getRandomAmount(),
      trader_1: false, // 'GAUEEYOUENSQLAFWSRPHABROV263WV5DKSH23ITC4BVY4GEWLBSRZUEG',
      trader_2: false, // 'GBE27GR2Y74PQK6OBU6DMD7CGZLU5S72DHV4O25ZJTSYBL6ZHW6HSONV',
      trader_3: 'GAUEEYOUENSQLAFWSRPHABROV263WV5DKSH23ITC4BVY4GEWLBSRZUEG',
      started: '',
      duration: '00:00:00',
      volume: 0,
      start_balance: 0,
      accumulation: 0,
      process_mess: 'Not running',
      cycles: 0,
      errors: 0,
      loader_process: false,
      cycle_fee: 0,
      all_cycle_fee: 0,
      fee: getStellarFee(),
      profit: '0',
      timeout: '0',
    };
  }


  componentDidMount() {

    const { counter_asset } = this.state

    getAssetInfo(counter_asset.code, counter_asset.issuer).then(result => {
      console.log('result: ', result)

      this.setState(
        { counter_asset_db: result.data[0] },
        () => this.getSearchParams())
    })

    this.getBasePrice()
    // this.getSearchParams()
  }




  getReceivePath = () => {

    // const destination_amount = this.getRandomAmount()
    const { base_asset, counter_asset, counter_amount, cycles, volume, trader_1, profit } = this.state

    console.log('getReceivePath...')
    this.processMessage(`Get Receive Path`)

    console.log('destination_amount: ', counter_amount)
    console.log('cycles: ', cycles)
    console.log('volume: ', volume+' XLM')

    Server.strictReceivePaths([base_asset], counter_asset, counter_amount)
      .call()
      .then( (pathResult) => {

        console.log('strictReceivePaths: ', pathResult)

        const max_amount = pathResult.records[0].source_amount

        const sendAsset = base_asset
        const sendMax = (Number(max_amount) + (Number(max_amount) * profit)).toFixed(7)
        const destination = trader_1
        const destAsset = counter_asset
        const destAmount = pathResult.records[0].destination_amount
        const path = pathResult.records[0].path.map(item => {
          if (item.asset_type === 'native') {
            return new StellarSdk.Asset.native()
          }
          else {
            return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
          }
        })

        console.log('path: ', path)
        console.log('sendMax: ', sendMax)

        this.strictReceive(sendAsset, sendMax, destination, destAsset, destAmount, path)
      })
      .catch( (err) => {
        console.log(err)
      })
  }


  getSendPath = () => {

    console.log('getSendPath...')

    this.processMessage(`Get Send Path`)

    const { base_asset, counter_asset, counter_amount, trader_1, profit } = this.state

    const destination_amount = (Number(counter_amount) + (Number(counter_amount) * profit)).toFixed(7)
    console.log('destination_amount: ', destination_amount)

    Server.strictSendPaths(counter_asset, destination_amount, [base_asset])
      .call()
      .then( (pathResult) => {

        console.log('strictSendPaths: ', pathResult)

        const min_amount = pathResult.records[0].destination_amount

        const sendAsset = counter_asset
        const sendAmount = pathResult.records[0].source_amount
        const destination = trader_1
        const destAsset = base_asset
        const destMin = (Number(min_amount) - (Number(min_amount) * profit)).toFixed(7)
        const path = pathResult.records[0].path.map(item => {
          if (item.asset_type === 'native') {
            return new StellarSdk.Asset.native()
          }
          else {
            return new StellarSdk.Asset(item.asset_code, item.asset_issuer)
          }
        })

        console.log('path: ', path)
        console.log('destMin: ', destMin)

        this.strictSend(sendAsset, sendAmount, destination, destAsset, destMin, path)
      })
      .catch( (err) => {
        console.log(err)
      })
  }


  strictReceive = (sendAsset, sendMax, destination, destAsset, destAmount, path) => {

    const { fee, volume, trader_1, cycles } = this.state
    let ops_fee

    console.log('loadAccount...')
    this.processMessage(`Get Acc Receive Path`)

    Server.loadAccount(trader_1)
      .then((sourceAccount) => {

        this.processMessage(`Set Receive Tx`)

        this.setAccount(sourceAccount, 'trader_1')

        const transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.pathPaymentStrictReceive({ sendAsset, sendMax, destination, destAsset, destAmount, path }))
          .setTimeout(100)
          .build();

        const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(trader_1).sk))
        transaction.sign(signer);

        console.log('transaction: ', transaction)

        ops_fee = transaction._fee

        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {
          console.log('Receive result: ', result)

          this.setState({
            volume: (volume + Number(sendMax)),
            cycles: cycles+1,
          })

          this.getSendPath()
          this.setAllFee(ops_fee)
        }
      })
      .catch((error) => {
        console.error('Error! ', error);

        this.countErrors()

        const reSubmit = () => {
          this.strictReceive(sendAsset, sendMax, destination, destAsset, destAmount, path)
        }


        if (error.response) {

          console.log('receive error.response: ', error.response)

          if (error.response.data && error.response.data.title === 'Timeout' ||
            error.response.data.extras.result_codes.transaction === 'tx_insufficient_fee' ||
            error.response.data.extras.result_codes.transaction === "tx_bad_seq") {
            reSubmit()
          }
          else if (error.response.data.extras.result_codes.operations &&
            error.response.data.extras.result_codes.operations[0] === 'op_too_few_offers' ||
            error.response.data.extras.result_codes.transaction === 'tx_too_late') {

            console.log('receive Timeout 5 min...')

            this.processMessage(`Error Receive Timeout...`)

            setTimeout(() => {
              reSubmit()
            }, 300000) // 5 min
          }
          else {
            reSubmit()
          }
        }
        else {
          reSubmit()
        }

      });
  }


  strictSend = (sendAsset, sendAmount, destination, destAsset, destMin, path) => {

    const { fee, volume, trader_1, cycles, timeout } = this.state
    let ops_fee

    console.log('loadAccount...')
    this.processMessage(`Get Acc Send Path`)

    Server.loadAccount(trader_1)
      .then((sourceAccount) => {

        this.processMessage(`Set Send Tx`)

        this.setAccount(sourceAccount, 'trader_1')

        const transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee,
          networkPassphrase: StellarSdk.Networks.PUBLIC
        })
          .addOperation(StellarSdk.Operation.pathPaymentStrictSend({ sendAsset, sendAmount, destination, destAsset, destMin, path }))
          .setTimeout(100)
          .build();


        const signer = StellarSdk.Keypair.fromSecret(window.atob(getSigner(trader_1).sk))
        transaction.sign(signer);

        console.log('transaction: ', transaction)

        ops_fee = transaction._fee

        return Server.submitTransaction(transaction);
      })
      .then((result) => {

        if (result) {

          console.log('Send result: ', result)

          this.setState({
            counter_amount: this.getRandomAmount(),
            cycles: cycles+1,
            volume: (volume + Number(destMin))
          },
            () => {

              this.processMessage(`Timeout...`)

              setTimeout(() => {
                this.getReceivePath()
              }, timeout)

              this.setAllFee(ops_fee)
            })
        }
      })
      .catch((error) => {
        console.error('Send Error! ', error);


        this.countErrors()


        if (error.response) {

          console.log('receive error.response: ', error.response)

          if (error.response.data && error.response.data.title === 'Timeout' ||
            error.response.data.extras.result_codes.transaction === 'tx_insufficient_fee' ||
            error.response.data.extras.result_codes.transaction === "tx_bad_seq") {
            this.strictSend(sendAsset, sendAmount, destination, destAsset, destMin, path)
          }
          //
          else if (error.response.data.extras.result_codes.operations &&
            error.response.data.extras.result_codes.operations[0] === 'op_too_few_offers'||
            error.response.data.extras.result_codes.transaction === 'tx_too_late') {

            console.log('receive Timeout 5 min...')

            this.processMessage(`Error Send Timeout...`)

            setTimeout(() => {
              this.strictSend(sendAsset, sendAmount, destination, destAsset, destMin, path)
            }, 300000) // 5 min
          }
          else {
            this.strictSend(sendAsset, sendAmount, destination, destAsset, destMin, path)
          }
        }
        else {
          this.strictSend(sendAsset, sendAmount, destination, destAsset, destMin, path)
        }
      });
  }



  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));
    if (price && price.crypto_price) {
      price = price.crypto_price.xlm_usd
    }
    else if (price && price.local_price) {
      price =price.local_price.USD
    }

    this.setState({ price })
  }


  getRandomAmount = () => {

    const { min_counter_amount, counter_amount } = this.state

    // return randomAmount(500, 5000)
    // return randomAmount(1000, 6000)
    return randomAmount(min_counter_amount, counter_amount)
    // return randomAmount(3000, 10000)
    // return randomAmount(3000, 15000)
    // return randomAmount(100, 1000)
    // return randomAmount(500, 3500)
    // return randomAmount(10, 100)
  }


  startProcess = () => {

    const { counter_amount, trader_1, trader_2 } = this.state

    if (!trader_1 || !trader_2) {
      ToastStore.warning('Trading addresses not selected');
    }
    else if (!counter_amount) {
      ToastStore.warning('Specify max SCOP amount');
    }
    else {

      swal({
        title: "Confirm",
        // text: element,
        icon: "info",
        buttons: {
          cancel: true,
          confirm: true,
        },
      })
        .then((confirm) => {
          if (confirm) {

            this.getReceivePath()

            this.setState({
              started: String(new Date()),
              loader_process: true,
            })
          }
        })
    }
  }


  stopProcess = () => {

    this.setState({
      loader_process: false,
    })
  }


  processMessage = (mess) => {
    this.setState({
      process_mess: mess,
      duration: getDurationTime(this.state.started)
    })
  }




  setAllFee = (ops_fee) => {

    // console.log('ops_fee: ', ops_fee)

    this.setState({
      cycle_fee: ops_fee,
      all_cycle_fee: (this.state.all_cycle_fee + Number(ops_fee))
    })
  }



  countErrors = () => {
    console.log('countErrors: ', this.state.errors)

    this.setState({ errors: this.state.errors + 1 })
  }




  changeAccount1 = async (value) => {

    const { counter_asset } = this.state

    const address = getSelectAddress(value)

    this.setState({ trader_1: address, loader_1: true })

    console.log('loadAccount... ', address)

    const account = await Server.loadAccount(address)
    console.log('result_1: ', account)

    this.setAccount(account, 'trader_1')
  }


  changeAccount2 = async (value) => {

    const { counter_asset } = this.state

    const address = getSelectAddress(value)

    this.setState({ trader_2: address, loader_2: true })

    console.log('loadAccount_2... ', address)

    const account = await Server.loadAccount(address)
    console.log('result_2: ', account)

    this.setAccount(account, 'trader_2')
  }


  setAccount = (account, type) => {

    const { counter_asset, start_balance } = this.state

    const balance_1 = account.balances[account.balances.length-1].balance
    const balance_2 = account.balances.filter(item => item.asset_code === counter_asset.code)[0].balance
    let accumulation = start_balance ? (balance_1 - start_balance) : 0

    if (type === 'trader_1') {

      if (!start_balance) {
        this.setState({ start_balance: balance_1 })
      }

      this.setState({
        account_1: account,
        loader_1: false,
        base_balance_1: balance_1,
        counter_balance_1: balance_2,
        accumulation,
      })
    }

    if (type === 'trader_2') {

      this.setState({
        account_2: account,
        loader_2: false,
        base_balance_2: balance_1,
        counter_balance_2: balance_2,
      })
    }

    // this.setSearchParams()
  }


  changeCounterAmount = (value) => {

    const { counter_asset_db, min_counter_amount } = this.state

    let counter_amount = '', count = 0;

    if (isNaN(value)) {

      for (const key of Object.keys(value)) {
        counter_amount = (counter_amount + (Object.keys(value).length-1 > count ? value[key] : ''))
        count++
      }
    }
    else {
      counter_amount = value
    }

    const base_amount = (counter_amount * counter_asset_db.price)
    const min_base_amount = (min_counter_amount * counter_asset_db.price)

    this.setState({
      min_base_amount,
      counter_amount,
      base_amount,
    }, () => {

      this.setState({
        buy_amount_counter: this.getRandomAmount()
      })

      this.setSearchParams()
    })
  }


  setSearchParams = () => {

    const { trader_1, trader_2, counter_amount, profit, timeout } = this.state

    const searchParams = new URLSearchParams();
    searchParams.append('profit', profit)
    searchParams.append('trader_1', trader_1)
    searchParams.append('trader_2', trader_2)
    searchParams.append('max', counter_amount)
    searchParams.append('timeout', timeout)


    this.props.history.push({
      pathname: `/dashboard/volume_amm/?${searchParams.toString()}`,
    });
  }


  getSearchParams = () => {

    console.log('counter_asset_db: ', this.state.counter_asset_db)

    const { counter_asset_db, min_counter_amount } = this.state

    const searchParams = new URLSearchParams(window.location.search);
    const trader_1 = searchParams.get('trader_1')
    const trader_2 = searchParams.get('trader_2')
    const max = searchParams.get('max')
    const profit = searchParams.get('profit')
    const timeout = searchParams.get('timeout')
    const base_amount = (max * counter_asset_db.price)
    const min_base_amount = (min_counter_amount * counter_asset_db.price)

    if (trader_1 && trader_2 && max && profit) {

      this.props.change('trader_1', trader_1)
      this.props.change('trader_2', trader_2)
      this.props.change('max_amount', max)
      this.props.change('profit', profit)
      this.props.change('timeout', timeout)

      this.setState({
        counter_amount: max,
        base_amount,
        min_base_amount,
        trader_1,
        trader_2,
        loader_1: true,
        loader_2: true,
        profit,
        timeout
        // max_amount: max,
      }, () => {
        this.setState({
          buy_amount_counter: this.getRandomAmount(),
        })
      })

      Server.loadAccount(trader_1).then(result => {
        this.setAccount(result, 'trader_1')
      })
      Server.loadAccount(trader_2).then(result => {
        this.setAccount(result, 'trader_2')
      })

      setTimeout(() =>{
        // this.changeCounterAmount(max)
        // this.setSearchParams()
      }, 1000)
    }
  }


  changeProfit = (value) => {
    const profit = getFloatValue(value)

    this.setState({ profit },
      () => this.setSearchParams())
  }


  changeTimeout = (value) => {
    const timeout = getFloatValue(value)

    this.setState({ timeout },
      () => this.setSearchParams())
  }





  render() {

    console.log('State AMM: ', this.state)

    const { wallets, account_1, account_2, base_asset, counter_asset, counter_amount, base_amount, start_balance, min_base_amount,
      sell_price, buy_price,
      loader_1, base_balance_1, counter_balance_1,
      loader_2, base_balance_2, counter_balance_2,
      started, duration, volume, accumulation, process_mess, cycles, errors, loader_process, price, fee, cycle_fee, all_cycle_fee  } = this.state


    const strop_xlm = stellarStroop()

    const fee_xlm =  (fee * strop_xlm).toFixed(7)
    const fee_usd =  (fee * strop_xlm * price).toFixed(7)

    const cycle_fee_xlm = (cycle_fee * strop_xlm).toFixed(7)
    const cycle_fee_usd =  ((cycle_fee * strop_xlm) * price).toFixed(7)

    const all_cycle_fee_xlm = (all_cycle_fee * strop_xlm).toFixed(7)
    const all_cycle_fee_usd = ((all_cycle_fee * strop_xlm) * price).toFixed(7)

    return(
      <Container className="dashboard">

        <ToastContainer store={ToastStore} position={ToastContainer.POSITION.TOP_RIGHT} />

        <Row>
          <Col md={12}>
            <h3 className="page-title">Volume AMM</h3>
          </Col>
        </Row>


        <Card>
          <CardBody>

            <Row>
              <Col md={3}>

                <form className="material-form">
                  <div>
                    {
                      wallets ?

                        <div>
                          <Field
                            name="trader_1"
                            component={renderTextField}
                            select
                            label="Select Trader-1"
                            onChange={(e) => {
                              this.changeAccount1(e)
                            }} >
                            {
                              wallets.length ? wallets.map((item, index) => {

                                if (item) {
                                  return (
                                    <MenuItem
                                      key={index}
                                      style={{fontSize: '12px'}}
                                      className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                      value={item.pk}>
                                      <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                    </MenuItem>
                                  )
                                }
                              }) : null
                            }
                          </Field>

                          { loader_1 ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

                          {
                            account_1 ?
                              <div style={{marginTop: -8}}>
                                <div><span>Balances:</span></div>
                                <div><span><b className="text-info">{numFormat(base_balance_1, 7)}</b> <b className="text-warning">XLM</b></span></div>
                                <div>
                                  <span><b className="text-info">{numFormat(counter_balance_1, 7)}</b> <b className="text-warning">{counter_asset.code}</b></span>
                                </div>
                                {/*<div style={{fontSize: 11, marginTop: 4}}><small>Avaliable: <b className="text-info">{numFormat(this.state.available_balance, 7)}</b> <b className="text-warning">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-warning">XLM</b></font></small></div>*/}
                              </div>
                              :
                              null
                          }

                        </div>

                        :
                        null
                    }
                  </div>

                </form>


                {
                  account_1 ?
                    <div style={{marginTop: -8}} className={'text-secondary'}>
                      <br/>
                      <div><span>Start balance:</span></div>
                      <div><span><b className="text-secondary">{numFormat(start_balance, 7)}</b> XLM</span></div>
                    </div>
                    :
                    null
                }

              </Col>


              <Col md={3}>

                <form className="material-form">
                  <div>
                    {
                      wallets ?

                        <div>
                          <Field
                            name="trader_2"
                            component={renderTextField}
                            select
                            label="Select Trader-2"
                            onChange={(e) => {
                              this.changeAccount2(e)
                            }} >
                            {
                              wallets.length ? wallets.map((item, index) => {

                                if (item) {
                                  return (
                                    <MenuItem
                                      key={index}
                                      style={{fontSize: '12px'}}
                                      className={`material-form__option ${item.provider ? 'connect-provider' : ''}`}
                                      value={item.pk}>
                                      <b className="text-secondary">{iconIdent(item.pk, 'icon-indent')} {shortAddr(item.pk, 4)}</b> <small>&nbsp;/ {item.title} / <b>{item.balance ? numFormat(item.balance, 7) : null} XLM</b></small> { getIconConnectProvider(item) }
                                    </MenuItem>
                                  )
                                }
                              }) : null
                            }
                          </Field>

                          { loader_2 ? <div className="panel__refresh" style={{height: '100%'}}><LoadingIcon /></div> : null }

                          {
                            account_2 ?
                              <div style={{marginTop: -8}}>
                                <div><span>Balances:</span></div>
                                <div><span><b className="text-info">{numFormat(base_balance_2, 7)}</b> <b className="text-warning">XLM</b></span></div>
                                <div>
                                  <span><b className="text-info">{numFormat(counter_balance_2, 7)}</b> <b className="text-warning">{counter_asset.code}</b></span>
                                </div>
                                {/*<div style={{fontSize: 11, marginTop: 4}}><small>Avaliable: <b className="text-info">{numFormat(this.state.available_balance, 7)}</b> <b className="text-warning">XLM</b> &nbsp;/&nbsp; <font>Reserve: <b className="text-info">{this.state.reserve_balance}</b> <b className="text-warning">XLM</b></font></small></div>*/}
                              </div>
                              :
                              null
                          }
                        </div>

                        :
                        null
                    }
                  </div>

                </form>

              </Col>


              <Col md={2}>

                <form className="material-form" >
                  <div>
                    <Field
                      name="max_amount"
                      component={renderTextField}
                      type="text"
                      label="Max amount SCOP"
                      onChange={this.changeCounterAmount}
                    />
                    {/*<span className={'material-field-icon'}>SCOP</span>*/}
                  </div>
                </form>
                {
                  base_amount ?
                    <div style={{marginTop: -8}} className={'text-secondary'}>
                      <div><span>From <b className="text-info">{numFormat(min_base_amount)}</b> <b className="text-warning">XLM</b></span></div>
                      <div><span>To <b className="text-info">{numFormat(base_amount, 7)}</b> <b className="text-warning">XLM</b> <small>(random)</small></span></div>
                    </div>
                    :
                    null
                }

                <br/>

                <form className="material-form">
                  <div>
                    <Field
                      name="profit"
                      component={renderTextField}
                      label="Profit"
                      select
                      onChange={this.changeProfit}
                    >
                      <MenuItem value={'0.00'} className="material-form__option">0%</MenuItem>
                      <MenuItem value={'0.01'} className="material-form__option">1%</MenuItem>
                      <MenuItem value={'0.02'} className="material-form__option">2%</MenuItem>
                      <MenuItem value={'0.03'} className="material-form__option">3%</MenuItem>
                      <MenuItem value={'0.04'} className="material-form__option">4%</MenuItem>
                      <MenuItem value={'0.05'} className="material-form__option">5%</MenuItem>
                      <MenuItem value={'0.10'} className="material-form__option">10%</MenuItem>
                      <MenuItem value={'0.15'} className="material-form__option">15%</MenuItem>
                      <MenuItem value={'0.20'} className="material-form__option">20%</MenuItem>
                      <MenuItem value={'0.25'} className="material-form__option">25%</MenuItem>
                      <MenuItem value={'0.30'} className="material-form__option">30%</MenuItem>
                    </Field>
                  </div>
                </form>

                <br/>

                <form className="material-form">
                  <div>
                    <Field
                      name="timeout"
                      component={renderTextField}
                      label="Timeout"
                      select
                      onChange={this.changeTimeout}
                    >
                      <MenuItem value={'0'} className="material-form__option">0 min</MenuItem>
                      <MenuItem value={'60000'} className="material-form__option">1 min</MenuItem>
                      <MenuItem value={'120000'} className="material-form__option">2 min</MenuItem>
                      <MenuItem value={'300000'} className="material-form__option">5 min</MenuItem>
                      <MenuItem value={'600000'} className="material-form__option">10 min</MenuItem>
                      <MenuItem value={'900000'} className="material-form__option">15 min</MenuItem>
                      <MenuItem value={'1200000'} className="material-form__option">20 min</MenuItem>
                      <MenuItem value={'1500000'} className="material-form__option">25 min</MenuItem>
                      <MenuItem value={'1800000'} className="material-form__option">30 min</MenuItem>
                      {/*<MenuItem value={'240000'} className="material-form__option">40 min</MenuItem>*/}
                      {/*<MenuItem value={'360000'} className="material-form__option">60 min</MenuItem>*/}
                    </Field>
                  </div>
                </form>

              </Col>


              <Col md={4}>

                <div>Started: <b className={'text-info'}>{ formatDate(started) }</b></div>
                <div>Duration: <b className={'text-info'}>{ duration }</b> &nbsp;&nbsp;&nbsp;
                  {
                    loader_process ?
                      <ClockLoader
                        color="#36d7b7"
                        css={loaderDuration}
                        size={18} />
                      : null
                  }
                </div>
                <div>Volume: <b className={'text-info'}>{numFormat(volume)}</b> <b className="text-warning">XLM</b></div>
                { true ?
                  <div>Accumulation: <b className={'text-info'}>{numFormat(accumulation)}</b> <b className="text-warning">XLM</b></div>
                  : null }
                <div>Proccess: <b className={'text-info'}>{ process_mess }</b> {' '}
                  {
                    loader_process ?
                      <BeatLoader
                        css={loaderProcess}
                        color={"#70bbfd"}
                        loading={true}
                        size={6}
                      />
                      : null
                  }
                </div>
                <div>Cycles: <b className={'text-info'}>{numFormat(cycles)}</b></div>
                <div>Errors: <b className={'text-danger'}>{numFormat(errors)}</b></div>
                <hr/>
                <div className={'text-secondary'}>Fee: <b className={'text-info'}>{numFormat(fee)}</b> stroop <small>/ <b>{fee_xlm}</b> XLM / <b>${fee_usd}</b></small></div>
                <div className={'text-secondary'}>Cycle Fee: <b className={'text-info'}>{numFormat(cycle_fee, 7)}</b> stroop <small>/ <b>{cycle_fee_xlm}</b> XLM / <b>${cycle_fee_usd}</b></small></div>
                <div className={'text-secondary'}>All Fees: <b className={'text-info'}>{numFormat(all_cycle_fee, 7)}</b> stroop <small>/ <b>{all_cycle_fee_xlm}</b> XLM / <b>${all_cycle_fee_usd}</b></small></div>

                {
                  buy_price ?
                    <p className={'text-secondary'}>
                      <small>
                        Buy price: <b className={'text-success'}>{buy_price}</b> XLM <br/>
                        Sell price: <b className={'text-danger'}>{sell_price}</b> XLM
                      </small>
                    </p>
                    : null
                }

              </Col>
            </Row>

            <br/>
            <hr/>
            <br/>


            <ButtonToolbar className="form__button-toolbar">
              <Button color="success" onClick={this.startProcess}>Start</Button>
              <Button color="primary" onClick={this.stopProcess}>Stop</Button> &nbsp;&nbsp;&nbsp;&nbsp;

              {
                loader_process ?
                  <span className={'mt-6'}>
                    <ClockLoader color="#36d7b7" size={30} />
                  </span>
                  : null
              }

            </ButtonToolbar>

          </CardBody>
        </Card>


      </Container>

    )
  }
}


export default reduxForm({
  form: 'floating_labels_form',
})(translate('common')(VolumeAMM));
