import React, { PureComponent } from 'react';
import { Col, Container, Row, Card, CardBody, Button, Table, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import StellarSdk from 'stellar-sdk';
import { Server } from '../../../modules/stellar/index';
import each from 'lodash/each';
import axios from 'axios';
import LoadingIcon from 'mdi-react/LoadingIcon';
import { GLOBE } from '../../../modules/globeVars/index';
import { numFormat } from '../../../modules/index';
import AssetChart from '../Asset/components/AssetChart';
import Trades from '../Account/components/Effects/Trades';
import Issuer from '../Asset/components/Issuer';
import AssetInfo from './components/AssetInfo';
import Orderbook from './components/Orderbook';
import PaymentsForAsset from './components/PaymentsForAsset';
import SpinnerLoader from '../../../modules/SpinnerLoader/index';
import Collapse from '../../../shared/components/Collapse';
import swal from 'sweetalert';
import Footer from '../../Layout/footer/Footer';
import { getTitle } from '../../../modules/index';
import getPrice from "../../App/GetPrice";



let resolution = 86400000,
    limit_items = 30,
    render_count = 0;


class Asset extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      asset_db: [],
      asset_url: this.parseUrl(),

      toml: {},
      toml_full: [],
      home_domain: '',
      price: false,
      load_xlm_price: false,
      load_asset_db: false,

      asset: [],
      load_asset: false,

      trades_agregate: false,
      load_trades_agregate: false,

      payments: [],
      load_payments: false,
      last_payments: false,
      payments_more_btn: false,

      trades: [],
      load_trades: false,

      trades_pair: [],
      load_trades_pair: false,
      last_trades_pair: false,
      trades_pair_more_btn: false,

      load_toml: false,

      orderbook: false,
      load_orderbook: false,

      orderbook_short: [],
      load_orderbook_short: true,

      // orderbook_all: [],
      // orderbook_all: false,
      // asks_length: 0,
      // asks_sum: 0,
      // bids_length: 0,
      // bids_sum: 0,

      change24: false,
      load_more: true,
      up_token: false,
    };
  }

  count = 0;

  componentDidMount() {

    const asset = this.parseUrl();

    getTitle(`${asset.asset_code} | Stellar Asset: ${this.props.match.params.id}`);

    setTimeout(() => {
      // if (!this.state.up_token && this.state.load_asset) {
        this.sendAssetData();
      // }
    }, 10000)

    this.getBasePrice();
    this.getAssetDataFromDB();

    if (this.props.match.params.id !== 'XLM-native') {
      this.getAsset();
      this.getAgregate(resolution);
      this.getTrades();
      // this.getTradesPair();
      this.getShortOrderbook();
      // this.getOrderbook();
      // this.getPayments();

      this.streamTrades();
    }



    switch(window.location.hash) {
      case '#orderbook':
          // this.toggle('1');
        break
      case '#trades':
          this.toggle('2');
        break
      case '#payments':
          this.toggle('3');
        break
      case '#issuer':
          this.toggle('4');
        break
      default:
        break
    }
  }



  getBasePrice = () => {
    let price = JSON.parse(localStorage.getItem('price'));

    if (price && price.crypto_price) {
      this.setState({ price: price.crypto_price.xlm_usd });
    }
    else if (price && price.local_price) {
      this.setState({ price: price.local_price.USD })
    }
    else {
      if (this.count < 2) {
        getPrice()
        setTimeout(() => {
          this.getBasePrice()
          this.count++;
        }, 2000)
      }
    }
  }


  getAssetDataFromDB() {

    // console.log('getAssetDataFromDB this.state', this.state)
    // console.log('getAssetDataFromDB this.state.asset_url', this.state.asset_url)

    axios.get(`${GLOBE.API_URL}/get_assets_code_issuer/?asset_code=${this.state.asset_url.asset_code}&asset_issuer=${this.state.asset_url.asset_issuer}`)
      .then((response) => {

        // console.log('response', response);

        if (response.data.length) {
          this.setState({
            asset_db: response.data[0],
          })
        }

        this.setState({
          load_asset_db: true,
        })
      })
      .catch(function (error) {
        console.log(error);
    });
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }

    this.toggleHash(tab);
  };

  toggleHash(tab) {

    switch(tab) {
      case '1':
        window.location.hash = 'orderbook';
        // if (!this.state.load_orderbook)
          // this.getShortOrderbook();
        break
      case '2':
        window.location.hash = 'trades';
        if (!this.state.load_trades_pair)
          this.getTradesPair();
        break
      case '3':
        window.location.hash = 'payments';
        if (!this.state.load_payments)
          this.getPayments();
        break
      case '4':
          window.location.hash = 'issuer';
        break
      default:
        break
    }
  }


  getOrderbook() {

    let asset = this.parseUrl();

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(200)
      .call()
      .then((result) => {
        // console.log('result orderbook: ', result)

        if (result) {
          this.setState({
            orderbook: result,
            load_orderbook: true,
          })
        }

      })
      .catch((err) => {
        console.log(err);
    })
  }



  streamTrades() {

    let asset = this.parseUrl();

    Server.trades()
      .forAssetPair(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .cursor('now')
      .stream({
        onmessage: (message) => {

          const modState = () => {
            this.setState({
              trades_pair: [message, ...this.state.trades_pair], // this.state.trades_pair.concat(message),
              // last_trades_pair: message.paging_token,
            });
          }

          if (message.counter_asset_code === asset.asset_code && message.counter_asset_issuer === asset.asset_issuer) {
            modState();
          }
          else if (message.base_asset_code === asset.asset_code && message.base_asset_issuer === asset.asset_issuer) {
            modState();
          }
        }
    });
  }


  getShortOrderbook() {

    let asset = this.parseUrl();
    let limit = 30;

    let orderbook_length = localStorage.getItem('orderbook_length');
    // console.log('orderbook_length: ', orderbook_length)

    if (orderbook_length) {
      limit = orderbook_length;
    }

    Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .limit(limit)
      .call()
      .then((result) => {

        if (result) {
          this.setState({
            orderbook_short: result,
            load_orderbook_short: true,
          })

          this.getOrderbook();
        }

      })
      .catch((err) => {
        console.log(err);
    })


    // var orderbookHandler = function (orderbookResponse) {
    //   console.log('orderbookResponse: ', orderbookResponse);
    // };

    // var es = Server.orderbook(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
    //   .cursor('now')
    //   .stream({
    //     onmessage: (result) => {
    //       console.log('orderbookResponse: ', result);

    //       this.setState({
    //         orderbook_short: result,
    //         load_orderbook_short: true,
    //       })

    //       // this.getOrderbook();
    //     }
    //   })
  }


  getPayments = () => {

    let $this = this;
    let asset = this.parseUrl();
    let cursor;

    this.setState({ load_more: false })

    if (this.state.last_payments) {
      cursor = '&cursor='+this.state.last_payments;
    } else {
      cursor = '';
    }

    const url = `https://api.stellar.expert/api/explorer/public/payments?asset=${asset.asset_code}-${asset.asset_issuer}&order=desc&limit=${limit_items}${cursor}`;

    fetch(url)
      .then(function(response) {
        return response.json();
       })
      .then(function(result) {
        const records = result._embedded.records;

        // console.log('getPayments records: ', records)

        if(records.length < limit_items || records.length === 0) {
          $this.setState({ payments_more_btn: false });
        } else {
          $this.setState({ payments_more_btn: true });
        }

        $this.setState({
          payments: $this.state.payments.concat(records),
          last_payments: records[records.length - 1].paging_token,
          load_payments: true,
          load_more: true,
        })
      })
      .catch( error => {
        console.log('error: ', error)
      } );
  }


  getAsset = () => {

    let $this = this;
    let asset = this.parseUrl();

    Server.assets()
      .forIssuer(asset.asset_issuer)
      .forCode(asset.asset_code)
      .call()
      .then(function(resp) {
        const asset = resp.records[0];

        $this.setState({
          asset: asset,
          load_asset: true,
        })

        $this.getToml(asset);
      })
      .catch(function(err) {
        console.error('err: ', err);
    });
  }


  parseUrl() {

    let part = this.props.match.params.id.split('-');
    let asset = {
      asset_code: part[0],
      asset_issuer: part[1],
    }
    return asset;
  }


  getTrades() {

    var $this = this;
    let asset = this.parseUrl();

    var base = new StellarSdk.Asset.native();
    var counter = new StellarSdk.Asset(asset.asset_code, asset.asset_issuer);
    var startTime = 0;
    var endTime = 0;
    var resolution = 86400000;
    var offset = 0;

    Server.tradeAggregation(base, counter, startTime, endTime, resolution, offset)
    .order('desc')
    .limit(100)
    .call()
    .then(function(tradesRes){

      if (tradesRes.records.length) {
        $this.setState({
          trades: tradesRes.records,
          load_trades: true
        });
      }
      else {
        swal({
          title: 'No trading history',
          text: 'This Asset has no trading history yet. Just a few transactions and data will appear.',
          icon: 'info'
        })
      }
    })
    .catch(function(err){
      console.log('err trades count', err);
    })
  }




  streamTrades() {

    let asset = this.parseUrl();

    Server.trades()
      .forAssetPair(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .cursor('now')
      .stream({
        onmessage: (message) => {

          const modState = () => {
            this.setState({
              trades_pair: [message, ...this.state.trades_pair], // this.state.trades_pair.concat(message),
              // last_trades_pair: message.paging_token,
            });
          }

          if (message.counter_asset_code === asset.asset_code && message.counter_asset_issuer === asset.asset_issuer) {
            modState();
          }
          else if (message.base_asset_code === asset.asset_code && message.base_asset_issuer === asset.asset_issuer) {
            modState();
          }
        }
    });
  }


  getTradesPair() {

    var $this = this;
    let asset = this.parseUrl();

    this.setState({ load_more: false })

    const ServerTrades = Server.trades()
      .forAssetPair(new StellarSdk.Asset.native(), new StellarSdk.Asset(asset.asset_code, asset.asset_issuer))
      .order('desc')
      .limit(limit_items)

      if ($this.state.last_trades_pair) {
        ServerTrades.cursor($this.state.last_trades_pair);
        // var length = this.state.trades_pair.length;
      }

    ServerTrades
      .call()
      .then(function(result){

        if(result.records.length < limit_items || result.records.length === 0) {
          $this.setState({ trades_pair_more_btn: false });
        } else {
          $this.setState({ trades_pair_more_btn: true });
        }

        $this.setState({
          trades_pair: $this.state.trades_pair.concat(result.records),
          last_trades_pair: result.records[result.records.length - 1].paging_token,
          load_trades_pair: true,
          load_more: true,
        });
      })
      .catch(function(err){
        console.log('err trades count', err);
    })
  }


  getToml(asset) {

    const home_domain = asset._links.toml.href.replace('https://', '').replace('/.well-known/stellar.toml', '');
    const part = this.props.match.params.id.split('-');

    if (home_domain) {

      this.setState({
        home_domain: home_domain,
      })

      StellarSdk.StellarTomlResolver.resolve(home_domain)
        .then(assetToml => {

          this.setState({
            toml_full: assetToml,
          })

          each(assetToml.CURRENCIES, (item) => {
            if (item.code === part[0] && item.issuer === part[1]) {

              this.setState({
                toml: item,
                load_toml: true,
              })

              // this.getTomlImage(item)
              this.sendAssetData()
            }
          });
        })
        .catch(error => {
          console.log('toml err', error);
          // this.getTomlImage('error')
      });
    }
  }


  // getTomlImage(toml) {
  //   if (toml == 'error') {
  //     imag
  //   }
  // }


  getAgregate(resolution) {

    let $this = this;
    let asset = this.parseUrl();
    // let trades_agregate = [];

    var obj = {
        base: new StellarSdk.Asset.native(),
        counter: new StellarSdk.Asset(asset.asset_code, asset.asset_issuer),
        start_time: 0,
        end_time: 0,
        resolution: resolution,
        offset: 0
    }

    Server.tradeAggregation(obj.base, obj.counter, obj.start_time, obj.end_time, obj.resolution, obj.offset)
      .order('desc')
      .limit(100)
      .call()
      .then((resp) => {

        // console.log('tradeAggregation : ', resp);

        // each(resp.records, (item, index) => {
        //   item.value = numFormat(item.close_r.D / item.close_r.N, 7);
        //   item.date = moment(item.timestamp).format("DD MMM");
        //   // item.volume = numFormat(item.base_volume, 0);
        //   trades_agregate.unshift(item);
        // });

        let change7d = '', change_7d_color = '', chart7d = '';
        if (resp.records.length >= 7) {
          change7d = this.change7d(resp).change7d;
          change_7d_color = this.change24(resp).change_24h_color;
          chart7d = this.change24(resp).chart7d;
        }

        this.setState({
          trades_agregate: resp.records,// trades_agregate,
          load_trades_agregate: true,
          change24: this.change24(resp).change24,
          change_24h_color: this.change24(resp).change_24h_color,
          change7d: change7d,
          change_7d_color: change_7d_color,
          chart7d: chart7d,
        });

        this.sendAssetData();
      })
      .catch(function(err) {
        console.error('err: ', err);
    });
  }


  change24(val) {

   var today = Number((1 / val.records[0].close).toFixed(6));
   var yestoday = Number((1 / val.records[1].close).toFixed(7));

    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      val.change_24h_color = 'secondary';
      val.change24 = '0.00';
    }
    else {
      less();
    }

    function more() {
      // val.sign = '+';
      val.change24 = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      val.change_24h_color = 'success';
    }

    function less() {
      val.change24 = Number(100 - yestoday * 100 / today).toFixed(2);
      val.change_24h_color = 'danger';
    }

    return val;
  }


  change7d(val) {

   var today = Number((1 / val.records[0].close).toFixed(6));
   var yestoday = Number((1 / val.records[6].close).toFixed(7));

    if (today > yestoday) {
      more();
    }
    else if (today === yestoday) {
      val.change_7d_color = 'secondary';
      val.change7d = '0.00';
    }
    else {
      less();
    }

    function more() {
      // val.sign = '+';
      val.change7d = '+'+Number(today / yestoday * 100 - 100).toFixed(2);
      val.change_7d_color = 'success';
    }

    function less() {
      val.change7d = Number(100 - yestoday * 100 / today).toFixed(2);
      val.change_7d_color = 'danger';
    }

    // 7d
    val.chart7d = [];
    val.records.forEach((item, index) => {
      if (index+1 <= 7) {
        var price = Number((item.close_r.d / item.close_r.n).toFixed(7));
        if (val.records[0].close > item.close) {
          price = (price * 1.5)
        }
        else if (val.records[0].close < item.close) {
          price = (price / 1.5)
        }
        val.chart7d.unshift({ name: index, price: price });
      }
    });

    return val;
  }


  getLinkAsset(item) {
    return (
      <a href={item.asset_type === 'native' ? '/asset/XLM-native' : `/asset/${item.asset_code}-${item.asset_issuer}`} className="text-warning"><b>{item.asset_type === 'native' ? 'XLM' : item.asset_code}</b></a>
    )
  }


  sendAssetData() {

    let bids_price = 0, asks_price = 0, spread = 0;
    if (this.state.load_orderbook) {
      if (this.state.orderbook.bids) {
        bids_price = (this.state.orderbook.bids[0].price_r.d / this.state.orderbook.bids[0].price_r.n);
      }
      if (this.state.orderbook.asks.length) {
        asks_price = (this.state.orderbook.asks[0].price_r.d / this.state.orderbook.asks[0].price_r.n);
      }
    }

    spread = (bids_price - asks_price).toFixed(7);
    spread = numFormat(100 / (bids_price / spread), 2);

    // console.log('this.state.trades_agregate: ', this.state.trades_agregate)

    let asset = {
      asset_code: this.state.asset.asset_code,
      asset_issuer: this.state.asset.asset_issuer,
      image: this.state.toml.image,
      name: this.state.toml.name,
      home_domain: this.state.home_domain,
      supply: this.state.asset.amount,
      holders: this.state.asset.num_accounts,
      base_volume: this.state.trades_agregate ? this.state.trades_agregate[0].base_volume : 0,
      price: this.state.trades_agregate ? (this.state.trades_agregate[0].close_r.d / this.state.trades_agregate[0].close_r.n) : 0,
      change24: this.state.trades_agregate ? this.state.change24 : 0,
      price7d: this.state.change7d,
      change7d: this.state.change7d,
      chart7d: JSON.stringify(this.state.chart7d),
      change24: this.state.trades_agregate ? this.state.change24 : 0,
      change_24h_color: this.state.trades_agregate ? this.state.change_24h_color : 0,
      spread: spread
    }

    // console.log('asset_up: ', asset)

    axios.get(`${GLOBE.API_URL}/update_token/?asset_code=${asset.asset_code}&
                                              asset_issuer=${asset.asset_issuer}&
                                              image=${asset.image}&
                                              name=${asset.name}&
                                              home_domain=${asset.home_domain}&
                                              supply=${asset.supply}&
                                              base_volume=${asset.base_volume}&
                                              price=${asset.price}&
                                              price7d=${asset.price7d}&
                                              change24=${asset.change24}&
                                              change7d=${asset.change7d}&
                                              holders=${asset.holders}&
                                              chart7d=${asset.chart7d}&
                                              change_24h_color=${asset.change_24h_color}&
                                              spread=${asset.spread}`)
      .then((response) => {
        if (response) {
          console.log('up_token: ', response)

          this.setState({
            up_token: true,
          })
        }
      })
      .catch(function (error) {
        console.log(error);
    });
  }




  render() {

    // console.log('Asset state: ', this.state)
    // console.log('render_count: ', render_count)

    // if (this.state.load_asset
    //   // && this.state.load_trades
    //   // && this.state.load_toml
    //   // && this.state.load_trades_agregate
    //   // && this.state.load_orderbook
    //   && render_count === 0) {

        // setTimeout(() => {
        //   this.sendAssetData();
        //   render_count++;
        // }, 10000)
    // }

    const asset = this.parseUrl();

    let sell_asset = {
      asset_code: 'XLM',
      asset_issuer: 'native',
    };

    if (this.props.match.params.id === 'XLM-native') {
      return <Redirect to={'/native'} />;
    }

    return (
      <Container className="dashboard">
        {/*<Row>
          <Col md={12}>
            <h4 className="page-title">Asset: {asset.asset_code}-{asset.asset_issuer}</h4>

            <h3 className="page-title">Asset: {asset.asset_code}</h3>
            <h3 className="page-subhead subhead">Issuer: {asset.asset_issuer}</h3>

          </Col>
        </Row>*/}

        <Row>
          <Col md={12} lg={4}>
            <Card>
              <CardBody>


                {
                  this.state.load_asset  ? // && this.state.load_asset_db && this.state.load_trades && this.state.load_orderbook
                    <AssetInfo state={this.state}
                                asset_db={this.state.asset_db} />
                  :
                    <div className="panel__refresh"><LoadingIcon /></div>
                }

              </CardBody>
            </Card>
          </Col>

        {/* Chart */}
          <Col md={12} lg={8}>
            <Card>
              <CardBody style={{paddingBottom: '15px'}}>

                { this.state.load_trades_agregate && this.state.load_asset && this.state.load_orderbook && this.state.load_asset_db
                  ?

                    <AssetChart trade_agregation={this.state.trades_agregate}
                                orderbook={this.state.orderbook}
                                buy_asset={this.state.asset}
                                sell_asset={sell_asset}
                                asset_db={this.state.asset_db}
                                price={this.state.price}/>
                  :
                    <div className="panel__refresh"><LoadingIcon /></div> }

              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>

          <Col xs={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody>
                {/*<div className="card__title">
                  <h5 className="bold-text">{t('ui_elements.tabs.default_tabs')}</h5>
                  <h5 className="subhead">Use default tabs</h5>
                </div>*/}
                <div className="tabs tabs--bordered-bottom">
                  <div className="tabs__wrap">
                    <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => {
                            this.toggle('1');
                          }}
                        >
                          <b>Orderbook</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => {
                            this.toggle('2');
                          }}
                        >
                          <b>Trades</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => {
                            this.toggle('3');
                          }}
                        >
                          <b>Payments</b>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => {
                            this.toggle('4');
                          }}
                        >
                          <b>Issuer (toml)</b>
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>

                      <TabPane tabId="1">

                        {
                          this.state.load_orderbook
                          ?
                            <Orderbook orderbook={this.state.orderbook}
                                       orderbook_short={this.state.orderbook_short} />
                          :
                            <div className="panel__refresh"><LoadingIcon /></div>
                        }


                        {/*<Row>
                          <Col md={12} lg={12} xl={6}>
                            <Card>
                              <div className="card__title">
                                <h5 className="bold-text">Buy Offers ({this.state.asks_length})</h5>
                                <h5 className="subhead">ASKS SUM: <b>{numFormat(this.state.asks_sum, 2)}</b> {this.state.asset.asset_code}</h5>
                              </div>
                              <Table striped responsive hover className="text-right">
                                <thead>
                                  <tr>
                                    <th>XLM</th>
                                    <th>{this.state.asset.asset_code}</th>
                                    <th>Price</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  { this.state.load_orderbook ? this.state.orderbook.asks.map(this.renderBuyOffers.bind(this)) : '' }

                                </tbody>
                              </Table>
                            </Card>
                          </Col>

                          <Col md={12} lg={12} xl={6}>
                            <Card>
                              <div className="card__title">
                                <h5 className="bold-text">Sell Offers ({this.state.bids_length})</h5>
                                <h5 className="subhead">BIDS SUM: <b>{numFormat(this.state.bids_sum, 2)}</b> {this.state.asset.asset_code}</h5>
                              </div>
                              <Table striped responsive hover>
                                <thead>
                                  <tr>
                                    <th>Price</th>
                                    <th>{this.state.asset.asset_code}</th>
                                    <th>XLM</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  { this.state.load_orderbook ? this.state.orderbook.bids.map(this.renderSellOffers.bind(this)) : '' }

                                </tbody>
                              </Table>
                            </Card>
                          </Col>
                        </Row>*/}

                        {/*<br/>
                        <div className="text-center">
                          {
                            this.state.ord ?

                              <Button color="secondary" onClick={() => {this.getTradesPair()}}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>*/}
                      </TabPane>

                      <TabPane tabId="2">

                        { !this.state.load_trades_pair ? <div className="panel__refresh"><LoadingIcon /></div> : ''}
                        { this.state.load_trades_pair ?
                            <Trades items={this.state.trades_pair} />
                          : null }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.trades_pair_more_btn ?

                              <Button color="secondary" onClick={() => {this.getTradesPair()}}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>

                        {/*<Col md={12} lg={12} xl={6}>
                          <Card>
                            <div className="card__title">
                              <h5 className="bold-text">Trades</h5>
                            </div>
                            <Table striped responsive hover>
                              <thead>
                                <tr>
                                  <th>base_account</th>
                                  <th>base_amount</th>
                                  <th>base_asset_type</th>
                                  <th>offer_id</th>
                                  <th>counter_account</th>
                                  <th>counter_amount</th>
                                  <th>counter_asset_code</th>
                                  <th>peice</th>
                                  <th>ledger_close_time</th>
                                </tr>
                              </thead>
                              <tbody>

                                { this.state.load_trades_pair ? this.state.trades_pair.map(this.renderTrades.bind(this)) : '' }

                              </tbody>
                            </Table>
                          </Card>
                        </Col>*/}

                      </TabPane>

                      <TabPane tabId="3">
                        { !this.state.load_payments ? <div className="panel__refresh"><LoadingIcon /></div> : null}
                        { this.state.load_payments ? <PaymentsForAsset items={this.state.payments}/> : '' }
                        <br/>
                        <div className="text-center">
                          {
                            this.state.payments_more_btn ?

                              <Button color="secondary" onClick={() => {this.getPayments()}}>
                                {
                                  this.state.load_more ? 'Load more...' :
                                  <SpinnerLoader />
                                }
                              </Button>
                              : null
                          }
                        </div>
                      </TabPane>

                      <TabPane tabId="4">
                        {
                          this.state.load_asset && this.state.load_toml ?
                            <Issuer asset={this.state.asset}
                                    toml={this.state.toml}
                                    toml_full={this.state.toml_full}
                                    home_domain={this.state.home_domain}/>
                          :
                            <Card><CardBody><div className="panel__refresh"><LoadingIcon /></div></CardBody></Card>
                        }
                      </TabPane>
                    </TabContent>

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>

        <Footer />

      </Container>
    );
  }
}


Asset.propTypes = {
  t: PropTypes.func.isRequired,
};



export default translate('common')(Asset);
